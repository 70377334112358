import styled from 'styled-components';

export const AddDocumentStyles = styled.div`  
  .formSection {
    padding: 17px 38px 17px 17px;

    .checkboxWrapper {
      span {
        margin-top: 16px;
      }
    }

    .languagesSection {
      padding: 0 0 39px 0;
      box-sizing: border-box;

      h3 {
        font-size: 16px;
        font-weight: 500;
        margin-top: 0;
        margin-bottom: 8px;
      }

      .languages {
        display: grid;
        grid-template-columns: repeat(auto-fill,minmax(80px,100px));
        grid-gap: 16px;
        align-items: center;

        .language {
          width: 100px;
          height: 32px;
          background-color: #fff;
          border: 1px solid #0E9285;
          border-radius: 4px;
          outline: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          font-size: 14px;
          color: #0E9285;
          transition: all 0.3s;

          &.active {
            color: #fff;
            background-color: #0E9285;
            transition: all 0.3s;
          }
        }
      }
    }

    .fileContainer {
      display: grid;
      gap: 20px;
      margin-bottom: 24px;

      font-family: 'Roboto', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #000000;
      
      .sizeContainer {
        display: grid;
        gap: 3px;

        font-size: 14px;
        color: #C4C4C4;
      }
    }
    
    .error {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }
    
    .submitWrapper {
      max-width: 544px;
      width: 100%;
      
      button {
        margin-bottom: 4px;
      }
      
      .error {
        font-size: 13px;
        line-height: 16px;
        color: #ff3333;
        text-align: right;
      }
    }
  }
`;
