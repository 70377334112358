import React, { useEffect, useRef, useState } from 'react';
import { FormInfoStyles } from './FormInfoStyles';
import { useNavigate, useParams } from 'react-router-dom';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import DefaultCompanyImage from '../../../assets/images/defaultCompanyImage.png';
import { Api } from '../../../api';
import CustomColorsSelect from '../../../components/form/customSelect/CustomColorsSelect';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import { validationSchema } from './constructorFormFields';
import { IColor, IForm, ILanguage } from '../../../entities';
import {
  useAppDispatch, useAppSelector, setNotSavedForm, setSelectedCompany, setSelectedNode,
} from '../../../state';
import SelectWithAnyItems from '../../../components/form/customSelect/SelectWithAnyItems';
import { arrayUniqueByKey, getErrorMessage, handleKeyUp } from '../../../utils';
import InputField from '../../../components/form/inputField/InputField';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import { getTranslationByLangOrEng } from '../../../i18n';
import { CheckMark, DeleteIcon } from '../../../assets';
import { getSendButtonTemplate, getThankYouTemplate } from '../formConsructor/constructorFormFields';
import RadioButton from '../../../components/form/radioButton/RadioButton';
import { Helmet } from 'react-helmet-async';
import {
  LeavePageModal, Loader, SubHeader, TransparentButton,
} from '../../../components';

const radioButtonBorderMaxWidth = 5;
const radioButtonCheckedBorderMaxRadius = 30;
const radioButtonCheckedMaxSizes = 28;

interface IAdditionalFormProps {
  startPageButtonAvailablePercentWidth: string[];
  dividingDecorativeLineAvailablePercentWidth: string[];
}

export default function FormInfo() {
  const { companyId, nodeId, formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [formName, setFormName] = useState('');
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<IForm & IAdditionalFormProps>();
  const [submitError, setSubmitError] = useState<string>('');
  const [companyLanguages, setCompanyLanguages] = useState<ILanguage[]>();
  const [companyImages, setCompanyImages] = useState<{ name: string, url: string, id: number }[]>();
  const [companyColors, setCompanyColors] = useState<{ name: string, color: string }[]>();
  const [companyFonts, setCompanyFonts] = useState<{ name: string, url: string }[]>();
  const [pathToNavigate, setPathToNavigate] = useState<string>('');

  const commonDataLoadingRef = useRef<boolean>(false);
  const formLoadingRef = useRef<boolean>(false);

  const { selectedCompany: company, selectedNode: node } = useAppSelector((state) => state.company);

  async function getCurrentNodeData(id: string) {
    await Api.getNodeById(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          dispatch(setSelectedNode(res.data));
        }
      });
  }

  async function getCommonData(id: string) {
    await Api.getCompanyById(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          dispatch(setSelectedCompany(res.data));
        }
      });

    await Api.getCompanyLanguages(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyLanguages(res.data);
        }
      });

    await Api.getCompanyMetadata(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyImages(res.data.filter((item) => item.key.startsWith('image_')).map((item) => {
            const imageParts = item.value.split('|||');
            return { name: imageParts[0], url: imageParts[1], id: item.id };
          }));
          setCompanyColors(res.data.filter((item) => item.key.startsWith('color_')).map((item) => {
            const colorParts = item.value.split('|||');
            return { name: colorParts[0], color: colorParts[1] };
          }));
          setCompanyFonts(res.data.filter((item) => item.key.startsWith('font_')).map((item) => {
            const fontParts = item.value.split('|||');
            return { name: fontParts[0], url: fontParts[1] };
          }));
        }
      });
  }

  useEffect(() => {
    if (!company) {
      commonDataLoadingRef.current = true;
      getCommonData(companyId!).finally(() => {
        commonDataLoadingRef.current = false;
      });
    }
  }, [companyId]);

  useEffect(() => {
    if (!node) {
      commonDataLoadingRef.current = true;
      getCurrentNodeData(nodeId!).finally(() => {
        commonDataLoadingRef.current = false;
      });
    }
  }, [nodeId]);

  useEffect(() => {
    if (companyColors) {
      if (companyId && formId && !formLoadingRef.current) {
        formLoadingRef.current = true;

        Api.getFormById(+formId)
          .then((res) => {
            if (res.statusCode === 200) {
              setFormName(res.data.name || '');

              setInitialValues({
                logo: res.data.logo,
                font: res.data.font,
                logoLocation: res.data.logoLocation,
                name: res.data.name || '',
                colors: arrayUniqueByKey<IColor>('name', res.data.backgroundColor ? [...companyColors, res.data.backgroundColor] : companyColors),
                backgroundColor: res.data.backgroundColor,
                elementsColor: res.data.elementsColor,
                mainMascot: res.data.mainMascot,
                anonymous: res.data.anonymous,
                isDefault: res.data.isDefault,
                status: res.data.status,
                language_id: res.data.language_id,
                companyID: +companyId!,
                node_id: +nodeId!,
                withSteps: res.data.withSteps,
                showNodeCode: res.data.showNodeCode,
                startPageButtonPercentWidth: res.data.startPageButtonPercentWidth,
                startPageButtonAvailablePercentWidth: ['100', '50', '30'],
                radioButtonBorderWidth: res.data.radioButtonBorderWidth || 1,
                radioButtonCheckedBorderRadius: res.data.radioButtonCheckedBorderRadius || 22,
                radioButtonCheckedSizes: res.data.radioButtonCheckedSizes || 18,
                notCheckedElementsColor: res.data.notCheckedElementsColor,
                stepsTextUnderlineColor: res.data.stepsTextUnderlineColor,
                stepsTextColor: res.data.stepsTextColor,
                showDividingDecorativeLine: res.data.showDividingDecorativeLine,
                dividingDecorativeLineAvailablePercentWidth: ['90', '60', '30'],
                dividingDecorativeLinePercentWidth: res.data.dividingDecorativeLinePercentWidth,
                dividingDecorativeLineHeight: res.data.dividingDecorativeLineHeight || 2,
                dividingDecorativeLineColor: res.data.dividingDecorativeLineColor,
                setFontWeightBold: res.data.setFontWeightBold,
              });
            }
          })
          .finally(() => {
            formLoadingRef.current = false;
          });
      } else if (!formLoadingRef.current && !commonDataLoadingRef.current && companyLanguages) {
        setInitialValues(
          {
            logo: undefined,
            font: undefined,
            logoLocation: 'left',
            name: '',
            colors: arrayUniqueByKey<IColor>('name', companyColors),
            backgroundColor: undefined,
            elementsColor: undefined,
            mainMascot: undefined,
            anonymous: false,
            isDefault: false,
            status: false,
            language_id: companyLanguages[0].id!,
            companyID: +companyId!,
            node_id: +nodeId!,
            withSteps: false,
            showNodeCode: true,
            startPageButtonPercentWidth: '100',
            startPageButtonAvailablePercentWidth: ['100', '50', '30'],
            radioButtonBorderWidth: 1,
            radioButtonCheckedBorderRadius: 22,
            radioButtonCheckedSizes: 18,
            notCheckedElementsColor: undefined,
            stepsTextUnderlineColor: undefined,
            stepsTextColor: undefined,
            showDividingDecorativeLine: false,
            dividingDecorativeLineAvailablePercentWidth: ['90', '60', '30'],
            dividingDecorativeLinePercentWidth: '90',
            dividingDecorativeLineHeight: 2,
            dividingDecorativeLineColor: undefined,
            setFontWeightBold: false,
          },
        );
      }
    } else if (!companyColors) {
      if (!commonDataLoadingRef.current) {
        commonDataLoadingRef.current = true;
        try {
          getCommonData(companyId!);
        } catch (e) {
          console.warn('Error loading common data', e);
        } finally {
          commonDataLoadingRef.current = false;
        }
      }
    }
  }, [companyId, formId, formLoadingRef.current, commonDataLoadingRef.current, companyLanguages, companyColors]);

  function handleCancel() {
    navigate(`/companies/${companyId}/nodes/${nodeId}/forms`);
  }

  function handleResult(success: boolean) {
    if (success) {
      dispatch(setNotSavedForm(undefined));
      handleCancel();
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }
  }

  async function onSubmit(
    values: IForm & IAdditionalFormProps,
    { setSubmitting }: FormikHelpers<IForm & IAdditionalFormProps>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const data = {
      name: values.name,
      isDefault: values.isDefault,
      anonymous: values.anonymous,
      status: values.status,
      language_id: values.language_id,
      logo: values.logo,
      font: values.font,
      logoLocation: values.logoLocation,
      backgroundColor: values.backgroundColor,
      elementsColor: values.elementsColor,
      mainMascot: values.mainMascot,
      companyID: +companyId!,
      node_id: +nodeId!,
      withSteps: values.withSteps,
      showNodeCode: values.showNodeCode,
      startPageButtonPercentWidth: values.startPageButtonPercentWidth,
      radioButtonBorderWidth: values.radioButtonBorderWidth,
      radioButtonCheckedBorderRadius: values.radioButtonCheckedBorderRadius,
      radioButtonCheckedSizes: values.radioButtonCheckedSizes,
      notCheckedElementsColor: values.notCheckedElementsColor,
      stepsTextUnderlineColor: values.stepsTextUnderlineColor,
      stepsTextColor: values.stepsTextColor,
      showDividingDecorativeLine: values.showDividingDecorativeLine,
      dividingDecorativeLinePercentWidth: values.dividingDecorativeLinePercentWidth,
      dividingDecorativeLineHeight: values.dividingDecorativeLineHeight,
      dividingDecorativeLineColor: values.dividingDecorativeLineColor,
      setFontWeightBold: values.setFontWeightBold,
    };

    if (formId) {
      const formFields = await Api.getFormFields(+formId!);
      const res = await Api.editCompanyForm({
        ...data,
        id: +formId!,
        fieldID: data?.withSteps ? (formFields.data.extraFields || [])[0]?.id : undefined,
        sendButtonTextColor: formFields.data.sendButton?.textColor,
        sendButtonBackgroundColor: formFields.data.sendButton?.backgroundColor,
        sendButtonDisabledTextColor: formFields.data.sendButton?.sendButtonDisabledTextColor,
        sendButtonDisabledBackgroundColor: formFields.data.sendButton?.sendButtonDisabledBackgroundColor,
      });
      handleResult(res.statusCode >= 200 && res.statusCode < 300);
    } else {
      const res = await Api.createFeedbackForm(data);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        await Api.createThankYouPage(getThankYouTemplate(interfaceLanguage, res.data.id, '1').config);
        await Api.createFormSendButton(getSendButtonTemplate(interfaceLanguage, res.data.id, '2').config);
      }

      handleResult(res.statusCode >= 200 && res.statusCode < 300);
    }
  }

  function getColorStringValue(color: string | { r: number; g: number; b: number; a: number; }) {
    return typeof color === 'object'
      ? `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`
      : color;
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IForm & IAdditionalFormProps>) {
    return (
      <Form>
        <div className="commonInfo">
          <InputField
            extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
            name="name"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_form_name_placeholder')}
            value={values.name}
            error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
            label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_form_name_label')}
            required
          />

          <Checkbox
            label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_status_label')}
            name="status"
            value={values.status}
            onChange={handleChange}
            error={errors.status || (typeof errorMessage === 'object' ? getErrorMessage('status', errorMessage) : undefined)}
          >
            <span>{values.status ? getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_status_published') : getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_status_not_published')}</span>
          </Checkbox>

          <Checkbox
            name="isDefault"
            value={values.isDefault}
            onChange={handleChange}
            error={errors.isDefault || (typeof errorMessage === 'object' ? getErrorMessage('isDefault', errorMessage) : undefined)}
          >
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_use_as_default_form')}</span>
          </Checkbox>

          {companyLanguages && (
            <CustomSelect
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_language_label')}
              name="language"
              // property="icon"
              selectKey="name"
              options={companyLanguages}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_language_placeholder')}
              value={companyLanguages.find((lang) => lang.id === values.language_id)}
              handleSelect={(language) => setFieldValue('language_id', language.id)}
              formGroupStyles={{ maxWidth: 300, width: '100%' }}
              search
            />
          )}

          <Checkbox
            name="withSteps"
            value={values.withSteps}
            onChange={handleChange}
            error={errors.withSteps || (typeof errorMessage === 'object' ? getErrorMessage('withSteps', errorMessage) : undefined)}
          >
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_with_steps')}</span>
          </Checkbox>

          <Checkbox
            name="showNodeCode"
            value={values.showNodeCode}
            onChange={handleChange}
            error={errors.showNodeCode || (typeof errorMessage === 'object' ? getErrorMessage('showNodeCode', errorMessage) : undefined)}
          >
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_show_node_code')}</span>
          </Checkbox>
        </div>

        <div className="horizontalLine" />

        <div className="topSection">
          <div className="addImageContainer">
            <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_logo_label')}</h2>
            <div className="addImageWrapper">
              <div className={values.logo ? 'imageContainer' : 'imageContainer default'}>
                {values.logo ? (
                  <img
                    className="selectedImage"
                    src={values.logo.url}
                    alt={values.logo.name}
                  />
                ) : (
                  <img
                    src={DefaultCompanyImage}
                    alt=""
                  />
                )}
              </div>

              {companyImages && (
                <div className="uploadButtonFieldContainer">
                  <p className="description">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_logo_title')}</p>
                  <SelectWithAnyItems
                    name="images"
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_logo_button')}
                    options={companyImages || []}
                    value={values.logo}
                    handleSelect={(image) => setFieldValue('logo', image)}
                    formGroupStyles={{ width: '300', marginBottom: 0 }}
                    hideErrors
                    renderItem={(option) => (
                      <div className="imageOptionItem">
                        <div className="imageContainer">
                          <img src={option.url} alt={option.name} />
                        </div>

                        <p>{option.name}</p>
                      </div>
                    )}
                    renderSelect={(value) => <p>{value.name}</p>}
                    optionExtraClassName="imageOption"
                    optionsExtraClassName="imageOptions"
                    search
                  />
                </div>
              )}
            </div>
          </div>
          <div className="buttonsContainer">
            <div className="selectLogoLocationContainer">
              <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_logo_place_label')}</h2>
              <div className="locationButtons">
                <button
                  type="button"
                  className={values.logoLocation === 'left' ? 'locationButton active' : 'locationButton'}
                  onClick={() => setFieldValue('logoLocation', 'left')}
                >
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_logo_place_left')}</span>
                </button>
                <button
                  type="button"
                  className={values.logoLocation === 'center' ? 'locationButton active' : 'locationButton'}
                  onClick={() => setFieldValue('logoLocation', 'center')}

                >
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_logo_place_center')}</span>
                </button>
                <button
                  type="button"
                  className={values.logoLocation === 'right' ? 'locationButton active' : 'locationButton'}
                  onClick={() => setFieldValue('logoLocation', 'right')}

                >
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_logo_place_right')}</span>
                </button>
              </div>
            </div>
          </div>

          <div className="selectBackgroundContainer">
            <h2>
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_background_color_title')}
              <span className="required"> *</span>
            </h2>
            <div style={{ width: 300 }}>
              <CustomColorsSelect
                name="backgroundColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={values.colors!}
                value={values.backgroundColor}
                handleSelect={(color) => setFieldValue('backgroundColor', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  setFieldValue(`colors[${values.colors!.length}]`, color);
                  setFieldValue('backgroundColor', color);
                }}
              />
            </div>
          </div>

          <div className="horizontalLine" />

          <div className="selectBackgroundContainer">
            <div className="elementsContainer">
              <div
                className="customizedRadioItem"
                style={{
                  width: +(values.radioButtonCheckedBorderRadius || 22),
                  height: +(values.radioButtonCheckedBorderRadius || 22),
                  border: `${values.radioButtonBorderWidth || 1}px solid ${values.elementsColor ? getColorStringValue(values.elementsColor.color) : '#0E9285'}`,
                }}
              >
                <div
                  className="checkedItem"
                  style={{
                    width: +(values.radioButtonCheckedSizes || 18),
                    height: +(values.radioButtonCheckedSizes || 18),
                    backgroundColor: values.elementsColor ? getColorStringValue(values.elementsColor.color) : '#0E9285',
                  }}
                />
              </div>

              <div
                className="customizedCheckboxItem"
                style={{
                  width: +(values.radioButtonCheckedBorderRadius || 22),
                  height: +(values.radioButtonCheckedBorderRadius || 22),
                  border: `${values.radioButtonBorderWidth || 1}px solid ${values.elementsColor ? getColorStringValue(values.elementsColor.color) : '#0E9285'}`,
                }}
              >
                <CheckMark color={values.elementsColor ? getColorStringValue(values.elementsColor.color) : '#0E9285'} />
              </div>

              <div
                className="customizedRadioItem"
                style={{
                  width: +(values.radioButtonCheckedBorderRadius || 22),
                  height: +(values.radioButtonCheckedBorderRadius || 22),
                  border: `${values.radioButtonBorderWidth || 1}px solid ${values.notCheckedElementsColor ? getColorStringValue(values.notCheckedElementsColor.color) : '#eee'}`,
                }}
              />

              <div
                className="customizedCheckboxItem"
                style={{
                  width: +(values.radioButtonCheckedBorderRadius || 22),
                  height: +(values.radioButtonCheckedBorderRadius || 22),
                  border: `${values.radioButtonBorderWidth || 1}px solid ${values.notCheckedElementsColor ? getColorStringValue(values.notCheckedElementsColor.color) : '#eee'}`,
                }}
              />
            </div>

            <h2>
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_elements_color_title')}
              <span className="required"> *</span>
            </h2>

            <div style={{ width: 300 }}>
              <CustomColorsSelect
                name="elementsColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={values.colors!}
                value={values.elementsColor}
                handleSelect={(color) => setFieldValue('elementsColor', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  setFieldValue(`colors[${values.colors!.length}]`, color);
                  setFieldValue('elementsColor', color);
                }}
                required
              />
            </div>

            <h2>
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_not_checked_elements_color_title')}
              <span className="required"> *</span>
            </h2>

            <div style={{ width: 300 }}>
              <CustomColorsSelect
                name="notCheckedElementsColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={values.colors!}
                value={values.notCheckedElementsColor}
                handleSelect={(color) => setFieldValue('notCheckedElementsColor', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  setFieldValue(`colors[${values.colors!.length}]`, color);
                  setFieldValue('notCheckedElementsColor', color);
                }}
                required
              />
            </div>

            <InputField
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_elements_border_width_title')}
              type="number"
              min={1}
              max={radioButtonBorderMaxWidth}
              extraBlockStyles={{ width: '100%', maxWidth: '250px' }}
              name="radioButtonBorderWidth"
              onChange={(key, value) => setFieldValue(key, value)}
              onKeyUp={() => handleKeyUp('radioButtonBorderWidth', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_label')}
              value={values.radioButtonBorderWidth}
              error={typeof errorMessage === 'object' ? getErrorMessage('radioButtonBorderWidth', errorMessage) : undefined}
            />

            <InputField
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_checked_elements_border_radius_title')}
              type="number"
              min={7}
              max={radioButtonCheckedBorderMaxRadius}
              extraBlockStyles={{ width: '100%', maxWidth: '250px' }}
              name="radioButtonCheckedBorderRadius"
              onChange={(key, value) => setFieldValue(key, value)}
              onKeyUp={() => handleKeyUp('radioButtonCheckedBorderRadius', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_label')}
              value={values.radioButtonCheckedBorderRadius}
              error={typeof errorMessage === 'object' ? getErrorMessage('radioButtonCheckedBorderRadius', errorMessage) : undefined}
            />

            <InputField
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_checked_elements_checked_size_title')}
              type="number"
              min={3}
              max={radioButtonCheckedMaxSizes}
              extraBlockStyles={{ width: '100%', maxWidth: '250px' }}
              name="radioButtonCheckedSizes"
              onChange={(key, value) => setFieldValue(key, value)}
              onKeyUp={() => handleKeyUp('radioButtonCheckedSizes', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_label')}
              value={values.radioButtonCheckedSizes}
              error={typeof errorMessage === 'object' ? getErrorMessage('radioButtonCheckedSizes', errorMessage) : undefined}
            />
          </div>

          <div className="horizontalLine" />

          <div className="addImageContainer">
            <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_main_mascot_label')}</h2>
            <div className="addImageWrapper">
              <div className={values.mainMascot ? 'imageContainer' : 'imageContainer default'}>
                {values.mainMascot ? (
                  <img
                    className="selectedImage"
                    src={values.mainMascot.url}
                    alt={values.mainMascot.name}
                  />
                ) : (
                  <img
                    src={DefaultCompanyImage}
                    alt=""
                  />
                )}
              </div>

              {companyImages && (
                <div className="uploadButtonFieldContainer">
                  <p className="description">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_main_mascot_title')}</p>
                  <div className="mainMascotSelectorContainer">
                    <SelectWithAnyItems
                      name="images"
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_main_mascot_button')}
                      options={companyImages || []}
                      value={values.mainMascot}
                      handleSelect={(image) => setFieldValue('mainMascot', image)}
                      formGroupStyles={{ width: '600', marginBottom: 0 }}
                      hideErrors
                      renderItem={(option) => (
                        <div className="imageOptionItem">
                          <div className="imageContainer">
                            <img src={option.url} alt={option.name} />
                          </div>

                          <p>{option.name}</p>
                        </div>
                      )}
                      renderSelect={(value) => <p>{value.name}</p>}
                      optionExtraClassName="imageOption"
                      optionsExtraClassName="imageOptions"
                      search
                      extraStyles={{ width: '90%' }}
                    />
                    {values.mainMascot && (
                    <button
                      type="button"
                      onClick={() => { setFieldValue('mainMascot', undefined); }}
                    >
                      <DeleteIcon />
                    </button>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="startPageButtonWidth">
            <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_start_page_button_width')}</h3>

            <FieldArray
              name="startPageButtonAvailablePercentWidth"
              render={({ insert, remove, push }) => (
                <div className="optionsHorizontal">
                  {values.startPageButtonAvailablePercentWidth.map((value, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="radioFieldContainer" key={value}>
                      <RadioButton
                        name={`startPageButtonAvailablePercentWidth[${index}]`}
                        value={values.startPageButtonAvailablePercentWidth[index]}
                        onChange={(event) => setFieldValue('startPageButtonPercentWidth', event.target.value)}
                        checked={values.startPageButtonPercentWidth === value}
                      >
                        <div className="radioText">
                          {`${value}%`}
                        </div>
                      </RadioButton>
                    </div>
                  ))}
                </div>
              )}
            />
          </div>

          <div className="horizontalLine" />

          <div className="dividingDecorativeLineSettingsBlock">
            <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_dividing_decorative_line')}</h3>

            <Checkbox
              name="showDividingDecorativeLine"
              value={values.showDividingDecorativeLine}
              onChange={handleChange}
              error={errors.showDividingDecorativeLine || (typeof errorMessage === 'object' ? getErrorMessage('showDividingDecorativeLine', errorMessage) : undefined)}
            >
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_show_form_dividing_decorative_line')}</span>
            </Checkbox>

            <div className="dividingDecorativeLineWidthStyle">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_dividing_decorative_line_width_in_percent')}</span>
              <FieldArray
                name="dividingDecorativeLineAvailablePercentWidth"
                render={(helpers) => (
                  <div className="optionsHorizontal">
                    {values.dividingDecorativeLineAvailablePercentWidth.map((value, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <div className="radioFieldContainer" key={value}>
                        <RadioButton
                          disabled={!values.showDividingDecorativeLine}
                          name={`dividingDecorativeLineAvailablePercentWidth[${index}]`}
                          value={values.dividingDecorativeLineAvailablePercentWidth[index]}
                          onChange={(event) => setFieldValue('dividingDecorativeLinePercentWidth', event.target.value)}
                          checked={values.dividingDecorativeLinePercentWidth === value}
                        >
                          <div className="radioText">
                            {`${value}%`}
                          </div>
                        </RadioButton>
                      </div>
                    ))}
                  </div>
                )}
              />
            </div>
            <div className="dividingDecorativeLineHeightStyle">
              <InputField
                label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_dividing_decorative_line_height')}
                name="dividingDecorativeLineHeight"
                type="number"
                min={1}
                max={10}
                extraBlockStyles={{ width: '100%' }}
                onChange={(key, value) => setFieldValue(key, value)}
                onKeyUp={() => handleKeyUp('dividingDecorativeLineHeight', setErrorMessage, errorMessage)}
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_dividing_decorative_line_height_placeholder')}
                value={values.dividingDecorativeLineHeight}
                error={typeof errorMessage === 'object' ? getErrorMessage('dividingDecorativeLineHeight', errorMessage) : undefined}
                disabled={!values.showDividingDecorativeLine}
              />
            </div>

            <div className="dividingDecorativeLineColorStyle">
              <CustomColorsSelect
                label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_dividing_decorative_line_color')}
                name="dividingDecorativeLineColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_dividing_decorative_line_color_placeholder')}
                options={values.colors!}
                value={values.dividingDecorativeLineColor}
                handleSelect={(color) => setFieldValue('dividingDecorativeLineColor', color)}
                handleAddColor={(color) => {
                  setFieldValue(`colors[${values.colors!.length}]`, color);
                  setFieldValue('dividingDecorativeLineColor', color);
                }}
                extraComponentPosition="left"
                disabled={!values.showDividingDecorativeLine}
              />
            </div>
          </div>

          <div className="horizontalLine" />

          <div className="addImageContainer">
            <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_form_font_title')}</h2>

            <CustomSelect
              name="font"
              options={companyFonts || []}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_form_font_placeholder')}
              value={values.font}
              handleSelect={(value) => setFieldValue('font', value)}
              search
              hideError
              emptyOption
              emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')}
              formGroupStyles={{ width: '100%', maxWidth: '250px' }}
            />

            <h2>Font weight</h2>
            <Checkbox
              name="setFontWeightBold"
              value={values.setFontWeightBold ? values.setFontWeightBold : false}
              onChange={handleChange}
              error={errors.setFontWeightBold || (typeof errorMessage === 'object' ? getErrorMessage('showDividingDecorativeLine', errorMessage) : undefined)}
            >
              <span>Font weight bold</span>
            </Checkbox>

          </div>

          <div className="horizontalLine" />

          <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_step_text_color')}</h2>
          <div style={{ width: 300 }}>
            <CustomColorsSelect
              name="stepsTextColor"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_step_text_color')}
              options={values.colors!}
              value={values.stepsTextColor}
              handleSelect={(color) => setFieldValue('stepsTextColor', color)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              extraComponentPosition="left"
              handleAddColor={(color) => {
                setFieldValue(`colors[${values.colors!.length}]`, color);
                setFieldValue('stepsTextColor', color);
              }}
              disabled={!values.withSteps}
            />
          </div>

          <div className="horizontalLine" />

          <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_step_text_underline_color')}</h2>
          <div style={{ width: 300 }}>
            <CustomColorsSelect
              name="stepsTextUnderlineColor"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_step_text_underline_color_placeholder')}
              options={values.colors!}
              value={values.stepsTextUnderlineColor}
              handleSelect={(color) => setFieldValue('stepsTextUnderlineColor', color)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              extraComponentPosition="left"
              handleAddColor={(color) => {
                setFieldValue(`colors[${values.colors!.length}]`, color);
                setFieldValue('stepsTextUnderlineColor', color);
              }}
              disabled={!values.withSteps}
            />
          </div>
        </div>

        <div className="horizontalLine" />

        <div className="extraFieldListContainer">
          <div className="bottomButtonsContainer">
            <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'save_form_button')}</SubmitButton>
          </div>
        </div>

        <p className="uploadError">{submitError}</p>
      </Form>
    );
  }

  function showWarningModal(path?: string) {
    setPathToNavigate(path || '');
  }

  return (
    <FormInfoStyles>
      <Helmet>
        <title>{`Basic form info ${formName} Voicer`}</title>
      </Helmet>

      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_info_title')}
        pathArray={[
          ...(company ? [{ name: company.name!, route: `/companies/${companyId}/nodes`, callback: showWarningModal }] : []),
          ...(node ? [{ name: node.name!, route: `/companies/${companyId}/nodes/${nodeId}/forms`, callback: showWarningModal }] : []),
          ...(formName ? [{ name: formName }] : []),
        ]}
      >
        <TransparentButton
          handleClick={handleCancel}
          text={formId ? getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button') : getTranslationByLangOrEng(interfaceLanguage, 'close_button')}
        />
      </SubHeader>

      {!initialValues ? <Loader /> : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}

      {pathToNavigate && <LeavePageModal setPathToNavigate={setPathToNavigate} pathToNavigate={pathToNavigate} />}
    </FormInfoStyles>
  );
}
