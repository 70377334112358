import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const PhotoWithPlusIcon: FC<TIcon> = React.memo(({ width = 512, height = 512, color = '#2A9E93' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M2.667 3.333h8.666V8h1.334V3.333c0-.735-.598-1.333-1.333-1.333H2.667c-.735 0-1.333.598-1.333 1.333v8c0 .736.597 1.334 1.333 1.334H8v-1.334H2.667v-8z"
    />
    <path
      fill={color}
      d="M5.333 7.333l-2 2.667h7.334L8 6 6 8.667l-.667-1.334zM12.667 9.336h-1.333v2h-2v1.333h2v2h1.333v-2h2v-1.333h-2v-2z"
    />
  </svg>
))
