import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const CheckMark: FC<TIcon> = React.memo(({ width = 18, height = 14, color = '#0E9285' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 18 14"
  >
    <path
      fill={color}
      d="M5.965 10.44L1.967 6.294.25 8.074 5.965 14 17.75 1.78 16.033 0 5.965 10.44z"
    />
  </svg>
));
