import React, {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { SearchIcon } from '../../../../assets';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { DeepArrayItem } from '../../../../entities/DeepArrayItem';
import { filterDeepObjectArray, hasNonEmptyChildren } from '../../../../utils';
import { useAppSelector } from '../../../../state';
import { MultiSelectWithChildrenStyles } from './MultiSelectWithChildrenStyles';
import MultiSelectItem from '../multiSelectItem/MultiSelectItem';
import { expandAllSelectedItemsAndTheirParents, expandSearchedItemsAndTheirParents } from './utils';
import { useDebounce } from '../../../../hooks/useDebounce';
import { FormErrorMessage, Loader } from '../../../atoms';

interface MultiSelectWithChildrenProps {
  label?: string;
  name: string;
  options: any[];
  selectKey: string;
  handleSelect: (value: any, path?: number[], filtered?: boolean) => any;
  property?: string;
  search?: boolean;
  formGroupStyles?: CSSProperties;
  optionsContainerStyles?: string;
  hideError?: boolean;
  selectError?: string;
  selectErrorName?: string;
  required?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

export default function MultiSelectWithChildren({
  label,
  name,
  options,
  selectKey,
  property,
  handleSelect,
  formGroupStyles,
  optionsContainerStyles,
  search,
  hideError,
  selectError,
  selectErrorName,
  required,
  isLoading,
  disabled,
}: MultiSelectWithChildrenProps) {
  const { errors, touched } = useFormikContext();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState(true);
  const [searchParams, setSearchParams] = useState<string>('');
  const [nodesExpanded, setNodesExpanded] = useState<{[key: number]: boolean}>({});
  const [nodesFiltered, setNodesFiltered] = useState<boolean>(false);
  const [selected, setSelected] = useState<boolean>(false);

  const optionsListRef = useRef<HTMLDivElement>(null);
  const nodesExpandedRef = useRef<{[key: number]: boolean}>({});
  const nodesFilteredRef = useRef<DeepArrayItem[]>([]);

  const debouncedSearchParams = useDebounce(searchParams, 1500);

  function addToExpandedItemsList(id: number) {
    setNodesExpanded({ ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] });
    nodesExpandedRef.current = { ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] };
  }

  function clearExpandedItemsList() {
    setNodesExpanded({});
    nodesExpandedRef.current = {};
  }

  useEffect(() => {
    nodesFilteredRef.current = options;
    setNodesFiltered(!nodesFiltered);
  }, [options]);

  const handleExpandRow = React.useCallback((id: number) => {
    if (nodesExpandedRef.current) {
      addToExpandedItemsList(id);
    }
  }, []);

  useEffect(() => {
    if (options) {
      if (search && debouncedSearchParams) {
        const nodesTree = filterDeepObjectArray(options, debouncedSearchParams, selectKey);
        nodesFilteredRef.current = nodesTree;
        setNodesFiltered(!nodesFiltered);
        clearExpandedItemsList();
        expandSearchedItemsAndTheirParents(nodesTree, addToExpandedItemsList);
      } else {
        nodesFilteredRef.current = [...options];
        setNodesFiltered(!nodesFiltered);
        clearExpandedItemsList();
        expandAllSelectedItemsAndTheirParents(options, addToExpandedItemsList);
      }
    }
  }, [debouncedSearchParams]);

  useEffect(() => {
    setSearchParams('');
  }, [open]);

  function handleSelectItem(value: any, path?: number[]) {
    handleSelect(value, path || undefined, !!searchParams);
    setSelected(!selected);
  }

  return (
    <MultiSelectWithChildrenStyles>
      <div
        className={`form-group${
        // @ts-ignore
          touched[name] && !errors[name]
            ? ' valid'
          // @ts-ignore
            : touched[name] && errors[name]
              ? ' error'
              : ''
        }`}
        style={formGroupStyles || {}}
      >
        {label
          && (
            <div className="labelContainer">
              <label htmlFor={name}>
                {label}
                {required && <span className="required"> *</span>}
              </label>
            </div>
          )}
      </div>
      {(isLoading || disabled) && (
        <div className="loading">
          {isLoading && <Loader margin={0} height={20} />}
        </div>
      )}

      {open && (
        <div
          className={`options${optionsContainerStyles ? ` ${optionsContainerStyles}` : ''}`}
          ref={optionsListRef}
        >
          {search
            && (
              <div className="searchWrapper">
                <SearchIcon />
                <input
                  type="text"
                  value={searchParams}
                  onChange={(e) => setSearchParams(e.target.value)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
                />
              </div>
            )}
          {nodesExpandedRef.current && nodesFilteredRef.current.map((option, index) => {
            const visibleArrow = hasNonEmptyChildren(option.children)
            return (
              <MultiSelectItem
                key={`itemKey:${[index]}`}
                path={[index]}
                option={option}
                selectKey={selectKey}
                childrenKey="children"
                inputName={name}
                expandedRows={nodesExpandedRef.current}
                expandable={visibleArrow && option.children.length > 0}
                handleExpandRow={handleExpandRow}
                imageProperty={property}
                handleSelect={handleSelectItem}
                setOpen={setOpen}
                selectable
                checkboxSelected={option.selected}
                checkboxHasSelectedChildren={option.hasSelectedChildren}
                checkboxAllChildrenSelected={option.allChildrenAreSelected}
              />
            )
          })}
        </div>
      )}

      {!hideError && (
        <div className="selectErrorContainer">
          {selectError && <span>{selectError}</span>}
          {!selectError && touched && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
        </div>
      )}
    </MultiSelectWithChildrenStyles>
  );
}
