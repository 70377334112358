import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { RegistrationStyles } from './RegistrationStyles';
import InputField from '../../components/form/inputField/InputField';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector, registerUser } from '../../state';
import { phoneRegExp } from '../../constants';
import { getErrorMessage, handleKeyUp } from '../../utils';

interface FormValues {
  email: string;
  firstName: string;
  lastName: string;
  middleName: string;
  password: string;
  confirmPassword: string;
  phoneNumber:string;
}

const initialValues: FormValues = {
  email: '',
  firstName: '',
  lastName: '',
  middleName: '',
  password: '',
  confirmPassword: '',
  phoneNumber: '',
};

export const Registration = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});

  const { data, status, statusCode } = useAppSelector((state) => state.user);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email('* Неверный формат электронной почты')
      .required('* Обязательно'),
    firstName: Yup.string()
      .max(12, '* Должно быть менее 12 символов')
      .matches(/^[a-z ]+$/i, '* Имя должно содержать только буквы и пробелы')
      .required('* Обязательно'),
    lastName: Yup.string()
      .max(12, '* Должно быть менее 12 символов')
      .matches(/^[a-z ]+$/i, '* Фамилия должна содержать только буквы и пробелы')
      .required('* Обязательно'),
    middleName: Yup.string()
      .max(12, '* Должно быть менее 12 символов')
      .matches(/^[a-z ]+$/i, '* Отчество должно содержать только буквы и пробелы')
      .required('* Обязательно'),
    password: Yup.string()
      .min(6, '* Должно быть не менее 6 символов')
      .max(16, '* Должно быть менее 16 символов')
      .matches(
        /^(?=.*?[a-z])(?=.*?[0-9]).{1,}$/,
        '* Должен содержать буквы, цифры и символы #?!@$%^&*-',
      )
      .required('* Обязательно'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('* Обязательно'),
    phoneNumber: Yup.string().matches(phoneRegExp, 'Номер телефона не валидный'),
  });

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);
    setIsLoading(true);
    dispatch(registerUser(values));
  }

  useEffect(() => {
    if (status === 'idle' && !!data && statusCode === 200) {
      navigate('/');
    } else if (data) {
      setErrorMessage(data);
    }
  }, [data, status]);

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="inputs">
        <InputField
          name="phoneNumber"
          type="phone"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('confirmPassword', setErrorMessage, errorMessage)}
          placeholder="Введите номер телефона"
          value={values.phoneNumber}
          error={typeof errorMessage === 'object' ? getErrorMessage('phoneNumber', errorMessage) : undefined}
          label="Номер телефона"
          required
        />

        <InputField
          name="email"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)}
          placeholder="Введите почту"
          value={values.email}
          error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined}
          label="Email"
          required
        />

        <InputField
          name="firstName"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('firstName', setErrorMessage, errorMessage)}
          placeholder="Введите имя сотрудника"
          value={values.firstName}
          error={typeof errorMessage === 'object' ? getErrorMessage('firsName', errorMessage) : undefined}
          label="Имя"
        />

        <InputField
          name="lastName"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('lastName', setErrorMessage, errorMessage)}
          placeholder="Введите фамилию сотрудника"
          value={values.lastName}
          error={typeof errorMessage === 'object' ? getErrorMessage('lastName', errorMessage) : undefined}
          label="Фамилия"
        />

        <InputField
          name="middleName"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('middleName', setErrorMessage, errorMessage)}
          placeholder="Введите отчество сотрудника"
          value={values.middleName}
          error={typeof errorMessage === 'object' ? getErrorMessage('middleName', errorMessage) : undefined}
          label="Отчество"
        />
        <div />
        <InputField
          name="password"
          type="password"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('password', setErrorMessage, errorMessage)}
          placeholder="Введите пароль"
          value={values.password}
          error={typeof errorMessage === 'object' ? getErrorMessage('password', errorMessage) : undefined}
          label="Пароль"
          required
        />

        <InputField
          name="confirmPassword"
          type="password"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('confirmPassword', setErrorMessage, errorMessage)}
          placeholder="Повторите введеный пароль"
          value={values.confirmPassword}
          error={typeof errorMessage === 'object' ? getErrorMessage('confirmPassword', errorMessage) : undefined}
          label="Повторить пароль"
          required
        />
      </div>

      <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>Зарегистрироваться</SubmitButton>

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  return (
    <RegistrationStyles>
      <div className="formContainer">
        <div className="titleContainer">
          <h1>Регистрация</h1>
        </div>

        <div className="form__wrapper">
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {renderForm}
          </Formik>

          <div className="linkContainer">
            <span>Вы уже Voicer?</span>
            <Link to="/auth/login" className="form__link">Войти в аккаунт</Link>
          </div>
        </div>
      </div>
    </RegistrationStyles>
  );
}
