import styled from 'styled-components';

export const MassMailingsCreatingEmailStep3Styles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
  }

  .formContainer {
    padding: 16px 32px 32px 16px;
  }

  .infoWrapper {
    padding: 8px 8px;
    border-top: 1px solid #F0F0F0;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    display: flex;
    
    &.name {
      border-top: none;
      padding-top: 0;
    }

    span:nth-child(1) {
      margin-right: 8px;
    }

    span:nth-child(2) {
      font-weight: 600;
    }
  }
  
  .mailingInfoContainer {
    .infoWrapper {
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }

  .modalContent {
    border: 1px solid #e5e5e5;
    border-radius: 4px;
    margin-bottom: 24px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    position: relative;

    .desktopPreview {
      display: grid;
      grid-template-columns: 1fr;
      width: 100%;
      height: 100%;
      max-height: calc(100vh - 160px - 48px - 40px);
      overflow-y: scroll;
    }

    .phonePreview {
      table {
        width: 100% !important;
      }

      .previewBodyContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        transform: translate(-50%, -50%);
        width: 205px;
        height: 444px;
      }

      & > img {
        //box-shadow: 2px 2px 10px rgba(0,0,0,0,15);
        filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15)) drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.15));
      }
    }
  }

  .buttonsContainer {
    display: grid;
    gap: 24px;
    grid-template-columns: 250px 250px;
    
    & > div {
      margin: 0;
    }

    button {
      width: 100%;
    }
  }  
`;
