import { AddRoleStyles } from './AddRoleStyles';
import { getTranslationByLangOrEng, translations } from '../../i18n';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { formInitialValues, FormValues, validationSchema } from './formValuesAndValidation';
import { Api, ApiResponse } from '../../api';
import InputField from '../../components/form/inputField/InputField';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import CustomSelect from '../../components/form/customSelect/CustomSelect';
import { IUserPermission } from '../../entities';
import { availablePageSizes } from '../../constants';
import { useClickOutside } from '../../hooks/useClickOutside';
import { CheckMark, DeleteIcon, ThreeDots } from '../../assets';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { extraFieldsSettings } from './addRoleConsts';
import {
  Loader, SubHeader, Table, TransparentButton,
} from '../../components';

function NameCustomField({ name, logo, textColor }: {name: string, logo?: string, textColor?: string}) {
  return (
    <div className="customNameWrapper">
      {logo && (
        <div className={logo ? 'imageContainer' : 'imageContainer default'}>
          <div className="image">
            {logo && <img src={logo} alt="logo" />}
          </div>
        </div>
      )}
      <span style={{ color: textColor || '#000' }}>{name}</span>
    </div>
  );
}

function EditButtons({ lang, id, filterItemsAfterDelete }: {lang: string, id: number, filterItemsAfterDelete: (id: number) => void}) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);

  const menuRef = useRef<any>();

  useClickOutside(menuRef, () => setMenuOpen(false));

  return (
    <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        <button
          className="menuOpenerButton"
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
        >
          <ThreeDots />
        </button>
        {menuOpen && (
          <ul className="menuList">
            <li>
              <button
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  filterItemsAfterDelete(id);
                }}
              >
                <DeleteIcon />
                {' '}
                <span>{translations[lang].delete_button}</span>
              </button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
}

function StaticCheckBox({ value }: {value: boolean}) {
  return (
    <div className="checkboxContainer">
      <div className="checkboxWrapper">
        <div className={value ? 'checkboxSelected checkbox' : 'checkbox'}>
          {value && <CheckMark width={10} height={8} />}
        </div>
      </div>
    </div>
  );
}

export const AddRole = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [searchParams, setSearchParams] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [submitError, setSubmitError] = useState<string>('');
  const [itemsListChanged, setItemsListChanged] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>();
  const [loadingTable, setLoadingTable] = useState<boolean>(false);
  const [uploading, setUploading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [roleTableCurrentPage, setRoleTableCurrentPage] = useState<number>(0);
  const [init, setInit] = useState<boolean>(true);
  const [allItemsDownloaded, setAllItemsDownloaded] = useState<boolean>(false);
  const [selectedPermissionIds, setSelectedPermissionIds] = useState<number[]>([]);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const permissionsListRef = useRef<IUserPermission[]>();
  const selectedPermissionsRef = useRef<IUserPermission[]>([]);
  const itemsQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[0]);

  function workWithResponse(response: ApiResponse<IUserPermission[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newItems = response.data;

      permissionsListRef.current = [
        ...(permissionsListRef.current || []),
        ...(Array.isArray(newItems)
          ? newItems
          : [])];
      itemsQuantityRef.current = response.count!;
      setItemsListChanged(!itemsListChanged);
    }
  }

  useEffect(() => {
    if (!uploading && !init) {
      setInit(true);
      setUploading(true);
      Api.getPermissions(currentPage * pageSizeRef.current, pageSizeRef.current)
        .then((response) => {
          workWithResponse(response);
        })
        .finally(() => {
          setInit(false);
          setUploading(false);
        });
    }
  }, [currentPage]);

  useEffect(() => {
    setIsLoading(true);
    try {
      setCurrentPage(0);
      setInit(true);
      setUploading(true);
      Api.getPermissions(currentPage * pageSizeRef.current, pageSizeRef.current)
        .then((response) => {
          workWithResponse(response);
        })
        .finally(() => {
          setInit(false);
          setUploading(false);
        });

      if (id) {
        setLoadingTable(true);
        Api.getRole(+id).then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setInitialValues({
              name: res.data.name,
              // @ts-ignore
              permissions: res.data.permissions,
            });

            selectedPermissionsRef.current = res.data.permissions;
            setSelectedPermissionIds(res.data.permissions.map((item) => item.id!));
            setItemsListChanged(!itemsListChanged);
          }
        }).finally(() => {
          setLoadingTable(false);
        });
      } else {
        setInitialValues(formInitialValues);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  async function updatePermissionsList(roleId: number, permissions: IUserPermission[]) {
    const initialPermissions = initialValues?.permissions.map((item) => item.id) || [];
    const currentPermissions = permissions.map((item) => item.id);
    const permissionsToAttach: number[] = [];
    const permissionsToDetach: number[] = [];

    currentPermissions.forEach((item, index) => {
      if (!initialPermissions.includes(item)) {
        permissionsToAttach.push(item!);
      }
    });

    initialPermissions.forEach((item, index) => {
      if (!currentPermissions.includes(item)) {
        permissionsToDetach.push(item!);
      }
    });

    if (permissionsToAttach.length) {
      await Api.attachPermissionsToRole(roleId, permissionsToAttach);
    }
    if (permissionsToDetach.length) {
      await Api.detachPermissionsFromRole(roleId, permissionsToDetach);
    }
  }

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);

    let res;

    try {
      if (id) {
        res = await Api.updateRole(+id, { ...values, permissions: selectedPermissionsRef.current });
      } else {
        res = await Api.createRole({ ...values, permissions: selectedPermissionsRef.current });
      }

      await updatePermissionsList(id ? +id : res?.data?.id!, selectedPermissionsRef.current || []);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        navigate('/users/roles');
      }
    } catch (e) {
      console.log('ON SUBMIT ERROR', e);
    }
  }

  const filterItemsAfterDelete = useCallback((id: number) => {
    selectedPermissionsRef.current = [...(selectedPermissionsRef.current?.filter((item) => item.id !== id) || [])];
    setSelectedPermissionIds(selectedPermissionsRef.current!.map((item) => item.id!));
    setItemsListChanged(!itemsListChanged);
  }, []);

  const filteredItems = useMemo(() => selectedPermissionsRef.current?.filter(
    (permission) => permission.name!.toLowerCase().includes(searchParams.toLowerCase()),
  ), [searchParams, selectedPermissionsRef.current, uploading]);

  useEffect(() => {
    if (permissionsListRef.current?.length) {
      setTableHeaders([
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_permission_header'),
          accessor: 'permission',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField name={data.row.original.name} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_resource_header'),
          accessor: 'resource',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField name={data.row.original.resource} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_create_header'),
          accessor: 'create',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(0)} />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_read_header'),
          accessor: 'read',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(1)} />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_update_header'),
          accessor: 'update',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(2)} />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_delete_header'),
          accessor: 'delete',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(3)} />,
        },
        {
          Header: 'Edit buttons',
          accessor: 'editButtons',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <EditButtons lang={interfaceLanguage} id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
          ),
        },
      ]);
    }
  }, [permissionsListRef.current]);

  const sortableColumns = ['permission'];

  async function handleLoadMore() {
    if (!allItemsDownloaded && !uploading && !init) {
      setInit(true);
      setUploading(true);

      const r1 = await Api.getPermissions((currentPage + 1) * pageSizeRef.current, pageSizeRef.current);
      setCurrentPage(currentPage + 1);
      workWithResponse(r1);

      if (r1.data.length === 0) {
        setAllItemsDownloaded(true);
      }
      setInit(false);
      setUploading(false);
    }
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form id="roleForm">
      <div className="formSection">

        <InputField
          extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
          name="name"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'role_name_placeholder')}
          value={values.name}
          error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'role_name_title')}
          required
        />

        {!!permissionsListRef.current && (
          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'role_permissions')}
            name="permissions"
            // property="icon"
            selectKey="name"
            options={permissionsListRef.current}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'role_select_permissions_placeholder')}
            value=""
            handleSelect={(permission) => {
              selectedPermissionsRef.current = [...selectedPermissionsRef.current, permission];
              setItemsListChanged(!itemsListChanged);
            }}
            formGroupStyles={{ maxWidth: 300, width: '100%' }}
            search
            autoloadable
            handleAutoload={handleLoadMore}
            autoloading={uploading}
          />
        )}

        {loadingTable && <Loader />}

        {!loadingTable && !!selectedPermissionsRef.current && tableHeaders && (
          <Table
            pagination
            columns={tableHeaders}
            data={filteredItems.slice((roleTableCurrentPage - 1) * 10, selectedPermissionsRef.current.length)}
            hideFieldsSplitters
            extraFieldsSettings={extraFieldsSettings}
            headerColumnCounts={{ permission: selectedPermissionsRef.current.length }}
            rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_permission_title')}
            hiddenHeaders={['editButtons']}
            fullWidthColumns={['editButtons']}
            pageSize={pageSizeRef.current}
            sortableColumns={sortableColumns}
            currentPage={roleTableCurrentPage || 1}
            rowsQuantity={selectedPermissionsRef.current.length}
            pageNumberHandler={(page) => setRoleTableCurrentPage(page)}
          />
        )}
      </div>
    </Form>
  );

  return (
    <AddRoleStyles>
      <Helmet>
        <title>{id ? 'Editing role Voicer' : 'Creating a role Voicer'}</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, id ? 'role_edit_title' : 'role_create_title')}>
        <div className="buttonsContainer">
          <TransparentButton form="roleForm" submit filled text={getTranslationByLangOrEng(interfaceLanguage, 'save_button')} />
          <TransparentButton handleClick={() => navigate('/users/roles')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      {(isLoading || !initialValues) ? <Loader /> : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}
    </AddRoleStyles>
  );
}
