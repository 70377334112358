import React, {
  Dispatch,
  FC, SetStateAction, useEffect, useRef, useState,
} from 'react'
import { Api, ApiNotifiableSource } from '../../../api';
import { CompaniesMainInfo } from '../../../entities';
import { useAppSelector } from '../../../state';
import { NotifiableCompany } from '../notifiableCompany';
import { NotifiableSourceStyles } from './NotifiableSourceStyles'
import { getTranslationByLangOrEng } from '../../../i18n';

type TNotifableSource = {
    id: number;
    reQuery: boolean;
    setReQuery: Dispatch<SetStateAction<boolean>>
}

export const NotifiableSource: FC<TNotifableSource> = React.memo(({ id, reQuery, setReQuery }) => {
  const [attachedCompanies, setAttachedCompanies] = useState<number[]>([])
  const [notifiableCompanies, setNotifiableCompanies] = useState<CompaniesMainInfo[]>([])
  const [notifiableSources, setNotifiableSources] = useState<ApiNotifiableSource[]>([]);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const companies = useRef<CompaniesMainInfo[]>([]);

  useEffect(() => {
    Api.getCompanies().then((res) => {
      companies.current = res.data
      Api.getMultiTenancy(id).then((res) => {
        setAttachedCompanies(res.data.structures.map((structure) => structure.companyID))
      })
      Api.getNotifiableSource(id).then((res) => {
        setNotifiableSources(res.data)
      })
    })
  }, [reQuery])

  useEffect(() => {
    setNotifiableCompanies(companies.current.filter((company) => attachedCompanies.includes(company.id)))
  }, [companies.current, attachedCompanies])
  return (
    <NotifiableSourceStyles>
      <h3>{getTranslationByLangOrEng(interfaceLanguage, 'notifiable_sources')}</h3>
      <div className="company-wrapper">
        {notifiableCompanies.map((company) => <NotifiableCompany notifiableSources={notifiableSources} reQuery={reQuery} setReQuery={setReQuery} key={company.id} id={id} company={company} />)}
      </div>
    </NotifiableSourceStyles>
  )
})
