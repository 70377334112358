import { useFormikContext } from 'formik';
import React, {
  FC, useEffect, useMemo, useRef, useState,
} from 'react';
import { CSSProperties } from 'styled-components';
import { SearchIcon } from '../../../assets';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Loader } from '../../atoms';
import { CustomSelectStyles } from './CustomSelectStyles';
import SelectItem from './selectItem/SelectItem';

interface SelectProps {
    label?: string;
    name: string;
    options: any[];
    selectKey: string;
    placeholder: string;
    value: any;
    handleSelect: (value: any) => any;
    property?: string;
    search?: boolean;
    formGroupStyles?: CSSProperties;
    optionsContainerStyles?: CSSProperties;
    required?: boolean;
    isLoading?: boolean;
    disabled?: boolean;
    emptyOption?: boolean;
  }

const CustomSelectResponsible: FC<SelectProps> = ({
  name, options, selectKey, placeholder, value, handleSelect, formGroupStyles, optionsContainerStyles, property, search, isLoading, required, disabled, label, emptyOption,
}) => {
  const { errors, touched } = useFormikContext();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [searchParams, setSearchParams] = useState<string>('');
  const [open, setOpen] = useState(false);

  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const filteredParams = useMemo(() => {
    if (search) {
      return options.filter((option) => (selectKey ? `${option[selectKey]}` : `${option}`).toLowerCase().includes(searchParams.toLowerCase()));
    }

    return options;
  }, [searchParams, options]);

  useEffect(() => {
    setSearchParams('');
  }, [open]);

  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);

  return (
    <CustomSelectStyles>
      <div
        className={`form-group${
        // @ts-ignore
          touched[name] && !errors[name]
            ? ' valid'
          // @ts-ignore
            : touched[name] && errors[name]
              ? ' error'
              : ''
        }`}
        style={formGroupStyles || {}}
      >
        {label
          && (
            <div className="labelContainer">
              <label htmlFor={name}>
                {label}
                {required && <span className="required"> *</span>}
              </label>
            </div>
          )}
        <div className="selectField">
          <div className="selectedValue" onClick={() => ((disabled || isLoading) ? null : setOpen(!open))} ref={selectedValueFieldRef}>
            {value ? (
              <div className="valueContainer">
                {value}
              </div>
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </div>

          {(isLoading || disabled) && (
            <div className="loading">
              {isLoading && <Loader margin={0} height={20} />}
            </div>
          )}

          {open && (
            <div
              className="options"
              style={optionsContainerStyles}
              ref={optionsListRef}
            >
              {search
                && (
                  <div className="searchWrapper">
                    <SearchIcon />
                    <input
                      type="text"
                      value={searchParams}
                      onChange={(e) => setSearchParams(e.target.value)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
                    />
                  </div>
                )}
                {emptyOption && (
                <div className="option">
                  <div className="row">
                    <button
                      type="button"
                      className={property ? 'withImage' : ''}
                      onClick={() => {
                        handleSelect('');
                        setOpen(!open);
                      }}
                    >
                      <div className="labelContent">-</div>
                    </button>
                  </div>
                </div>
                )}
              {filteredParams.map((option) => (
                <SelectItem
                  option={option}
                  selectKey={selectKey}
                  childrenKey="children"
                  inputName={name}
                  imageProperty={property}
                  handleSelect={handleSelect}
                  setOpen={setOpen}
                />
              ))}
            </div>
          )}
        </div>
      </div>
    </CustomSelectStyles>
  );
};

export default CustomSelectResponsible;
