import styled from 'styled-components';
import image from '../../../assets/images/defaultCompanyImage.png';

export const TableStyles = styled.div<{ dataLength: boolean }>`
width: 100%;
display: flex;
flex-direction: column;

  .tableContainer {
    width: 100%;
    
    @media screen and (max-width: 1100px) {
      overflow: auto;
      min-height: ${({ dataLength }) => (!dataLength ? '70vh' : 'none')};
    }
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  tr {
    border-bottom: 1px solid #f0f0f0;
  }
  
  th, td {
    border-right: 1px solid #f0f0f0;
    vertical-align: middle;
    height: 50px;
    
    &:last-child {
      border-right: none;
    }
    
    .fieldContent {
      padding: 0 16px;
      height: 100%;
      display: flex;
      align-items: center;
      
      span {
        &.withBackground {
          height: 24px;
          border-radius: 12px;
          padding: 0 8px;
          background-color: #EAFBF2;
          display: flex;
          align-items: center;
        }
      }
    }
    
    .arrows {
      margin-left: 14px;
      display: flex;
      flex-direction: column;
      
      div {
        &:first-child {
          margin-bottom: -6px;
        }
        &:last-child {
          margin-top: -6px;
        }
        
        &.active svg {
          filter: invert(38%) sepia(19%) saturate(2462%) hue-rotate(129deg) brightness(109%) contrast(89%);
        }
      }
      
      .arrowUp {
        transform: rotate(180deg);
      }
    }
  }

  td {
    padding: 8px 0;
  }
  
  thead, tbody {
    vertical-align: middle;
  }
  
  .clickableRow {
    cursor: pointer;

    &:hover {
      background-color: #1bcab92b;
    }
  }

  .menuList {
    z-index: 1;
    position: absolute;
    top: calc(50% + 15px);
    right: 42px;
    width: 260px;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
    overflow: hidden;
    filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

    list-style: none;
    font-size: 14px;
    color: #000000;

    li {
      height: 41px;
      background: #FFFFFF;
      border-bottom: 1px solid #E5E5E5;
      box-sizing: border-box;
      
      &:last-child {
        border-bottom: none;
      }
    }

    button {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: 0 16px;
      box-sizing: border-box;
      transition: background-color 0.3s;
      
      &:hover {
        background-color: #F0F5F5;
        transition: background-color 0.3s;
      }
    }

    span {
      margin-left: 12px;
    }
  }
  
  .imageContainer {
    margin-right: 8px;
    width: 64px;
    height: 48px;
    background-image: url(${image});
    background-repeat: no-repeat;
    background-position: center center;
    background-color: #FFFFFF;
    padding: 2px;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 4px;
    overflow: hidden;
    
    .image {
      width: 100%;
      height: 100%;
      border-radius: 4px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
    &.default {
      background-color: #F0F0F0;
      display: grid;
      place-items: center;
    }
  }

  .dotsContainer {
    position: relative;
    width: 88px;

    button {
      padding: 0 20px;
    }
  }
`;
