import styled from 'styled-components';

export const AddNodeStyles = styled.div`
  h2 {
    font-weight: 600;
    font-size: 18px;
    line-height: 23px;
    color: #000000;
  }
  
  .inputLabel, .labelContainer {
    .required {
      width: 10px;
      display: flex;
      justify-content: flex-end;
    }
  }
  
  .formSection {
    padding: 20px 32px 0 16px;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;

    .formSectionInnerWrap {
      display: grid;
      gap: 60px;
      grid-template-columns: 500px 300px;
      grid-auto-flow: row;
    }

    .labelContainer {
      display: flex;
      margin-bottom: 8px;
      
      .fakeLabel {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }
    }
    
    h2 {
      margin-bottom: 17px;
    }

    .extraFieldsWrapper {
      max-width: 544px;
      .extraFieldContainer {
        position: relative;
        border-top: 1px solid #999;
        padding-top: 24px;
      
        .removeButtonContainer {
          position: absolute;
          top: calc(152px / 2 - 10px);
          right: -60px;
        }
      }
    
      .addFieldButtonContainer {
        margin-top: 10px;
        margin-bottom: 36px;
      }
    }
  }
  
  .additionalSettings {
    padding-bottom: 32px;
    box-sizing: border-box;
  }
  
  .integrationsSettingWrap {
    width: 100%;
    max-width: 850px;
    display: flex;
    flex-direction: column;
    
    & > span {
      margin-bottom: 16px;
    }
    
    .checkboxWrap {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
      
      .checkboxValue {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }

      .formErrorContainer {
        display: none;
      }
    }
    
    .integrationFieldsWrap {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 40px;
      
      .inputLabel {
        display: flex;
        align-items: center;
        margin-right: 6px;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }
    }
  }
  
  .inputWrap {
    flex-direction: row-reverse;
  }
  
  .extraFields {
    .extraFieldContainer {
      display: flex;
      align-items: center;
      
      &:not(:first-child) {
        margin-top: -8px;
      }
      
      .extraFieldButtons {
        display: flex;
        margin-left: 20px;

        .extraFieldsButtonContainer {
          height: 20px;
          margin-bottom: 24px;
        }
      }
    }
  }

  .addFieldButtonContainer {
    margin-top: -8px;
    margin-bottom: 24px;
  }
`;
