import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IMassMailingMainInfo, INode } from '../../entities';

export interface MassMailingsEmailRedux {
  senderName: string,
  letterSubject: string,
  senderEmail: { name: string, id: number } | undefined,
  emailBody: string,
  attachedFiles: (string | File)[],
}

export interface MassMailingsViberRedux {
  senderAccount: string | undefined;
  text: string;
  image: {
    value: string | File,
    id?: number,
  } | undefined;
  messageType: 'text' | 'image';
  actionButtonActive: boolean;
  buttonText: string;
  buttonUrl: string;
}

export interface MassMailingsSmsRedux {
  senderAccount: string,
  text: string,
  phones: string[],
}

export interface MassMailingState {
  massMailingMainInfo: Partial<IMassMailingMainInfo> | null;
  nodes: { [key: string]:Partial<INode> } | null;
  massMailingsEmail: Partial<MassMailingsEmailRedux> | null;
  massMailingsViber: MassMailingsViberRedux | null;
  massMailingsSms: MassMailingsSmsRedux | null;
}

const initialState: MassMailingState = {
  massMailingMainInfo: null,
  nodes: null,
  massMailingsEmail: null,
  massMailingsViber: null,
  massMailingsSms: null,
};

export const massMailingSlice = createSlice({
  name: 'massMailing',
  initialState,
  reducers: {
    // Reducer comes here
    setMassMailingMainInfoCompany: (state, action: PayloadAction<Partial<IMassMailingMainInfo> | null>) => ({ ...state, massMailingMainInfo: action.payload }),
    setMailingNodes: (state, action: PayloadAction<{ [key: string]:Partial<INode> } | null>) => ({ ...state, nodes: action.payload }),
    setMassMailingsEmail: (state, action: PayloadAction<Partial<MassMailingsEmailRedux> | null>) => ({ ...state, massMailingsEmail: action.payload }),
    setMassMailingsViber: (state, action: PayloadAction<MassMailingsViberRedux | null>) => ({ ...state, massMailingsViber: action.payload }),
    setMassMailingsSms: (state, action: PayloadAction<MassMailingsSmsRedux | null>) => ({ ...state, massMailingsSms: action.payload }),
  },
  extraReducers: (builder) => {},
});

export const { actions: massMailingActions, reducer: massMailingReducer } = massMailingSlice;
// Extract and export each action creator by name
export const {
  setMassMailingMainInfoCompany, setMailingNodes, setMassMailingsEmail, setMassMailingsViber, setMassMailingsSms,
} = massMailingActions;
