import { FieldsSelectStyles } from './FieldsSelectStyles';
import FormConstructorItem from '../formConstructorItem/FormConstructorItem';
import React from 'react';
import { SimplePlusIcon } from '../../../../assets';
import { IExtraField } from '../../../../entities/IExtraField';

interface FieldsSelectProps {
  items: any[];
  handleAddField: (config: IExtraField) => void;
  handleClose: () => void;
}

export default function FieldsSelect({ items, handleAddField, handleClose }: FieldsSelectProps) {
  return (
    <FieldsSelectStyles>
      <div className="header">
        <h3>Select Field</h3>

        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        <button type="button" onClick={handleClose}><SimplePlusIcon width={20} /></button>
      </div>

      <div className="itemsList">
        {items.map((item) => (
          <FormConstructorItem
            key={item.name}
            type={item.config.type}
            handleClick={() => handleAddField(item.config)}
            previewText={item.name}
          />
        ))}
      </div>
    </FieldsSelectStyles>
  );
}
