import styled from 'styled-components';

export const MassMailingTableFiltersStyles = styled.div`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #FFF;
  position: absolute;
  top: 42px;
  right: -32px;
  width: 300px;
  padding: 16px;
  border-radius: 4px;

  .options {
    max-height: 280px;
  }
`;
