import { IColor, IImage } from '../entities';

export function mapIColorToString(color: IColor): string {
  return typeof color.color === 'string'
    ? color.color
    : `rgba(${color.color.r}, ${color.color.g}, ${color.color.b}, ${color.color.a})`;
}

export function mapIImageToString(image: IImage): string {
  return image ? `${image.name}|||${image.url}` : '';
}

export function mapIColorsToStringsArray(colors: IColor[]): string[] {
  return colors.map((color) => mapIColorToString(color));
}
