import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { TablePaginationStyles } from './TablePaginationStyles';
import { useWindowSize } from 'usehooks-ts';
import { validateInputNumber } from './TablePaginationUtils';
import { useAppSelector } from '../../../../state';
import { useDebounce } from '../../../../hooks';
import {
  ArrowLeft, ArrowRight,
  StripArrowLeft, StripArrowRight,
} from '../../../../assets';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { availablePageSizes } from '../../../../constants';

interface TablePaginationProps {
  pageSize: number;
  currentPage: number;
  setPageSize: Dispatch<SetStateAction<number>> | undefined;
  rowsQuantity: number;
  pageNumberHandler: (number: number) => void;
}
export const TablePagination = React.memo(({
  pageSize,
  currentPage,
  rowsQuantity,
  setPageSize,
  pageNumberHandler,
}: TablePaginationProps) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [pageNumber, setPageNumber] = useState<string | number>();
  const [pageQuantity, setPageQuantity] = useState<number>(1);
  const [canPreviousPage, setCanPreviousPage] = useState<boolean>(false);
  const [canNextPage, setCanNextPage] = useState<boolean>(false);

  const { width } = useWindowSize();
  const debouncedPageNumber = useDebounce(pageNumber, 1000);

  useEffect(() => {
    if (debouncedPageNumber) {
      if (debouncedPageNumber > pageQuantity) {
        setPageNumber(pageQuantity);
        pageNumberHandler(pageQuantity);
      } else {
        pageNumberHandler(parseInt(debouncedPageNumber, 10));
      }
    }
  }, [debouncedPageNumber])

  useEffect(() => {
    setPageQuantity(Math.ceil(rowsQuantity / pageSize));
  }, [rowsQuantity, pageSize]);

  useEffect(() => {
    setPageNumber(currentPage);
    setCanPreviousPage(currentPage > 1);
    setCanNextPage(currentPage < Math.ceil(rowsQuantity / pageSize));
  }, [currentPage]);

  function gotoPage(pageNumber: number) {
    pageNumberHandler(pageNumber);
  }

  return (
    <TablePaginationStyles mobileVersion={width <= 500}>
      { setPageSize !== undefined && (
        <div className="countSelect">
          <select value={pageSize} onChange={(e) => setPageSize(parseInt(e.target.value, 10))}>
            {availablePageSizes.map((el) => <option>{el}</option>)}
          </select>
        </div>
      )}
      <div className="arrowsContainer">
        <div className="arrows">
          <button onClick={() => gotoPage(1)} disabled={!canPreviousPage} type="button">
            <StripArrowLeft color={canPreviousPage ? '#000' : '#999'} />
          </button>
          <button onClick={() => gotoPage(currentPage - 1)} disabled={!canPreviousPage} type="button">
            <ArrowLeft color={canPreviousPage ? '#000' : '#999'} />
          </button>
        </div>

        <div className="pageCounter">
          <input
            className="currentPage"
            id="defaultValue"
            type="text"
            value={pageNumber}
            onChange={(event) => (validateInputNumber(event.target.value) ? setPageNumber(event.target.value) : null)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'pagination_page_number_placeholder')}
          />
          {' / '}
          <span>{pageQuantity}</span>
        </div>

        <div className="arrows">
          <button onClick={() => gotoPage(currentPage + 1)} disabled={!canNextPage} type="button">
            <ArrowRight color={canNextPage ? '#000' : '#999'} />
          </button>
          <button onClick={() => gotoPage(pageQuantity)} disabled={!canNextPage} type="button">
            <StripArrowRight color={canNextPage ? '#000' : '#999'} />
          </button>
        </div>
      </div>
    </TablePaginationStyles>
  );
})
