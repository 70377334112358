import { useState, useRef, useEffect } from 'react';
import { Api } from '../api';
import { IColor } from '../entities/IColor';

export function useCompanyColors(companyId: string) {
  const [prevCompanyId, setPrevCompanyId] = useState<string>('');
  const [companyColorsLoaded, setCompanyColorsLoaded] = useState<boolean>(false);
  const [companyColorsLoading, setCompanyColorsLoading] = useState<boolean>(false);

  const companyColorsRef = useRef<IColor[]>();

  function addCompanyColor(color: IColor) {
    companyColorsRef.current = companyColorsRef.current ? [...companyColorsRef.current, color] : [color]
  }

  async function getCompanyMetadata(id: number) {
    const res = await Api.getCompanyMetadata(id);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      companyColorsRef.current = res.data.filter((item) => item.key.startsWith('color_')).map((item) => {
        const colorParts = item.value.split('|||');
        return { name: colorParts[0], color: colorParts[1] };
      });
    }
  }

  useEffect(() => {
    if (prevCompanyId !== companyId && !companyColorsLoaded && !companyColorsLoading) {
      setPrevCompanyId(companyId);
      setCompanyColorsLoading(true);
      getCompanyMetadata(+companyId!).finally(() => {
        setCompanyColorsLoading(false);
        setCompanyColorsLoaded(true);
      });
    }
  }, []);

  return {
    companyColorsLoaded,
    companyColorsLoading,
    companyColors: companyColorsRef.current,
    addCompanyColor,
  };
}
