import { Api, ApiResponse } from '../../api';
import { IIssueStatus } from '../../entities';

export const functionsForStatuses = [
  Api.deleteTicketStatus,
  Api.updateTicketStatus,
  Api.createTicketStatusByCompany,
];

export const functionsForTypes = [
  Api.deleteTicketType,
  Api.updateTicketType,
  Api.createTicketTypeByCompany,
];

async function manipulateData(dataType: 'type' | 'status', actionType: 'create' | 'update' | 'delete', items: any[], companyId: number) {
  if (items.length > 0) {
    const promises: Promise<ApiResponse<IIssueStatus | null>>[] = [];

    items.forEach((item) => {
      let action;

      if (dataType === 'type') {
        switch (actionType) {
          case 'create':
            action = Api.createTicketTypeByCompany(companyId, item!);
            break;
          case 'update':
            action = Api.updateTicketType(companyId, item!);
            break;
          case 'delete':
            action = Api.deleteTicketType(item!);
            break;
        }
      }

      if (dataType === 'status') {
        switch (actionType) {
          case 'create':
            action = Api.createTicketStatusByCompany(companyId, item!);
            break;
          case 'update':
            action = Api.updateTicketStatus(companyId, item!);
            break;
          case 'delete':
            action = Api.deleteTicketStatus(item!);
            break;
        }
      }

      if (action) {
        promises.push(action);
      }
    });

    await Promise.all(promises);
  }
}

export async function updateItemsList(dataType: 'type' | 'status', companyId: number, initialItems: any[], items: any[]) {
  const initialItemNames = initialItems.reduce((acc: {[key: number]: string}, item) => {
    if (item.id) {
      acc[item.id] = item.name;
    }

    return acc;
  }, {});

  const initialItemsIds = initialItems.map((item) => item.id!);
  const currentItemsIds = items.map((item) => item.id);
  const itemsToDelete: number[] = initialItemsIds.filter((x) => !currentItemsIds.includes(x));
  const itemsToUpdate: any[] = items.filter((item) => (item.id ? item.name !== initialItemNames[item.id] : false));
  const itemsToCreate: any[] = items.filter((item) => !item.id);

  await manipulateData(dataType, 'delete', itemsToDelete, companyId);
  await manipulateData(dataType, 'update', itemsToUpdate, companyId);
  await manipulateData(dataType, 'create', itemsToCreate, companyId);
}
