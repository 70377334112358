export const extraFieldsSettings = {
  connectionChannelExtraField: {
    justifyContent: 'center',
    width: '100px',
  },
  createAt: {
    width: '100px',
    justifyContent: 'center',
    margin: 'auto',
    textColor: '#979797',
  },
  nodeName: {
    justifyContent: 'center',
  },
  reviewExtraField: {
    justifyContent: 'center',
  },
  language: {
    textColor: '#979797',
  },
  statuses: {
    justifyContent: 'center',
  },
  keyAnswer: {
    justifyContent: 'center',
  },
  sources: {
    justifyContent: 'center',
  },
  email: {
    justifyContent: 'center',
    width: '120px',
    margin: 'auto',
  },
  name: {
    justifyContent: 'center',
  },
  phone: {
    justifyContent: 'center',
  },
  parentNode: {
    justifyContent: 'center',
  },
  comment: {
    justifyContent: 'center',
  },
  keyQuestion: {
    justifyContent: 'center',
  },
};

export function mapTableSettingsKeyNames(name: string) {
  switch (name) {
    case 'source':
      return 'sources';
    case 'node':
      return 'nodes';
    case 'status':
      return 'statuses';
    default:
      return name;
  }
}
