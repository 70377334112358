import React from 'react';
import {
  CheckboxIcon, DocumentCompanyIcon,
  EmailCompanyIcon, ExtraQuestionIcon,
  FileCompanyIcon, NameIcon,
  NetworkIcon, PhoneCompanyIcon,
  QuantityIcon, RadioButtonIcon,
  ScaleIcon, TitleIcon,
} from '../../../assets';

export const typeColors: {[key: string]: string} = {
  title: '#F06292',
  name: '#BA68C8',
  email: '#9575CD',
  phone_number: '#7986CB',
  extra_question: '#64B5F6',
  checkbox: '#81C784',
  radio: '#DCE775',
  scale: '#FFF176',
  upload_files: '#FFD54F',
  networks: '#FFB74D',
  document: '#FF8A65',
  quantity: '#c04848',
};

export const typeIcons: {[key: string]: React.ReactElement} = {
  title: <TitleIcon height={12} width={(448 / 512) * 12} color="#FFF" />,
  name: <NameIcon height={12} width={(640 / 512) * 12} color="#FFF" />,
  email: <EmailCompanyIcon height={12} width={(512 / 512) * 12} color="#FFF" />,
  phone_number: <PhoneCompanyIcon height={12} width={(512 / 512) * 12} color="#FFF" />,
  extra_question: <ExtraQuestionIcon height={12} width={(320 / 512) * 12} color="#FFF" />,
  checkbox: <CheckboxIcon height={12} width={(448 / 512) * 12} color="#FFF" />,
  radio: <RadioButtonIcon height={12} width={(512 / 512) * 12} color="#FFF" />,
  scale: <ScaleIcon height={12} width={(448 / 512) * 12} color="#FFF" />,
  upload_files: <FileCompanyIcon height={12} width={(512 / 512) * 12} color="#FFF" />,
  networks: <NetworkIcon height={14} width={(384 / 512) * 14} color="#FFF" />,
  document: <DocumentCompanyIcon height={12} width={(576 / 512) * 12} color="#FFF" />,
  quantity: <QuantityIcon height={14} width={(448 / 512) * 14} color="#FFF" />,
};
