import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { translations } from '../../../../../../i18n';
import { useAppDispatch, useAppSelector } from '../../../../../../state';
import { MassMailingsCreatingEmailStep3Styles } from './MassMailingsCreatingEmailStep3Styles';
import parse from 'html-react-parser';
import { TransparentButton } from '../../../../../../components';

interface InitialValues {
  senderName: string,
  senderEmail: { name: string, id: number } | undefined,
  letterSubject: string,
  dateAndTime: string,
  emailBody: string,
}

export default function MassMailingCreatingEmailStep3() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { massMailingMainInfo, massMailingsEmail } = useAppSelector((state) => state.massMailing);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<InitialValues>();

  // const { data, status, statusCode } = useAppSelector((state) => state.user);

  async function onSubmit() {
    // TODO: add logic
  }

  function getInitialData() {
    if (massMailingsEmail) {
      const initialValuesFromRedux: InitialValues = {
        dateAndTime: `${massMailingMainInfo?.date} ${massMailingMainInfo?.time}`,
        senderName: massMailingsEmail.senderName || '',
        senderEmail: massMailingsEmail.senderEmail,
        emailBody: massMailingsEmail.emailBody || '',
        letterSubject: massMailingsEmail.letterSubject || '',
      };
      setInitialValues(initialValuesFromRedux);
    } else {
      setInitialValues({
        dateAndTime: '',
        emailBody: '',
        letterSubject: '',
        senderEmail: undefined,
        senderName: '',
      });
    }
  }

  useEffect(() => {
    // getDataFromServer();
    getInitialData();
  }, []);

  return (
    <MassMailingsCreatingEmailStep3Styles>
      <div className="titleWrapper">
        <h1>Creating - Step 3 of 3</h1>
        <TransparentButton
          handleClick={() => {
            navigate('/massMailings/create/step-2');
          }}
          text={translations[interfaceLanguage].go_back}
        />
      </div>
      <div className="formContainer">
        {massMailingMainInfo && (
          <div className="infoWrapper name">
            <span>Name:</span>
            <span>{massMailingMainInfo.name}</span>
          </div>
        )}
        {initialValues && (
          <div className="mailingInfoContainer">
            <div className="infoWrapper">
              <span>Sender name:</span>
              <span>{initialValues.senderName}</span>
            </div>

            <div className="infoWrapper">
              <span>Sender&apos;s mail:</span>
              <span>{initialValues.senderEmail?.name}</span>
            </div>

            <div className="infoWrapper">
              <span>Letter subject:</span>
              <span>{initialValues.letterSubject}</span>
            </div>

            <div className="infoWrapper">
              <span>Date and time:</span>
              <span>{initialValues.dateAndTime}</span>
            </div>
          </div>
        )}

        <div className="modalContent">
          <div className="desktopPreview">
            {parse(massMailingsEmail?.emailBody || '')}
          </div>
        </div>

        <div className="buttonsContainer">
          <TransparentButton handleClick={() => {}} text="Save draft" extraStyles={{ height: 40 }} />
          <TransparentButton handleClick={() => {}} text="Send" filled extraStyles={{ height: 40 }} />
        </div>
      </div>
    </MassMailingsCreatingEmailStep3Styles>
  );
}
