import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ClosedAutoNoReplyIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
    enableBackground="new 0 0 700 700;"
  >
    <g>
      <g>
        <path
          fill="#27C360"
          d="M590.3,405.1L405.1,590.3c-30.4,30.4-79.8,30.4-110.2,0L109.7,405.1c-30.4-30.4-30.4-79.8,0-110.2 l185.2-185.2c30.4-30.4,79.8-30.4,110.2,0l185.2,185.2C620.7,325.3,620.7,374.7,590.3,405.1z"
        />
      </g>
    </g>
    <circle fill="#FFFFFF" cx="350" cy="435.1" r="28.4" />
    <path
      fill="#FFFFFF"
      d="M378.4,350c0,15.7-12.7,28.4-28.4,28.4s-28.4-12.7-28.4-28.4v-85.1c0-15.7,12.7-28.4,28.4-28.4 s28.4,12.7,28.4,28.4V350z"
    />
  </svg>
));
