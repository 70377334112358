import styled from 'styled-components';

export const ScaleConstructorStyles = styled.div`
  .tabsContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid #F0F0F0;

    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #FFF;
      margin-right: 32px;

      font-size: 15px;
      line-height: 16px;
      color: #7C7878;
      transition: all 0.3s;

      &:last-child {
        margin-right: 0;
      }

      &.active {
        border-bottom: 3px solid #0E9285;
        color: #0E9285;
        transition: all 0.3s;
      }
    }
  }

  .modalContainer {
    background: #fff;
    color: #000000;
    border-radius: 4px;

    .modalHeader {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-right: 12px;
      box-sizing: border-box;

      h5 {
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .body {
      padding: 16px;
      border-bottom: 1px solid #e5e5e5;

      h6 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      & > div, a {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
      }

      & > div {
        width: 100%;
        margin: 0;

        a, button {
          width: 100%;
        }
      }
    }
  }
`;
