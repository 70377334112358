import React, { CSSProperties, useEffect, useState } from 'react';
import { FormConstructorDocumentStyles } from './FormConstructorDocumentStyles';
import FieldHeader from '../fieldHeader/FieldHeader';
import CustomSelect from '../../form/customSelect/CustomSelect';
import { InfoCharIcon } from '../../../assets';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getTranslationByLangOrEng } from '../../../i18n';
import { extraFieldValidationSchema } from '../validationSchemas';
import { useAppDispatch, useAppSelector, updateNotSavedDocument } from '../../../state';
import { IFormDocument } from '../../../entities/IExtraField';
import { Api } from '../../../api';
import { ICompanyDocument } from '../../../entities/ICompanyDocument';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import Checkbox from '../../form/checkbox/Checkbox';
import { FormErrorMessage, Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface SelectFieldProps {
  index: number;
  options: ICompanyDocument[];
  extraBlockStyles?: CSSProperties;
  disabled?: boolean;

  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;

  showInfo?: boolean;

  provided?: DraggableProvided;
}

export default function FormConstructorDocument({
  index,
  options,
  extraBlockStyles,
  disabled,

  draggable,
  handleCopy,
  handleRemove,

  showInfo = true,

  provided,
}: SelectFieldProps) {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedDocuments } = useAppSelector((state) => state.notSavedForm);

  const [initialValues, setInitialValues] = useState<IFormDocument>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (notSavedDocuments) {
      setInitialValues(notSavedDocuments[index]);
    }
  }, [index]);

  async function onSubmit(
    values: IFormDocument,
    { setSubmitting }: FormikHelpers<IFormDocument>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateFormDocument(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(updateNotSavedDocument(values));
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IFormDocument>) {
    const error = typeof errorMessage === 'object' ? getErrorMessage('description', errorMessage) : undefined;

    return (
      <Form>
        <div className="body">
          {/* {subTitle && <h3>{subTitle}</h3>} */}

          <div className="inputContainer">
            <div className="inputRaw">
              <input
                id="description"
                type="text"
                value={values.description || ''}
                onChange={(event) => setFieldValue('description', event.target.value)}
                onKeyUp={() => handleKeyUp('description', setErrorMessage, errorMessage)}
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_document_description_placeholder')}
                disabled={disabled}
                className={disabled ? 'disabled' : ''}
              />
              <div className="infoContainer">
                <div className="infoIcon">
                  <InfoCharIcon />
                </div>
                <div className="infoText">
                  <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_question')}</p>
                </div>
              </div>
            </div>
            <div className="formErrorContainer">
              {error && <span>{error}</span>}
              {!error && touched && <ErrorMessage name="description" component={FormErrorMessage} />}
            </div>
          </div>

          <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_document_description')}</p>

          <div className="inputContainer">
            <div className="inputRaw">
              <CustomSelect
                name="selected"
                options={options}
                emptyList={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_document_empty_list')}
                selectKey="description"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_document_placeholder')}
                value={values.selected}
                handleSelect={(value) => setFieldValue('selected', value)}
              />
              {showInfo && (
              <div className="infoContainer">
                <div className="infoIcon">
                  <InfoCharIcon />
                </div>
                <div className="infoText">
                  <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_question')}</p>
                </div>
              </div>
              )}
            </div>
          </div>

          <Checkbox
            name="required"
            value={values.required || false}
            onChange={handleChange}
            disabled={disabled}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question')}</span>
          </Checkbox>

          <Checkbox
            name="setTermsAgreed"
            value={values.setTermsAgreed || false}
            onChange={handleChange}
            disabled={disabled}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_set_default_agreed')}</span>
          </Checkbox>
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <FormConstructorDocumentStyles style={extraBlockStyles}>
      <FieldHeader
        type="document"
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_document_title')}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // @ts-ignore
          validationSchema={extraFieldValidationSchema.documents}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </FormConstructorDocumentStyles>
  );
}
