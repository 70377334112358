import styled from 'styled-components';

export const ReviewsTableStyles = styled.div<{tableVersion: boolean, mobileVersion: boolean}>`
  .searchContainer {
    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    input[type="number"] {
      -moz-appearance: textfield;
    }

    display: flex;
    align-items: center;
   
    div {
      display: flex;
      width: 50%;
      align-items: center;
      padding: 0 19px;
      border-bottom: 1px solid #F0F0F0;

      &:not(:last-child) {
        border-right: 1px solid #F0F0F0;
      }
    }

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    input::placeholder {
      font-size: 15px;
      color: #979797;
    }
  }

  .customReviewsWrapper {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 8px;

    .reviewContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 2px 8px;
      gap: 4px;
      background: #F0F5F5;
      border-radius: 4px;

      span {
        background: #FF0101;
        border-radius: 35px;
        font-size: 14px;
        font-weight: 700;
        color: #FFFFFF;
        padding: 0 6px;
      }
    }
  }

  .tableFiltersWrapper {
    padding: 0 32px 0 16px;
    min-height: 50px;
    display: flex;
    gap: 40px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    position: ${({ tableVersion }) => (tableVersion ? 'relative' : 'initial')};

    .tableFiltersWrapperBtns {
      display: flex;
      gap: 10px;
      align-items: center;
    }

    @media screen and (max-width: 991px) {
      flex-wrap: wrap;
      padding: 10px 0;
      justify-content: center;
      gap: 10px;
    }

    button {
      width: fit-content;
    }

    .calendarWrapper {
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
      position: ${({ tableVersion }) => (tableVersion ? 'initial' : 'relative')};

      .calendarOpenerWrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
        place-items: center;
        gap: 10px;
        cursor: pointer;

        .active {
          transform: rotate(180deg);
        }
      }

      .splitter {
        width: 1px;
        background: #F0F0F0;
        height: 100%;
      }
    }

    .filtersWrapper {
      position: ${({ tableVersion }) => (tableVersion ? 'initial' : 'relative')};

      .filtersOpener {
        cursor: pointer;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr auto;
        align-items: center;

        &-active {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
  }

  .connectionChannelContainer {
    max-width: 150px;
    width: 100%;
    display: flex;
    gap: 16px;

    .networkDataContainer {
      display: flex;
      align-items: center;
      .botIsNotConnected {
        font-size: 12px;
      }
    }
  }

  .statusContainer {
    display: grid;
    place-items: center;
    gap: 8px;
    white-space: pre-line;
    text-align: center;
    font-size: 12px;
    color: #444;
  }

  .sourceContainer {
    text-align: center;
  }

  .answersContainer {
    .checkboxesList {
      list-style: none;
      display: grid;
      gap: 8px;
      color: #000000;
    }

    .answersExtraQuestionField {
      width: 200px;
    }

    .scaleContainer {
      font-weight: 700;
      font-size: 14px;
      color: #000000;
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      gap: 4px;
    }
  }

  .nameContainer {
    font-family: 'Roboto', sans-serif;
    position: relative;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      height: 12px;
    }

    h3 {
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #979797;
      margin-bottom: 8px;
    }

    span {
      font-weight: 400;
      font-size: 12px;
      line-height: 130%;
      color: #000000;
    }
  }

  .tableSettings {
    position: relative;
  }

  .chooseCompanyTitleContainer {
    position: relative;
    height: 50vh;

    .chooseCompanyTitle {
      position: absolute;
      width: 100%;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 24px;
      font-weight: 700;
    }
  }

  .commentContainer {
    display: flex;
    justify-content: center;

    .commentField {
      width: 180px;
      text-align: center;
      word-wrap: break-word;
    }
  }

  .extraQuestionFieldAnswerContainer {
    display: flex;
    justify-content: center;

    .extraQuestionFieldAnswer {
     width: 180px;
     text-align: center;
     word-wrap: break-word;
    }
  }

  .extraFilterFormStyles {
    height: 100%;
  }

  .extraFilterStyles {
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr;
    grid-gap: 16px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .optionsExtraStyles {
    border: none !important;
    border-radius: 0 !important;
    overflow-y: auto !important;
    max-height: none !important;
  }
`;
