import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ExpectingReplyIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <g>
      <g>
        <g>
          <path
            fill="#F3E820"
            d="M590.3,405.1L405.1,590.3c-30.4,30.4-79.8,30.4-110.2,0L109.7,405.1c-30.4-30.4-30.4-79.8,0-110.2
                  l185.2-185.2c30.4-30.4,79.8-30.4,110.2,0l185.2,185.2C620.7,325.3,620.7,374.7,590.3,405.1z"
          />
        </g>
      </g>
      <g>
        <path
          fill="#FFFFFF"
          d="M350,409.2c15.2,0,27.5,12.3,27.5,27.5c0,15.2-12.3,27.5-27.5,27.5s-27.5-12.3-27.5-27.5
              S334.8,409.2,350,409.2z"
        />
        <path
          fill="#FFFFFF"
          d="M278.8,300c0.8-17.8,8.2-34.6,20.8-47.2c13.4-13.4,31.4-20.9,50.4-20.9s37,7.5,50.4,20.9
              c13.4,13.4,20.9,31.4,20.9,50.4c0,12.9-3.5,25.5-10.1,36.6c-6.6,11.1-16.2,20.2-27.6,26.2c-3.3,1.8-6.1,4.2-8,7.5
              c-1.9,3.2-2.7,6.9-2.7,10.6v5.7c0,2.1-1.7,3.7-3.7,3.7h-38.2c-2,0-3.7-1.7-3.7-3.7v-5.7c0-12,3.1-23.7,9.3-34
              c6.2-10.3,15.1-18.5,25.7-24.2c4.1-2.2,7.5-5.5,9.9-9.5c2.4-4,3.6-8.5,3.6-13.2c0-6.8-2.7-13.3-7.5-18.1
              c-4.8-4.8-11.3-7.5-18.1-7.5c-6.8,0-13.3,2.7-18.1,7.5c-4.3,4.3-7,10-7.4,16.2l-0.1,1.7c-0.1,2-1.8,3.5-3.8,3.4l-38.2-0.7
              c-1,0-1.9-0.4-2.6-1.2c-0.7-0.8-1.1-1.7-1-2.7L278.8,300L278.8,300z"
        />
      </g>
    </g>
  </svg>
));
