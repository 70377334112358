import React, { CSSProperties } from 'react';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { Modal } from '../Modal';
import { CompaniesInfoModalStyles } from './CompaniesInfoModalStyles';

type Props = {
  extraStyles?: CSSProperties,
  onDecline: () => void,
  onConfirm: () => void,
  onClose: () => void,
  title?: string,
  confirmText?: string,
  declineText?: string,
}

export const CompaniesInfoModal = React.memo(({
  onDecline, extraStyles, onConfirm, onClose, title, confirmText, declineText,
}: Props) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <CompaniesInfoModalStyles>
      <Modal onClose={onClose} extraStyles={extraStyles}>
        <div className="modalContainer">
          <p>{title || getTranslationByLangOrEng(interfaceLanguage, 'changes_saved_successfully')}</p>
          <div className="buttonsContainer">
            <button type="button" className="declineButton" onClick={onDecline}>{declineText || getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button')}</button>
            <button type="button" className="confirmButton" onClick={onConfirm}>{confirmText || getTranslationByLangOrEng(interfaceLanguage, 'continue_editing_button')}</button>
          </div>
        </div>
      </Modal>
    </CompaniesInfoModalStyles>
  );
});
