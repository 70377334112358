import React from 'react';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { TransparentButton } from '../../atoms';
import { FieldsSwitcherStyles } from './FieldsSwitcherStyles';

interface FieldsSwitcherProps {
  selectedSectionType: 'documents' | 'content' | 'sendButton' | 'thankYouPage';
  selectedFieldIndex: number;
  handleContentItemClick: (fieldType: 'content' | 'documents', fieldIndex: number) => void;
}

export default function FieldsSwitcher({ selectedSectionType, selectedFieldIndex, handleContentItemClick }: FieldsSwitcherProps) {
  const { notSavedExtraFields, notSavedDocuments } = useAppSelector((state) => state.notSavedForm);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  function getPrevFieldIndex() {
    if (selectedFieldIndex === 0) {
      handleContentItemClick(selectedSectionType as 'documents' | 'content', (selectedSectionType === 'content' ? notSavedExtraFields : notSavedDocuments).length - 1);
    } else {
      handleContentItemClick(selectedSectionType as 'documents' | 'content', selectedFieldIndex! - 1);
    }
  }

  function getNextFieldIndex() {
    if (selectedFieldIndex === (selectedSectionType === 'content' ? notSavedExtraFields : notSavedDocuments).length - 1) {
      handleContentItemClick(selectedSectionType as 'documents' | 'content', 0);
    } else {
      handleContentItemClick(selectedSectionType as 'documents' | 'content', selectedFieldIndex! + 1);
    }
  }

  return (
    <FieldsSwitcherStyles>
      <TransparentButton handleClick={() => getPrevFieldIndex()} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_prev_field')} />
      <TransparentButton handleClick={() => getNextFieldIndex()} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_next_field')} />
    </FieldsSwitcherStyles>
  );
}
