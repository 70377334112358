import styled from 'styled-components';

export const QuantityFieldStyles = styled.div`
  max-width: 540px;
  width: 100%;
  border: 1px solid #F0F5F5;
  border-radius: 4px;
  background-color: #fff;
  
  .quantityCounterContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 24px;
  }
  
  .inputsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 24px;

    .nameContainer {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #000;
      margin-bottom: 4px;
    }
  }

  .body {
    padding: 24px 16px 0 16px;
    box-sizing: border-box;

    .inputContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }

    .inputRaw {
      .nameContainer {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        color: #000;
        margin-bottom: 4px;
      }

      .inputElementContainer {
        display: grid;
        grid-template-columns: 1fr auto;
      }

      input {
        height: 40px;
        font-size: 14px;
        color: #000;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;

        &::placeholder {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          color: #979797;
        }

        &.disabled {
          background-color: #fafafa;
        }
      }

      .infoContainer {
        width: 34px;
        height: 40px;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover .infoText {
          display: block;
          z-index: 100;
        }

        .infoIcon {
          width: 16px;
          height: 16px;
          border-radius: 12px;
          border: 1px solid #0E9285;
          display: grid;
          place-items: center;

          svg {
            width: 12px;
            height: 12px;
            filter: invert(42%) sepia(68%) saturate(490%) hue-rotate(125deg) brightness(96%) contrast(99%);
          }
        }

        .infoText {
          display: none;
          width: 280px;
          padding: 16px;
          position: absolute;
          right: -8px;
          top: 38px;
          font-size: 14px;
          color: #000;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }

  .formSuccessContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #0cc757;
  }
`;
