import React, {
  Dispatch,
  FC, SetStateAction, useMemo, useRef, useState,
} from 'react'
import { Api, UpdateType } from '../../../api';
import { ArrowDownIcon, CheckMark, SearchIcon } from '../../../assets';
import { CompaniesMainInfo } from '../../../entities/ICompany';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { CompanySelectStyles } from './CompanySelectStyles'

type TCompanySelect = {
    id: number;
    attachedCompanies: number[]
    companies: CompaniesMainInfo[];
    label?: string;
    placeholder: string;
    setReQuery: Dispatch<SetStateAction<boolean>>
    reQuery: boolean;
}

export const CompanySelect: FC<TCompanySelect> = React.memo(({
  companies, label, placeholder, attachedCompanies, id, setReQuery, reQuery,
}) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');

  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  const companiesNames = useMemo(() => companies.filter((company) => attachedCompanies.includes(company.id)).map((company) => company.name).join(', '), [companies, attachedCompanies, id, reQuery])

  const handleAttach = (compID: number) => {
    const reqBody = {
      attachCompanyIDs: [compID],
      updateType: UpdateType.Strict,
    }

    Api.putMultiTenancy(id, reqBody).then((res) => {
      setReQuery(!reQuery)
    });
  }

  const handleDetach = (compID: number) => {
    const reqBody = {
      detachCompanyIDs: [compID],
      updateType: UpdateType.Strict,
    }

    Api.putMultiTenancy(id, reqBody).then((res) => {
      setReQuery(!reQuery)
    });
  }

  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);

  return (
    <CompanySelectStyles>
      {label && (
        <div className="labelContainer">
          <label htmlFor="company">
            <span>
              {label}
            </span>
          </label>
        </div>
      )}
      <div className="selectField">
        {companiesNames ? (
          <div className="valueContainer" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
            <div className="value">
              <span>{companiesNames.length > 30 ? `${companiesNames.slice(0, 30)}...` : companiesNames}</span>
            </div>
            <div className={open ? 'arrowDownActive' : ''}>
              <ArrowDownIcon />
            </div>
          </div>
        ) : (
          <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
            <span className="placeholder">{placeholder}</span>
            <div className={open ? 'arrowDownActive' : ''}>
              <ArrowDownIcon />
            </div>
          </div>
        )}

        {open && (
          <div
            className="options"
            ref={optionsListRef}
          >
            <div className="searchWrapper">
              <SearchIcon />
              <input
                type="text"
                value={searchParams}
                onChange={(e) => setSearchParams(e.target.value)}
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
              />
            </div>
              {companies && companies.filter((company) => company.name.toLowerCase().includes(searchParams.toLocaleLowerCase())).map((company) => (
                <div
                  className="option"
                  key={`selectOption:${company.id}`}
                >
                  <div className="rowSel">
                    <button
                      type="button"
                      onClick={() => (attachedCompanies.includes(company.id) ? handleDetach(company.id) : handleAttach(company.id))}
                    >
                      <div className="labelContent">
                        <div className="default">
                          {attachedCompanies.includes(company.id) && <CheckMark />}
                        </div>
                        <span>{company.name}</span>
                      </div>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
    </CompanySelectStyles>
  )
});
