import { useRef, useState } from 'react';
import { Api } from '../../api';
import { DeleteIcon } from '../../assets';
import { ModalConfirmDecline } from '../../components';
import { useClickOutside } from '../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../i18n';
import { useAppSelector } from '../../state';

export const extraFieldsSettings = {
  email: {
    justifyContent: 'center',
  },
  company: {
    justifyContent: 'center',
  },
  role: {
    justifyContent: 'center',
  },
  notifications: {
    justifyContent: 'center',
  },
};

export function NameCustomField({ name, logo, textColor }: {name: string, logo?: string, textColor?: string}) {
  return (
    <div className="customNameWrapper">
      {logo && (
      <div className={logo ? 'imageContainer' : 'imageContainer default'}>
        <div className="image">
          {logo && <img src={logo} alt="logo" />}
        </div>
      </div>
      )}
      <span style={{ color: textColor || '#000' }}>{name}</span>
    </div>
  );
}

export function EditButtons({ id, filterItemsAfterDelete }: {id: number, filterItemsAfterDelete: (id: number) => void}) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);

  const menuRef = useRef<any>();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useClickOutside(menuRef, () => setMenuOpen(false));

  return (
    <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        <button type="button" onClick={() => setModalIndex(1)}>
          <DeleteIcon />
        </button>
      </div>
      {modalIndex === 1 && (
        <ModalConfirmDecline
          onClose={() => setModalIndex(0)}
          onDecline={() => setModalIndex(0)}
          onConfirm={() => {
            Api.deleteUser(id)
              .then((res) => {
                if (res.statusCode >= 200 && res.statusCode < 300) {
                  filterItemsAfterDelete(id);
                  setModalIndex(0);
                } else {
                  setModalErrorIndex(1);
                }
              });
          }}
          title={getTranslationByLangOrEng(interfaceLanguage, 'languages_table_modal_delete_title')}
          buttonsLabel={getTranslationByLangOrEng(interfaceLanguage, 'users_main_delete_description')}
          confirmText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          firstButtonStyles={{ border: '1px solid #C4C4C4', backgroundColor: '#ffffff', color: '#000000' }}
          secondButtonStyles={{ backgroundColor: '#FF0101', border: '1px solid #FF0101' }}
          error={modalErrorIndex === 1}
          errorText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
        />
      )}
    </div>
  );
}
