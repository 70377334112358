import * as Yup from 'yup';
import { every } from 'lodash';

// nodeAddressMustBeShown
// required

export interface IFormField {
  type: 'title'
    | 'name'
    | 'email'
    | 'phone_number'
    | 'extra_question'
    | 'checkbox'
    | 'radio'
    | 'scale'
    | 'upload_files'
    | 'networks'
    | 'document';
  required: boolean;
  answer: string;
}

const commonFieldSchema = Yup.object().shape({
  required: Yup.boolean(),
  answer: Yup.string().when('required', {
    is: true,
    then: Yup.string().required('* Required'),
    otherwise: Yup.string().nullable(),
  }),
});

const scaleFieldSchema = Yup.object().shape({
  required: Yup.boolean(),
  answer: Yup.string().when('required', {
    is: true,
    then: Yup.string().test({
      name: 'selected',
      message: '* Required',
      test: (val) => val !== undefined && +val > 0,
    }),
    otherwise: Yup.string().nullable(),
  }),
});

const minOneByTypeSchema = Yup.object().shape({
  required: Yup.boolean(),
  // TODO: change answer schema from object to array and add validation
  answer: Yup.array().when('required', {
    is: true,
    then: Yup.array().of(Yup.lazy((obj: Object | string | undefined) => (typeof obj === 'string' ? Yup.string() : Yup.object())) as any).min(1, '* Required').test({
      name: 'one-true',
      message: '* You must upload at least 1 file',
      test: (val) => !every(val, ['value', '' || undefined]),
    }),
    otherwise: Yup.array(),
  }),
});

export const fieldsValidation = {
  title: Yup.object(),
  name: commonFieldSchema,
  email: commonFieldSchema,
  phone_number: commonFieldSchema,
  extra_question: commonFieldSchema,
  checkbox: Yup.object().shape({
    required: Yup.boolean(),
    options: Yup.array()
      .of(
        Yup.object().shape({
          selected: Yup.boolean(),
        }),
      ).when('required', {
        is: true,
        then: Yup.array().test({
          name: 'one-true',
          message: '* You must select at least 1 option',
          test: (val) => !every(val, ['value', '' || undefined]),
        }),
        otherwise: Yup.array(),
      }),
  }),
  radio: Yup.object().shape({
    required: Yup.boolean(),
    selectedOption: Yup.string().nullable(),
  }),
  scale: scaleFieldSchema,

  // TODO: add validation
  upload_files: minOneByTypeSchema,
  networks: minOneByTypeSchema,

  document: Yup.object().shape({
    required: Yup.boolean(),
    selectedInPreview: Yup.boolean().when('required', {
      is: true,
      then: Yup.boolean().oneOf([true], '* Required'),
      otherwise: Yup.boolean(),
    }),
  }),
};
