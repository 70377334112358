import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IExtraField, IFormDocument,
  ISendButton, IThankYouPage,
  IForm, INotSavedForm,
  IColor,
} from '../../entities';

export interface NotSavedFormState {
  notSavedForm: INotSavedForm | undefined;
  notSavedExtraFields: IExtraField[];
  notSavedSendButton: ISendButton | undefined;
  notSavedDocuments: IFormDocument[];
  notSavedThankYouPage: IThankYouPage | undefined;
  notSavedColors: IColor[];
}

const initialState: NotSavedFormState = {
  notSavedForm: undefined,
  notSavedExtraFields: [],
  notSavedSendButton: undefined,
  notSavedDocuments: [],
  notSavedThankYouPage: undefined,
  notSavedColors: [],
};

export const notSavedFormSlice = createSlice({
  name: 'notSavedForm',
  initialState,
  reducers: {
    // Reducer comes here
    setNotSavedForm: (state, action: PayloadAction<IForm | undefined>) => ({ ...state, notSavedForm: action.payload }),
    setNotSavedExtraFields: (state, action: PayloadAction<IExtraField[]>) => ({ ...state, notSavedExtraFields: action.payload }),
    addNotSavedExtraField: (state, action: PayloadAction<IExtraField>) => ({ ...state, notSavedExtraFields: [...state.notSavedExtraFields, action.payload] }),
    deleteNotSavedExtraField: (state, action: PayloadAction<number>) => ({
      ...state,
      notSavedExtraFields: state.notSavedExtraFields
        ? state.notSavedExtraFields.filter((item) => item.id !== action.payload)
        : state.notSavedExtraFields,
    }),
    updateNotSavedExtraField: (state, action: PayloadAction<IExtraField>) => {
      const fieldIndex = state.notSavedExtraFields.findIndex((item) => item.id === action.payload.id);

      return ({
        ...state,
        notSavedExtraFields: [
          ...state.notSavedExtraFields.slice(0, fieldIndex),
          action.payload,
          ...state.notSavedExtraFields!.slice(fieldIndex + 1),
        ],
      });
    },
    setNotSavedSendButton: (state, action: PayloadAction<ISendButton | undefined>) => ({ ...state, notSavedSendButton: action.payload }),
    setNotSavedDocuments: (state, action: PayloadAction<IFormDocument[]>) => ({ ...state, notSavedDocuments: action.payload }),
    addNotSavedDocument: (state, action: PayloadAction<IFormDocument>) => ({ ...state, notSavedDocuments: [...state.notSavedDocuments, action.payload] }),
    deleteNotSavedDocument: (state, action: PayloadAction<number>) => ({
      ...state,
      notSavedDocuments: state.notSavedDocuments
        ? state.notSavedDocuments.filter((item) => item.id !== action.payload)
        : state.notSavedDocuments,
    }),
    updateNotSavedDocument: (state, action: PayloadAction<IFormDocument>) => {
      const fieldIndex = state.notSavedDocuments.findIndex((item) => item.id === action.payload.id);

      return ({
        ...state,
        notSavedDocuments: [
          ...state.notSavedDocuments.slice(0, fieldIndex),
          action.payload,
          ...state.notSavedDocuments!.slice(fieldIndex + 1),
        ],
      });
    },
    setNotSavedThankYouPage: (state, action: PayloadAction<IThankYouPage | undefined>) => ({ ...state, notSavedThankYouPage: action.payload }),
    setNotSavedColors: (state, action: PayloadAction<IColor[]>) => ({ ...state, notSavedColors: action.payload }),
  },
  extraReducers: (builder) => {},
});

export const { actions: notSavedFormActions, reducer: notSavedFormReducer } = notSavedFormSlice;
// Extract and export each action creator by name
export const {
  setNotSavedForm,
  setNotSavedExtraFields,
  addNotSavedExtraField,
  deleteNotSavedExtraField,
  updateNotSavedExtraField,
  setNotSavedSendButton,
  setNotSavedDocuments,
  addNotSavedDocument,
  deleteNotSavedDocument,
  updateNotSavedDocument,
  setNotSavedThankYouPage,
  setNotSavedColors,
} = notSavedFormActions;
