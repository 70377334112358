import styled from 'styled-components';

export const NotifiableSourceStyles = styled.div`
    width: 100%;

        .company-wrapper {
            display: flex;
            flex-direction: column;
            margin: 10px 0;
            gap: 2px;
        }
`;
