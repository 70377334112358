import {
  IExtraField, IOption, INewAnswerStructure, DeepArrayItem,
} from '../entities';

export function isEmpty(obj: any) {
  return Object.keys(obj).length === 0;
}

export function truncate(text: string, size: number) {
  return text.length > size ? `${text.slice(0, size - 1)}…` : text;
}

export function oneInArray(arr: any, values: any) {
  return values.find((value: any) => arr.includes(value));
}

export function addFieldTypeToAnswer(answer: INewAnswerStructure, fields: IExtraField[]) {
  let type: string = '';
  const fieldById = fields.find((field: any) => field?.id === answer?.fieldID)!;
  if (fieldById?.type === 'scale') {
    type = `${fieldById?.type}_${fieldById?.selectedOption}`
  } else {
    type = fieldById?.type;
  }
  return type;
}

export function addFieldPositionToAnswer(answer: INewAnswerStructure, fields: IExtraField[]) {
  return fields.find((field: any) => field?.id === answer?.fieldID)?.position!;
}

export function addKeyQuestionValueToAnswer(answer: INewAnswerStructure, fields: IExtraField[]) {
  let isKeyQuestion: boolean = false;
  const fieldById = fields.find((field: any) => field?.id === answer?.fieldID)!;

  if (fieldById?.keyQuestion) {
    isKeyQuestion = fieldById?.keyQuestion
  }

  return isKeyQuestion;
}

export function addNewScaleAnswers(answers: IOption[], maxAnswersValue: number) {
  const currentAnswerValues = answers?.map((item: any) => +item.value);
  const theBiggestNumber = Math.max(...currentAnswerValues);
  const differentBetweenTheBiggestNumberAndMaxValue = maxAnswersValue - theBiggestNumber;
  const newScaleAnswers = Array(differentBetweenTheBiggestNumberAndMaxValue)
    .fill(theBiggestNumber)
    .map((item, index) => (index === 0
      ? { fieldID: undefined, text: `${item + 1}`, nextField: undefined }
      : { fieldID: undefined, text: `${item + index + 1}`, nextField: undefined }
    ));

  return newScaleAnswers;
}

export function hasNonEmptyChildren(array: DeepArrayItem[]) {
  for (let i = 0; i < array.length; i++) {
    if (array[i].children && array[i].children.length > 0) {
      return true;
    }
  }
  return false;
}
