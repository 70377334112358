import styled from 'styled-components';

export const CheckboxFieldStyles = styled.div`
  margin-bottom: 24px;
  max-width: 540px;
  width: 100%;
  border: 1px solid #F0F5F5;
  border-radius: 4px;
  background-color: #fff;

  .body {
    padding: 24px 16px 0 16px;
    box-sizing: border-box;

    .inputContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }

    .nameContainer {
      font-family: Roboto, sans-serif;
      font-size: 14px;
      color: #000;
      margin-bottom: 4px;
    }

    .inputRaw {
      .inputElementContainer {
        display: grid;
        grid-template-columns: 1fr auto;
      }

      input {
        height: 40px;
        font-size: 14px;
        color: #000;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;

        &::placeholder {
          font-family: Roboto, sans-serif;
          font-size: 14px;
          color: #979797;
        }

        &.disabled {
          background-color: #fafafa;
        }
      }

      .infoContainer {
        width: 34px;
        height: 40px;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover .infoText {
          display: block;
        }

        .infoIcon {
          width: 16px;
          height: 16px;
          border-radius: 12px;
          border: 1px solid #0E9285;
          display: grid;
          place-items: center;

          svg {
            width: 12px;
            height: 12px;
            filter: invert(42%) sepia(68%) saturate(490%) hue-rotate(125deg) brightness(96%) contrast(99%);
          }
        }

        .infoText {
          display: none;
          width: 280px;
          padding: 16px;
          position: absolute;
          right: -8px;
          top: 38px;
          font-size: 14px;
          color: #000;
          background-color: #fff;
          border-radius: 4px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .infoContainer {
    height: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    &:hover .infoText {
      display: block;
    }

    .infoIcon {
      width: 16px;
      height: 16px;
      border-radius: 12px;
      border: 1px solid #0E9285;
      display: grid;
      place-items: center;

      svg {
        width: 12px;
        height: 12px;
        filter: invert(42%) sepia(68%) saturate(490%) hue-rotate(125deg) brightness(96%) contrast(99%);
      }
    }

    .infoText {
      display: none;
      width: 280px;
      padding: 16px;
      position: absolute;
      right: -8px;
      top: 38px;
      z-index: 10;
      font-size: 14px;
      color: #000;
      background-color: #fff;
      border-radius: 4px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
    }
  }
  
  .addFieldButtonContainer {    
    input {
      height: 32px;
      font-size: 14px;
      color: #000;
      background-color: #FFFFFF;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 16px;

      &::placeholder {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        color: #979797;
      }

      &.disabled {
        background-color: #fafafa;
      }
    }
  }

  .rowItemContainer {
    height: 40px;
    display: flex;
    align-items: center;
  }
  
  .verticalLine {
    width: 1px;
    height: 40px;
    background-color: #eee;
  }
  
  .choiceDescriptionCheckboxContainer {
    display: flex;
    align-items: center;
    
    .iconContainer {
      width: 18px;
      height: 18px;
      border-radius: 9px;
      display: grid;
      place-items: center;
      background-color: #0E9285;
      margin-right: 8px;
    }
  }
  
  .choicePlaceholderContainer {
    margin-top: 8px;
  }
  
  .checkboxFieldContainer {
    display: grid;
    grid-template-columns: 1fr auto 1px auto auto;
    grid-gap: 0 16px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
