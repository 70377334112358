import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import React, { useCallback, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom';
import { Api, ApiGoogleAccounts, ApiGoogleLocations } from '../../../api';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { Loader, TransparentButton } from '../../atoms';
import InputField from '../../form/inputField/InputField';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { Table } from '../../organisms';
import { CompanyIntegrationsStyles } from './CompanyIntegrationsStyles';
import { extraFieldsSettings, FormValues, validationSchema } from './CompanyIntegrationsUtils';

export const CompanyIntegrations = React.memo(() => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const code = searchParams.get('code');
  const { id } = useParams()

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [accounts, setAccounts] = useState<ApiGoogleAccounts[]>([]);
  const [initialValues, setInitialValues] = useState<FormValues>({ clientId: '' })
  const [locations, setLocations] = useState<ApiGoogleLocations[]>([]);
  const [tableHeaders, setTableHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>();
  const clientId = localStorage.getItem(`clientId_${id}`);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { data } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (code) {
      setIsLoading(true)
      Api.saveGoogleToken({ accessCode: code, companyId: id!, clientId: clientId! }, data?.id!).then((res) => {
        setAccounts(res.data ? res.data : [])
        setIsLoading(false)
      })
    }
  }, [location])

  useEffect(() => {
    if (code) {
      setInitialValues({ clientId: clientId as string })
    }
  }, [clientId])

  useEffect(() => {
    setIsLoading(true)
    Api.verifyGoogleLocations(+id!).then((res) => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        setInitialValues({ clientId: res.data.clientId })
        Api.postGoogleLocations(data?.id!, { accountId: res.data.accountId, companyId: id! }).then((resp) => {
          setLocations(resp.data)
        })
      }
      setIsLoading(false)
    })
  }, [])

  useEffect(() => {
    if (locations.length) {
      setTableHeaders([
        {
          Header: 'ID',
          accessor: 'id',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.id}</span>
            </div>
          ),
        },
        {
          Header: 'Title',
          accessor: 'title',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.title}</span>
            </div>
          ),
        },
        {
          Header: 'Address',
          accessor: 'address',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.address}</span>
            </div>
          ),
        },
        {
          Header: 'Synchronization',
          accessor: 'sync',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <div className={`dot ${data.row.original.sync ? 'active' : ''}`} />
            </div>
          ),
        },
      ]);
    }
  }, [locations, interfaceLanguage]);

  const handleSendAccount = useCallback((locId: string) => {
    Api.postGoogleLocations(data?.id!, { accountId: locId.split('/')[1], companyId: id! }).then((res) => {
      setLocations(res.data)
    })
  }, [])

  const handleLogout = useCallback(() => {
    Api.logoutGoogle(id!).then((res) => {
      console.log(res);
    })
  }, [])

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="connected">
        <InputField
          name="clientId"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('clientId', setErrorMessage, errorMessage)}
          placeholder="Client Id"
          value={values.clientId}
          error={typeof errorMessage === 'object' ? getErrorMessage('clientId', errorMessage) : undefined}
          label="Google clientId"
          disabled={!!initialValues.clientId}
        />

        {(!initialValues.clientId) ? (
          <SubmitButton
            isLoading={isLoading}
            isError={touched && Object.keys(errors).length > 0}
          >
            Send
          </SubmitButton>
        ) : (
          <TransparentButton handleClick={handleLogout} text="Logout" filled />
        )}

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}

        {!!accounts.length && (
          <div className="accountsContainer">
            {accounts.map((account) => <TransparentButton text={account.accountName} handleClick={() => handleSendAccount(account.name)} />)}
          </div>
        )}

        {!!locations && !!tableHeaders && (
        <div className="tableUsers">
          <Table
            columns={tableHeaders}
            data={locations}
            extraFieldsSettings={extraFieldsSettings}
            hideFieldsSplitters
            fullWidthColumns={['address']}
          />
        </div>
        )}
      </div>

    </Form>
  );

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    const form = document.createElement('form');
    form.setAttribute('method', 'GET');
    form.setAttribute('action', process.env.REACT_APP_GOOGLE_OAUTH2!);

    const params: { [key: string]: string } = {
      client_id: values.clientId,
      redirect_uri: window.location.href,
      response_type: 'code',
      scope: process.env.REACT_APP_GOOGLE_SCOPE!,
      include_granted_scopes: 'true',
      state: 'random',
      access_type: 'offline',
      prompt: 'consent',
    };

    // eslint-disable-next-line guard-for-in
    for (const p in params) {
      const input = document.createElement('input');
      input.setAttribute('type', 'hidden');
      input.setAttribute('name', p);
      input.setAttribute('value', params[p] as string);
      form.appendChild(input);
    }

    localStorage.setItem(`clientId_${id}`, values.clientId);

    document.body.appendChild(form);
    form.submit();

    setSubmitting(false)
  }

  if (isLoading) {
    return <Loader />
  }

  return (
    <CompanyIntegrationsStyles>
      <div className="formContainer">
        <div className="integrationHeader">
          <h4>Google</h4>
          <div className={`dot ${initialValues.clientId ? 'active' : ''}`} />
        </div>
        <Formik
          key={JSON.stringify(initialValues)}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      </div>
    </CompanyIntegrationsStyles>
  )
})
