import styled from 'styled-components';

export const CompanyTemplatesStyles = styled.div`
  padding: 26px 32px 80px 16px;
  
  .formContainer {
    display: grid;
    gap: 34px;
  }

  form {
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    border-radius: 4px;
    max-width: 991px;
    width: 100%;
    padding: 24px 16px;
    
    padding-bottom: 24px;
    
    .blockHead {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 9px 11px 9px 16px;
      background: #F0F5F5;
      
      .caption {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;

        color: #000000;
      }
    }
    
    .arrayWrapper {
      .templateContainer {
        margin: 10px 0;
        padding: 20px 10px;
        border: 1px solid #F0F0F0;
        border-radius: 4px;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .templateKey {
          font-weight: bold;
          text-align: center;
          width: 15%;
        }

        .templateContent {
         width: 70%;

          textarea {
            resize: none;
            border: 1px solid #E5E5E5;
            border-radius: 4px;
            padding: 12px 16px;
            width: 100%;
            height:auto;
            box-sizing: border-box;

            ::-webkit-scrollbar {
              display: none;
            }
          }
        }

        .buttons {
          width: 15%;
          display: flex;
          justify-content: center;

          button:nth-child(1) {
            margin-right: 12px;
          }
        }
      }
    }
    
    .emailsArrayWrapper {
      display: grid;
      gap: 24px;
    }
  }
`;
