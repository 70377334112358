import styled from 'styled-components';

export const ColorScaleStyles = styled.div<{signatureColor: string | undefined}>`
  display: flex;
  flex-direction: column;
  align-items: center;
  
  .numbers {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    padding: 0 -10px;
    
    span {
      width: 20px;
      text-align: center;
    }
  }
  
  input {
    width: 200px;
  }
  
  .signatures {
    margin-top: 7px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    color: ${({ signatureColor }) => signatureColor || '#b4b4b4'};;
  }
`;
