import React, { CSSProperties, useCallback, useRef } from 'react';
import { ToggleSwitcherStyles } from './ToggleSwitcherStyles';

interface ToggleSwitcherProps {
  name: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  extraBlockStyles?: CSSProperties;
  label?: string;
  error?: string;
  disabled?: boolean;
  children?: any;
  hideError?: boolean;
}

export const ToggleSwitcher = React.memo(({
  name,
  value,
  onChange,
  extraBlockStyles,
  label,
  error,
  disabled,
  children,
  hideError = false,
}: ToggleSwitcherProps) => {
  const inputRef = useRef(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
    }
  }, [inputRef]);

  return (
    <ToggleSwitcherStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label && <span>{label}</span>}
        <input type="checkbox" id={name} checked={value} ref={inputRef} onChange={onChange} disabled={disabled} />
        <div className="toggleSwitcherWrapper">
          <div
            className="customizedToggleSwitcher"
            style={label ? { marginTop: 16 } : {}}
            onChange={() => (disabled ? null : handleOnClick)}
          >
            <div className={value ? 'circle checked' : 'circle'} />
          </div>
          {children}
        </div>
      </label>
      {!hideError && (
        <div className="formErrorContainer">
          <span>{error}</span>
        </div>
      )}
    </ToggleSwitcherStyles>
  );
})
