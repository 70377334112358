import { useParams } from 'react-router-dom';
import React, { ForwardedRef, useEffect, useState } from 'react';
import {
  IColorScaleSettings, INumberScaleSettings,
  IStarScaleSettings, INpsScaleSettings,
} from '../../../entities';
import { useAppDispatch, useAppSelector, setSelectedScaleType } from '../../../state';
import { ScaleConstructorStyles } from '../../../pages/companies/formConsructor/scaleConstructor/ScaleConstructorStyles';
import ColorsScaleConstructor from '../../scaleConstuctors/colorsScaleConstructor/ColorsScaleConstructor';
import NumbersScaleConstructor from '../../scaleConstuctors/numbersScaleConstructor/NumbersScaleConstructor';
import StarsScaleConstructor from '../../scaleConstuctors/starsScaleConstructor/StarsScaleConstructor';
import { useCompanyImages, useCompanyColors } from '../../../hooks';
import NpsScaleConstructor from '../../scaleConstuctors/npsScaleConstructor/NpsScaleConstructor';
import { FormikProps } from 'formik';

interface ScaleFieldSettingsFormProps {
  index: number;
  selectedTab: string;
  handleCancel: () => void;
  resetForm?: (values: IColorScaleSettings | INumberScaleSettings | IStarScaleSettings | INpsScaleSettings) => void;
  formReinitialized?: boolean;
}

const ScaleFieldSettingsForm = React.forwardRef<FormikProps<IColorScaleSettings | INumberScaleSettings | IStarScaleSettings | INpsScaleSettings>, ScaleFieldSettingsFormProps>(({
  index, selectedTab, handleCancel, resetForm, formReinitialized,
}, ref) => {
  const dispatch = useAppDispatch();
  const { companyId } = useParams();

  const { companyColorsLoaded, companyColors } = useCompanyColors(companyId!);
  const { companyImagesLoaded, companyImages } = useCompanyImages(companyId!);

  const [colorScaleSettings, setColorScaleSettings] = useState<IColorScaleSettings>();
  const [numberScaleSettings, setNumberScaleSettings] = useState<INumberScaleSettings>();
  const [starScaleSettings, setStarScaleSettings] = useState<IStarScaleSettings>();
  const [npsScaleSettings, setNpsScaleSettings] = useState<INpsScaleSettings>();

  const { notSavedForm, notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);
  const { selectedScaleType } = useAppSelector((state) => state.scaleSettings);

  useEffect(() => {
    if (notSavedForm) {
      if (companyImagesLoaded && companyColorsLoaded) {
        const fieldSettings = notSavedExtraFields![index];
        dispatch(setSelectedScaleType(notSavedExtraFields[index].selectedOption));

        if (notSavedExtraFields[index].selectedOption === 'color') {
          setColorScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColors!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            mascots: fieldSettings.mascots || [],
            scalePointerColor: fieldSettings.scalePointerColor,
            maxValue: fieldSettings.maxValue,
            scaleGradientColors: fieldSettings.scaleGradientColors || [],
            signatureColor: fieldSettings.signatureColor,
          });
        }

        if (notSavedExtraFields[index].selectedOption === 'number') {
          setNumberScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColors!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            showDefaultValue: fieldSettings.showDefaultValue!,
            mascots: fieldSettings.mascots || [],
            maxValue: fieldSettings.maxValue,
            numbersColor: fieldSettings.numbersColor,
            selectedButtonColor: fieldSettings.selectedButtonColor,
            signatureColor: fieldSettings.signatureColor,
          });
        }

        if (notSavedExtraFields[index].selectedOption === 'star') {
          setStarScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColors!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            showDefaultValue: fieldSettings.showDefaultValue!,
            mascots: fieldSettings.mascots || [],
            maxValue: fieldSettings.maxValue,
            notSelectedStarBackground: fieldSettings.notSelectedStarBackground,
            notSelectedStarBorder: fieldSettings.notSelectedStarBorder,
            selectedStarColor: fieldSettings.selectedStarColor,
            signatureColor: fieldSettings.signatureColor,
          });
        }

        if (notSavedExtraFields[index].selectedOption === 'nps') {
          setNpsScaleSettings({
            id: fieldSettings.id,
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColors!, ...(fieldSettings.backgroundColors || [])],
            backgroundColors: fieldSettings.backgroundColors || [],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            showDefaultValue: fieldSettings.showDefaultValue!,
            mascots: fieldSettings.mascots || [],
            signatureColor: fieldSettings.signatureColor,
          });
        }
      }
    } else {
      handleCancel();
    }
  }, [companyImagesLoaded, companyColorsLoaded, formReinitialized, index]);

  return (
    <ScaleConstructorStyles>
      {companyImagesLoaded && companyColorsLoaded && (colorScaleSettings || numberScaleSettings || starScaleSettings || npsScaleSettings) && (
        <div className="contentWrapper">
          {selectedScaleType === 'color' && <ColorsScaleConstructor ref={ref as ForwardedRef<FormikProps<IColorScaleSettings>>} resetForm={resetForm} selectedTab={selectedTab} scaleIndex={index} companyImages={companyImages!} companyColors={companyColors!} settings={colorScaleSettings!} handleCancel={() => handleCancel()} />}
          {selectedScaleType === 'number' && <NumbersScaleConstructor ref={ref as ForwardedRef<FormikProps<INumberScaleSettings>>} resetForm={resetForm} selectedTab={selectedTab} scaleIndex={index} companyImages={companyImages!} companyColors={companyColors!} settings={numberScaleSettings!} handleCancel={() => handleCancel()} />}
          {selectedScaleType === 'star' && <StarsScaleConstructor ref={ref as ForwardedRef<FormikProps<IStarScaleSettings>>} resetForm={resetForm} selectedTab={selectedTab} scaleIndex={index} companyImages={companyImages!} companyColors={companyColors!} settings={starScaleSettings!} handleCancel={() => handleCancel()} />}
          {selectedScaleType === 'nps' && <NpsScaleConstructor ref={ref as ForwardedRef<FormikProps<INpsScaleSettings>>} resetForm={resetForm} selectedTab={selectedTab} scaleIndex={index} companyImages={companyImages!} companyColors={companyColors!} settings={npsScaleSettings!} handleCancel={() => handleCancel()} />}
        </div>
      )}
    </ScaleConstructorStyles>
  );
});

export default ScaleFieldSettingsForm;
