import React, { useState, useRef, useEffect } from 'react';
import { CompanyFilterStyles } from './CompanyFilterStyles';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import CustomSelect from '../../../form/customSelect/CustomColorsSelect';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { CompaniesMainInfo } from '../../../../entities/ICompany';
import { Loader } from '../../..';

interface CompanyFilterValues {
    company: { id: number; name: string; } | undefined;
}

interface ICompanyFilter {
  companies: {[key: string]: CompaniesMainInfo | any},
  filterParams: {[key: string]: number | string | any },
  setReQuery: () => void,
  setApply: (params: {[key: string] : number | string }) => void,
  setFilterParams: (params: {[key: string] : number | string }) => void,
}

export const CompanyFilter = React.memo(({
  companies, filterParams, setReQuery, setApply, setFilterParams,
} : ICompanyFilter) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [initialValues, setInitialValues] = useState<CompanyFilterValues>();

  const ref = useRef<any>();
  const formRef = useRef<FormikProps<CompanyFilterValues>>(null);
  const companiesRef = useRef<{ id: number; name: string; }[]>([]);

  async function onSubmit(
    values: CompanyFilterValues,
    { setSubmitting }: FormikHelpers<CompanyFilterValues>,
  ) {
    const params: { [key: string]: number | string | any} = {};

    if (values.company?.id) {
      params.companyID = +values.company.id;
    } else if (filterParams?.companyID && !values.company) {
      delete filterParams.companyID;
    }

    setFilterParams({ ...params });
    setApply({ ...params });
    setReQuery();
    setSubmitting(false);
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleSubmit,
  }: FormikProps<CompanyFilterValues>) => (
    <Form>
      <div>
        <CustomSelect
          name="company"
          options={companiesRef.current}
          placeholder={translations[interfaceLanguage].select_company}
          value={values.company}
          handleSelect={(value) => {
            setFieldValue('company', value);
            handleSubmit();
          }}
          search
          hideErrors
        />
      </div>
    </Form>
  );

  useEffect(() => {
    const companiesArray = Object.keys(companies).map((key) => ({ id: +key, name: companies[key].name }));
    const company = filterParams?.companyID
      ? companiesArray.find((company) => +company.id === +filterParams.companyID) : !filterParams?.companyID && companiesArray.length <= 1 ? companiesArray[0] : undefined;

    companiesRef.current = companiesArray;
    if (companiesArray.length <= 1 && company) {
      setFilterParams({ ...filterParams, companyID: company!.id });
      setApply({ ...filterParams, companyID: company!.id });
      setReQuery();
    }
    setInitialValues({ company });
  }, [])

  return (
    <CompanyFilterStyles ref={ref}>
      {initialValues ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {renderForm}
        </Formik>
      ) : <Loader margin={0} />}
    </CompanyFilterStyles>
  )
})
