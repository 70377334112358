import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const SolutionNotRequiredIcon: FC<TIcon> = React.memo(({ width = 22, height = 22, color = '#0E9285' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <g>
      <path
        fill="#27C360"
        d="M581.6,145.2L581.6,145.2l-464.9,0c-9.2,0-16.5,7.9-16.5,17.2v311.9c0,9.2,7.3,17.1,16.5,17.1h71.4v101
    c0,4.4,1.8,8.7,5,11.8c3.2,3.1,7.4,4.9,11.9,4.9c4.1,0.1,8.2-1.4,11.3-4.2l125.3-113.6h240.1c9.2,0,16.5-7.8,16.5-17.1V162.4
    C598.1,153.1,590.8,145.2,581.6,145.2z"
      />
      <path
        fill="#FFFFFF"
        d="M236.8,279c9.3-9.3,24.4-9.3,33.7,0l59.3,60l95.3-95.9c9.3-9.3,24.4-9.3,33.7,0c9.3,9.4,9.3,24.6,0,34.1
    L346.7,389.9c-9.3,9.3-24.4,9.3-33.7,0L236.8,313C227.5,303.6,227.5,288.4,236.8,279L236.8,279z"
      />
    </g>
  </svg>
))
