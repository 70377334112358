import React, { CSSProperties } from 'react';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { Modal } from '../Modal';
import { DeleteModalStyles } from './DeleteModalStyles';

type Props = {
  extraStyles?: CSSProperties,
  onDecline: () => void,
  onConfirm: () => void,
  onClose: () => void,
  title?: string,
  description?: string,
  confirmText?: string,
  declineText?: string,
  children?: any
}

export const DeleteModal = React.memo(({
  onDecline, extraStyles, onConfirm, onClose, title, description, confirmText, declineText, children,
}: Props) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <DeleteModalStyles>
      <Modal onClose={onClose} extraStyles={extraStyles}>
        <div className="modalHeader">
          <h3>{title || getTranslationByLangOrEng(interfaceLanguage, 'changes_saved_successfully')}</h3>
        </div>

        <div className="modalContent">
          {description && <p className="modalDescription">{description}</p>}

          {children}
        </div>

        <div className="modalFooter">
          <button type="button" className="declineButton" onClick={onDecline}>{declineText || getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button')}</button>
          <button type="button" className="confirmButton" onClick={onConfirm}>{confirmText || getTranslationByLangOrEng(interfaceLanguage, 'continue_editing_button')}</button>
        </div>
      </Modal>
    </DeleteModalStyles>
  );
});
