import styled from 'styled-components';

export const FormConstructorStyles = styled.div<{ showDiagram: boolean }>`
  .pageContentWrapper {
    position: relative;
    width: 100%;
    height: 100%;
    max-height: ${({ showDiagram }) => (showDiagram ? 'calc(100vh - 217px)' : 'none')};
    overflow: ${({ showDiagram }) => (showDiagram ? 'hidden' : 'none')};

    .formSectionTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 48px;
      padding: 0 16px;
      border-bottom: 1px solid #e5e5e5;
    }
    
    .diagramWrapper {
      position: absolute;
      inset: 0;
      background-color: #fff;
      height: calc(100vh - 217px);
      display: grid;
      grid-template-columns: 1fr 340px;


      .settingsWrapper {
        .selectedFieldPreviewContainer {
          margin-bottom: 12px;
          padding-bottom: 12px;
          border-bottom: 1px solid #bbb;
        }
      }

      .content {
        height: calc(100vh - 212px);
        border-left: 1px solid #eee;
      }

      .tabs {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 1px;
        background-color: #eee;

        button {
          width: 100%;
          height: 40px;
          display: grid;
          place-items: center;
          background-color: #fff;
          border-bottom: 1px solid #eee;
        }
      }

      .extraFieldsList {
        display: grid;
        grid-gap: 16px;
      }

      .settings {
        height: calc(100vh - 256px);
        background-color: #f3f3f3;
        padding: 16px;
        box-sizing: border-box;
        border-bottom: 1px solid #e5e5e5;
        overflow-y: scroll;

        .connectionsList,
        .addCaseWrapper {
          margin-bottom: 24px;
        }
      }
    }
  }

  .tabsContainer {
    width: 100%;
    height: 48px;
    border-bottom: 1px solid #eee;
    padding: 0 16px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .tabs {
      display: flex;
      align-items: flex-end;
      box-sizing: border-box;

      button {
        height: 24px;
        display: flex;
        align-items: flex-start;
        border-bottom: 3px solid #FFF;
        margin-right: 32px;

        font-size: 15px;
        line-height: 16px;
        color: #7C7878;
        transition: all 0.3s;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          border-bottom: 3px solid #0E9285;
          color: #0E9285;
          transition: all 0.3s;
        }
      }
    }

    .saveFormButtonContainer {
      display: flex;
      align-items: center;
    }
  }
  
  .fieldsListWrapper {
    &.hideContent {
      grid-template-columns: 1fr;
    }
    
    .fieldsCreation {
      min-height: calc(100vh - 217px);
      display: grid;
      grid-template-columns: 1fr 300px;

      .formExtraFields {
        height: calc(100vh - 412px);
        overflow-y: scroll;
      }
    }
    
    .selectedFieldWrapper {
      box-sizing: border-box;
      
      .noSelectedField {
        padding: 24px;
        font-size: 16px;
        font-weight: 600;
        color: #0E9285;
      }
      
      .selectedField {
        padding: 24px;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        
        & > div:nth-child(2) {
          box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.02);
        }
      }
    }
    
    .formContentWrapper {
      border-left: 1px solid #e5e5e5;
      //min-height: calc(100vh - 168px);
      
      .extraFieldsList {
        display: grid;
        grid-gap: 16px;
      }
    }
  }

  .formExtraFields, .freeError {
    padding: 16px;
    box-sizing: border-box;
    border-bottom: 1px solid #e5e5e5;
  }
  
  .diagramWrapper .formExtraFields {
    height: calc(100vh - 354px);
    overflow-y: scroll;
    box-sizing: border-box;
  }

  .formExtraFields {
    background-color: #f3f3f3;
  }

  .icons svg {
    width: auto;
    height: 24px;
  }

  .sendButtonFieldContainer {
    width: 540px;
    padding: 24px 32px 0 16px;
  }

  .bottomButtonsContainer {
    width: 540px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 24px 32px 24px 16px;
    
    & > div {
      width: 100%;

      a, button {
        width: 100%;
      }
    }
  }

  .horizontalLine {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 24px 0;
  }

  .previewButtonIconContainer {
    margin-right: 10px;
  }
  
  .targetDeleteFieldContainer {
    display: flex;
    justify-content: center;
    
    .targetDeleteFieldWrapper {
      width: 100%;
      max-width: 300px;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      
      svg {
        height: 12px;
      }
    }
  }
`;
