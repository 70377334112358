import React, { CSSProperties } from 'react';
import { TransparentButtonStyles } from './TransparentButtonStyles';
import { Link } from 'react-router-dom';

interface TransparentButtonProps {
  handleClick?: () => void;
  text?: string;
  filled?: boolean;
  childrenPosition?: 'left' | 'right',
  extraStyles?: CSSProperties,
  disabled?: boolean,
  isLink?: boolean,
  linkAddress?: string,
  extraButtonTextStyles?: CSSProperties,
  extraContainerStyles?: CSSProperties,
  targetIsBlank?: boolean,
  submit?: boolean,
  form?: string,
}

export const TransparentButton: React.FC<TransparentButtonProps> = ({
  text,
  handleClick,
  filled,
  childrenPosition = 'right',
  extraStyles,
  children,
  disabled,
  isLink,
  linkAddress,
  extraButtonTextStyles,
  extraContainerStyles,
  targetIsBlank,
  submit,
  form,
}) => (
  <TransparentButtonStyles disabled={!!disabled} style={extraContainerStyles}>
    {(isLink && linkAddress) ? (
      <Link
        to={linkAddress}
        className={filled ? 'filled' : ''}
        onClick={(disabled || !handleClick) ? () => null : () => handleClick()}
        style={extraStyles || {}}
        target={targetIsBlank ? '_blank' : undefined}
      >
        <div className="linkContentWrapper">
          {childrenPosition === 'left' && children}
          <span>{text}</span>
          {childrenPosition === 'right' && children}
        </div>
      </Link>
    ) : (
      <button
        className={filled ? 'filled' : ''}
        type={submit ? 'submit' : 'button'}
        onClick={(disabled || !handleClick) ? () => null : () => handleClick()}
        style={extraStyles || {}}
        disabled={disabled}
        form={form}
      >
        {childrenPosition === 'left' && children}
        <span style={extraButtonTextStyles || {}}>{text}</span>
        {childrenPosition === 'right' && children}
      </button>
    )}
  </TransparentButtonStyles>
);
