import styled from 'styled-components';

export const TextAreaStyles = styled.div`
  textarea {
    resize: none;
    border: 1px solid #E5E5E5;
    border-radius: 4px;
    padding: 12px 16px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }
`;
