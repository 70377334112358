import React, { useEffect, useRef, useState } from 'react';
import { ScaleConstructorStyles } from './ScaleConstructorStyles';
import { useNavigate, useParams } from 'react-router-dom';
import { TransparentButton } from '../../../../components/atoms/transparentButton/TransparentButton';
import ColorsScaleConstructor
  from '../../../../components/scaleConstuctors/colorsScaleConstructor/ColorsScaleConstructor';
import NumbersScaleConstructor
  from '../../../../components/scaleConstuctors/numbersScaleConstructor/NumbersScaleConstructor';
import StarsScaleConstructor from '../../../../components/scaleConstuctors/starsScaleConstructor/StarsScaleConstructor';
import { getTranslationByLangOrEng, translations } from '../../../../i18n';
import { Api } from '../../../../api';
import { useAppSelector } from '../../../../state';
import {
  IColorScaleSettings, INumberScaleSettings,
  IStarScaleSettings, INpsScaleSettings,
  IColor,
} from '../../../../entities';
import NpsScaleConstructor from '../../../../components/scaleConstuctors/npsScaleConstructor/NpsScaleConstructor';
import { LeavePageModal, SubHeader } from '../../../../components';

export default function ScaleConstructor() {
  const navigate = useNavigate();
  const {
    id, nodeId, formId, scaleIndex,
  } = useParams();

  const [formName, setFormName] = useState<string>();
  const [companyImagesLoaded, setCompanyImagesLoaded] = useState<boolean>(false);
  const [companyColorsLoaded, setCompanyColorsLoaded] = useState<boolean>(false);
  const [companyImagesLoading, setCompanyImagesLoading] = useState<boolean>(false);
  const [companyColorsLoading, setCompanyColorsLoading] = useState<boolean>(false);
  const [colorScaleSettings, setColorScaleSettings] = useState<IColorScaleSettings>();
  const [numberScaleSettings, setNumberScaleSettings] = useState<INumberScaleSettings>();
  const [starScaleSettings, setStarScaleSettings] = useState<IStarScaleSettings>();
  const [npsScaleSettings, setNpsScaleSettings] = useState<INpsScaleSettings>();
  const [pathToNavigate, setPathToNavigate] = useState<string>('');

  const companyImagesRef = useRef<{ name: string, url: string }[]>();
  const companyColorsRef = useRef<IColor[]>();

  const { selectedCompany: company, selectedNode: node } = useAppSelector((state) => state.company);
  const { notSavedForm, notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);
  const { selectedScaleType } = useAppSelector((state) => state.scaleSettings);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  async function getCompanyMetadata(id: number) {
    const res = await Api.getCompanyMetadata(+id!);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      companyImagesRef.current = res.data.filter((item) => item.key.startsWith('image_')).map((item) => {
        const imageParts = item.value.split('|||');
        return { name: imageParts[0], url: imageParts[1] };
      });
      companyColorsRef.current = res.data.filter((item) => item.key.startsWith('color_')).map((item) => {
        const colorParts = item.value.split('|||');
        return { name: colorParts[0], color: colorParts[1] };
      });
    }
  }

  function handleCancel() {
    if (formId === undefined) {
      navigate(`/companies/${id}/nodes/${nodeId}/forms/create`);
    } else {
      navigate(`/companies/${id}/nodes/${nodeId}/forms/${formId}/constructor`);
    }
  }

  useEffect(() => {
    if (!companyImagesLoaded && !companyColorsLoaded && !companyImagesLoading && !companyColorsLoading) {
      setCompanyImagesLoading(true);
      setCompanyColorsLoading(true);
      getCompanyMetadata(+id!).finally(() => {
        setCompanyImagesLoading(false);
        setCompanyColorsLoading(false);
        setCompanyImagesLoaded(true);
        setCompanyColorsLoaded(true);
      });
    }
  }, []);

  useEffect(() => {
    if (notSavedForm) {
      setFormName(notSavedForm.name);

      if (companyImagesLoaded && companyColorsLoaded) {
        setFormName(notSavedForm.name || '');

        const fieldSettings = notSavedExtraFields![+scaleIndex!];

        if (selectedScaleType === 'color') {
          setColorScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColorsRef.current!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            mascots: fieldSettings.mascots || [],
            scalePointerColor: fieldSettings.scalePointerColor,
            maxValue: fieldSettings.maxValue,
            scaleGradientColors: fieldSettings.scaleGradientColors || [],
            signatureColor: fieldSettings.signatureColor,
          });
        }

        if (selectedScaleType === 'number') {
          setNumberScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColorsRef.current!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            showDefaultValue: fieldSettings.showDefaultValue!,
            mascots: fieldSettings.mascots || [],
            maxValue: fieldSettings.maxValue,
            numbersColor: fieldSettings.numbersColor,
            selectedButtonColor: fieldSettings.selectedButtonColor,
            signatureColor: fieldSettings.signatureColor,
          });
        }

        if (selectedScaleType === 'star') {
          setStarScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColorsRef.current!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            showDefaultValue: fieldSettings.showDefaultValue!,
            mascots: fieldSettings.mascots || [],
            maxValue: fieldSettings.maxValue,
            notSelectedStarBackground: fieldSettings.notSelectedStarBackground,
            notSelectedStarBorder: fieldSettings.notSelectedStarBorder,
            selectedStarColor: fieldSettings.selectedStarColor,
            signatureColor: fieldSettings.signatureColor,
          });
        }

        if (selectedScaleType === 'nps') {
          setNpsScaleSettings({
            id: fieldSettings.id,
            backgroundColors: fieldSettings.backgroundColors || [],
            changeMascot: fieldSettings.changeMascot || false,
            colors: [...companyColorsRef.current!, ...(fieldSettings.backgroundColors || [])],
            changeBackgroundColor: fieldSettings.changeBackgroundColor || false,
            defaultValue: fieldSettings.defaultValue,
            showDefaultValue: fieldSettings.showDefaultValue!,
            mascots: fieldSettings.mascots || [],
            signatureColor: fieldSettings.signatureColor,
          });
        }
      }
    } else {
      handleCancel();
    }
  }, [companyImagesLoaded, companyColorsLoaded]);

  function showWarningModal(path?: string) {
    setPathToNavigate(path || '');
  }

  return (
    <ScaleConstructorStyles>
      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_title')}
        pathArray={[
          ...(company ? [{ name: company.name!, route: `/companies/${id}/nodes`, callback: showWarningModal }] : []),
          ...(node ? [{ name: node.name!, route: `/companies/${id}/nodes/${nodeId}/forms`, callback: showWarningModal }] : []),
          ...(formName ? [{ name: formName }] : []),
        ]}
      >
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
      </SubHeader>

      {companyImagesLoaded && companyColorsLoaded && (colorScaleSettings || numberScaleSettings || starScaleSettings) && (
        <div className="contentWrapper">
          {selectedScaleType === 'color' && <ColorsScaleConstructor selectedTab="settings" scaleIndex={+scaleIndex!} companyImages={companyImagesRef.current!} companyColors={companyColorsRef.current!} settings={colorScaleSettings!} handleCancel={() => handleCancel()} />}
          {selectedScaleType === 'number' && <NumbersScaleConstructor selectedTab="settings" scaleIndex={+scaleIndex!} companyImages={companyImagesRef.current!} companyColors={companyColorsRef.current!} settings={numberScaleSettings!} handleCancel={() => handleCancel()} />}
          {selectedScaleType === 'star' && <StarsScaleConstructor selectedTab="settings" scaleIndex={+scaleIndex!} companyImages={companyImagesRef.current!} companyColors={companyColorsRef.current!} settings={starScaleSettings!} handleCancel={() => handleCancel()} />}
          {selectedScaleType === 'nps' && <NpsScaleConstructor selectedTab="settings" scaleIndex={+scaleIndex!} companyImages={companyImagesRef.current!} companyColors={companyColorsRef.current!} settings={starScaleSettings!} handleCancel={() => handleCancel()} />}
        </div>
      )}

      {pathToNavigate && <LeavePageModal setPathToNavigate={setPathToNavigate} pathToNavigate={pathToNavigate} />}
    </ScaleConstructorStyles>
  );
}
