import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { LayoutStyles } from './LayoutStyles';
import { useWindowSize } from 'usehooks-ts';
import {
  setCompanies, setSidebarFullWidth,
  useAppDispatch, useAppSelector,
} from '../../../state';
import { Api } from '../../../api';
import { Header } from '../header';
import { Footer } from '../footer';
import { Sidebar } from '../../molecules';

export const Layout: React.FC = React.memo(() => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { fullWidth } = useAppSelector((state) => state.sidebar);
  const { width } = useWindowSize();
  const { loggedIn } = useAppSelector((state) => state.user);

  useEffect(() => {
    if (loggedIn) {
      Api.getCompanies()
        .then((res) => {
          const companies = res.data.map((el) => el.id);
          dispatch(setCompanies(companies));
        });
    }
  }, [loggedIn])

  useEffect(() => {
    dispatch(setSidebarFullWidth(width > 768));
  }, [width])

  return (
    <LayoutStyles>
      <Header />
      <main>
        <div
          className="mainBody"
          style={
            pathname.startsWith('/auth')
              ? { gridTemplateColumns: '1fr' }
              : {}
          }
        >
          {loggedIn && <Sidebar />}
          {!pathname.startsWith('/auth') && <div style={{ width: (fullWidth && width > 768) ? 208 : width > 768 ? 56 : 0 }} />}
          <div style={{ minHeight: 'calc(100vh - 104px)', overflowX: 'scroll' }}><Outlet /></div>
        </div>
      </main>
      <Footer />
    </LayoutStyles>
  );
})
