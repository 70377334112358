import React, { useEffect, useRef, useState } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import { connectionChannelsMassMailings, massMailingStatuses, massMailingTypes } from '../../../constants';
import { MassMailingTableFiltersStyles } from './MassMailingTableFiltersStyles';
import { INode, ICompany, DeepArrayItem } from '../../../entities';
import CustomSelectTiedNode from '../../../components/form/customSelect/CustomSelectTiedNode';
import { useAppSelector } from '../../../state';

interface FilterFormValues {
  status: { id: string, name: string } | undefined ;
  connectionChannel: { id: string, name: string, companyId: string } | undefined ;
  type: string | undefined;
  company: Partial<ICompany> | undefined;
  node: Partial<INode> | undefined;
}

export function MassMailingTableFilters({
  filterParams, setFilterParams, setReQuery, onClose, companies, nodes, nodesWithChilds,
}: {
  setReQuery: () => void,
  setFilterParams: (params: {[key: string] : number | string}) => void,
  filterParams: { [key: string]: number | string | string[] | number[]},
  onClose: () => void,
  companies: {[key: string]: Partial<ICompany>},
  nodes: {[key: string]: Partial<INode>},
  nodesWithChilds: DeepArrayItem[],
}) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [initialValues, setInitialValues] = useState<FilterFormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});

  const formRef = useRef<FormikProps<FilterFormValues>>(null);
  const ref = useRef<any>();

  useEffect(() => {
    setInitialValues({
      status: undefined,
      connectionChannel: undefined,
      type: undefined,
      node: undefined,
      company: undefined,
    });
  }, []);

  async function onSubmit(
    values: FilterFormValues,
    { setSubmitting }: FormikHelpers<FilterFormValues>,
  ) {
    // @ts-ignore
    const params: { [key: string]: string} = {};

    setFilterParams(params);
    setReQuery();
    onClose();

    setSubmitting(false);
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<FilterFormValues>) => (
    <Form>
      <div className="formSection">
        <div className="formSection additionalSettings">

          <CustomSelect
            label="Компания"
            name="company"
            options={Object.values(companies) || []}
            selectKey="name"
            placeholder="Выберите компанию"
            value={values.company}
            handleSelect={(companiesArray) => setFieldValue('company', companiesArray)}
          />

          <CustomSelect
            label="Уровень"
            name="node"
            options={Object.values(nodes) || []}
            selectKey="name"
            placeholder="Выберите уровень"
            value={values.node}
            handleSelect={(companiesArray) => setFieldValue('node', companiesArray)}
          />

          <CustomSelectTiedNode
            label="Node"
            name="node"
            options={values.company ? nodesWithChilds.filter((node) => nodes![node.id].companyId === values.company?.id) : nodesWithChilds}
            selectKey="name"
            placeholder="Select node"
            value=""
            handleSelect={(node) => {
              setFieldValue('node', node);
            }}
            formGroupStyles={{ width: '300px' }}
            search
            expandable
          />

          <CustomSelect
            label="Тип рассылки"
            name="status"
            options={Object.values(massMailingStatuses) || []}
            selectKey="text"
            placeholder="Выберите тип рассылки"
            value={values.status}
            handleSelect={(companiesArray) => setFieldValue('status', companiesArray)}
          />

          <CustomSelect
            label="Канал связи"
            name="connectionChannel"
            options={Object.values(connectionChannelsMassMailings) || []}
            selectKey="name"
            placeholder="Выберите канал связи"
            value={values.connectionChannel}
            handleSelect={(companiesArray) => setFieldValue('connectionChannel', companiesArray)}
          />

          <CustomSelect
            label="Тип рассылки"
            name="type"
            options={Object.values(massMailingTypes) || []}
            selectKey="text"
            placeholder="Выберите тип рассылки"
            value={values.type}
            handleSelect={(companiesArray) => setFieldValue('type', companiesArray)}
          />

        </div>
        <SubmitButton extraButtonStyles={{ width: '100%', maxWidth: '500px' }}>{getTranslationByLangOrEng(interfaceLanguage, 'apply_filters_button')}</SubmitButton>
        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <MassMailingTableFiltersStyles ref={ref}>
      {initialValues && (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {renderForm}
        </Formik>
      )}
    </MassMailingTableFiltersStyles>
  );
}
