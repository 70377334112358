import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ReopenedIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <circle fill="#199085" cx="350" cy="350" r="239" />
    <circle display="none" fill="#27C360" cx="350" cy="350" r="239" />
    <g>
      <path
        fill="#FFFFFF"
        d="M321.1,398.5c-11.1-11.1-11.1-29.2,0-40.3l41.7-41.2l-110.4,0c-16,0-28.9-13-28.9-28.9v-1.9
          c0-16,12.9-28.9,28.9-28.9h110l-41.3-40.8c-11.1-11.1-11.1-29.2,0-40.3c11.3-11.1,29.5-11.1,40.7,0l91.9,91.1
          c11.1,11.1,11.1,29.2,0,40.3l-91.9,91C350.6,409.6,332.4,409.6,321.1,398.5L321.1,398.5z"
      />
      <path
        fill="#199085"
        d="M341.5,533.4c-9.5,0-18.5-3.7-25.3-10.4l0.1-0.1l-0.1,0.1c-13.8-13.8-13.8-36.4,0-50.2l29.6-29.2l-93.3,0
          c-19.8,0-35.9-16.1-35.9-35.9v-1.9c0-19.8,16.1-35.9,35.9-35.9h92.9L316.2,341c-13.9-13.9-13.9-36.4,0-50.2
          c6.8-6.7,15.8-10.4,25.3-10.4c9.5,0,18.5,3.7,25.3,10.4l92,91.1c6.7,6.7,10.4,15.6,10.4,25.1c0,9.5-3.7,18.4-10.4,25.2l-92,91
          C360,529.7,351,533.4,341.5,533.4z"
      />
      <path
        fill="#FFFFFF"
        d="M321.1,518c-11.1-11.1-11.1-29.2,0-40.3l41.7-41.2l-110.4,0c-16,0-28.9-13-28.9-28.9v-1.9
          c0-16,12.9-28.9,28.9-28.9h110L321.1,336c-11.1-11.1-11.1-29.2,0-40.3c11.3-11.1,29.5-11.1,40.7,0l91.9,91.1
          c11.1,11.1,11.1,29.2,0,40.3l-91.9,91C350.6,529.2,332.4,529.2,321.1,518L321.1,518z"
      />
    </g>
  </svg>

));
