import React, { CSSProperties, useCallback, useRef } from 'react';
import { CheckboxStyles } from './CheckboxStyles';

interface CheckboxProps {
  name: string;
  value: boolean;
  onChange: (e: React.ChangeEvent<any>) => void;
  extraBlockStyles?: CSSProperties;
  label?: string;
  error?: string;
  disabled?: boolean;
  children?: any;
  hideError?: boolean;
}

export default function Checkbox({
  name,
  value,
  onChange,
  extraBlockStyles,
  label,
  error,
  disabled,
  children,
  hideError = false,
}: CheckboxProps) {
  const inputRef = useRef(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      // @ts-ignore
      inputRef.current.focus();
      // @ts-ignore
      inputRef.current.select();
    }
  }, [inputRef]);

  return (
    <CheckboxStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label && <span>{label}</span>}
        <input type="checkbox" id={name} checked={value} ref={inputRef} onChange={onChange} disabled={disabled} />
        <div className="checkboxWrapper">
          <div
            className="customizedCheckbox"
            style={label ? { marginTop: 16 } : {}}
            onChange={() => (disabled ? null : handleOnClick)}
          >
            <div className={value ? 'circle checked' : 'circle'} />
          </div>
          {children}
        </div>
      </label>
      {!hideError && (
        <div className="formErrorContainer">
          <span>{error}</span>
        </div>
      )}
    </CheckboxStyles>
  );
}
