import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import React, {
  FC, useEffect, useRef, useState,
} from 'react'
import { Api, ApiContacts } from '../../../api';
import { EmailCompanyIcon, TelegramIcon, ViberIcon } from '../../../assets';
import { IUserRole } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';
import { updateRolesList, validationUserSchema } from '../../../pages/addUser/AddUserUtils';
import { useAppSelector } from '../../../state';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { Loader } from '../../atoms';
import Checkbox from '../../form/checkbox/Checkbox';
import InputField from '../../form/inputField/InputField';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { RoleSelect, NotifiableSource } from '../../molecules';
import { MultiTenancy } from '../multiTenancy';
import { EditUserStyles } from './EditUserStyles';
import { formInitialValues, FormValues } from './utils';

type TEditUser = {
    id: number
    onClose: () => void;
}

export const EditUser: FC<TEditUser> = React.memo(({ id, onClose }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [reMultiQuery, setReMultiQuery] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>(formInitialValues);
  const [roleList, setRoleList] = useState<IUserRole[]>([])
  const [contacts, setContacts] = useState<ApiContacts[]>([])

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    Api.getUserContacts(+id).then((res) => {
      setContacts(res.data)
    })
  }, [id])

  useEffect(() => {
    setIsLoading(true);
    try {
      Api.getRoles(0, -1)
        .then((response) => {
          setRoleList(response.data)
        })
      Api.getUser(id).then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setInitialValues({
            name: res.data.name,
            email: res.data.email,
            roles: res.data.roles.map((role) => role.id!),
          });
          setIsLoading(false);
        }
      })
    } catch (e) {
      console.log(e)
    }
  }, [reQuery, id]);

  const handleToggleNotification = (contact: ApiContacts) => {
    Api.putContactNotification(contact.id, { active: !contact.active }).then((res) => {
      if (res.statusCode === 200) {
        setContacts((prev) => prev.map((cont) => (cont.id === contact.id ? { ...cont, active: !cont.active } : cont)))
      }
    })
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <h3>Global information</h3>
        <div className="row">
          <InputField
            extraBlockStyles={{ width: '100%' }}
            name="name"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_name_placeholder')}
            value={values.name}
            error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
            label={getTranslationByLangOrEng(interfaceLanguage, 'user_name_title')}
            required
          />

          <InputField
            extraBlockStyles={{ width: '100%' }}
            name="email"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_email_placeholder')}
            value={values.email}
            error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined}
            label={getTranslationByLangOrEng(interfaceLanguage, 'user_email_title')}
            required
          />
        </div>

        <div className="row">
          <RoleSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_title')}
            name="roles"
            required
            roles={roleList}
            userRoles={values.roles}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_role_placeholder')}
            value=""
            search
            handleSelect={(option) => {
              const selectedRole = values.roles.includes(option.id as number)
              setFieldValue('roles', selectedRole ? values.roles.filter((role) => role !== option.id) : [...values.roles, option.id])
            }}
            error={errors.roles as string}
          />
        </div>

        <div className="horizontalLine" />

        <h3>Multi-tenancy</h3>
        <MultiTenancy id={id} reQuery={reMultiQuery} setReQuery={setReMultiQuery} />

        {!!contacts.length && (
          <>
            <div className="horizontalLine" />

            <h3>{getTranslationByLangOrEng(interfaceLanguage, 'notifications')}</h3>
            <div className="contacts">
              {contacts.map((cont) => {
                if ('telegramID' in cont) {
                  return (
                    <div title={cont.telegramID}>
                      <TelegramIcon width={38} height={38} />
                      <Checkbox name={`telegram-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                    </div>
                  );
                }
                if ('viberID' in cont) {
                  return (
                    <div title={cont.viberID}>
                      <ViberIcon width={38} height={38} />
                      <Checkbox name={`viber-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                    </div>
                  );
                }
                if ('email' in cont) {
                  return (
                    <div title={cont.email}>
                      <EmailCompanyIcon width={38} height={38} />
                      <Checkbox name={`email-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          </>
        )}

        <div className="horizontalLine" />
        <NotifiableSource id={id} reQuery={reMultiQuery} setReQuery={setReMultiQuery} />

        <div className="horizontalLine" />
        <h3>Change password?</h3>
        <div className="row">
          <InputField
            extraBlockStyles={{ width: '100%' }}
            name="passwords.password"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('passwords.password', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_new_password_placeholder')}
            value={values.passwords?.password}
            error={typeof errorMessage === 'object' ? getErrorMessage('passwords.password', errorMessage) : undefined}
            label={getTranslationByLangOrEng(interfaceLanguage, 'user_new_password_title')}
            type="password"
          />

          <InputField
            extraBlockStyles={{ width: '100%' }}
            name="passwords.passwordConfirmation"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('passwords.passwordConfirmation', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_placeholder')}
            value={values.passwords?.passwordConfirmation}
            error={typeof errorMessage === 'object' ? getErrorMessage('passwords.passwordConfirmation', errorMessage) : undefined}
            label={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_title')}
            type="password"
          />
        </div>

        <div className="horizontalLine" />

        <div className="formErrorContainer">
          {errors.passwords && <span>{errors.passwords}</span>}
        </div>
        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}

        <SubmitButton extraBlockStyles={{ maxWidth: 600, width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>

        <div className="horizontalLine" />
      </div>
    </Form>
  )

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setIsLoading(true);

    try {
      const res = await Api.editUser(+id, {
        name: values.name,
        email: values.email,
        ...(values.passwords?.password && { password: values.passwords.password }),
        ...(values.passwords?.passwordConfirmation && { passwordConfirmation: values.passwords.passwordConfirmation }),
      });

      await updateRolesList(values.roles, roleList.map((role) => role.id!), id, initialValues.roles);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        setErrorMessage('')
        onClose()
        setReQuery(!reQuery)
      } else {
        setErrorMessage(`${res.statusCode} помилкове тіло запиту`)
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setErrorMessage('Помилкове тіло запиту')
    }

    setSubmitting(false);
  }

  return (
    <EditUserStyles>
      {(isLoading || !initialValues) ? <Loader /> : (
        <Formik
          key={JSON.stringify(initialValues)}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationUserSchema(interfaceLanguage, 1)}
        >
          {renderForm}
        </Formik>
      )}
    </EditUserStyles>
  )
})
