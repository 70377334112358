import React, {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { CustomSelectStyles } from './CustomSelectStyles';
import { SearchIcon } from '../../../assets';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../../i18n';
import { DeepArrayItem } from '../../../entities/DeepArrayItem';
import { filterDeepObjectArray } from '../../../utils';
import { useAppSelector } from '../../../state';
import SelectItem from './selectItem/SelectItem';
import { FormErrorMessage, Loader } from '../../atoms';

interface SelectProps {
  label?: string;
  name: string;
  options: any[];
  selectKey: string;
  placeholder: string;
  value: any;
  handleSelect: (value: any) => any;
  property?: string;
  search?: boolean;
  formGroupStyles?: CSSProperties;
  optionsContainerStyles?: CSSProperties;
  hideError?: boolean;
  selectError?: string;
  selectErrorName?: string;
  expandable?: boolean;
  required?: boolean;
  isLoading?: boolean;
  disabled?: boolean;
}

const CustomSelectTiedNode: React.FC<SelectProps> = ({
  label,
  name,
  options,
  selectKey,
  placeholder,
  property,
  value,
  handleSelect,
  formGroupStyles,
  optionsContainerStyles,
  search,
  hideError,
  selectError,
  selectErrorName,
  expandable,
  required,
  isLoading,
  disabled,
}) => {
  const { errors, touched } = useFormikContext();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [nodesFiltered, setNodesFiltered] = useState<boolean>(false);
  const [nodesExpanded, setNodesExpanded] = useState<{[key: number]: boolean}>({});

  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const nodesExpandedRef = useRef<{[key: number]: boolean}>({});
  const nodesFilteredRef = useRef<DeepArrayItem[]>([]);

  useEffect(() => {
    nodesFilteredRef.current = options;
    setNodesFiltered(!nodesFiltered);
  }, [options]);

  const handleExpandRow = React.useCallback((id: number) => {
    if (nodesExpandedRef.current) {
      setNodesExpanded({ ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] });
      nodesExpandedRef.current = { ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] };
    }
  }, []);

  useEffect(() => {
    if (options) {
      if (search && searchParams) {
        nodesFilteredRef.current = filterDeepObjectArray(options, searchParams, selectKey);
        setNodesFiltered(!nodesFiltered);
      } else {
        nodesFilteredRef.current = [...options];
        setNodesFiltered(!nodesFiltered);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    setSearchParams('');
  }, [open]);

  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);

  return (
    <CustomSelectStyles>
      <div
        className={`form-group${
        // @ts-ignore
          touched[name] && !errors[name]
            ? ' valid'
          // @ts-ignore
            : touched[name] && errors[name]
              ? ' error'
              : ''
        }`}
        style={formGroupStyles || {}}
      >
        {label
          && (
            <div className="labelContainer">
              <label htmlFor={name}>
                {label}
                {required && <span className="required"> *</span>}
              </label>
            </div>
          )}
        <div className="selectField">
          <div className="selectedValue" onClick={() => ((disabled || isLoading) ? null : setOpen(!open))} ref={selectedValueFieldRef}>
            {value ? (
              <div className="valueContainer">
                {property && value[property] && (
                  <span className="emoji">{value[property]}</span>
                )}
                <span>{value[selectKey]}</span>
              </div>
            ) : (
              <span className="placeholder">{placeholder}</span>
            )}
          </div>

          {(isLoading || disabled) && (
            <div className="loading">
              {isLoading && <Loader margin={0} height={20} />}
            </div>
          )}

          {open && (
            <div
              className="options"
              style={optionsContainerStyles}
              ref={optionsListRef}
            >
              {search
                && (
                  <div className="searchWrapper">
                    <SearchIcon />
                    <input
                      type="text"
                      value={searchParams}
                      onChange={(e) => setSearchParams(e.target.value)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
                    />
                  </div>
                )}
              {nodesExpandedRef.current && nodesFilteredRef.current.map((option) => (
                <SelectItem
                  option={option}
                  selectKey={selectKey}
                  childrenKey="children"
                  inputName={name}
                  expandedRows={nodesExpandedRef.current}
                  expandable={expandable && option.children.length > 0}
                  handleExpandRow={handleExpandRow}
                  imageProperty={property}
                  handleSelect={handleSelect}
                  setOpen={setOpen}
                />
              ))}
            </div>
          )}
        </div>
        {!hideError && (
          <div className="selectErrorContainer">
            {selectError && <span>{selectError}</span>}
            {!selectError && touched && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
          </div>
        )}
      </div>
    </CustomSelectStyles>
  );
};

export default CustomSelectTiedNode;
