import styled from 'styled-components';

export const LoginStyles = styled.div`
  flex-grow: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  
  .formContainer {
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.14));
    background-color: #fff;
    border-radius: 4px;
    width: 95%;
    max-width: 600px;
    min-width: 320px;
    
    .titleContainer {
      height: 55px;
      display: grid;
      place-items: center;
      border-bottom: 1px solid #F0F0F0;

      h1 {
        font-weight: 500;
        font-size: 18px;
        color: #000000;
      }
    }
    
    form {
      width: 100%
      max-width: calc(100% - 32px);
      padding: 23px 32px 24px;
    }
    
    .linkContainer {
      display: flex;
      justify-content: center;
      padding-bottom: 24px;

      .form__link {
        margin-left: 5px;
        color: #0E9285;
      }
    }

    .extraErrorMessage {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }
  }
`;
