import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface SidebarState {
  fullWidth: boolean;
  activeTab: string | undefined;
}

const initialState: SidebarState = {
  fullWidth: true,
  activeTab: undefined,
};

export const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    // Reducer comes here
    setSidebarFullWidth: (state, action: PayloadAction<boolean>) => ({ ...state, fullWidth: action.payload }),
    setSidebarActiveTab: (state, action: PayloadAction<string | undefined>) => ({ ...state, activeTab: action.payload }),
  },
  extraReducers: (builder) => {},
});

export const { actions: sidebarActions, reducer: sidebarReducer } = sidebarSlice;
// Extract and export each action creator by name
export const { setSidebarActiveTab, setSidebarFullWidth } = sidebarActions;
