import { IssuesStyles } from './IssuesStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  createSearchParams, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { ArrowDownIcon, CalendarIcon, FilterIcon } from '../../assets';
import { IssuesTableFilter } from '../../components/organisms/issuesTableFilter/IssuesTableFilter';
import { useClickOutside } from '../../hooks/useClickOutside';
import { useAppDispatch, useAppSelector } from '../../state';
import { getLanguages, getNodesDictionary } from '../../utils';
import { Api, ApiResponse } from '../../api';
import {
  INode, IUser,
  IIssueStatus, IIssueType,
  IIssue, IIssueByIssueTable,
  CompaniesMainInfo,
} from '../../entities';
import { format } from 'date-fns';
import { availablePageSizes } from '../../constants';
import { useDebounce } from '../../hooks/useDebounce';
import { Helmet } from 'react-helmet-async';
import { usePrevious } from '../../hooks/usePrevious';
import {
  CalendarFilter, Loader, SubHeader, Table, TransparentButton,
} from '../../components';

function NameCustomField({ value, textColor }: {value?: string, textColor: string}) {
  return <div style={{ color: textColor || '#000' }}>{value}</div>;
}

export const Issues = () => {
  const { pageNumber } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { languages: languagesState } = useAppSelector((state) => state.languages);

  const [searchParams, setSearchParams] = useSearchParams();

  const [searchByNameText, setSearchByNameText] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [issues, setIssues] = useState<IIssueByIssueTable[]>([]);
  const [filtersOpen, setFilterOpen] = useState<boolean>(false);
  const [calendarsOpen, setCalendarsOpen] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>();
  const [reRender, setReRender] = useState<boolean>();
  const [init, setInit] = useState<boolean>(true);
  const [companyId, setCompanyId] = useState<number>();

  const companiesRef = useRef<{[key: string]: CompaniesMainInfo}>({});
  const nodesRef = useRef<{[key: string]: Partial<INode>}>({});
  const issueStatusesRef = useRef<{[key: string]: IIssueStatus}>({});
  const issueTypesRef = useRef<{[key: string]: IIssueType}>({});
  const usersRef = useRef<{[key: string]: IUser}>({});
  const issuesRef = useRef<IIssueByIssueTable[]>();
  const issuesQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[0]);
  const headersRef = useRef<{Header: string, accessor: string, Cell?: any}[]>();
  const filtersRef = useRef<any>();
  const calendarsRef = useRef<any>();
  const filterParamsRef = useRef<{ [key: string]: number | string | string[] | number[] }>({});

  const debouncedSearchByNameText = useDebounce(searchByNameText, 1000);
  const prevFilterCompanyId = usePrevious(companyId);

  useEffect(() => {
    if (debouncedSearchByNameText) {
      setSearchByNameText(debouncedSearchByNameText);
    }
  }, [debouncedSearchByNameText])

  useClickOutside(filtersRef, () => setFilterOpen(false));
  useClickOutside(calendarsRef, () => setCalendarsOpen(false));

  function getCompanies(data: CompaniesMainInfo[]) {
    const newCompanies = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;

      return acc;
    }, {});

    const companiesDictionary = companiesRef.current ? { ...companiesRef.current, ...newCompanies } : newCompanies;

    // @ts-ignore
    companiesRef.current = companiesDictionary;
  }

  async function getCompanyNodes(id: number) {
    const res = await Api.getCompanyNodes(id);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      const newNodesDictionary: {[key: number]: Partial<INode>} = {};

      // @ts-ignore
      getNodesDictionary(newNodesDictionary, res.data);

      const nodesDictionary = nodesRef.current ? { ...nodesRef.current, ...newNodesDictionary } : newNodesDictionary;

      if (Object.keys(nodesDictionary).length > 0) {
        // @ts-ignore
        nodesRef.current = nodesDictionary;
      }
    }
  }

  async function getDetailedIssueDataFromApi(issue: IIssue) {
    let status = issueStatusesRef.current[issue.statusId];
    let type = issueTypesRef.current[issue.typeId];
    let responsible = usersRef.current[issue.responsible!];
    let company = companiesRef.current![issue.companyId!];
    let node = nodesRef.current![issue.nodeId!];

    if (!status) {
      const statusFromServer = await Api.getTicketStatus(issue.statusId);

      issueStatusesRef.current = { ...issueStatusesRef.current, [`${statusFromServer.data.id}`]: statusFromServer.data };
      status = statusFromServer.data;
    }

    if (!type) {
      const typeFromServer = await Api.getTicketType(issue.typeId);

      issueTypesRef.current = { ...issueTypesRef.current, [`${typeFromServer.data.id}`]: typeFromServer.data };
      type = typeFromServer.data;
    }

    if (!responsible && issue.responsible) {
      const userFromServer = await Api.getUser(issue.responsible!).then((res) => res.data);

      usersRef.current = { ...usersRef.current, [`${userFromServer.id}`]: userFromServer };
      responsible = userFromServer;
    }

    if (!company) {
      const companyFromServer = await Api.getCompanyById(issue.companyId!);

      companiesRef.current = { ...companiesRef.current, [`${companyFromServer.data.id}`]: companyFromServer.data };
      company = companyFromServer.data;
    }

    if (!node) {
      const nodeFromServer = await Api.getNodeById(issue.nodeId!);

      nodesRef.current = { ...nodesRef.current, [`${nodeFromServer.data.id}`]: nodeFromServer.data };
      node = nodeFromServer.data;
    }

    return {
      name: issue.name,
      status,
      responsible,
      type,
      company,
      node,
      priority: issue.priority,
      createdAt: issue.createdAt!,
      id: issue.id!,
    };
  }

  async function workWithResponse(response: ApiResponse<IIssue[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newIssues = response.data;
      const newIssuesWithDetailInfo = await Promise.all(newIssues.map(getDetailedIssueDataFromApi));

      const data = [...(issuesRef.current || []), ...(newIssuesWithDetailInfo || [])];

      issuesRef.current = data;
      issuesQuantityRef.current = response.count!;
      setIssues(data);

      const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>)

      setSearchParams(path);
      setLoading(false);
    }
  }

  async function getIssuesByNode(id: number) {
    //
  }

  async function getIssuesByCompany(id: number) {
    const promises: Promise<any>[] = [];

    const res = await Api.getCompanyNodes(id);

    res.data.forEach((node) => {
      promises.push(getIssuesByNode(node.id));
    });

    await Promise.all(promises);
  }

  async function getAllIssues() {
    const promises: Promise<any>[] = [];

    const res = await Api.getCompanies();

    res.data.forEach((company) => {
      promises.push(getIssuesByCompany(company.id));
    });

    await Promise.all(promises);
  }

  function getCompanyIssues() {
    const filterParams = searchParams.toString().split('&').reduce((acc: {[key: string]: string | string[]}, item) => {
      const paramParts = item.split('=');

      if (paramParts[0] === 'status') {
        if ('status' in acc) {
          (acc.status as string[]).push(paramParts[1]);
        } else {
          acc.status = [paramParts[1]];
        }
      } else if (paramParts[0] !== '' && paramParts[1] !== undefined) {
        acc[paramParts[0]] = paramParts[1];
      }

      return acc;
    }, {});
    filterParamsRef.current = filterParams;

    if (filterParams.companyID) {
      setInit(true);

      issuesRef.current = undefined;
      // Api.getIssues(filterParams, (pageNumber ? +pageNumber - 1 : 0) * pageSizeRef.current, pageSizeRef.current)
      Api.getTicketsByCompany(+(filterParams.companyID as string | number))
        .then((formsResult) => {
          workWithResponse(formsResult);
        }).finally(() => {
          setInit(false);
        });
    } else {
      issuesRef.current = undefined;
      setIssues([]);
      setLoading(false);
    }
  }

  useEffect(() => {
    if (!languagesState) {
      getLanguages(dispatch);
    }

    Api.getCompanies().then((res) => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });
  }, [])

  useEffect(() => {
    setLoading(true);
    if (filterParamsRef.current.companyID && +filterParamsRef.current.companyID !== prevFilterCompanyId) {
      setCompanyId(+filterParamsRef.current.companyID);
      getCompanyNodes(+filterParamsRef.current.companyID).then(() => {
        getCompanyIssues();
      });
    } else {
      getCompanyIssues();
    }
  }, [pageNumber, reQuery]);

  useEffect(() => {
    if (issuesRef.current) {
      const data = [
        {
          Header: '#',
          accessor: 'issues-code',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField value={data.row.original.id} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_name'),
          accessor: 'name',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField value={data.row.original.name} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_status'),
          accessor: 'status',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <NameCustomField
              value={data.row.original.status.name}
              textColor="#979797"
            />
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_type'),
          accessor: 'type',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <NameCustomField
              value={data.row.original.type.name}
              textColor="#979797"
            />
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_priority'),
          accessor: 'priority',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField value={data.row.original.priority} textColor="#000" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_company'),
          accessor: 'company',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField value={data.row.original.company.name} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_node'),
          accessor: 'node',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField value={data.row.original.node.name} textColor="#000" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'issues_table_header_date'),
          accessor: 'date',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField value={format(new Date(data.row.original.createdAt), 'dd.MM.yyyy hh:mm a')} textColor="#000" />,
        },
      ];
      headersRef.current = data;
    }
  }, [issuesRef.current, reRender, interfaceLanguage]);

  const extraFieldsSettings = {
    responsible: {
      flexDirections: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    status: {
      justifyContent: 'center',
    },
    type: {
      justifyContent: 'center',
    },
    priority: {
      justifyContent: 'center',
    },
    company: {
      justifyContent: 'center',
    },
    node: {
      justifyContent: 'center',
    },
  };

  const onClickToRow = async (data: any) => {
    if (!data.status) {
      await Api.updateTicket(data.id, { ...data, status: 1 })
        .then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setReRender(!reRender);
          }
        });
    }

    window.open(`/issues/${data.id}`, '_blank');
  };

  return (
    <IssuesStyles>
      <Helmet>
        <title>Tickets Voicer</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'issues_sub_header_title')}>
        <TransparentButton handleClick={() => navigate('/issues/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'issues_add_issue')} filled />
      </SubHeader>
      <div className="tableFiltersWrapper">
        <div />
        <div className="splitter" />
        <div className="filtersWrapper" ref={filtersRef}>
          <div className={filtersOpen ? 'filtersOpener filtersOpener-active' : 'filtersOpener'} onClick={() => setFilterOpen(!filtersOpen)}>
            <FilterIcon color="#979797" />
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'filters')}</span>
            <ArrowDownIcon />
          </div>
          {languagesState && filtersOpen && (
            <IssuesTableFilter
              setFilterParams={(params) => { filterParamsRef.current = params; }}
              companies={companiesRef.current!}
              filterParams={filterParamsRef.current}
              setReQuery={() => setReQuery(!reQuery)}
              onClose={() => setFilterOpen(false)}
              languages={languagesState || []}
              setApply={(params) => {
                filterParamsRef.current = params;
                const path = createSearchParams(params as Record<string, string | string[]>);
                navigate(`/issues/page/1?${path}`);
              }}
            />
          )}
        </div>
        <div className="splitter" />
        <div className="calendarWrapper" ref={calendarsRef}>
          <div className="calendarOpenerWrapper" onClick={() => setCalendarsOpen(!calendarsOpen)}>
            <CalendarIcon color="#979797" />
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'choose_period')}</span>
            <div className={calendarsOpen ? 'active' : ''}>
              <ArrowDownIcon />
            </div>
          </div>
          {calendarsOpen && (
            <CalendarFilter
              setFilterParams={(params) => { filterParamsRef.current = params; }}
              filterParams={filterParamsRef.current}
              setReQuery={() => setReQuery(!reQuery)}
              onClose={() => setCalendarsOpen(false)}
              extraBlockStyles={{ right: '0px' }}
            />
          )}
        </div>
      </div>
      {loading && <Loader />}

      {!!issuesRef.current && !!headersRef.current && !loading && (
        <Table
          pagination
          columns={headersRef.current}
          data={issuesRef.current.sort((a, b) => new Date(b.createdAt).getTime()
            - new Date(a.createdAt).getTime())}
          hideFieldsSplitters
          extraFieldsSettings={extraFieldsSettings}
          headerColumnCounts={{ name: issuesQuantityRef.current }}
          onClickToRow={onClickToRow}
          rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_issue_title')}
          pageSize={pageSizeRef.current}
          currentPage={pageNumber ? +pageNumber : 1}
          rowsQuantity={issuesQuantityRef.current}
          pageNumberHandler={(page) => {
            if (page) {
              const params = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
              navigate(`/issues/page/${page}?${params}`);
            }
          }}
        />
      )}

      {!(!!issuesRef.current && !!headersRef.current) && !loading && (
        <p
          style={{
            textAlign: 'center',
            color: '#0E9285',
            marginTop: 24,
            marginBottom: 24,
          }}
        >
          {getTranslationByLangOrEng(interfaceLanguage, 'issues_table_empty')}
        </p>
      )}
    </IssuesStyles>
  );
}
