import styled from 'styled-components';

export const FormConstructorItemStyles = styled.div`
  width: 100%;
  height: 36px;
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  grid-gap: 12px;
  background-color: #fff;
  border-radius: 4px;
  padding: 4px 8px;
  box-sizing: border-box;
  
  p {
    font-size: 12px;
    
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;
