import * as Yup from 'yup';
import { CompaniesMainInfo } from '../../../entities/ICompany';
import { getTranslationByLangOrEng } from '../../../i18n';

export interface FormValues {
  entity: { name: string } | undefined;
  file: File | undefined;
  company: CompaniesMainInfo | undefined;
  sheet: string;
}

export interface ImportReviewsData {
  file: File;
  sheet: string;
  width?: number;
  height?: number;
}

export interface ImportUsersData extends ImportReviewsData {
  companyID?: string;
}

export interface ImportNodesData extends ImportReviewsData {
  companyID: string;
}

export const validationSchema = (lang: string) => Yup.object({
  entity: Yup.object().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
  sheet: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  company: Yup.object().when('entity', {
    is: (entity: { name: string }) => entity && entity.name === 'Nodes',
    then: Yup.object().required(
      getTranslationByLangOrEng(lang, 'validation_required'),
    ),
    otherwise: Yup.object(),
  }),
  file: Yup.mixed().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
});
