import React from 'react';

export const HorizontalDashedLineIcon = React.memo(() => (
  <svg
    width="41"
    height="1"
    fill="none"
    viewBox="0 0 41 1"
  >
    <path stroke="#000" strokeDasharray="2 2" d="M41 0.5L0 0.5" />
  </svg>
));
