import React, {
  useEffect, useRef, useState,
} from 'react';
import { AnalyticStyles } from './AnalyticStyles';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { CompaniesMainInfo } from '../../entities/ICompany';
import { Api } from '../../api';
import { translations } from '../../i18n';
import { CompanyFilter, Loader } from '../../components';
import { Report } from './Report';

export const Analytic = React.memo(() => {
  const { data } = useAppSelector((state) => state.user);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [companies, setCompanies] = useState<{[key: string]: CompaniesMainInfo}>();
  const [reQuery, setReQuery] = useState<boolean>();
  const [config, setConfig] = useState<{reportId: string, groupId: string}>({ groupId: '', reportId: '' })
  const [accessToken, setAccessToken] = useState<string>('');

  const companiesRef = useRef<{[key: string]: CompaniesMainInfo}>({});
  const shouldBeCanceled = useRef<boolean>(false);
  const filterParamsRef = useRef<{ [key: string]: number | string | string[] | number[] }>({});

  function getCompanies(data: CompaniesMainInfo[]) {
    const newCompanies = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;

      return acc;
    }, {});

    const companiesDictionary = companiesRef.current ? { ...companiesRef.current, ...newCompanies } : newCompanies;

    // @ts-ignore
    companiesRef.current = companiesDictionary;
    setCompanies(companiesDictionary);
  }

  useEffect(() => {
    fetch(process.env.REACT_APP_POWERBI_TOKEN_URL!, {
      method: 'GET',
      redirect: 'follow',
    }).then((response) => response.json()).then((data) => setAccessToken(data.access_token))
  }, [filterParamsRef.current])

  useEffect(() => {
    Api.getCompanies().then((res) => {
      if (!shouldBeCanceled.current && res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });

    return () => {
      shouldBeCanceled.current = true;
    }
  }, []);

  return (
    <AnalyticStyles>
      <Helmet>
        <title>Analytics Voicer</title>
      </Helmet>

      <div className="companyFilterContainer">
        {companies ? (
          <>
            <span className="companyFilterTitle">{translations[interfaceLanguage].company}</span>
            <CompanyFilter
              filterParams={filterParamsRef.current}
              companies={companies}
              setFilterParams={(params) => {
                filterParamsRef.current = params;
                if (params.companyID === 111) {
                  setConfig({ groupId: '454d183b-5094-40fe-8c6a-68ce0ee6c791', reportId: '43673eca-7ee4-4ae5-91a8-39934fc111c2' })
                }
                if (params.companyID === 1) {
                  setConfig({ groupId: 'ef3130d9-7686-4c23-8fa6-5678bbf147d4', reportId: 'b3a22180-3948-4567-b1da-4204082e37b7' })
                }
                if (params.companyID === 110) {
                  setConfig({ groupId: '5b98554b-7db2-4c87-bb2f-7d46cc56f7b0', reportId: '007e1115-c4f8-4742-b69b-e3bea8b86bb8' })
                }
                if (params.companyID === 117) {
                  setConfig({ groupId: '5685656d-c5fd-4be9-a3e8-c1f93adcc5a7', reportId: 'd6a445b7-5908-4e9a-bed9-9c659e66d970' })
                }
                if (params.companyID === 135) {
                  setConfig({ groupId: '1687bc3d-229e-4971-8674-4520f8eab762', reportId: 'fa571efd-f45d-4584-970c-3eda3560d96d' })
                }
              }}
              setReQuery={() => setReQuery(!reQuery)}
              setApply={(params) => {
                filterParamsRef.current = params;
              }}
            />
          </>
        ) : (
          <Loader margin={0} />
        )}
      </div>

      {(filterParamsRef.current.companyID === 110 || filterParamsRef.current.companyID === 1 || filterParamsRef.current.companyID === 111 || filterParamsRef.current.companyID === 135 || (filterParamsRef.current.companyID === 117 && [3020, 3, 6].includes(data?.id!))) && (
        <Report accessToken={accessToken} groupId={config.groupId} reportId={config.reportId} />
      )}
      {filterParamsRef.current.companyID === 109 && (
        <iframe title="Gas station" src="https://app.powerbi.com/view?r=eyJrIjoiY2ZiMWJkOWUtNDY1Ny00NjAyLThhOWItMjI5MWZkNGQ3NjI0IiwidCI6IjNmZjA1MjdjLWUwNGQtNGRiMy04NjdlLThhZjUyZDdmNjFiMCIsImMiOjl9" frameBorder="0" allowFullScreen />
      )}
    </AnalyticStyles>
  )
})
