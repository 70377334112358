import React, {
  Dispatch,
  FC, SetStateAction, useEffect, useRef, useState,
} from 'react'
import { Api, ApiNotifiableSource } from '../../../api';
import { ArrowDownIcon } from '../../../assets';
import { CompaniesMainInfo, INotifiableSources, ISource } from '../../../entities';
import { NotifiableCompanyStyles } from './NotifiableCompanyStyles';
import { mergeSourcesArrays } from './NotifiableCompanyUtils';
import { ResultSources } from './resultSources';

type TNotifableCompany = {
    company: CompaniesMainInfo,
    id: number;
    reQuery: boolean;
    setReQuery: Dispatch<SetStateAction<boolean>>;
    notifiableSources: ApiNotifiableSource[]
}

export const NotifiableCompany: FC<TNotifableCompany> = React.memo(({
  company, id, reQuery, setReQuery, notifiableSources,
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [resultSources, setResultSources] = useState<INotifiableSources[]>([]);

  const sources = useRef<ISource[]>([]);

  useEffect(() => {
    Api.getSourcesByCompanyId(company.id).then((res) => {
      sources.current = res.data
    })
  }, [reQuery])

  useEffect(() => {
    setResultSources(mergeSourcesArrays(notifiableSources, sources.current ? sources.current : []))
  }, [sources.current, notifiableSources])

  return (
    <NotifiableCompanyStyles>
      <div className="companyName" onClick={() => setOpen(!open)}>
        <h2>{company.name}</h2>
        <div className={`arrow ${!open && 'open'}`}>
          <ArrowDownIcon />
        </div>
      </div>
      {open && (
        <div className="sourceContainer">
          {resultSources.map((source) => <ResultSources setReQuery={setReQuery} reQuery={reQuery} key={source.id} userId={id} source={source} />)}
        </div>
      )}
    </NotifiableCompanyStyles>
  )
})
