import styled from 'styled-components';

export const LayoutStyles = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-top: 56px;
  box-sizing: border-box;
  
  main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    
    .mainBody {
      display: grid;
      grid-template-columns: auto 1fr;
    }
  }
`;
