import styled from 'styled-components';

export const ReviewStyles = styled.div`
  .tabsContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid #F0F0F0;
    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #FFF;
      margin-right: 32px;
      font-size: 15px;
      line-height: 16px;
      color: #7C7878;
      transition: all 0.3s;
      
      &:last-child {
        margin-right: 0;
      }
      
      &.active {
        border-bottom: 3px solid #0E9285;
        color: #0E9285;
        transition: all 0.3s;
      }
    }
  }

  .subBtns {
    display: 'flex';
    align-items: center;
    gap: 15px;
  }

  .button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: #0E9285;
    border: #0E9285;
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;

    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    
    text-decoration: none;
  }

  .extraBlockStyles {
    margin-bottom: 0;
  }

  .quantityCounterContainer.quantityCounterContainerExtraStyles {
    margin-top: 0;
  }
  
  .mainWrapper {
    gap: 10px;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 16px 32px 32px 16px;

    .mainChat, .mainContainer, .mainManagerForm {
      width: 33.33%;
      min-width: 320px;
    }

    .mainManagerForm {
      max-width: 500px;
    }
    
    .mainContainer {
      max-width: 450px;
    }

    .mainChat {
      max-width: 370px;
    }
    
    .companyInfo {
      margin-bottom: 35px;
      display: grid;
      gap: 16px;
    }
    
    .contactsContainer {
      display: grid;
      gap: 16px;
      
      > div:last-child {
        margin-bottom: 42px;
      }
    }
    
    .infoContainer {
      display: flex;
      flex-direction: column;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-size: 14px;

      & > div {
        display: flex;
        align-items: center;
        gap: 10px;
        height: fit-content;

        div {
          cursor: pointer;
        }
      }

      .userContainer {
        display: flex;
        align-items: center;
        gap: 10px;

        img {
          max-width: 60px;
        }
      }
      
      > span:nth-child(1) {
        margin-bottom: 8px;
        line-height: 16px;
        color: #979797;
      }
      
      > span:nth-child(2) {
        line-height: 19px;
        color: #000000;
      }

      .connectionChannelsWrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 44%;
      
      .connectionChannelsContainer {
        display: flex;
        gap: 8px;
      }
    }
      
      .filesContainer {
        display: flex;
        flex-wrap: wrap;

        .oneFile {
          margin-right: 10px;
          
          &:last-child {
            margin-right: 0px;
          }
        }
        
        .fileContainer {
          height: 28px;
          display: flex;
          align-items: center;
          gap: 8px;
          padding: 0 8px;
          background: #F0F5F5;
          border-radius: 4px;
          cursor: pointer;
          margin-left: 16px;
          
          &:first-child {
            margin-left: 0;
          }

          & > div {
            display: flex;
            align-items: center;
          }

          a, span {
            color: #0E9285;
          }
        }
      }
    }
      
    .listWrapper {
      display: grid;
      gap: 20px;
      border-bottom: 1px dashed #e5e5e5;
      padding-bottom: 24px;
      font-size: 14px;
      
      .listTitle, .noIssues {
        font-size: 16px;
        color: #000;
      }
      
      .noIssues {
        margin: auto;
      }
    }

    .typeSelectorsContainer {
      padding-top: 20px;
    }
    
    .issuesContainer {
      display: grid;
      gap: 20px;
      border-top: 1px solid #e5e5e5;
      border-bottom: 1px solid #e5e5e5;
      padding-top: 16px;
      padding-bottom: 24px;
      
      ul {
        display: grid;
        gap: 8px;
        list-style: none;
        
        li {
          border: 1px solid #e5e5e5;
          border-radius: 8px;
          padding: 8px;

          .name {
            margin-bottom: 8px;

            span {
              color: #888;
            }
          }
        }
      }
    }
    
    .answersContainer {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 42px;
      h3 {
        max-width: 416px;
        width: 100%;
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 18px;
        line-height: 120%;
        color: #202020;
      }

      .checkboxInputWrapper {
        margin-top: 10px;
      }
      
      .inputWrapper {
        display: grid;
        max-width: 416px;
        width: 100%;
        
        .input {
          box-sizing: border-box;
          min-height: 40px;
          width: 100%;
          padding: 12px 16px;
          border: 1px solid #E5E5E5;
          background: #F9F9F9;
          border-radius: 3px;
          font-family: 'Roboto', sans-serif;
          font-weight: 400;
          font-size: 14px;
          color: #000000;
        }
        
        .inputLabel {
          font-weight: 500;
          font-size: 15px;
          line-height: 16px;
          color: #000000;
          margin-bottom: 8px;

          &.other {
            margin-top: 12px;
          }
        }
        
        &-withValue {
          line-height: 18px;
          color: #000000;
        }
      }
      
      .answerLabel {
        display: flex;
        align-items: center;          
        margin-bottom: 14px;
        justify-content: space-between;
        
        h4 {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #202020;
          margin: 0;
        }
      }
      
      .questionDescription {
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        color: #202020;
        margin-bottom: 20px;
      }
      
      .scaleContainer {
        max-width: 414px;
        width: 100%;
        
        footer {
          margin-bottom: 0;
        }
        
        button {
          cursor: auto;
        }
      }
      
      .radioContainer {
        display: grid;
        row-gap: 8px;
        
        .optionContainer {
          display: grid;
          grid-template-columns: 22px 1fr;
          grid-gap: 12px;
          align-items: center;
          
          .radioOption {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 22px;
            height: 22px;
            background-color: #FFFFFF;
            border: 1px solid #E5E5E5;
            border-radius: 11px;
            box-sizing: border-box;
            
            &.checked {
              border: 1px solid #0E9285;
            }
            
            .radioOptionSelected {
              width: 18px;
              height: 18px;
              border-radius: 9px;
              background-color: #0E9285;
            }
          }
          
          .value {
            line-height: 22px;
            font-size: 14px;
          }
        }
      }
      
      .checkboxContainer {
        display: grid;
        gap: 8px;
        
        .checkboxWrapper {
          display: grid;
          grid-template-columns: 16px auto;
          align-items: center;
          grid-gap: 8px;
        }
        
        .checkbox {
          border: 1px solid #E5E5E5;
          border-radius: 2px;
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          
          &Selected {
            border: 1px solid #0E9285;
          }
        }
      }
    }
  }
  
  .reviewTypeSelectContainer {
    margin-top: 48px;

    .selectContainer {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      & > .buttonContainer {
        margin-left: 16px;
      }
    }
    
    .reviewTypeItemRow {
      height: 32px;
      box-sizing: border-box;
      border-bottom: 1px solid #C4C4C4;
      padding: 0 16px;
      display: flex;
      align-items: center;
    }

    .reviewTypeItem {
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 8px;
      align-items: center;

      p {
        text-align: left;
      }
    }
  }

  .chatContainer {
    position: relative;
    display: flex;
    flex-direction: column;

    .chatted {
      display: flex;
      border: 1px solid #eee;
      border-bottom: none; 
      width: 100%;
      justify-content: space-around;
      max-width: 370px;

      &-active {
        background: rgb(240, 245, 245); 
        color: rgb(27, 202, 185);
        border: 1px solid rgb(27, 202, 185);
      }

      &-item {
        padding: 10px 0;
        width: 50%;
        text-align: center;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .reviewDiscussionHistory {
      width: 100%;
      max-width: 370px;
      height: 460px;
      overflow-y: scroll;
      padding: 16px;
      box-sizing: border-box;
      border: 1px solid #eee;
      position: relative;
    
      &.chatIsClosedReviewHistory {
        border-bottom: none;
      }
      
      .message {
        width: 80%;
        margin-bottom: 16px;
        padding: 8px;
        box-sizing: border-box;
        font-size: 13px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
        
        &:last-child {
          margin-bottom: 0;
        }
        
        .messageText {
          white-space: pre-line;
        }

        .chatImg {
          width: 100%;
          height: 90%;
          cursor: pointer;
          object-fit: cover;
        }

        .chatLink {
          width: fit-content;
          display: flex;
          align-items: center;
          gap: 5px;
          border-radius: 8px;
          padding: 5px;
          background: #fff ;
          margin-top: 5px;

          span {
            text-decoration: underline;
            color: #000;
          }
        }
  
        .authorAndDate {
          font-style: italic;
          margin-top: 8px;
          display: flex;
          justify-content: space-between;
        }
        
        &.user {
          float: left;
          border-radius: 0 8px 8px 8px;
          background-color: #eee;
  
          .authorAndDate {
            color: #222;
          }
        }
        
        &.admin {
          float: right;
          border-radius: 8px 0 8px 8px;
          background-color: #0E9285;
          color: #fff;
  
          .authorAndDate {
            color: #ddd;
          }
        }
      }
      
      .openDialogButtonContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 300px;
      }
      .chatHistoryEmptyContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 300px;
      }
    }
  }

  .chatErrorModalContent {
    background-color: #fff;
    padding: 16px;

    .errorDetails {
      margin-top: 16px;
      font-size: 12px;
      display: grid;
      grid-template-columns: auto 1fr;
      grid-gap: 16px;
      color: red;
    }
  }
  
  .messageInputContainer {
    display: grid;
    grid-template-columns: 1fr auto;
    position: relative;

    .selectedFile {
      position: absolute;
      background: #d9d9d9;
      border-radius: 5px;
      padding: 5px;
      right: 10px;
      top: -75px;

      .delBtn {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background: #fff;
        width: 15px;
        height: 15px;
      }

      svg {
        width: 50px;
        height: 50px;
      }
    }

    .selectedImg {
      position: absolute;
      right: 10px;
      top: -110px;

      .delBtn {
        position: absolute;
        top: 5px;
        right: 5px;
        border-radius: 50%;
        background: #fff;
        width: 15px;
        height: 15px;
      }

      img {
        object-fit: cover;
        width: 100px;
        height: 100px;
        border-radius: 5px;
      }
    }

    .chatTemplates {
      position: absolute;
      left: 50%;
      transform: translate(-50%);
      background: #fff;
      width: 120%;
      padding: 10px;
      overflow: auto;
      border: 1px solid #eee;
      top: 100px;
      max-height: 200px;
      border-radius: 4px;

      .chatTemplate {
        margin: 5px 0;
        cursor: pointer;
        position: relative;
        font-size: 10px;
        z-index: 999;
        border: 1px solid #eee;
        padding: 10px 5px;
        transform: scale(0.95);
        overflow: hidden;
        text-overflow: ellipsis;

        &:hover {
          transform: scale(1);
        }

        b {
          font-size: 12px;
        }
      }
    }

    .emojiContainer {
      position: absolute;
      top: 100px;
    }

    .btnWrapper {
      display: flex; 
      flex-wrap: wrap;
      width: 100px;
    }
    
    .submitButton {
      padding-top: 1px;
      width: 50px;
      box-sizing: border-box;
      border: 1px solid #eee;
      border-top: none;
      border-left: none;
      height: 50px;
      cursor: pointer;
    }

    .fileBtn {
      cursor: pointer;
      padding-top: 1px;
      width: 50px;
      box-sizing: border-box;
      border: 1px solid #eee;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center
    }
  }

  .chatIsFinished {
    max-width: 370px;
    width: 100%;
    padding: 12px 0px 20px;
    color: #0E9285;
    font-weight: 700;
    text-align: center;
    background-color: #ffffff;
    border-bottom: 1px solid #eee;
    border-right: 1px solid #eee;
    border-left: 1px solid #eee;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;

    &::before {
      display: block;
      margin: 0 auto;
      margin-bottom: 20px;
      content: "";
      width: 90%;
      height: 2px;
      background-color: #0E9285;
    }
  }
  
  .templateWrapper {
    width: 100%;
    display: grid;
    gap: 10px;

    .templateContainer {
      display: flex;
      justify-content: space-between;
      align-items: center;
      
      width: 100%;
      padding: 12px 8px 12px 16px;
      margin-left: auto;

      background: #FFFFFF;

      border: 1px solid #E5E5E5;
      border-radius: 4px;
      
      text-align: left;
      font-weight: 400;
      font-size: 14px;
      line-height: 19px;

      color: #000000;
      
      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .modalContainer {
    background: #fff;
    color: #000000;
    border-radius: 4px;

    .modalHeader {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-right: 12px;
      box-sizing: border-box;

      h5 {
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .body {
      padding: 16px;
      border-bottom: 1px solid #e5e5e5;

      .modalTitleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 16px;

        h6 {
          margin-bottom: 0;
        }

        span {
          margin-top: 2px;
          margin-left: 4px;
          font-size: 12px;
        }
      }

      h6 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      &.right {
        grid-template-columns: 1fr auto;
      }

      & > div, a {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
      }

      & > div {
        width: 100%;
        margin: 0;

        a, button {
          width: 100%;
        }
      }
    }
  }
  
  .selectedTypeColor {
    display: flex;
    flex-direction: column;
    
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 8px;
    }

    .selectedTypeColorWrap {
      display: flex;
      align-items: center;

      .selectedTypeColorContainer {
        flex-grow: 1;
        display: grid;
        grid-template-columns: 1fr;
      }

      .selectedTypeColorRow {
        flex-grow: 1;
        display: flex;
        align-items: center;
        width: 100%;
        height: 40px;
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;
      }

      .selectedTypeColorItem {
        display: grid;
        grid-template-columns: auto 1fr;
        grid-gap: 8px;
        align-items: center;

        p {
          text-align: left;
        }
      }
    }

    .formErrorContainer {
      height: 24px;
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }

    .required {
      color: #FF0101;
    }
  }

  .closeReviewDialogContainer {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    display: grid;
    gap: 10px;

    .closeReviewDialogButton {
      background-color: rgb(255, 1, 1);
      color: #fff;
      min-height: 40px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
    }

    .openDialogButton {
      margin-top: 10px;
      background-color: #0E9285;
      color: #fff;
      min-height: 40px;
      font-weight: 400;
      font-size: 14px;
      border-radius: 4px;
    }
  }

  .loaderReviewPageContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
