import React, { useEffect, useState } from 'react';
import { ColorChangeHandler, SketchPicker } from 'react-color';
import { ColorPickerStyles } from './ColorPickerStyles';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { TransparentButton } from '../../atoms';

interface CustomColorPickerProps {
  color: {
    rgb: {r: number, g: number, b: number, a?: number},
    hex: string
  };
  handleSubmit: (color: {rgb: {r: number, g: number, b: number, a?: number}, hex: string}) => void
}

export default function ColorPicker({ color, handleSubmit }: CustomColorPickerProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [r, setR] = useState(255);
  const [g, setG] = useState(255);
  const [b, setB] = useState(255);
  const [a, setA] = useState(1);
  const [hex, setHex] = useState('#FFFFFF');

  useEffect(() => {
    setR(color.rgb.r);
    setG(color.rgb.g);
    setB(color.rgb.b);
    setA(color.rgb.a === undefined ? 1 : color.rgb.a);
    setHex(color.hex);

    const defaultPicker = document.querySelector('.sketch-picker');
    if (defaultPicker) {
      // @ts-ignore
      defaultPicker.style.border = 'none';
      // @ts-ignore
      defaultPicker.style.boxShadow = 'none';
      // @ts-ignore
      defaultPicker.style.padding = 0;
    }
  }, [color]);

  const handleChange:ColorChangeHandler = ({ rgb, hex }) => {
    setR(rgb.r);
    setG(rgb.g);
    setB(rgb.b);
    setA(rgb.a === undefined ? 1 : rgb.a);
    setHex(hex);
  };

  return (
    <ColorPickerStyles>
      <SketchPicker
        color={{
          r, g, b, a,
        }}
        onChange={handleChange}
        className="colorPicker"
      />

      <TransparentButton
        handleClick={() => handleSubmit({
          rgb: {
            r, g, b, a,
          },
          hex,
        })}
      >
        {getTranslationByLangOrEng(interfaceLanguage, 'color_picker_add_button')}
      </TransparentButton>
    </ColorPickerStyles>
  );
}
