import { CheckboxFieldStyles } from './CheckboxFieldStyles';
import React, { CSSProperties } from 'react';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import { FormErrorMessage } from '../../atoms';

interface CheckboxFieldProps {
  index: number;
  values: any;

  name: string;
  optionsName: string;
  optionsError: string | undefined;
  extraBlockStyles?: CSSProperties;
  disabled?: boolean;

  question: string;
  required: boolean;

  handleChangeOptionsState: (e: React.ChangeEvent<any>) => void;
}

export default function CheckboxField({
  index,
  values,

  name,
  optionsName,
  optionsError,
  extraBlockStyles,
  disabled,

  question,
  required,

  handleChangeOptionsState,
}: CheckboxFieldProps) {
  const { errors, touched } = useFormikContext();

  return (
    <CheckboxFieldStyles style={extraBlockStyles}>
      <div className="titleContainer">
        {required && <span>*</span>}
        <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question}`}</h3>
      </div>

      <FieldArray
        name={`extraFields[${index}].options`}
        render={() => (
          <div className="options">
            {values.extraFields[index].options.map((field: any, subIndex: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="checkboxFieldContainer" key={subIndex}>
                <SquareCheckbox
                  name={`extraFields[${index}].options[${subIndex}].selected`}
                  value={values.extraFields[index].options[subIndex].selected}
                  onChange={handleChangeOptionsState}
                >
                  {field.value}
                </SquareCheckbox>
              </div>
            ))}

            <div className="formErrorContainer">
              {optionsError && <span>{optionsError}</span>}
              {!optionsError && touched && <ErrorMessage name={optionsName} component={FormErrorMessage} />}
            </div>
          </div>
        )}
      />
    </CheckboxFieldStyles>
  );
}
