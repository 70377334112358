import React, { FC } from 'react';
import { Modal } from '../Modal';
import { ImgModalStyles } from './ImgModalStyles';

type Props = {
  onClose: () => void;
  url: string;
};

export const ImgModal: FC<Props> = React.memo(({ onClose, url }) => (
  <ImgModalStyles>
    <Modal onClose={onClose}>
      <img src={url} alt="img" />
    </Modal>
  </ImgModalStyles>
));
