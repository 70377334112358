import * as Yup from 'yup';
import { every } from 'lodash';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import {
  IExtraField, IFormDocument, ISendButton, IThankYouPage,
} from '../../../entities/IExtraField';

type FormFieldTypes = 'title'
  | 'name'
  | 'email'
  | 'phone_number'
  | 'extra_question'
  | 'checkbox'
  | 'radio'
  | 'scale'
  | 'upload_files'
  | 'networks';

function fieldsValidation(lang: string) {
  return ({
    title: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
    name: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
    email: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
    phone_number: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
    extra_question: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
    checkbox: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
      options: Yup.array().min(2, getTranslationByLangOrEng(lang, 'validation_constructor_at_least_2_possible_answers')),
    }),
    radio: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
      options: Yup.array().min(2, getTranslationByLangOrEng(lang, 'validation_constructor_at_least_2_possible_answers')),
    }),
    scale: Yup.object().shape({
      question: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
      selectedOption: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
    upload_files: Yup.object().shape({
      options: Yup.array()
        .of(
          Yup.object().shape({
            selected: Yup.boolean(),
          }),
        )
        .test({
          name: 'one-true',
          message: getTranslationByLangOrEng(lang, 'validation_constructor_should_select_file_types'),
          test: (val) => !every(val, ['selected', false]),
        }),
    }),
    networks: Yup.object().shape({
      options: Yup.array()
        .of(
          Yup.object().shape({
            selected: Yup.boolean(),
          }),
        )
        .test({
          name: 'one-true',
          message: getTranslationByLangOrEng(lang, 'validation_constructor_should_select_file_types'),
          test: (val) => !every(val, ['selected', false]),
        }),
    }),
    documents: Yup.array().of(Yup.object().shape({
      selected: Yup.object().shape({
        id: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
      }),
      description: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    })),
  });
}

export function validationSchema(lang: string) {
  return (
    Yup.object({
      name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
      language_id: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
      backgroundColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
      elementsColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
      extraFields: Yup.array().of(Yup.lazy((obj: IExtraField | undefined) => (obj && fieldsValidation(lang)[obj.type as FormFieldTypes]) || Yup.object()) as any).min(1, getTranslationByLangOrEng(lang, 'validation_form_should_have_minimum_1')),
      sendButton: Yup.object().shape({
        text: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
        textColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
        backgroundColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
      }),
      documents: fieldsValidation(lang).documents,
      thankYouPage: Yup.object().shape({
        title: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
        text: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
        backgroundColor: Yup.object(),
        displayMainScaleBackgroundColor: Yup.boolean(),
        mascot: Yup.object(),
        displayMainScaleMascot: Yup.boolean(),
        buttonText: Yup.string(),
        buttonTextColor: Yup.object().when('buttonText', {
          is: (selectedType: string) => selectedType !== undefined,
          then: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
          otherwise: Yup.object(),
        }),
        buttonBackgroundColor: Yup.object().when('buttonText', {
          is: (selectedType: string) => selectedType !== undefined,
          then: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
          otherwise: Yup.object(),
        }),
        buttonLink: Yup.string().when('buttonText', {
          is: (selectedType: string) => selectedType !== undefined,
          then: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
          otherwise: Yup.string(),
        }),
      }),
    })
  );
}

export function getFieldTemplatesList(lang: string, formID: number, position: string, nodeAddress?: string): { name: string, config: IExtraField }[] {
  return [
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_title_title'),
      config: {
        formID,
        type: 'title',
        question: '',
        nodeAddressMustBeShown: false,
        nodeAddress,
        position,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_name_title'),
      config: {
        formID,
        type: 'name',
        question: '',
        required: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_email_title'),
      config: {
        formID,
        type: 'email',
        question: '',
        required: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_phone_title'),
      config: {
        formID,
        type: 'phone_number',
        question: '',
        required: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_full_answer_title'),
      config: {
        formID,
        type: 'extra_question',
        question: '',
        required: false,
        keyQuestion: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_several_from_list_title'),
      config: {
        formID,
        type: 'checkbox',
        question: '',
        options: [],
        answers: [],
        required: false,
        keyQuestion: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_one_from_list_title'),
      config: {
        formID,
        type: 'radio',
        question: '',
        options: [],
        answers: [],
        selectedOption: '',
        required: false,
        keyQuestion: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_scale_title'),
      config: {
        formID,
        type: 'scale',
        question: '',
        options: [
          { value: 'color' },
          { value: 'number' },
          { value: 'star' },
          { value: 'nps' },
        ],
        answers: [
          { value: '0' },
          { value: '1' },
          { value: '2' },
          { value: '3' },
          { value: '4' },
          { value: '5' },
          { value: '6' },
          { value: '7' },
          { value: '8' },
          { value: '9' },
          { value: '10' },
        ],
        maxValue: 11,
        selectedOption: 'color',
        signatureMin: '',
        signatureMax: '',
        required: false,
        keyQuestion: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_upload_files_title'),
      config: {
        formID,
        type: 'upload_files',
        question: '',
        options: [
          { value: 'document', selected: false },
          { value: 'image', selected: false },
          { value: 'audio', selected: false },
          { value: 'video', selected: false },
        ],
        required: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_title'),
      config: {
        formID,
        type: 'networks',
        question: '',
        options: [
          { value: 'call', selected: false },
          { value: 'sms', selected: false },
          { value: 'telegram', selected: false },
          { value: 'viber', selected: false },
          { value: 'email', selected: false },
          { value: 'whatsapp', selected: false },
          { value: 'without answer', selected: false },
        ],
        required: false,
        position,
        fieldInSeries: false,
      },
    },
    {
      name: getTranslationByLangOrEng(lang, 'companies_form_constructor_quantity_title'),
      config: {
        formID,
        type: 'quantity',
        question: '',
        required: false,
        position,
        fieldInSeries: false,
      },
    },
  ];
}

export function getDocumentTemplate(lang: string, formID: number, position: string): { name: string, config: IFormDocument } {
  return {
    name: getTranslationByLangOrEng(lang, 'companies_form_constructor_document_title'),
    config: {
      formID,
      type: 'document',
      required: false,
      description: '',
      position,
    },
  };
}

export function getSendButtonTemplate(lang: string, formID: number, position: string): { name: string, config: ISendButton } {
  return {
    name: getTranslationByLangOrEng(lang, 'companies_form_constructor_send_button_title'),
    config: {
      formID,
      type: 'send_button',
      text: '',
      textColor: undefined,
      backgroundColor: undefined,
      position,
    },
  };
}

export function getThankYouTemplate(lang: string, formID: number, position: string): { name: string, config: IThankYouPage } {
  return {
    name: getTranslationByLangOrEng(lang, 'companies_form_constructor_send_button_title'),
    config: {
      formID,
      type: 'thank_you_page',
      title: '',
      textInTwoRows: false,
      text: '',
      textFirstLine: '',
      textSecondLine: '',
      backgroundColor: undefined,
      displayMainScaleBackgroundColor: false,
      mascot: undefined,
      displayMainScaleMascot: false,
      buttonText: '',
      buttonTextColor: undefined,
      buttonBackgroundColor: undefined,
      buttonLink: '',
      position,
      backgroundColorForText: undefined,
    },
  };
}
