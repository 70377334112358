import React, { useEffect, useState } from 'react';
import { StarScaleStyles } from './StarScaleStyles';
import { StarIcon } from '../../../../assets';

interface ColorScaleProps {
  selectedStarColor: string,
  notSelectedStarBorder: string,
  notSelectedStarBackground: string,
  maxValue: number,
  defaultValue: number | undefined,
  showDefaultValue: boolean,
  signatures: [string | undefined, string | undefined],
  value?: number,
  handleChange?: (value: number | undefined) => void,
  signatureColor?: string,
}

export default function StarScale({
  selectedStarColor,
  notSelectedStarBorder,
  notSelectedStarBackground,
  maxValue,
  defaultValue,
  showDefaultValue,
  signatures,
  value,
  handleChange,
  signatureColor,
}: ColorScaleProps) {
  const [assessment, setAssessment] = useState<number>();

  const numbers = new Array(maxValue).fill(1);

  useEffect(() => {
    if (showDefaultValue) {
      if (handleChange) {
        handleChange(defaultValue ? (defaultValue > maxValue ? maxValue : defaultValue) : defaultValue);
      } else {
        setAssessment(defaultValue ? (defaultValue > maxValue ? maxValue : defaultValue) : defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <StarScaleStyles signatureColor={signatureColor}>
      <div className="buttons">
        {numbers.map((number, index) => {
          const isSelected = (value ? (value >= (number + index)) : (assessment ? assessment >= (number + index) : false));

          return (
            <button
              // eslint-disable-next-line react/no-array-index-key
              key={number + index}
              type="button"
              onClick={handleChange ? () => handleChange(number + index) : () => setAssessment(number + index)}
            >
              <StarIcon
                width={40}
                height={40}
                color={isSelected ? selectedStarColor : notSelectedStarBackground}
                borderColor={isSelected ? selectedStarColor : notSelectedStarBorder}
              />
            </button>
          );
        })}
      </div>

      {signatures[0] && signatures[1] && (
        <div className="signatures">
          <span>{signatures[0]}</span>
          <span>{signatures[1]}</span>
        </div>
      )}
    </StarScaleStyles>
  );
}
