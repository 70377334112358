import styled from 'styled-components';

export const AuditModalStyles = styled.div`
    .modalBody {
        width: 80%;
        background: #fff;
        max-height: 100vh;
        overflow: auto;
        margin: 20px 0;
        padding: 60px 40px;

        h3 {
            margin-bottom: 15px;
            font-size: 22px;
        }

        .cellWrapper {
            display: flex;
            align-items: center;
            gap: 10px;
        }
        
        .tableWrapper {
            border: 1px solid rgb(240, 240, 240);
        }
    }
`;
