import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { Link, useNavigate } from 'react-router-dom';
import { LoginStyles } from './LoginStyles';
import InputField from '../../components/form/inputField/InputField';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector, loginUser } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../utils';

interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: '',
  password: '',
};

export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { data, status, statusCode } = useAppSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});

  useEffect(() => {
    if (status === 'failed') {
      setErrorMessage('Email or password is wrong!');
    }
    setIsLoading(false);
  }, [status, statusCode, data]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email(getTranslationByLangOrEng(interfaceLanguage, 'validation_invalid_email'))
      .required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    password: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
  });

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    if (!isLoading) {
      setSubmitting(false);
      setIsLoading(true);
      dispatch(loginUser(values));
    }
  }

  useEffect(() => {
    if (status === 'idle' && !!data && statusCode === 200) {
      navigate('/');
    } else if (data) {
      setErrorMessage(data);
    }
  }, [data, status]);

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <InputField
        name="email"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'email_placeholder')}
        value={values.email}
        error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined}
        label={getTranslationByLangOrEng(interfaceLanguage, 'email')}
      />
      <InputField
        name="password"
        type="password"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('password', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'password_placeholder')}
        value={values.password}
        error={typeof errorMessage === 'object' ? getErrorMessage('password', errorMessage) : undefined}
        label={getTranslationByLangOrEng(interfaceLanguage, 'password')}
      />

      <SubmitButton
        isLoading={isLoading}
        isError={touched && Object.keys(errors).length > 0}
      >
        {getTranslationByLangOrEng(interfaceLanguage, 'enter_button')}
      </SubmitButton>

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  return (
    <LoginStyles>
      <Helmet>
        <title>Authorization Voicer</title>
      </Helmet>

      <div className="formContainer">
        <div className="titleContainer">
          <h1>{getTranslationByLangOrEng(interfaceLanguage, 'login_title')}</h1>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>

        <div className="linkContainer">
          <Link to="/auth/recovery" className="form__link">{getTranslationByLangOrEng(interfaceLanguage, 'login_recovery_password')}</Link>
        </div>
      </div>
    </LoginStyles>
  );
}
