import styled from 'styled-components';

export const SubmitButtonStyles = styled.div<{bgColor: string | undefined, textColor: string | undefined}>`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    background: ${({ bgColor }) => bgColor || '#0E9285'};
    border: ${({ bgColor }) => `1px solid ${bgColor}` || '1px solid #0E9285'};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 16px;

    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
  }
  
  .statusWrapper {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .errorWrapper {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    background-color: #fff;
    display: grid;
    place-items: center;
    
    svg {
      height: 16px;
    }
  }
`;
