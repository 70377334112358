import { forwardRef, useEffect, useState } from 'react';
import { ColorsScaleConstructorStyles } from './ColorsScaleConstructorStyles';
import {
  ErrorMessage, FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useParams } from 'react-router-dom';
import { IColor, IColorScaleSettings, IImage } from '../../../entities';
import { colorScaleValidationSchema } from '../scaleFormsValidation';
import ColorScale from '../../form/scales/colorScale/ColorScale';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
import { DeleteIcon, SimplePlusIcon } from '../../../assets';
import InputField from '../../form/inputField/InputField';
import Checkbox from '../../form/checkbox/Checkbox';
import DefaultCompanyImage from '../../../assets/images/defaultCompanyImage.png';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import { mapIColorsToStringsArray, mapIColorToString } from '../../../mappers';
import SelectWithAnyItems from '../../form/customSelect/SelectWithAnyItems';
import { arrayUniqueByKey, getErrorMessage, handleKeyUp } from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useForwardRef } from '../../../hooks';
import { FormErrorMessage, TransparentButton } from '../../atoms';

const defaultScaleColors = ['#EB5757', '#EF9644', '#F4E02D', '#18ECD6'];
const defaultPointerColor: IColor = { name: 'Pointer color', color: '#EB5757' };
const defaultMaxValue = 10;

interface ColorsScaleConstructorProps {
  selectedTab: string,
  scaleIndex: number,
  companyImages: { name: string, url: string }[],
  companyColors: IColor[],
  settings: IColorScaleSettings,
  handleCancel: () => void,
  resetForm?: (values: IColorScaleSettings) => void;
}

const ColorsScaleConstructor = forwardRef<FormikProps<IColorScaleSettings>, ColorsScaleConstructorProps>(({
  selectedTab, scaleIndex, companyImages, companyColors, settings, handleCancel, resetForm,
}, ref) => {
  const { formId } = useParams();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const { notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [extraMainScaleColor, setExtraMainScaleColor] = useState<IColor>();
  const [extraBackgroundColor, setExtraBackgroundColor] = useState<IColor>();
  const [extraMascot, setExtraMascot] = useState<IImage>();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<IColorScaleSettings>();

  const formRef = useForwardRef<FormikProps<IColorScaleSettings>>(ref);

  useEffect(() => {
    if (companyColors) {
      const colorsList = [...companyColors, ...(settings.scaleGradientColors || []), ...(settings.backgroundColors || [])];

      const colorsUniqueList = arrayUniqueByKey<IColor>('name', settings.scalePointerColor ? [...colorsList, settings.scalePointerColor] : colorsList);

      setInitialValues({
        colors: colorsUniqueList,
        scaleGradientColors: settings.scaleGradientColors,
        scalePointerColor: settings.scalePointerColor,
        maxValue: settings.maxValue,
        defaultValue: settings.defaultValue,
        changeBackgroundColor: settings.changeBackgroundColor,
        backgroundColors: settings.backgroundColors,
        changeMascot: settings.changeMascot,
        mascots: settings.mascots,
        signatureColor: settings.signatureColor,
      });
    }
  }, [settings, formId, companyColors]);

  function onSubmit(
    values: IColorScaleSettings,
    { setSubmitting }: FormikHelpers<IColorScaleSettings>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    dispatch(updateNotSavedExtraField({
      ...notSavedExtraFields[scaleIndex],
      ...{
        ...values,
        pointerColor: values.scalePointerColor || defaultPointerColor,
        maxValue: values.maxValue || defaultMaxValue,
        defaultValue: values.defaultValue,
      },
    }));
    setIsLoading(false);

    if (resetForm) {
      resetForm({
        ...notSavedExtraFields[scaleIndex],
        ...{
          ...values,
          pointerColor: values.scalePointerColor || defaultPointerColor,
          maxValue: values.maxValue || defaultMaxValue,
          defaultValue: values.defaultValue,
        },
      })
    }
    handleCancel();
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IColorScaleSettings>) {
    return (
      <Form>
        <div className="scaleContainer">
          <ColorScale
            colors={values.scaleGradientColors.length > 0
              ? mapIColorsToStringsArray(values.scaleGradientColors)
              : defaultScaleColors}
            pointerColor={values.scalePointerColor
              ? mapIColorToString(values.scalePointerColor)
              : mapIColorToString(defaultPointerColor)}
            maxValue={values.maxValue ? +values.maxValue : 10}
            defaultValue={(values.defaultValue ? +values.defaultValue : 1) || 1}
            signatures={[getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_min_placeholder'), getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_max_placeholder')]}
            signatureColor={values.signatureColor?.color as string}
          />
        </div>

        <div className="selectMultipleColorsContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_main_gradient_colors_label')}</h3>

          <FieldArray
            name="scaleGradientColors"
            render={({ remove, push }) => (
              <div className="selectedColorsContainer">
                {values.scaleGradientColors.map((field, index) => (
                  <div className="selectedColor">
                    <div className="valueContainer colors">
                      <div
                        className="color"
                        style={{
                          backgroundColor: typeof field.color === 'string'
                            ? field.color
                            : `rgba(${field.color.r}, ${field.color.g}, ${field.color.g}, ${field.color.a || 100}`,
                        }}
                      />
                      <span>{field.name}</span>
                    </div>

                    <button
                      className="removeColorButton"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                ))}

                <div className="addColorContainer">
                  <CustomColorsSelect
                    name="extraMainScaleColor"
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                    options={values.colors}
                    value={extraMainScaleColor}
                    handleSelect={(color) => setExtraMainScaleColor(color)}
                    formGroupStyles={{ width: '300', marginBottom: 0 }}
                    extraComponentPosition="left"
                    extraStyles={{ flexGrow: 1 }}
                    handleAddColor={(color) => {
                      setFieldValue(`colors[${values.colors.length}]`, color);
                      setExtraMainScaleColor(color);
                    }}
                  />

                  <div className="addItemButtonContainer">
                    <button
                      className="addColorButton withMarginBottom"
                      type="button"
                      onClick={() => {
                        push(extraMainScaleColor);
                        setExtraMainScaleColor(undefined);
                      }}
                      disabled={!extraMainScaleColor}
                    >
                      <SimplePlusIcon color="#FFF" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          />
        </div>

        <div className="pointerColorContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_pointer_color_label')}</h3>
          <CustomColorsSelect
            name="scalePointerColor"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_select_color_placeholder')}
            options={values.colors}
            value={values.scalePointerColor}
            handleSelect={(color) => setFieldValue('scalePointerColor', color)}
            formGroupStyles={{ width: '300', marginBottom: 0 }}
            extraComponentPosition="left"
            extraStyles={{ flexGrow: 1 }}
            handleAddColor={(color) => {
              setFieldValue(`colors[${values.colors.length}]`, color);
              setFieldValue('pointerColor', color);
            }}
          />
        </div>

        <div className="pointerColorContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_signature_text_color_label')}</h3>
          <CustomColorsSelect
            name="signatureColor"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_select_color_placeholder')}
            options={values.colors}
            value={values.signatureColor}
            handleSelect={(color) => setFieldValue('signatureColor', color)}
            formGroupStyles={{ width: '300', marginBottom: 0 }}
            extraComponentPosition="left"
            extraStyles={{ flexGrow: 1 }}
            handleAddColor={(color) => {
              setFieldValue(`colors[${values.colors.length}]`, color);
              setFieldValue('signatureColor', color);
            }}
          />
        </div>

        <div className="maxValueContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_maximum_value_label')}</h3>
          <InputField
            type="number"
            min={3}
            extraBlockStyles={{ width: '100%', maxWidth: '250px' }}
            name="maxValue"
            onChange={(key, value) => {
              if (values.defaultValue && (values.maxValue === values.defaultValue) && (+value < values.defaultValue)) {
                setFieldValue('defaultValue', Math.abs(+value));
              }
              if (Number.isNaN(+value) || +value < 3) {
                setFieldValue(key, 3);
              } else {
                setFieldValue(key, value);
              }
            }}
            onKeyUp={() => handleKeyUp('maxValue', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_maximum_value_placeholder')}
            value={values.maxValue}
            error={typeof errorMessage === 'object' ? getErrorMessage('maxValue', errorMessage) : undefined}
          />
        </div>

        <div className="defaultValueContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_placeholder')}</h3>
          <InputField
            type="number"
            min={0}
            max={values.maxValue || 10}
            extraBlockStyles={{ width: '100%', maxWidth: '250px' }}
            name="defaultValue"
            onChange={(key, value) => {
              if (Number.isNaN(+value) || +value < 0) {
                setFieldValue(key, 0);
              } else if (+value > (values.maxValue || 10)) {
                setFieldValue(key, values.maxValue || 10);
              } else {
                setFieldValue(key, value);
              }
            }}
            onKeyUp={() => handleKeyUp('defaultValue', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_label')}
            value={values.defaultValue}
            error={typeof errorMessage === 'object' ? getErrorMessage('defaultValue', errorMessage) : undefined}
          />
        </div>

        <div className="horizontalLine" />

        <div className="selectBackgroundsContainer">
          <div className="changeBackgroundContainer">
            <Checkbox
              name="changeBackgroundColor"
              value={values.changeBackgroundColor}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_background_checkbox_label')}</span>
            </Checkbox>
          </div>

          {values.changeBackgroundColor && (
            <div
              className="selectMultipleColorsContainer"
              style={values.backgroundColors.length === (values.maxValue ? +values.maxValue : 10) ? { marginBottom: 24 } : {}}
            >
              <div className="titleContainer">
                <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_background_select_label')}</h3>
                <div className="quantityContainer">
                  <span>{`${values.backgroundColors.length}/${values.maxValue || 10}`}</span>
                </div>
              </div>

              <FieldArray
                name="backgroundColors"
                render={({ remove, push }) => (
                  <div className="selectedColorsContainer">
                    {values.backgroundColors.map((field, index) => (
                      <div className="selectedColor">
                        <span className="colorNumber">{index + 1}</span>

                        <div className="valueContainer colors">
                          <div
                            className="color"
                            style={{
                              backgroundColor: typeof field.color === 'string'
                                ? field.color
                                : `rgba(${field.color.r}, ${field.color.g}, ${field.color.g}, ${field.color.a || 100}`,
                            }}
                          />
                          <span>{field.name}</span>
                        </div>

                        <button
                          className="removeColorButton"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ))}

                    {values.backgroundColors.length < (values.maxValue || 10) && (
                      <div className="addColorContainer">
                        <CustomColorsSelect
                          name="extraBackgroundColor"
                          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_background_select_placeholder')}
                          options={values.colors}
                          value={extraBackgroundColor}
                          handleSelect={(color) => setExtraBackgroundColor(color)}
                          formGroupStyles={{ width: '300', marginBottom: 0 }}
                          extraComponentPosition="left"
                          extraStyles={{ flexGrow: 1 }}
                          hideErrors
                          handleAddColor={(color) => {
                            setFieldValue(`colors[${values.colors.length}]`, color);
                            setExtraBackgroundColor(color);
                          }}
                        />

                        <div className="addItemButtonContainer">
                          <button
                            className="addColorButton"
                            type="button"
                            onClick={() => {
                              push(extraBackgroundColor);
                              setExtraBackgroundColor(undefined);
                            }}
                            disabled={!extraBackgroundColor}
                          >
                            <SimplePlusIcon color="#FFF" />
                          </button>
                        </div>
                      </div>
                    )}

                    {values.backgroundColors.length < (values.maxValue || 10) && (
                      <div className="formErrorContainer">
                        {typeof errorMessage === 'object' ? getErrorMessage('backgroundColors', errorMessage) : undefined}
                        {errors.backgroundColors && <ErrorMessage name="backgroundColors" component={FormErrorMessage} />}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div
          className="horizontalLine"
          style={(values.backgroundColors.length === (values.maxValue ? +values.maxValue : 10) && values.changeBackgroundColor)
            ? { marginTop: 32 }
            : { marginTop: 16 }}
        />

        <div className="selectBackgroundsContainer">
          <div className="changeBackgroundContainer">
            <Checkbox
              name="changeMascot"
              value={values.changeMascot}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_mascot_checkbox_label')}</span>
            </Checkbox>
          </div>

          {values.changeMascot && (
            <div
              className="selectMultipleFilesContainer"
              style={values.mascots.length === (values.maxValue ? +values.maxValue : 10) ? { marginBottom: 24 } : {}}
            >
              <div className="titleContainer">
                <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_mascot_select_label')}</h3>
                <div className="quantityContainer">
                  <span>{`${values.mascots.length}/${values.maxValue || 10}`}</span>
                </div>
              </div>

              <FieldArray
                name="mascots"
                render={({ remove, push }) => (
                  <div className="selectedColorsContainer mascotsContainer">
                    {values.mascots.map((field, index) => (
                      <div className="selectedMascot">
                        <span className="colorNumber">{index + 1}</span>

                        <div className="valueContainer mascots">
                          {/* {JSON.stringify(field)} */}
                          <div className="uploadedMascotContainer">
                            <img
                              className="selectedImage"
                              src={field.url}
                              alt={field.name}
                            />
                          </div>
                          <span>{typeof field === 'string' ? field : field.name}</span>
                        </div>

                        <button
                          className="removeColorButton"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ))}

                    {values.mascots.length < (values.maxValue || 10) && (
                      <div className="addMascotContainer">
                        <div className="addMascotWrapper">
                          <div className={extraMascot ? 'logoContainer' : 'logoContainer default'}>
                            {extraMascot ? (
                              <img
                                className="selectedImage"
                                src={extraMascot.url}
                                alt={extraMascot.name}
                              />
                            ) : (
                              <img
                                src={DefaultCompanyImage}
                                alt=""
                              />
                            )}
                          </div>

                          {companyImages && (
                            <div className="uploadButtonFieldContainer">
                              <SelectWithAnyItems
                                name="mascots"
                                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_mascot_select_placeholder')}
                                options={companyImages}
                                value={extraMascot}
                                handleSelect={(file) => setExtraMascot(file)}
                                formGroupStyles={{ width: '300', marginBottom: 0 }}
                                hideErrors
                                renderItem={(option) => (
                                  <div className="mascotOptionItem">
                                    <div className="imageContainer">
                                      <img src={option.url} alt={option.name} />
                                    </div>

                                    <p>{option.name}</p>
                                  </div>
                                )}
                                renderSelect={(value) => <p>{value.name}</p>}
                                optionExtraClassName="mascotOption"
                                optionsExtraClassName="mascotOptions"
                                search
                              />

                              <TransparentButton
                                handleClick={() => {
                                  if (extraMascot) {
                                    setFieldValue(`mascots[${values.mascots.length}]`, extraMascot);
                                    setExtraMascot(undefined);
                                  }
                                }}
                                filled
                                childrenPosition="left"
                                text={getTranslationByLangOrEng(interfaceLanguage, 'add_button')}
                                extraStyles={{
                                  marginTop: 12,
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div className="submitButtonContainer">
          <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}</SubmitButton>
        </div>

        <p className="uploadError">{submitError}</p>
      </Form>
    );
  }

  return (
    <ColorsScaleConstructorStyles>
      {initialValues && (
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={colorScaleValidationSchema}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      )}
    </ColorsScaleConstructorStyles>
  );
});

export default ColorsScaleConstructor;
