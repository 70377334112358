import styled from 'styled-components';

export const DocumentsStyles = styled.div`
  .documentsWrapper {
    .tableTitleWrapper {
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 0 32px 0 16px;
    }

    .editButtonsContainer {
      height: 100%;
      display: flex;
      justify-content: center;

      a {
        display: flex;
        align-items: center;
      }

      button {
        display: flex;
        align-items: center;
      }
    }

    .editButtons {
      width: 70px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  
  .documentsTableContainer {
    border-top: 1px solid #f0f0f0;
  }

  .modalBody {
    .modalTitle {
      padding: 10px 16px;
      background: #F0F5F5;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      border: 1px solid #F0F5F5;
      border-radius: 4px 4px 0px 0px;
    }

    .modalButtonsWrap {
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 0px 0px 4px 4px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: 14px;
        color: #000000;
      }

      div {
        display: flex;
        justify-content: space-between;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;

        :nth-child(1) {
          background: #FFFFFF;
          border: 1px solid #C4C4C4;
          color: #000000;

          :hover {
            color: #FFF;
            background: #C4C4C4;
          }
        }

        :nth-child(2) {
          border: 1px solid #FF0101;
          background: #FF0101;
          color: #FFFFFF;

          :hover {
            background: #FFFFFF;
            color: #FF0101;
          }
        }
      }
    }

    .modalError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
