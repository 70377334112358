import React from 'react';

export const CompleteIcon = React.memo(() => (
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
  >
    <path
      d="M 17.226562 2.738281 C 17.089844 2.796875 16.03125 3.835938 12.398438 7.464844 L 7.746094 12.117188 L 5.351562 9.691406 C 3.699219 8.019531 2.90625 7.242188 2.792969 7.1875 C 2.617188 7.101562 2.335938 7.085938 2.160156 7.152344 C 1.996094 7.210938 0.164062 9.046875 0.0742188 9.238281 C -0.0195312 9.4375 -0.0195312 9.664062 0.0742188 9.863281 C 0.175781 10.085938 7.222656 17.171875 7.441406 17.269531 C 7.640625 17.363281 7.867188 17.363281 8.066406 17.269531 C 8.289062 17.164062 19.855469 5.585938 19.941406 5.382812 C 20.019531 5.1875 20.015625 4.976562 19.925781 4.785156 C 19.824219 4.570312 18.039062 2.796875 17.84375 2.714844 C 17.648438 2.636719 17.4375 2.640625 17.226562 2.738281 Z M 17.226562 2.738281 "
      fill="#0E9285"
    />
  </svg>
));
