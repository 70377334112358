import React, { useEffect, useState } from 'react';
import { NpsScaleStyles } from './NpsScaleStyles';

interface NpsScaleProps {
  defaultValue: number | undefined,
  showDefaultValue: boolean;
  signatures: [string, string],
  value?: number,
  handleChange?: (value: number | undefined) => void,
  signatureColor?: string,
}

const valuesAndColors: any = {
  0: '#EB5757',
  1: '#EB5757',
  2: '#EB5757',
  3: '#EB5757',
  4: '#EB5757',
  5: '#EB5757',
  6: '#EB5757',
  7: '#FFC627',
  8: '#FFC627',
  9: '#94D60A',
  10: '#94D60A',
};

const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

function getColor(defaultValue: number | undefined, value: number | undefined, assessment: number | undefined, number: number, colorType: 'color' | 'backgorund', showDefaultValue: boolean) {
  const selectedOptionColor = colorType === 'backgorund' ? valuesAndColors[number] : '#fff';
  const unselectedOptionColor = colorType === 'backgorund' ? '#fff' : valuesAndColors[number];

  if (value !== undefined || assessment !== undefined) {
    return ((value || value === 0) ? value === number : assessment ? +assessment === number : false) ? selectedOptionColor : unselectedOptionColor;
  }

  if (defaultValue !== undefined && defaultValue === number && showDefaultValue) {
    return selectedOptionColor;
  }

  return unselectedOptionColor;
}

export default function NpsScale({
  defaultValue,
  showDefaultValue,
  signatures,
  value,
  handleChange,
  signatureColor,
}: NpsScaleProps) {
  const [assessment, setAssessment] = useState<number>();

  useEffect(() => {
    if (showDefaultValue) {
      if (handleChange) {
        handleChange(defaultValue ? (defaultValue > 10 ? 10 : defaultValue) : defaultValue);
      } else {
        setAssessment(defaultValue ? (defaultValue > 10 ? 10 : defaultValue) : defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <NpsScaleStyles signatureColor={signatureColor}>
      <div className="buttons" style={{ borderColor: '#E5E5E5' }}>
        {numbers.map((number) => (
          <button
            key={number}
            type="button"
            // className={(value ? value === number : assessment === number) ? 'selected' : ''}
            style={{ backgroundColor: getColor(defaultValue, value, assessment, number, 'backgorund', showDefaultValue) }}
            onClick={handleChange ? () => handleChange(number) : () => setAssessment(number)}
          >
            <span style={{ color: getColor(defaultValue, value, assessment, number, 'color', showDefaultValue) }}>{number}</span>
          </button>
        ))}
      </div>

      <div className="signatures">
        <span>{signatures[0]}</span>
        <span>{signatures[1]}</span>
      </div>
    </NpsScaleStyles>
  );
}
