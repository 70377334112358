import styled from 'styled-components';

export const InputFieldStyles = styled.div`
  label {
    display: flex;
    flex-direction: column;

    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 8px;
    }
  }

  .inputWrap {
    display: flex;
    align-items: center;
    
    .inputContainer {
      position: relative;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr;

      button {
        position: absolute;
        right: 10px;
        top: 35%;
        transform: translate(0, -50%);
      }
    }

    input {
      height: 40px;
      font-size: 14px;
      color: #000;
      background-color: #FFFFFF;
      border: 1px solid #E5E5E5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 16px;

      &::placeholder {
        font-family: Roboto, sans-serif;
        font-size: 14px;
        color: #979797;
      }
      
      &.disabled {
        background-color: #fafafa;
      }
    }
    
    .childrenContainer {
      margin-bottom: 24px;
    }
  }

  .formErrorContainer {
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }

  .required {
    color: #FF0101;
  }
`;
