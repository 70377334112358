import { ApiNotifiableSource } from '../../../api';
import { INotifiableSources, ISource } from '../../../entities';

export const mergeSourcesArrays = (apiSources: ApiNotifiableSource[], sources: ISource[]): INotifiableSources[] => sources.map((source) => {
  const apiSource = apiSources.find(
    (apiSource) => apiSource.sourceID === source.id,
  );
  return {
    companyID: source.companyID,
    id: source.id,
    name: source.name,
    notiTime: apiSource ?? null,
  };
})
