import React, { CSSProperties, Dispatch, SetStateAction } from 'react';
import { TextAreaStyles } from './TextAreaStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from '../../atoms';

interface TextAreaProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (key: string, value: string) => void;
  extraBlockStyles?: CSSProperties;
  onKeyUp?: () => void;
  disabled?: boolean;
  label?: string;
  showError?: boolean;
  error?: string | undefined;
  extraTextAreaStyles?: CSSProperties;
  onKeyPress?: any;
  cols?: number;
  rows?: number;
  setIsTemplate?: Dispatch<SetStateAction<boolean>>
}

function TextArea({
  name,
  placeholder,
  max,
  min,
  value,
  disabled,
  extraBlockStyles,
  onChange,
  onKeyUp,
  label,
  showError = true,
  error,
  extraTextAreaStyles,
  onKeyPress,
  cols,
  rows,
  setIsTemplate,
}: TextAreaProps) {
  const { touched } = useFormikContext();

  return (
    <TextAreaStyles style={extraBlockStyles}>
      {label && <span>{label}</span>}
      <textarea
        style={extraTextAreaStyles}
        name={name}
        value={value}
        onChange={(event) => {
          if (setIsTemplate) {
            if (event.target.value.length > 0 && event.target.value.charAt(0) === '/') {
              setIsTemplate(true)
            } else {
              setIsTemplate(false)
            }
          }
          return onChange(name, event.target.value)
        }}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        disabled={disabled}
        className={disabled ? 'disabled textArea' : 'textArea'}
        minLength={min}
        maxLength={max}
        cols={cols}
        rows={rows}
        onKeyPress={onKeyPress}
      />

      {showError && (
        <div className="formErrorContainer">
          {error && <span>{error}</span>}
          {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
        </div>
      )}
    </TextAreaStyles>
  );
}

export default TextArea;
