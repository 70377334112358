import styled from 'styled-components';

export const ConnectionStyles = styled.div`
  background-color: #fff;
  border-radius: 4px;
  padding: 16px;
  box-sizing: border-box;
  margin-bottom: 8px;
  
  .closedConnection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .leftPart {
      display: flex;
      align-items: center;
      
      div {
        padding: 0 2px;
        box-sizing: border-box;
        min-width: 24px;
        height: 24px;
        display: grid;
        place-items: center;
        margin-left: 8px;
        border: 1px solid #0E9285;
        color: #0E9285;
      }
    }
    
    .rightPart {
      display: flex;
      align-items: center;
      width: 160px;

      .goToText {
        width: 72px;
      }
      
      .typeIcon {
        padding: 0;
        margin: 0;
        margin-left: 8px;
        height: 24px;
        display: grid;
        grid-template-columns: 50px 1fr;
        align-items: center;
        grid-gap: 12px;
        background-color: #fff;
        border-radius: 4px;
        box-sizing: border-box;
      }

      .nextThankYouPageFieldText {
        width: 60px;
        text-align: center;
      }
    }
  }

  .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    
    button {
      width: 100%;
    }

    div:first-child {
      button {
        border: 1px solid #e11f1f;

        &.filled {
          background: #e11f1f;

          &:hover {
            span {
              color: #fff;
            }
          }
        }
      }
    }
  }
`;
