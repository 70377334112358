import React, { useEffect, useState } from 'react'
import { DebounceInput } from 'react-debounce-input';
import { useParams } from 'react-router-dom';
import { Api } from '../../../api';
import { SearchIcon } from '../../../assets';
import { ICompanyUser } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Loader } from '../../atoms';
import { Table } from '../table';
import { CompanyUsersStyles } from './CompanyUsersStyles';
import { extraFieldsSettings } from './CompanyUsersUtils';

export const CompanyUsers = React.memo(() => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { id } = useParams();

  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>();
  const [users, setUsers] = useState<ICompanyUser[]>([]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      Api.getUsersByCompany(+id)
        .then((response) => {
          setUsers(response.data)
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (users.length) {
      setTableHeaders([
        {
          Header: 'ID',
          accessor: 'id',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.id}</span>
            </div>
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_name_header'),
          accessor: 'name',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.name}</span>
            </div>
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_email_header'),
          accessor: 'email',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.email}</span>
            </div>
          ),
        },
        {
          Header: 'Nodes count',
          accessor: 'nodesCount',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.nodesCount}</span>
            </div>
          ),
        },
        {
          Header: 'Responsible count',
          accessor: 'responsibleCount',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div className="customNameWrapper">
              <span style={{ color: '#000' }}>{data.row.original.responsibleCount}</span>
            </div>
          ),
        },
      ]);
    }
  }, [users, interfaceLanguage]);

  return (
    <CompanyUsersStyles>
      <div className="searchWrapper">
        <SearchIcon />

        <DebounceInput
          value={search}
          minLength={2}
          debounceTimeout={1000}
          onChange={(event) => setSearch(event.target.value)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')}
        />
      </div>
      {loading && <Loader />}

      {!!users && !!tableHeaders && !loading && (
        <div className="tableUsers">
          <Table
            columns={tableHeaders}
            data={users.filter((user) => user.name.toLowerCase().includes(search.toLowerCase()))}
            hideFieldsSplitters
            sortableColumns={['id', 'nodesCount', 'responsibleCount']}
            extraFieldsSettings={extraFieldsSettings}
            fullWidthColumns={['name', 'email']}
          />
        </div>
      )}
    </CompanyUsersStyles>
  )
})
