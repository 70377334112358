import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import * as Yup from 'yup';
import { useAppSelector } from '../../state';
import { CompanySourcesStyles } from './CompanySourcesStyles';
import { DeleteIcon, JustPlusIcon, PencilIcon } from '../../assets';
import InputField from '../form/inputField/InputField';
import SubmitButton from '../form/submitButton/SubmitButton';
import { getTranslationByLangOrEng } from '../../i18n';
import { Api } from '../../api';
import { updateSourcesList } from './utils';
import { handleKeyUp } from '../../utils';
import { Loader, TransparentButton } from '../atoms';
import { CompaniesInfoModal, Modal, ModalConfirmDecline } from '../organisms';

export interface FormValues {
    sources: { name: string }[],
}
export interface FormValues2 {
    name: string;
}

export default function CompanySources() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [initialValues, setInitialValues] = useState<FormValues>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [sourceCreateLoading, setSourceCreateLoading] = useState<boolean>(false);
  const [isCreateNewSourceModalOpened, setCreateNewSourceModalOpened] = useState<boolean>(false);
  const [accessibleSourcesEdit, setAccessibleSourcesEdit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitError, setSubmitError] = useState<string>('');
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [afterSaveModal, setAfterSaveModal] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');

  const createSourceModalInitValue: FormValues2 = {
    name: '',
  };

  const formRef = useRef<FormikProps<FormValues>>(null);
  const form2Ref = useRef<FormikProps<FormValues2>>(null);

  async function handleCreateSource(value: { companyId: number, name: string }) {
    formRef.current!.values.sources = [...formRef.current!.values.sources, value];
    setDataChanged((prev) => !prev);
  }

  function getErrorMessage(key: string) {
    return typeof errorMessage === 'object'
      ? errorMessage[key]
      && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
        key
      ].substring(1)}`
      : undefined;
  }

  const validationSchema = Yup.object({
    sources: Yup.array().of(
      Yup.object({
        name: Yup.string(),
      }),
    ),
  });

  const validationSchema2 = Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')).max(100, getTranslationByLangOrEng(interfaceLanguage, 'companies_source_name_max_length_error')),
  });

  async function getDataBySelectedCompany(companyId: number) {
    const sources = await Api.getSourcesByCompanyId(companyId);

    if (sources.statusCode >= 200 && sources.statusCode < 300) {
      setInitialValues({ sources: sources.data || [] });
    }
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getDataBySelectedCompany(+id).finally(() => {
        setIsLoading(false);
      });
    }
  }, [id]);

  const onSaveModalConfirm = async (values: FormValues) => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        updateSourcesList(+id!, initialValues ? initialValues.sources : [], values.sources);
      } catch (e) {
        setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      } finally {
        if (!submitError) {
          setConfirmSaveModalOpen(false);
          setAfterSaveModal(true);
        }
        setIsLoading(false);
      }
    }
  }

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setConfirmSaveModalOpen(true);

    setSubmitting(false);
  }

  async function onSubmit2(
    values: FormValues2,
    { setSubmitting }: FormikHelpers<FormValues2>,
  ) {
    setSubmitting(false);
    setSourceCreateLoading(true);

    try {
      handleCreateSource({ companyId: +id!, name: values.name })
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setSourceCreateLoading(false);
      setCreateNewSourceModalOpened(false);
    }
  }

  const renderForm = ({
    values,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="sources"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_title')}</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleSourcesEdit(!accessibleSourcesEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <TransparentButton
                        handleClick={() => setCreateNewSourceModalOpened(true)}
                        text={getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_add_source_button')}
                      />
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.sources.map((item, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={accessibleSourcesEdit ? 'itemsWrapper edit' : 'itemsWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleSourcesEdit ? (
                          <InputField
                            name={`sources[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`sources[${index}].name`, setErrorMessage, errorMessage)}
                            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_placeholder')}
                            value={values.sources[index].name}
                              // @ts-ignore*
                            error={typeof errorMessage === 'object' ? getErrorMessage(`statuses[${index}].name`) : undefined}
                            extraBlockStyles={{ width: '300px' }}
                          />
                        ) : item.name}
                      </div>
                      <div className="deleteButtonWrapper">
                        {accessibleSourcesEdit
                            && (
                              <button
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </button>
                            )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
      </div>
      <SubmitButton extraBlockStyles={{ maxWidth: '544px', width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
      <span className="saveError">{submitError}</span>
    </Form>
  )

  const renderForm2 = ({
    values,
    setFieldValue,
  }: FormikProps<FormValues2>) => (
    <Form>
      <InputField
        extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
        name="name"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_placeholder')}
        value={values.name}
        error={typeof errorMessage === 'object' ? getErrorMessage('name') : undefined}
        label={getTranslationByLangOrEng(interfaceLanguage, 'companies_source_name_label')}
        required
      />

      <SubmitButton extraBlockStyles={{ width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
    </Form>
  );

  return (
    <CompanySourcesStyles>
      {afterSaveModal && (
        <CompaniesInfoModal
          onConfirm={() => setAfterSaveModal(false)}
          onClose={() => {
            setAfterSaveModal(false);
          }}
          onDecline={() => navigate('/companies')}
        />
      )}

      {!initialValues ? <Loader /> : (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>
      )}

      {confirmSaveModalOpen && (
      <ModalConfirmDecline
        onClose={() => setConfirmSaveModalOpen(false)}
        onConfirm={() => onSaveModalConfirm(formRef.current!.values)}
        onDecline={() => setConfirmSaveModalOpen(false)}
        confirmText={getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}
        declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_confirmation_before_save_modal_title')}
        error={!!dataSaveError}
        errorText={dataSaveError}
        firstButtonStyles={{ background: '#ff0000' }}
        secondButtonStyles={{ background: '#0E9285' }}
      />
      )}

      {isCreateNewSourceModalOpened && (
      <Modal onClose={() => setCreateNewSourceModalOpened(false)} extraStyles={{ width: '100%', maxWidth: 500 }}>
        <div className="modalContainer">
          <div className="modalHeader">
            <h5>{getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_create_new_source_title')}</h5>
            <button type="button" onClick={() => setCreateNewSourceModalOpened(false)} disabled={sourceCreateLoading}>
              <JustPlusIcon color="#999" width={20} height={20} />
            </button>
          </div>
          <div className="body">
            <div className="modalTitleContainer">
              <h6 style={{ textAlign: 'left' }}>{getTranslationByLangOrEng(interfaceLanguage, 'companies_sources_create_source_text')}</h6>
              <span>{`(${getTranslationByLangOrEng(interfaceLanguage, 'companies_source_must_be_unique_text')})`}</span>
            </div>

            <Formik
              innerRef={form2Ref}
              initialValues={createSourceModalInitValue}
              onSubmit={onSubmit2}
              validationSchema={validationSchema2}
            >
              {renderForm2}
            </Formik>
          </div>
          <div className="footer right">
            <div />
            <TransparentButton disabled={sourceCreateLoading} handleClick={() => setCreateNewSourceModalOpened(false)} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} />
          </div>
        </div>
      </Modal>
      )}
    </CompanySourcesStyles>
  )
}
