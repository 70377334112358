import React, { FC, useEffect, useState } from 'react'
import { AuditModalStyles } from './AuditModalStyles'
import { Modal } from '../Modal';
import { Api, ApiAudit } from '../../../../api';
import { useAppSelector } from '../../../../state';
import { reviewsStatusIconsAndText } from '../../../../constants';
import { Table } from '../../table';
import { Loader } from '../../../atoms';
import { format } from 'date-fns';
import { IUser } from '../../../../entities';
import { getTranslationByLangOrEng } from '../../../../i18n';

type TAuditModal = {
  id: string;
  onClose: () => void;
  companyID: number;
}

export const AuditModal: FC<TAuditModal> = React.memo(({ onClose, id, companyID }) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [audit, setAudit] = useState<ApiAudit[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [tableHeaders, setTableHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>();
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (audit.length && users.length) {
      setTableHeaders([
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'status'),
          accessor: 'status',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => {
            const Icon = reviewsStatusIconsAndText(interfaceLanguage)[data.row.original.status].icon
            return (
              <div className="cellWrapper">
                <span style={{ color: '#000' }}>{reviewsStatusIconsAndText(interfaceLanguage)[data.row.original.status].text}</span>
                <Icon />
              </div>
            )
          },
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'action'),
          accessor: 'action',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div>
              <span style={{ color: '#000' }}>{getTranslationByLangOrEng(interfaceLanguage, data.row.original.action)}</span>
            </div>
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'actor'),
          accessor: 'actor',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div>
              <span style={{ color: '#000' }}>{getTranslationByLangOrEng(interfaceLanguage, data.row.original.actor)}</span>
            </div>
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'review_updated_at'),
          accessor: 'date',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div>
              <span style={{ color: '#000' }}>{format(new Date(data.row.original.timestamp), 'dd.MM.yyyy H:mm:ss')}</span>
            </div>
          ),
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'name'),
          accessor: 'name',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => {
            const user = users ? users.find((user) => user.id === data.row.original.userID) : null;
            return (
              <div>
                <span style={{ color: '#000' }}>{user ? user.name : ''}</span>
              </div>
            )
          },
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'users_main_table_user_role_header'),
          accessor: 'role',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => {
            const role = users ? users.find((user) => user.id === data.row.original.userID)?.roles.map((role) => role.name).join(', ') : null;
            return (
              <div>
                <span style={{ color: '#000' }}>{role || ''}</span>
              </div>
            )
          },
        },
      ]);
    }
  }, [audit, interfaceLanguage, users]);

  useEffect(() => {
    setLoading(true)
    Api.getReviewHistory(id).then(async (res) => {
      setAudit(res.data)
      const apiUsers = await Promise.all(res.data.flatMap((item) => (item.userID ? [Api.getUser(item.userID).then((res) => res.data)] : [])));
      setUsers(apiUsers)
      setLoading(false)
    })
  }, [])

  return (
    <AuditModalStyles>
      <Modal onClose={onClose}>
        <h3>{getTranslationByLangOrEng(interfaceLanguage, 'review_audit')}</h3>
        {loading && <Loader />}
        {!!audit && !loading && !!tableHeaders && (
          <div className="tableWrapper">
            <Table
              columns={tableHeaders}
              data={audit}
              // extraFieldsSettings={extraFieldsSettings}
              hideFieldsSplitters
            />
          </div>
        )}
      </Modal>
    </AuditModalStyles>
  )
})
