import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const EmailIcon: FC<TIcon> = React.memo(({ width = 512, height = 512, color = '#27BDEC' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 512 512"
  >
    <path fill={color} d="M207.8 20.73c-93.45 18.32-168.7 93.66-187 187.1-27.64 140.9 68.65 266.2 199.1 285.1 19.01 2.888 36.17-12.26 36.17-31.49v-.663c0-15.74-11.44-28.88-26.84-31.24-84.35-12.98-149.2-86.13-149.2-174.2 0-102.9 88.61-185.5 193.4-175.4 91.54 8.869 158.6 91.25 158.6 183.2v16.16c0 22.09-17.94 40.05-40 40.05s-40.01-17.96-40.01-40.05v-120.1c0-8.847-7.16-16.02-16.01-16.02l-31.98.003c-7.299 0-13.2 4.992-15.12 11.68-24.85-12.15-54.24-16.38-86.06-5.106-38.75 13.73-68.12 48.91-73.72 89.64-9.483 69.01 43.81 128 110.9 128 26.44 0 50.43-9.544 69.59-24.88 24 31.3 65.23 48.69 109.4 37.49C465.2 369.3 496 324.1 495.1 277.2v-20.9c0-149.2-133.9-265.632-287.3-235.57zm31.3 283.57c-26.47 0-48-21.56-48-48.05s21.53-48.05 48-48.05 48 21.56 48 48.05-20.6 48.05-48 48.05z" />
  </svg>
));
