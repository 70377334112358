import { useState } from 'react';
import { IIssueLocalType, IIssueType } from '../entities';

export function useLocalTypes() {
  const [localTypes, setLocalTypes] = useState<IIssueLocalType[]>([]);

  function getLocalTypes(types: IIssueType[]) {
    const localTypes: IIssueLocalType[] = [];
    types.forEach((item) => {
      const typeNameParts = item.name.split('.');
      const typeIndex: number = localTypes.findIndex((type) => type.name === typeNameParts[0]);
      const categoryIndex: number | undefined = typeNameParts[1] && typeIndex > -1
        ? localTypes[typeIndex].categories?.findIndex((category) => category.name === typeNameParts[1])
        : undefined;
      const subcategoryIndex: number | undefined = typeNameParts[2] && categoryIndex !== undefined && categoryIndex > -1
        ? localTypes[typeIndex].categories![categoryIndex].subcategories?.findIndex((subcategory) => subcategory.name === typeNameParts[2])
        : undefined;

      if (typeNameParts[0] && typeIndex === -1) {
        localTypes.push({
          id: item.id,
          createdAt: item.createdAt,
          companyId: item.companyId,
          name: typeNameParts[0],
          categories: [...(typeNameParts[1] ? [{
            name: typeNameParts[1],
            subcategories: [...(typeNameParts[2] ? [{
              name: typeNameParts[2],
            }] : [])],
          }] : [])],
        });
      } else if (typeNameParts[0] && typeNameParts[1] && categoryIndex === -1) {
        localTypes[typeIndex].categories!.push({
          name: typeNameParts[1],
          subcategories: [...(typeNameParts[2] ? [{
            name: typeNameParts[2],
          }] : [])],
        });
      } else if (typeNameParts[0] && typeNameParts[1] && typeNameParts[2] && subcategoryIndex === -1) {
        localTypes[typeIndex].categories![categoryIndex!].subcategories.push({
          name: typeNameParts[2],
        });
      }
    });

    setLocalTypes(localTypes);
  }

  return { localTypes, getLocalTypes };
}
