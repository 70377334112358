import React, {
  CSSProperties, useEffect, useMemo, useRef, useState,
} from 'react';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { ArrowDownIcon, SearchIcon } from '../../../assets';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import SquareCheckbox from '../squareCheckbox/SquareCheckbox';
import { CustomSelectWithMultipleCheckboxesStyles } from './CustomSelectWithMultipleCheckboxesStyles';
import { useAppSelector } from '../../../state';
import { reviewsStatusIconsAndText } from '../../../constants';
import { FormErrorMessage, Loader } from '../../atoms';

interface SelectProps {
  label?: string;
  name: string;
  options: any[];
  placeholder?: string;
  valueKey?: string;
  search?: boolean;
  optionTextKey?: string;
  formGroupStyles?: CSSProperties;
  optionsContainerStyles?: CSSProperties;
  hideError?: boolean;
  selectError?: string;
  selectErrorName?: string;
  handleChange: any,
  extraOneOptionStyles?: CSSProperties;
  placeholderStyles?: CSSProperties;
  oneOptionValueStyles?: CSSProperties;
  disabled?: boolean;
  isLoading?: boolean;
  error?: string | undefined;
  alwaysOpened?: boolean;
}

const CustomSelectWithMultipleCheckboxes: React.FC<SelectProps> = ({
  label,
  name,
  options,
  placeholder,
  valueKey,
  formGroupStyles,
  optionsContainerStyles,
  optionTextKey,
  search,
  hideError,
  selectError,
  selectErrorName,
  handleChange,
  extraOneOptionStyles,
  placeholderStyles,
  oneOptionValueStyles,
  disabled,
  isLoading,
  error,
  alwaysOpened,
}) => {
  const { errors, touched } = useFormikContext();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSearchParams('');
  }, [open]);

  useClickOutside(optionsListRef, () => {
    if (!alwaysOpened) {
      setOpen(false);
    }
  }, selectedValueFieldRef.current);

  return (
    <CustomSelectWithMultipleCheckboxesStyles>
      <div
        className={`form-group${
          // @ts-ignore
          touched[name] && !errors[name]
            ? ' valid'
            // @ts-ignore
            : touched[name] && errors[name]
              ? ' error'
              : ''
        }`}
        style={formGroupStyles || {}}
      >
        {label && <div className="labelContainer"><label htmlFor={name}>{label}</label></div>}
        <div className="mainBodyWrapper">
          {!alwaysOpened && (
            <div className="selectField">
              <div className="selectedValue" ref={selectedValueFieldRef} onClick={() => ((disabled || isLoading) ? null : setOpen(!open))}>
                <div className="valueContainer">
                  <span style={placeholderStyles}>{placeholder}</span>
                  <div className={open ? 'arrowDownActive' : ''}>
                    <ArrowDownIcon />
                  </div>
                </div>
              </div>
            </div>
          )}
          {(isLoading || disabled) && (
            <div className="loading">
              {isLoading && <Loader margin={0} height={20} />}
            </div>
          )}
          {!hideError && (
            <div className="selectErrorContainer">
              {selectError && <span>{selectError}</span>}
              {!selectError && touched && <ErrorMessage name={selectErrorName || name} component={FormErrorMessage} />}
            </div>
          )}
          {(alwaysOpened || open) && (
            <div
              className={`options${alwaysOpened ? ' alwaysOpened' : ''}`}
              style={optionsContainerStyles}
              ref={optionsListRef}
            >
              {search
                && (
                  <div className="searchWrapper">
                    <SearchIcon />
                    <input
                      type="text"
                      value={searchParams}
                      onChange={(e) => setSearchParams(e.target.value)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
                    />
                  </div>
                )}
              <FieldArray
                name="languages"
                render={(arrayHelpers) => (
                  // eslint-disable-next-line react/jsx-no-useless-fragment
                  <>
                    {options.length > 0
                      && options.map((field, index) => {
                        const Icon = name === 'status' && field && field?.hasOwnProperty('icon')
                          ? reviewsStatusIconsAndText(interfaceLanguage)[field?.id]?.icon : null;
                        const NetworkIcon = name === 'networks' && field.icon;
                        return (field.name.toLowerCase().includes(searchParams.toLowerCase()) && (
                          <div
                            style={extraOneOptionStyles}
                            className="option"
                            key={field.name}
                          >
                            <div className="row">
                              <SquareCheckbox
                                name={valueKey ? `${name}[${index}][${valueKey}]` : `${name}[${index}].value`}
                                value={valueKey ? options[index][valueKey] : options[index].value}
                                onChange={handleChange}
                                extraBlockStyles={{ width: '100%' }}
                                checkboxWrapperStyles={{ alignItems: 'center' }}
                              >
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                  {Icon && (
                                    <div className="iconWrapper">
                                      <Icon />
                                    </div>
                                  )}
                                  {NetworkIcon && (
                                    <div className="iconWrapper">
                                      <NetworkIcon width={20} height={20} />
                                    </div>
                                  )}
                                  <span style={oneOptionValueStyles}>{(optionTextKey && field[optionTextKey]) || field.name}</span>
                                </div>
                              </SquareCheckbox>
                            </div>
                          </div>
                        ))
                      })}
                  </>
                )}
              />
            </div>
          )}
        </div>
      </div>
    </CustomSelectWithMultipleCheckboxesStyles>
  );
};

export default CustomSelectWithMultipleCheckboxes;
