import React from 'react';
import { CheckMark } from '../../../../assets';
import { CheckboxSquareStyles } from './CheckboxSquareStyles';

interface CheckboxSquareProps {
  selected: boolean;
  handleOnClick: () => void;
  hasSelectedChildren?: boolean;
  allChildrenAreSelected?: boolean;
  hasLabel?: boolean;
  disabled?: boolean;
}

export function CheckboxSquare({
  selected, hasSelectedChildren, allChildrenAreSelected, handleOnClick, hasLabel, disabled,
}: CheckboxSquareProps) {
  return (
    <CheckboxSquareStyles style={hasLabel ? { marginTop: 16 } : {}}>
      <div
        className={selected || hasSelectedChildren ? 'default selected' : 'default'}
        onChange={disabled ? () => null : handleOnClick}
      >
        {(selected || allChildrenAreSelected) && <CheckMark />}
        {!selected && !allChildrenAreSelected && hasSelectedChildren && <div className="square" />}
      </div>
    </CheckboxSquareStyles>
  )
}
