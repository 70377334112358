import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const CantDecidedIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <circle fill="#199085" cx="350" cy="350" r="239" />
    <g>
      <path fill="#F00801" d="M350,590c132,0,239-107,239-239S482,112,350,112S111,219,111,351S218,590,350,590z" />
      <g>
        <circle fill="#FFFFFF" cx="350" cy="432.1" r="28.4" />
        <path
          fill="#FFFFFF"
          d="M378.4,347c0,15.7-12.7,28.4-28.4,28.4s-28.4-12.7-28.4-28.4v-85.1c0-15.7,12.7-28.4,28.4-28.4 s28.4,12.7,28.4,28.4V347z"
        />
      </g>
    </g>
  </svg>
));
