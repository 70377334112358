import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { ReviewStyles } from './ReviewStyles';
import { Api, ApiResponse, ApiChatTemplates } from '../../../api';
import ScaleField from '../../../components/clientForm/scaleField/ScaleField';
import QuantityField from '../../../components/clientForm/quantityField/QuantityField';
import {
  networks, reviewsFilesExtraFieldText, reviewsFilesIcons, reviewsStatusIconsAndText,
  hiddenReviewStatuses,
  hiddenUPStatuses,
  hiddenUPStatuses2,
} from '../../../constants';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import TextArea from '../../../components/form/textArea/TextArea';
import { useAppSelector } from '../../../state';
import InputField from '../../../components/form/inputField/InputField';
import {
  reopenDialogData,
  FormMessageValues, FormValues,
  IssuesFormValues, possibleOptions,
  ReviewData, closeDialogData,
} from './ReviewUtils';
import { format } from 'date-fns';
import { useLocalTypes } from '../../../hooks';
import {
  IUser, IIssueType,
  IIssueStatus, IIssue,
  IReviewDiscussionMessage, IReviewType,
  IContact, ICompanyChatBot,
  IForm, IExtraField,
  INewAnswerStructure, IOneReviewForm,
} from '../../../entities';
import {
  CheckMark,
  CopyIcon,
  EmojiIcon, FileIcon,
  KeyIcon, PinIcon,
  SaveIcon, SendIcon,
  TemplatesIcon,
} from '../../../assets';
import {
  addFieldTypeToAnswer, getErrorMessage, handleKeyUp, addFieldPositionToAnswer, getTypeOfContact,
} from '../../../utils';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import {
  AuditModal,
  ImgModal, Loader, ModalConfirmDecline, SubHeader, TransparentButton,
} from '../../../components';

export const Review = () => {
  const navigate = useNavigate();
  const { companyId, id } = useParams();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { data: user } = useAppSelector((state) => state.user);
  const { localTypes, getLocalTypes } = useLocalTypes();

  const is78role = useMemo(() => {
    if (user) {
      return user.roles?.find((role) => role.id === 78);
    }

    return false;
  }, [data])

  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [fileLoading, setFileLoading] = useState<boolean>(false);
  const [initialMessageValues, setInitialMessageValues] = useState<FormMessageValues>({ text: '', close: false });
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [typesInitialValues, setTypesInitialValues] = useState<IssuesFormValues>();
  const [reviewData, setReviewData] = useState<ReviewData>();
  const [messageSending, setMessageSending] = useState<boolean>(false);
  const [forms, setForms] = useState<IForm>();
  const [answers, setAnswers] = useState<INewAnswerStructure[]>();
  const [issues, setIssues] = useState<IIssue[]>([]);
  const [issuesTypes, setIssuesTypes] = useState<IIssueType[]>([]);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const [clientId, setClientId] = useState<number>();
  const [isModalOpened, setIsModalOpened] = useState<boolean>(false);
  const [chatError, setChatError] = useState<{message: string, statusCode: number} | undefined>();
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);
  const [isReviewDialogOpened, setIsReviewDialogOpened] = useState<boolean>(true);
  const [apiRequestTimeout, setApiRequestTimeout] = useState();
  const [contactsData, setContactsData] = useState<IContact[]>([]);
  const [isSuccessfullySaved, setIsSuccessfullySaved] = useState<boolean>(false);
  const [nodeId, setNodeId] = useState<number>();
  const [nonSubmitModal, setNonSubmitModal] = useState<boolean>(false);
  const [validStatus, setValidStatus] = useState<number>(initialValues?.status.value)
  const [chatHistory, setChatHistory] = useState<IReviewDiscussionMessage[]>([])
  const [chatTemplates, setChatTemplates] = useState<ApiChatTemplates[]>();
  const [isTemplate, setIsTemplate] = useState<boolean>(false);
  const [isEmoji, setIsEmoji] = useState<boolean>(false);
  const [imgData, setImgData] = useState<{attachmentUrl: string, DeleteUrl: string, attachmentType: number}>({ attachmentUrl: '', DeleteUrl: '', attachmentType: 0 });
  const [imageModal, setImageModal] = useState<boolean>(false);
  const [selectedImg, setSelectedImg] = useState<string>('');
  const [allPageFields, setAllPageFields] = useState<IExtraField[]>()
  const [chatTab, setChatTab] = useState<'target' | 'all'>('target');
  const [audit, setAudit] = useState<boolean>(false);

  const reviewRef = useRef<IOneReviewForm>();
  const typesRef = useRef<IIssueType[]>([]);
  const issueStatusesRef = useRef<IIssueStatus[]>([]);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const form3Ref = useRef<FormikProps<FormMessageValues>>(null);
  const typesFormRef = useRef<FormikProps<IssuesFormValues>>(null);
  const historyInfoRef = useRef<IReviewDiscussionMessage[]>([]);
  const allHistoryInfoRef = useRef<IReviewDiscussionMessage[]>([]);
  const companyReviewTypesRef = useRef<IReviewType[]>([]);
  const chatHistoryRef = useRef<HTMLDivElement>(null);

  const issuePartNames = [
    getTranslationByLangOrEng(interfaceLanguage, 'task_form_list_type_label'),
    getTranslationByLangOrEng(interfaceLanguage, 'task_form_list_category_label'),
    getTranslationByLangOrEng(interfaceLanguage, 'task_form_list_subcategory_label'),
  ];

  const getReviewInfo = async (id: string) => {
    try {
      const review = await Api.getReview(id);
      const formFields = await Api.getFormFields(review.data.formId);

      if (!review.data.status) {
        await Api.updateReview(+id, { status: 1 })
      }

      const fieldsWithQuestion = formFields.data.extraFields;
      const answersWithType = review.data.answers.map((item) => ({ ...item, type: addFieldTypeToAnswer(item, fieldsWithQuestion!), position: +addFieldPositionToAnswer(item, fieldsWithQuestion!) }));

      const filteredReviewAnswers = answersWithType.filter((answer) => possibleOptions.includes(answer?.type)).sort((a, b) => a.position - b.position);
      const checkboxAnswers = answersWithType.filter((answer) => answer?.type?.includes('checkbox'));
      const uploadFilesAnswers = answersWithType.filter((answer) => answer?.type?.includes('upload_files') && answer.value);

      const joinedUploadFilesAnswers: { [fieldID: number]: INewAnswerStructure } = {};
      for (const answer of uploadFilesAnswers) {
        if (joinedUploadFilesAnswers[answer.fieldID]) {
          joinedUploadFilesAnswers[answer.fieldID].value = `${joinedUploadFilesAnswers[answer.fieldID].value}|||${answer.value}`;
        } else {
          joinedUploadFilesAnswers[answer.fieldID] = answer;
        }
      }

      const joinedCheckboxAnswers: { [fieldID: number]: INewAnswerStructure } = {};
      for (const answer of checkboxAnswers) {
        if (joinedCheckboxAnswers[answer.fieldID]) {
          joinedCheckboxAnswers[answer.fieldID].value = `${joinedCheckboxAnswers[answer.fieldID].value}|||${answer.value}`;
        } else {
          joinedCheckboxAnswers[answer.fieldID] = answer;
        }
      }

      if (Object.values(joinedCheckboxAnswers).length || Object.values(joinedUploadFilesAnswers).length) {
        const resAnswers = +companyId! === 111 ? [...Object.values(joinedCheckboxAnswers), ...Object.values(joinedUploadFilesAnswers), ...filteredReviewAnswers].sort((a, b) => +a.id - +b.id) : [...Object.values(joinedCheckboxAnswers), ...Object.values(joinedUploadFilesAnswers), ...filteredReviewAnswers].sort((a, b) => +a.fieldID - +b.fieldID)

        setAnswers(resAnswers);
      } else {
        setAnswers(filteredReviewAnswers);
      }
      reviewRef.current = review.data;

      const form = await Api.getFormById(review.data.formId);

      setForms(form.data);
      setAllPageFields(fieldsWithQuestion);

      const parentNode = await Api.getNodeById(review.data.parentNode.id);
      const node = await Api.getNodeById(form.data.node_id);
      setNodeId(review.data.parentNode.id);

      let formSource: {
        name: string | number;
        id: number
      } | null = null;
      if (node.data.sourceId) {
        const formSourceData = await Api.getSourceById(node.data.sourceId);
        formSource = formSourceData && formSourceData.data.name ? { name: formSourceData.data.name, id: formSourceData.data.id } : null;
      }

      const responsible: ApiResponse<IUser> | undefined = parentNode.data?.responsibleId
        ? await Api.getUser(parentNode.data.responsibleId)
        : undefined;

      const company = await Api.getCompanyById(node.data.companyId);

      const nameFieldAnswer = answersWithType.find((item) => item?.type?.includes('name'))?.value;
      const phoneFieldAnswer = answersWithType.find((item) => item?.type?.includes('phone' || 'phone_number'))?.value;
      let phone = phoneFieldAnswer?.includes('|||') ? phoneFieldAnswer?.split('|||')[0] : phoneFieldAnswer;
      let userName = nameFieldAnswer?.includes('|||') ? nameFieldAnswer?.split('|||')[0] : nameFieldAnswer;

      const email = answersWithType.find((item) => item?.type?.includes('email'))?.value?.split('|||')?.[0];

      const contact = answersWithType.find((item) => item?.type?.includes('networks'));
      const connectionChannels: { [key: string]: string } = {};
      const isWithoutAnswer = contact?.value?.includes('without answer');

      if (contact && !isWithoutAnswer) {
        connectionChannels[contact?.value.split('_')[1]] = contact.value.split('_')[2];

        if (!phone) {
          const contactPhoneValue = contact.value.split('|||')[0].split('_')[2];
          const correctPhoneNumber = contactPhoneValue?.includes('false') ? contactPhoneValue?.split('false')[0] : contactPhoneValue;
          phone = correctPhoneNumber;
        }

        if (!userName) {
          userName = contact.value.split('|||')[0].split('_')[3];
        }
      }

      setInitialValues({
        status: reviewsStatusIconsAndText(interfaceLanguage)[!review.data.status ? 1 : review.data.status],
        comment: review.data.comment,
      });

      return {
        companyId: company.data.id,
        reviewId: review.data.id,
        nodeId: node.data.id,
        companyName: company.data.name,
        nodeName: node.data.name,
        nodeCode: node.data.code,
        formName: form.data.name,
        userName,
        phone,
        email,
        connectionChannels,
        formSource,
        reviewCreatedAt: review.data.createAt,
        responsible: responsible?.data.name,
        reviewUpdatedAt: review.data.updatedAt,
        googlePlaceID: node.data.googlePlaceID,
        averageGoogleScoring: node.data.averageGoogleScoring,
        wasOverdue: review.data.wasOverdue,
      };
    } catch (e) {
      console.log(e);
    }
  }

  const getData = async () => {
    setMessageSending(true);
    setLoadingDataByCompany(true);

    const reviewInfo = await getReviewInfo(id!);
    const companyReviewTypes = await Api.getCompanyReviewType(+id!);
    const issues = await Api.getTicketsByReviewId(+id!);
    const contacts = await Api.getContactsByReviewId(+id!);

    setTypesInitialValues({
      issues: issues.data,
      newIssueTypeId: undefined,
      newIssueName: '',
      newIssueType: undefined,
      newIssueCategory: undefined,
      newIssueSubcategory: undefined,
      newIssueStatus: undefined,
    })

    if (companyId) {
      const types = await Api.getTicketTypesByCompany(+companyId);
      typesRef.current = types.data;
      getLocalTypes(types.data);

      const statuses = await Api.getTicketStatusesByCompany(+companyId);
      issueStatusesRef.current = statuses.data;
    }

    if (contacts.statusCode >= 200 && contacts.statusCode < 300) {
      setContactsData(contacts.data);
      if (contacts.data && contacts.data.length > 0) {
        const historyInfoRes = await Api.getReviewDiscussionHistory(+id!);
        const historyAllInfoRes = await Api.getReviewAllDiscussionHistory(+id!);
        historyInfoRef.current = (historyInfoRes.statusCode >= 200 && historyInfoRes.statusCode < 300) ? historyInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
        allHistoryInfoRef.current = (historyAllInfoRes.statusCode >= 200 && historyAllInfoRes.statusCode < 300) ? historyAllInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
        setClientId(historyInfoRes.data.clientID);
        setChatHistory(historyInfoRes.data.messages);
      }
    } else {
      setErrorMessage(translations.loading_error);
    }

    const issuesWithTypes = await Promise.all(issues.data.map((issue) => Api.getTicketType(issue.typeId).then((res) => res.data)));
    setIssuesTypes(issuesWithTypes);

    if (issues.statusCode >= 200 && issues.statusCode < 300) {
      setIssues(issues.data);
    } else {
      setErrorMessage(getTranslationByLangOrEng(interfaceLanguage, 'loading_error'));
    }

    setReviewData(reviewInfo);
    companyReviewTypesRef.current = (companyReviewTypes.statusCode >= 200 && companyReviewTypes.statusCode < 300) ? companyReviewTypes.data : [];

    setLoadingDataByCompany(false);
    setMessageSending(false);
    setInitialMessageValues({ text: '', close: false });
  };

  const getMessagesHistoryData = async () => {
    setMessageSending(true);
    const historyInfoRes = await Api.getReviewDiscussionHistory(+id!);
    const historyAllInfoRes = await Api.getReviewAllDiscussionHistory(+id!);
    historyInfoRef.current = (historyInfoRes.statusCode >= 200 && historyInfoRes.statusCode < 300) ? historyInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
    allHistoryInfoRef.current = (historyInfoRes.statusCode >= 200 && historyAllInfoRes.statusCode < 300) ? historyAllInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
    setClientId(historyInfoRes.data.clientID);
    setInitialMessageValues({ text: '', close: false });
    setMessageSending(false);
  };

  const fileSelectedHandler = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setFileLoading(true)
    if (event.target.files && event.target.files[0]) {
      Api.sendChatImg(event.target.files[0]).then((res) => {
        setImgData({ attachmentUrl: res.data.attachmentUrl, DeleteUrl: res.data.DeleteUrl, attachmentType: res.data.attachmentType })
        setFileLoading(false)
      }).catch((e) => console.log(e))
    }
  }, [])

  const deleteSelectedFile = async () => {
    const res = await fetch(imgData.DeleteUrl, {
      method: 'DELETE',
      redirect: 'follow',
      cache: 'no-cache',
    })

    if (res.status === 204) {
      setImgData({ attachmentUrl: '', DeleteUrl: '', attachmentType: 0 })
    } else {
      console.log('Error')
    }
  };

  useEffect(() => {
    try {
      if (id || companyId) {
        getData();
        Api.getChatTemplates(parseInt(companyId!, 10)).then((res) => {
          if (res.statusCode === 200) {
            setChatTemplates(res.data);
          } else {
            console.log(res.data)
          }
        })
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoaded(true);
    }
  }, []);

  useEffect(() => {
    if (chatHistoryRef.current) {
      chatHistoryRef.current.scrollTop = chatHistoryRef.current.scrollHeight;
    }
  }, [chatHistoryRef.current, historyInfoRef.current, allHistoryInfoRef.current]);

  function recursiveTimeout() {
    const interval = setTimeout(() => {
      if (id) {
        getMessagesHistoryData();
        recursiveTimeout();

        // @ts-ignore
        setApiRequestTimeout(interval);
      }
    }, 60000);
  }

  useEffect(() => {
    if (contactsData.length > 0) {
      if (isReviewDialogOpened) {
        recursiveTimeout();

        return () => {
          clearTimeout(apiRequestTimeout);
        };
      }

      if (!isReviewDialogOpened) {
        clearTimeout(apiRequestTimeout);
      }
    }
  }, [isReviewDialogOpened, contactsData]);

  useEffect(() => {
    if (!clientId && chatHistory.length > 0) {
      setIsReviewDialogOpened(false);
    }
  }, [clientId, chatHistory]);

  const messageTextareaStyles = {
    borderTopLeftRadius: 0, borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTop: 'none', fontFamily: 'Roboto', outline: 0,
  }

  function handleContinueDialog() {
    Api.textToReview(+id!, reopenDialogData).then(async (res) => {
      if (res.statusCode >= 200 && res.statusCode < 300) {
        const historyInfoRes = await Api.getReviewDiscussionHistory(+id!);
        const historyAllInfoRes = await Api.getReviewAllDiscussionHistory(+id!);
        historyInfoRef.current = (historyInfoRes.statusCode >= 200 && historyInfoRes.statusCode < 300) ? historyInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
        allHistoryInfoRef.current = (historyInfoRes.statusCode >= 200 && historyAllInfoRes.statusCode < 300) ? historyAllInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
        setClientId(historyInfoRes.data.clientID);
        setIsReviewDialogOpened(true);
      } else {
        setErrorMessage(getTranslationByLangOrEng(interfaceLanguage, 'review_handle_continue_dialog_error_message'));
      }
    });
  }

  useEffect(() => {
    setValidStatus(initialValues?.status.value)
  }, [initialValues?.status.value])

  const onSaveModalConfirm = useCallback((values: FormValues) => {
    const data: {comment?: string, status?: number} = {};

    if (values.comment.length) {
      data.comment = values.comment;
    }

    if (values.status) {
      data.status = values.status.value;
    }

    Api.updateReview(reviewRef.current!.id, data)
      .then((res) => {
        if (!(res.statusCode >= 200 && res.statusCode < 300)) {
          setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
        } else {
          setIsSuccessfullySaved(true);
          window.location.reload()
        }
      });
  }, []);

  const connectionChannelsArr = reviewData?.connectionChannels ? Object.keys(reviewData.connectionChannels) : [];
  const isSmsNetworkChosen = connectionChannelsArr?.includes('sms');
  const networksTypes = useMemo(() => contactsData.map((contact) => getTypeOfContact(contact)), [contactsData])
  const DisableNetworkIcon = (connectionChannelsArr[0] === 'telegram' && connectionChannelsArr[0]) ? networks[connectionChannelsArr[0]].icon : null;

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);

    setConfirmSaveModalOpen(true);
  }

  function onSubmitTypes(
    values: IssuesFormValues,
    { setSubmitting }: FormikHelpers<IssuesFormValues>,
  ) {
    setSubmitting(false);
  }

  async function onSubmit3(
    values: FormMessageValues,
    { setSubmitting, resetForm }: FormikHelpers<FormMessageValues>,
  ) {
    setSubmitting(false);

    if (contactsData.length && id) {
      const data = {
        text: values.text,
        close: values.close,
        attachmentUrl: imgData.attachmentUrl,
      };
      setErrorMessage('');
      setMessageSending(true);
      await Api.textToReview(+id, data).then(async (res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          resetForm({
            values: {
              text: '',
              close: false,
            },
          });
          setImgData({ attachmentUrl: '', DeleteUrl: '', attachmentType: 0 })
          const historyInfoRes = await Api.getReviewDiscussionHistory(+id!);
          const historyAllInfoRes = await Api.getReviewAllDiscussionHistory(+id!);
          historyInfoRef.current = (historyInfoRes.statusCode >= 200 && historyInfoRes.statusCode < 300) ? historyInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
          allHistoryInfoRef.current = (historyAllInfoRes.statusCode >= 200 && historyAllInfoRes.statusCode < 300) ? historyAllInfoRes.data.messages.sort((a, b) => +new Date(a.date) - +new Date(b.date)) : [];
          setClientId(historyAllInfoRes.data.clientID);
        } else {
          // @ts-ignore
          setChatError({ message: res.data.message, statusCode: res.statusCode });
        }
      }).finally(() => {
        setMessageSending(false);
      });
    }
  }

  function onDeclineAndCloseSaveModalHandler() {
    setConfirmSaveModalOpen(false);
    setIsSuccessfullySaved(false);
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
  }: FormikProps<FormValues>) => {
    useEffect(() => {
      setFieldValue('status', reviewsStatusIconsAndText(interfaceLanguage)[values.status.value]);
    }, [interfaceLanguage]);

    const UPStauses = useMemo(() => ((+companyId! === 111 && reviewData?.wasOverdue) ? hiddenUPStatuses2 : (+companyId! === 111 && reviewData?.formSource && (reviewData?.formSource?.id === 1 || reviewData?.formSource?.id === 2 || reviewData?.formSource?.id === 3 || (reviewData?.formSource?.id === 4 && !connectionChannelsArr.includes('telegram') && !connectionChannelsArr.includes('viber')))) ? hiddenUPStatuses : []), [companyId, reviewData?.wasOverdue])
    const statuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter((item) => (UPStauses.length ? !UPStauses.includes(item?.value) : !hiddenReviewStatuses.includes(item?.value)));

    return (
      <Form>
        <CustomSelect
          label={getTranslationByLangOrEng(interfaceLanguage, 'status')}
          name="status"
          selectKey="text"
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_status')}
          options={statuses || []}
          disabled={((initialValues?.status.value >= 2 && initialValues?.status.value < 6) || !!is78role) && reviewData?.companyId === 111}
          value={values.status}
          handleSelect={(reviewsStatusIconsAndText) => {
            setValidStatus(reviewsStatusIconsAndText.value)
            setFieldValue('status', reviewsStatusIconsAndText)
          }}
        />

        <TextArea
          disabled={((initialValues?.status.value >= 2 && initialValues?.status.value < 6) || !!is78role) && reviewData?.companyId === 111}
          name="comment"
          label={getTranslationByLangOrEng(interfaceLanguage, 'comment_internal')}
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('password', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'comment_placeholder')}
          value={values.comment}
          extraBlockStyles={{ marginBottom: 24, height: 120 }}
        />

        <SubmitButton disabled={((initialValues?.status.value >= 2 && initialValues?.status.value < 6) || !!is78role) && reviewData?.companyId === 111} extraBlockStyles={{ width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </Form>
    );
  };

  const renderForm3 = ({
    values,
    setFieldValue,
  }: FormikProps<FormMessageValues>) => (
    <Form>
      <div className="chatContainer">
        <h6>{getTranslationByLangOrEng(interfaceLanguage, 'chat_label')}</h6>
        <div className="chatted">
          <div onClick={() => setChatTab('target')} className={`chatted-item ${chatTab === 'target' && 'chatted-active'}`}>{getTranslationByLangOrEng(interfaceLanguage, 'chat_tab1')}</div>
          <div onClick={() => setChatTab('all')} className={`chatted-item ${chatTab === 'all' && 'chatted-active'}`}>{getTranslationByLangOrEng(interfaceLanguage, 'chat_tab2')}</div>
        </div>

        {chatTab === 'all' && (
          <div className={isReviewDialogOpened ? 'reviewDiscussionHistory' : 'reviewDiscussionHistory chatIsClosedReviewHistory'} ref={chatHistoryRef}>
            {allHistoryInfoRef.current.length > 0 ? (
              <>
                {allHistoryInfoRef.current.map((item, index) => (
                  <div key={item.id || `message-${index + 1}`} className={`message ${item.author}`}>
                    <p className="messageText">{item.text}</p>
                    {item.attachmentUrl && item.attachmentType === 1 && (
                      <img src={item.attachmentUrl} className="chatImg" alt="img" onClick={() => { setSelectedImg(item.attachmentUrl!); setImageModal(true) }} />
                    )}

                    {item.attachmentUrl && item.attachmentType === 2 && (
                      <a target="_blank" href={item.attachmentUrl} className="chatLink" rel="noreferrer">
                        <SaveIcon color="#0E9285" />
                        <span>Download</span>
                      </a>
                    )}
                    <div className="authorAndDate">
                      <p>{item.author}</p>
                      <p>{format(new Date(item.date), 'y-MM-d HH:mm:ss')}</p>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div className="chatHistoryEmptyContainer">
                <h2 className="chatHistoryEmptyTitle">{getTranslationByLangOrEng(interfaceLanguage, 'chat_history_is_empty')}</h2>
              </div>
            )}
          </div>
        )}
        {chatTab === 'target' && (
          <div className={isReviewDialogOpened ? 'reviewDiscussionHistory' : 'reviewDiscussionHistory chatIsClosedReviewHistory'} ref={chatHistoryRef}>
              {historyInfoRef.current.length > 0 ? (
                <>
                  {historyInfoRef.current.map((item, index) => (
                    <div key={item.id || `message-${index + 1}`} className={`message ${item.author}`}>
                      <p className="messageText">{item.text}</p>
                      {item.attachmentUrl && item.attachmentType === 1 && (
                        <img src={item.attachmentUrl} className="chatImg" alt="img" onClick={() => { setSelectedImg(item.attachmentUrl!); setImageModal(true) }} />
                      )}

                      {item.attachmentUrl && item.attachmentType === 2 && (
                        <a target="_blank" href={item.attachmentUrl} className="chatLink" rel="noreferrer">
                          <SaveIcon color="#0E9285" />
                          <span>Download</span>
                        </a>
                      )}
                      <div className="authorAndDate">
                        <p>{item.author}</p>
                        <p>{format(new Date(item.date), 'y-MM-d HH:mm:ss')}</p>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <div className="chatHistoryEmptyContainer">
                  <h2 className="chatHistoryEmptyTitle">{getTranslationByLangOrEng(interfaceLanguage, 'chat_history_is_empty')}</h2>
                </div>
              )}
          </div>
        )}
      </div>

      {isReviewDialogOpened ? (
        <div className="messageInputContainer">
          <TextArea
            name="text"
            onChange={setFieldValue}
            setIsTemplate={setIsTemplate}
            onKeyUp={() => handleKeyUp('text', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'review_message_input_placeholder')}
            value={values.text}
            disabled={!!is78role}
            extraBlockStyles={{ height: 100 }}
            extraTextAreaStyles={messageTextareaStyles}
            error={typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined}
          />

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <div className="btnWrapper">
            <button className="submitButton" type="submit">
              {messageSending ? <Loader height={20} margin={0} /> : <SendIcon height={20} color="#0E9285" />}
            </button>
            <button className="submitButton" onClick={() => setIsTemplate(!isTemplate)} type="button" disabled={!chatTemplates?.length}>
              <TemplatesIcon height={20} color="#0E9285" />
            </button>
            <button className="submitButton" type="button" onClick={() => setIsEmoji(!isEmoji)}>
              <EmojiIcon height={20} color="#0E9285" />
            </button>
            {/* // eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor="file-input" className="fileBtn">
              {fileLoading ? (<Loader height={20} margin={0} />) : (<PinIcon height={20} color="#0E9285" />)}
            </label>
            <input id="file-input" accept=".png, .jpeg, .jpg, .mp4, .avi, .mov, .mkv" type="file" onChange={fileSelectedHandler} style={{ display: 'none' }} />
          </div>
          {(isTemplate && chatTemplates) && (
            <div className="chatTemplates">
              {
                chatTemplates?.filter((template) => template.key.toLocaleLowerCase().includes(values.text.toLocaleLowerCase())).map((template) => (
                  <div onClick={() => { setFieldValue('text', template.content); setIsTemplate(false) }} className="chatTemplate">
                    <b>{template.key}</b>
                    -
                    {template.content}
                  </div>
                ))
              }
            </div>
          )}
          {isEmoji && (
            <div className="emojiContainer">
              <Picker
                data={data}
                onEmojiSelect={(e: any) => {
                  setFieldValue('text', values.text + e.native)
                }}
              />
            </div>
          )}
          {(imgData.attachmentUrl !== '' && imgData.attachmentType === 1) && (
            <div className="selectedImg">
              <img src={imgData.attachmentUrl} alt="attachmentUrl" />
              <button onClick={deleteSelectedFile} type="button" className="delBtn">x</button>
            </div>
          )}
          {(imgData.attachmentUrl !== '' && imgData.attachmentType === 2) && (
            <div className="selectedFile">
              <FileIcon width={50} height={50} />
              <button onClick={deleteSelectedFile} type="button" className="delBtn">x</button>
            </div>
          )}
        </div>
      ) : (
        <div className="chatIsFinished">{getTranslationByLangOrEng(interfaceLanguage, 'chat_is_finished')}</div>
      )}

      <div>
        {!isSmsNetworkChosen && (
        <div className="closeReviewDialogContainer">
          {isReviewDialogOpened ? (
            <button disabled={!!is78role} onClick={() => setIsModalOpened(true)} type="button" className="closeReviewDialogButton">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'finish_review_dialog_button')}</span>
            </button>
          ) : (
            <button disabled={((initialValues?.status.value >= 2 && initialValues?.status.value < 6) || !!is78role) && reviewData?.companyId === 111} onClick={handleContinueDialog} type="button" className="openDialogButton">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'continue_review_dialog_button')}</span>
            </button>
          )}
        </div>
        )}
      </div>

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  const renderTypesForm = ({
    values,
    setFieldValue,
    resetForm,
  }: FormikProps<IssuesFormValues>) => (
    <Form>
      <div className="issueSelectContainer">
        <FieldArray
          name="issues"
          render={({ insert, remove, push }) => (
            <div className="connectedIssuesList">
              <div className="listWrapper">
                <span className="listTitle">{getTranslationByLangOrEng(interfaceLanguage, 'review_page_type_of_task')}</span>
                {values.issues.length > 0 ? (
                  <ul>
                    {values.issues.map((issue) => {
                      const taskNameParts = typesRef.current.find((type) => type.id === issue.typeId)?.name.split('.');

                      return (
                        <li>
                          {taskNameParts?.map((part, index) => (
                            <div className="name">
                              <span>{`${issuePartNames[index]}:`}</span>
                              {part}
                            </div>
                          ))}

                          <TransparentButton
                            isLink
                            linkAddress={`/issues/${issue.id}`}
                            targetIsBlank
                            text={getTranslationByLangOrEng(interfaceLanguage, 'review_open_task_button_text')}
                            filled
                          />
                        </li>
                      )
                    })}
                  </ul>
                ) : <span className="noIssues">{getTranslationByLangOrEng(interfaceLanguage, 'review_no_associated_tasks')}</span>}
              </div>

              <div className="typeSelectorsContainer">
                <InputField
                  label={getTranslationByLangOrEng(interfaceLanguage, 'new_task_name')}
                  name="newIssueName"
                  onChange={setFieldValue}
                  onKeyUp={() => handleKeyUp('newIssueName', setErrorMessage, errorMessage)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'enter_task_name')}
                  value={values.newIssueName}
                  error={typeof errorMessage === 'object' ? getErrorMessage('newIssueName', errorMessage) : undefined}
                  extraBlockStyles={{ maxWidth: '428px', width: '100%' }}
                  required
                  disabled={!!is78role}
                />

                <CustomSelect
                  label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_type_label')}
                  name="newIssueType"
                  selectKey="name"
                  options={localTypes}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_placeholder')}
                  value={localTypes.find((type) => type.name === values.newIssueType)}
                  handleSelect={(value) => {
                    setFieldValue('newIssueType', value.name);

                    if (!value.categories || value.categories.length === 0) {
                      setFieldValue('newIssueTypeId', typesRef.current.find((type) => type.name === value.name)!.id);
                    }

                    setFieldValue('newIssueCategory', undefined);
                    setFieldValue('newIssueSubcategory', undefined);
                  }}
                  formGroupStyles={{ width: '250px' }}
                  required
                  isLoading={loadingDataByCompany}
                  search
                  disabled={!!is78role}
                />

                {values.newIssueType !== undefined && !!localTypes.find((item) => item.name === values.newIssueType)?.categories?.length && (
                <CustomSelect
                  label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_category_label')}
                  name="newIssueCategory"
                  selectKey="name"
                  options={localTypes.find((item) => item.name === values.newIssueType)!.categories!}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_placeholder')}
                  value={localTypes.find((item) => item.name === values.newIssueType)!.categories!.find((category) => category.name === values.newIssueCategory)}
                  handleSelect={(value) => {
                    setFieldValue('newIssueCategory', value.name);

                    if (!value.subcategories || value.subcategories.length === 0) {
                      setFieldValue('newIssueTypeId', typesRef.current.find((type) => type.name === [values.newIssueType, value.name].join('.'))!.id);
                    }

                    setFieldValue('newIssueSubcategory', undefined);
                  }}
                  formGroupStyles={{ width: '250px' }}
                  required
                  isLoading={loadingDataByCompany}
                  search
                  disabled={!!is78role}
                />
                )}

                {values.newIssueType !== undefined
                  && !!localTypes.find((item) => item.name === values.newIssueType)?.categories?.find((item) => item.name === values.newIssueCategory)?.subcategories?.length
                  && (
                    <CustomSelect
                      label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_subcategory_label')}
                      name="newIssueSubcategory"
                      selectKey="name"
                      options={localTypes.find((item) => item.name === values.newIssueType)!.categories!.find((item) => item.name === values.newIssueCategory)!.subcategories!}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_placeholder')}
                      value={
                        localTypes
                          .find((item) => item.name === values.newIssueType)!.categories!
                          .find((category) => category.name === values.newIssueCategory)!.subcategories!
                          .find((subcategory) => subcategory.name === values.newIssueSubcategory)
                      }
                      handleSelect={(value) => {
                        setFieldValue('newIssueSubcategory', value.name);
                        setFieldValue('newIssueTypeId', typesRef.current.find((type) => type.name === [values.newIssueType, values.newIssueCategory, value.name].join('.'))!.id);
                      }}
                      formGroupStyles={{ width: '250px' }}
                      required
                      isLoading={loadingDataByCompany}
                      search
                      disabled={!!is78role}
                    />
                  )}

                <CustomSelect
                  label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_status_label')}
                  name="newIssueStatus"
                  selectKey="name"
                  options={issueStatusesRef.current}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_status_placeholder')}
                  value={issueStatusesRef.current.find((status) => status.id === values.newIssueStatus)}
                  handleSelect={(value) => setFieldValue('newIssueStatus', value.id)}
                  formGroupStyles={{ width: '250px' }}
                  required
                  isLoading={loadingDataByCompany}
                  search
                  disabled={!!is78role}
                />

                <div className="addFieldButtonContainer">
                  <TransparentButton
                    handleClick={async () => {
                      const newTask = await Api.createTicket(
                        +companyId!,
                        {
                          name: values.newIssueName!,
                          statusId: values.newIssueStatus!,
                          companyId: +companyId!,
                          nodeId: nodeId!,
                          typeId: values.newIssueTypeId!,
                          priority: 1,
                          comment: '',
                          reviewId: +id!,
                          connectedIssueId: undefined,
                        },
                      );

                      if (newTask.statusCode >= 200 && newTask.statusCode < 300) {
                        resetForm({
                          values: {
                            issues: [...values.issues, newTask.data],
                            newIssueTypeId: undefined,
                            newIssueName: '',
                            newIssueType: undefined,
                            newIssueCategory: undefined,
                            newIssueSubcategory: undefined,
                            newIssueStatus: undefined,
                          },
                        })
                      }
                    }}
                    text={getTranslationByLangOrEng(interfaceLanguage, 'task_form_create_task')}
                    filled
                    disabled={!!is78role}
                  />
                </div>
              </div>
            </div>
          )}
        />
      </div>
    </Form>
  )

  const getElementByAnswerType = (answer: INewAnswerStructure, form: IExtraField) => {
    switch (answer?.type) {
      case 'checkbox': {
        const answerValue = answer.value.split('|||');
        const showChoiceDescriptionList = form.answers?.filter((item) => item.showChoiceDescription).map((item) => item.value);
        const extraFieldAnswer = answer.value.split('|||');

        return (
          <div className="checkboxContainer">
            {form.answers?.map((item) => (
              <div key={item.id}>
                <div className="checkboxWrapper">
                  <div className={answerValue.includes(item.value!) ? 'checkboxSelected checkbox' : 'checkbox'}>
                    {answerValue.includes(item.value!) && <CheckMark width={10} height={8} />}
                  </div>

                  <span>{item.value}</span>
                </div>
                {(answerValue.includes(item.value!) && showChoiceDescriptionList?.includes(item.value!) && extraFieldAnswer[answerValue.indexOf(item.value!) + 1]) && (
                  <div className="inputWrapper checkboxInputWrapper">
                    <div className="input">{extraFieldAnswer[answerValue.indexOf(item.value!) + 1]}</div>
                  </div>
                )}
              </div>
            ))}
          </div>
        );
      }

      case 'extra_question': {
        const label = form.options?.find((option) => option.key === 'label');
        const answerParts = answer.value?.split('|||');

        return (
          <div className="extraQuestionContainer">
            {answerParts?.[0] && (
              <div className="inputWrapper">
                {label && <span className="inputLabel">{label}</span>}
                <div className="input">{answerParts[0]}</div>
              </div>
            )}
            {answerParts?.[2] && (
              <div className="inputWrapper">
                <span className="inputLabel other">{getTranslationByLangOrEng(interfaceLanguage, 'other')}</span>
                <div className="input">{answerParts[2]}</div>
              </div>
            )}
          </div>
        );
      }

      case 'radio': {
        const selectedOption = form.answers?.find((option) => option.value === answer.value.split('|||')[0]);
        const extraFieldAnswer = answer.value.split('|||')[1];

        return (
          <div className="radioContainer">
            {form.answers?.map((option, index) => {
              const selectedOptions = answer.value.split('|||');
              return (
              // eslint-disable-next-line react/no-array-index-key
                <div key={index} className="optionContainer">
                  <div className={selectedOptions.includes(option.value!) ? 'radioOption checked' : 'radioOption'}>
                    {selectedOptions.includes(option.value!)
                        && <div className="radioOptionSelected" />}
                  </div>
                  <div className="value">{option.value}</div>
                </div>
              );
            })}
            {(!!selectedOption && selectedOption.showChoiceDescription) && (
            <div className="inputWrapper">
              {selectedOption.choiceDescriptionTitle && (
              <span className="inputLabel">
                {selectedOption.choiceDescriptionTitle}
              </span>
              )}
              {(!!extraFieldAnswer && selectedOption.choiceDescriptionPlaceholder) && (
              <div className="input">{extraFieldAnswer}</div>
              )}
            </div>
            )}
          </div>
        );
      }

      case 'quantity': {
        const answerQuantity = answer?.value?.split('|||')[0] || '';
        return (
          <div style={{ width: '100%', maxWidth: '400px' }}>
            <QuantityField
              name="quantity"
              index={1}
              value={answerQuantity}
              onChange={() => null}
              required={false}
              buttonsBackgroundColor="#0E9285"
              signsColor={form?.quantityButtonSignsColor}
              extraBlockStyles="extraBlockStyles"
              quantityCounterContainerExtraStyles="quantityCounterContainerExtraStyles"
            />
          </div>
        )
      }

      case 'upload_files': {
        const uploadFiles = answer?.value?.split('|||').filter((item) => item !== '');
        const uploadFilesWithType = uploadFiles?.map((item) => ({ type: item?.split('_')[0], fileLink: item?.split('_')[1] }));
        return (
          <div className="infoContainer">
            <div className="filesContainer">
              {uploadFilesWithType.map((item) => {
                const Icon = reviewsFilesIcons[item.type];
                const url = item.fileLink;
                return (
                  <div className="oneFile">
                    {item.type === 'document' ? (
                      <a className="fileContainer" href={url} key={url}>
                        <div>
                          <Icon width={16} height={16} color="#0E9285" />
                        </div>
                        <span>{reviewsFilesExtraFieldText(interfaceLanguage)[item.type]}</span>
                      </a>
                    ) : (
                      <div className="fileContainer" style={{ cursor: 'pointer' }} onClick={() => window.open(url, 'Data', 'height=500,width=500')} key={url}>
                        <Icon width={16} height={16} color="#0E9285" />
                        <span>{reviewsFilesExtraFieldText(interfaceLanguage)[item.type]}</span>
                      </div>
                    )}
                  </div>
                )
              })}
            </div>
          </div>
        );
      }

      case 'scale_star':
      case 'scale_number':
      case 'scale_color':
      case 'scale_nps': {
        let colors: any;

        const isValidAnswer = (answer.type.split('|||')[0].split('_')[1] === 'star' && form.type === 'scale' && form.selectedOption === 'star')
            || (answer.type.split('|||')[0].split('_')[1] === 'number' && form.type === 'scale' && form.selectedOption === 'number')
            || (answer.type.split('|||')[0].split('_')[1] === 'color' && form.type === 'scale' && form.selectedOption === 'color')
            || (answer.type.split('|||')[0].split('_')[1] === 'nps' && form.type === 'scale' && form.selectedOption === 'nps')

        if (answer.type.split('|||')[0].split('_')[1] === 'star' && form.type === 'scale' && form.selectedOption === 'star') {
          colors = {
            selectedStarColor: form.selectedStarColor!.color,
            notSelectedStarBorder: form.notSelectedStarBorder!.color,
            notSelectedStarBackground: form.notSelectedStarBackground!.color,
          };
        }

        if (answer.type.split('|||')[0].split('_')[1] === 'number' && form.type === 'scale' && form.selectedOption === 'number') {
          colors = {
            numbersColor: form.numbersColor?.color || '',
            selectedButtonColor: form.selectedButtonColor?.color || '',
          };
        }

        if (answer.type.split('|||')[0].split('_')[1] === 'color' && form.type === 'scale' && form.selectedOption === 'color') {
          colors = {
            colors: form?.scaleGradientColors?.map((item) => item.color) || [],
            pointerColor: form?.scalePointerColor?.color || '',
          };
        }

        if (answer.type.split('|||')[0].split('_')[1] === 'nps' && form.type === 'scale' && form.selectedOption === 'nps') {
          colors = {
            signatureColor: form?.signatureColor?.color || '',
          };
        }

        if (isValidAnswer) {
          return (
            <div className="scaleContainer">
              <ScaleField
                scaleType={`${answer.type.split('|||')[0].split('_')[1]}`}
                colors={colors}
                maxValues={form.maxValue!}
                defaultValues={form.defaultValue!}
                showDefaultValue={form.showDefaultValue}
                signatureMin={form.signatureMin || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_signature_min_label')}
                signatureMax={form.signatureMax || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_signature_max_label')}
                index={1}
                required={false}
                value={+answer.value.split('/')[0]}
                handleChange={() => {}}
                withoutTitle
              />
            </div>
          );
        }
      }
    }
  };

  const handleCancel = () => {
    navigate(`/reviews/page/1?companyID=${companyId}`)
  }

  const copyToClipboard = async (text: string) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  return (
    <ReviewStyles>
      <Helmet>
        <title>{`${id} Review Voicer`}</title>
      </Helmet>
      {(!isLoaded || !reviewData) && (
        <div className="loaderReviewPageContainer">
          <Loader />
        </div>
      )}

      {isLoaded && reviewData && (
        <>
          <SubHeader
            title={getTranslationByLangOrEng(interfaceLanguage, 'companies_review_title')}
            pathArray={[{ name: reviewData.companyName }, { name: reviewData.formSource?.name as string }, { name: reviewData.nodeCode as string },
            ]}
          >
            <div className="subBtns" style={{ display: 'flex' }}>
              <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'audit')} handleClick={() => setAudit(true)} filled />
              <TransparentButton disabled={!(initialValues?.status.value === validStatus)} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} handleClick={handleCancel} filled={(initialValues?.status.value === validStatus)} />
            </div>
          </SubHeader>

          <div className="mainWrapper">
            <div className="mainContainer">
              <div className="companyInfo">
                {reviewData?.reviewId && (
                  <div className="infoContainer">
                    <span>{getTranslationByLangOrEng(interfaceLanguage, 'review_review_id')}</span>
                    <span>{reviewData.reviewId}</span>
                  </div>
                )}

                {reviewData?.reviewCreatedAt && (
                  <div className="infoContainer">
                    <span>{getTranslationByLangOrEng(interfaceLanguage, 'review_created_at')}</span>
                    <span>{reviewData.reviewCreatedAt}</span>
                  </div>
                )}

                {reviewData?.reviewUpdatedAt && (
                  <div className="infoContainer">
                    <span>{getTranslationByLangOrEng(interfaceLanguage, 'review_updated_at')}</span>
                    <span>{reviewData.reviewUpdatedAt}</span>
                  </div>
                )}

                {reviewData?.nodeName && (
                  <div className="infoContainer">
                    <span>{getTranslationByLangOrEng(interfaceLanguage, 'review_node_name')}</span>
                    <span>{reviewData.nodeName}</span>
                  </div>
                )}

                {reviewData?.formSource && (
                  <div className="infoContainer">
                    <span>{getTranslationByLangOrEng(interfaceLanguage, 'review_form_source')}</span>
                    <span>{reviewData?.formSource.name}</span>
                  </div>
                )}

                {reviewData?.responsible && +companyId! !== 136 && (
                  <div className="infoContainer">
                    <span>{getTranslationByLangOrEng(interfaceLanguage, 'issues_form_responsible_label')}</span>
                    <span>{reviewData?.responsible}</span>
                  </div>
                )}

                {reviewData?.averageGoogleScoring && (
                  <div className="infoContainer">
                    <span>Google score for target node</span>
                    <span>{reviewData?.averageGoogleScoring}</span>
                  </div>
                )}
              </div>
              <div className="answersContainer">
                {answers && forms && (
                  answers.map((answer, index) => {
                    // @ts-ignore
                    const answerForm = forms?.extraFields?.length > 0
                      ? forms.extraFields?.find((extraField) => extraField.position === answer.key) : allPageFields?.find((field) => field.id === answer.fieldID);
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="answerContainer" key={index}>
                        <div className="answerLabel">
                          <h4>
                            {index + 1 < 10 ? `0${index + 1}` : index + 1}
                            .
                            {' '}
                            {answerForm?.question}
                          </h4>
                          {answer.value.includes('true') && (
                            <div>
                              <KeyIcon width={20} height={20} color="#fae087" />
                            </div>
                          )}
                        </div>
                        {answerForm?.questionDescription && (
                          <h4 className="questionDescription">
                            {answerForm?.questionDescription}
                          </h4>
                        )}

                        <div>
                          {getElementByAnswerType(answer, answerForm!)}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>
            </div>

            <div className="mainManagerForm">
              {initialValues && (
                <Formik
                  innerRef={formRef}
                  initialValues={initialValues}
                  onSubmit={onSubmit}
                >
                  {renderForm}
                </Formik>
              )}

              <div className="issuesContainer" style={{ marginTop: 20 }}>
                {typesInitialValues && (
                  <Formik
                    innerRef={typesFormRef}
                    initialValues={typesInitialValues}
                    onSubmit={onSubmitTypes}
                  >
                    {renderTypesForm}
                  </Formik>
                )}
              </div>
            </div>

            <div className="mainChat">
              <div className="contactsContainer">
                {reviewData.userName && (
                  <div className="infoContainer">
                    <span>
                      {getTranslationByLangOrEng(interfaceLanguage, 'name')}
                      :
                    </span>
                    <div className="userContainer">
                      {!!contactsData.length && contactsData[0].client_photo && <img src={contactsData[0].client_photo} alt="avatar" /> }
                      <span>{reviewData.userName}</span>
                    </div>
                  </div>
                )}
                {reviewData.phone && (
                  <div className="infoContainer">
                    <span>
                      {getTranslationByLangOrEng(interfaceLanguage, 'phone')}
                      :
                    </span>
                    <div>
                      <a href={`tel:+${reviewData.phone}`}><span>{`+${reviewData.phone}`}</span></a>
                      <div title="Copy" onClick={() => copyToClipboard(`+${reviewData.phone}`)}><CopyIcon width={20} height={20} /></div>
                    </div>
                  </div>
                )}
                {reviewData.email && (
                  <div className="infoContainer">
                    <span>
                      {getTranslationByLangOrEng(interfaceLanguage, 'email')}
                      :
                    </span>
                    <span>{reviewData.email}</span>
                  </div>
                )}
                {(!!reviewData.connectionChannels && !!Object.keys(reviewData.connectionChannels).length) && (
                  <div className="infoContainer">
                    <span>
                      {getTranslationByLangOrEng(interfaceLanguage, 'channels_of_connections')}
                      :
                    </span>
                    <div className="connectionChannelsWrapper">
                      <div className="connectionChannelsContainer">
                        {!networksTypes.includes(connectionChannelsArr[0]) && DisableNetworkIcon && (
                        <DisableNetworkIcon width={32} height={28} color="rgb(204, 204, 204)" />
                        )}
                        {networksTypes && (
                          networksTypes.map((contact) => {
                            const NetworkIcon = contact ? networks[contact].icon : null;
                            return (
                              <div key={contact} className="networkDataContainer">
                                {NetworkIcon && <NetworkIcon width={32} height={28} color={networks[contact].color} key={contact} />}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              {(!(+companyId! === 117) && !!initialMessageValues && !(+companyId! === 111 && !(!!reviewData.connectionChannels && !!Object.keys(reviewData.connectionChannels).length))) && !(initialValues?.status.value === 5 && +companyId! === 111) && (
                <Formik
                  innerRef={form3Ref}
                  initialValues={initialMessageValues}
                  onSubmit={onSubmit3}
                >
                  {renderForm3}
                </Formik>
              )}
            </div>
          </div>
        </>
      )}

      {confirmSaveModalOpen
        && (
          <ModalConfirmDecline
            onClose={onDeclineAndCloseSaveModalHandler}
            onConfirm={() => onSaveModalConfirm(formRef.current!.values)}
            onDecline={onDeclineAndCloseSaveModalHandler}
            confirmText={id ? getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button') : getTranslationByLangOrEng(interfaceLanguage, 'create_button')}
            declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
            title={!isSuccessfullySaved ? getTranslationByLangOrEng(interfaceLanguage, 'review_save_modal_title') : getTranslationByLangOrEng(interfaceLanguage, 'changes_saved_successfully')}
            error={!!dataSaveError}
            errorText={dataSaveError}
            firstButtonStyles={{ background: isSuccessfullySaved ? '#0E9285' : '#ff0000' }}
            secondButtonStyles={{ background: '#0E9285' }}
            isConfirmButtonShown={!isSuccessfullySaved}
          />
        )}

      {nonSubmitModal && (
        <ModalConfirmDecline
          onClose={() => setNonSubmitModal(false)}
          onDecline={() => setNonSubmitModal(false)}
          title={getTranslationByLangOrEng(interfaceLanguage, 'non_submit_title')}
          firstButtonStyles={{ backgroundColor: '#FF0101', border: '1px solid #FF0101' }}
          buttonsLabel={getTranslationByLangOrEng(interfaceLanguage, 'non_submit_description')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
        />
      )}

      {imageModal && (
        <ImgModal onClose={() => setImageModal(false)} url={selectedImg} />
      )}

      {isModalOpened && (
        <ModalConfirmDecline
          onClose={() => setIsModalOpened(false)}
          onDecline={() => setIsModalOpened(false)}
          onConfirm={() => {
            Api.textToReview(+id!, closeDialogData).then((res) => {
              if (res.statusCode >= 200 && res.statusCode < 300) {
                setIsReviewDialogOpened(false);
                setIsModalOpened(false);
              } else {
                setModalErrorIndex(1);
              }
            });
          }}
          title={getTranslationByLangOrEng(interfaceLanguage, 'finish_review_dialog_modal_title')}
          buttonsLabel={getTranslationByLangOrEng(interfaceLanguage, 'finish_review_dialog_modal_description')}
          confirmText={getTranslationByLangOrEng(interfaceLanguage, 'finish_review_dialog_button')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          firstButtonStyles={{ border: '1px solid #C4C4C4', backgroundColor: '#ffffff', color: '#000000' }}
          secondButtonStyles={{ backgroundColor: '#FF0101', border: '1px solid #FF0101' }}
          error={modalErrorIndex === 1}
          errorText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
        />
      )}

      {audit && (
        <AuditModal onClose={() => setAudit(false)} id={id!} companyID={+companyId!} />
      )}

      {chatError && (
        <ModalConfirmDecline
          onClose={() => {
            setChatError(undefined);
          }}
          onDecline={() => {
            setChatError(undefined);
          }}
          title={getTranslationByLangOrEng(interfaceLanguage, 'error_title')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'close_button')}
          firstButtonStyles={{ border: '1px solid #0E9285', backgroundColor: '#ffffff', color: '#0E9285' }}
          extraStyles={{ width: '100%', maxWidth: 500 }}
          buttonsWrapperStyles={{ display: 'flex', justifyContent: 'center' }}
        >
          <div className="chatErrorModalContent">
            <p>{getTranslationByLangOrEng(interfaceLanguage, 'chat_error_description')}</p>
            <div className="errorDetails">
              <p>{getTranslationByLangOrEng(interfaceLanguage, 'chat_error_no_user')}</p>
            </div>
          </div>
        </ModalConfirmDecline>
      )}
    </ReviewStyles>
  );
}
