import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  ErrorMessage,
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { unwrapResult } from '@reduxjs/toolkit';
import base64url from 'base64url';
import buffer from 'buffer';
import { useWindowSize } from 'usehooks-ts';
import InputField from '../../form/inputField/InputField';
import SubmitButton from '../../form/submitButton/SubmitButton';
import {
  changeUserData, resetMessage, setUser, useAppDispatch, useAppSelector,
} from '../../../state';
import { FormValues, validationSchema1 } from './formValuesAndValidation';
import { Api, ApiContacts } from '../../../api';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import { FormErrorMessage, TransparentButton } from '../../atoms';
import { NotifiableSource } from '../notifiableSource';
import { UserProfileFormStyles } from './UserProfileFormStyles';
import { QRModal } from '../../organisms';
import Checkbox from '../../form/checkbox/Checkbox';
import { EmailIcon, TelegramIcon, ViberIcon } from '../../../assets';

interface Props {
  allowToEdit?: boolean;
  setAllowToEdit?: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserProfileForm = React.memo(({ allowToEdit, setAllowToEdit }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { width } = useWindowSize();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const {
    data, status, statusCode, message,
  } = useAppSelector((state) => state.user);

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [reQuery, setReQuery] = useState<boolean>(false);
  const [url, setUrl] = useState<string>('')
  const [qrModal, setQrModal] = useState<boolean>(false)
  const [contacts, setContacts] = useState<ApiContacts[]>([])

  const formRef = useRef<FormikProps<FormValues>>(null);

  const initialValues: FormValues = {
    name: data!.name,
    email: data!.email,
  }

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);

    const { passwords, ...rest } = values;

    const newUserData = {
      id: Number(data!.id),
      password: passwords?.password,
      passwordConfirmation: passwords?.passwordConfirmation,
      ...rest,
    }

    dispatch(changeUserData(newUserData))
      .then(unwrapResult)
      .then((result: { message: string; }) => {
        if (result?.message === 'OK' && setAllowToEdit) {
          setAllowToEdit(false);
        } else {
          setErrorMessage(result);
        }
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  }

  useEffect(() => {
    Api.getUserContacts(data?.id!).then((res) => {
      setContacts(res.data)
    })
  }, [])

  useEffect(() => {
    if (status === 'idle' && !!data && statusCode === 200) {
      if (message === 'OK') {
        dispatch(resetMessage());
        Api.getMyAccount().then((res) => {
          dispatch(setUser(res.data));
        });
      }
      navigate('/profile');
    } else if (data) {
      setErrorMessage(data);
    }
  }, [data, status, message]);

  function handleOpenBotLink(messenger: 'telegram' | 'viber') {
    global.Buffer = global.Buffer || buffer.Buffer;

    if (messenger === 'telegram') {
      setUrl(`${process.env.REACT_APP_TELEGRAM_BOT_HOST!}${base64url(`${data!.id!}`)}`)
    } else {
      setUrl(`${process.env.REACT_APP_VIBER_BOT_HOST!}${base64url(`${data!.id!}`)}`)
    }

    setQrModal(true)
  }

  const handleToggleNotification = (contact: ApiContacts) => {
    Api.putContactNotification(contact.id, { active: !contact.active }).then((res) => {
      if (res.statusCode === 200) {
        setContacts((prev) => prev.map((cont) => (cont.id === contact.id ? { ...cont, active: !cont.active } : cont)))
      }
    })
  }

  const hideNoty = useMemo(() => {
    if (data) {
      return data.roles?.find((role) => [78, 82].includes(role.id!));
    }

    return false;
  }, [data])

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="inputs">
        <InputField
          name="name"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'full_name_placeholder')}
          value={values.name}
          error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'full_name_label')}
          disabled={!allowToEdit}
        />
        <InputField
          name="email"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('email', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'email_placeholder')}
          value={values.email}
          error={typeof errorMessage === 'object' ? getErrorMessage('email', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'email_label')}
          disabled={!allowToEdit}
          required
        />
      </div>

      <div className="inputs">
        <InputField
          name="passwords.password"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('passwords.password', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_password_placeholder')}
          value={values.passwords?.password}
          error={typeof errorMessage === 'object' ? getErrorMessage('passwords.password', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'user_password_title')}
          required
          disabled={!allowToEdit}
          type="password"
        />

        <InputField
          name="passwords.passwordConfirmation"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('passwords.passwordConfirmation', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_placeholder')}
          value={values.passwords?.passwordConfirmation}
          error={typeof errorMessage === 'object' ? getErrorMessage('passwords.passwordConfirmation', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_title')}
          required
          disabled={!allowToEdit}
          type="password"
        />
      </div>

      {contacts && (
        <div className="contacts-wrapper">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'notifications')}</h3>
          <div className="contacts">
            {contacts.map((cont) => {
              if ('telegramID' in cont) {
                return (
                  <div key={cont.telegramID} title={cont.telegramID}>
                    <TelegramIcon width={38} height={38} />
                    <Checkbox name={`telegram-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                  </div>
                );
              }
              if ('viberID' in cont) {
                return (
                  <div key={cont.viberID} title={cont.viberID}>
                    <ViberIcon width={38} height={38} />
                    <Checkbox name={`viber-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                  </div>
                );
              }
              if ('email' in cont) {
                return (
                  <div key={cont.email} title={cont.email}>
                    <EmailIcon width={38} height={38} />
                    <Checkbox disabled name={`email-${cont.id}`} value={cont.active} onChange={() => handleToggleNotification(cont)} />
                  </div>
                );
              }
              return null;
            })}
          </div>
        </div>
      )}

      {!hideNoty && <NotifiableSource id={data?.id!} reQuery={reQuery} setReQuery={setReQuery} />}

      <div className="formErrorContainer">
        {errors.passwords && <span>{JSON.stringify(errors.passwords)}</span>}
        {!errors.passwords && touched.passwords && <ErrorMessage name="passwords" component={FormErrorMessage} />}
      </div>

      <div className="inputs">
        <TransparentButton
          text={getTranslationByLangOrEng(interfaceLanguage, 'connect_tg')}
          handleClick={() => handleOpenBotLink('telegram')}
          targetIsBlank
          extraStyles={{ marginBottom: 24 }}
        />
        <TransparentButton
          text={getTranslationByLangOrEng(interfaceLanguage, 'connect_vb')}
          handleClick={() => handleOpenBotLink('viber')}
          targetIsBlank
          extraStyles={{ marginBottom: 24 }}
        />
      </div>

      {allowToEdit && <SubmitButton extraButtonStyles={{ width: '608px' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}</SubmitButton>}

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  return (
    <UserProfileFormStyles tabletVersion={width < 756}>
      {qrModal && (<QRModal onClose={() => setQrModal(false)} url={url} />)}

      <div className="formContainer">
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema1(interfaceLanguage)}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      </div>
    </UserProfileFormStyles>
  );
});
