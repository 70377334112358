import React, { useEffect, useMemo, useState } from 'react';
import { SidebarStyles } from './SidebarStyles';
import { Link, useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import {
  AnalyticIcon, CompaniesIcon, FeedbackIcon,
  IssueIcon, SendIcon,
  UsersIcon,
} from '../../../assets';
import { Api } from '../../../api';
import { useWindowSize } from 'usehooks-ts';

export const Sidebar = React.memo(() => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { fullWidth } = useAppSelector((state) => state.sidebar);
  const { width } = useWindowSize();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { data, companies, loggedIn } = useAppSelector((state) => state.user);
  const [reviewsCountRef, setReviewsCountRef] = useState<number>(0)

  const isRootRole = data?.roles?.find((item) => item.name === 'Root')?.permissions?.find((item) => item.name === 'Root');

  const is46role = useMemo(() => {
    if (data) {
      return data.roles?.find((role) => role.id === 46);
    }

    return false;
  }, [data])

  useEffect(() => {
    if (loggedIn) {
      Api.getCompanies()
        .then((res) => {
          const companies = res.data.map((el) => el.id);
          Api.getReviewCount(companies, [0]).then((res) => setReviewsCountRef(res.data)).catch((error) => { console.error(error) })
        });
    }
  }, [loggedIn, data]);

  const tabs: (lang: string) => {[key: string]: any}[] = (lang) => ([
    ...(is46role || isRootRole || companies.includes(111) || companies.includes(110) || companies.includes(1) || companies.includes(117) || companies.includes(135) ? [{
      key: 'analytic',
      icon: <AnalyticIcon />,
      text: getTranslationByLangOrEng(lang, 'sidebar_analytic'),
      url: '/analytic',
    }] : []),
    ...(companies?.[0] !== 111 && companies?.[0] !== 135 ? [{
      key: 'companies',
      icon: <CompaniesIcon />,
      text: getTranslationByLangOrEng(lang, 'sidebar_companies'),
      url: '/companies',
    }] : []),
    {
      key: 'reviews',
      icon: <FeedbackIcon />,
      text: getTranslationByLangOrEng(lang, 'sidebar_reviews'),
      url: '/reviews',
    },
    ...(companies?.[0] !== 111 && companies?.[0] !== 135 ? [{
      key: 'issues',
      icon: <IssueIcon />,
      text: getTranslationByLangOrEng(lang, 'sidebar_tasks'),
      url: '/issues',
    }] : []),
    ...(isRootRole && companies?.[0] !== 111 ? [{
      key: 'users',
      icon: <UsersIcon />,
      text: getTranslationByLangOrEng(lang, 'sidebar_users'),
      url: '/users',
    }] : []),
    // ...(isUserWithThirdId ? [{
    //   key: 'impex',
    //   icon: <DocumentIcon />,
    //   text: getTranslationByLangOrEng(lang, 'sidebar_impex'),
    //   url: '/impex',
    // }] : []),
    ...(isRootRole ? [{
      key: 'massMailings',
      icon: <SendIcon />,
      text: getTranslationByLangOrEng(lang, 'sidebar_mass_mailings'),
      url: '/massMailings/create',
    }] : []),
  ]);

  function getSidebarMainExtraStyles() {
    return (pathname.startsWith('/auth'))
      ? { width: 0 }
      : fullWidth
        ? { width: 208 }
        : width > 768
          ? {
            width: 56, paddingLeft: 0, paddingRight: 0, alignItems: 'center',
          }
          : {
            width: 0, paddingLeft: 0, paddingRight: 0, alignItems: 'center',
          }
  }

  return (
    <SidebarStyles style={{ ...getSidebarMainExtraStyles() }}>
      <ul>
        {tabs(interfaceLanguage).map((tab) => (
          <li key={tab.key} className={pathname.startsWith(tab.url) ? 'active' : ''}>
            <Link to={tab.url}>
              <div
                className={
                  pathname.startsWith(tab.url)
                    ? fullWidth ? 'verticalLine active' : 'verticalLine active hidden'
                    : fullWidth ? 'verticalLine' : 'verticalLine hidden'
                }
              />
              <div className="iconContainer">{tab.icon}</div>
              {fullWidth && <span>{tab.text}</span>}
              {tab.key === 'reviews' && <span className={fullWidth ? 'count' : 'count count-abs'}>{reviewsCountRef}</span>}
            </Link>
          </li>
        ))}
      </ul>
    </SidebarStyles>
  );
})
