import React, { createContext, useMemo, useState } from 'react';

export const RefContext = createContext({});

export const RefContextProvider: React.FC = ({ children }) => {
  const [refs, setRefs] = useState({});

  const providerValue = useMemo(() => ({ refs, setRefs }), [refs, setRefs]);

  return (
    <RefContext.Provider value={providerValue}>
      {children}
    </RefContext.Provider>
  );
};
