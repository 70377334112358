import styled from 'styled-components';

export const CompanyInfoFormStyles = styled.div`
  padding: 26px 32px 80px 16px;
  
  .addLogoWrapper {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 32px;
    margin-bottom: 24px;

    .logoContainer {
      height: 80px;
      background: #FFF;
      box-sizing: border-box;
      overflow: hidden;

      &.default {
        display: grid;
        place-items: center;
        background: #F0F0F0;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
      }

      .selectedImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0;
      
      .description {
        font-size: 15px;
        line-height: 16px;
        color: #979797;
      }
    }
  }

  .formContainer {
    max-width: 544px;
  }
  
  .labelDomain {
    margin-left: 10px;
    margin-bottom: 0;
  }
  
  .extraFieldContainer {
    position: relative;
    border-top: 1px solid #999;
    padding-top: 24px;
    
    .removeButtonContainer {
      position: absolute;
      top: calc(152px / 2 - 10px);
      right: -60px;
    }
  }
  
  .addFieldButtonContainer {
    margin-top: 10px;
    margin-bottom: 36px;
  }

  .uploadError {
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }

  .multiLanguageWrap {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .multiLanguageTitle {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      margin-bottom: 8px;
    }

    .multiLanguageSelect {
      max-width: 253px;
      box-sizing: border-box;
      width: 100%;
      border-radius: 4px;
      position: relative;

      .headMultiLanguageSelect {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #979797;
        padding: 12px 16px;
        border: 1px solid #E5E5E5;
      }

      .checkboxLanguagesList {
        width: 100%;
        position: absolute;
        padding: 10px 4px 6px;
        border: 1px solid #E5E5E5;
        gap: 8px;
        box-sizing: border-box;
        background-color: #FFFFFF ;

        .searchWrapper {
          height: 32px;
          display: flex;
          align-items: center;
          box-sizing: border-box;
          padding: 0 16px;
          background-color: #fcfcfc;

          input {
            flex-grow: 1;
            margin-left: 12px;
            padding: 0;
            border: none;
            outline: none;
            font-size: 14px;
            color: #000;
            background-color: #fcfcfc;

            &::placeholder {
              font-size: 14px;
              color: #6e6e6e;
            }
          }
        }

        .listItem {
          width: 100%;
          list-style: none;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 1px solid #C4C4C4;
          border-radius: 4px;
          padding: 8px 16px;
          box-sizing: border-box;

          &:not(:last-child) {
            margin-bottom: 8px;
          }

          > div {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;

            label {
              display: flex;
              justify-content: center;
              flex-grow: 1;
            }
          }
        }
      }
  }
  
  form {
    margin-bottom: 8px;
  }
`;
