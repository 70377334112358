import React from 'react'

export const PencilIcon = React.memo(() => (
  <svg
    width="16"
    height="20"
    viewBox="0 0 16 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 0.012207L15 3.01221L12.713 5.30021L9.713 2.30021L12 0.012207ZM0 12.0002V15.0002H3L11.299 6.71321L8.299 3.71321L0 12.0002ZM0 18.0002H16V20.0002H0V18.0002Z"
      fill="#0E9285"
    />
  </svg>
));
