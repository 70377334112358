import styled from 'styled-components';

export const EditUserModalStyles = styled.div`
    .modalBody {
        background: #fff;
        max-height: 100vh;
        overflow: auto;
        margin: 20px 0;
    }
`;
