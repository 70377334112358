import { FieldIcon } from '../../../../components';
import { FormConstructorItemStyles } from './FormConstructorItemStyles';

export interface FormConstructorItemProps {
  type: string;
  previewText: string;
  handleClick?: () => void;
}

export default function FormConstructorItem({ type, previewText, handleClick }: FormConstructorItemProps) {
  return (
    <FormConstructorItemStyles
      onClick={(event) => {
        if (handleClick) {
          event.preventDefault();
          event.stopPropagation();
          handleClick();
        }
      }}
    >
      <FieldIcon type={type} />

      <p>{previewText}</p>
    </FormConstructorItemStyles>
  );
}
