import styled from 'styled-components';

export const FormInfoStyles = styled.div`
  .elementsContainer {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    & > div {
      margin-right: 16px;
    }
  }

  .fieldLabelContainer {
    margin-bottom: 4px !important;
  }

  .fieldLabel {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    color: #000;
  }

  .customizedRadioItem {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border-radius: 50%;
  }

  .checkedItem {
    border-radius: 50%;
    background-color: #0e9285;
  }

  .customizedCheckboxItem {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
  }

  .formSection {
    padding: 20px 32px 0 16px;
    border-bottom: 1px solid #f0f0f0;
    box-sizing: border-box;

    h2 {
      margin-bottom: 17px;
    }
  }

  .addImageContainer {
    width: 100%;
    max-width: 458px;
    margin-bottom: 24px;

    h2 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 8px;

      .required {
        color: #ff0101;
      }
    }

    .iconContainer svg {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
  }

  .addImageWrapper {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 16px;
    margin-bottom: 24px;

    .imageContainer {
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      overflow: hidden;

      &.default {
        display: grid;
        place-items: center;
        background: #f0f0f0;
        border: 1px solid #e5e5e5;
        border-radius: 4px;
      }

      .selectedImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .uploadButtonFieldContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .description {
        font-size: 14px;
        line-height: 16px;
        color: #555;
      }

      .mainMascotSelectorContainer {
        display: flex;
        justify-content: space-between;

        .mainMascotSelectorWrapper {
          width: 90%;
        }
      }
    }
  }

  .imageOptionItem {
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 12px;
    align-items: center;

    .imageContainer {
      width: 100%;
      height: 48px;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    p {
      text-align: left;
    }
  }

  .imageOptions {
    max-height: 200px;
    overflow-y: scroll;

    .imageOption {
      height: 64px !important;
    }
  }

  .uploadedImageContainer {
    width: 70px;
    height: 50px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .commonInfo {
    label > span {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .checkboxWrapper {
      margin-top: 8px;

      .customizedCheckbox {
        margin-top: 0 !important;
      }
    }
  }

  .topSection {
    margin-bottom: 26px;
  }

  .topSection,
  .commonInfo {
    align-items: flex-start;
    padding: 24px 32px 0 16px;
    box-sizing: border-box;

    h2 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 8px;
    }

    /*.logoContainer {
      width: 132px;
      height: 132px;
      box-sizing: border-box;
      overflow: hidden;
      display: grid;
      place-items: center;
      
      &.default {
        border-radius: 8px;
        background-color: #eee;
      }

      .defaultImageContainer {
        width: 100%;
        height: 100%;
        background-color: #F0F0F0;
        border-radius: 66px;
        display: grid;
        place-items: center;
      }

      .selectedImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      };
    }*/

    .buttonsContainer {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;

      .selectLogoButtonContainer {
        .uploadError {
          font-size: 11px;
          line-height: 16px;
          color: #ff3333;
        }

        .uploadFileButtonContainer span {
          margin-left: 8px;
        }

        .description {
          margin-top: 8px;
          font-size: 12px;
          color: #979797;
        }
      }

      .selectLogoLocationContainer {
        margin-bottom: 24px;

        .locationButtons {
          display: grid;
          grid-template-columns: repeat(auto-fill, minmax(80px, 100px));
          grid-gap: 16px;
          align-items: center;

          .locationButton {
            width: 100px;
            height: 32px;
            background-color: #fff;
            border: 1px solid #0e9285;
            border-radius: 4px;
            outline: none;
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;

            font-size: 14px;
            color: #0e9285;
            transition: all 0.3s;

            &.active {
              color: #fff;
              background-color: #0e9285;
              transition: all 0.3s;
            }
          }
        }
      }
    }

    .selectBackgroundContainer {
      .options {
        max-height: 170px;
        overflow-y: scroll;
      }
    }
  }

  .languagesSection {
    padding: 24px 32px 24px 16px;
    box-sizing: border-box;

    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .languages {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(80px, 100px));
      grid-gap: 16px;
      align-items: center;

      .language {
        width: 100px;
        height: 32px;
        background-color: #fff;
        border: 1px solid #0e9285;
        border-radius: 4px;
        outline: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 14px;
        color: #0e9285;
        transition: all 0.3s;

        &.active {
          color: #fff;
          background-color: #0e9285;
          transition: all 0.3s;
        }
      }
    }
  }

  .extraFieldListContainer {
    //display: flex;

    .extraFieldContainer {
      width: 100%;
      max-width: 540px;
    }
  }

  .formExtraFields,
  .freeError {
    padding: 24px 32px 0 16px;
    box-sizing: border-box;
  }

  .icons svg {
    width: auto;
    height: 24px;
  }

  .sendButtonFieldContainer {
    width: 540px;
    padding: 24px 32px 0 16px;
  }

  .bottomButtonsContainer {
    width: 540px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 16px;
    padding: 24px 32px 24px 16px;

    & > div {
      width: 100%;

      a,
      button {
        width: 100%;
      }
    }
  }

  .horizontalLine {
    width: 100%;
    height: 1px;
    background-color: #f0f0f0;
    margin: 24px 0;
  }

  .previewButtonIconContainer {
    margin-right: 10px;
  }

  .modalContainer {
    background: #fff;
    color: #000000;
    border-radius: 4px;

    .modalHeader {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-right: 12px;
      box-sizing: border-box;

      h5 {
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .body {
      padding: 16px;
      border-bottom: 1px solid #e5e5e5;

      h6 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 20px;

      & > div,
      a {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
      }

      & > div {
        width: 100%;
        margin: 0;

        a,
        button {
          width: 100%;
        }
      }
    }
  }

  .startPageButtonWidth {
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 8px;
    }

    .optionsHorizontal {
      display: flex;

      & > div:not(:last-child) {
        margin-right: 24px;
      }
    }
  }

  .dividingDecorativeLineSettingsBlock {
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-top: 0;
      margin-bottom: 14px;
    }

    .dividingDecorativeLineWidthStyle {
      margin-bottom: 18px;
      span {
        display: inline-block;
        margin-bottom: 12px;
      }

      .optionsHorizontal {
        display: flex;
  
        & > div:not(:last-child) {
          margin-right: 24px;
        }
      }
    }

    .dividingDecorativeLineHeightStyle,
    .dividingDecorativeLineColorStyle {
      width: 100%;
      max-width: 260px;
    }
  }
`;
