import styled from 'styled-components';

export const MassMailingCreatingSmsStep3Styles = styled.div`
  form {
    padding: 60px 32px 32px 16px;
    .formFieldsContainer {
      margin-bottom: 75px;
      .senderAccountInputContainer {
        display: flex;
        align-items: center;

        .senderAccountName {
          font-weight: 700;
        }
        
        span {
          height: 40px;
        }
      }
      .textContainer {
        display: flex;
        white-space: pre-line;

        .messageText {
          width: 100%;
          max-width: 500px;
        }
      }
    }

    .mailsCountContainer {
      display: flex;
      margin-bottom: 30px;
      justify-content: center;
      width: 100%;
      max-width: 540px;
    
      span {
        font-weight: 700;
      }
    }

    .title {
      width: 100%;
      max-width: 180px;
    }

    .extraErrorMessage {
      color: #ff0000;
      margin-top: 10px;
    }
  }
`;
