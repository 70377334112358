import { DeepArrayItem } from '../entities/DeepArrayItem';

export function filterDeepObjectArray(array: DeepArrayItem[], search: string, selectKey: string) {
  const arrayCopy: DeepArrayItem[] = JSON.parse(JSON.stringify(array));

  return arrayCopy.filter((item) => {
    // @ts-ignore
    const found = item[selectKey].toLowerCase().includes(search.toLowerCase());

    if (item.children.length > 0) {
      item.children = filterDeepObjectArray(item.children, search, selectKey);
    }

    return found || item.children.length > 0;
  });
}

export function getNodesDeepArray(initialArray: {
  id: number,
  name: string,
  deep: number,
  selected?: boolean,
  hasSelectedChildren?: boolean,
  allChildrenAreSelected?: boolean,
}[]) {
  const nodesDeepArray: DeepArrayItem[] = [];

  function pushDeepNode(array: DeepArrayItem[], node: DeepArrayItem, deep: number) {
    if (deep === 0) {
      array.push(node);
    } else {
      pushDeepNode(array[array.length - 1].children, node, deep - 1);
    }
  }

  initialArray.forEach((node) => {
    const item: DeepArrayItem = {
      ...node,
      children: [],
      ...(node.selected !== undefined ? { selected: node.selected } : {}),
      ...(node.hasSelectedChildren !== undefined ? { hasSelectedChildren: node.hasSelectedChildren } : {}),
      ...(node.allChildrenAreSelected !== undefined ? { allChildrenAreSelected: node.allChildrenAreSelected } : {}),
    };

    pushDeepNode(nodesDeepArray, item, item.deep);
  });

  return nodesDeepArray;
}
