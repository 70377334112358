import styled from 'styled-components';

export const SocialNetworksModuleStyles = styled.footer`
  .options {
    display: flex;
    justify-content: space-around;
  }

  .checkboxFieldContainer {
    button {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background-color: #F2F2F2;
      display: grid;
      place-items: center;

      &.active {
        background-color: #c8e9ff;
      }

      svg {
        width: 20px;
        height: 20px;
      }
    }
  }
  
  .socialItem {
    height: 20px;
  }
  
  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
