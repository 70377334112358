import { ConnectionStyles } from './ConnectionStyles';
import CustomSelect from '../../../../components/form/customSelect/CustomSelect';
import { IExtraField, IFormDocument, IOption } from '../../../../entities/IExtraField';
import SelectWithAnyItems from '../../../../components/form/customSelect/SelectWithAnyItems';
import { FormConstructorItemStyles } from '../formConstructorItem/FormConstructorItemStyles';
import { useAppSelector } from '../../../../state';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { truncate } from '../../../../utils';
import { FieldIcon, TransparentButton } from '../../../../components';

interface ConnectionProps {
  name: string,
  fieldType: string,
  types: {key: string, text: string}[],
  cases: IOption[],
  fields: (IExtraField | IFormDocument)[],
  isOpen: boolean,
  handleOpen: (value: boolean) => void,
  handleDelete: () => void,
  setFieldValue: (key: string, value: any) => void,
  type?: string,
  selectedCase?: number,
  selectedField?: number,
  caseOptionAnswer?: string,
}

export default function Connection({
  name,
  fieldType,
  types,
  cases,
  fields,
  isOpen,
  handleDelete,
  handleOpen,
  setFieldValue,
  type,
  selectedCase,
  selectedField,
  caseOptionAnswer,
}: ConnectionProps) {
  function renderForm() {
    const { interfaceLanguage } = useAppSelector((state) => state.languages);

    return (
      <div>
        {['radio', 'scale'].includes(fieldType) && (
          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_statement_label')}
            name={`${name}.type`}
            selectKey="text"
            options={types}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_statement_placeholder')}
            value={types.find((item) => item.key === type)}
            handleSelect={(value) => setFieldValue(`${name}.type`, value.key)}
          />
        )}
        {(type === 'if') && cases && (
          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_case_label')}
            name={`${name}.selectedCase`}
            selectKey="value"
            options={cases}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_case_placeholder')}
            value={cases.find((item) => item.id === selectedCase)}
            handleSelect={(value) => setFieldValue(`${name}.selectedCase`, value.id)}
          />
        )}
        {fields && (
          <SelectWithAnyItems
            label={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_select_with_any_items_label')}
            name={`${name}.selectedField`}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_select_with_any_items_placeholder')}
            options={[{ id: 'default', question: getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_select_with_any_items_options_1_question') }, ...fields]}
            value={fields.find((item) => item.id === selectedField)}
            handleSelect={(option) => setFieldValue(`${name}.selectedField`, option.id === 'default' ? undefined : option.id)}
            formGroupStyles={{ width: '268', marginBottom: 0 }}
            renderItem={(option, index) => (
              <FormConstructorItemStyles
                style={option.id === 'default'
                  ? { gridTemplateColumns: '1fr', backgroundColor: '#f2f2f2', borderRadius: 0 }
                  : { borderRadius: 0, borderBottom: index === fields.length ? 'none' : '1px solid #eee' }}
              >
                {option.id !== 'default' && <FieldIcon type={option.type} />}

                <p>{option.question || option.description}</p>
              </FormConstructorItemStyles>
            )}
            renderSelect={(value) => (value ? (
              <FormConstructorItemStyles style={{ padding: 0 }}>
                <FieldIcon type={value.type} />

                <p>{value.question || value.description}</p>
              </FormConstructorItemStyles>
            ) : <div>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_select_field')}</div>)}
          />
        )}

        <div className="buttons">
          <TransparentButton handleClick={() => handleDelete()} text={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')} filled />
          <TransparentButton handleClick={() => handleOpen(false)} text={getTranslationByLangOrEng(interfaceLanguage, 'done_button')} />
        </div>
        {/* <span className="uploadError">{submitError}</span> */}
      </div>
    );
  }

  function renderMinifiedConnection() {
    const selectedType = types.find((item) => item.key === type)?.key;
    const field = fields.find((item) => item.id === selectedField);
    const nextFieldIsThankYouPageText = 'Thank you page';
    const optionAnswerValue = truncate(caseOptionAnswer || '', 6);

    const { interfaceLanguage } = useAppSelector((state) => state.languages);

    const typeTexts = {
      if: getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_render_minifield_connection_type_texts_if'),
      always: getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_render_minifield_connection_type_texts_always'),
    };

    return (
      <div className="closedConnection" onClick={() => handleOpen(true)}>
        <div className="leftPart">
          {/* @ts-ignore */}
          <span>{selectedType ? typeTexts[selectedType] : getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_render_minifield_connection_always')}</span>

          {selectedType === 'if' && <div className="case">{optionAnswerValue}</div>}
        </div>

        <div className="rightPart">
          <div className="goToText"><p>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_connection_go_to')}</p></div>

          <div className="typeIcon">
            {field ? <FieldIcon title={field?.question} type={field?.type} /> : (
              <p className="nextThankYouPageFieldText">{nextFieldIsThankYouPageText}</p>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <ConnectionStyles>
      <div className={isOpen ? 'connectionWrapper open' : 'connectionWrapper'}>
        {isOpen ? renderForm() : renderMinifiedConnection()}
      </div>
    </ConnectionStyles>
  );
}
