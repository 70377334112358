import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const CalendarIcon: FC<TIcon> = React.memo(({ width = 18, height = 20, color = '#000' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 18 20"
  >
    <path
      fill={color}
      d="M18 18V4c0-1.103-.897-2-2-2h-2V0h-2v2H6V0H4v2H2C.897 2 0 2.897 0 4v14c0 1.103.897 2 2 2h14c1.103 0 2-.897 2-2zM6 16H4v-2h2v2zm0-4H4v-2h2v2zm4 4H8v-2h2v2zm0-4H8v-2h2v2zm4 4h-2v-2h2v2zm0-4h-2v-2h2v2zm2-5H2V5h14v2z"
    />
  </svg>
));
