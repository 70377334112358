import styled from 'styled-components';

export const FieldsSelectStyles = styled.div`
  .header {
    height: 48px;
    padding: 0 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    
    button {
      svg {
        transform: rotate(45deg);
      }
    }
  }

  .itemsList {
    padding: 24px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
  }
`;
