import { AddIssueStyles } from './AddIssueStyles';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import {
  formInitialValues,
  formInitialValues2,
  FormValues,
  FormValues2,
  priorities,
  validationSchema,
  validationSchema2,
} from './formValuesAndValidation';
import {
  CompaniesMainInfo, DeepArrayItem,
  IFormListItem, IIssue, IIssueStatus,
  IIssueType, IOneReviewForm, IUser,
} from '../../entities';
import { useAppSelector } from '../../state';
import { Api } from '../../api';
import { useLocalTypes } from '../../hooks';
import {
  getErrorMessage, getNodeNamesArray,
  getNodesDeepArray, handleKeyUp,
} from '../../utils';
import { getTranslationByLangOrEng } from '../../i18n';
import InputField from '../../components/form/inputField/InputField';
import CustomSelect from '../../components/form/customSelect/CustomSelect';
import CustomSelectTiedNode from '../../components/form/customSelect/CustomSelectTiedNode';
import TextArea from '../../components/form/textArea/TextArea';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import {
  Loader, Modal, ModalConfirmDecline, SubHeader, TransparentButton,
} from '../../components';
import { JustPlusIcon } from '../../assets';
import { Helmet } from 'react-helmet-async';

export const AddIssue = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [initialValues, setInitialValues] = useState<FormValues>();
  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const [issueTypeOrStatusCreateLoading, setIssueTypeOrStatusCreateLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [pathToNavigate, setPathToNavigate] = useState<string>('');
  const [openedModalType, setOpenedModalType] = useState<string>();

  const { localTypes, getLocalTypes } = useLocalTypes();

  const statusesRef = useRef<IIssueStatus[]>([]);
  const typesRef = useRef<IIssueType[]>([]);
  const reviewsRef = useRef<(IOneReviewForm & {form: string, node: string, company: string}) []>([]);
  const issuesRef = useRef<IIssue[]>([]);
  const companiesRef = useRef<CompaniesMainInfo[]>([]);
  const nodeNamesRef = useRef<{ id: number, name: string, deep: number }[]>([]);
  const nodesRef = useRef<DeepArrayItem[]>([]);
  const formsRef = useRef<IFormListItem[]>([]);
  const formRef = useRef<FormikProps<FormValues>>(null);
  const form2Ref = useRef<FormikProps<FormValues2>>(null);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  async function getDataBySelectedNode(companyId: number, nodeId: number) {
    const reviews = await Api.getReviews({ companyID: companyId, node_id: [nodeId] });
    const forms = nodeId ? await Api.getFormsByNode(nodeId) : undefined;

    if (reviews.statusCode >= 200 && reviews.statusCode < 300 && forms) {
      formsRef.current = forms.data;

      reviewsRef.current = reviews.data.map((rewiew) => {
        const form = forms.data.find((form) => form.id === rewiew.formId);
        const node = nodeNamesRef.current.find((node) => node.id === nodeId);
        const company = companiesRef.current.find((company) => company.id === companyId);

        return {
          ...rewiew,
          form: form?.name || '_',
          node: node?.name || '_',
          company: company?.name || '_',
        };
      });
    }
  }

  async function getDataBySelectedReview(companyId: number, nodeId: number, reviewId: number) {
    const review = await Api.getReview(reviewId);

    if (review.statusCode >= 200 && review.statusCode < 300) {
      const form = formsRef.current.find((form) => form.id === review.data.formId);
      const node = nodeNamesRef.current.find((node) => node.id === nodeId);
      const company = companiesRef.current.find((company) => company.id === companyId);

      reviewsRef.current = [
        ...reviewsRef.current,
        {
          ...review.data,
          form: form?.name || '_',
          node: node?.name || '_',
          company: company?.name || '_',
        },
      ]
    }
  }

  async function getDataBySelectedCompany(companyId: number) {
    const statuses = await Api.getTicketStatusesByCompany(companyId);
    const types = await Api.getTicketTypesByCompany(companyId);
    const users = await Api.getUsers({ companyID: companyId });
    const issues = await Api.getTicketsByCompany(companyId);

    if (
      statuses.statusCode >= 200 && statuses.statusCode < 300
      && types.statusCode >= 200 && types.statusCode < 300
      && users.statusCode >= 200 && users.statusCode < 300
      && issues.statusCode >= 200 && issues.statusCode < 300
    ) {
      statusesRef.current = statuses.data;
      typesRef.current = types.data;
      getLocalTypes(types.data);
      issuesRef.current = issues.data;
    }

    // NODES
    const nodes = await Api.getCompanyNodes(companyId);
    if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
      const nodeNamesArray: { id: number, name: string, deep: number }[] = [];

      getNodeNamesArray(nodeNamesArray, nodes.data || []);

      nodeNamesRef.current = nodeNamesArray;
      nodesRef.current = getNodesDeepArray(nodeNamesArray);
    }
  }

  async function getExtraFormData(companyId: number | undefined, nodeId: number | undefined, reviewId: number | undefined, init?: boolean) {
    setLoadingDataByCompany(true);

    if (init) {
      if (companyId) {
        await getDataBySelectedCompany(companyId);
      }
      if (nodeId) {
        await getDataBySelectedNode(companyId!, nodeId);
      }
      if (!!nodeId && !!reviewId && reviewsRef.current.findIndex((review) => review.id === reviewId) === -1) {
        await getDataBySelectedReview(companyId!, nodeId, reviewId);
      }
    } else if (nodeId) {
      await getDataBySelectedNode(companyId!, nodeId);
    } else if (companyId) {
      await getDataBySelectedCompany(companyId);
    } else {
      statusesRef.current = [];
      typesRef.current = [];
      formsRef.current = [];
      reviewsRef.current = [];
      issuesRef.current = [];
      nodeNamesRef.current = [];
      nodesRef.current = [];
    }

    setLoadingDataByCompany(false);
  }

  async function getAllCompanies() {
    const companies = await Api.getCompanies();

    if (companies.statusCode >= 200 && companies.statusCode < 300) {
      companiesRef.current = companies.data;
    }
  }

  async function getIssueInfo(id: string) {
    const issue = await Api.getTicket(+id);

    if (issue.statusCode >= 200 && issue.statusCode < 300) {
      await getExtraFormData(issue.data.companyId, issue.data.nodeId, issue.data.reviewId, true);

      const typeParts = typesRef.current.find((item) => item.id === issue.data.typeId)?.name.split('.');

      setInitialValues({
        connectedIssue: issue.data.connectedIssueId,
        comment: issue.data.comment,
        company: issue.data.companyId,
        name: issue.data.name,
        node: issue.data.nodeId,
        priority: issue.data.priority,
        responsible: issue.data.responsible,
        review: issue.data.reviewId,
        status: issue.data.statusId,
        typeId: issue.data.typeId,
        type: typeParts?.[0],
        category: typeParts?.[1],
        subcategory: typeParts?.[2],
      });
    }
  }

  async function initializeData() {
    setLoading(true);

    try {
      await getAllCompanies();
      if (id) {
        await getIssueInfo(id);
      } else if (localStorage.getItem('reviewIssuesCreateNodeId') && localStorage.getItem('reviewIssuesCreateCompanyId') && localStorage.getItem('reviewIssuesCreateReviewId')) {
        await getExtraFormData(Number(localStorage.getItem('reviewIssuesCreateCompanyId')), Number(localStorage.getItem('reviewIssuesCreateNodeId')), Number(localStorage.getItem('reviewIssuesCreateReviewId')), true);
        setInitialValues({
          ...formInitialValues,
          company: Number(localStorage.getItem('reviewIssuesCreateCompanyId')),
          node: Number(localStorage.getItem('reviewIssuesCreateNodeId')),
          review: Number(localStorage.getItem('reviewIssuesCreateReviewId')),
        });

        localStorage.removeItem('reviewIssuesCreateNodeId');
        localStorage.removeItem('reviewIssuesCreateCompanyId');
        localStorage.removeItem('reviewIssuesCreateReviewId');
      } else {
        setInitialValues(formInitialValues);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    initializeData();
  }, []);

  const onSaveModalConfirm = useCallback((values: FormValues, type: 'update' | 'create') => {
    const data: IIssue = {
      typeId: values.typeId!,
      statusId: values.status!,
      name: values.name,
      reviewId: values.review,
      nodeId: values.node!,
      responsible: values.responsible,
      connectedIssueId: values.connectedIssue,
      priority: values.priority,
      comment: values.comment,
      companyId: values.company!,
      userId: undefined,
    };

    if (id) {
      Api.updateTicket(+id, data)
        .then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            navigate('/issues');
          } else {
            setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
          }
        });
    } else {
      Api.createTicket(values.company!, data)
        .then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            navigate('/issues');
          } else {
            setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
          }
        });
    }
  }, []);

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);

    setConfirmSaveModalOpen(true);
  }

  async function handleCreateIssueType(value: IIssueType) {
    const res = await Api.createTicketTypeByCompany(formRef.current!.values.company!, value);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      typesRef.current = [...typesRef.current, res.data];
    }
  }

  async function handleCreateIssueStatus(value: IIssueStatus) {
    const res = await Api.createTicketStatusByCompany(formRef.current!.values.company!, value);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      statusesRef.current = [...statusesRef.current, res.data];
    }
  }

  async function onSubmit2(
    values: FormValues2,
    { setSubmitting }: FormikHelpers<FormValues2>,
  ) {
    setSubmitting(false);

    setIssueTypeOrStatusCreateLoading(true);

    if (formRef.current) {
      try {
        if (openedModalType === 'type') {
          await handleCreateIssueType({ companyId: formRef.current.values.company!, name: values.value });
        }
        if (openedModalType === 'status') {
          await handleCreateIssueStatus({ companyId: formRef.current.values.company!, name: values.value });
        }
      } catch (e) {
        console.log('ERROR', e);
      } finally {
        setIssueTypeOrStatusCreateLoading(false);
        setOpenedModalType(undefined);
      }
    }
  }

  const renderForm2 = ({
    values,
    errors,
    setFieldValue,
  }: FormikProps<FormValues2>) => (
    <Form>
      <InputField
        extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
        name="value"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('value', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_name_placeholder')}
        value={values.value}
        error={typeof errorMessage === 'object' ? getErrorMessage('value', errorMessage) : undefined}
        label={openedModalType === 'type' ? getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_name_label') : getTranslationByLangOrEng(interfaceLanguage, 'issues_form_status_name_label')}
        required
      />

      <SubmitButton extraBlockStyles={{ maxWidth: 250, width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
    </Form>
  );

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    resetForm,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <InputField
          extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
          name="name"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_name_placeholder')}
          value={values.name}
          error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_name_label')}
          required
        />

        <div className="twoColumns">
          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_company_label')}
            name="company"
            selectKey="name"
            options={companiesRef.current}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_company_placeholder')}
            value={companiesRef.current.find((company) => company.id === values.company)}
            handleSelect={(value) => {
              if (values.company !== value?.id) {
                setFieldValue('company', value?.id);
                setFieldValue('node', undefined);
                getExtraFormData(value?.id, undefined, undefined);
              }
            }}
            search
            emptyOption
            required
          />

          <CustomSelectTiedNode
            label={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_node_label')}
            name="node"
            options={nodesRef.current}
            selectKey="name"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_node_placeholder')}
            value={nodeNamesRef.current.find((node) => node.id === values.node)}
            handleSelect={(value) => {
              setFieldValue('node', value?.id);
              getExtraFormData(values.company, value?.id, undefined);
            }}
            search
            expandable
            required
            isLoading={loadingDataByCompany}
            disabled={!values.company}
          />
        </div>

        <CustomSelect
          label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_status_label')}
          name="status"
          selectKey="name"
          options={statusesRef.current}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_status_placeholder')}
          value={statusesRef.current.find((status) => status.id === values.status)}
          handleSelect={(value) => setFieldValue('status', value.id)}
          formGroupStyles={{ width: '250px' }}
          required
          isLoading={loadingDataByCompany}
          disabled={!values.company}
          search
        />

        <div className="issueSelectContainer">
          <div className="typeSelectorsContainer">
            <CustomSelect
              label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_category_label')}
              name="type"
              selectKey="name"
              options={localTypes}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_placeholder')}
              value={localTypes.find((type) => type.name === values.type)}
              handleSelect={(value) => {
                setFieldValue('type', value.name);

                if (!value.categories || value.categories.length === 0) {
                  setFieldValue('typeId', typesRef.current.find((type) => type.name === value.name)!.id);
                }

                setFieldValue('subcategory', '');
                setFieldValue('category', '');
              }}
              formGroupStyles={{ width: '250px' }}
              required
              isLoading={loadingDataByCompany}
              disabled={!values.company}
              search
            />

            {values.type !== undefined && !!localTypes.find((item) => item.name === values.type)?.categories?.length && (
              <CustomSelect
                label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_subcategory_label')}
                name="category"
                selectKey="name"
                options={localTypes.find((item) => item.name === values.type)!.categories!}
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_placeholder')}
                value={localTypes.find((item) => item.name === values.type)!.categories!.find((category) => category.name === values.category)}
                handleSelect={(value) => {
                  setFieldValue('category', value.name);

                  if (!value.subcategories || value.subcategories.length === 0) {
                    setFieldValue('typeId', typesRef.current.find((type) => type.name === [values.type, value.name].join('.'))!.id);
                  }

                  setFieldValue('subcategory', '');
                }}
                formGroupStyles={{ width: '250px' }}
                required
                isLoading={loadingDataByCompany}
                disabled={!values.company}
                search
              />
            )}

            {values.type !== undefined
              && !!localTypes.find((item) => item.name === values.type)?.categories?.find((item) => item.name === values.category)?.subcategories?.length
              && (
                <CustomSelect
                  label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_type_label')}
                  name="subcategory"
                  selectKey="name"
                  options={localTypes.find((item) => item.name === values.type)!.categories!.find((item) => item.name === values.category)!.subcategories!}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_placeholder')}
                  value={
                    localTypes
                      .find((item) => item.name === values.type)!.categories!
                      .find((category) => category.name === values.category)!.subcategories!
                      .find((subcategory) => subcategory.name === values.subcategory)
                  }
                  handleSelect={(value) => {
                    setFieldValue('subcategory', value.name);
                    setFieldValue('typeId', typesRef.current.find((type) => type.name === [values.type, values.category, value.name].join('.'))!.id);
                  }}
                  formGroupStyles={{ width: '250px' }}
                  required
                  isLoading={loadingDataByCompany}
                  disabled={!values.company}
                  search
                />
              )}
          </div>
        </div>

        <CustomSelect
          label={getTranslationByLangOrEng(interfaceLanguage, 'task_form_priority_label')}
          name="priority"
          options={priorities}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_priority_placeholder')}
          value={values.priority}
          handleSelect={(value) => setFieldValue('priority', value)}
          formGroupStyles={{ width: '250px' }}
          required
        />

        <TextArea
          name="comment"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('comment', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'comment_placeholder')}
          value={values.comment}
          extraBlockStyles={{ marginBottom: 24, height: 120, maxWidth: 524 }}
        />

        <div className="reviewFieldRow">
          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_review_label')}
            name="review"
            selectKey="id"
            property={['company', 'node', 'form']}
            options={reviewsRef.current}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_review_placeholder')}
            value={reviewsRef.current.find((item) => item.id === values.review)}
            handleSelect={(value) => setFieldValue('review', value.id)}
            formGroupStyles={{ width: '524px' }}
            search
            isLoading={loadingDataByCompany}
            disabled={!values.node}
          />

          {values.company && values.review && (
            <div className="openReviewContain">
              <TransparentButton
                text={getTranslationByLangOrEng(interfaceLanguage, 'open_review_title')}
                isLink
                linkAddress={`/reviews/company/${values.company}/review/${values.review}`}
                targetIsBlank
              />
            </div>
          )}
        </div>
        <SubmitButton extraBlockStyles={{ maxWidth: 250, width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <AddIssueStyles>
      <Helmet>
        <title>{id ? `${id} ticket Voicer` : 'Creating a ticket Voicer'}</title>
      </Helmet>

      {!loading && initialValues && (
      <SubHeader
        title={id
          ? getTranslationByLangOrEng(interfaceLanguage, 'issues_sub_header_edit_issue_title')
          : getTranslationByLangOrEng(interfaceLanguage, 'issues_sub_header_create_task_title')}
      >
        <TransparentButton
          text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          isLink
          linkAddress="/issues"
          filled
        />
      </SubHeader>
      )}

      {!initialValues ? <Loader /> : (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage, localTypes)}
          enableReinitialize
        >
          {!loading && renderForm}
        </Formik>
      )}

      {confirmSaveModalOpen
        && (
          <ModalConfirmDecline
            onClose={() => setConfirmSaveModalOpen(false)}
            onConfirm={() => onSaveModalConfirm(formRef.current!.values, id ? 'update' : 'create')}
            onDecline={() => setConfirmSaveModalOpen(false)}
            confirmText={id ? getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button') : getTranslationByLangOrEng(interfaceLanguage, 'create_button')}
            declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
            title={id ? getTranslationByLangOrEng(interfaceLanguage, 'issues_save_modal_edit_title') : getTranslationByLangOrEng(interfaceLanguage, 'issues_save_modal_create_title')}
            error={!!dataSaveError}
            errorText={dataSaveError}
            firstButtonStyles={{ background: '#ff0000' }}
            secondButtonStyles={{ background: '#0E9285' }}
          />
        )}

      {pathToNavigate && (
        <Modal onClose={() => setPathToNavigate('')}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h5>Warning</h5>
              <button type="button" onClick={() => setPathToNavigate('')}>
                <JustPlusIcon color="#999" width={20} height={20} />
              </button>
            </div>
            <div className="body">
              <h6>{getTranslationByLangOrEng(interfaceLanguage, 'are_you_sure')}</h6>
              <p>{getTranslationByLangOrEng(interfaceLanguage, 'your_changes_lose')}</p>
            </div>
            <div className="footer">
              <TransparentButton handleClick={() => setPathToNavigate('')} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
              <TransparentButton isLink linkAddress={pathToNavigate} text={getTranslationByLangOrEng(interfaceLanguage, 'leave_the_page')} />
            </div>
          </div>
        </Modal>
      )}

      {openedModalType && (
        <Modal onClose={() => setOpenedModalType(undefined)} extraStyles={{ width: '100%', maxWidth: 500 }}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h5>{openedModalType === 'type' ? getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_type') : getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_status')}</h5>
              <button type="button" onClick={() => setOpenedModalType(undefined)} disabled={issueTypeOrStatusCreateLoading}>
                <JustPlusIcon color="#999" width={20} height={20} />
              </button>
            </div>
            <div className="body">
              <div className="modalTitleContainer">
                <h6 style={{ textAlign: 'left' }}>{openedModalType === 'type' ? getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_type_title') : getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_status_title')}</h6>
                <span>{`(${getTranslationByLangOrEng(interfaceLanguage, 'issues_form_must_be_unique')})`}</span>
              </div>

              <Formik
                innerRef={form2Ref}
                initialValues={formInitialValues2}
                onSubmit={onSubmit2}
                validationSchema={validationSchema2}
              >
                {renderForm2}
              </Formik>
            </div>
            <div className="footer right">
              <div />
              <TransparentButton disabled={issueTypeOrStatusCreateLoading} handleClick={() => setOpenedModalType(undefined)} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} />
            </div>
          </div>
        </Modal>
      )}
    </AddIssueStyles>
  );
}
