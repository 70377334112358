import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';

export const validationSchema = (lang: string) => Yup.object({
  clientId: Yup.string()
    .required(getTranslationByLangOrEng(lang, 'validation_required')),
});

export interface FormValues {
  clientId: string;
}

export const extraFieldsSettings = {
  id: {
    width: '10%',
  },
  sync: {
    justifyContent: 'center',
  },
}
