import styled from 'styled-components';

export const ColorPickerStyles = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 16px;
  border-radius: 8px;
  
  .colorPicker {
    margin-bottom: 12px;
  }
`;
