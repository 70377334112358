import FormConstructorItem from '../formConstructorItem/FormConstructorItem';
import React from 'react';
import { useAppDispatch, useAppSelector, setNotSavedDocuments } from '../../../../state';
import {
  DragDropContext, Draggable, DraggingStyle, Droppable, DropResult, NotDraggingStyle,
} from 'react-beautiful-dnd';

interface DraggableConstructorFormDocumentsProps {
  handleContentItemClick: (type: 'content' | 'documents', index?: number) => void;
  selectedFieldIndex: number | undefined;
  setSelectedFieldIndex: (value: number | undefined) => void;
}

export default function DraggableConstructorFormDocuments({ handleContentItemClick, setSelectedFieldIndex, selectedFieldIndex }: DraggableConstructorFormDocumentsProps) {
  const dispatch = useAppDispatch();

  const { notSavedDocuments } = useAppSelector((state) => state.notSavedForm);

  function onDragEnd(result: DropResult) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const documents = [...notSavedDocuments];
    const targetField = { ...notSavedDocuments[result.source.index] };

    documents.splice(result.source.index, 1);
    documents.splice(result.destination.index, 0, targetField);

    dispatch(setNotSavedDocuments(documents.map((doc, index) => ({ ...doc, position: `${index + 1}` }))));

    if (selectedFieldIndex) {
      if (selectedFieldIndex === result.source.index) {
        setSelectedFieldIndex(result.destination.index);
      } else if (selectedFieldIndex > result.source.index && selectedFieldIndex < result.destination.index) {
        setSelectedFieldIndex(selectedFieldIndex - 1);
      } else if (selectedFieldIndex < result.source.index && selectedFieldIndex > result.destination.index) {
        setSelectedFieldIndex(selectedFieldIndex + 1);
      }
    }
  }

  const getItemStyle = (isDragging: boolean, draggableStyle?: DraggingStyle | NotDraggingStyle | undefined) => ({
    boxShadow: isDragging ? '0 0 10px rgba(0, 0, 0, 0.15)' : 'none',
    ...draggableStyle,
  });

  return (
    <div>
      <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
        {(notSavedDocuments && notSavedDocuments.length > 0) && (
          <div className="formExtraFields">
            <Droppable droppableId="droppable">
              {(provided, snapshot) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="extraFieldsList"
                >
                  {notSavedDocuments.map((field, index) => (
                    <Draggable
                      key={`${field.type}:${field.id}`}
                      draggableId={`${field.type}:${field.id}`}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="extraFieldContainer"
                          key={`notSavedDocuments:${field.id}`}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <FormConstructorItem
                            type={field.type}
                            handleClick={() => handleContentItemClick('documents', index)}
                            previewText={field.description || ''}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        )}

        {/* {errors.extraFields && touched && typeof errors.extraFields === 'string' && ( */}
        {/*  <div className="formErrorContainer freeError"> */}
        {/*    <ErrorMessage name="extraFields" component={FormErrorMessage} /> */}
        {/*  </div> */}
        {/* )} */}

      </DragDropContext>
    </div>
  );
}
