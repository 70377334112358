import React, {
  useEffect, useRef, useState,
} from 'react';
import { FormsStyles } from './FormsStyles';
import { TransparentButton } from '../../../components/atoms/transparentButton/TransparentButton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../api';
import {
  ClosedLock, CopyIcon,
  CrossInCircle, DeleteIcon,
  QRCodeIcon, SearchIcon,
  ThreeDots,
} from '../../../assets';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { chartColors } from '../../../constants';
import {
  useAppDispatch, useAppSelector,
  setNodeNames,
  setNotSavedForm,
  setSelectedCompany,
  setSelectedCompanyLanguages,
  setSelectedNode,
} from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import {
  getErrorMessage, getNodeNamesArray, getNodesDeepArray, handleKeyUp,
} from '../../../utils';
import { QRCode } from 'react-qr-svg';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { extraFieldsSettings, fieldsValidation, ICopyForm } from './constructorFormFields';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import CustomSelectTiedNode from '../../../components/form/customSelect/CustomSelectTiedNode';
import InputField from '../../../components/form/inputField/InputField';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import { Helmet } from 'react-helmet-async';
import { getThankYouTemplate, getSendButtonTemplate } from '../formConsructor/constructorFormFields';
import { ICompany, INode, IFormListItem } from '../../../entities';
import {
  Loader, Modal, SubHeader, Table,
} from '../../../components';

function CustomUrlField({ url }: {url: string}) {
  return (
    <a className="customUrlField" href={url} target="_blank" rel="noreferrer">
      <div>
        {url}
      </div>
    </a>
  );
}

function CustomMenuField({
  formId, companyId, nodeId, handleDelete, handleCopy, allFeedbackForms, url,
}: {
  formId: number,
  companyId: number,
  nodeId: number,
  handleDelete: (id: number) => void,
  handleCopy: (form: IFormListItem) => void,
  allFeedbackForms: IFormListItem[],
  url: string,
}) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const {
    nodeNames, selectedNode: node, selectedCompany: company, selectedCompanyLanguages: languages,
  } = useAppSelector((state) => state.company);

  const [menuVisible, setMenuVisible] = useState<boolean>(false);
  const [modalDeleteVisible, setModalDeleteVisible] = useState<boolean>(false);
  const [modalCopyVisible, setModalCopyVisible] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);
  const [copyError, setCopyError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitError, setSubmitError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<ICopyForm>();
  const [isLoading, setIsLoading] = useState(false);

  const ref = useRef<any>();

  const downloadQRCode = () => {
    // @ts-ignore
    const svg = new XMLSerializer().serializeToString(document.getElementById('qrCode'));
    const encodedData = window.btoa(svg);

    // @ts-ignore
    const base64svg = `data:image/svg+xml;base64,${encodedData}`.replace('image/svg+xml', 'image/octet-stream');
    const aEl = document.createElement('a');
    aEl.href = base64svg;
    aEl.download = `${node!.name}_QR_Code.svg`;
    document.body.appendChild(aEl);
    aEl.click();
    document.body.removeChild(aEl);
  };

  useEffect(() => {
    if (!nodeNames?.length) {
      Api.getCompanyNodes(+companyId!)
        .then((res) => {
          const nodeNamesArray: {id: number, name: string, deep: number}[] = [];

          getNodeNamesArray(nodeNamesArray, res.data || []);
          dispatch(setNodeNames(nodeNamesArray));
        });
    }
  }, []);

  useEffect(() => {
    if (nodeNames?.length && node) {
      const languageId = allFeedbackForms.find((form) => form.id === formId)!.language_id;

      setInitialValues({
        nodesList: getNodesDeepArray(nodeNames),
        nodeId: node.id!,
        newFormName: allFeedbackForms.find((form) => form.id === formId)!.name,
        newFormLanguage: languages!.find((lang) => lang.id === languageId)!,
      });
    }
  }, [nodeNames, node]);

  useClickOutside(ref, () => {
    setMenuVisible(false);
  });

  async function saveNewForm(values: ICopyForm) {
    const targetForm = await Api.getFormById(formId);

    if (targetForm.statusCode >= 200 && targetForm.statusCode < 300) {
      if (values.nodeId === nodeId && values.newFormName === targetForm.data.name) {
        setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'form_name_error'));
        return;
      }
      if (values.nodeId === nodeId && values.newFormLanguage.id === allFeedbackForms.find((form) => form.id === formId)!.language_id) {
        setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'form_language_error'));
        return;
      }

      let currentNode: Partial<INode> | null = node;
      let currentCompany: Partial<ICompany> | null = company;

      if (!node) {
        const nodeRes = await Api.getNodeById(nodeId);

        if (nodeRes.statusCode >= 200 && nodeRes.statusCode < 300) {
          dispatch(setSelectedNode(nodeRes.data));
          currentNode = nodeRes.data;
        }
      }

      if (!company) {
        const companyRes = await Api.getCompanyById(companyId);

        if (companyRes.statusCode >= 200 && companyRes.statusCode < 300) {
          dispatch(setSelectedCompany(companyRes.data));
          currentCompany = companyRes.data;
        }
      }

      const newForm = {
        ...targetForm.data,
        name: values.newFormName,
        language_id: values.newFormLanguage.id!,
        companyID: +companyId!,
        node_id: +values.nodeId!,
        url: `https://${company!.domain}.voicer.software/reviews/${node!.slug}`,
      };

      const targetFormFeedback = await Api.createFeedbackForm(newForm);

      if (targetFormFeedback.statusCode >= 200 && targetFormFeedback.statusCode < 300) {
        if (nodeId === values.nodeId) {
          handleCopy({
            ...targetFormFeedback.data,
            url: `https://${currentCompany!.domain}.voicer.software/reviews/${currentNode!.slug}`,
          });
        }
      } else {
        setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'form_copy_save_error'));
        return;
      }

      const targetFormFields = await Api.getFormFields(formId);

      if (targetFormFields.statusCode >= 200 && targetFormFields.statusCode < 300) {
        const promises: Promise<any>[] = [];

        targetFormFields.data.extraFields?.forEach(async (field) => {
          promises.push(Api.createField({ ...field, formID: targetFormFeedback.data.id }));
        })

        targetFormFields.data.documents?.forEach(async (field) => {
          promises.push(Api.createFormDocument({ ...field, formID: targetFormFeedback.data.id }));
        })

        promises.push(Api.createThankYouPage(targetFormFields.data.thankYouPage ? { ...targetFormFields.data.thankYouPage, formID: targetFormFeedback.data.id } : getThankYouTemplate(interfaceLanguage, targetFormFeedback.data.id, '1').config));
        promises.push(Api.createFormSendButton(targetFormFields.data.sendButton ? { ...targetFormFields.data.sendButton, formID: targetFormFeedback.data.id } : getSendButtonTemplate(interfaceLanguage, targetFormFeedback.data.id, '2').config));

        await Promise.all(promises);
      }
    }
    setModalCopyVisible(false);
    setMenuVisible(false);
  }

  function onSubmit(
    values: ICopyForm,
    { setSubmitting }: FormikHelpers<ICopyForm>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    try {
      saveNewForm(values);
    } catch (e) {
      setCopyError(true);
    } finally {
      setIsLoading(false);
    }
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<ICopyForm>) {
    return (
      <Form>
        <div className="modalTitle"><p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_copy_button')}</p></div>
        <div className="modalButtonsWrap">
          {nodeNames && values.nodeId && (
            <CustomSelectTiedNode
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_node_to_copy')}
              name="nodeId"
              options={values.nodesList}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_form_placeholder')}
              value={nodeNames.find((node) => node.id === values.nodeId)}
              handleSelect={(node) => setFieldValue('nodeId', node.id)}
              formGroupStyles={{ width: '100%' }}
              search
              expandable
            />
          )}

          <InputField
            label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_new_form_name_title')}
            name="newFormName"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('newFormName', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_new_form_name_placeholder')}
            value={values.newFormName}
            // @ts-ignore*
            error={typeof errorMessage === 'object' ? getErrorMessage('newFormName', errorMessage) : undefined}
            extraBlockStyles={{ width: '100%' }}
            required
          />

          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_new_form_language_title')}
            name="newFormLanguage"
            options={languages || []}
            selectKey="name"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_new_form_language_placeholder')}
            value={values.newFormLanguage}
            handleSelect={(language) => setFieldValue('newFormLanguage', language)}
            formGroupStyles={{ width: '100%' }}
            search
          />

          <SubmitButton isLoading={isLoading} isError={touched && !!submitError}>{getTranslationByLangOrEng(interfaceLanguage, 'save_form_without_changes_button')}</SubmitButton>
          <div className="formErrorContainer">
            {submitError && <span>{submitError}</span>}
          </div>

          <button
            className="confirmButton"
            type="button"
            onClick={
              () => {
                try {
                  Api.getFormById(formId)
                    .then((res) => {
                      if (res.statusCode >= 200 && res.statusCode < 300) {
                        setModalCopyVisible(false);
                        dispatch(setNotSavedForm({ ...res.data, name: values.newFormName!, language_id: values.newFormLanguage.id! }));
                        navigate(`/companies/${companyId}/nodes/${values.nodeId!}/forms/create`);
                      }
                    });
                } catch (e) {
                  setCopyError(true);
                }
              }
            }
          >
            {getTranslationByLangOrEng(interfaceLanguage, 'change_new_form_button')}
          </button>
          <button type="button" onClick={() => setModalCopyVisible(false)} className="declineButton">
            {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          </button>

          {copyError && (
            <p className="modalError">
              {getTranslationByLangOrEng(interfaceLanguage, 'copy_error')}
            </p>
          )}
        </div>
      </Form>
    );
  }

  return (
    <div ref={ref} className="menu">
      <button onClick={() => setMenuVisible(!menuVisible)} type="button">
        <ThreeDots />
      </button>
      {menuVisible
        && (
          <ul className="menuList">
            <li>
              <button type="button" onClick={() => setModalCopyVisible(true)}>
                <CopyIcon />
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'copy_button')}</span>
              </button>
            </li>

            <li>
              <button type="button" onClick={() => setModalDeleteVisible(true)}>
                <DeleteIcon />
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}</span>
              </button>
            </li>

            <li>
              <button
                type="button"
                onClick={() => {
                  downloadQRCode();
                }}
              >
                <QRCodeIcon />
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_download_qr')}</span>
              </button>
            </li>

            <div className="HpQrcode" style={{ display: 'none' }}>
              <QRCode
                id="qrCode"
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="Q"
                value={url}
              />
            </div>

          </ul>
        )}

      {modalDeleteVisible && (
        <Modal onClose={() => setModalDeleteVisible(false)} extraStyles={{ width: 300 }}>
          <>
            <div className="modalTitle"><p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_delete_button')}</p></div>
            <div className="modalButtonsWrap">
              <p className="modalButtonsCaption">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_delete_confirm_text')}</p>

              <button type="button" onClick={() => setModalDeleteVisible(false)} className="declineButton">
                {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
              </button>
              <button
                className="deleteButton"
                type="button"
                onClick={
                    () => {
                      Api.deleteFeedbackForm(formId)
                        .then((res) => {
                          if (res.statusCode === 200) {
                            handleDelete(formId);
                            setModalDeleteVisible(false);
                            setMenuVisible(false);
                          } else {
                            setDeleteError(true);
                          }
                        });
                    }
                  }
              >
                {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
              </button>

              {deleteError && <p className="modalError">{getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}</p>}
            </div>
          </>
        </Modal>
      )}

      {modalCopyVisible && (
        <Modal onClose={() => setModalCopyVisible(false)} extraStyles={{ width: 300 }}>
          {!initialValues ? <Loader /> : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={() => fieldsValidation(interfaceLanguage)}
            >
              {renderForm}
            </Formik>
          )}
        </Modal>
      )}
    </div>
  );
}

function CustomStatusField({ lang, status }: {lang: string, status: boolean}) {
  return (
    <div className="statusWrapper">
      {status
        ? <span>{getTranslationByLangOrEng(lang, 'companies_form_add_status_published')}</span>
        : (
          <>
            <div>
              <ClosedLock />
            </div>
            <span>{getTranslationByLangOrEng(lang, 'companies_form_add_status_not_published')}</span>
          </>
        )}
    </div>
  );
}

function CustomEditLinks({
  lang, companyId, nodeId, formId,
}: {lang: string, companyId: number, nodeId: number, formId: number}) {
  return (
    <div className="editLinks">
      <Link className="customEditLink" to={`/companies/${companyId}/nodes/${nodeId}/forms/${formId}/edit`}>
        <div>
          {getTranslationByLangOrEng(lang, 'companies_form_table_edit_button')}
        </div>
      </Link>
      <Link className="customConstructorField" to={`/companies/${companyId}/nodes/${nodeId}/forms/${formId}/constructor`}>
        <div>
          {getTranslationByLangOrEng(lang, 'companies_form_table_constructor_button')}
        </div>
      </Link>
    </div>
  );
}

function CustomNameField({ name, index }: {name: string, index: number}) {
  let color: string;
  if (index + 1 > chartColors.length) {
    const moreTimes = Math.floor((index + 1) / chartColors.length);

    color = chartColors[index + 1 - (chartColors.length * moreTimes)];
  }
  return (
    <div className="nameWrapper" style={{ background: index + 1 > chartColors.length ? `${color!}30` : `${chartColors[index]}30` }}>
      <span>{name}</span>
    </div>
  );
}

export default function Forms() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { companyId, nodeId } = useParams();

  const { selectedCompany: company, selectedCompanyLanguages, selectedNode: node } = useAppSelector((state) => state.company);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [searchParams, setSearchParams] = useState<string>('');
  const [feedbackForms, setFeedbackForms] = useState<IFormListItem[]>([]);
  const [filteredFeedbackFormsChanged, setFilteredFeedbackFormsChanged] = useState<boolean>(false);
  const [companyAlias, setCompanyAlias] = useState<string>('');
  const [nodeSlug, setNodeSlug] = useState<string>('');
  const [formsDeleted, setFormsDeleted] = useState<{[key: number]: boolean}>({});
  const [loading, setLoading] = useState<boolean>(false);

  const formsDeletedRef = useRef<{[key: number]: boolean}>({});
  const feedbackFormsRef = useRef<IFormListItem[]>([]);
  const filteredFeedbackFormsRef = useRef<IFormListItem[]>([]);

  async function getData() {
    if (nodeId && companyId) {
      if (company && company.id === +companyId) {
        setCompanyAlias(company.domain!);

        if (!selectedCompanyLanguages) {
          const targetForm = await Api.getCompanyLanguages(+companyId!);

          if (targetForm.statusCode >= 200 && targetForm.statusCode < 300) {
            dispatch(setSelectedCompanyLanguages(targetForm.data));
          }
        }
      } else {
        const targetFormFeedback = await Api.getCompanyById(+companyId);

        if (targetFormFeedback.statusCode >= 200 && targetFormFeedback.statusCode < 300) {
          setCompanyAlias(targetFormFeedback.data.domain!);
          dispatch(setSelectedCompany(targetFormFeedback.data));
        }

        const targetFormFields = await Api.getCompanyLanguages(+companyId!);

        if (targetFormFields.statusCode >= 200 && targetFormFields.statusCode < 300) {
          dispatch(setSelectedCompanyLanguages(targetFormFields.data));
        }
      }

      if (node && node.id === +nodeId) {
        setNodeSlug(node.slug!);
      } else {
        const targetFormPartially = await Api.getNodeByIdPartially(+nodeId);

        if (targetFormPartially.statusCode >= 200 && targetFormPartially.statusCode < 300) {
          setNodeSlug(targetFormPartially.data.slug!);
          dispatch(setSelectedNode(targetFormPartially.data));
        }
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    dispatch(setNotSavedForm(undefined));

    getData().finally(() => setLoading(false));
  }, [nodeId, companyId]);

  useEffect(() => {
    if (companyAlias && !loading) {
      Api.getNodeFeedbackForms(+nodeId!)
        .then((res) => {
          if (res.statusCode === 200) {
            const data = [...res.data].map((item) => ({
              ...item,
              url: nodeSlug ? `https://${companyAlias}.voicer.software/reviews/${nodeSlug}` : '',
            }));

            feedbackFormsRef.current = data;
            setFeedbackForms(data);
          } else {
            feedbackFormsRef.current = [];
            setFeedbackForms([]);
          }
        });
    }
  }, [companyAlias, loading]);

  useEffect(() => {
    filteredFeedbackFormsRef.current = feedbackFormsRef.current.filter((form) => form.name.toLowerCase().includes(searchParams.toLowerCase()));
    setFilteredFeedbackFormsChanged(!filteredFeedbackFormsChanged);
  }, [searchParams]);

  const handleDeleteRow = React.useCallback((id: number) => {
    if (formsDeletedRef.current) {
      formsDeletedRef.current = { ...formsDeletedRef.current, [id]: true };
      setFormsDeleted({ ...formsDeletedRef.current, [id]: true });

      if (feedbackFormsRef.current && feedbackForms) {
        feedbackFormsRef.current = [...feedbackFormsRef.current.filter((item) => item.id !== id)];
        setFeedbackForms([...feedbackForms.filter((item) => item.id !== id)]);
      }
    }
  }, []);

  const handleCopyRow = React.useCallback((newForm: IFormListItem) => {
    feedbackFormsRef.current = [...feedbackFormsRef.current, newForm];
    setFeedbackForms([...feedbackForms, newForm]);
  }, []);

  const EditButtonsCell = React.useCallback((data: any) => {
    if (!formsDeletedRef.current?.[data.row.original.id] && data.row.original) {
      return (
        <CustomMenuField
          formId={data.row.original.id}
          companyId={data.row.original.companyID}
          nodeId={data.row.original.node_id}
          handleDelete={handleDeleteRow}
          handleCopy={handleCopyRow}
          allFeedbackForms={feedbackFormsRef.current || []}
          url={data.row.original.url}
        />
      );
    }
    return null;
  }, [nodeId]);

  const companiesTableHeaders = React.useMemo(() => [
    {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_header_name'),
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <CustomNameField name={data.row.original.name} index={data.row.index} />
      ),
    },
    {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_header_link'),
      accessor: 'url',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <CustomUrlField url={data.row.original.url} />
      ),
    },
    {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_header_status'),
      accessor: 'status',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <CustomStatusField lang={interfaceLanguage} status={data.row.original.status} />
      ),
    },
    {
      Header: 'menu',
      accessor: 'menu',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: EditButtonsCell,
    },
    {
      Header: 'Custom edit link',
      accessor: 'customEditLink',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <CustomEditLinks lang={interfaceLanguage} companyId={+companyId!} nodeId={+nodeId!} formId={data.row.original.id} />
      ),
    },
  ], [interfaceLanguage]);

  return (
    <FormsStyles>
      <Helmet>
        <title>{`${company?.name || 'Company'} node forms Voicer`}</title>
      </Helmet>

      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_title')}
        pathArray={[
          ...(company ? [{ name: company.name!, route: `/companies/${companyId}/nodes` }] : []),
          ...(node ? [{ name: node.name! }] : []),
        ]}
      >
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate(`/companies/${companyId}/nodes/${nodeId}/forms/create`)} filled>
            <div>
              <CrossInCircle />
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_table_add_form_button')}</span>
            </div>
          </TransparentButton>
          <TransparentButton handleClick={() => navigate(`/companies/${companyId}/nodes`)} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      <div className="searchWrapper">
        <SearchIcon />
        <input
          className="searchField"
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')}
          type="text"
          value={searchParams}
          onChange={(e) => setSearchParams(e.target.value)}
        />
      </div>
      {loading ? <Loader /> : (
        <Table
          pagination
          columns={companiesTableHeaders}
          data={searchParams ? filteredFeedbackFormsRef.current : feedbackFormsRef.current}
          hideFieldsSplitters
          extraFieldsSettings={extraFieldsSettings}
          hiddenHeaders={['customEditLink', 'editButtons', 'menu']}
          deletedRows={formsDeletedRef.current}
          handleDeleteRow={handleDeleteRow}
        />
      )}
    </FormsStyles>
  );
}
