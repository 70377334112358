import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';
import { ILanguage, DeepArrayItem } from '../../../entities';

export interface ICopyForm {
  nodesList: DeepArrayItem[],
  nodeId: number,
  newFormName: string,
  newFormLanguage: ILanguage,
}

export const fieldsValidation = (lang: string) => Yup.object({
  nodeId: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
  newFormName: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  newFormLanguage: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
});

export const extraFieldsSettings = {
  url: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  updateAt: {
    textColor: '#979797',
  },
  status: {
    alignItems: 'center',
    justifyContent: 'center',
  },
  feedbacks: {
    alignItems: 'center',
    justifyContent: 'center',
  },
};
