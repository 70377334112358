import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../../i18n';

export interface FormValues {
    mailingType: 'byCompany' | 'addressMailing';
    connectionChannel: { name: string, key: number } | undefined;
    recipients: { recipient: string, id: string }[];
    contactsFile: File | string | undefined;
}

export const validationSchema = (lang: string) => Yup.object({
  mailingType: Yup.string(),
  // Common
  connectionChannel: Yup.object().shape({
    name: Yup.string(),
  }).test({
    name: 'selected',
    message: getTranslationByLangOrEng(lang, 'validation_required'),
    test: (val) => !!val.name,
  }),
  recipients: Yup.array().min(1, getTranslationByLangOrEng(lang, 'add_at_least_one_recipient_error')),

  // // Address mailing
  // splitter: Yup.string().when(['mailingType'], {
  //   is: (mailingType: 'byCompany' | 'addressMailing') => mailingType === 'addressMailing',
  //   then: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  //   otherwise: Yup.string().nullable(),
  // }),
});
