import React from 'react'
import { typeColors, typeIcons } from '../../../pages/companies/formConsructor/itemTypes';
import { FieldIconStyles } from './FieldIconStyles';

interface FieldIconProps {
  type: string;
  title?: string;
}

export const FieldIcon = React.memo(({ type, title }: FieldIconProps) => (
  <FieldIconStyles title={title} backgroundColor={typeColors[type]}>
    {typeIcons[type]}
  </FieldIconStyles>
))
