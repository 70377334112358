import { Api } from '../api';
import { setLanguages } from '../state/slices/languageSlice';
import { ThunkDispatch } from '@reduxjs/toolkit';

export async function getLanguages(dispatch: ThunkDispatch<any, any, any>) {
  const response = await Api.getLanguages();

  if (response.statusCode >= 200 && response.statusCode < 300) {
    dispatch(setLanguages(response.data));
  }
}
