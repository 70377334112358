import {
  useContext, useEffect, useRef, useState,
} from 'react';
import { FormConstructorStyles } from './FormConstructorStyles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../api';
import {
  IExtraField, IFormDocument,
  ICompanyDocument, IColor,
  ILanguage,
} from '../../../entities';
import { getSendButtonTemplate, getThankYouTemplate } from './constructorFormFields';
import {
  useAppDispatch,
  useAppSelector,
  setNotSavedScaleSettings,
  setNotSavedColors,
  setNotSavedDocuments,
  setNotSavedExtraFields,
  setNotSavedForm,
  setNotSavedSendButton,
  setNotSavedThankYouPage,
  setSelectedCompany,
  setSelectedNode,
} from '../../../state';
import { arrayUniqueByKey } from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import {
  Edge, ReactFlowInstance, useEdgesState, useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';
import FieldsForm from './fieldsForm/FieldsForm';
import DiagramForm from './diagramForm/DiagramForm';
import { Helmet } from 'react-helmet-async';
import {
  LeavePageModal, RefContext, SubHeader, TransparentButton,
} from '../../../components';

function getTabTexts(lang: string) {
  return [
    {
      key: 'fields',
      text: getTranslationByLangOrEng(lang, 'companies_form_constructor_tab_fields'),
    },
    {
      key: 'diagram',
      text: getTranslationByLangOrEng(lang, 'companies_form_constructor_tab_diagram'),
    },
  ];
}

export default function FormConstructor() {
  const { companyId, nodeId, formId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { selectedCompany: company, selectedNode: node } = useAppSelector((state) => state.company);
  const {
    notSavedForm, notSavedColors, notSavedExtraFields, notSavedDocuments, notSavedThankYouPage,
  } = useAppSelector((state) => state.notSavedForm);
  const { notSavedScaleSettings } = useAppSelector((state) => state.scaleSettings);

  const [formName, setFormName] = useState('');
  const [companyLanguages, setCompanyLanguages] = useState<ILanguage[]>();
  const [companyImages, setCompanyImages] = useState<{ name: string, url: string }[]>();
  const [companyColors, setCompanyColors] = useState<{ name: string, color: string }[]>();
  const [companyDocuments, setCompanyDocuments] = useState<ICompanyDocument[]>();
  const [selectedSectionType, setSelectedSectionType] = useState<string>();
  // const [selectedFieldType, setSelectedFieldType] = useState<string>();
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<number>();
  const [showFormSection, setShowFormSection] = useState<string>();
  const [selectedTab, setSelectedTab] = useState<string>('fields');
  const [showAddFieldList, setShowAddFieldList] = useState<boolean>(false);
  const [documentIds, setDocumentIds] = useState<number[]>([]);
  const [fieldIds, setFieldIds] = useState<number[]>([]);
  const [fieldsListChanged, setFieldsListChanged] = useState<boolean>(false);
  const [pathToNavigate, setPathToNavigate] = useState<string>('');
  const [diagramInitialized, setDiagramInitialized] = useState<boolean>(false);
  const [formDataInitialized, setFormDataInitialized] = useState<boolean>(false);

  const thankYouPageNode = {
    id: `${notSavedThankYouPage?.id}`,
    type: 'default',
    data: {
      label: 'Thank you page',
    },
    draggable: true,
    selectable: true,
    position: { x: -400, y: 1000 },
  };

  // Diagram start
  const reactFlowWrapper = useRef(null);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState<ReactFlowInstance<any, any>>();
  // Diagram end

  const commonDataLoadingRef = useRef<boolean>(false);
  const formLoadingRef = useRef<boolean>(false);
  // const constructorFormRef = useRef<FormikProps<IForm>>(null);
  const fieldsFormRef = useRef<{ getAlert:() => void }>(null);
  const diagramFormRef = useRef<{ getAlert:() => void }>(null);
  // @ts-ignore
  const { setRefs } = useContext(RefContext);

  async function getCurrentNodeData(id: string) {
    await Api.getNodeById(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          dispatch(setSelectedNode(res.data));
        }
      });
  }

  async function getCommonData(id: string) {
    await Api.getCompanyById(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          dispatch(setSelectedCompany(res.data));
        }
      });

    await Api.getCompanyLanguages(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyLanguages(res.data);
        }
      });

    await Api.getCompanyMetadata(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyImages(res.data.filter((item) => item.key.startsWith('image_')).map((item) => {
            const imageParts = item.value.split('|||');
            return { name: imageParts[0], url: imageParts[1] };
          }));
          setCompanyColors(res.data.filter((item) => item.key.startsWith('color_')).map((item) => {
            const colorParts = item.value.split('|||');
            return { name: colorParts[0], color: colorParts[1] };
          }));
        }
      });

    await Api.getCompanyDocuments(+id!)
      .then((res) => {
        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyDocuments(res.data);
        }
      });
  }

  async function initializeForm() {
    if (!companyColors) {
      await getCommonData(`${companyId}`);
    }

    if (companyColors) {
      const pathnameParts = pathname.split('/');

      if (notSavedForm && (pathnameParts[pathnameParts.length - 1] === 'create' || (formId && notSavedForm.id === +formId))) {
        const colorsList = [...companyColors, ...(notSavedColors || [])];
        let extraFields: IExtraField[] = notSavedExtraFields!;

        if (notSavedScaleSettings) {
          extraFields = [
            ...notSavedExtraFields!.slice(0, notSavedScaleSettings.index),
            {
              ...notSavedExtraFields![notSavedScaleSettings.index],
              ...notSavedScaleSettings.settings,
            },
            ...notSavedExtraFields!.slice(notSavedScaleSettings.index + 1),
          ];

          dispatch(setNotSavedScaleSettings(undefined));
        }

        setFormName(notSavedForm.name || '');

        dispatch(setNotSavedForm({
          ...notSavedForm,
          companyID: +companyId!,
          node_id: +nodeId!,
        }));
        dispatch(setNotSavedExtraFields(extraFields));
        dispatch(setNotSavedColors(arrayUniqueByKey<IColor>('name', notSavedForm.backgroundColor ? [...colorsList, notSavedForm.backgroundColor] : colorsList)));

        setFieldIds(extraFields.map((item) => item.id!));
        setDocumentIds(notSavedDocuments!.map((item) => item.id!));
      } else if (companyId && formId && !formLoadingRef.current) {
        formLoadingRef.current = true;

        const res = await Api.getFormById(+formId);

        if (res.statusCode === 200) {
          setFormName(res.data.name || '');

          dispatch(setNotSavedForm({
            ...res.data,
            companyID: +companyId!,
            node_id: +nodeId!,
          }));
          dispatch(setNotSavedColors(arrayUniqueByKey<IColor>('name', res.data.backgroundColor ? [...companyColors, res.data.backgroundColor] : companyColors)));

          const res2 = await Api.getFormFields(+formId);

          if (res2.statusCode === 200) {
            dispatch(setNotSavedSendButton(res2.data.sendButton));
            dispatch(setNotSavedDocuments(res2.data.documents || []));
            dispatch(setNotSavedThankYouPage(res2.data.thankYouPage));
            dispatch(setNotSavedExtraFields(res2.data.extraFields || []));

            setFieldIds(res2.data.extraFields!.map((item) => item.id!));
            setDocumentIds(res2.data.documents!.map((item) => item.id!));
          }
        }

        formLoadingRef.current = false;
      } else if (!formLoadingRef.current && !commonDataLoadingRef.current) {
        if (!companyLanguages) {
          await Api.getCompanyLanguages(+companyId!)
            .then((res) => {
              if (res.statusCode >= 200 && res.statusCode < 300) {
                setCompanyLanguages(res.data);
                dispatch(setNotSavedForm({
                  logo: undefined,
                  font: undefined,
                  logoLocation: 'left',
                  name: '',
                  backgroundColor: undefined,
                  elementsColor: undefined,
                  mainMascot: undefined,
                  anonymous: false,
                  isDefault: false,
                  status: false,
                  language_id: res.data[0].id!,
                  companyID: +companyId!,
                  node_id: +nodeId!,
                  withSteps: false,
                  showNodeCode: true,
                  startPageButtonPercentWidth: '100',
                  showDividingDecorativeLine: false,
                  dividingDecorativeLinePercentWidth: '90',
                }));
              }
            });
        } else {
          dispatch(setNotSavedForm({
            logo: undefined,
            font: undefined,
            logoLocation: 'left',
            name: '',
            backgroundColor: undefined,
            elementsColor: undefined,
            mainMascot: undefined,
            anonymous: false,
            isDefault: false,
            status: false,
            language_id: companyLanguages[0].id!,
            companyID: +companyId!,
            node_id: +nodeId!,
            withSteps: false,
            showNodeCode: true,
            startPageButtonPercentWidth: '100',
            showDividingDecorativeLine: false,
            dividingDecorativeLinePercentWidth: '90',
          }));
        }

        dispatch(setNotSavedSendButton(getSendButtonTemplate(interfaceLanguage, +formId!, '2').config));
        dispatch(setNotSavedDocuments([]));
        dispatch(setNotSavedThankYouPage(getThankYouTemplate(interfaceLanguage, +formId!, '1').config));
        dispatch(setNotSavedExtraFields([]));
        dispatch(setNotSavedColors(arrayUniqueByKey<IColor>('name', companyColors)));
      }
    }
  }

  // Unmount form
  useEffect(() => () => {
    dispatch(setNotSavedForm(undefined));
    dispatch(setNotSavedSendButton(undefined));
    dispatch(setNotSavedDocuments([]));
    dispatch(setNotSavedThankYouPage(undefined));
    dispatch(setNotSavedExtraFields([]));
    dispatch(setNotSavedColors([]));
  }, []);

  // Fill diagram existed data
  useEffect(() => {
    if (formDataInitialized && notSavedExtraFields.length && !diagramInitialized) {
      const placedNodes: (IExtraField | IFormDocument)[] = ([...notSavedExtraFields, ...notSavedDocuments]).filter((item) => item.coordinates && item.fieldID);

      const nodesList = placedNodes.map((item) => ({
        id: `${item.id!}`,
        type: 'custom',
        position: item.coordinates!,
        data: { label: `${item.type} node`, type: item.type, question: item.type === 'document' ? item.description : item.question },
      }));

      const edgesMainList = placedNodes.filter((item) => item.fieldID).map((item) => ({
        id: `edge-${item.id!}-${item.fieldID!}`,
        type: 'custom',
        source: `${item.id!}`,
        target: `${item.fieldID!}`,
        sourceHandle: null,
        targetHandle: null,
      }));

      const edgesSecondaryList: Edge[] = [];

      placedNodes.forEach((item) => {
        if (item.type !== 'document') {
          item.answers?.forEach((answer) => {
            if (answer.nextField) {
              edgesSecondaryList.push({
                id: `edge-${item.id!}-${answer.nextField!}`,
                type: 'custom',
                source: `${item.id!}`,
                target: `${answer.nextField!}`,
                sourceHandle: null,
                targetHandle: null,
              });
            }
          });
        }
      });

      setNodes((prev) => ([...prev, ...nodesList, thankYouPageNode]));
      setEdges((prev) => ([...prev, ...edgesMainList, ...edgesSecondaryList]));
      setDiagramInitialized(true);
    }
  }, [formDataInitialized, notSavedExtraFields.length, diagramInitialized]);

  useEffect(() => {
    if (reactFlowWrapper && diagramFormRef) {
      setRefs({ reactFlowWrapper, diagramFormRef });
    }
  }, [reactFlowWrapper, diagramFormRef]);

  useEffect(() => {
    if (!company) {
      commonDataLoadingRef.current = true;
      getCommonData(companyId!).finally(() => {
        commonDataLoadingRef.current = false;
      });
    }
  }, [companyId]);

  useEffect(() => {
    commonDataLoadingRef.current = true;
    getCurrentNodeData(nodeId!).finally(() => {
      commonDataLoadingRef.current = false;
    });
  }, [nodeId]);

  useEffect(() => {
    if (!companyColors) {
      if (!commonDataLoadingRef.current) {
        commonDataLoadingRef.current = true;
        getCommonData(companyId!).finally(() => {
          commonDataLoadingRef.current = false;
        });
      }
    }
  }, [companyId, formId]);

  useEffect(() => {
    initializeForm().then(() => setFormDataInitialized(true));
  }, [companyId, formId, companyColors]);

  function handleCancel() {
    navigate(`/companies/${companyId}/nodes/${nodeId}/forms`);
  }

  async function onSubmitForm() {
    const fields = [...notSavedExtraFields];
    const documents = [...notSavedDocuments];

    if (diagramFormRef.current) {
      const fieldIds = fields.map((item) => item.id);
      const documentIds = documents.map((item) => item.id);

      nodes.forEach((node) => {
        const fieldIndex = fieldIds.indexOf(+node.id);
        const documentIndex = documentIds.indexOf(+node.id);

        if (fieldIndex !== -1) {
          fields[fieldIndex] = { ...fields[fieldIndex], coordinates: node.position };
        }

        if (documentIndex !== -1) {
          documents[documentIndex] = { ...documents[documentIndex], coordinates: node.position };
        }
      });
    }

    const nextFieldIds = notSavedExtraFields.filter((item) => item.fieldID && item.fieldID !== notSavedThankYouPage?.id).map((item) => item.fieldID);
    const answersNextFieldIds = notSavedExtraFields.filter((item) => item.type === 'radio' || item.type.includes('scale'))
      .flatMap((item) => item.answers?.map((item) => item.nextField));
    const allNextFieldIds = [...nextFieldIds, ...answersNextFieldIds];

    const notRelatedAnotherExtraFieldIds = notSavedExtraFields
      .filter((item) => !allNextFieldIds.includes(item.id) && item.position !== '1' && item.type !== 'title')
      ?.map((item) => item.id);

    const formFields1 = fields
      .map((item) => ((notRelatedAnotherExtraFieldIds.length > 0 && notRelatedAnotherExtraFieldIds.includes(item.id))
        ? { ...item, fieldID: undefined } : { ...item, fieldID: !item.fieldID && item.type !== 'title' ? notSavedThankYouPage?.id : item.fieldID }));
    const formFields2 = formFields1.map((item) => (item.type === 'title' ? { ...item, nodeAddress: node?.address?.value } : item));
    const firstFieldID = formFields2.find((item) => item.type === 'title' && item.fieldID) ? formFields2.find((item) => item.type === 'title')?.id : fields[0].id;

    await Promise.all(formFields2.map((field, index) => Api.updateField({ ...field, position: `${index + 1}`, fieldID: notSavedForm?.withSteps ? field.fieldID : undefined })));
    await Promise.all(documents.map((doc, index) => Api.updateFormDocument({ ...doc, position: `${index + 1}` })));
    await Api.editCompanyForm(notSavedForm?.withSteps ? { ...notSavedForm!, fieldID: firstFieldID } : notSavedForm!);
    handleCancel();
  }

  function renderPageContent() {
    return (
      <div className={selectedTab === 'fields' ? 'fieldsListWrapper' : 'fieldsListWrapper hideContent'}>
        <div className="selectedFieldWrapper">
          <div className="tabsContainer">
            <div className="tabs">
              {(notSavedForm?.withSteps
                ? getTabTexts(interfaceLanguage)
                : getTabTexts(interfaceLanguage).filter((tab) => tab.key !== 'diagram')
              ).map((tab) => (
                <button
                  className={selectedTab === tab.key ? 'active' : ''}
                  key={tab.key}
                  type="button"
                  onClick={() => setSelectedTab(tab.key)}
                >
                  {tab.text}
                </button>
              ))}
            </div>

            <div className="saveFormButtonContainer">
              <TransparentButton handleClick={onSubmitForm} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_submit_button')} filled />
            </div>
          </div>

          <div className="pageContentWrapper">
            {notSavedForm && companyDocuments && companyImages && (
              <FieldsForm
                ref={fieldsFormRef}
                companyDocuments={companyDocuments}
                companyImages={companyImages}
                showContentList={selectedTab === 'fields'}
                showAddFieldList={showAddFieldList}
                selectedFieldIndex={selectedFieldIndex}
                showFormSection={showFormSection}
                setShowAddFieldList={setShowAddFieldList}
                setSelectedFieldIndex={setSelectedFieldIndex}
                setDocumentIds={setDocumentIds}
                setShowFormSection={setShowFormSection}
                // handleOpenOnDiagram={handleOpenOnDiagram}
              />
            )}

            {selectedTab === 'diagram' && notSavedForm && (
              <DiagramForm
                ref={diagramFormRef}
                nodes={nodes}
                edges={edges}
                setNodes={setNodes}
                setEdges={setEdges}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                reactFlowInstance={reactFlowInstance}
                showFormSection={showFormSection}
                setReactFlowInstance={setReactFlowInstance}
                setShowAddFieldList={setShowAddFieldList}
                setSelectedFieldIndex={setSelectedFieldIndex}
                setSelectedSectionType={setSelectedSectionType}
                setFieldsListChanged={setFieldsListChanged}
                setShowFormSection={setShowFormSection}
              />
            )}
          </div>
        </div>
      </div>
    );
  }

  function showWarningModal(path?: string) {
    setPathToNavigate(path || '');
  }

  return (
    <FormConstructorStyles showDiagram={selectedTab === 'diagram' && !!notSavedForm}>
      <Helmet>
        <title>{`Form constructor ${formName} Voicer`}</title>
      </Helmet>

      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title')}
        pathArray={[
          ...(company ? [{ name: company.name!, route: `/companies/${companyId}/nodes`, callback: showWarningModal }] : []),
          ...(node ? [{ name: node.name!, route: `/companies/${companyId}/nodes/${nodeId}/forms`, callback: showWarningModal }] : []),
          ...(formName ? [{ name: formName }] : []),
        ]}
      >
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button')} />
      </SubHeader>

      {renderPageContent()}

      {pathToNavigate && <LeavePageModal setPathToNavigate={setPathToNavigate} pathToNavigate={pathToNavigate} />}
    </FormConstructorStyles>
  );
}
