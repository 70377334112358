import React, {
  useEffect, useRef, useState,
} from 'react';
import {
  useNavigate,
  useParams,
  useSearchParams,
  createSearchParams,
  useLocation,
} from 'react-router-dom';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { ReviewsTableStyles } from './ReviewsTableStyles';
import { Api, ApiResponse, mapApiFormToIForm } from '../../../api';
import {
  getNodesDictionary, getLanguages, addFieldTypeToAnswer, addKeyQuestionValueToAnswer,
} from '../../../utils';
import {
  INode, IReviewForm,
  IReviewFormWithNodeInfo,
  CompaniesMainInfo, IContact,
  IReviewStatus, ISource,
} from '../../../entities';
import 'react-calendar/dist/Calendar.css';
import {
  ConnectionChannelExtraField,
  KeyAnswerExtraField,
  LanguagesExtraField,
  NameExtraField,
  StatusExtraField,
  SourceExtraField,
  EmailExtraField,
  UserNameExtraField,
  PhoneNumberExtraField,
  CommentExtraField,
  KeyQuestionAnswerField,
} from './ReviewsTableExtraFields';
import { useAppDispatch, useAppSelector, setCompanyMetadata } from '../../../state';
import {
  availablePageSizes, hiddenReviewFilterStatuses, reviewsStatusIconsAndText,
} from '../../../constants';
import { useWindowSize } from 'usehooks-ts';
import { Helmet } from 'react-helmet-async';
import { extraFieldsSettings, mapTableSettingsKeyNames } from './reviewsTableUtils';
import {
  CompanyFilter, FilterSelectInput, Loader, SubHeader, Table, TransparentButton,
} from '../../../components';
import { useDebounce } from '../../../hooks';
import { SearchIcon } from '../../../assets';

export const ReviewsTable = () => {
  const { pageNumber } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const code = search.get('companyID');

  const { width } = useWindowSize();

  const { languages: languagesState, interfaceLanguage } = useAppSelector((state) => state.languages);

  const [searchParams, setSearchParams] = useSearchParams();

  const [loadingReviews, setLoadingReviews] = useState<boolean>(false);
  const [companies, setCompanies] = useState<{[key: string]: CompaniesMainInfo}>();
  const [reQuery, setReQuery] = useState<boolean>();
  const [pageSize, setPageSize] = useState(availablePageSizes[0])
  const [phone, setPhone] = useState<string>('');
  const [reviewID, setReviewId] = useState<string>('');

  const shouldBeCanceled = useRef<boolean>(false);
  const companiesRef = useRef<{[key: string]: CompaniesMainInfo}>({});
  const nodesRef = useRef<{[key: string]: Partial<INode>}>({});
  const formsRef = useRef<{[key: string]: {lang: number, companyId: number, nodeId: number}}>({});
  const reviewsRef = useRef<IReviewFormWithNodeInfo[]>();
  const reviewsQuantityRef = useRef<number>(0);
  const headersRef = useRef<{Header: string | any, accessor: string, Cell?: any}[]>();
  const filterParamsRef = useRef<{ [key: string]: number | string | string[] | number[] }>({});
  const tableColumnsSettingsRef = useRef<{ [key: string]: boolean }>({});
  const sourcesRef = useRef<{ reviewId: number, value: string | null | undefined}[]>([]);
  const reviewContactsRef = useRef<Array<IContact[]>>([]);

  const debouncedPhone = useDebounce(phone, 1500);
  const debouncedReviewID = useDebounce(reviewID, 1500);

  const reviewStatuses = Object.values(reviewsStatusIconsAndText(interfaceLanguage))?.filter((item) => !hiddenReviewFilterStatuses.includes(item?.value));

  const onClickToRow = async (data: any) => {
    window.open(`/reviews/company/${formsRef.current[`${data.formId}`]?.companyId}/review/${data.id}`, '_blank');
  };

  const refreshFilter = () => {
    filterParamsRef.current = { companyID: filterParamsRef.current?.companyID }
    const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>)
    setSearchParams(path);
    setReQuery(!reQuery)
  }

  const getCompanies = (data: CompaniesMainInfo[]) => {
    const newCompanies = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;

      return acc;
    }, {});

    const companiesDictionary = companiesRef.current ? { ...companiesRef.current, ...newCompanies } : newCompanies;

    // @ts-ignore
    companiesRef.current = companiesDictionary;
    setCompanies(companiesDictionary);
  }

  const getCompanyNodes = async (id: number) => {
    const res = await Api.getCompanyNodes(id);

    if (!shouldBeCanceled.current && res.statusCode >= 200 && res.statusCode < 300) {
      const newNodesDictionary: {[key: number]: Partial<INode>} = {};

      // @ts-ignore
      getNodesDictionary(newNodesDictionary, res.data);

      const nodesDictionary = nodesRef.current ? { ...nodesRef.current, ...newNodesDictionary } : newNodesDictionary;

      if (Object.keys(nodesDictionary).length > 0) {
        // @ts-ignore
        nodesRef.current = nodesDictionary;
      }
    }
  }

  function transformFormsData(arr: any) {
    const newForms = arr.reduce((acc: any, item: any) => {
      acc[`${item.id}`] = {
        lang: item.language_id, companyId: item.companyID, nodeId: item.node_id, name: item.name,
      };

      return acc;
    }, {});

    const data = formsRef.current ? { ...formsRef.current, ...newForms } : newForms;

    formsRef.current = data;
  }

  useEffect(() => {
    if (!languagesState) {
      getLanguages(dispatch);
    }

    Api.getCompanies().then((res) => {
      if (!shouldBeCanceled.current && res.statusCode >= 200 && res.statusCode < 300) {
        getCompanies(res.data);
      }
    });

    return () => {
      shouldBeCanceled.current = true;
    }
  }, []);

  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      getCompanyNodes(+filterParamsRef.current.companyID);
    }
    const interval = setInterval(() => {
      if (filterParamsRef.current.companyID === 110) {
        window.location.reload();
      }
    }, 180000);
    return () => clearInterval(interval);
  }, [filterParamsRef.current.companyID])

  function getReviewsAdditionalData(item: IReviewForm, source: ISource) {
    const node = nodesRef.current[formsRef.current[item.formId]?.nodeId];

    if (node?.sourceId) {
      return {
        reviewId: item.id,
        value: source.name,
      };
    }

    return {
      reviewId: item.id,
      value: null,
    };
  }

  function transformReviewAnswers(review: any) {
    if (!shouldBeCanceled.current) {
      const reviewAnswersWithType = review.answers.map((item: any) => ({
        ...item,
        type: addFieldTypeToAnswer(item, review.formFields.extraFields!),
        keyQuestion: addKeyQuestionValueToAnswer(item, review.formFields.extraFields!),
      }));
      return {
        ...review,
        answers: reviewAnswersWithType,
      }
    }
  }

  async function workWithResponse(response: ApiResponse<IReviewForm[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newReviews = response.data.map((form) => ({
        ...form,
        nodeName: '',
        address: '',
      }));
      const newReviewsModified = newReviews.map(transformReviewAnswers)
      const data = [...(reviewsRef.current || []), ...(Array.isArray(newReviewsModified) ? newReviewsModified : [])];

      if (!shouldBeCanceled.current) {
        reviewsRef.current = data;
        reviewsQuantityRef.current = response.count!;

        const formOfReviews = newReviews.map((item) => mapApiFormToIForm(item.form))
        transformFormsData(formOfReviews);

        if (tableColumnsSettingsRef.current?.sources) {
          sourcesRef.current = newReviews.map((item) => getReviewsAdditionalData(item, item.source as ISource));
        }

        if (tableColumnsSettingsRef.current?.communication_channel) {
          // @ts-ignore
          // eslint-disable-next-line array-callback-return
          reviewContactsRef.current = newReviews.map((item) => {
            if (!shouldBeCanceled.current) {
              return item.contact;
            }
          });
        }

        const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>)

        setSearchParams(path);
        setLoadingReviews(false);
      }
    }
  }

  useEffect(() => {
    if (formsRef.current && reviewsRef.current && tableColumnsSettingsRef.current) {
      const data = [
        ...(tableColumnsSettingsRef.current?.reviewId ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_review_id_column_title'),
          accessor: 'reviewId',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <div>{data.row.original.id}</div>
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.created_at ? [{
          Header:
          <FilterSelectInput
            columnName="createdAt"
            title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_create')}
            filterParams={filterParamsRef.current}
            setFilterParams={(params: any) => { filterParamsRef.current = params; }}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={(params: any) => {
              filterParamsRef.current = params;
              const path = createSearchParams(params as Record<string, string | string[]>);
              navigate(`/reviews/page/1?${path}`);
            }}
          />,
          accessor: 'createAt',
        }] : []),
        ...(tableColumnsSettingsRef.current?.statuses ? [{
          Header:
          <FilterSelectInput
            columnName="statuses"
            title={getTranslationByLangOrEng(interfaceLanguage, 'table_columns_settings_status_option')}
            filterParams={filterParamsRef.current}
            setFilterParams={(params: any) => { filterParamsRef.current = params; }}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={(params: any) => {
              filterParamsRef.current = params;
              const path = createSearchParams(params as Record<string, string | string[]>);
              navigate(`/reviews/page/1?${path}`);
            }}
            status={reviewStatuses as IReviewStatus[] || []}
          />,
          accessor: 'statuses',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <StatusExtraField status={data.row.original.status} lang={interfaceLanguage} />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.nodes ? [{
          Header:
          <FilterSelectInput
            title={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_node_column_title')}
            columnName="nodes"
            filterParams={filterParamsRef.current}
            setFilterParams={(params: any) => { filterParamsRef.current = params; }}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={(params: any) => {
              filterParamsRef.current = params;
              const path = createSearchParams(params as Record<string, string | string[]>);
              navigate(`/reviews/page/1?${path}`);
            }}
            containerExtraStyles="extraFilterStyles"
            extraFilterFormStyles="extraFilterFormStyles"
            optionsExtraStyles="optionsExtraStyles"
          />,
          accessor: 'nodeName',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <NameExtraField
              nodeName={data.row.original.node.name || ''}
            />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.sources ? [{
          Header:
          <FilterSelectInput
            title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_source')}
            columnName="sources"
            filterParams={filterParamsRef.current}
            setFilterParams={(params: any) => { filterParamsRef.current = params; }}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={(params: any) => {
              filterParamsRef.current = params;
              const path = createSearchParams(params as Record<string, string | string[]>);
              navigate(`/reviews/page/1?${path}`);
            }}
          />,
          accessor: 'sources',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <SourceExtraField reviewId={data.row.original.id} sources={sourcesRef.current} />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.keyAnswer ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_estimate'),
          accessor: 'keyAnswer',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <KeyAnswerExtraField answers={data.row.original.answers} />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.communication_channel ? [{
          Header:
          <FilterSelectInput
            columnName="contacts"
            title={getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_contact_type')}
            filterParams={filterParamsRef.current}
            setFilterParams={(params: any) => { filterParamsRef.current = params; }}
            setReQuery={() => setReQuery(!reQuery)}
            setApply={(params: any) => {
              filterParamsRef.current = params;
              const path = createSearchParams(params as Record<string, string | string[]>);
              navigate(`/reviews/page/1?${path}`);
            }}
          />,
          accessor: 'connectionChannel',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => {
            const contactsData = reviewContactsRef.current.filter((arr: IContact[]) => arr.some((obj: IContact) => obj.clientID === data.row.original.id));
            return (
              <ConnectionChannelExtraField rewID={data.row.original.id} answers={data.row.original.answers} contacts={contactsData.flat()} />
            )
          },
        }] : []),
        ...(tableColumnsSettingsRef.current?.comment ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_comment_column_title'),
          accessor: 'comment',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <CommentExtraField comment={data.row.original.comment} />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.name ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_user_name_column_title'),
          accessor: 'name',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <UserNameExtraField answers={data.row.original.answers} />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.phone ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_phone_column_title'),
          accessor: 'phone',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <PhoneNumberExtraField answers={data.row.original.answers} />
          ),
        }] : []),
        ...(tableColumnsSettingsRef.current?.email ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_email_column_title'),
          accessor: 'email',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <EmailExtraField answers={data.row.original.answers} />
          ),
        }] : []),
        ...(languagesState?.length && tableColumnsSettingsRef.current?.language ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_header_language'),
          accessor: 'language',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => {
            const language = languagesState?.find((lang) => lang.id === formsRef.current[`${data.row.original.formId}`].lang);

            // @ts-ignore
            return language ? <LanguagesExtraField lang={language} /> : <div />;
          },
        }] : []),
        ...(tableColumnsSettingsRef.current?.keyQuestion ? [{
          Header: getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_key_question_column_title'),
          accessor: 'keyQuestion',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <KeyQuestionAnswerField answers={data.row.original.answers} />
          ),
        }] : []),
      ];
      headersRef.current = data;
    }
  }, [formsRef.current, reviewsRef.current, interfaceLanguage, tableColumnsSettingsRef.current]);

  useEffect(() => {
    if (!loadingReviews) {
      const filterParams = searchParams.toString().split('&').reduce((acc: {[key: string]: string | number | number[]| string[]}, item) => {
        const paramParts = item.split('=');

        if (paramParts[0] === 'statuses') {
          if ('statuses' in acc) {
            (acc.statuses as number[]).push(+paramParts[1]);
          } else {
            acc.statuses = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'nodes') {
          if ('nodes' in acc) {
            (acc.nodes as number[]).push(+paramParts[1]);
          } else {
            acc.nodes = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'sources') {
          if ('sources' in acc) {
            (acc.sources as number[]).push(+paramParts[1]);
          } else {
            acc.sources = [+paramParts[1]];
          }
        } else if (paramParts[0] === 'contacts') {
          if ('contacts' in acc) {
            (acc.contacts as string[]).push(paramParts[1]);
          } else {
            acc.contacts = [paramParts[1]];
          }
        } else if (paramParts[0] === 'from') {
          acc.from = paramParts[1].replaceAll('%3A', ':');
        } else if (paramParts[0] === 'clientID') {
          setReviewId(paramParts[1])
          acc.clientID = paramParts[1];
        } else if (paramParts[0] === 'phone') {
          setPhone(paramParts[1])
          acc.phone = paramParts[1];
        } else if (paramParts[0] === 'to') {
          acc.to = paramParts[1].replaceAll('%3A', ':');
        } else if (paramParts[0] !== '' && paramParts[1] !== undefined) {
          acc[paramParts[0]] = +paramParts[1];
        }

        return acc;
      }, {});

      if (filterParams.companyID) {
        filterParamsRef.current = filterParams;

        setLoadingReviews(true);
        reviewsRef.current = undefined;
        Api.postDataToGetFilteredReviews({ ...filterParams, search: { clientID: filterParams.clientID as string, phone: filterParams.phone as string } }, (pageNumber ? +pageNumber - 1 : 0) * pageSize, pageSize)
          .then((formsResult) => {
            if (!shouldBeCanceled.current) {
              workWithResponse(formsResult);
            }
          })
      }
    }
  }, [pageNumber, reQuery, pageSize, debouncedPhone, debouncedReviewID]);

  useEffect(() => {
    if (filterParamsRef.current.companyID) {
      Api.getCompanyMetadataByCompanyId(+filterParamsRef.current.companyID).then((res) => {
        if (res.statusCode === 200) {
          dispatch(setCompanyMetadata(res.data));
          const tableSettings: {[key: string]: boolean} = {};
          const tableColumnSettings = res.data?.reviewTableSettings;
          tableColumnSettings?.forEach((item) => {
            tableSettings[mapTableSettingsKeyNames(item.name)] = true;
          })
          tableColumnsSettingsRef.current = tableSettings;
        }
      }).catch((e) => {
        console.log('e', e);
      });
    }
  }, [filterParamsRef.current.companyID])

  return (
    <ReviewsTableStyles tableVersion={width < 1020} mobileVersion={width < 500}>
      <Helmet>
        <title>Reviews Voicer</title>
      </Helmet>

      <div>
        <SubHeader
          title={`${getTranslationByLangOrEng(interfaceLanguage, 'companies_reviews_table_title')} (${reviewsQuantityRef.current})`}
        />

        <div className="tableFiltersWrapper">
          {companies && (
            <CompanyFilter
              filterParams={filterParamsRef.current}
              companies={companies}
              setFilterParams={(params: any) => { filterParamsRef.current = params; }}
              setReQuery={() => setReQuery(!reQuery)}
              setApply={(params: any) => {
                filterParamsRef.current = params;
                const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
                navigate(`/reviews/page/1?${path}`);
              }}
            />
          )}
          <div className="tableFiltersWrapperBtns">
            {!!formsRef.current && !!reviewsRef.current && !!headersRef.current && !loadingReviews && filterParamsRef.current?.companyID && (
              <TransparentButton
                filled
                handleClick={() => {
                  navigate(`/reviews/page/1?companyID=${code}&statuses=0&statuses=1&statuses=6&statuses=7&statuses=8`);
                  setReQuery(!reQuery);
                }}
                text={getTranslationByLangOrEng(interfaceLanguage, 'reviews_table_ukrposhta_settings_button')}
              />
            )}
            {!!formsRef.current && !!reviewsRef.current && !!headersRef.current && !loadingReviews && filterParamsRef.current?.companyID && (
              <>
                <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'roles_sub_permission_update')} handleClick={() => window.location.reload()} filled />
                <TransparentButton text={getTranslationByLangOrEng(interfaceLanguage, 'refresh_all_fitlers')} handleClick={() => refreshFilter()} extraStyles={{ backgroundColor: '#ccc', border: 'none' }} extraButtonTextStyles={{ color: '#ffffff' }} />
              </>
            )}
          </div>
        </div>

        {!!formsRef.current && !!reviewsRef.current && !!headersRef.current && !loadingReviews && filterParamsRef.current?.companyID && (
          <div className="searchContainer">
            <div>
              <SearchIcon />
              <input
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_by_reviewID')}
                type="number"
                value={reviewID}
                onChange={(e) => {
                  if (e.target.value.length) {
                    filterParamsRef.current = { ...filterParamsRef.current, clientID: e.target.value }
                  } else {
                    const { clientID, ...rest } = filterParamsRef.current;
                    filterParamsRef.current = rest;
                  }
                  const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
                  setReviewId(e.target.value)
                  navigate(`/reviews/page/1?${path}`)
                }}
              />
            </div>
            <div>
              <SearchIcon />
              <input
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_by_phone')}
                type="number"
                value={phone}
                onChange={(e) => {
                  if (e.target.value.length) {
                    filterParamsRef.current = { ...filterParamsRef.current, phone: e.target.value };
                  } else {
                    const { phone, ...rest } = filterParamsRef.current;
                    filterParamsRef.current = rest;
                  }
                  const path = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
                  setPhone(e.target.value)
                  navigate(`/reviews/page/1?${path}`)
                }}
              />
            </div>
          </div>
        )}

        {loadingReviews && <Loader />}

        {!loadingReviews && !filterParamsRef.current.companyID
        && (
        <div className="chooseCompanyTitleContainer">
          <div className="chooseCompanyTitle">{translations[interfaceLanguage].reviews_page_choose_company_title}</div>
        </div>
        )}

        {!!formsRef.current && !!reviewsRef.current && !!headersRef.current && !loadingReviews && filterParamsRef.current?.companyID && (
          <Table
            pagination
            columns={headersRef.current}
            data={reviewsRef.current}
            hideFieldsSplitters
            extraFieldsSettings={extraFieldsSettings}
            onClickToRow={onClickToRow}
            rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_review_title')}
            hiddenHeaders={['details']}
            fullWidthColumns={['createAt', 'details']}
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={pageNumber ? +pageNumber : 1}
            rowsQuantity={reviewsQuantityRef.current}
            pageNumberHandler={(page) => {
              if (page) {
                const params = createSearchParams(filterParamsRef.current as Record<string, string | string[]>);
                navigate(`/reviews/page/${page}?${params}`);
              }
            }}
          />
        )}
      </div>
    </ReviewsTableStyles>
  );
}
