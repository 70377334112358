import React, { useEffect } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes,
} from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { Layout, RefContextProvider } from './components';
import { useAppDispatch, useAppSelector, setInterfaceLanguage } from './state';
import AddCompany from './pages/companies/addCompany/AddCompany';
import AddNode from './pages/companies/addNode/AddNode';
import EditCompany from './pages/companies/editCompany/EditCompany';
import CompanyStructure from './pages/companies/structure/CompanyStructure';
import FormConstructor from './pages/companies/formConsructor/FormConstructor';
import Forms from './pages/companies/forms/Forms';
import Documents from './pages/companies/documents/Documents';
import AddDocument from './pages/companies/addDocument/AddDocument';
import ScaleConstructor from './pages/companies/formConsructor/scaleConstructor/ScaleConstructor';
import Form from './pages/companies/form/Form';
import MassMailingsTablePage from './pages/massMailings/table/MassMailingsTablePage';
import MassMailingCreationStep2
  from './pages/massMailings/creating/massMailingCreationByConnectionChannel/massMailingCreationStep2';
import MassMailingCreationStep3
  from './pages/massMailings/creating/massMailingCreationByConnectionChannel/massMailingCreationStep3';
import {
  AddIssue, AddLanguage,
  AddPermission, AddUser,
  Analytic, ErrorPage,
  Impex, Issues, Languages,
  Permissions, Profile, Recovery,
  Login, Registration, ResetPassword,
  Review, ReviewsTable, AddRole, Roles, Users, Companies,
} from './pages';
import FormInfo from './pages/companies/formInfo/FormInfo';
import { getInterfaceLanguage } from './utils';
import CreateMassMailingsFirstStep from './pages/massMailings/creating/createMassMailingsFirstStep/CreateMassMailingsFirstStep';
import ReactGA from 'react-ga';

ReactGA.initialize('G-ZF7WY5DGRD');

function App() {
  const helmetContext = {};
  const dispatch = useAppDispatch();

  const { loggedIn } = useAppSelector((state) => state.user);

  useEffect(() => {
    dispatch(setInterfaceLanguage(getInterfaceLanguage() || 'EN'));
  }, []);

  // console.log(new Date().getTime())

  return (
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <Routes>
          <Route path="/error/:id" element={<ErrorPage />} />

          <Route element={<Layout />}>
            {loggedIn && (
              <>
                <Route
                  path="/"
                  element={<Navigate to="/profile" />}
                />
                <Route path="/profile" element={<Profile />} />
                <Route path="/analytic" element={<Analytic />} />

                {/* Companies */}
                <Route path="/companies" element={<Companies />} />
                <Route path="/companies/create" element={<AddCompany />} />
                <Route path="/companies/:id/nodes" element={<CompanyStructure />} />
                <Route path="/companies/:companyId/nodes/add" element={<AddNode />} />
                <Route path="/companies/:id/edit" element={<EditCompany />} />
                <Route path="/companies/:companyId/nodes/:nodeId/forms" element={<Forms />} />
                <Route path="/companies/:companyId/nodes/:nodeId/forms/create" element={<FormInfo />} />
                <Route path="/companies/:companyId/nodes/:nodeId/forms/:formId/edit" element={<FormInfo />} />
                <Route path="/companies/:companyId/nodes/:nodeId/forms/:formId/constructor" element={<RefContextProvider><FormConstructor /></RefContextProvider>} />
                <Route path="/companies/:id/nodes/:nodeId/scale/:scaleIndex/scaleConstructor" element={<ScaleConstructor />} />
                <Route path="/companies/:id/nodes/:nodeId/forms/:formId/scale/:scaleIndex/scaleConstructor" element={<ScaleConstructor />} />
                <Route path="/companies/:id/nodes/:nodeId/forms/:formId/preview" element={<Form />} />
                <Route path="/companies/:id/nodes/:nodeId/forms/new/preview" element={<Form />} />
                {/* <Route path="/companies/:id/forms/:formId/thankYou" element={<ThankYou />} /> */}
                <Route path="/companies/:companyId/documents" element={<Documents />} />
                <Route path="/companies/:companyId/documents/create" element={<AddDocument />} />
                <Route path="/companies/:companyId/documents/:documentId/edit" element={<AddDocument />} />

                {/* Reviews */}
                <Route path="/reviews" element={<Navigate to="/reviews/page/1" />} />
                <Route path="/reviews/page/:pageNumber" element={<ReviewsTable />} />
                <Route path="/reviews/:companyID" element={<ReviewsTable />} />
                <Route path="/reviews/:companyID/:node_id" element={<ReviewsTable />} />
                <Route path="/reviews/company/:companyId/review/:id" element={<Review />} />

                {/* Issues */}
                <Route path="/issues" element={<Navigate to="/issues/page/1" />} />
                <Route path="/issues/page/:pageNumber" element={<Issues />} />
                <Route path="/issues/create" element={<AddIssue />} />
                <Route path="/issues/:id" element={<AddIssue />} />

                {/* Users */}
                <Route path="/users" element={<Navigate to="/users/page/1" />} />
                <Route path="/users/page/:pageNumber" element={<Users />} />
                <Route path="/users/create" element={<AddUser />} />
                <Route path="/users/:id" element={<AddUser />} />
                <Route path="/users/roles" element={<Roles />} />
                <Route path="/users/roles/page/:pageNumber" element={<Roles />} />
                <Route path="/users/roles/create" element={<AddRole />} />
                <Route path="/users/roles/:id" element={<AddRole />} />
                <Route path="/users/permissions" element={<Permissions />} />
                <Route path="/users/permissions/page/:pageNumber" element={<Permissions />} />
                <Route path="/users/permissions/create" element={<AddPermission />} />
                <Route path="/users/permissions/:id" element={<AddPermission />} />

                {/* Languages */}
                <Route path="/languages" element={<Languages />} />
                <Route path="/languages/page/:pageNumber" element={<Languages />} />
                <Route path="/languages/create" element={<AddLanguage />} />

                {/* Mass mailing */}
                <Route path="/massMailings/create" element={<CreateMassMailingsFirstStep />} />
                <Route path="/massMailings/table" element={<MassMailingsTablePage />} />
                <Route path="/massMailings/create/step-2" element={<MassMailingCreationStep2 />} />
                <Route path="/massMailings/create/step-3" element={<MassMailingCreationStep3 />} />

                {/* Import/Export */}
                <Route path="/impex" element={<Impex />} />
              </>
            )}

            {!loggedIn && (
              <>
                <Route
                  path="/"
                  element={<Navigate to="/auth/login" />}
                />
                <Route path="/auth/registration" element={<Registration />} />
                <Route path="/auth/login" element={<Login />} />
                <Route path="/auth/recovery" element={<Recovery />} />
                <Route path="/auth/password-reset" element={<ResetPassword />} />
              </>
            )}
          </Route>

          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
