import styled from 'styled-components';

export const FilterSelectInputStyles = styled.div`
    position: relative;
    padding: 8px 0px;

    .entityTitle {
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            margin-right: 8px;
        }

        svg {
            width: 16px;
            height: 16px;
        }
    }

    .filterTitle {
       font-weight: 400;
    }

    .filtersOpener {
        cursor: pointer;
        display: flex;
        justify-content: space-between;
        width: 100px;
        align-items: center;
        padding: 4px 10px;
        border: 1px solid #E5E5E5;

        @media (max-width: 781px) {
            width: 100%;
        }

        &-active {
            svg {
                transform: rotate(180deg);
            }
        }
    }
`;
