import styled from 'styled-components';

export const ColorsScaleConstructorStyles = styled.div`
  padding: 26px 32px 80px 16px;

  .titleContainer {
    display: flex;
    align-items: center;
    
    .quantityContainer {
      display: flex;
      align-items: center;
      height: 26px;
      padding: 0 8px;
      background-color: #eee;
      border-radius: 4px;
      margin-left: 16px;
      margin-bottom: 8px;
    }

    span {
      font-size: 14px;
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }

  h3 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 0;
    margin-bottom: 8px;
  }

  .horizontalLine {
    width: 100%;
    max-width: 458px;
    margin: 16px 0 40px;
    height: 1px;
    background-color: #E5E5E5;
  }

  .scaleContainer {
    width: 458px;
    margin-bottom: 24px;
  }

  .selectMultipleFilesContainer {
    width: 100%;
  }

  .pointerColorContainer {
    width: 250px;
  }

  .selectedColor {
    width: 290px;
    height: 40px;
    display: flex;
    align-items: center;

    .colorNumber {
      width: 24px;
      margin-right: 8px;
    }

    .colors {
      display: flex;
      align-items: center;

      &.valueContainer {
        grid-gap: 12px;
        flex-grow: 1;
        display: grid;
        grid-template-columns: auto 1fr auto;
        align-items: center;
        font-size: 14px;
        color: #000;
      }

      &.colorOptionContent {
        color: #6e6e6e;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        grid-template-columns: auto 1fr auto;
        grid-gap: 12px;
        align-items: center;

        .value {
          width: 100%;
        }
      }

      .color {
        width: 24px;
        height: 24px;
        border-radius: 4px;
      }
    }

    .removeColorButton {
      margin-left: 16px;
    }
  }

  .addColorContainer {
    width: 100%;
    max-width: 290px;
    display: flex;
    
    .addItemButtonContainer {
      height: 40px;
      display: flex;
      align-items: center;
    }

    .addColorButton {
      width: 20px;
      height: 20px;
      margin-left: 16px;
      background-color: #0E9285;
      border-radius: 10px;
      display: grid;
      place-items: center;

      svg {
        width: auto;
        height: 12px;
      }
    }
  }
  
  .mascotOptionItem {
    width: 100%;
    height: 64px;
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-gap: 12px;
    align-items: center;
    
    .imageContainer {
      width: 100%;
      height: 48px;
      
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
    
    p {
      text-align: left;
    }
  }
  
  .mascotOptions {
    max-height: 200px;
    overflow-y: scroll;
    
    .mascotOption {
      height: 64px !important;
    }
  }

  .uploadedMascotContainer {
    width: 70px;
    height: 50px;
    margin-right: 12px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .mascotsContainer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;

    .valueContainer.mascots {
      display: flex;
      align-items: center;
    }

    .selectedMascot {
      background-color: #fafafa;
      border: 1px solid #E5E5E5;
      border-radius: 4px;
      padding: 8px;
      box-sizing: border-box;
      width: 100%;
      max-width: 458px;
      display: grid;
      grid-template-columns: 24px 1fr 20px;
      grid-gap: 16px;
      align-items: center;

      .valueContainer {
        display: grid;
        grid-template-columns: 70px 1fr;
        grid-gap: 16px;
        align-items: center;

        span {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }
    }
  }

  .addMascotContainer {
    width: 100%;
    max-width: 458px;
    margin-top: 8px;
    margin-bottom: 24px;
    
    .iconContainer svg {
      width: 16px;
      height: 16px;
      margin-right: 12px;
    }
  }

  .addMascotWrapper {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 16px;
    margin-bottom: 24px;
    align-items: center;

    .logoContainer {
      height: 80px;
      background: #FFF;
      box-sizing: border-box;
      overflow: hidden;

      &.default {
        display: grid;
        place-items: center;
        background: #F0F0F0;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
      }

      .selectedImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .uploadButtonFieldContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0;

      .uploadError {
        font-size: 11px;
        line-height: 16px;
        color: #ff3333;
      }

      .description {
        font-size: 12px;
        line-height: 16px;
        color: #979797;
      }
    }
  }

  .checkboxValue {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
  }
  
  .submitButtonContainer {
    width: 100%;
    max-width: 458px;
  }
`;
