import styled from 'styled-components';

export const MassMailingsCreatingEmailStep2Styles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #F0F0F0;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }

    .buttonsContainer {      
      & > div {
        margin: 0;
      }
    }
  }

  .formSection {
    padding: 20px 32px 32px 16px;
    box-sizing: border-box;
  }
  
  .nameContainer {
    padding: 20px 32px 0 16px;
    box-sizing: border-box;
  }
  
  form {
    .formFieldsContainer {
      margin-bottom: 20px;
    }
    
    .uploadFilesContainer {
      display: grid;
      grid-template-columns: auto 1fr;
      gap: 64px;

      margin-bottom: 24px;

      .uploadButtonContainer {
        display: flex;
        flex-direction: column;
        
        &.fileLimitError {
          button {
            background-color: #ff3333;
            border: 1px solid #ff3333;
            color: #FFF;
          }
        }
        
        button {
          height: 40px;
          margin-bottom: 8px;
        }

        .description {
          font-size: 15px;
          line-height: 16px;
          color: #979797;
        }
      }
      
      .attachedFilesContainer {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 16px;
        
        .fileItemWrapper {
          position: relative;
          
          .fileItem {
            display: grid;
            grid-template-columns: 1fr 32px;
            max-width: 416px;
            grid-gap: 16px;
            align-items: center;
            background-color: #f9f9f9;
            height: 48px;
            padding: 0 16px;
            border-radius: 4px;

            &.tooBigSizeError {
              background-color: #ff3333;
            }

            span {
              text-overflow: ellipsis;
              font-size: 13px;
              color: #00729f;
            }

            p {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              color: #000;
            }

            button {
              width: 32px;
              height: 32px;
              display: grid;
              place-items: center;

              svg {
                transform: rotate(45deg);
              }
            }
          }
            
          .formErrorContainer {
            position: absolute;
          }
        }
      }
    }

    .buttonsContainer {
      display: grid;
      grid-template-columns: 1fr;
      row-gap: 16px;

      & > div {
        margin: 0;
      }
      
      button {
        width: 250px;
      }
      
      max-width: 830px;
      width: 100%;
    }
  }

  .previewContainer {
    width: 100%;
    max-width: 1000px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;

    .modalHeader {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-right: 12px;
      box-sizing: border-box;

      .modalTitle {
        font-size: 14px;
        color: #000;
        margin: 0;
        padding: 0;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .modalContent {
      border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      position: relative;
      
      .desktopPreview {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        height: 100%;
        max-height: calc(100vh - 160px - 48px - 40px);
        overflow-y: scroll;
      }
      
      .phonePreview {
        table {
          width: 100% !important;
        }
        
        .previewBodyContainer {
          position: absolute;
          top: 50%;
          left: 50%;
          z-index: 100;
          transform: translate(-50%, -50%);
          width: 205px;
          height: 444px;
        }

        & > img {
          //box-shadow: 2px 2px 10px rgba(0,0,0,0,15);
          filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15)) drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.15));
        }
      }
    }

    .modalFooter {
      margin: 0 !important;
      height: 48px !important;
      padding: 0 16px !important;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
    }
  }
`;
