import {
  INodeConnectionTypes, INodesConnection,
  IExtraField, IFormDocument,
  INodeSettings,
} from '../../../entities';

export function getNodeConnections(field: (IExtraField | IFormDocument)): INodeSettings {
  const connections: INodesConnection[] = (field.type === 'document' ? [] : field.answers)?.filter((answer) => !!answer.nextField).map((answer) => ({
    type: INodeConnectionTypes.if,
    selectedCase: answer.id,
    selectedField: answer.nextField,
    isOpen: false,
  })) || [];

  const defaultConnection: INodesConnection[] = field.fieldID ? [{
    type: INodeConnectionTypes.always,
    selectedField: field.fieldID,
    isOpen: false,
  }] : [];

  const emptyConnection = {
    isOpen: true,
    type: INodeConnectionTypes.always,
    selectedCase: undefined,
    selectedField: undefined,
  };

  const connectionsWithAnswers = [
    ...connections,
    ...defaultConnection,
    ...((connections.length + defaultConnection.length) === 0 ? [emptyConnection] : []),
  ];

  const connectionsWithoutAnswers = [
    ...defaultConnection,
    ...(defaultConnection.length === 0 ? [emptyConnection] : []),
  ];

  switch (field.type) {
    case 'radio':
      return { fieldType: field.type, inSeries: field.fieldInSeries, connections: connectionsWithAnswers };
    case 'scale':
      return { fieldType: field.type, inSeries: field.fieldInSeries, connections: connectionsWithAnswers };
    default:
      return { fieldType: field.type, inSeries: field.fieldInSeries, connections: connectionsWithoutAnswers };
  }
}
