import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../i18n';

export const priorities = [1, 2, 3, 4, 5];

export interface FormValues {
  name: string;
  company: number | undefined;
  node: number | undefined;
  status: number | undefined;
  typeId: number | undefined;
  type: string | undefined;
  category: string | undefined;
  subcategory: string | undefined;
  priority: number;
  responsible: number | undefined;
  comment: string;
  review: number | undefined;
  connectedIssue: number | undefined;
}

export interface FormValues2 {
  value: string;
}

export interface LocalType {
  name: string;
  companyId: number | undefined;
  createdAt?: string | undefined;
  id?: number | undefined;
  categories?: {
    name: string;
    subcategories: { name: string }[];
  }[] | undefined;
}

export const formInitialValues: FormValues = {
  name: '',
  company: undefined,
  node: undefined,
  status: undefined,
  typeId: undefined,
  type: undefined,
  category: undefined,
  subcategory: undefined,
  priority: priorities[0],
  responsible: undefined,
  comment: '',
  review: undefined,
  connectedIssue: undefined,
};

export const formInitialValues2: FormValues2 = {
  value: '',
};

function checkCategoryValueRequired(value: any, ctx: any, localTypes: LocalType[]) {
  const chosenType = ctx?.parent?.type;
  const chosenTypeData = localTypes?.find((item: any) => item.name === chosenType);
  if (chosenTypeData?.categories && chosenTypeData?.categories?.length > 0 && !ctx?.originalValue) {
    return false;
  }
  return true;
}

function checkSubcategoryValueRequired(value: any, ctx: any, localTypes: LocalType[]) {
  const chosenType = ctx?.parent?.type;
  const chosenTypeData = localTypes?.find((item: any) => item.name === chosenType);
  const chosenCategory = ctx?.parent?.category;
  const chosenCategoryData = chosenTypeData?.categories?.find((item: any) => item.name === chosenCategory);

  if (chosenCategoryData?.subcategories && chosenCategoryData?.subcategories?.length > 0 && !ctx?.originalValue) {
    return false;
  }
  return true;
}

export const validationSchema = (lang: string, localTypes: LocalType[]) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  company: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
  node: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
  status: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  typeId: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_full_type_required')),
  type: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  category: Yup.string().test({
    name: 'is-category-required',
    message: getTranslationByLangOrEng(lang, 'validation_required'),
    test: (value, ctx) => checkCategoryValueRequired(value, ctx, localTypes),
  }),
  subcategory: Yup.string().test({
    name: 'is-subcategory-required',
    message: getTranslationByLangOrEng(lang, 'validation_required'),
    test: (value, ctx) => checkSubcategoryValueRequired(value, ctx, localTypes),
  }),
  priority: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
  // responsible: Yup.array().min(1, getTranslationByLangOrEng(lang, '')validation_required),
  comment: Yup.string(),
  review: Yup.string(),
  connectedIssue: Yup.string(),
});

export const validationSchema2 = (lang: string) => Yup.object({
  value: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
});
