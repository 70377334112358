import React, {
  Dispatch, ReactElement, SetStateAction, useRef, useState,
} from 'react';
import { TableStyles } from './TableStyles';
import {
  useExpanded, usePagination, useSortBy, useTable,
} from 'react-table';
import { TablePagination } from './tablePagination';
import { ArrowDownIcon, ThreeDots } from '../../../assets';
import { useClickOutside } from '../../../hooks';

interface TableProps {
  columns: {Header: string | any, accessor: string, element?: any}[],
  data?: any[],
  showMenuButton?: boolean,
  hideFieldsSplitters?: boolean,
  pagination?: boolean,
  rowMenuItems?: {key: string, icon: React.FC, text: string}[],
  bodyFieldIcons?: {
    [key: string]: {
      icon?: ReactElement<any, any>,
      imageKey?: string,
      defaultImage?: string
    }
  }
  extraFieldsSettings?: {
    [key: string]: {
      textColor?: string,
      backgroundColor?: boolean,
      flexDirections?: string,
      justifyContent?: string,
      alignItems?: string,
      width?: string,
      margin?: string,
    }
  },
  headerColumnCounts?: {[key: string]: number},
  sortableColumns?: string[] | 'all',
  hiddenHeaders?: string[],
  fullWidthColumns?: string[] | 'all',
  subRowsComponent?: (subRow: any) => ReactElement<any, any> | null,
  subRowKey?: string,
  expands?: boolean,
  expandKey?: string,
  expandedRows?: {[key: number]: boolean},
  deletedRows?: {[key: number]: boolean},
  handleExpandRow?: (id: number) => void,
  handleDeleteRow?: (id: number) => void,
  onClickToRow?: (data: any) => void,
  rowTitle?: string,
  pageSize?: number;
  setPageSize?: Dispatch<SetStateAction<number>>;
  currentPage?: number;
  rowsQuantity?: number,
  pageNumberHandler?: (pageNumber: number) => void,
  onClickToUser?: (data: any) => void,
}

export const Table = React.memo(({
  data, columns,
  showMenuButton, hideFieldsSplitters,
  pagination, bodyFieldIcons,
  extraFieldsSettings, headerColumnCounts,
  rowMenuItems, sortableColumns,
  hiddenHeaders, fullWidthColumns,
  subRowsComponent, subRowKey,
  expands, expandKey,
  expandedRows, deletedRows,
  handleExpandRow, onClickToRow,
  rowTitle, pageSize,
  setPageSize, currentPage,
  rowsQuantity, pageNumberHandler,
  onClickToUser,
}: TableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    page,
  } = useTable(
    {
      columns,
      data: data || [],
      initialState: { pageIndex: 0, pageSize: pageSize || 10 },
    },
    useSortBy,
    useExpanded,
    usePagination,
  );

  const ref: any = useRef(null);
  const [showTableRowMenu, setShowTableRowMenu] = useState<string>('');

  useClickOutside(ref, () => {
    setShowTableRowMenu('');
  });

  const bodyFieldIconsKeys = bodyFieldIcons ? Object.keys(bodyFieldIcons) : undefined;

  const renderTableHeader = () => (
    <thead>
      {headerGroups.map((headerGroup) => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map((column) => {
            const columnItem = (column.getHeaderProps().key as string).split('_')[1];

            return (
            // @ts-ignore
              <th
                {...column.getHeaderProps(((sortableColumns?.includes(columnItem) || sortableColumns === 'all')) ? column.getSortByToggleProps() : undefined)}
                style={hideFieldsSplitters ? { borderRight: 'none' } : {}}
              >
                <div className="fieldContent" style={{ justifyContent: extraFieldsSettings?.[columnItem]?.justifyContent }}>
                  <span>
                    {hiddenHeaders?.includes(columnItem) ? '' : column.render('Header')}
                    {headerColumnCounts?.[columnItem] ? ` (${headerColumnCounts?.[columnItem]})` : ''}
                  </span>
                  {(!hiddenHeaders?.includes(columnItem) && (sortableColumns?.includes(columnItem) || sortableColumns === 'all')) && (
                    <div className="arrows">
                      <div className={column.isSortedDesc ? 'arrowUp active' : 'arrowUp'}><ArrowDownIcon /></div>
                      <div className={column.isSorted && !column.isSortedDesc ? 'arrowDown active' : 'arrowDown'}>
                        <ArrowDownIcon />
                      </div>
                    </div>
                  )}
                </div>
              </th>
            );
          })}

          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          {showMenuButton && <th />}
        </tr>
      ))}
    </thead>
  );

  const renderRowMenu = (rowId: string) => (
    <td className="dotsContainer">
      <div className="fieldContent" style={{ justifyContent: 'center' }}>
        <button type="button" className="buttonIcon" onClick={() => setShowTableRowMenu(rowId)}>
          <ThreeDots />
        </button>
      </div>
      {rowMenuItems && showTableRowMenu === rowId && (
        <ul className="menuList" ref={ref}>
          {rowMenuItems.map((item) => {
            const IconComponent = item.icon;

            return (
              <li key={item.key}>
                <button type="button">
                  <IconComponent />
                  <span>{item.text}</span>
                </button>
              </li>
            );
          })}
        </ul>
      )}
    </td>
  );

  const renderTableBody = () => (
    <tbody {...getTableBodyProps()}>
      {((pagination ? page : rows).length === 0) && (
        <tr className="emptyRow">
          <td colSpan={columns.length}>
            <p style={{ textAlign: 'center', color: '#0E9285' }}>Table is empty</p>
          </td>
        </tr>
      )}

      {(pagination ? page : rows).map((row, index) => {
        prepareRow(row);

        // @ts-ignore
        if (deletedRows?.[row.original.id]) {
          return null;
        }

        return (
          // @ts-ignore
          <React.Fragment key={row.original.id}>
            {/* @ts-ignore */}
            <tr title={rowTitle || ''} onClick={() => (onClickToRow ? onClickToRow(row.original) : '')} {...row.getRowProps()} className={onClickToRow ? 'clickableRow' : ''}>
              {
                row.cells.map((cell) => {
                  const column = (cell.getCellProps().key as string).split('_')[2];

                  return (
                    <td
                      // @ts-ignore
                      onClick={() => (column === expandKey && handleExpandRow && handleExpandRow(row.original.id))}
                      {...cell.getCellProps()}
                      style={hideFieldsSplitters ? { borderRight: 'none' } : {}}
                    >
                      <div
                        onClick={() => onClickToUser && cell.column.id === 'name' && onClickToUser(row.original)}
                        className="fieldContent"
                        style={{
                          color: extraFieldsSettings?.[column]?.textColor,
                          flexDirection: extraFieldsSettings?.[column]?.flexDirections as 'row' | 'column' | undefined,
                          justifyContent: extraFieldsSettings?.[column]?.justifyContent,
                          alignItems: extraFieldsSettings?.[column]?.alignItems,
                          width: extraFieldsSettings?.[column]?.width,
                          margin: extraFieldsSettings?.[column]?.margin,
                          cursor: (onClickToUser && cell.column.id === 'name') || onClickToRow ? 'pointer' : 'initial',
                        }}
                      >
                        {!!bodyFieldIconsKeys && bodyFieldIconsKeys.map((item) => {
                          const iconData = bodyFieldIcons![item];

                          if (iconData.imageKey && column === item) {
                            return (
                              <div
                                key={`${row.getRowProps().key}:${iconData.imageKey}`}
                                className={data?.[index][iconData.imageKey] ? 'imageContainer' : 'imageContainer default'}
                              >
                                {data?.[index][iconData.imageKey]
                                  ? (
                                    <div className="image">
                                      <img src={data?.[index][iconData.imageKey]} alt="" />
                                    </div>
                                  )
                                  : <img src={iconData.defaultImage} alt="" />}
                              </div>
                            );
                          }

                          if (iconData.icon && column === item) {
                            return (
                              <div className="image">
                                {iconData.icon}
                              </div>
                            );
                          }

                          return null;
                        })}
                        <div
                          className={extraFieldsSettings?.[column]?.backgroundColor ? 'withBackground' : ''}
                          style={{ width: fullWidthColumns?.includes(column) ? '100%' : 'auto' }}
                        >
                          {cell.render('Cell')}
                        </div>
                      </div>
                    </td>
                  );
                })
              }

              {showMenuButton && renderRowMenu(row.id)}
            </tr>

            {/* @ts-ignore */}
            {!deletedRows?.[row.original.id]
              && expands
              // @ts-ignore
              && expandedRows![row.original.id]
              && !!subRowsComponent
              && !!subRowKey
              // @ts-ignore
              && row.original[subRowKey]?.map((subNode: any) => subRowsComponent(subNode))}
          </React.Fragment>
        );
      })}
    </tbody>
  );

  return (
    <TableStyles dataLength={data ? data.length >= 3 : false}>
      <div className="tableContainer">
        <table {...getTableProps()}>
          {renderTableHeader()}
          {renderTableBody()}
        </table>
      </div>

      {
        pagination
        && pageSize !== undefined
        && currentPage !== undefined
        && rowsQuantity !== undefined
        && pageNumberHandler !== undefined
        && (
          <TablePagination
            pageSize={pageSize}
            setPageSize={setPageSize}
            currentPage={currentPage}
            pageNumberHandler={pageNumberHandler}
            rowsQuantity={rowsQuantity}
          />
        )
      }
    </TableStyles>
  );
})
