import { getTranslationByLangOrEng } from '../../../i18n';

export const tabsEdit: (lang: string) => {[key: string]: string}[] = (lang) => ([
  {
    key: 'info',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_base_info'),
  },
  {
    key: 'design',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_design'),
  },
  {
    key: 'documents',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_documents'),
  },
  {
    key: 'issuesStatusesAndTypes',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_issues_statuses_and_types'),
  },
  {
    key: 'templates',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_templates'),
  },
  {
    key: 'sources',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_sources'),
  },
  {
    key: 'reviewTableColumnSettings',
    text: getTranslationByLangOrEng(lang, 'companies_info_tab_review_table_columns_settings'),
  },
  {
    key: 'integrations',
    text: 'Integrations',
  },
]);
