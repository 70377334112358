export const translations: {[key: string]: {[key: string]: string}} = {
  EN: {
    pass_changed_success: 'Password changed successfully',
    connect_tg: 'Connect Telegram bot',
    connect_vb: 'Connect Viber bot',
    reset_pass: 'Reset password',
    notifiable_sources: 'Notifiable Sources',
    notifications: 'Notifications',
    audit: 'History audit',
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    review_audit: 'Review processing history',
    USER: 'User',
    SYSTEM: 'System',
    actor: 'Actor',
    action: 'Action',
    search_by_phone: 'Search by phone number',
    search_by_reviewID: 'Search by review ID',
    chat_error_no_user: 'The chat with the user was not found, maybe he did not connect or deleted the bot',
    chat_label: 'Chat with the client',
    comment_internal: 'Internal comment',
    changes_not_saved_modal: 'Changes are not saved. Are you sure you want to leave the page?',
    go_back: 'Go back',
    all_items_uploaded: 'All items uploaded',
    login_title: 'Sign in',
    login_recovery_password: 'Password recovery',
    add_comment: 'Add a comment',
    add_button: 'Add',
    add_option: 'Add option',
    add_option_button: 'Add Variant',
    apply_filters_button: 'Apply filters',
    apply_table_settings: 'Apply table settings',
    are_you_sure: 'Are you sure?',
    attached_files: 'Attached files',
    bot_is_not_connected: 'Bot is not connected',
    cancel_button: 'Cancel',
    cant_decided: "Can't solve",
    change_new_form_button: 'Change new form',
    changes_saved_successfully: 'Changes saved successfully',
    channels_for_notifications: 'Channels for notification',
    channels_of_connections: 'Channels of connection',
    chat_error_description: 'An error occurred while sending a message',
    chat_history_is_empty: 'Chat history is empty',
    chat_is_finished: 'Chat finished',
    choose_file_button: 'Select a file',
    choose_period: 'Choose period',
    client_form_scale_field_h3: 'Your impression of our company',
    close_button: 'Close',
    close_editing_button: 'Close editing',
    closed_auto_no_replay: 'Closed automatically due to no client’s answer',
    color_picker_add_button: 'Add color',
    comment_placeholder: 'Enter comment',
    companies_columns_settings_confirmation_before_save_modal_title: 'Are you sure you want to edit the column settings for reviews page?',
    companies_design_add_color_button: 'Add color',
    companies_design_add_color_placeholder: 'Enter color name',
    companies_design_add_font_button: 'Add font',
    companies_design_add_font_placeholder: 'Enter font name',
    companies_design_colors_text: 'Colors',
    companies_design_confirmation_before_save_modal_title: 'Are you sure that you want to edit company information?',
    companies_design_fonts_text: 'Fonts',
    companies_design_logos_add_button: 'Upload file',
    companies_design_logos_add_placeholder: 'Enter logo name',
    companies_design_logos_text: 'Images',
    companies_documents_add_localization: 'Localization',
    companies_documents_add_name_label: 'Name',
    companies_documents_add_name_placeholder: 'Enter document name',
    companies_documents_add_title: 'Adding a document',
    companies_documents_add_title_editing: 'Document editing',
    companies_documents_file_caption: 'File',
    companies_documents_file_format: 'Format',
    companies_documents_file_max_size: 'Maximum file size',
    companies_documents_file_not_loaded: 'not loaded',
    companies_documents_file_save_button: 'Upload file',
    companies_documents_file_text_editor_label: 'Document text',
    companies_documents_file_text_editor_placeholder: 'Enter document text',
    companies_documents_table_add_document_button: 'Add Document',
    companies_documents_table_delete_text: 'Are you sure you want to delete the document?',
    companies_documents_table_delete_title: 'Deleting a document',
    companies_documents_table_header_edit_date: 'Date of change',
    companies_documents_table_header_edit_languages: 'Languages',
    companies_documents_table_header_name: 'Name',
    companies_form_add_anonymous_checkbox_no: 'No answers cannot be anonymous.',
    companies_form_add_anonymous_checkbox_yes: 'Yes answers can be anonymous',
    companies_form_add_anonymous_label: 'Possibility to leave anonymous reviews',
    companies_form_add_create_title: 'Add a feedback form',
    companies_form_add_edit_title: 'Editing a feedback form',
    companies_form_add_link_label: 'Form link',
    companies_form_add_link_placeholder: 'Enter the link to the application form',
    companies_form_add_main_language_label: 'Primary language',
    companies_form_add_main_language_placeholder: 'Not selected',
    companies_form_add_multi_languages_label: 'Multilingual',
    companies_form_add_multi_languages_placeholder: 'Choice of languages',
    companies_form_add_name_label: 'Name of the application form',
    companies_form_add_name_placeholder: 'Enter the name of the application form',
    companies_form_add_status_not_published: 'Not published',
    companies_form_add_status_published: 'Published',
    companies_form_add_status_title: 'Status',
    companies_form_add_tied_node_label: 'Tied node',
    companies_form_add_tied_node_label_second: 'The second is responsible in the node',
    companies_form_add_tied_node_placeholder: 'Node',
    companies_form_constructor_add_an_option: 'Add an option',
    companies_form_constructor_add_document_button: 'Add a document',
    companies_form_constructor_add_field_to_series: 'Add to series of fields',
    companies_form_constructor_add_question_button: 'Add a question',
    companies_form_constructor_background_color: 'Background',
    companies_form_constructor_background_color_for_text_title: 'Background color for text',
    companies_form_constructor_background_color_title: 'Background color',
    companies_form_constructor_caption_checkbox_label: 'Select document',
    companies_form_constructor_caption_placeholder: 'Select the document you need',
    companies_form_constructor_caption_title: 'Privacy policy',
    companies_form_constructor_checked_elements_border_radius_title: 'Checked radio buttons border radius',
    companies_form_constructor_checked_elements_checked_size_title: 'Radio button checked size',
    companies_form_constructor_choice_description_info: 'You can display a text field if you want user extend response when this option is selected',
    companies_form_constructor_choice_description_placeholder: 'Choice description placeholder...',
    companies_form_constructor_choice_description_required: 'Choice description required',
    companies_form_constructor_choice_description_title: 'Choice description caption...',
    companies_form_constructor_choose_form_font_placeholder: 'Select the questionnaire font',
    companies_form_constructor_choose_logo_button: 'Choose a logo',
    companies_form_constructor_choose_logo_label: 'Logo',
    companies_form_constructor_choose_logo_title: 'Choose a logo from the uploaded company images',
    companies_form_constructor_choose_main_mascot_button: 'Choose a mascot',
    companies_form_constructor_choose_main_mascot_label: 'Main mascot',
    companies_form_constructor_choose_main_mascot_title: 'Choose a mascot from the uploaded company images',
    companies_form_constructor_chose_field_placeholder: 'Select field',
    companies_form_constructor_communication_channels_call: 'Call',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label: 'Auto answer',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder: 'Enter auto answer text',
    companies_form_constructor_communication_channels_email: 'Email',
    companies_form_constructor_communication_channels_label: 'Choose what the communication channels will be available on the form page',
    companies_form_constructor_communication_channels_placeholder: 'Explanation that you need to choose a communication channel for feedback',
    companies_form_constructor_communication_channels_sms: 'SMS',
    companies_form_constructor_communication_channels_sub_label: 'Select messengers used for replies',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Channels of connection',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer: 'Without answer',
    companies_form_constructor_country_code_not_editable: 'Country code not editable',
    companies_form_constructor_disabled_send_button_background_color: 'Disabled button\'s background color',
    companies_form_constructor_display_node_address: 'Display node address ',
    companies_form_constructor_display_scale_background_color: 'Display the background color of the main scale',
    companies_form_constructor_display_scale_background_color_info: 'Background color must depend of the main scale selected value',
    companies_form_constructor_display_scale_mascot: 'Display the mascot of the main scale',
    companies_form_constructor_display_scale_mascot_info: 'Mascot must depend of the main scale selected value',
    companies_form_constructor_document_description: 'Select the document you need',
    companies_form_constructor_document_description_placeholder: 'Document description',
    companies_form_constructor_document_empty_list: "Company don't have documents",
    companies_form_constructor_document_placeholder: 'Select document',
    companies_form_constructor_document_title: 'Document',
    companies_form_constructor_elements_border_width_title: 'Elements border width',
    companies_form_constructor_elements_color_title: 'Color of elements',
    companies_form_constructor_email_placeholder: 'Enter your email',
    companies_form_constructor_email_title: 'E-mail',
    companies_form_constructor_form_font_title: 'Questionnaire font',
    companies_form_constructor_form_name_label: 'Name of form',
    companies_form_constructor_form_name_placeholder: 'Enter form name',
    companies_form_constructor_full_answer_placeholder: 'We would be happy if you left an e-mail for communication',
    companies_form_constructor_full_answer_title: 'Detailed response',
    companies_form_constructor_info_additional_field_placeholder: 'In this field, enter the placeholder for additional field of the question you want to ask the respondents.',
    companies_form_constructor_info_placeholder: 'In this field enter the placeholder of the question you want to ask the respondents.',
    companies_form_constructor_info_question: 'In this field enter the question you want to ask the respondents.',
    companies_form_constructor_info_scale_max: 'In this field enter a short description for the maximum scale value.',
    companies_form_constructor_info_scale_min: 'In this field enter a short description for the minimum scale value.',
    companies_form_constructor_info_step_text: 'In this field, enter the text you want to show as a step text.',
    companies_form_constructor_info_thank_you_page_text: 'In this field enter the text you want to display on Thank you page.',
    companies_form_constructor_info_thank_you_page_title: 'In this field enter the title you want to display on Thank you page.',
    companies_form_constructor_key_question: 'Key question',
    companies_form_constructor_language_label: 'Form language',
    companies_form_constructor_language_placeholder: 'Language',
    companies_form_constructor_localization_label: 'Localization for the whole form',
    companies_form_constructor_logo_place_center: 'Centered',
    companies_form_constructor_logo_place_label: 'Logo location',
    companies_form_constructor_logo_place_left: 'Left',
    companies_form_constructor_logo_place_right: 'On right',
    companies_form_constructor_main_question: 'Leave your rating of the company',
    companies_form_constructor_main_scale: 'Main scale',
    companies_form_constructor_mascot: 'Mascot',
    companies_form_constructor_max_placeholder: 'Extremely incredible',
    companies_form_constructor_min_placeholder: 'Unlikely',
    companies_form_constructor_name_field_required: 'Name input field required',
    companies_form_constructor_name_placeholder: 'What is your name',
    companies_form_constructor_name_title: 'Name',
    companies_form_constructor_next_field: 'Next field',
    companies_form_constructor_not_checked_elements_color_title: 'Color of not checked elements elements',
    companies_form_constructor_one_from_list_placeholder: 'How long have you been visiting our establishment?',
    companies_form_constructor_one_from_list_title: 'One of the list',
    companies_form_constructor_phone_placeholder: 'Enter your phone number',
    companies_form_constructor_phone_title: 'Phone number',
    companies_form_constructor_prev_field: 'Prev field',
    companies_form_constructor_privacy_policy_label: 'Select the document you need',
    companies_form_constructor_privacy_policy_placeholder: 'Select document',
    companies_form_constructor_privacy_policy_title: 'Privacy policy',
    companies_form_constructor_quantity_button_signs_color: 'Button signs color',
    companies_form_constructor_quantity_button_signs_color_placeholder: 'Select button sings color',
    companies_form_constructor_quantity_buttons_background_color: 'Buttons background color',
    companies_form_constructor_quantity_buttons_background_color_placeholder: 'Select buttons background color',
    companies_form_constructor_quantity_default_value_title: 'Default value',
    companies_form_constructor_quantity_max_value_title: 'Max value',
    companies_form_constructor_quantity_min_value_title: 'Min value',
    companies_form_constructor_quantity_placeholder: 'How much times did you come to our place?',
    companies_form_constructor_quantity_title: 'Quantity',
    companies_form_constructor_required_question: 'Mandatory question',
    companies_form_constructor_scale_change_design_button: 'Change the design of the scale',
    companies_form_constructor_scale_title: 'Scale',
    companies_form_constructor_select_field: 'Select field',
    companies_form_constructor_send_button: 'Button',
    companies_form_constructor_send_button_background_color: "Button's background color",
    companies_form_constructor_send_button_link: "Button's link",
    companies_form_constructor_send_button_text: "Button's text",
    companies_form_constructor_send_button_text_color: "Button's text color",
    companies_form_constructor_send_button_title: 'Send button',
    companies_form_constructor_send_disabled_button_text_color: 'Disabled button\'s text color',
    companies_form_constructor_set_default_agreed: 'Set default agreed',
    companies_form_constructor_several_from_list_placeholder: 'How long have you been visiting our establishment?',
    companies_form_constructor_several_from_list_title: 'A few from the list',
    companies_form_constructor_show_additional_field: 'Show additional field',
    companies_form_constructor_show_name_input_field: 'Show name input field',
    companies_form_constructor_show_text_in_two_rows: 'Show text in two rows',
    companies_form_constructor_signature_label: 'Signature',
    companies_form_constructor_signature_max_label: 'max',
    companies_form_constructor_signature_min_label: 'min',
    companies_form_constructor_start_page_button_width: 'Start page button width',
    companies_form_constructor_status_label: 'Status',
    companies_form_constructor_step_text: 'Step text',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Write step text',
    companies_form_constructor_step_text_underline_color: 'Step text underline color',
    companies_form_constructor_step_text_underline_color_placeholder: 'Select step text underline color',
    companies_form_constructor_submit_button: 'Save form',
    companies_form_constructor_tab_diagram: 'Diagram',
    companies_form_constructor_tab_field: 'Field',
    companies_form_constructor_tab_fields: 'Fields',
    companies_form_constructor_tab_settings: 'Settings',
    companies_form_constructor_text: 'Text...',
    companies_form_constructor_thank_you_page_field_title: 'Thank you page',
    companies_form_constructor_thank_you_page_text: 'Thank you page text',
    companies_form_constructor_thank_you_page_text_first_line: 'Thank you page text first line',
    companies_form_constructor_thank_you_page_text_second_line: 'Thank you page text second line',
    companies_form_constructor_thank_you_page_title: 'Thank you page title',
    companies_form_constructor_title: 'Form constructor',
    companies_form_constructor_title_additional_field_placeholder: 'Another',
    companies_form_constructor_title_additional_field_placeholder_title: 'Additional field placeholder',
    companies_form_constructor_title_placeholder: 'We would be happy if you leave a review',
    companies_form_constructor_title_placeholder_title: 'Placeholder',
    companies_form_constructor_title_title: 'Caption',
    companies_form_constructor_upload_files_label: 'Choose what types of file uploads will be available on the form page',
    companies_form_constructor_upload_files_placeholder: 'Explanation that it is possible to attach files to a form',
    companies_form_constructor_upload_files_title: 'File upload',
    companies_form_download_qr: 'Download QR',
    companies_form_scale_constructor_change_background_checkbox_label: 'Change background when rating changes',
    companies_form_scale_constructor_change_background_select_label: 'Background color',
    companies_form_scale_constructor_change_background_select_placeholder: 'Choose a color',
    companies_form_scale_constructor_change_mascot_checkbox_label: 'Change mascot when rating changes',
    companies_form_scale_constructor_change_mascot_select_label: 'Mascots',
    companies_form_scale_constructor_change_mascot_select_placeholder: 'Choose a mascot',
    companies_form_scale_constructor_choose_color_placeholder: 'Choose a color',
    companies_form_scale_constructor_file_not_uploaded: 'file not uploaded',
    companies_form_scale_constructor_main_gradient_colors_label: 'Basic gradient colors',
    companies_form_scale_constructor_maximum_value_label: 'Maximum value',
    companies_form_scale_constructor_maximum_value_placeholder: 'Enter the maximum value',
    companies_form_scale_constructor_minimum_value_label: 'Enter a default value',
    companies_form_scale_constructor_minimum_value_placeholder: 'Default value',
    companies_form_scale_constructor_numbers_chosen_colors_label: 'The color of the selected answer',
    companies_form_scale_constructor_numbers_colors_label: 'Color of numbers',
    companies_form_scale_constructor_pointer_color_label: 'Pointer color',
    companies_form_scale_constructor_show_default_value: 'Show default value',
    companies_form_scale_constructor_signature_text_color_label: 'Signature text color',
    companies_form_scale_constructor_star_chosen_color_label: 'The color of the selected star',
    companies_form_scale_constructor_star_not_chosen_color_label: 'The fill color of the unselected star',
    companies_form_scale_constructor_star_not_chosen_contour_color_label: 'Outline star outline color',
    companies_form_scale_constructor_tab_title: 'Scale',
    companies_form_scale_constructor_title: 'Scale constructor',
    companies_form_select_color_placeholder: 'Select color',
    companies_form_show_node_code: 'Show node code',
    companies_form_table_add_form_button: 'Add form',
    companies_form_table_constructor_button: 'Form constructor',
    companies_form_table_copy_button: 'Copying a Form',
    companies_form_table_copy_confirm_text: 'Are you sure you want to copy the form?',
    companies_form_table_delete_button: 'Deleting a Form',
    companies_form_table_delete_confirm_text: 'Are you sure you want to delete the form?',
    companies_form_table_edit_button: 'Edit',
    companies_form_table_header_feedbacks: 'Reviews',
    companies_form_table_header_last_edit: 'Last modified',
    companies_form_table_header_link: 'Link',
    companies_form_table_header_name: 'Form name',
    companies_form_table_header_status: 'Status',
    companies_form_table_new_form_language_placeholder: 'Select new form language',
    companies_form_table_new_form_language_title: 'New form language',
    companies_form_table_new_form_name_placeholder: 'Enter new form name',
    companies_form_table_new_form_name_title: 'New form name',
    companies_form_table_new_node_language_placeholder: 'Select new node language',
    companies_form_table_new_node_language_title: 'New node language',
    companies_form_table_new_node_name_placeholder: 'Enter new node name',
    companies_form_table_new_node_name_title: 'New node name',
    companies_form_table_node_to_copy: 'Where do you want to copy the form?',
    companies_form_table_thank_button: 'Thank-you-page',
    companies_form_table_title: 'Company node feedback forms',
    companies_form_use_as_default_form: 'Use as default form',
    companies_form_with_steps: 'Form with steps',
    companies_info_add_field_button: 'Add field',
    companies_info_after_save_modal_confirm_button: 'Edit company data',
    companies_info_after_save_modal_title: 'The company was successfully established',
    companies_info_choose_file_button: 'Select a file',
    companies_info_company_domain_label: 'Company domain',
    companies_info_company_domain_placeholder: 'Enter company domain',
    companies_info_company_extra_field_name_placeholder: 'Enter field name',
    companies_info_company_extra_field_value_placeholder: 'Enter field value',
    companies_info_company_name_label: 'The name of the company',
    companies_info_company_name_placeholder: 'Enter company name',
    companies_info_confirmation_before_save_modal_title: 'Are you sure that you want to edit company information?',
    companies_info_file_max_size: 'JPG GIF or PNG. Maximum size 800K',
    companies_info_file_not_selected: 'File not selected',
    companies_info_submit_create_button: 'Save the company',
    companies_info_submit_edit_button: 'Save changes',
    companies_info_tab_base_info: 'Basic information',
    companies_info_tab_design: 'Design',
    companies_info_tab_documents: 'Documents',
    companies_info_tab_issues_statuses_and_types: 'Task statuses and types',
    companies_info_tab_review_table_columns_settings: 'Columns settings',
    companies_info_tab_sources: 'Sources',
    companies_info_tab_templates: 'Templates',
    companies_info_title_add: 'Add company',
    companies_info_title_edit: 'Edit company',
    companies_issue_statuses_and_types_add_status_button: 'Add status',
    companies_issue_statuses_and_types_add_status_placeholder: 'Enter status name',
    companies_issue_statuses_and_types_statuses_text: 'Statuses',
    companies_issue_statuses_and_types_type_add_button: 'Add type',
    companies_issue_statuses_and_types_type_add_placeholder: 'Enter type name',
    companies_issue_statuses_and_types_types_text: 'Types',
    companies_issues_and_types_confirmation_before_save_modal_title: 'Are you sure you want to edit tasks statuses and types?',
    companies_main_add_company_button: 'Add company',
    companies_main_delete_description: 'Are you sure you want to delete the company?',
    companies_main_delete_title: 'Deleting a company',
    companies_main_forms_button: 'Contact forms',
    companies_main_structure_button: 'Structure',
    companies_main_table_company_name_header: 'Company',
    companies_main_title: 'Companies',
    companies_review_table_columns_settings_title: 'Columns',
    companies_review_title: 'Review',
    companies_reviews_table_header_button_details: 'Details',
    companies_reviews_table_header_contact: 'Channel',
    companies_reviews_table_header_contact_type: 'Communication type',
    companies_reviews_table_header_create: 'Date of creation',
    companies_reviews_table_header_estimate: 'Score',
    companies_reviews_table_header_files: 'Attached files',
    companies_reviews_table_header_key_answer: 'Key answer',
    companies_reviews_table_header_language: 'Language',
    companies_reviews_table_header_location: 'Locations',
    companies_reviews_table_header_source: 'Source',
    companies_reviews_table_header_status: 'Status',
    companies_reviews_table_title: 'Reviews',
    companies_source_must_be_unique_text: 'must be unique',
    companies_source_name_label: 'Name of the source',
    companies_source_name_max_length_error: 'The source name must be less than 100 characters',
    companies_sources_add_source_button: 'Add source',
    companies_sources_confirmation_before_save_modal_title: 'Are you sure you want to edit sources?',
    companies_sources_create_new_source_title: 'Create new source',
    companies_sources_create_source_text: 'Create source',
    companies_sources_placeholder: 'Enter source name',
    companies_sources_title: 'Sources',
    companies_structure: 'Structure',
    companies_structure_create_accountable: 'Responsible(s)',
    companies_structure_create_address_label: 'The address',
    companies_structure_create_address_placeholder: 'Enter address',
    companies_structure_create_advanced_settings: 'Advanced settings',
    companies_structure_create_edit_title: 'Edit node',
    companies_structure_create_email: 'E-mail',
    companies_structure_create_google_place_id_label: 'Google Place ID',
    companies_structure_create_integration_alpha_name_label: 'Alpha-name',
    companies_structure_create_integration_key_label: 'Key',
    companies_structure_create_integration_title: 'Integration settings',
    companies_structure_create_modal_title: 'Are you sure what you want to create node?',
    companies_structure_create_node_code_label: 'Node code',
    companies_structure_create_node_code_placeholder: 'Enter node code',
    companies_structure_create_node_name_label: 'Node name',
    companies_structure_create_node_name_placeholder: 'Enter node name',
    companies_structure_create_sms: 'SMS',
    companies_structure_create_telegram: 'Telegram',
    companies_structure_create_tied_form_placeholder: 'Form',
    companies_structure_create_tied_node_label: 'Tied node',
    companies_structure_create_tied_node_placeholder: 'Node',
    companies_structure_create_title: 'Create node',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay: "Don't use an expiration timer",
    companies_structure_lang_detection: 'Use automatic form language detection',
    companies_structure_table_accountable: 'Responsible',
    companies_structure_table_add_node_button: 'Add node',
    companies_structure_table_delete_description_1: 'Are you sure you want to delete the node',
    companies_structure_table_delete_description_2: 'company structures',
    companies_structure_table_delete_description_3: 'This will remove all nested nodes',
    companies_structure_table_delete_title: 'Deleting a company structure node',
    companies_structure_table_header_name: 'Nodes',
    companies_structure_title: 'Company structure',
    companies_structure_update_modal_title: 'Are you sure what you want to edit node?',
    company: 'Company',
    company_main_info_delete_title: 'Removal of the company',
    company_main_info_edit_title: 'Edit basic information about the company \ncompany designcompany documents',
    company_main_info_go_to_company_structure: 'Go to company structure',
    company_structure_delete_button_title: 'Deleting a node',
    company_structure_edit_button_title: 'Node editing',
    confirm_button: 'Confirm',
    connect_button: 'To plug',
    continue_editing_button: 'Continue editing',
    continue_review_dialog_button: 'Continue the dialogue',
    copy_button: 'Copy',
    copy_error: '* An error occurred while copying',
    create_button: 'Create',
    data_save_error: '* An error occurred while saving data',
    delete_button: 'Delete',
    delete_error: '* An error occurred while deleting',
    document: 'Document',
    done_button: 'Done',
    edit_button: 'Edit',
    email: 'Email',
    email_label: 'Email',
    email_placeholder: 'Enter email',
    employee_profile_title: 'Employee profile',
    enter_button: 'Enter',
    enter_forgot_button: 'Recover password',
    enter_task_name: 'Enter task name',
    error: '* An error occurred',
    error_text: 'Error text',
    error_title: 'Error',
    expecting_reply_from_client: 'Waiting for client’s answer',
    filter_title_sources: 'Sources',
    filter_title_status: 'Statuses',
    filters: 'Filters',
    finish_review_dialog_button: 'Finish the dialogue',
    finish_review_dialog_modal_description: 'Are you sure you want to finish the dialogue?',
    finish_review_dialog_modal_title: 'Finish the dialogue',
    form: 'Form',
    form_constructor_connection_case_label: 'Option',
    form_constructor_connection_case_placeholder: 'Select option',
    form_constructor_connection_go_to: 'go to',
    form_constructor_connection_render_minifield_connection_always: 'Always',
    form_constructor_connection_render_minifield_connection_type_texts_always: 'All other cases',
    form_constructor_connection_render_minifield_connection_type_texts_if: 'If is',
    form_constructor_connection_select_field: 'Select field',
    form_constructor_connection_select_with_any_items_label: 'Next field',
    form_constructor_connection_select_with_any_items_options_1_question: 'Not selected',
    form_constructor_connection_select_with_any_items_placeholder: 'Select field',
    form_constructor_connection_statement_label: 'Rule',
    form_constructor_connection_statement_placeholder: 'Select rule',
    form_constructor_delete_field_modal_description: 'Are you sure you want to delete this field?',
    form_constructor_delete_field_modal_title: 'Warning!',
    form_constructor_diagram_fields_list_is_empty: 'Fields list is empty',
    form_constructor_fields_form_content: 'Content',
    form_constructor_fields_form_documents: 'Documents',
    form_constructor_fields_form_no_fields_selected: 'No selected field',
    form_constructor_fields_form_send_button: 'Send button',
    form_constructor_fields_form_thank_you_page: 'Thank you page',
    form_copy_save_error: '* A form with the same name already exists on the selected node',
    form_language_error: '* You already have form with selected language',
    form_name_error: '* Form name should be unique',
    form_save_error: '* An error occurred while saving the form',
    from: 'From',
    full_name_label: 'Full name of the employee',
    full_name_placeholder: 'Enter employee name',
    generate_button: 'Generate',
    go_to_forms_page_button_title: 'Go to feedback forms',
    go_to_issues_create: 'Go to task create',
    go_to_reviews_page_button_title: 'Go to reviews',
    go_to_task_create: 'Go to task create',
    in_progress: 'In progress',
    issues_add_issue: 'Add an task',
    issues_form_company_label: 'Company',
    issues_form_company_placeholder: 'Select a company',
    issues_form_connect_issue_label: 'Connect task',
    issues_form_connect_issue_placeholder: 'Select a task',
    issues_form_create_new_issue_status: 'Create new task status',
    issues_form_create_new_issue_status_title: 'Create status',
    issues_form_create_new_issue_type: 'Create new task type',
    issues_form_create_new_issue_type_title: 'Create type',
    issues_form_must_be_unique: 'must be unique',
    issues_form_name_label: 'Name of task',
    issues_form_name_placeholder: 'Enter form name',
    issues_form_node_label: 'Node',
    issues_form_node_placeholder: 'Select a node',
    issues_form_priority_label: 'Priority of task',
    issues_form_priority_placeholder: 'Select a priority',
    issues_form_responsible_label: 'Responsible',
    issues_form_responsible_placeholder: 'Select a responsible',
    issues_form_review_label: 'Review',
    issues_form_review_placeholder: 'Select a review',
    issues_form_status_label: 'Status of task',
    issues_form_status_name_label: 'Name of task status',
    issues_form_status_placeholder: 'Select a status',
    issues_form_type_label: 'Type of task',
    issues_form_type_name_label: 'Name of task type',
    issues_form_type_placeholder: 'Select a type of task',
    issues_save_modal_create_title: 'Are you sure what you want to create task?',
    issues_save_modal_edit_title: 'Are you sure what you want to edit task?',
    issues_search_by_name: 'Search task by name',
    issues_sub_header_create_issue_title: 'Create task',
    issues_sub_header_create_task_title: 'Creating a task',
    issues_sub_header_edit_issue_title: 'Edit task',
    issues_sub_header_title: 'Tasks',
    issues_table_empty: 'Select company to display tasks',
    issues_table_header_company: 'Company',
    issues_table_header_date: 'Date of creation',
    issues_table_header_name: 'Name',
    issues_table_header_node: 'Node',
    issues_table_header_priority: 'Priority',
    issues_table_header_status: 'Status',
    issues_table_header_type: 'Task type',
    language: 'Language',
    languages_add_name_placeholder: 'Enter name',
    languages_add_short_name_label: 'Short name',
    languages_add_short_name_placeholder: 'Enter short name',
    languages_add_title: 'Adding a language',
    languages_table_add_language_button: 'Add language',
    languages_table_header_full_name: 'Full name',
    languages_table_header_short_name: 'Short name',
    languages_table_modal_delete_buttons_label_end: 'language?',
    languages_table_modal_delete_buttons_label_start: 'Are you sure you want to delete',
    languages_table_modal_delete_title: 'Deleting language',
    languages_table_title: 'Languages',
    leave_the_page: 'Leave the page',
    loading_error: '* An error occurred while loading data',
    login_recovery_auth: 'Return',
    login_recovery_complete: 'A message with instructions on how to reset your password will be sent to your email address shortly',
    mass_mailing_type_by_addresses: 'Address mailing',
    mass_mailing_type_by_company: 'By company',
    max_size_error: '* Maximum allowed file size exceeded',
    max_size_error_25: '* Maximum allowed file size is 25Mb',
    max_table_column_settings_apply_warning: 'Max 6 active settings',
    more_detailed: 'More detailed',
    name: 'Name',
    new_task_name: 'New task name',
    next_button: 'Next',
    no_options_available: 'No options available',
    node: 'Node',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes',
    not_decided: 'Not solved',
    not_viewed: 'Not viewed',
    nothing_find: 'Not found',
    login_recovery_password_title: 'Recovery of a lost password',
    open_issue_title: 'Open task',
    open_language_title: 'Open language',
    open_permission_title: 'Open permission',
    open_review_title: 'Open review',
    open_role_title: 'Open role',
    open_user_title: 'Open user',
    other: 'Other',
    overdue_status_text: 'Overdue',
    pagination_page_number_placeholder: 'Page number',
    pagination_title: 'Lines per page',
    password: 'Password',
    password_confirmation_label: 'Repeat password',
    password_confirmation_placeholder: 'Enter password again',
    password_label: 'Password',
    password_placeholder: 'Enter password',
    permission_create_title: 'Create permission',
    permission_edit_title: 'Edit permission',
    permission_name_placeholder: 'Enter permission name',
    permission_name_title: 'Permission name',
    permission_resource_placeholder: 'Enter permission resource',
    permission_resource_title: 'Resource',
    permissions_main_add_permission_button: 'Add permission',
    permissions_main_delete_description: 'Are you sure you want to delete the permission?',
    permissions_main_delete_title: 'Deleting a permission',
    permissions_main_permissions_button: 'Permissions',
    permissions_main_table_create_header: 'Create',
    permissions_main_table_delete_header: 'Delete',
    permissions_main_table_permission_header: 'Permission name',
    permissions_main_table_read_header: 'Read',
    permissions_main_table_resource_header: 'Resource',
    permissions_main_table_update_header: 'Update',
    permissions_main_title: 'Permissions',
    phone: 'Phone',
    phone_number_label: 'Phone number',
    phone_number_placeholder: 'Enter phone number',
    photo: 'Photo',
    preview_button: 'Preview',
    privacy_policy: 'user agreement',
    privacy_policy_confirm: 'I agree with',
    record: 'Audio',
    refresh_all_fitlers: 'Clear all filters',
    registration_profile_completion: 'Profile completion',
    reopened: 'Opened again',
    reset_reviews_filter: 'Reset filter',
    reset_table_settings: 'Reset table settings',
    review_choose_template: 'Choose a response template',
    review_created_at: 'Review date and time',
    review_dialog_finished_text: 'The dialogue was finished',
    review_form_name: 'Name of form',
    review_form_source: 'Form source',
    review_handle_continue_dialog_error_message: 'Something is wrong. Please, try again later.',
    review_message_input_placeholder: 'Write your message',
    review_no_asscociated_issues: 'No associated tasks',
    review_no_associated_tasks: 'No associated tasks',
    review_node_code: "Node's code",
    review_node_name: "Node's name",
    review_on_submit_3_error_message: 'The client already has a dialogue with another manager. Please, try again later.,',
    review_open_task_button_text: 'Open task',
    review_page_type_of_task: 'Type of task',
    review_review_id: 'Review id',
    review_save_modal_title: 'Are you sure that you want to edit review?',
    review_updated_at: 'Processing date,',
    reviews_page_choose_company_title: 'Select a company from the drop-down list',
    reviews_table_comment_column_title: 'Comment',
    reviews_table_email_column_title: 'Email',
    reviews_table_filter_title: 'Filter',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_node_column_title: 'Node',
    reviews_table_parent_node_column_title: 'Parent node',
    reviews_table_phone_column_title: 'Phone number',
    reviews_table_review_id_column_title: 'Review ID',
    reviews_table_ukrposhta_settings_button: 'Unprocesed reviews',
    reviews_table_user_name_column_title: 'Name',
    reviewType: 'Review type',
    role_create_title: 'Create role',
    role_edit_title: 'Edit role',
    role_name_placeholder: 'Enter role name',
    role_name_title: 'Role name',
    role_page_create_title: 'Create role',
    role_permissions: 'Permissions',
    role_select_permissions_placeholder: 'Select permissions',
    roles_main_add_role_button: 'Add role',
    roles_main_delete_description: 'Are you sure you want to delete the role?',
    roles_main_delete_title: 'Deleting a role',
    roles_main_permissions_button: 'Permissions',
    roles_main_table_user_role_header: 'Role name',
    roles_main_title: 'Roles',
    roles_part_analytic: 'Analytic',
    roles_part_companies: 'Companies',
    roles_part_documents: 'Documents',
    roles_part_forms: 'Forms',
    roles_part_languages: 'Languages',
    roles_part_mass_mailings: 'Mass mailings',
    roles_part_nodes: 'Nodes',
    roles_part_reviews: 'Reviews',
    roles_part_users: 'Users',
    roles_sub_permission_create: 'Create',
    roles_sub_permission_delete: 'Delete',
    roles_sub_permission_read: 'Read',
    roles_sub_permission_update: 'Update',
    save_button: 'Save',
    save_changes_button: 'Save Changes',
    save_document_button: 'Save',
    save_draft_button: 'Save draft',
    save_field_button: 'Save field',
    save_form_button: 'Save form',
    save_form_without_changes_button: 'Save form without changes',
    search: 'Search...',
    search_for_name: 'Search by name',
    search_for_email: 'Search by email',
    select_company: 'Select company',
    select_form: 'Select form',
    select_language: 'Select language',
    select_multiple_sources: 'Select sources',
    select_multiple_statuses: 'Select statuses',
    select_node: 'Select node',
    select_status: 'Select status',
    select_type: 'Select type',
    send_button: 'Send',
    sidebar_analytic: 'Analytics',
    sidebar_companies: 'Companies',
    sidebar_exit: 'Quit profile',
    sidebar_issues: 'Tasks',
    sidebar_languages: 'Languages',
    sidebar_profile: 'Profile',
    sidebar_reviews: 'Reviews',
    sidebar_tasks: 'Tasks',
    sidebar_users: 'Users',
    sms: 'SMS',
    social_networks_module_text: 'Choose a convenient way for you to communicate with us',
    solution_not_required: 'Solution \n not required',
    solved: 'Solved',
    source: 'Source',
    status: 'Status of review',
    status_code: 'Status code',
    table_columns_settings_comment_option: 'Comment',
    table_columns_settings_communication_channel_option: 'Communication type',
    table_columns_settings_company_option: 'Company',
    table_columns_settings_created_at_option: 'Created at',
    table_columns_settings_email_option: 'Email',
    table_columns_settings_estimation_option: 'Score',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_language_option: 'Language',
    table_columns_settings_name_option: 'Name',
    table_columns_settings_node_option: 'Node',
    table_columns_settings_parent_node_option: 'Parent node',
    table_columns_settings_phone_option: 'Phone number',
    table_columns_settings_reviewId_option: 'Review ID',
    table_columns_settings_source_option: 'Source',
    table_columns_settings_status_option: 'Status',
    table_settings: 'Table settings',
    task_form_category_label: 'Category of task',
    task_form_create_task: 'Create task',
    task_form_list_category_label: 'Category',
    task_form_list_subcategory_label: 'Subcategory',
    task_form_list_type_label: 'Type',
    task_form_name_label: 'Name of the task',
    task_form_priority_label: 'Task priority',
    task_form_status_label: 'Task status',
    task_form_subcategory_label: 'Subcategory of task',
    task_form_type_label: 'Type of task',
    telegram: 'Telegram',
    to: 'To',
    to_plug_button: 'To plug',
    turn_off_button: 'Disable',
    upload: 'Upload',
    upload_color_button: 'Upload color',
    uploading_files: 'Uploading files',
    user_email_placeholder: 'Enter email',
    user_email_title: 'Email',
    user_name_placeholder: 'Enter name',
    user_name_title: 'First and last name',
    user_new_password_placeholder: 'Enter new password',
    user_new_password_title: 'New password',
    user_old_password_placeholder: 'Enter old password',
    user_old_password_title: 'Old password',
    user_page_create: 'Create user',
    user_page_edit: 'Edit user',
    user_password_confirmation_placeholder: 'Repeat password',
    user_password_confirmation_title: 'Password confirmation',
    user_password_placeholder: 'Enter password',
    user_password_title: 'Password',
    user_role_placeholder: 'Select user roles',
    user_role_title: 'Roles',
    users_main_add_user_button: 'Add user',
    users_main_delete_description: 'Are you sure you want to delete the user?',
    users_main_delete_title: 'Deleting a user',
    users_main_roles_button: 'Roles',
    users_main_table_user_company_header: 'Company',
    users_main_table_user_email_header: 'Email',
    users_main_table_user_name_header: 'Name',
    users_main_table_user_notifications_header: 'Notifications',
    users_main_table_user_role_header: 'Role',
    users_main_title: 'Users',
    users_main_upload_users_button: 'Upload users',
    validation_constructor_at_least_2_possible_answers: '* Question must have at least 2 possible answers',
    validation_constructor_should_have_at_least_1_document: '* Form should have min one document',
    validation_constructor_should_select_file_types: '* You must select file types allowed for upload or remove this field from the form',
    validation_domain: "The domain can contain only English characters numbers '.' and '-'",
    validation_form_should_have_minimum_1: '* Form should have minimum one field',
    validation_full_type_required: '* You must select each parts of type',
    validation_invalid_email: '* Invalid email format',
    validation_invalid_link: '* Invalid link format',
    validation_more_one_accountable: '* You must select at least 1 node manager',
    validation_more_one_option: '* You must select at least 1 option',
    validation_must_fill_all_fields_for: '* You must complete all fields for',
    validation_must_fill_this_field: '* You must complete this field',
    validation_name_must_be_longer_4: '* The name is too short - must be at least 4 characters long.',
    validation_required: '* Required',
    viber: 'Viber',
    video: 'Video',
    viewed: 'Viewed',
    your_changes_lose: 'You need to save the changes. Otherwise they will be lost.',
    send_feedback: 'Send feedback',
    send_feedback_title: 'Feedback',
    send_feedback_label: 'Your wishes or comments',
    send_feedback_complete: 'Thank you for your feedback',
    sidebar_impex: 'Import/Export',
    impex_page_title: 'Import/Export',
    impex_import_tab: 'Import',
    impex_export_tab: 'Export',
    select_import_entity: 'What do you want to import?',
    start_import_button_text: 'Start import',
    choose_file_for_import: 'Choose file for import',
    import_entity_selection_title: 'To import',
    import_file_sheet_name_label: 'The name of the sheet in the import file',
    import_file_sheet_name_input_placeholder: 'Enter name of the sheet',
    interrupt_import_script_button_text: 'Interrupt import',
    import_file_format_text: 'Format must be:',
    import_progress_bar_title: 'Import progress:',
    import_users_entity_name: 'Users',
    import_nodes_entity_name: 'Nodes',
    qr_title: 'Scan the QR to further customize notifications',
    mass_mailings_channels_of_connections: 'Channels of connection',
    mass_mailings_send_button: 'Send',
    yes_button_text: 'Yes',
    no_button_text: 'No',
    confirm_send_mass_mailings_modal_title: 'Are you sure you want to make mass mailings?',
    modal_mass_mailings_sent_success_title: 'Messages sent successfully',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Error sending messages',
    mass_mailings_recipient_label: 'Recipient',
    mass_mailings_recipients_list_title: 'Recipients',
    add_at_least_one_recipient_error: 'At least one recipient must be added',
    sidebar_mass_mailings: 'Mass mailings',
    create_mass_mailings_page_title: 'Create mass mailings',
    create_mass_mailings_first_step_subheader_title: 'Mass mailing - Creating - Step 1 of 3',
    choose_mass_mailings_communication_channel: 'Choose communication channel',
    mass_mailings_add_recipient_button_text: 'Add recipient',
    download_recipients_file_label: 'Download a file with recipients',
    format: 'Format',
    choose_file: 'Choose a file',
    file_data_downloading_text: 'File data downloading',
    mass_mailings_mails_count: 'The number of mails that will be sent',
    create_sms_mass_mailings_second_step_subheader_title: 'Creating SMS-mailing - Step 2 of 3',
    create_viber_mass_mailings_second_step_subheader_title: 'Creating Viber-mailing - Step 2 of 3',
    create_sms_mass_mailings_third_step_subheader_title: 'Creating SMS-mailing - Step 3 of 3',
    create_viber_mass_mailings_third_step_subheader_title: 'Creating Viber-mailing - Step 3 of 3',
    mass_mailings_sender_account: 'Sender account',
    sms_text: 'SMS text',
    message: 'Message',
    message_text_placeholder: 'Message text',
    responsible: 'Responsible',
    responsible_id: 'Responsible ID',
    companies_form_constructor_dividing_decorative_line: 'Form dividing decorative line parameters',
    companies_form_constructor_show_form_dividing_decorative_line: 'Show form\'s dividing decorative line',
    companies_form_constructor_dividing_decorative_line_width_in_percent: 'Length of the decorative line in percent',
    companies_form_constructor_dividing_decorative_line_height: 'Height of the decorative line in pixels',
    companies_form_constructor_dividing_decorative_line_height_placeholder: 'Enter height of the decorative line',
    companies_form_constructor_dividing_decorative_line_color: 'Decorative line color',
    companies_form_constructor_dividing_decorative_line_color_placeholder: 'Choose decorative line color',
    export_entity_selection_title: 'To export',
    select_export_entity: 'What do you want to export?',
    export_button_text: 'To export',
    export_nodes_error_text: 'Nodes export error',
    export_users_error_text: 'Users export error',
    chat_tab1: 'Current review',
    chat_tab2: 'All history',
    quarter: 'Last 3 months',
    months: 'Last 1 months',
    days7: 'Last 7 days',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Phone',
    email_network: 'Email',
    whatsapp_network: 'WhatsApp',
  },
  UA: {
    pass_changed_success: 'Пароль успішно змінено',
    connect_tg: 'Підключити бот Telegram',
    connect_vb: 'Підключити бот Viber',
    notifiable_sources: 'Налаштування каналів сповіщень',
    notifications: 'Сповіщення',
    reset_pass: 'Відновлення паролю',
    audit: 'Історія обробки',
    CREATE: 'Публікація',
    UPDATE: 'Оновлення',
    DELETE: 'Видалення',
    review_audit: 'Історія обробки відгуку',
    SYSTEM: 'Система',
    USER: 'Користувач',
    actor: 'Виконавець',
    action: 'Дія',
    search_by_phone: 'Пошук за номером телефону',
    search_by_reviewID: 'Пошук за ідентифікатором відгуку',
    chat_error_no_user: 'Чат з користувачем не знайдено, можливо він не підʼєднав або видалив бота',
    chat_label: 'Чат з клієнтом',
    comment_internal: 'Внутрішній коментар',
    changes_not_saved_modal: 'Зміни не зберігаються. Ви впевнені, що бажаєте залишити сторінку?',
    go_back: 'Повернутися',
    all_items_uploaded: 'Всі елементи завантажено',
    login_title: 'Увійти',
    login_recovery_password: 'Відновлення пароля',
    add_comment: 'Додати коментар',
    add_button: 'Додати',
    add_option: 'Додати варіант',
    add_option_button: 'Додати варіант',
    apply_filters_button: 'Застосувати фільтри',
    apply_table_settings: 'Застосувати налаштування',
    are_you_sure: 'Ви впевнені?',
    attached_files: 'Прикріплені файли',
    bot_is_not_connected: "Бот не під'єднаний",
    cancel_button: 'Закрити',
    cant_decided: 'Не може бути вирішено',
    change_new_form_button: 'Змінити нову форму',
    changes_saved_successfully: 'Зміни збережено успішно',
    channels_for_notifications: 'Канали для сповіщення',
    channels_of_connections: 'Канали зворотнього звʼязку',
    chat_error_description: 'Виникла помилка під час відправлення повідомлення',
    chat_history_is_empty: 'Історія чату порожня',
    chat_is_finished: 'Чат завершено',
    choose_file_button: 'Вибрати файл',
    choose_period: 'Виберіть період',
    client_form_scale_field_h3: 'Ваші враження від нашої компанії',
    close_button: 'Закрити',
    close_editing_button: 'Закрити редагування',
    closed_auto_no_replay: 'Закрито автоматично через відсутність відповіді від клієнта',
    color_picker_add_button: 'Додати колір',
    comment_placeholder: 'Ведіть коментар, чому обрали цей статус',
    companies_columns_settings_confirmation_before_save_modal_title: 'Ви впевнені, що хочете редагувати налаштування стовпців на сторінці відгуків?',
    companies_design_add_color_button: 'Додати колір',
    companies_design_add_color_placeholder: 'Введіть назву кольору',
    companies_design_add_font_button: 'Додати шрифт',
    companies_design_add_font_placeholder: 'Введіть назву шрифта',
    companies_design_colors_text: 'Кольори',
    companies_design_confirmation_before_save_modal_title: 'Ви впевнені, що хочете редагувати інформацію про компанію?',
    companies_design_fonts_text: 'Шрифти',
    companies_design_logos_add_button: 'Завантажити файл',
    companies_design_logos_add_placeholder: 'Введіть назву логотипу',
    companies_design_logos_text: 'Зображення',
    companies_documents_add_localization: 'Мова',
    companies_documents_add_name_label: 'Назва',
    companies_documents_add_name_placeholder: 'Введіть назву документу',
    companies_documents_add_title: 'Додавання документу',
    companies_documents_add_title_editing: 'Редагування документу',
    companies_documents_file_caption: 'Файл',
    companies_documents_file_format: 'Формат',
    companies_documents_file_max_size: 'Максимальний розмір файлу',
    companies_documents_file_not_loaded: 'не завантажено',
    companies_documents_file_save_button: 'Завантажити файл',
    companies_documents_file_text_editor_label: 'Текст документу',
    companies_documents_file_text_editor_placeholder: 'Введіть текст документу',
    companies_documents_table_add_document_button: 'Додати документ',
    companies_documents_table_delete_text: 'Ви впевнені, що хочете видалити документ?',
    companies_documents_table_delete_title: 'Видалити документ',
    companies_documents_table_header_edit_date: 'Дата редагування',
    companies_documents_table_header_edit_languages: 'Мови',
    companies_documents_table_header_name: 'Назва',
    companies_form_add_anonymous_checkbox_no: 'Ні. не може бути анонімна',
    companies_form_add_anonymous_checkbox_yes: 'Так. може бути анонімна',
    companies_form_add_anonymous_label: 'Можливість залишати анонімні відгуки',
    companies_form_add_create_title: 'Додати форму зворотнього звʼязку',
    companies_form_add_edit_title: 'Редагувати форму зворотнього звʼязку',
    companies_form_add_link_label: 'Посилання на форму',
    companies_form_add_link_placeholder: 'Введіть посилання на форму',
    companies_form_add_main_language_label: 'Основна мова',
    companies_form_add_main_language_placeholder: 'Не вибрано',
    companies_form_add_multi_languages_label: 'Багатомовний',
    companies_form_add_multi_languages_placeholder: 'Виберіть мови',
    companies_form_add_name_label: 'Назва форми',
    companies_form_add_name_placeholder: 'Введіть назву форми',
    companies_form_add_status_not_published: 'Не опубліковано',
    companies_form_add_status_published: 'Опубліковано',
    companies_form_add_status_title: 'Статус',
    companies_form_add_tied_node_label: 'Зв’язаний вузол',
    companies_form_add_tied_node_label_second: 'Другий відповідальний у вузлі',
    companies_form_add_tied_node_placeholder: 'Вузол',
    companies_form_constructor_add_an_option: 'Додати варіант',
    companies_form_constructor_add_document_button: 'Додати документ',
    companies_form_constructor_add_field_to_series: 'Додати до серії полів',
    companies_form_constructor_add_question_button: 'Додати запитання',
    companies_form_constructor_background_color: 'Фон',
    companies_form_constructor_background_color_for_text_title: 'Колір тла тексту',
    companies_form_constructor_background_color_title: 'Колір фону',
    companies_form_constructor_caption_checkbox_label: 'Виберіть документ',
    companies_form_constructor_caption_placeholder: 'Виберіть потрібний документ',
    companies_form_constructor_caption_title: 'Політика конфіденційності',
    companies_form_constructor_checked_elements_border_radius_title: 'Радіус межі обранних кнопок',
    companies_form_constructor_checked_elements_checked_size_title: 'Розмір обранної кнопки',
    companies_form_constructor_choice_description_info: 'Ви можете відібразити текстове поле, якщо хочете, щоб користувач додав пояснення, коли вибрано цей варіант',
    companies_form_constructor_choice_description_placeholder: 'Виберіть шаблон опису…',
    companies_form_constructor_choice_description_required: "Обов'язковий опис вибору",
    companies_form_constructor_choice_description_title: 'Виберіть опис надпису…',
    companies_form_constructor_choose_form_font_placeholder: 'Виберіть шрифт анкети',
    companies_form_constructor_choose_logo_button: 'Виберіть логотип',
    companies_form_constructor_choose_logo_label: 'Логотип',
    companies_form_constructor_choose_logo_title: 'Виберіть логотип із завантажених файлів компанії',
    companies_form_constructor_choose_main_mascot_button: 'Виберіть маскот',
    companies_form_constructor_choose_main_mascot_label: 'Головний маскот ',
    companies_form_constructor_choose_main_mascot_title: 'Виберіть маскот із завантажених файлів компанії',
    companies_form_constructor_chose_field_placeholder: 'Виберіть файл',
    companies_form_constructor_communication_channels_call: 'Дзвінок',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label: 'Автовідповідь',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder: 'Введіть текст автовідповіді',
    companies_form_constructor_communication_channels_email: 'Пошта',
    companies_form_constructor_communication_channels_label: 'Виберіть, який канал комунікації буде доступний у формі відгуку',
    companies_form_constructor_communication_channels_placeholder: 'Пояснення, що вам потрібно вибрати канал комунікації для зворотнього звʼязку',
    companies_form_constructor_communication_channels_sms: 'SMS',
    companies_form_constructor_communication_channels_sub_label: 'Виберіть меседжери, що будуть використуватись для зворотнього звʼязку',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Канали комунації',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer: 'Без відповіді',
    companies_form_constructor_country_code_not_editable: 'Код країни не редагується',
    companies_form_constructor_disabled_send_button_background_color: 'Колір тла неактивної кнопки',
    companies_form_constructor_display_node_address: 'Відображати адресу вузла',
    companies_form_constructor_display_scale_background_color: 'Відобразити колір фону головної шкали',
    companies_form_constructor_display_scale_background_color_info: 'Колір фону залежить від обраного значення головної шкали',
    companies_form_constructor_display_scale_mascot: 'Відобразити маскот головної шкали',
    companies_form_constructor_display_scale_mascot_info: 'Маскот залежить від значення головної шкали',
    companies_form_constructor_document_description: 'Виберіть потрібний документ',
    companies_form_constructor_document_description_placeholder: 'Опис документу',
    companies_form_constructor_document_empty_list: 'Компанія ще не має документів',
    companies_form_constructor_document_placeholder: 'Виберіть документ',
    companies_form_constructor_document_title: 'Документ',
    companies_form_constructor_elements_border_width_title: 'Ширина межі елементів',
    companies_form_constructor_elements_color_title: 'Колір елементів',
    companies_form_constructor_email_placeholder: 'Ведіть свою адресу електронної пошти',
    companies_form_constructor_email_title: 'Пошта',
    companies_form_constructor_form_font_title: 'Шрифт анкети',
    companies_form_constructor_form_name_label: 'Назва форми',
    companies_form_constructor_form_name_placeholder: 'Введіть назву форми',
    companies_form_constructor_full_answer_placeholder: 'Будемо раді, якщо ви залишите свою адресу електронної пошти для звʼязку',
    companies_form_constructor_full_answer_title: 'Детальна відповідь',
    companies_form_constructor_info_additional_field_placeholder: 'В цьому полі введіть плейсхолдер для поля додаткового питання',
    companies_form_constructor_info_placeholder: 'У це поле введіть шаблон питання, на яке хотіли б отримати відповідь.',
    companies_form_constructor_info_question: 'У це поле введіть запитання, на яке хотіли б отримати відповідь.',
    companies_form_constructor_info_scale_max: 'У це поле введіть короткий опис для максимальної оцінки у шкалі',
    companies_form_constructor_info_scale_min: 'У це поле введіть короткий опис для мінімальної оцінки у шкалі.',
    companies_form_constructor_info_step_text: 'В цьому полі введіть текст, який ви хочете показати як текст кроку.',
    companies_form_constructor_info_thank_you_page_text: 'У це поле введіть текст, який буде відображатися на сторінці подяки.',
    companies_form_constructor_info_thank_you_page_title: 'У це поле введіть текст заголовку, яке буде відображатися на сторінці подяки.',
    companies_form_constructor_key_question: 'Ключове запитання',
    companies_form_constructor_language_label: 'Мова форми',
    companies_form_constructor_language_placeholder: 'Мова',
    companies_form_constructor_localization_label: 'Розміщення для всієї форми',
    companies_form_constructor_logo_place_center: 'По центу',
    companies_form_constructor_logo_place_label: 'Розташування логотипу',
    companies_form_constructor_logo_place_left: 'Ліворуч',
    companies_form_constructor_logo_place_right: 'Праворуч',
    companies_form_constructor_main_question: 'Залиште оцінку компанії',
    companies_form_constructor_main_scale: 'Головна шкала',
    companies_form_constructor_mascot: 'Маскот',
    companies_form_constructor_max_placeholder: 'Дуже ймовірно',
    companies_form_constructor_min_placeholder: 'Малоймовірно',
    companies_form_constructor_name_field_required: 'Необхідне поле вводу імені',
    companies_form_constructor_name_placeholder: 'Ваше імʼя',
    companies_form_constructor_name_title: 'Імʼя',
    companies_form_constructor_next_field: 'Наступне поле',
    companies_form_constructor_not_checked_elements_color_title: 'Колір необранних елементів',
    companies_form_constructor_one_from_list_placeholder: 'Як давно ви відвідуєте наш заклад?',
    companies_form_constructor_one_from_list_title: 'Один зі списку',
    companies_form_constructor_phone_placeholder: 'Введіть свій номер телефону',
    companies_form_constructor_phone_title: 'Номер телефону',
    companies_form_constructor_prev_field: 'Попереднє поле',
    companies_form_constructor_privacy_policy_label: 'Виберіть потрібний документ',
    companies_form_constructor_privacy_policy_placeholder: 'Виберіть документ',
    companies_form_constructor_privacy_policy_title: 'Політика конфіденційності',
    companies_form_constructor_quantity_button_signs_color: 'Колір підписів кнопок',
    companies_form_constructor_quantity_button_signs_color_placeholder: 'Вибрати колір підписів кнопок',
    companies_form_constructor_quantity_buttons_background_color: 'Колір тла кнопок',
    companies_form_constructor_quantity_buttons_background_color_placeholder: 'Вибрати колір тла кнопок',
    companies_form_constructor_quantity_default_value_title: 'Значення за замовчуванням',
    companies_form_constructor_quantity_max_value_title: 'Максимальне значення',
    companies_form_constructor_quantity_min_value_title: 'Мінімальне значення',
    companies_form_constructor_quantity_placeholder: 'Скільки разів ви відвідували нашу локацію?',
    companies_form_constructor_quantity_title: 'Кількість',
    companies_form_constructor_required_question: 'Обовʼязкове запитання',
    companies_form_constructor_scale_change_design_button: 'Змінити дизайн шкали',
    companies_form_constructor_scale_title: 'Шкала',
    companies_form_constructor_select_field: 'Вибрати поле',
    companies_form_constructor_send_button: 'Кнопка',
    companies_form_constructor_send_button_background_color: 'Колір фону кнопки',
    companies_form_constructor_send_button_link: 'Посилання на кнопку',
    companies_form_constructor_send_button_text: 'Тест кнопки',
    companies_form_constructor_send_button_text_color: 'Колір тексту кнопки',
    companies_form_constructor_send_button_title: 'Кнопка відправки',
    companies_form_constructor_send_disabled_button_text_color: 'Колір тексту неактивної кнопки',
    companies_form_constructor_set_default_agreed: 'Вставити значення за замовчуванням',
    companies_form_constructor_several_from_list_placeholder: 'Як давно ви відвідуєте наш заклад?',
    companies_form_constructor_several_from_list_title: 'Декілька зі списку',
    companies_form_constructor_show_additional_field: 'Показати додаткове поле',
    companies_form_constructor_show_name_input_field: 'Показати поле вводу імені',
    companies_form_constructor_show_text_in_two_rows: 'Показати текст у двох рядках',
    companies_form_constructor_signature_label: 'Підпис',
    companies_form_constructor_signature_max_label: 'Максимум',
    companies_form_constructor_signature_min_label: 'Мінімум',
    companies_form_constructor_start_page_button_width: 'Ширина кнопки початкової сторінки',
    companies_form_constructor_status_label: 'Статус',
    companies_form_constructor_step_text: 'Текст кроку',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Введіть текст кроку',
    companies_form_constructor_step_text_underline_color: 'Колір підкреслення тексту кроку',
    companies_form_constructor_step_text_underline_color_placeholder: 'Вибрати колір підкреслення тексту кроку',
    companies_form_constructor_submit_button: 'Зберегти форму',
    companies_form_constructor_tab_diagram: 'Діаграма',
    companies_form_constructor_tab_field: 'Поле',
    companies_form_constructor_tab_fields: 'Поля',
    companies_form_constructor_tab_settings: 'Налаштування',
    companies_form_constructor_text: 'Текст…',
    companies_form_constructor_thank_you_page_field_title: 'Сторінка подяки',
    companies_form_constructor_thank_you_page_text: 'Текст сторінки подяки',
    companies_form_constructor_thank_you_page_text_first_line: "Текст сторінки 'Дякую' перший рядок",
    companies_form_constructor_thank_you_page_text_second_line: "Текст сторінки 'Дякую' другий рядок",
    companies_form_constructor_thank_you_page_title: 'Назва сторінки подяки',
    companies_form_constructor_title: 'Констурктор форми',
    companies_form_constructor_title_additional_field_placeholder: 'Інший',
    companies_form_constructor_title_additional_field_placeholder_title: 'Плейсхолдер додаткового поля',
    companies_form_constructor_title_placeholder: 'Будемо раді, якщо ви залишите відгук',
    companies_form_constructor_title_placeholder_title: 'Плейсхолдер',
    companies_form_constructor_title_title: 'Підпис',
    companies_form_constructor_upload_files_label: 'Виберіть, які типи файлів можна буде завантажити у форму відгуку',
    companies_form_constructor_upload_files_placeholder: 'Пояснення, що до форми можна прикріплювати файли',
    companies_form_constructor_upload_files_title: 'Завантажити файл',
    companies_form_download_qr: 'Завантажити QR-код',
    companies_form_scale_constructor_change_background_checkbox_label: 'Змінити тло коли змінюється оцінка',
    companies_form_scale_constructor_change_background_select_label: 'Колір тла',
    companies_form_scale_constructor_change_background_select_placeholder: 'Виберіть колір',
    companies_form_scale_constructor_change_mascot_checkbox_label: 'Змінити талісман коли змінюється оцінка',
    companies_form_scale_constructor_change_mascot_select_label: 'Талісмани',
    companies_form_scale_constructor_change_mascot_select_placeholder: 'Вибрати талісман',
    companies_form_scale_constructor_choose_color_placeholder: 'Виберіть колір',
    companies_form_scale_constructor_file_not_uploaded: 'Файл не завантажено',
    companies_form_scale_constructor_main_gradient_colors_label: 'Базові кольори градієнту',
    companies_form_scale_constructor_maximum_value_label: 'Максимальне значення',
    companies_form_scale_constructor_maximum_value_placeholder: 'Введіть максимальне значення',
    companies_form_scale_constructor_minimum_value_label: 'Введіть значення за замовчуванням',
    companies_form_scale_constructor_minimum_value_placeholder: 'Значення за замовчуванням',
    companies_form_scale_constructor_numbers_chosen_colors_label: 'Колір вибраного питання',
    companies_form_scale_constructor_numbers_colors_label: 'Колір номерів',
    companies_form_scale_constructor_pointer_color_label: 'Колір покажчика',
    companies_form_scale_constructor_show_default_value: 'Показати значення за замовчуванням',
    companies_form_scale_constructor_signature_text_color_label: 'Колір тексту підпису',
    companies_form_scale_constructor_star_chosen_color_label: 'Колір вибраної зірки',
    companies_form_scale_constructor_star_not_chosen_color_label: 'Колір заливки невибраної зірки',
    companies_form_scale_constructor_star_not_chosen_contour_color_label: 'Колір контуру колір зірки',
    companies_form_scale_constructor_tab_title: 'Шкала',
    companies_form_scale_constructor_title: 'Констурктор шкали',
    companies_form_select_color_placeholder: 'Виберіть колір',
    companies_form_show_node_code: 'Показати код вузла',
    companies_form_table_add_form_button: 'Додати форму',
    companies_form_table_constructor_button: 'Конструктор форми',
    companies_form_table_copy_button: 'Копіювання форми',
    companies_form_table_copy_confirm_text: 'Ви впевнені, що хочете скопіювати форму?',
    companies_form_table_delete_button: 'Видалити форму',
    companies_form_table_delete_confirm_text: 'Ви впевнені, що хочете видалити форму?',
    companies_form_table_edit_button: 'Редагувати',
    companies_form_table_header_feedbacks: 'Відгуки',
    companies_form_table_header_last_edit: 'Останні зміни',
    companies_form_table_header_link: 'Посилання',
    companies_form_table_header_name: 'Назва форми',
    companies_form_table_header_status: 'Статус',
    companies_form_table_new_form_language_placeholder: 'Виберіть мову для нової форми',
    companies_form_table_new_form_language_title: 'Мова нової форми',
    companies_form_table_new_form_name_placeholder: 'Введіть назву нової форми',
    companies_form_table_new_form_name_title: 'Назва нової форми',
    companies_form_table_new_node_language_placeholder: 'Вибрати мову вузла',
    companies_form_table_new_node_language_title: 'Мова вузла',
    companies_form_table_new_node_name_placeholder: 'Введіть назву нового вузла',
    companies_form_table_new_node_name_title: 'Назва нового вузла',
    companies_form_table_node_to_copy: 'Куди ви хочете скопіювати форму?',
    companies_form_table_thank_button: 'Сторінка подяки',
    companies_form_table_title: 'Форми зворотнього звʼязку вузла компанії',
    companies_form_use_as_default_form: 'Використати форму за замовчуванням',
    companies_form_with_steps: 'Покрокова форма',
    companies_info_add_field_button: 'Додати поле',
    companies_info_after_save_modal_confirm_button: 'Редагувати дані компанії',
    companies_info_after_save_modal_title: 'Компанія успішно створена',
    companies_info_choose_file_button: 'Вибрати файл',
    companies_info_company_domain_label: 'Домен компанії',
    companies_info_company_domain_placeholder: 'Введіть домен компанії',
    companies_info_company_extra_field_name_placeholder: 'Введіть назву поля',
    companies_info_company_extra_field_value_placeholder: 'Введіть значення поля',
    companies_info_company_name_label: 'Назва компанії',
    companies_info_company_name_placeholder: 'Введіть назву компанії',
    companies_info_confirmation_before_save_modal_title: 'Ви впевнені, що хочете редагувати інформацію про компанію?',
    companies_info_file_max_size: 'JPG, GIF чи PNG. Максимальний розмір 800К',
    companies_info_file_not_selected: 'Файл не вибрано',
    companies_info_submit_create_button: 'Зберегти компанію',
    companies_info_submit_edit_button: 'Зберегти зміни',
    companies_info_tab_base_info: 'Основна інформація',
    companies_info_tab_design: 'Дизайн',
    companies_info_tab_documents: 'Документи',
    companies_info_tab_issues_statuses_and_types: 'Статуси і типи задач',
    companies_info_tab_review_table_columns_settings: 'Налаштування стовпців',
    companies_info_tab_sources: 'Джерела',
    companies_info_tab_templates: 'Шаблони',
    companies_info_title_add: 'Додати компанію',
    companies_info_title_edit: 'Редагувати компанію',
    companies_issue_statuses_and_types_add_status_button: 'Додати статус',
    companies_issue_statuses_and_types_add_status_placeholder: 'Введіть назву статусу',
    companies_issue_statuses_and_types_statuses_text: 'Статуси',
    companies_issue_statuses_and_types_type_add_button: 'Додати тип',
    companies_issue_statuses_and_types_type_add_placeholder: 'Введіть назву типу',
    companies_issue_statuses_and_types_types_text: 'Типи',
    companies_issues_and_types_confirmation_before_save_modal_title: 'Ви впевнені, що хочете редагувати статуси та типи задач?',
    companies_main_add_company_button: 'Додати компанію',
    companies_main_delete_description: 'Ви впевнені, що хочете видалити компанію?',
    companies_main_delete_title: 'Видалити компанію',
    companies_main_forms_button: 'Форма зворотнього звʼязку',
    companies_main_structure_button: 'Структура',
    companies_main_table_company_name_header: 'Компанія',
    companies_main_title: 'Компанії',
    companies_review_table_columns_settings_title: 'Cтовпці',
    companies_review_title: 'Відгук',
    companies_reviews_table_header_button_details: 'Деталі',
    companies_reviews_table_header_contact: 'Канал',
    companies_reviews_table_header_contact_type: "Тип зв'язку",
    companies_reviews_table_header_create: 'Дата створення',
    companies_reviews_table_header_estimate: 'Оцінка',
    companies_reviews_table_header_files: 'Прикріплені файли',
    companies_reviews_table_header_key_answer: 'Ключова відповідь',
    companies_reviews_table_header_language: 'Мова',
    companies_reviews_table_header_location: 'Локації',
    companies_reviews_table_header_source: 'Джерело',
    companies_reviews_table_header_status: 'Статус',
    companies_reviews_table_title: 'Відгуки',
    companies_source_must_be_unique_text: 'має бути унікальним',
    companies_source_name_label: 'Назва джерела',
    companies_source_name_max_length_error: 'Назва джерела має містити менше 100 символів',
    companies_sources_add_source_button: 'Додати джерело',
    companies_sources_confirmation_before_save_modal_title: 'Ви впевнені, що хочете редагувати джерела?',
    companies_sources_create_new_source_title: 'Створити нове джерело',
    companies_sources_create_source_text: 'Створити джерело',
    companies_sources_placeholder: 'Введіть назву джерела',
    companies_sources_title: 'Джерела',
    companies_structure: 'Структура',
    companies_structure_create_accountable: 'Відповідальний(-і)',
    companies_structure_create_address_label: 'Додати адреси',
    companies_structure_create_address_placeholder: 'Введіть адреси',
    companies_structure_create_advanced_settings: 'Додаткові налаштування',
    companies_structure_create_edit_title: 'Редагувати вузол',
    companies_structure_create_email: 'Пошта',
    companies_structure_create_google_place_id_label: 'Ідентифікатор Google Place',
    companies_structure_create_integration_alpha_name_label: "Альфа ім'я",
    companies_structure_create_integration_key_label: 'Ключ',
    companies_structure_create_integration_title: 'Налаштування інтеграції',
    companies_structure_create_modal_title: 'Ви впевнені що хочете створити вузол?',
    companies_structure_create_node_code_label: 'Код вузла',
    companies_structure_create_node_code_placeholder: 'Ввести код вузла',
    companies_structure_create_node_name_label: 'Назва вузла',
    companies_structure_create_node_name_placeholder: 'Ввести назву вузла',
    companies_structure_create_sms: 'SMS',
    companies_structure_create_telegram: 'Telegram',
    companies_structure_create_tied_form_placeholder: 'Форма',
    companies_structure_create_tied_node_label: 'Звʼязаний вузол',
    companies_structure_create_tied_node_placeholder: 'Вузол',
    companies_structure_create_title: 'Створити вузол',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay: 'Не використовувати таймер просроченості',
    companies_structure_lang_detection: 'Використати автоматичне визначення мови форми',
    companies_structure_table_accountable: 'Відповідальний',
    companies_structure_table_add_node_button: 'Додати вузол',
    companies_structure_table_delete_description_1: 'Ви впевнені, що хочете видалити вузол?',
    companies_structure_table_delete_description_2: 'структури компанії',
    companies_structure_table_delete_description_3: 'Це видалить всі вкладені вузли',
    companies_structure_table_delete_title: 'Видалити вузол структури компанії',
    companies_structure_table_header_name: 'Вузли',
    companies_structure_title: 'Структура компанії',
    companies_structure_update_modal_title: 'Ви впевнені, що хочете змінити вузол?',
    company: 'Компанія',
    company_main_info_delete_title: 'Видалення компанії',
    company_main_info_edit_title: 'Редагувати основну інформацію про компанію',
    company_main_info_go_to_company_structure: 'Перейти до структури компанії',
    company_structure_delete_button_title: 'Видалити вузол',
    company_structure_edit_button_title: 'Редагування вузла',
    confirm_button: 'Підтвердити',
    connect_button: 'Закрити',
    continue_editing_button: 'Продовжити редагування',
    continue_review_dialog_button: 'Продовжити діалог',
    copy_button: 'Копіювати',
    copy_error: '* Під час копіювання сталася помилка',
    create_button: 'Створити',
    data_save_error: '* Під час збереження даних сталася помилка',
    delete_button: 'Видалити',
    delete_error: '* Під час видалення сталася помилка',
    document: 'Документ',
    done_button: 'Готово',
    edit_button: 'Редагувати',
    email: 'Адреса електронної пошти',
    email_label: 'Адреса електронної пошти',
    email_placeholder: 'Введіть свою адресу електронної пошти',
    employee_profile_title: 'Профіль співробітника',
    enter_button: 'Увійти',
    enter_forgot_button: 'Відновити пароль',
    enter_task_name: 'Введіть назву задачі',
    error: '* Сталася помилка',
    error_text: 'Текст помилки',
    error_title: 'Помилка',
    expecting_reply_from_client: 'Чекаємо на відповідь клієнта',
    filter_title_sources: 'Джерела',
    filter_title_status: 'Статуси',
    filters: 'Фільтри',
    finish_review_dialog_button: 'Завершити діалог з клієнтом',
    finish_review_dialog_modal_description: 'Ви впевнені що хочете завершити діалог?',
    finish_review_dialog_modal_title: 'Завершити діалог',
    form: 'Форма',
    form_constructor_connection_case_label: 'Варіант',
    form_constructor_connection_case_placeholder: 'Виберіть варіант',
    form_constructor_connection_go_to: 'перехід до',
    form_constructor_connection_render_minifield_connection_always: 'Завжди',
    form_constructor_connection_render_minifield_connection_type_texts_always: 'Всі інші випадки',
    form_constructor_connection_render_minifield_connection_type_texts_if: 'Якщо',
    form_constructor_connection_select_field: 'Виберіть поле',
    form_constructor_connection_select_with_any_items_label: 'Наступне поле',
    form_constructor_connection_select_with_any_items_options_1_question: 'Не обрано',
    form_constructor_connection_select_with_any_items_placeholder: 'Виберіть поле',
    form_constructor_connection_statement_label: 'Правило',
    form_constructor_connection_statement_placeholder: 'Виберіть правило',
    form_constructor_delete_field_modal_description: 'Ви впевнені, що хочете видалити це поле?',
    form_constructor_delete_field_modal_title: 'Увага!',
    form_constructor_diagram_fields_list_is_empty: 'Список полів порожній',
    form_constructor_fields_form_content: 'Зміст',
    form_constructor_fields_form_documents: 'Документи',
    form_constructor_fields_form_no_fields_selected: 'Не вибрано поле',
    form_constructor_fields_form_send_button: 'Кнопка відправки',
    form_constructor_fields_form_thank_you_page: 'Сторінка подяки',
    form_copy_save_error: '* Форма за такою назвою вже існує у вибраному вузлі',
    form_language_error: '* Ви вже маєте форму з вибраною мовою',
    form_name_error: '* Назва форми повинна бути унікальною',
    form_save_error: '* Під час збереження форми сталася помилка',
    from: 'Від',
    full_name_label: 'Повне імʼя працівника',
    full_name_placeholder: 'Введіть повне імʼя працівника',
    generate_button: 'Генерувати',
    go_to_forms_page_button_title: 'Перейти до форми зворотнього звʼязку',
    go_to_issues_create: 'Перейти до створення задачі',
    go_to_reviews_page_button_title: 'Перейти до відгуків',
    go_to_task_create: 'Перейти до створення задачі',
    in_progress: 'В роботі',
    issues_add_issue: 'Додати задачу',
    issues_form_company_label: 'Компанія',
    issues_form_company_placeholder: 'Виберіть компанію',
    issues_form_connect_issue_label: 'Звʼязана задача',
    issues_form_connect_issue_placeholder: 'Виберіть задачу',
    issues_form_create_new_issue_status: 'Створити новий статус задачі',
    issues_form_create_new_issue_status_title: 'Створити статус',
    issues_form_create_new_issue_type: 'Створити новий тип задачі',
    issues_form_create_new_issue_type_title: 'Створити тип',
    issues_form_must_be_unique: 'Повинен бути унікальним',
    issues_form_name_label: 'Назва задачі',
    issues_form_name_placeholder: 'Введіть назву форми',
    issues_form_node_label: 'Вузол',
    issues_form_node_placeholder: 'Виберіть вузол',
    issues_form_priority_label: 'Пріоритет задачі',
    issues_form_priority_placeholder: 'Виберіть пріоритет',
    issues_form_responsible_label: 'Відповідальний',
    issues_form_responsible_placeholder: 'Виберіть відповідального',
    issues_form_review_label: 'Відгук',
    issues_form_review_placeholder: 'Виберіть відгук',
    issues_form_status_label: 'Статус задачі',
    issues_form_status_name_label: 'Назва статусу задачі',
    issues_form_status_placeholder: 'Виберіть статус',
    issues_form_type_label: 'Тип задачі',
    issues_form_type_name_label: 'Назва типу задачі',
    issues_form_type_placeholder: 'Виберіть тип задачі',
    issues_save_modal_create_title: 'Ви впевнені що бажаєте створити задачу?',
    issues_save_modal_edit_title: 'Ви впевнені що бажаєте змінити задачу?',
    issues_search_by_name: 'Шукати питанння за назвою',
    issues_sub_header_create_issue_title: 'Створити задачу',
    issues_sub_header_create_task_title: 'Створення задачі',
    issues_sub_header_edit_issue_title: 'Редагувати задачу',
    issues_sub_header_title: 'Задачі',
    issues_table_empty: 'Оберіть компанію для відображення задачі',
    issues_table_header_company: 'Компанія',
    issues_table_header_date: 'Дата створення',
    issues_table_header_name: 'Назва задачі',
    issues_table_header_node: 'Вузол',
    issues_table_header_priority: 'Пріорітет',
    issues_table_header_status: 'Статус',
    issues_table_header_type: 'Тип задачі',
    language: 'Мова',
    languages_add_name_placeholder: 'Введіть імʼя',
    languages_add_short_name_label: 'Коротке імʼя',
    languages_add_short_name_placeholder: 'Введіть коротке імʼя',
    languages_add_title: 'Додати мову',
    languages_table_add_language_button: 'Додати мову',
    languages_table_header_full_name: 'Повне імʼя',
    languages_table_header_short_name: 'Коротке імʼя',
    languages_table_modal_delete_buttons_label_end: 'мову?',
    languages_table_modal_delete_buttons_label_start: 'Ви впевнені, що хочете видалити',
    languages_table_modal_delete_title: 'Видалити мову',
    languages_table_title: 'Мови',
    leave_the_page: 'залишити сторінку',
    loading_error: '* Під час завантаження даних сталася помилка',
    login_recovery_auth: 'Повернутися',
    login_recovery_complete: 'Повідомлення з інструкціями щодо скидання пароля найблищим часом буде надіслано на вашу електронну адресу',
    mass_mailing_type_by_addresses: 'Поштова адреса',
    mass_mailing_type_by_company: 'За компанією',
    max_size_error: '* Максимально допустимий розмір файлу перевищено',
    max_size_error_25: '* Максимально допустимий розмір файлу 25Мтб',
    max_table_column_settings_apply_warning: 'Одночасно може бути 6 активних налаштувань',
    more_detailed: 'Більш детально',
    name: 'Імʼя',
    new_task_name: 'Нова назва задачі',
    next_button: 'Далі',
    no_options_available: 'Немає доступних варіантів',
    node: 'Вузол',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes,',
    not_decided: 'Не вирішено',
    not_viewed: 'Не переглянуто',
    nothing_find: 'Не знайдено',
    login_recovery_password_title: 'Відновлення втраченого пароля',
    open_issue_title: 'Відкрити задачу',
    open_language_title: 'Відкрити мову',
    open_permission_title: 'Відкрити дозвіл',
    open_review_title: 'Відкрити відгук',
    open_role_title: 'Відкрити роль',
    open_user_title: 'Відкрити користувача',
    other: 'Інше',
    overdue_status_text: 'Прострочено',
    pagination_page_number_placeholder: 'Номер сторінки',
    pagination_title: 'Рядків на сторінці',
    password: 'Пароль',
    password_confirmation_label: 'Повторіть пароль',
    password_confirmation_placeholder: 'повторно введіть пароль',
    password_label: 'Пароль',
    password_placeholder: 'Введіть пароль',
    permission_create_title: 'Створити дозвіл',
    permission_edit_title: 'Редагувати дозвіл',
    permission_name_placeholder: 'Введіть назву дозволу',
    permission_name_title: 'Назва дозволу',
    permission_resource_placeholder: 'Введіть ресурс дозволу',
    permission_resource_title: 'Ресурс',
    permissions_main_add_permission_button: 'Додати дозвіл',
    permissions_main_delete_description: 'Ви впевнені що хочете видалити дозвіл?',
    permissions_main_delete_title: 'Видалити дозвіл',
    permissions_main_permissions_button: 'Дозволи',
    permissions_main_table_create_header: 'Створити',
    permissions_main_table_delete_header: 'Видалити',
    permissions_main_table_permission_header: 'Назва дозволу',
    permissions_main_table_read_header: 'Читати',
    permissions_main_table_resource_header: 'Ресурс',
    permissions_main_table_update_header: 'Оновити',
    permissions_main_title: 'Дозволи',
    phone: 'Телефон',
    phone_number_label: 'Номер телефону',
    phone_number_placeholder: 'Введіть номер телефону',
    photo: 'Фото',
    preview_button: 'Попередній вигляд',
    privacy_policy: 'Угодою користувача',
    privacy_policy_confirm: 'Я погоджуюся з',
    record: 'Аудіо',
    refresh_all_fitlers: 'Скинути всі фільтри',
    registration_profile_completion: 'Заповнення профілю',
    reopened: 'Відкрито знову',
    reset_reviews_filter: 'Скинути фільтр',
    reset_table_settings: 'Скинути налаштування таблиці',
    review_choose_template: 'Виберіть шаблон відповіді',
    review_created_at: 'Дата та час публікації',
    review_dialog_finished_text: 'Діалог завершено',
    review_form_name: 'Назва форми',
    review_form_source: 'Джерело форми',
    review_handle_continue_dialog_error_message: 'Щось не так. Будь-ласка спробуйте пізніше.',
    review_message_input_placeholder: 'Напишіть своє повідомлення клієнту, або скористайтеся готовими шаблонами натиснувши на кнопку справа зі всіма шаблонами',
    review_no_asscociated_issues: "Жодних пов'язаних задач",
    review_no_associated_tasks: 'Немає зв’язанних задач',
    review_node_code: 'Код вузла',
    review_node_name: 'Назва вузла',
    review_on_submit_3_error_message: 'Клієнт вже веде діалог з іншим менеджером. Будь-ласка спробуйте пізніше.',
    review_open_task_button_text: 'Відкрити задачу',
    review_page_type_of_task: 'Тип задачі',
    review_review_id: 'Ідентифікатор відгуку',
    review_save_modal_title: 'Ви впевнені що бажаєте редагувати відгук?',
    review_updated_at: 'Дата обробки',
    reviews_page_choose_company_title: 'Оберіть компанію у випадаючому списку',
    reviews_table_comment_column_title: 'Коментар',
    reviews_table_email_column_title: 'Пошта',
    reviews_table_filter_title: 'Фільтр',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_node_column_title: 'Вузол',
    reviews_table_parent_node_column_title: 'Батьківський вузол',
    reviews_table_phone_column_title: 'Телефон',
    reviews_table_review_id_column_title: 'ID відгуку',
    reviews_table_ukrposhta_settings_button: 'Неопрацьовані відгуки',
    reviews_table_user_name_column_title: 'Ім\'я',
    reviewType: 'Тип відгуку',
    role_create_title: 'Створити роль',
    role_edit_title: 'Редагувати роль',
    role_name_placeholder: 'Введіть назву ролі',
    role_name_title: 'Назва ролі',
    role_page_create_title: 'Створити роль',
    role_permissions: 'Дозволи',
    role_select_permissions_placeholder: 'Виберіть дозволи',
    roles_main_add_role_button: 'Додати роль',
    roles_main_delete_description: 'Ви впевнені що хочете видалити роль?',
    roles_main_delete_title: 'Видалити роль',
    roles_main_permissions_button: 'Дозволи',
    roles_main_table_user_role_header: 'Назва ролі',
    roles_main_title: 'Ролі',
    roles_part_analytic: 'Аналітика',
    roles_part_companies: 'Компанії',
    roles_part_documents: 'Документи',
    roles_part_forms: 'Форми',
    roles_part_languages: 'Мови',
    roles_part_mass_mailings: 'Масові розсилки',
    roles_part_nodes: 'Вузли',
    roles_part_reviews: 'Відгуки',
    roles_part_users: 'Користувачі',
    roles_sub_permission_create: 'Створити',
    roles_sub_permission_delete: 'Видалити',
    roles_sub_permission_read: 'Читати',
    roles_sub_permission_update: 'Оновити',
    save_button: 'Зберегти',
    save_changes_button: 'Зберегти зміни',
    save_document_button: 'Зберегти',
    save_draft_button: 'Зберегти шаблон',
    save_field_button: 'Зберегти поле',
    save_form_button: 'Зберегти форму',
    save_form_without_changes_button: 'Зберегти форму без змін',
    search: 'Пошук…',
    search_for_name: 'Пошук за назвою',
    search_for_email: 'Пошук за електронною адресою',
    select_company: 'Вибрати компанію',
    select_form: 'Вибрати форму',
    select_language: 'Виберіть мову',
    select_multiple_sources: 'Вибрати джерела',
    select_multiple_statuses: 'Вибрати статуси',
    select_node: 'Вибрати вузол',
    select_status: 'Вибрати статус',
    select_type: 'Вибрати тип',
    send_button: 'Надіслати',
    sidebar_analytic: 'Аналітика',
    sidebar_companies: 'Компанії',
    sidebar_exit: 'Вийти з профілю',
    sidebar_issues: 'Задачі',
    sidebar_languages: 'Мови',
    sidebar_profile: 'Профіль',
    sidebar_reviews: 'Відгуки',
    sidebar_tasks: 'Задачі',
    sidebar_users: 'Користувачі',
    sms: 'SMS',
    social_networks_module_text: 'Виберіть зручний для вас спосіб звʼязку з нами',
    solution_not_required: 'Вирішення не потрібне',
    solved: 'Вирішено',
    source: 'Джерело',
    status: 'Статус відгуку',
    status_code: 'Код статусу',
    table_columns_settings_comment_option: 'Коментар до відгука',
    table_columns_settings_communication_channel_option: 'Тип зв\'язку',
    table_columns_settings_company_option: 'Компанія',
    table_columns_settings_created_at_option: 'Дата створення',
    table_columns_settings_email_option: 'Пошта',
    table_columns_settings_estimation_option: 'Оцінка',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_language_option: 'Мова',
    table_columns_settings_name_option: 'Ім\'я',
    table_columns_settings_node_option: 'Вузол',
    table_columns_settings_parent_node_option: 'Батьківський вузол',
    table_columns_settings_phone_option: 'Телефон',
    table_columns_settings_reviewId_option: 'ID відгуку',
    table_columns_settings_source_option: 'Джерело',
    table_columns_settings_status_option: 'Статус',
    table_settings: 'Налаштування таблиці',
    task_form_category_label: 'Категорія задачі',
    task_form_create_task: 'Створити задачу',
    task_form_list_category_label: 'Категорія',
    task_form_list_subcategory_label: 'Підкатегорія',
    task_form_list_type_label: 'Тип',
    task_form_name_label: 'Назва задачі',
    task_form_priority_label: 'Пріорітет задачі',
    task_form_status_label: 'Статус задачі',
    task_form_subcategory_label: 'Підкатегорія задачі',
    task_form_type_label: 'Тип задачі',
    telegram: 'Telegram',
    to: 'По',
    to_plug_button: 'Закрити',
    turn_off_button: 'Вимкнути',
    upload: 'Завантажити',
    upload_color_button: 'Завантажити колір',
    uploading_files: 'Завантаження файлів',
    user_email_placeholder: 'Введіть адресу електронної пошти',
    user_email_title: 'Адреса електронної пошти',
    user_name_placeholder: 'Введіть імʼя',
    user_name_title: 'Імʼя та прізвище',
    user_new_password_placeholder: 'Введіть новий пароль',
    user_new_password_title: 'Новий пароль',
    user_old_password_placeholder: 'Введіть старий пароль',
    user_old_password_title: 'старий пароль',
    user_page_create: 'Створити користувача',
    user_page_edit: 'Редагувати користувача',
    user_password_confirmation_placeholder: 'Повторіть пароль',
    user_password_confirmation_title: 'Підтвердження паролю',
    user_password_placeholder: 'Введіть пароль',
    user_password_title: 'Пароль',
    user_role_placeholder: 'Виберіть ролі користувача',
    user_role_title: 'Ролі',
    users_main_add_user_button: 'Додати користувача',
    users_main_delete_description: 'Ви впевнені що хочете видалити користувача?',
    users_main_delete_title: 'Видалити користувача',
    users_main_roles_button: 'Ролі',
    users_main_table_user_company_header: 'Компанія',
    users_main_table_user_email_header: 'Адреса електронної пошти',
    users_main_table_user_name_header: 'Імʼя',
    users_main_table_user_notifications_header: 'Сповіщення',
    users_main_table_user_role_header: 'Роль',
    users_main_title: 'Користувачі',
    users_main_upload_users_button: 'Завантажити користувачів',
    validation_constructor_at_least_2_possible_answers: 'Питання повинно мати принаймні 2 варіанти відповіді',
    validation_constructor_should_have_at_least_1_document: 'Форма повинна містити мінімум один документ',
    validation_constructor_should_select_file_types: 'Ви повинні вибрати типи файлів дозволені для завантаження або видалити це поле з форми',
    validation_domain: "Доменне імʼя може мати тільки англійські символи, цифри, '.' і '-'",
    validation_form_should_have_minimum_1: '* У формі має бути щонайменше 1 поле',
    validation_full_type_required: '* Необхідно обрати всі дані',
    validation_invalid_email: '* Недійсний формат пошти',
    validation_invalid_link: '* Недійсний формат посилання',
    validation_more_one_accountable: '* Ви маєте обрати щонайменше 1 менеджера для вузла',
    validation_more_one_option: '* Ви маєте обрати щонайменше 1 варіант',
    validation_must_fill_all_fields_for: '* Ви маєте заповнити всі поля для',
    validation_must_fill_this_field: '* Ви маєте заповнити це поле',
    validation_name_must_be_longer_4: '* Назва закоротка - вона має містити щонайменше 4 символи',
    validation_required: '* Обовʼязково',
    viber: 'Viber',
    video: 'Відео',
    viewed: 'Переглянуто',
    your_changes_lose: 'Вам потрібно зберегти зміни. В іншому випадку вони будуть втрачені.',
    send_feedback: 'Надіслати відгук',
    send_feedback_title: 'Зворотній звʼязок',
    send_feedback_label: 'Ваші побажання або зауваження',
    send_feedback_complete: 'Дякуємо за Ваш відгук',
    sidebar_impex: 'Імпорт/Експорт',
    impex_page_title: 'Імпорт/Експорт',
    impex_import_tab: 'Імпорт',
    impex_export_tab: 'Експорт',
    select_import_entity: 'Що ви хочете імпортувати?',
    start_import_button_text: 'Почати імпорт',
    choose_file_for_import: 'Оберіть файл для імпорту',
    import_entity_selection_title: 'Імпортуємо',
    import_file_sheet_name_label: 'Назва листа у файлі імпорту',
    import_file_sheet_name_input_placeholder: 'Введіть назву листа у файлі для імпорту',
    interrupt_import_script_button_text: 'Зупинити імпорт',
    import_file_format_text: 'Формат файлу має бути:',
    import_progress_bar_title: 'Прогрес виконання імпорту:',
    import_users_entity_name: 'Користувачі',
    import_nodes_entity_name: 'Вузли',
    qr_title: 'Відскануйте QR  для подальшого налаштування сповіщень',
    mass_mailings_channels_of_connections: 'Канали комунікації',
    mass_mailings_send_button: 'Надіслати',
    yes_button_text: 'Так',
    no_button_text: 'Ні',
    confirm_send_mass_mailings_modal_title: 'Ви впевнені, що хочете зробити масову відправку повідомлень?',
    modal_mass_mailings_sent_success_title: 'Повідомлення успішно відправлені',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Помилка відправки повідомлень',
    mass_mailings_recipient_label: 'Отримувач',
    mass_mailings_recipients_list_title: 'Отримувачі',
    add_at_least_one_recipient_error: 'Необхідно додати принаймні одного отримувача',
    sidebar_mass_mailings: 'Масові розсилки',
    create_mass_mailings_page_title: 'Створення масової розсилки',
    create_mass_mailings_first_step_subheader_title: 'Створення масової розсилки - Крок 1 з 3',
    choose_mass_mailings_communication_channel: 'Оберіть канал комунікації',
    mass_mailings_add_recipient_button_text: 'Додати отримувача',
    download_recipients_file_label: 'Завантаження файла отримувачів',
    format: 'Формат',
    choose_file: 'Обрати файл',
    file_data_downloading_text: 'Дані файлу завантажуються',
    mass_mailings_mails_count: 'Кількість повідомлень, яка буде відправлена',
    create_sms_mass_mailings_second_step_subheader_title: 'Створення SMS розсилки - Крок 2 з 3',
    create_viber_mass_mailings_second_step_subheader_title: 'Створення Вайбер розсилки - Крок 2 з 3',
    create_sms_mass_mailings_third_step_subheader_title: 'Створення SMS розсилки - Крок 3 з 3',
    create_viber_mass_mailings_third_step_subheader_title: 'Створення Вайбер розсилки - Крок 3 з 3',
    mass_mailings_sender_account: 'Акаунт відправник',
    sms_text: 'Текст SMS',
    message: 'Повідомлення',
    message_text_placeholder: 'Текст повідомлення',
    responsible: 'Відповідальний',
    responsible_id: 'ID відповідального',
    companies_form_constructor_dividing_decorative_line: 'Налаштування декоративної лінії форми',
    companies_form_constructor_show_form_dividing_decorative_line: 'Показати роздільну декоративну лінію форми',
    companies_form_constructor_dividing_decorative_line_width_in_percent: 'Довжина декоративної лінії у відсотках',
    companies_form_constructor_dividing_decorative_line_height: 'Висота декоративної лінії у пікселях',
    companies_form_constructor_dividing_decorative_line_height_placeholder: 'Вкажіть висоту декоративної лінії',
    companies_form_constructor_dividing_decorative_line_color: 'Колір декоративної лінії',
    companies_form_constructor_dividing_decorative_line_color_placeholder: 'Оберіть колір декоративної лінії',
    export_entity_selection_title: 'Експортуємо',
    select_export_entity: 'Що ви хочете експортувати?',
    export_button_text: 'Експортувати',
    export_nodes_error_text: 'Помилка експорту вузлів',
    export_users_error_text: 'Помилка експорту користувачів',
    chat_tab1: 'Поточний відгук',
    chat_tab2: 'Повна історія чату',
    quarter: 'Минулі 3 місяці',
    months: 'Минулий місяць',
    days7: 'Минулі 7 днів ',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Телефон',
    email_network: 'Електронна адреса',
    whatsapp_network: 'WhatsApp',
  },
  KZ: {
    pass_changed_success: 'Password changed successfully',
    connect_tg: 'Connect Telegram bot',
    connect_vb: 'Connect Viber bot',
    notifiable_sources: 'Notifiable Sources',
    notifications: 'Notifications',
    reset_pass: 'Reset password',
    audit: 'History audit',
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    review_audit: 'Review processing history',
    SYSTEM: 'System',
    USER: 'User',
    actor: 'Actor',
    action: 'Action',
    search_by_phone: 'Search by phone number',
    search_by_reviewID: 'Search by review ID',
    chat_error_no_user: 'The chat with the user was not found, maybe he did not connect or deleted the bot',
    chat_label: 'Chat with the client',
    comment_internal: 'Internal comment',
    changes_not_saved_modal: 'Өзгерістер сақталмайды. Беттен шынымен шыққыңыз келе ме?',
    go_back: 'Артқа оралу',
    all_items_uploaded: 'Барлық жүктелген элементтер',
    login_title: 'Тіркелу',
    login_recovery_password: 'Құпия сөзді қалпына келтіру',
    add_comment: 'Пікір қосу',
    add_button: 'Қосу',
    add_option: 'Опцияны қосу',
    add_option_button: 'Опцияны қосу',
    apply_filters_button: 'Сүзгілерді қолдану',
    apply_table_settings: 'Apply table settings',
    are_you_sure: 'Сен сенімдісіз бе?',
    attached_files: 'Тіркелген файлдар',
    bot_is_not_connected: 'Бот қосылмаған',
    cancel_button: 'Бас тарту',
    cant_decided: 'Шеше алмайды',
    change_new_form_button: 'Жаңа пішінді өзгертіңіз',
    changes_saved_successfully: 'Өзгерістер сәтті сақталды',
    channels_for_notifications: 'Хабарландыру арналары',
    channels_of_connections: 'Қосылу арналары',
    chat_error_description: 'An error occurred while sending a message',
    chat_history_is_empty: 'Чат тарихы бос',
    chat_is_finished: 'Chat finished',
    choose_file_button: 'Файлды таңдаңыз',
    choose_period: 'Мерзімді таңдаңыз',
    client_form_scale_field_h3: 'Біздің компания туралы сіздің әсеріңіз',
    close_button: 'Жабу',
    close_editing_button: 'Өңдеуді жабу',
    closed_auto_no_replay: 'Клиенттің жауабы болмағандықтан автоматты түрде жабылады',
    color_picker_add_button: 'Түс қосу',
    comment_placeholder: 'Түсініктеме енгізіңіз',
    companies_columns_settings_confirmation_before_save_modal_title: 'Are you sure you want to edit the column settings for reviews page?',
    companies_design_add_color_button: 'Түс қосу',
    companies_design_add_color_placeholder: 'Түс атауын енгізіңіз',
    companies_design_add_font_button: 'Add font',
    companies_design_add_font_placeholder: 'Enter font name',
    companies_design_colors_text: 'Түстер',
    companies_design_confirmation_before_save_modal_title: 'Are you sure that you want to edit company information?',
    companies_design_fonts_text: 'Fonts',
    companies_design_logos_add_button: 'Файлды жүктеу',
    companies_design_logos_add_placeholder: 'Логотиптің атын енгізіңіз',
    companies_design_logos_text: 'Суреттер',
    companies_documents_add_localization: 'Локализация',
    companies_documents_add_name_label: 'Аты',
    companies_documents_add_name_placeholder: 'Құжаттың атын енгізіңіз',
    companies_documents_add_title: 'Құжатты қосу',
    companies_documents_add_title_editing: 'Құжаттарды өңдеу',
    companies_documents_file_caption: 'Файл',
    companies_documents_file_format: 'Формат',
    companies_documents_file_max_size: 'Файлдың максималды өлшемі',
    companies_documents_file_not_loaded: 'жүктелмеген',
    companies_documents_file_save_button: 'Файлды жүктеу',
    companies_documents_file_text_editor_label: 'Құжат мәтіні',
    companies_documents_file_text_editor_placeholder: 'Құжаттың мәтінін енгізіңіз',
    companies_documents_table_add_document_button: 'Құжат қосу',
    companies_documents_table_delete_text: 'Құжатты жойғыңыз келетініне сенімдісіз бе?',
    companies_documents_table_delete_title: 'Құжатты жою',
    companies_documents_table_header_edit_date: 'Өзгерту күні',
    companies_documents_table_header_edit_languages: 'Тілдер',
    companies_documents_table_header_name: 'Аты',
    companies_form_add_anonymous_checkbox_no: 'Ешқандай жауап жасырын болуы мүмкін емес.',
    companies_form_add_anonymous_checkbox_yes: 'Иә, жауаптар жасырын болуы мүмкін',
    companies_form_add_anonymous_label: 'Анонимді пікірлер қалдыру мүмкіндігі',
    companies_form_add_create_title: 'Кері байланыс формасын қосыңыз',
    companies_form_add_edit_title: 'Кері байланыс формасын өңдеу',
    companies_form_add_link_label: 'Пішінге сілтеме',
    companies_form_add_link_placeholder: 'Өтініш формасына сілтеме енгізіңіз',
    companies_form_add_main_language_label: 'Негізгі тіл',
    companies_form_add_main_language_placeholder: 'Таңдалмаған',
    companies_form_add_multi_languages_label: 'Көптілді',
    companies_form_add_multi_languages_placeholder: 'Тілдерді таңдау',
    companies_form_add_name_label: 'Өтініш формасының атауы',
    companies_form_add_name_placeholder: 'Өтініш формасының атын енгізіңіз',
    companies_form_add_status_not_published: 'Жарияланбаған',
    companies_form_add_status_published: 'Жарияланған',
    companies_form_add_status_title: 'Мәртебесі',
    companies_form_add_tied_node_label: 'Байланған түйін',
    companies_form_add_tied_node_label_second: 'Екіншісі түйінде жауапты',
    companies_form_add_tied_node_placeholder: 'Түйін',
    companies_form_constructor_add_an_option: 'Опцияны қосу',
    companies_form_constructor_add_document_button: 'Құжат қосу',
    companies_form_constructor_add_field_to_series: 'Add to series of fields',
    companies_form_constructor_add_question_button: 'Сұрақ қосу',
    companies_form_constructor_background_color: 'Фон',
    companies_form_constructor_background_color_for_text_title: 'Background color for text',
    companies_form_constructor_background_color_title: 'Фон түсі',
    companies_form_constructor_caption_checkbox_label: 'Құжатты таңдаңыз',
    companies_form_constructor_caption_placeholder: 'Қажетті құжатты таңдаңыз',
    companies_form_constructor_caption_title: 'Құпиялылық саясаты',
    companies_form_constructor_checked_elements_border_radius_title: 'Checked radio buttons border radius',
    companies_form_constructor_checked_elements_checked_size_title: 'Radio button checked size',
    companies_form_constructor_choice_description_info: 'Егер сіз осы параметрді таңдаған кезде пайдаланушының жауабын кеңейтуін қаласаңыз, мәтін жолағын көрсете аласыз',
    companies_form_constructor_choice_description_placeholder: 'Таңдау сипаттамасының толтырғышы...',
    companies_form_constructor_choice_description_required: 'Choice description required',
    companies_form_constructor_choice_description_title: 'Таңдау сипаттамасына қол қою...',
    companies_form_constructor_choose_form_font_placeholder: 'Select the questionnaire font',
    companies_form_constructor_choose_logo_button: 'Логотипті таңдаңыз',
    companies_form_constructor_choose_logo_label: 'Логотип',
    companies_form_constructor_choose_logo_title: 'Компанияның жүктелген суреттерінен логотипті таңдаңыз',
    companies_form_constructor_choose_main_mascot_button: 'Тұмарды таңдаңыз',
    companies_form_constructor_choose_main_mascot_label: 'Негізгі тұмар',
    companies_form_constructor_choose_main_mascot_title: 'Компанияның жүктелген суреттерінен тұмарды таңдаңыз',
    companies_form_constructor_chose_field_placeholder: 'Өрісті таңдаңыз',
    companies_form_constructor_communication_channels_call: 'Қоңырау',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label: 'Автоматты жауап',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder: 'Автоматты жауап беру мәтінін енгізіңіз',
    companies_form_constructor_communication_channels_email: 'Электрондық пошта',
    companies_form_constructor_communication_channels_label: 'Пішін бетінде қандай байланыс арналары қолжетімді болатынын таңдаңыз',
    companies_form_constructor_communication_channels_placeholder: 'Кері байланыс үшін байланыс арнасын таңдау керек екенін түсіндіру',
    companies_form_constructor_communication_channels_sms: 'қысқаша хабар қызметі (смс)',
    companies_form_constructor_communication_channels_sub_label: 'Жауаптар үшін пайдаланылатын мессенджерлерді таңдаңыз',
    companies_form_constructor_communication_channels_telegram: 'Телеграмм',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Қосылу арналары',
    companies_form_constructor_communication_channels_viber: 'Вайбер',
    companies_form_constructor_communication_channels_without_answer: 'Without answer',
    companies_form_constructor_country_code_not_editable: 'Country code not editable',
    companies_form_constructor_disabled_send_button_background_color: 'Disabled button\'s background color',
    companies_form_constructor_display_node_address: 'Түйін мекенжайын көрсету',
    companies_form_constructor_display_scale_background_color: 'Негізгі масштабтың фондық түсін көрсету',
    companies_form_constructor_display_scale_background_color_info: 'Фон түсі таңдалған негізгі масштаб мәніне байланысты болуы керек',
    companies_form_constructor_display_scale_mascot: 'Негізгі масштабтағы тұмарды көрсетіңіз',
    companies_form_constructor_display_scale_mascot_info: 'Тұмар негізгі шкаланың таңдалған мәніне байланысты болуы керек',
    companies_form_constructor_document_description: 'Қажетті құжатты таңдаңыз',
    companies_form_constructor_document_description_placeholder: 'Құжаттың сипаттамасы',
    companies_form_constructor_document_empty_list: 'Компанияның құжаттары жоқ',
    companies_form_constructor_document_placeholder: 'Құжатты таңдаңыз',
    companies_form_constructor_document_title: 'Құжат',
    companies_form_constructor_elements_border_width_title: 'Elements border width',
    companies_form_constructor_elements_color_title: 'Элементтердің түсі',
    companies_form_constructor_email_placeholder: 'Электрондық пошта мекенжайын енгізіңіз',
    companies_form_constructor_email_title: 'Электрондық пошта',
    companies_form_constructor_form_font_title: 'Questionnaire font',
    companies_form_constructor_form_name_label: 'Пішін атауы',
    companies_form_constructor_form_name_placeholder: 'Пішіннің атын енгізіңіз',
    companies_form_constructor_full_answer_placeholder: 'Егер сіз байланыс үшін электрондық пошта қалдырсаңыз, біз қуанар едік',
    companies_form_constructor_full_answer_title: 'Толық жауап',
    companies_form_constructor_info_additional_field_placeholder: 'In this field, enter the placeholder for additional field of the question you want to ask the respondents.',
    companies_form_constructor_info_placeholder: 'Бұл өріске респонденттерге қойғыңыз келетін сұрақтың толтырғышын енгізіңіз.',
    companies_form_constructor_info_question: 'Бұл өріске респонденттерге қойғыңыз келетін сұрақты енгізіңіз.',
    companies_form_constructor_info_scale_max: 'Бұл өріске масштабтың максималды мәнінің қысқаша сипаттамасын енгізіңіз.',
    companies_form_constructor_info_scale_min: 'Бұл өріске шкаланың минималды мәнінің қысқаша сипаттамасын енгізіңіз.',
    companies_form_constructor_info_step_text: 'In this field, enter the text you want to show as a step text.',
    companies_form_constructor_info_thank_you_page_text: 'Бұл өріске алғыс парағында көрсеткіңіз келетін мәтінді енгізіңіз.',
    companies_form_constructor_info_thank_you_page_title: 'Бұл өріске алғыс парағында көрсеткіңіз келетін тақырыпты енгізіңіз.',
    companies_form_constructor_key_question: 'Негізгі сұрақ',
    companies_form_constructor_language_label: 'Пішін тілі',
    companies_form_constructor_language_placeholder: 'тіл',
    companies_form_constructor_localization_label: 'Бүкіл пішін үшін локализация',
    companies_form_constructor_logo_place_center: 'Орталықтандырылған',
    companies_form_constructor_logo_place_label: 'Логотиптің орналасуы',
    companies_form_constructor_logo_place_left: 'Сол жақта',
    companies_form_constructor_logo_place_right: 'Оң жақта',
    companies_form_constructor_main_question: 'Компанияға өз рейтингіңізді қалдырыңыз',
    companies_form_constructor_main_scale: 'Негізгі шкала',
    companies_form_constructor_mascot: 'Тұмар',
    companies_form_constructor_max_placeholder: 'Өте керемет',
    companies_form_constructor_min_placeholder: 'Екіталай',
    companies_form_constructor_name_field_required: 'Name input field required',
    companies_form_constructor_name_placeholder: 'Сіздің атыңыз кім?',
    companies_form_constructor_name_title: 'Аты',
    companies_form_constructor_next_field: 'Next field',
    companies_form_constructor_not_checked_elements_color_title: 'Color of not checked elements elements',
    companies_form_constructor_one_from_list_placeholder: 'Сіз біздің мекемеге қашаннан бері келе жатырсыз?',
    companies_form_constructor_one_from_list_title: 'Тізімнің бірі',
    companies_form_constructor_phone_placeholder: 'Телефон нөміріңізді енгізіңіз',
    companies_form_constructor_phone_title: 'Телефон нөмірі',
    companies_form_constructor_prev_field: 'Prev field',
    companies_form_constructor_privacy_policy_label: 'Қажетті құжатты таңдаңыз',
    companies_form_constructor_privacy_policy_placeholder: 'Құжатты таңдаңыз',
    companies_form_constructor_privacy_policy_title: 'Құпиялылық саясаты',
    companies_form_constructor_quantity_button_signs_color: 'Button signs color',
    companies_form_constructor_quantity_button_signs_color_placeholder: 'Select button sings color',
    companies_form_constructor_quantity_buttons_background_color: 'Buttons background color',
    companies_form_constructor_quantity_buttons_background_color_placeholder: 'Select buttons background color',
    companies_form_constructor_quantity_default_value_title: 'Default value',
    companies_form_constructor_quantity_max_value_title: 'Max value',
    companies_form_constructor_quantity_min_value_title: 'Min value',
    companies_form_constructor_quantity_placeholder: 'How much times did you come to our place?',
    companies_form_constructor_quantity_title: 'Quantity',
    companies_form_constructor_required_question: 'Міндетті сұрақ',
    companies_form_constructor_scale_change_design_button: 'Масштабтың дизайнын өзгертіңіз',
    companies_form_constructor_scale_title: 'Масштаб',
    companies_form_constructor_select_field: 'Өрісті таңдаңыз',
    companies_form_constructor_send_button: 'Түйме',
    companies_form_constructor_send_button_background_color: 'Түйменің фон түсі',
    companies_form_constructor_send_button_link: 'Түйменің сілтемесі',
    companies_form_constructor_send_button_text: 'Түйме мәтіні',
    companies_form_constructor_send_button_text_color: 'Түйменің мәтін түсі',
    companies_form_constructor_send_button_title: 'Жіберу түймесі',
    companies_form_constructor_send_disabled_button_text_color: 'Disabled button\'s text color',
    companies_form_constructor_set_default_agreed: 'Set default agreed',
    companies_form_constructor_several_from_list_placeholder: 'Сіз біздің мекемеге келгеніңізге қанша уақыт болды?',
    companies_form_constructor_several_from_list_title: 'Кейбір тізім',
    companies_form_constructor_show_additional_field: 'Show additional field',
    companies_form_constructor_show_name_input_field: 'Show name input field',
    companies_form_constructor_show_text_in_two_rows: 'Show text in two rows',
    companies_form_constructor_signature_label: 'Қолы',
    companies_form_constructor_signature_max_label: 'максимум',
    companies_form_constructor_signature_min_label: 'минимум',
    companies_form_constructor_start_page_button_width: 'Start page button width',
    companies_form_constructor_status_label: 'Мәртебесі',
    companies_form_constructor_step_text: 'Step text',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Write step text',
    companies_form_constructor_step_text_underline_color: 'Step text underline color',
    companies_form_constructor_step_text_underline_color_placeholder: 'Select step text underline color',
    companies_form_constructor_submit_button: 'Пішінді сақтау',
    companies_form_constructor_tab_diagram: 'Diagram',
    companies_form_constructor_tab_field: 'Field',
    companies_form_constructor_tab_fields: 'Fields',
    companies_form_constructor_tab_settings: 'Settings',
    companies_form_constructor_text: 'Мәтін...',
    companies_form_constructor_thank_you_page_field_title: 'Алғыс парақшасы',
    companies_form_constructor_thank_you_page_text: 'Алғыс парағының мәтіні',
    companies_form_constructor_thank_you_page_text_first_line: 'Thank you page text first line',
    companies_form_constructor_thank_you_page_text_second_line: 'Thank you page text second line',
    companies_form_constructor_thank_you_page_title: 'Алғыс бет атауы',
    companies_form_constructor_title: 'Пішін конструкторы',
    companies_form_constructor_title_additional_field_placeholder: 'Another',
    companies_form_constructor_title_additional_field_placeholder_title: 'Additional field placeholder',
    companies_form_constructor_title_placeholder: 'Егер сіз пікір қалдырсаңыз, біз қуанамыз',
    companies_form_constructor_title_placeholder_title: 'Placeholder',
    companies_form_constructor_title_title: 'Қолы',
    companies_form_constructor_upload_files_label: 'Пішін бетінде файлдарды жүктеудің қандай түрлері қолжетімді болатынын таңдаңыз',
    companies_form_constructor_upload_files_placeholder: 'Пішінге файлдарды тіркеуге болатындығын түсіндіру',
    companies_form_constructor_upload_files_title: 'Файлды жүктеу',
    companies_form_download_qr: 'QR кодын жүктеп алыңыз',
    companies_form_scale_constructor_change_background_checkbox_label: 'Рейтинг өзгерген кезде фонды өзгертіңіз',
    companies_form_scale_constructor_change_background_select_label: 'Фон түсі',
    companies_form_scale_constructor_change_background_select_placeholder: 'Түсті таңдаңыз',
    companies_form_scale_constructor_change_mascot_checkbox_label: 'Рейтинг өзгерген кезде тұмарды өзгертіңіз',
    companies_form_scale_constructor_change_mascot_select_label: 'Тұмарлар',
    companies_form_scale_constructor_change_mascot_select_placeholder: 'Тұмарды таңдаңыз',
    companies_form_scale_constructor_choose_color_placeholder: 'Түсті таңдаңыз',
    companies_form_scale_constructor_file_not_uploaded: 'файл жүктелмеген',
    companies_form_scale_constructor_main_gradient_colors_label: 'Негізгі градиент түстері',
    companies_form_scale_constructor_maximum_value_label: 'Максималды мән',
    companies_form_scale_constructor_maximum_value_placeholder: 'Максималды мәнді енгізіңіз',
    companies_form_scale_constructor_minimum_value_label: 'Әдепкі мәнді енгізіңіз',
    companies_form_scale_constructor_minimum_value_placeholder: 'Әдепкі мән',
    companies_form_scale_constructor_numbers_chosen_colors_label: 'Таңдалған жауаптың түсі',
    companies_form_scale_constructor_numbers_colors_label: 'Сандардың түсі',
    companies_form_scale_constructor_pointer_color_label: 'Көрсеткіш түсі',
    companies_form_scale_constructor_show_default_value: 'Show default value',
    companies_form_scale_constructor_signature_text_color_label: 'Signature text color',
    companies_form_scale_constructor_star_chosen_color_label: 'Таңдалған жұлдыздың түсі',
    companies_form_scale_constructor_star_not_chosen_color_label: 'Таңдалмаған жұлдыздың бояу түсі',
    companies_form_scale_constructor_star_not_chosen_contour_color_label: 'Жұлдызша контурының түсі',
    companies_form_scale_constructor_tab_title: 'Масштаб',
    companies_form_scale_constructor_title: 'Масштабты конструктор',
    companies_form_select_color_placeholder: 'Select color',
    companies_form_show_node_code: 'Show node code',
    companies_form_table_add_form_button: 'Пішінді қосу',
    companies_form_table_constructor_button: 'Пішін конструкторы',
    companies_form_table_copy_button: 'Пішінді көшіру',
    companies_form_table_copy_confirm_text: 'Пішінді көшіргіңіз келетініне сенімдісіз бе?',
    companies_form_table_delete_button: 'Пішінді жою',
    companies_form_table_delete_confirm_text: 'Пішінді алып тастағыңыз келетініне сенімдісіз бе?',
    companies_form_table_edit_button: 'Edit',
    companies_form_table_header_feedbacks: 'Пікірлер',
    companies_form_table_header_last_edit: 'Соңғы өзгеріс',
    companies_form_table_header_link: 'Сілтеме',
    companies_form_table_header_name: 'Пішін атауы',
    companies_form_table_header_status: 'Мәртебесі',
    companies_form_table_new_form_language_placeholder: 'Жаңа пішін тілін таңдаңыз',
    companies_form_table_new_form_language_title: 'Жаңа форма тілі',
    companies_form_table_new_form_name_placeholder: 'Жаңа пішіннің атын енгізіңіз',
    companies_form_table_new_form_name_title: 'Пішіннің жаңа атауы',
    companies_form_table_new_node_language_placeholder: 'Жаңа түйін тілін таңдаңыз',
    companies_form_table_new_node_language_title: 'Жаңа түйін тілі',
    companies_form_table_new_node_name_placeholder: 'Жаңа түйін атауын енгізіңіз',
    companies_form_table_new_node_name_title: 'Жаңа түйін атауы',
    companies_form_table_node_to_copy: 'Пішінді қайда көшіргіңіз келеді ме?',
    companies_form_table_thank_button: 'Алғыс парағы',
    companies_form_table_title: 'Компанияның кері байланыс нысандары',
    companies_form_use_as_default_form: 'Әдепкі пішін ретінде пайдаланыңыз',
    companies_form_with_steps: 'Form with steps',
    companies_info_add_field_button: 'Өрісті қосу',
    companies_info_after_save_modal_confirm_button: 'Компания деректерін өңдеу',
    companies_info_after_save_modal_title: 'Компания сәтті құрылды',
    companies_info_choose_file_button: 'Файлды таңдаңыз',
    companies_info_company_domain_label: 'Компания домені',
    companies_info_company_domain_placeholder: 'Компания доменін енгізіңіз',
    companies_info_company_extra_field_name_placeholder: 'Өрістің атын енгізіңіз',
    companies_info_company_extra_field_value_placeholder: 'Өріс мәнін енгізіңіз',
    companies_info_company_name_label: 'Компания атауы',
    companies_info_company_name_placeholder: 'Компанияның атын енгізіңіз',
    companies_info_confirmation_before_save_modal_title: 'Are you sure that you want to edit company information?',
    companies_info_file_max_size: 'JPG GIF немесе PNG. Максималды өлшемі 800K',
    companies_info_file_not_selected: 'Файл таңдалмаған',
    companies_info_submit_create_button: 'Компанияны сақтаңыз',
    companies_info_submit_edit_button: 'Өзгерістерді сақтау',
    companies_info_tab_base_info: 'Негізгі ақпарат',
    companies_info_tab_design: 'Дизайн',
    companies_info_tab_documents: 'Құжаттар',
    companies_info_tab_issues_statuses_and_types: 'Мәселелердің күйлері мен түрлері',
    companies_info_tab_review_table_columns_settings: 'Columns settings',
    companies_info_tab_sources: 'Sources',
    companies_info_tab_templates: 'Үлгілер (Шаблондар)',
    companies_info_title_add: 'Компания қосу',
    companies_info_title_edit: 'Компанияны өңдеу',
    companies_issue_statuses_and_types_add_status_button: 'Күй қосу',
    companies_issue_statuses_and_types_add_status_placeholder: 'Күй атауын енгізіңіз',
    companies_issue_statuses_and_types_statuses_text: 'Күйлер',
    companies_issue_statuses_and_types_type_add_button: 'Түр қосу',
    companies_issue_statuses_and_types_type_add_placeholder: 'Түр атауын енгізіңіз',
    companies_issue_statuses_and_types_types_text: 'Түрлері',
    companies_issues_and_types_confirmation_before_save_modal_title: 'Are you sure you want to edit statuses and issue types?',
    companies_main_add_company_button: 'Компания қосу',
    companies_main_delete_description: 'Сіз компанияны жойғыңыз келетініне сенімдісіз бе?',
    companies_main_delete_title: 'Компанияны жою',
    companies_main_forms_button: 'Байланыс формалары',
    companies_main_structure_button: 'Құрылымы',
    companies_main_table_company_name_header: 'Компания',
    companies_main_title: 'Компаниялар',
    companies_review_table_columns_settings_title: 'Columns',
    companies_review_title: 'Шолу',
    companies_reviews_table_header_button_details: 'Толығырақ',
    companies_reviews_table_header_contact: 'Арна',
    companies_reviews_table_header_contact_type: 'Байланыс түрі',
    companies_reviews_table_header_create: 'Құрылған күні',
    companies_reviews_table_header_estimate: 'Бағалау',
    companies_reviews_table_header_files: 'Тіркелген файлдар',
    companies_reviews_table_header_key_answer: 'Негізгі жауап',
    companies_reviews_table_header_language: 'Тіл',
    companies_reviews_table_header_location: 'Орындар',
    companies_reviews_table_header_source: 'Source',
    companies_reviews_table_header_status: 'Мәртебесі',
    companies_reviews_table_title: 'Пікірлер',
    companies_source_must_be_unique_text: 'бірегей болуы керек',
    companies_source_name_label: 'Дереккөздің аты',
    companies_source_name_max_length_error: 'Дереккөз атауы 100 таңбадан аз болуы керек',
    companies_sources_add_source_button: 'Дереккөзді қосу',
    companies_sources_confirmation_before_save_modal_title: 'Are you sure you want to edit sources?',
    companies_sources_create_new_source_title: 'Жаңа дереккөз жасау',
    companies_sources_create_source_text: 'Дереккөз жасау',
    companies_sources_placeholder: 'Дереккөздің атын енгізіңіз',
    companies_sources_title: 'Дереккөздер',
    companies_structure: 'Құрылымы',
    companies_structure_create_accountable: 'Жауапты(лар)',
    companies_structure_create_address_label: 'Мекен-жайы',
    companies_structure_create_address_placeholder: 'Мекенжайды енгізіңіз',
    companies_structure_create_advanced_settings: 'Қосымша параметрлер',
    companies_structure_create_edit_title: 'Түйінді өңдеу',
    companies_structure_create_email: 'Электрондық пошта',
    companies_structure_create_google_place_id_label: 'Google-дегі орын идентификаторы',
    companies_structure_create_integration_alpha_name_label: 'Альфа атауы',
    companies_structure_create_integration_key_label: 'Кілт',
    companies_structure_create_integration_title: 'Интеграция параметрлері',
    companies_structure_create_modal_title: 'Сіз түйін жасағыңыз келетініне сенімдісіз бе?',
    companies_structure_create_node_code_label: 'Түйін коды',
    companies_structure_create_node_code_placeholder: 'Түйін кодын енгізіңіз',
    companies_structure_create_node_name_label: 'Түйіннің аты',
    companies_structure_create_node_name_placeholder: 'Түйіннің атын енгізіңіз',
    companies_structure_create_sms: 'қысқаша хабар қызметі (смс)',
    companies_structure_create_telegram: 'Телеграм',
    companies_structure_create_tied_form_placeholder: 'Пішін',
    companies_structure_create_tied_node_label: 'Байланған түйін',
    companies_structure_create_tied_node_placeholder: 'түйін',
    companies_structure_create_title: 'Түйін жасаңыз',
    companies_structure_create_viber: 'Вайбер',
    companies_structure_lang_delay: 'Don\t use an expiration timer',
    companies_structure_lang_detection: 'Пішін тілін автоматты түрде анықтауды қолданыңыз',
    companies_structure_table_accountable: 'Жауапты',
    companies_structure_table_add_node_button: 'Түйін қосу',
    companies_structure_table_delete_description_1: 'Сіз түйінді жойғыңыз келетініне сенімдісіз',
    companies_structure_table_delete_description_2: 'компания құрылымдары',
    companies_structure_table_delete_description_3: 'Бұл барлық кірістірілген түйіндерді жояды',
    companies_structure_table_delete_title: 'Компания құрылымының түйінін жою',
    companies_structure_table_header_name: 'Түйіндер',
    companies_structure_title: 'Компанияның құрылымы',
    companies_structure_update_modal_title: 'Сіз түйінді өңдегіңіз келетініне сенімдісіз бе?',
    company: 'Компания',
    company_main_info_delete_title: 'Компанияны жою',
    company_main_info_edit_title: 'Компания туралы негізгі ақпаратты өңдеу компанияның құжаттарын рәсімдеу',
    company_main_info_go_to_company_structure: 'Компания құрылымына өтіңіз',
    company_structure_delete_button_title: 'Түйінді жою',
    company_structure_edit_button_title: 'Түйінді өңдеу',
    confirm_button: 'Confirm',
    connect_button: 'Қосылу үшін',
    continue_editing_button: 'Өңдеуді жалғастыру',
    continue_review_dialog_button: 'Диалогты жалғастырыңыз',
    copy_button: 'Көшіру',
    copy_error: '* Көшіру кезінде қате пайда болды',
    create_button: 'Жасау',
    data_save_error: '* Деректерді сақтау кезінде қате пайда болды',
    delete_button: 'Жою',
    delete_error: '* Жою кезінде қате пайда болды',
    document: 'Құжат',
    done_button: 'Жасалды',
    edit_button: 'Өңдеу',
    email: 'Электрондық пошта',
    email_label: 'Электрондық пошта',
    email_placeholder: 'Электрондық пошта мекенжайын енгізіңіз',
    employee_profile_title: 'Қызметкердің профилі',
    enter_button: 'Кіру',
    enter_forgot_button: 'Құпия сөзді қалпына келтіру',
    enter_task_name: 'Enter task name',
    error: '* Қате пайда болды',
    error_text: 'Error text',
    error_title: 'Error',
    expecting_reply_from_client: 'Клиенттің жауабын күту',
    filter_title_sources: 'Sources',
    filter_title_status: 'Мәртебелері',
    filters: 'Сүзгілер',
    finish_review_dialog_button: 'Диалогты аяқтаңыз',
    finish_review_dialog_modal_description: 'Сіз диалогты аяқтағыңыз келетініне сенімдісіз бе?',
    finish_review_dialog_modal_title: 'Диалогты аяқтаңыз',
    form: 'Пішін',
    form_constructor_connection_case_label: 'Нұсқа',
    form_constructor_connection_case_placeholder: 'Опцияны таңдаңыз',
    form_constructor_connection_go_to: 'ауысу',
    form_constructor_connection_render_minifield_connection_always: 'Әрқашан',
    form_constructor_connection_render_minifield_connection_type_texts_always: 'Барлық басқа жағдайлар',
    form_constructor_connection_render_minifield_connection_type_texts_if: 'Егер болса',
    form_constructor_connection_select_field: 'Өрісті таңдаңыз',
    form_constructor_connection_select_with_any_items_label: 'Келесі өріс',
    form_constructor_connection_select_with_any_items_options_1_question: 'Таңдалмаған',
    form_constructor_connection_select_with_any_items_placeholder: 'Өрісті таңдаңыз',
    form_constructor_connection_statement_label: 'Ереже',
    form_constructor_connection_statement_placeholder: 'Ережені таңдаңыз',
    form_constructor_delete_field_modal_description: 'Are you sure you want to delete this field?',
    form_constructor_delete_field_modal_title: 'Warning!',
    form_constructor_diagram_fields_list_is_empty: 'Өрістер тізімі бос',
    form_constructor_fields_form_content: 'Мазмұны',
    form_constructor_fields_form_documents: 'Құжаттар',
    form_constructor_fields_form_no_fields_selected: 'Таңдалған өріс жоқ',
    form_constructor_fields_form_send_button: 'Жіберу түймесі',
    form_constructor_fields_form_thank_you_page: 'Рахмет парақшасы',
    form_copy_save_error: '* Таңдалған түйінде бірдей атаумен пішін бар',
    form_language_error: '* Сізде таңдалған тілдегі форма бар',
    form_name_error: '* Пішіннің атауы ерекше болуы керек',
    form_save_error: '* Пішінді сақтау кезінде қате пайда болды',
    from: 'Бастап',
    full_name_label: 'Қызметкердің толық аты-жөні',
    full_name_placeholder: 'Қызметкердің атын енгізіңіз',
    generate_button: 'Жасау',
    go_to_forms_page_button_title: 'Кері байланыс пішіндеріне өтіңіз',
    go_to_issues_create: 'Мәселелерді жасау бөліміне өтіңіз',
    go_to_reviews_page_button_title: 'Пікірлерге өтіңіз',
    go_to_task_create: 'Тапсырма жасауға өтіңіз',
    in_progress: 'Орындалуда',
    issues_add_issue: 'Мәселені қосыңыз',
    issues_form_company_label: 'Компания',
    issues_form_company_placeholder: 'Компанияны таңдаңыз',
    issues_form_connect_issue_label: 'Қосылу мәселесі',
    issues_form_connect_issue_placeholder: 'Мәселені таңдаңыз',
    issues_form_create_new_issue_status: 'Мәселенің жаңа күйін жасаңыз',
    issues_form_create_new_issue_status_title: 'Күй құру',
    issues_form_create_new_issue_type: 'Мәселенің жаңа түрін жасаңыз',
    issues_form_create_new_issue_type_title: 'Түрін жасау',
    issues_form_must_be_unique: 'бірегей болуы керек',
    issues_form_name_label: 'Эмитенттің атауы',
    issues_form_name_placeholder: 'Пішін атауын енгізіңіз',
    issues_form_node_label: 'Түйін',
    issues_form_node_placeholder: 'Түйінді таңдаңыз',
    issues_form_priority_label: 'Мәселенің басымдығы',
    issues_form_priority_placeholder: 'Басымдықты таңдаңыз',
    issues_form_responsible_label: 'Жауапты',
    issues_form_responsible_placeholder: 'Жауапты таңдаңыз',
    issues_form_review_label: 'Шолу',
    issues_form_review_placeholder: 'Пікірді таңдаңыз',
    issues_form_status_label: 'Сұрақтың мәртебесі',
    issues_form_status_name_label: 'Мәселе күйінің атауы',
    issues_form_status_placeholder: 'Күйді таңдаңыз',
    issues_form_type_label: 'Мәселе түрі',
    issues_form_type_name_label: 'Шығарылым түрінің атауы',
    issues_form_type_placeholder: 'Мәселе түрін таңдаңыз',
    issues_save_modal_create_title: 'Мәселе жасағыңыз келетініне сенімдісіз бе?',
    issues_save_modal_edit_title: 'Мәселені өңдегіңіз келетініне сенімдісіз бе?',
    issues_search_by_name: 'Мәселені тақырып бойынша іздеу',
    issues_sub_header_create_issue_title: 'Мәселені жасаңыз',
    issues_sub_header_create_task_title: 'Тапсырма құру',
    issues_sub_header_edit_issue_title: 'Мәселені өңдеу',
    issues_sub_header_title: 'Мәселелер',
    issues_table_empty: 'Мәселелерді көрсету үшін компанияны таңдаңыз',
    issues_table_header_company: 'Компания',
    issues_table_header_date: 'Жасалу күні',
    issues_table_header_name: 'Аты',
    issues_table_header_node: 'Түйін',
    issues_table_header_priority: 'Басымдық',
    issues_table_header_status: 'Мәртебесі',
    issues_table_header_type: 'Мәселе түрі',
    language: 'Тіл',
    languages_add_name_placeholder: 'Атын енгізіңіз',
    languages_add_short_name_label: 'Қысқаша атауы',
    languages_add_short_name_placeholder: 'Қысқаша атауды енгізіңіз',
    languages_add_title: 'Тіл қосу',
    languages_table_add_language_button: 'Тіл қосу',
    languages_table_header_full_name: 'Толық аты',
    languages_table_header_short_name: 'Қысқаша атауы',
    languages_table_modal_delete_buttons_label_end: 'тіл?',
    languages_table_modal_delete_buttons_label_start: 'Сіз жойғыңыз келетініне сенімдісіз',
    languages_table_modal_delete_title: 'Тілді жою',
    languages_table_title: 'Тілдер',
    leave_the_page: 'Беттен кету',
    loading_error: '* Деректерді жүктеу кезінде қате пайда болды',
    login_recovery_auth: 'Қайту',
    login_recovery_complete: 'Құпия сөзді қалпына келтіру туралы нұсқаулары бар хабарлама жақын арада электрондық пошта мекенжайыңызға жіберіледі',
    mass_mailing_type_by_addresses: 'Мекенжай Ақпараттық бюллетені',
    mass_mailing_type_by_company: 'Компания бойынша',
    max_size_error: '* Рұқсат етілген файл өлшемінен асып кетті',
    max_size_error_25: '* Файлдың рұқсат етілген ең үлкен өлшемі-25 МБ',
    max_table_column_settings_apply_warning: 'Max 6 active settings',
    more_detailed: 'More detailed',
    name: 'Аты',
    new_task_name: 'Жаңа тапсырма атауы',
    next_button: 'Келесі',
    no_options_available: 'Опциялар жоқ',
    node: 'Түйін',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes,',
    not_decided: 'Шешілген жоқ',
    not_viewed: 'Көрінбейді',
    nothing_find: 'Not found,',
    login_recovery_password_title: 'Жоғалған құпия сөзді қалпына келтіру',
    open_issue_title: 'Ашық сұрақ',
    open_language_title: 'Ашық тіл',
    open_permission_title: 'Ашуға рұқсат',
    open_review_title: 'Ашық шолу',
    open_role_title: 'Ашық рөл',
    open_user_title: 'Ашық пайдаланушы',
    other: 'Other',
    overdue_status_text: 'Мерзімі өткен',
    pagination_page_number_placeholder: 'Page number',
    pagination_title: 'Беттегі жолдар',
    password: 'Құпия сөз',
    password_confirmation_label: 'Құпия сөзді қайталаңыз',
    password_confirmation_placeholder: 'Құпия сөзді қайтадан енгізіңіз',
    password_label: 'Құпия сөз',
    password_placeholder: 'Құпия сөзді енгізіңіз',
    permission_create_title: 'Жасауға рұқсат',
    permission_edit_title: 'Өңдеуге рұқсат',
    permission_name_placeholder: 'Рұқсаттың атын енгізіңіз',
    permission_name_title: 'Рұқсат атауы',
    permission_resource_placeholder: 'Рұқсат ресурсын енгізіңіз',
    permission_resource_title: 'Ресурс',
    permissions_main_add_permission_button: 'Рұқсат қосу',
    permissions_main_delete_description: 'Сіз бұл рұқсатты жойғыңыз келетініне сенімдісіз бе?',
    permissions_main_delete_title: 'Рұқсатты жою',
    permissions_main_permissions_button: 'Рұқсаттар',
    permissions_main_table_create_header: 'Жасау',
    permissions_main_table_delete_header: 'Жою',
    permissions_main_table_permission_header: 'Рұқсат атауы',
    permissions_main_table_read_header: 'Оқыңыз',
    permissions_main_table_resource_header: 'Ресурс',
    permissions_main_table_update_header: 'Жаңарту',
    permissions_main_title: 'Рұқсаттар',
    phone: 'Телефон',
    phone_number_label: 'Телефон нөмірі',
    phone_number_placeholder: 'Телефон нөмірін енгізіңіз',
    photo: 'Фотосурет',
    preview_button: 'Алдын ала қарау',
    privacy_policy: 'пайдаланушы келісімі',
    privacy_policy_confirm: 'Мен келісемін',
    record: 'Аудио',
    refresh_all_fitlers: 'Барлық сүзгілерді қалпына келтіріңіз',
    registration_profile_completion: 'Профильді толтыру',
    reopened: 'Қайта ашылды',
    reset_reviews_filter: 'Reset filter',
    reset_table_settings: 'Reset table settings',
    review_choose_template: 'Жауап үлгісін таңдаңыз',
    review_created_at: 'Review date and time',
    review_dialog_finished_text: 'Диалог аяқталды',
    review_form_name: 'Пішін атауы',
    review_form_source: 'Form source',
    review_handle_continue_dialog_error_message: 'Бірдеңе дұрыс емес. Кейінірек қайталап көріңіз.',
    review_message_input_placeholder: 'Хабарламаңызды жазыңыз',
    review_no_asscociated_issues: 'Байланысты мәселелер жоқ',
    review_no_associated_tasks: 'Байланысты міндеттер жоқ',
    review_node_code: 'Түйін коды',
    review_node_name: 'Түйіннің аты',
    review_on_submit_3_error_message: 'Клиенттің басқа менеджермен диалогы бар. Кейінірек қайталап көріңіз.',
    review_open_task_button_text: 'Open task',
    review_page_type_of_task: 'Тапсырма түрі',
    review_review_id: 'Кері байланыс идентификаторы',
    review_save_modal_title: 'Сіз шолуды өңдегіңіз келетініне сенімдісіз бе?',
    review_updated_at: 'Processing date,',
    reviews_page_choose_company_title: 'Select a company from the drop-down list',
    reviews_table_comment_column_title: 'Comment',
    reviews_table_email_column_title: 'Email',
    reviews_table_filter_title: 'Filter',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_node_column_title: 'Node',
    reviews_table_parent_node_column_title: 'Parent node',
    reviews_table_phone_column_title: 'Phone number',
    reviews_table_review_id_column_title: 'Review ID',
    reviews_table_ukrposhta_settings_button: 'Unprocesed reviews',
    reviews_table_user_name_column_title: 'Name',
    reviewType: 'Шолу түрі',
    role_create_title: 'Рөл жасау',
    role_edit_title: 'Рөлді өңдеу',
    role_name_placeholder: 'Рөл атауын енгізіңіз',
    role_name_title: 'Рөл атауы',
    role_page_create_title: 'Рөл жасау',
    role_permissions: 'Рұқсаттар',
    role_select_permissions_placeholder: 'Рұқсаттарды таңдаңыз',
    roles_main_add_role_button: 'Рөл қосу',
    roles_main_delete_description: 'Сіз бұл рөлді жойғыңыз келетініне сенімдісіз бе?',
    roles_main_delete_title: 'Рөлді жою',
    roles_main_permissions_button: 'Рұқсаттар',
    roles_main_table_user_role_header: 'Рөл атауы',
    roles_main_title: 'Рөлдер',
    roles_part_analytic: 'Аналитика',
    roles_part_companies: 'Компания',
    roles_part_documents: 'Құжаттар',
    roles_part_forms: 'Пішіндер',
    roles_part_languages: 'Тілдер',
    roles_part_mass_mailings: 'Жаппай тарату',
    roles_part_nodes: 'Түйіндер',
    roles_part_reviews: 'Шолу',
    roles_part_users: 'Пайдаланушылар',
    roles_sub_permission_create: 'Жасау',
    roles_sub_permission_delete: 'Жою',
    roles_sub_permission_read: 'Оқыңыз',
    roles_sub_permission_update: 'Жаңарту',
    save_button: 'Сақтау',
    save_changes_button: 'Өзгерістерді сақтау',
    save_document_button: 'Сақтау',
    save_draft_button: 'Жобаны сақтау',
    save_field_button: 'Save field',
    save_form_button: 'Пішінді сақтау',
    save_form_without_changes_button: 'Пішінді өзгеріссіз сақтаңыз',
    search: 'Іздеу...',
    search_for_name: 'Аты бойынша іздеу',
    search_for_email: 'Search by email',
    select_company: 'Компанияны таңдаңыз',
    select_form: 'Пішінді таңдаңыз',
    select_language: 'Тілді таңдаңыз',
    select_multiple_sources: 'Select sources',
    select_multiple_statuses: 'Select statuses',
    select_node: 'Түйінді таңдаңыз',
    select_status: 'Күйді таңдаңыз',
    select_type: 'Түрін таңдаңыз',
    send_button: 'Жіберу',
    sidebar_analytic: 'Аналитика',
    sidebar_companies: 'Компаниялар',
    sidebar_exit: 'Профильден шығу',
    sidebar_issues: 'Мәселелер',
    sidebar_languages: 'Тілдер',
    sidebar_profile: 'Профиль',
    sidebar_reviews: 'Пікірлер',
    sidebar_tasks: 'Тапсырмалар',
    sidebar_users: 'Пайдаланушылар',
    sms: 'қысқаша хабар қызметі (смс)',
    social_networks_module_text: 'Бізбен байланысудың ыңғайлы әдісін таңдаңыз',
    solution_not_required: 'Шешім \n қажет емес',
    solved: 'Шешілді',
    source: 'Дереккөз',
    status: 'Қарау мәртебесі',
    status_code: 'Status code',
    table_columns_settings_comment_option: 'Comment',
    table_columns_settings_communication_channel_option: 'Communication type',
    table_columns_settings_company_option: 'Company',
    table_columns_settings_created_at_option: 'Created at',
    table_columns_settings_email_option: 'Email',
    table_columns_settings_estimation_option: 'Score',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_language_option: 'Language',
    table_columns_settings_name_option: 'Name',
    table_columns_settings_node_option: 'Node',
    table_columns_settings_parent_node_option: 'Parent node',
    table_columns_settings_phone_option: 'Phone number',
    table_columns_settings_reviewId_option: 'Review ID',
    table_columns_settings_source_option: 'Source',
    table_columns_settings_status_option: 'Status',
    table_settings: 'Table settings',
    task_form_category_label: 'Category of task',
    task_form_create_task: 'Create task',
    task_form_list_category_label: 'Category',
    task_form_list_subcategory_label: 'Subcategory',
    task_form_list_type_label: 'Type',
    task_form_name_label: 'Тапсырма атауы',
    task_form_priority_label: 'Тапсырманың басымдығы',
    task_form_status_label: 'Тапсырма мәртебесі',
    task_form_subcategory_label: 'Subcategory of task',
    task_form_type_label: 'Тапсырма түрі',
    telegram: 'Телеграмм',
    to: 'Кімге',
    to_plug_button: 'Қосылу үшін',
    turn_off_button: 'Өшіру',
    upload: 'Жүктеу',
    upload_color_button: 'Түсті жүктеу',
    uploading_files: 'Файлдарды жүктеу',
    user_email_placeholder: 'Электрондық пошта мекенжайын енгізіңіз',
    user_email_title: 'Электрондық пошта',
    user_name_placeholder: 'Атын енгізіңіз',
    user_name_title: 'Аты - жөні',
    user_new_password_placeholder: 'Жаңа құпия сөзді енгізіңіз',
    user_new_password_title: 'Жаңа құпия сөз',
    user_old_password_placeholder: 'Ескі құпия сөзді енгізіңіз',
    user_old_password_title: 'Ескі құпия сөз',
    user_page_create: 'Пайдаланушы жасаңыз',
    user_page_edit: 'Пайдаланушыны өңдеу',
    user_password_confirmation_placeholder: 'Құпия сөзді қайталаңыз',
    user_password_confirmation_title: 'Құпия сөзді растау',
    user_password_placeholder: 'Құпия сөзді енгізіңіз',
    user_password_title: 'құпия сөз',
    user_role_placeholder: 'Пайдаланушы рөлдерін таңдаңыз',
    user_role_title: 'Рөлдер',
    users_main_add_user_button: 'Пайдаланушыны қосу',
    users_main_delete_description: 'Пайдаланушыны жойғыңыз келетініне сенімдісіз бе?',
    users_main_delete_title: 'Пайдаланушыны жою',
    users_main_roles_button: 'Рөлдер',
    users_main_table_user_company_header: 'Компания',
    users_main_table_user_email_header: 'Электрондық пошта',
    users_main_table_user_name_header: 'Аты',
    users_main_table_user_notifications_header: 'Хабарламалар',
    users_main_table_user_role_header: 'Рөлі',
    users_main_title: 'Пайдаланушылар',
    users_main_upload_users_button: 'Пайдаланушыларды жүктеу',
    validation_constructor_at_least_2_possible_answers: '* Сұрақтың кем дегенде 2 ықтимал жауабы болуы керек',
    validation_constructor_should_have_at_least_1_document: '* Нысанда кемінде бір құжат болуы керек',
    validation_constructor_should_select_file_types: '* Жүктеп салуға рұқсат етілген файл түрлерін таңдау керек немесе бұл өрісті пішіннен алып тастаңыз.',
    validation_domain: "Доменде тек ағылшын таңбаларының нөмірлері болуы мүмкін '.' және '-'",
    validation_form_should_have_minimum_1: '* Пішінде кем дегенде бір өріс болуы керек',
    validation_full_type_required: '* You must select each parts of type',
    validation_invalid_email: '* Жарамсыз электрондық пошта пішімі',
    validation_invalid_link: '* Жарамсыз сілтеме пішімі',
    validation_more_one_accountable: '* Сіз кем дегенде 1 түйін менеджерін таңдауыңыз керек',
    validation_more_one_option: '* Сіз кем дегенде 1 опцияны таңдауыңыз керек',
    validation_must_fill_all_fields_for: '* Сіз барлық өрістерді толтыруыңыз керек',
    validation_must_fill_this_field: '* Сіз бұл өрісті толтыруыңыз керек',
    validation_name_must_be_longer_4: '* Атау тым қысқа-кем дегенде 4 таңбадан тұруы керек.',
    validation_required: '* Міндетті',
    viber: 'Вайбер',
    video: 'Бейне',
    viewed: 'Қаралды',
    your_changes_lose: 'Өзгерістерді сақтау керек. Әйтпесе, олар жоғалады.',
    send_feedback: 'Пікір жіберу',
    send_feedback_title: 'Кері байланыс',
    send_feedback_label: 'Сіздің тілектеріңіз немесе пікірлеріңіз',
    send_feedback_complete: 'Пікіріңізге рахмет',
    sidebar_impex: 'Импорттау/Экспорттау',
    impex_page_title: 'Импорттау/Экспорттау',
    impex_import_tab: 'Импорттау',
    impex_export_tab: 'Экспорттау',
    select_import_entity: 'Нені импорттағыңыз келеді?',
    start_import_button_text: 'Импорттауды бастаңыз',
    choose_file_for_import: 'Импорттау үшін файлды таңдаңыз',
    import_entity_selection_title: 'To import',
    import_file_sheet_name_label: 'The name of the sheet in the import file',
    import_file_sheet_name_input_placeholder: 'Enter name of the sheet',
    interrupt_import_script_button_text: 'Interrupt import',
    import_file_format_text: 'Format must be:',
    import_progress_bar_title: 'Import progress:',
    import_users_entity_name: 'Users',
    import_nodes_entity_name: 'Nodes',
    qr_title: 'Хабарландыруларды одан әрі теңшеу үшін QR сканерін сканерлеңіз',
    mass_mailings_channels_of_connections: 'Қосылу арналары',
    mass_mailings_send_button: 'Жіберу',
    yes_button_text: 'Иә',
    no_button_text: 'Жоқ',
    confirm_send_mass_mailings_modal_title: 'Are you sure you want to make mass mailings?',
    modal_mass_mailings_sent_success_title: 'Messages sent successfully',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Error sending messages',
    mass_mailings_recipient_label: 'Recipient',
    mass_mailings_recipients_list_title: 'Recipients',
    add_at_least_one_recipient_error: 'At least one recipient must be added',
    sidebar_mass_mailings: 'Mass mailings',
    create_mass_mailings_page_title: 'Create mass mailings',
    create_mass_mailings_first_step_subheader_title: 'Mass mailing - Creating - Step 1 of 3',
    choose_mass_mailings_communication_channel: 'Choose communication channel',
    mass_mailings_add_recipient_button_text: 'Add recipient',
    download_recipients_file_label: 'Download a file with recipients',
    format: 'Format',
    choose_file: 'Choose a file',
    file_data_downloading_text: 'File data downloading',
    mass_mailings_mails_count: 'The number of mails that will be sent',
    create_sms_mass_mailings_second_step_subheader_title: 'Creating SMS-mailing - Step 2 of 3',
    create_viber_mass_mailings_second_step_subheader_title: 'Creating Viber-mailing - Step 2 of 3',
    create_sms_mass_mailings_third_step_subheader_title: 'Creating SMS-mailing - Step 3 of 3',
    create_viber_mass_mailings_third_step_subheader_title: 'Creating Viber-mailing - Step 3 of 3',
    mass_mailings_sender_account: 'Sender account',
    sms_text: 'SMS text',
    message: 'Message',
    message_text_placeholder: 'Message text',
    responsible: 'Жауапты',
    responsible_id: 'ID жауапты',
    companies_form_constructor_dividing_decorative_line: 'Пішінді бөлетін сәндік сызық параметрлері',
    companies_form_constructor_show_form_dividing_decorative_line: 'Пішіннің бөлетін сәндік сызығын көрсетіңіз',
    companies_form_constructor_dividing_decorative_line_width_in_percent: 'Сәндік сызықтың ұзындығы пайызбен',
    companies_form_constructor_dividing_decorative_line_height: 'Сәндік сызықтың пиксельдегі биіктігі',
    companies_form_constructor_dividing_decorative_line_height_placeholder: 'Сәндік сызықтың биіктігін көрсетіңіз',
    companies_form_constructor_dividing_decorative_line_color: 'Сәндік сызықтың түсі',
    companies_form_constructor_dividing_decorative_line_color_placeholder: 'Сәндік сызықтың түсін таңдаңыз',
    export_entity_selection_title: 'To export',
    select_export_entity: 'Нені экспорттағыңыз келеді?',
    export_button_text: 'Экспорттау',
    export_nodes_error_text: 'Түйіндерді экспорттау қатесі',
    export_users_error_text: 'Пайдаланушыларды экспорттау қатесі',
    quarter: 'Last 3 months',
    months: 'Last 1 months',
    days7: 'Last 7 days',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Phone',
    email_network: 'Email',
    whatsapp_network: 'WhatsApp',
  },
  RO: {
    pass_changed_success: 'Password changed successfully',
    connect_tg: 'Connect Telegram bot',
    connect_vb: 'Connect Viber bot',
    notifiable_sources: 'Notifiable Sources',
    notifications: 'Notifications',
    reset_pass: 'Reset password',
    audit: 'History audit',
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    review_audit: 'Review processing history',
    SYSTEM: 'System',
    USER: 'User',
    actor: 'Actor',
    action: 'Action',
    search_by_phone: 'Search by phone number',
    search_by_reviewID: 'Search by review ID',
    chat_error_no_user: 'The chat with the user was not found, maybe he did not connect or deleted the bot',
    chat_label: 'Chat with the client',
    comment_internal: 'Internal comment',
    changes_not_saved_modal: 'Modificările nu sunt salvate. Sunteți sigur că doriți să părăsiți pagina?',
    go_back: 'Mergi înapoi',
    all_items_uploaded: 'Toate articolele au fost încărcate',
    login_title: 'Loghează-te',
    login_recovery_password: 'Vă recuperati parola',
    add_comment: 'Adauga un comentariu',
    add_button: 'Adauga',
    add_option: 'Adăugați opțiunea',
    add_option_button: 'Adăugați o variantă',
    apply_filters_button: 'Aplicați filtre',
    apply_table_settings: 'Aplicati setarile tabelului',
    are_you_sure: 'Esti sigur?',
    attached_files: 'Fișiere atașate',
    bot_is_not_connected: 'Botul nu este conectat',
    cancel_button: 'Anulare',
    cant_decided: 'Nu se poate rezolva',
    change_new_form_button: 'Schimbați noul formular',
    changes_saved_successfully: 'Modificările au fost salvate cu succes',
    channels_for_notifications: 'Canale pentru notificare',
    channels_of_connections: 'Canale de conectare',
    chat_error_description: 'A apărut o eroare la trimiterea unui mesaj',
    chat_history_is_empty: 'Istoricul mesajului este gol',
    chat_is_finished: 'Chat terminat',
    choose_file_button: 'Selectați un fișier',
    choose_period: 'Alegeți perioada',
    client_form_scale_field_h3: 'Impresia dumneavoastră despre compania noastră',
    close_button: 'Închide',
    close_editing_button: 'Închideți editarea',
    closed_auto_no_replay: 'Închis automat din cauza niciunui răspuns de la client',
    color_picker_add_button: 'Adăugați culoare',
    comment_placeholder: 'Introdu comentariul',
    companies_columns_settings_confirmation_before_save_modal_title: 'Sigur doriți să editați setările coloanei pentru pagina de recenzie?',
    companies_design_add_color_button: 'Adăugați culoare',
    companies_design_add_color_placeholder: 'Introduceți numele culorii',
    companies_design_add_font_button: 'Adauga fond',
    companies_design_add_font_placeholder: 'Introduce numele fondului',
    companies_design_colors_text: 'Culori',
    companies_design_confirmation_before_save_modal_title: 'Sunteți sigur că doriți să editați informațiile despre companie?',
    companies_design_fonts_text: 'Fonduri',
    companies_design_logos_add_button: 'Incarca fisier',
    companies_design_logos_add_placeholder: 'Introduceți numele siglei',
    companies_design_logos_text: 'Imagini',
    companies_documents_add_localization: 'Localizare',
    companies_documents_add_name_label: 'Nume',
    companies_documents_add_name_placeholder: 'Introduceți numele documentului',
    companies_documents_add_title: 'Adăugarea unui document',
    companies_documents_add_title_editing: 'Editarea documentului',
    companies_documents_file_caption: 'Fişier',
    companies_documents_file_format: 'Format',
    companies_documents_file_max_size: 'Dimensiunea maximă a fișierului',
    companies_documents_file_not_loaded: 'nu este încărcat',
    companies_documents_file_save_button: 'Incarca fisier',
    companies_documents_file_text_editor_label: 'Textul documentului',
    companies_documents_file_text_editor_placeholder: 'Introduceți textul documentului',
    companies_documents_table_add_document_button: 'Adăugați document',
    companies_documents_table_delete_text: 'Sigur doriți să ștergeți documentul?',
    companies_documents_table_delete_title: 'Ștergerea unui document',
    companies_documents_table_header_edit_date: 'Data modificării',
    companies_documents_table_header_edit_languages: 'Limbi',
    companies_documents_table_header_name: 'Nume',
    companies_form_add_anonymous_checkbox_no: 'Niciun răspuns nu poate fi anonim.',
    companies_form_add_anonymous_checkbox_yes: 'Răspunsurile Da pot fi anonime',
    companies_form_add_anonymous_label: 'Posibilitatea de a lăsa recenzii anonime',
    companies_form_add_create_title: 'Adăugați un formular de feedback',
    companies_form_add_edit_title: 'Editarea unui formular de feedback',
    companies_form_add_link_label: 'Link pentru formular',
    companies_form_add_link_placeholder: 'Introduceți linkul către formularul de cerere',
    companies_form_add_main_language_label: 'Limba primară',
    companies_form_add_main_language_placeholder: 'Nu a fost selectat',
    companies_form_add_multi_languages_label: 'Multilingvistic',
    companies_form_add_multi_languages_placeholder: 'Alegerea limbilor',
    companies_form_add_name_label: 'Numele formularului de cerere',
    companies_form_add_name_placeholder: 'Introduceți numele formularului de cerere',
    companies_form_add_status_not_published: 'Nepublicat',
    companies_form_add_status_published: 'Publicat',
    companies_form_add_status_title: 'Status',
    companies_form_add_tied_node_label: 'Nod legat',
    companies_form_add_tied_node_label_second: 'Al doilea este responsabil în nod',
    companies_form_add_tied_node_placeholder: 'Nod',
    companies_form_constructor_add_an_option: 'Adăugați o opțiune',
    companies_form_constructor_add_document_button: 'Adăugați un document',
    companies_form_constructor_add_field_to_series: 'Adăugați la o serie de câmpuri',
    companies_form_constructor_add_question_button: 'Adăugați o întrebare',
    companies_form_constructor_background_color: 'fundal',
    companies_form_constructor_background_color_for_text_title: 'Culoarea de fundal pentru text',
    companies_form_constructor_background_color_title: 'Culoare de fundal',
    companies_form_constructor_caption_checkbox_label: 'Selectați documentul',
    companies_form_constructor_caption_placeholder: 'Selectați documentul de care aveți nevoie',
    companies_form_constructor_caption_title: 'Politica de Confidențialitate',
    companies_form_constructor_checked_elements_border_radius_title: 'Raza chenarului butoanelor radio a fost bifată',
    companies_form_constructor_checked_elements_checked_size_title: 'S-a a verificat dimensiunea butonului radio',
    companies_form_constructor_choice_description_info: 'Puteți afișa un câmp de text dacă doriți să extindeți răspunsul utilizatorului când este selectată această pțiune',
    companies_form_constructor_choice_description_placeholder: 'Alege descrierea substituentului...',
    companies_form_constructor_choice_description_required: 'Este necesară descrierea alegerii',
    companies_form_constructor_choice_description_title: 'Alege descrierea legenddei...',
    companies_form_constructor_choose_form_font_placeholder: 'Selecteaza fondul chestionarului',
    companies_form_constructor_choose_logo_button: 'Alegeți un logo',
    companies_form_constructor_choose_logo_label: 'Siglă',
    companies_form_constructor_choose_logo_title: 'Alegeți un logo din imaginile companiei încărcate',
    companies_form_constructor_choose_main_mascot_button: 'Alegeți o mascota',
    companies_form_constructor_choose_main_mascot_label: 'Mascota principală',
    companies_form_constructor_choose_main_mascot_title: 'Alegeți o mascota din imaginile încărcate ale companiei',
    companies_form_constructor_chose_field_placeholder: 'Selectați câmpul',
    companies_form_constructor_communication_channels_call: 'Apel',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label: 'Raspuns automat',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder: 'Introduceți textul de răspuns automat',
    companies_form_constructor_communication_channels_email: 'E-mail',
    companies_form_constructor_communication_channels_label: 'Alegeți ce canale de comunicare vor fi disponibile pe pagina de formular',
    companies_form_constructor_communication_channels_placeholder: 'Explicație că trebuie să alegeți un canal de comunicare pentru feedback',
    companies_form_constructor_communication_channels_sms: 'mesaj',
    companies_form_constructor_communication_channels_sub_label: 'Selectați mesagerii folosiți pentru răspunsuri',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Canale de conectare',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer: 'Fără răspuns',
    companies_form_constructor_country_code_not_editable: 'Codul de țară nu poate fi editat',
    companies_form_constructor_disabled_send_button_background_color: 'Culoarea de fundal a butonului a fost dezactivată',
    companies_form_constructor_display_node_address: 'Afișează adresa nodului',
    companies_form_constructor_display_scale_background_color: 'Afișați culoarea de fundal a scării principale',
    companies_form_constructor_display_scale_background_color_info: 'Culoarea de fundal trebuie să depindă de valoarea selectată a scării principale',
    companies_form_constructor_display_scale_mascot: 'Afișați mascota scării principale',
    companies_form_constructor_display_scale_mascot_info: 'Mascota trebuie să depindă de valoarea selectată pentru scara principală',
    companies_form_constructor_document_description: 'Selectați documentul de care aveți nevoie',
    companies_form_constructor_document_description_placeholder: 'Descrierea documentului',
    companies_form_constructor_document_empty_list: 'Firma nu are acte',
    companies_form_constructor_document_placeholder: 'Selectați documentul',
    companies_form_constructor_document_title: 'Document',
    companies_form_constructor_elements_border_width_title: 'Lățimea chenarului elementelor',
    companies_form_constructor_elements_color_title: 'Culoarea elementelor',
    companies_form_constructor_email_placeholder: 'Introduceți adresa dvs. de email',
    companies_form_constructor_email_title: 'E-mail',
    companies_form_constructor_form_font_title: 'Fondul chestionarului',
    companies_form_constructor_form_name_label: 'Numele formularului',
    companies_form_constructor_form_name_placeholder: 'Introduceți numele formularului',
    companies_form_constructor_full_answer_placeholder: 'Ne-am bucura dacă ați lăsa un e-mail pentru comunicare',
    companies_form_constructor_full_answer_title: 'Răspuns detaliat',
    companies_form_constructor_info_additional_field_placeholder: 'În acest câmp, introduceți substituentul pentru câmpul suplimentar al întrebării pe care doriți să  adresațirespondenților.',
    companies_form_constructor_info_placeholder: 'În acest câmp introduceți substituentul întrebării pe care doriți să o adresați respondenților.',
    companies_form_constructor_info_question: 'În acest câmp introduceți întrebarea pe care doriți să o adresați respondenților.',
    companies_form_constructor_info_scale_max: 'În acest câmp introduceți o scurtă descriere pentru valoarea maximă a scalei.',
    companies_form_constructor_info_scale_min: 'În acest câmp introduceți o scurtă descriere pentru valoarea minimă a scalei.',
    companies_form_constructor_info_step_text: 'În acest câmp, introduceți textul pe care doriți să îl afișați respondenților ca text pentru etapa.',
    companies_form_constructor_info_thank_you_page_text: 'În acest câmp introduceți textul pe care doriți să îl afișați pe pagina de mulțumiri.',
    companies_form_constructor_info_thank_you_page_title: 'În acest câmp introduceți titlul pe care doriți să îl afișați pe pagina Mulțumiri.',
    companies_form_constructor_key_question: 'Întrebare cheie',
    companies_form_constructor_language_label: 'Limba formularului',
    companies_form_constructor_language_placeholder: 'Limba',
    companies_form_constructor_localization_label: 'Localizare pentru întreaga formă',
    companies_form_constructor_logo_place_center: 'Centrat',
    companies_form_constructor_logo_place_label: 'Locația logo-ului',
    companies_form_constructor_logo_place_left: 'Stânga',
    companies_form_constructor_logo_place_right: 'Pe dreapta',
    companies_form_constructor_main_question: 'Lăsați evaluarea dvs. companiei',
    companies_form_constructor_main_scale: 'Scara principală',
    companies_form_constructor_mascot: 'Mascotă',
    companies_form_constructor_max_placeholder: 'Extrem de incredibil',
    companies_form_constructor_min_placeholder: 'Improbabil',
    companies_form_constructor_name_field_required: 'Câmpul de introducere a numelui este obligatoriu',
    companies_form_constructor_name_placeholder: 'Cum te numești',
    companies_form_constructor_name_title: 'Nume',
    companies_form_constructor_next_field: 'Următorul câmp',
    companies_form_constructor_not_checked_elements_color_title: 'Culoarea elementelor nebifate',
    companies_form_constructor_one_from_list_placeholder: 'De cât timp vizitezi unitatea noastră?',
    companies_form_constructor_one_from_list_title: 'Unul din lista',
    companies_form_constructor_phone_placeholder: 'Introdu numarul tau de telefon',
    companies_form_constructor_phone_title: 'Număr de telefon',
    companies_form_constructor_prev_field: 'Câmpul anterior',
    companies_form_constructor_privacy_policy_label: 'Selectați documentul de care aveți nevoie',
    companies_form_constructor_privacy_policy_placeholder: 'Selectați documentul',
    companies_form_constructor_privacy_policy_title: 'Politica de Confidențialitate',
    companies_form_constructor_quantity_button_signs_color: 'Culoarea semnelor de buton',
    companies_form_constructor_quantity_button_signs_color_placeholder: 'Selectați culoarea semnelor de buton',
    companies_form_constructor_quantity_buttons_background_color: 'Culoarea de fundal a butonului',
    companies_form_constructor_quantity_buttons_background_color_placeholder: 'Selectați culoarea de fundal a butoanelor',
    companies_form_constructor_quantity_default_value_title: 'Valoare implicită',
    companies_form_constructor_quantity_max_value_title: 'Valoarea maximă',
    companies_form_constructor_quantity_min_value_title: 'Valoare minimă',
    companies_form_constructor_quantity_placeholder: 'De câte ori ati venit la noi?',
    companies_form_constructor_quantity_title: 'Cantitate',
    companies_form_constructor_required_question: 'Întrebare obligatorie',
    companies_form_constructor_scale_change_design_button: 'Schimbați designul scalei',
    companies_form_constructor_scale_title: 'Scară',
    companies_form_constructor_select_field: 'Selectați câmpul',
    companies_form_constructor_send_button: 'Buton',
    companies_form_constructor_send_button_background_color: 'Culoarea de fundal a butonului',
    companies_form_constructor_send_button_link: 'Link-ul butonului',
    companies_form_constructor_send_button_text: 'Textul butonului',
    companies_form_constructor_send_button_text_color: 'Culoarea textului butonului',
    companies_form_constructor_send_button_title: 'butonul Trimitere',
    companies_form_constructor_send_disabled_button_text_color: 'Culoarea textului butonului a fost dezactivată',
    companies_form_constructor_set_default_agreed: 'Setați acord implicit',
    companies_form_constructor_several_from_list_placeholder: 'De cât timp vizitezi unitatea noastră?',
    companies_form_constructor_several_from_list_title: 'Câteva din listă',
    companies_form_constructor_show_additional_field: 'Afișați câmp suplimentar',
    companies_form_constructor_show_name_input_field: 'Afișează câmpul de introducere a numelui',
    companies_form_constructor_show_text_in_two_rows: 'Afișați textul pe două rânduri',
    companies_form_constructor_signature_label: 'Semnătură',
    companies_form_constructor_signature_max_label: 'max',
    companies_form_constructor_signature_min_label: 'min',
    companies_form_constructor_start_page_button_width: 'Lățimea butonului paginii de pornire',
    companies_form_constructor_status_label: 'Status',
    companies_form_constructor_step_text: 'Textul etapei',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Scrieți textul etapei',
    companies_form_constructor_step_text_underline_color: 'Culoarea sublinierii textului etapei',
    companies_form_constructor_step_text_underline_color_placeholder: 'Selectați culoarea sublinierii textului etapei',
    companies_form_constructor_submit_button: 'Salvare formular',
    companies_form_constructor_tab_diagram: 'Diagramă',
    companies_form_constructor_tab_field: 'Camp',
    companies_form_constructor_tab_fields: 'Câmpuri',
    companies_form_constructor_tab_settings: 'Setări',
    companies_form_constructor_text: 'Text...',
    companies_form_constructor_thank_you_page_field_title: 'Pagina de multumiri',
    companies_form_constructor_thank_you_page_text: 'Textul paginii de multumire',
    companies_form_constructor_thank_you_page_text_first_line: 'Primul rând al textului paginii Vă mulțumim',
    companies_form_constructor_thank_you_page_text_second_line: 'Al doilea rând al textului paginii Vă mulțumim',
    companies_form_constructor_thank_you_page_title: 'Titlul paginii de multumire',
    companies_form_constructor_title: 'Constructor de formulare',
    companies_form_constructor_title_additional_field_placeholder: 'Altul',
    companies_form_constructor_title_additional_field_placeholder_title: 'Substituent suplimentar pentru câmp',
    companies_form_constructor_title_placeholder: 'Ne-am bucura dacă lăsați o recenzie',
    companies_form_constructor_title_placeholder_title: 'Substituent',
    companies_form_constructor_title_title: 'Legendă',
    companies_form_constructor_upload_files_label: 'Alegeți ce tipuri de fișiere încărcate vor fi disponibile pe pagina formularului',
    companies_form_constructor_upload_files_placeholder: 'Explicație că este posibil să atașați fișiere la un formular',
    companies_form_constructor_upload_files_title: 'Fișier încărcat',
    companies_form_download_qr: 'Descărcați QR',
    companies_form_scale_constructor_change_background_checkbox_label: 'Schimbați fundalul când se schimbă evaluarea',
    companies_form_scale_constructor_change_background_select_label: 'Culoare de fundal',
    companies_form_scale_constructor_change_background_select_placeholder: 'Alegeți o culoare',
    companies_form_scale_constructor_change_mascot_checkbox_label: 'Schimbați mascota când se schimbă ratingul',
    companies_form_scale_constructor_change_mascot_select_label: 'Mascote',
    companies_form_scale_constructor_change_mascot_select_placeholder: 'Alegeți o mascota',
    companies_form_scale_constructor_choose_color_placeholder: 'Alegeți o culoare',
    companies_form_scale_constructor_file_not_uploaded: 'fișierul nu a fost încărcat',
    companies_form_scale_constructor_main_gradient_colors_label: 'Culori gradiente de bază',
    companies_form_scale_constructor_maximum_value_label: 'Valoare maximă',
    companies_form_scale_constructor_maximum_value_placeholder: 'Introduceți valoarea maximă',
    companies_form_scale_constructor_minimum_value_label: 'Introduceți o valoare implicită',
    companies_form_scale_constructor_minimum_value_placeholder: 'Valoare implicită',
    companies_form_scale_constructor_numbers_chosen_colors_label: 'Culoarea răspunsului selectat',
    companies_form_scale_constructor_numbers_colors_label: 'Culoarea numerelor',
    companies_form_scale_constructor_pointer_color_label: 'Indicator de culoare',
    companies_form_scale_constructor_show_default_value: 'Afișați valoarea implicită',
    companies_form_scale_constructor_signature_text_color_label: 'Culoarea textului semnăturii',
    companies_form_scale_constructor_star_chosen_color_label: 'Culoarea stelei selectate',
    companies_form_scale_constructor_star_not_chosen_color_label: 'Culoarea de umplere a stelei neselectate',
    companies_form_scale_constructor_star_not_chosen_contour_color_label: 'Culoarea conturului stelei',
    companies_form_scale_constructor_tab_title: 'Scară',
    companies_form_scale_constructor_title: 'Constructor de scară',
    companies_form_select_color_placeholder: 'Selectați culoarea',
    companies_form_show_node_code: 'Afișați codul nodului',
    companies_form_table_add_form_button: 'Adăugați formular',
    companies_form_table_constructor_button: 'Constructor de formulare',
    companies_form_table_copy_button: 'Copierea unui formular',
    companies_form_table_copy_confirm_text: 'Sigur doriți să copiați formularul?',
    companies_form_table_delete_button: 'Ștergerea unui formular',
    companies_form_table_delete_confirm_text: 'Sigur doriți să ștergeți formularul?',
    companies_form_table_edit_button: 'Editați',
    companies_form_table_header_feedbacks: 'Recenzii',
    companies_form_table_header_last_edit: 'Modificat ultima dată',
    companies_form_table_header_link: 'Legătură',
    companies_form_table_header_name: 'Numele formularului',
    companies_form_table_header_status: 'Status',
    companies_form_table_new_form_language_placeholder: 'Selectați limba noului formular',
    companies_form_table_new_form_language_title: 'Limba noului formular',
    companies_form_table_new_form_name_placeholder: 'Introduceți un nou nume de formular',
    companies_form_table_new_form_name_title: 'Nou nume de formular',
    companies_form_table_new_node_language_placeholder: 'Selectați limba noului nod',
    companies_form_table_new_node_language_title: 'Limba noului nod',
    companies_form_table_new_node_name_placeholder: 'Introduceți numele noului nod',
    companies_form_table_new_node_name_title: 'Numele noului nod',
    companies_form_table_node_to_copy: 'Unde doriți să copiați formularul?',
    companies_form_table_thank_button: 'Pagina de mulțumire',
    companies_form_table_title: 'Formulare de feedback pentru nodul companiei',
    companies_form_use_as_default_form: 'Utilizați ca formă implicită',
    companies_form_with_steps: 'Cu etape',
    companies_info_add_field_button: 'Adăugați câmp',
    companies_info_after_save_modal_confirm_button: 'Editați datele companiei',
    companies_info_after_save_modal_title: 'Compania a fost înființată cu succes',
    companies_info_choose_file_button: 'Selectați un fișier',
    companies_info_company_domain_label: 'Domeniul companiei',
    companies_info_company_domain_placeholder: 'Introduceți domeniul companiei',
    companies_info_company_extra_field_name_placeholder: 'Introduceți numele câmpului',
    companies_info_company_extra_field_value_placeholder: 'Introduceți valoarea câmpului',
    companies_info_company_name_label: 'Numele companiei',
    companies_info_company_name_placeholder: 'Introduceți numele companiei',
    companies_info_confirmation_before_save_modal_title: 'Sunteți sigur că doriți să editați informațiile despre companie?',
    companies_info_file_max_size: 'JPG GIF sau PNG. Dimensiune maxima 800K',
    companies_info_file_not_selected: 'Fișierul nu este selectat',
    companies_info_submit_create_button: 'Salvați compania',
    companies_info_submit_edit_button: 'Salvează modificările',
    companies_info_tab_base_info: 'Informatii de baza',
    companies_info_tab_design: 'Design',
    companies_info_tab_documents: 'Documente',
    companies_info_tab_issues_statuses_and_types: 'Zone de interes si departamente',
    companies_info_tab_review_table_columns_settings: 'Setarile coloanelor',
    companies_info_tab_sources: 'Surse',
    companies_info_tab_templates: 'Șabloane',
    companies_info_title_add: 'Adăugați o companie',
    companies_info_title_edit: 'Editați compania',
    companies_issue_statuses_and_types_add_status_button: 'Adăugați statusul',
    companies_issue_statuses_and_types_add_status_placeholder: 'Introduceți numele statusului',
    companies_issue_statuses_and_types_statuses_text: 'Statusuri',
    companies_issue_statuses_and_types_type_add_button: 'Adăugați tip',
    companies_issue_statuses_and_types_type_add_placeholder: 'Introduceți numele tipului',
    companies_issue_statuses_and_types_types_text: 'Tipuri',
    companies_issues_and_types_confirmation_before_save_modal_title: 'Sunteți sigur că doriți să editați statusurile și tipurile de probleme?',
    companies_main_add_company_button: 'Adăugați o companie',
    companies_main_delete_description: 'Sigur doriți să ștergeți compania?',
    companies_main_delete_title: 'Ștergerea unei companii',
    companies_main_forms_button: 'Formulare de contact',
    companies_main_structure_button: 'Structura',
    companies_main_table_company_name_header: 'Companie',
    companies_main_title: 'Companie',
    companies_review_table_columns_settings_title: 'Coloane',
    companies_review_title: 'Recenzie',
    companies_reviews_table_header_button_details: 'Detalii',
    companies_reviews_table_header_contact: 'Canal',
    companies_reviews_table_header_contact_type: 'tip de comunicare',
    companies_reviews_table_header_create: 'Data creării',
    companies_reviews_table_header_estimate: 'Scor',
    companies_reviews_table_header_files: 'Fișiere atașate',
    companies_reviews_table_header_key_answer: 'Răspuns cheie',
    companies_reviews_table_header_language: 'Limba',
    companies_reviews_table_header_location: 'Locații',
    companies_reviews_table_header_source: 'Sursă',
    companies_reviews_table_header_status: 'Status',
    companies_reviews_table_title: 'Recenzii',
    companies_source_must_be_unique_text: 'trebuie să fie unic',
    companies_source_name_label: 'Numele sursei',
    companies_source_name_max_length_error: 'Numele sursei trebuie să aibă mai puțin de 100 de caractere”',
    companies_sources_add_source_button: 'Adăugați sursa',
    companies_sources_confirmation_before_save_modal_title: 'Sunteți sigur că doriți să editați sursele?',
    companies_sources_create_new_source_title: 'Creați o nouă sursă',
    companies_sources_create_source_text: 'Creați sursa',
    companies_sources_placeholder: 'Introduceți numele sursei',
    companies_sources_title: 'Surse',
    companies_structure: 'Structura',
    companies_structure_create_accountable: 'Responsabil(i)',
    companies_structure_create_address_label: 'Adresa',
    companies_structure_create_address_placeholder: 'Introdu adresa',
    companies_structure_create_advanced_settings: 'Setari avansate',
    companies_structure_create_edit_title: 'Editați nodul',
    companies_structure_create_email: 'E-mail',
    companies_structure_create_google_place_id_label: 'ID-ul locației Google',
    companies_structure_create_integration_alpha_name_label: 'Nume alfa',
    companies_structure_create_integration_key_label: 'Cheie',
    companies_structure_create_integration_title: 'Setări de integrare',
    companies_structure_create_modal_title: 'Sunteți sigur că doriți să creați nod?',
    companies_structure_create_node_code_label: 'Codul nodului',
    companies_structure_create_node_code_placeholder: 'Introdu codul nodului',
    companies_structure_create_node_name_label: 'Numele nodului',
    companies_structure_create_node_name_placeholder: 'Introduceți numele nodului',
    companies_structure_create_sms: 'mesaj',
    companies_structure_create_telegram: 'Telegram',
    companies_structure_create_tied_form_placeholder: 'Formă',
    companies_structure_create_tied_node_label: 'Nod legat',
    companies_structure_create_tied_node_placeholder: 'Nod',
    companies_structure_create_title: 'Creați un nod',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay: 'Don\t use an expiration timer',
    companies_structure_lang_detection: 'Utilizați detectarea automată a limbajului formularului',
    companies_structure_table_accountable: 'Responsabil',
    companies_structure_table_add_node_button: 'Adăugați nod',
    companies_structure_table_delete_description_1: 'Sunteți sigur că doriți să ștergeți nodul',
    companies_structure_table_delete_description_2: 'structurile companiei',
    companies_structure_table_delete_description_3: 'Acest lucru va elimina toate nodurile combinate',
    companies_structure_table_delete_title: 'Ștergerea unui nod al structurii companiei',
    companies_structure_table_header_name: 'Noduri',
    companies_structure_title: 'Structura companiei',
    companies_structure_update_modal_title: 'Sunteți sigur că doriți să editați nodul?',
    company: 'Companie',
    company_main_info_delete_title: 'Înlăturarea companiei',
    company_main_info_edit_title: 'Editați informațiile de bază despre \ncompanie designul companiei documentele companiei',
    company_main_info_go_to_company_structure: 'Accesați structura companiei',
    company_structure_delete_button_title: 'Ștergerea unui nod',
    company_structure_edit_button_title: 'Editarea nodului',
    confirm_button: 'Confirma',
    connect_button: 'A conecta',
    continue_editing_button: 'Continuați editarea',
    continue_review_dialog_button: 'Continuă dialogul',
    copy_button: 'Copiaza',
    copy_error: '* A apărut o eroare la copiere',
    create_button: 'Creaza',
    data_save_error: '* A apărut o eroare la salvarea datelor',
    delete_button: 'Șterge',
    delete_error: '* A apărut o eroare la ștergere',
    document: 'Document',
    done_button: 'Terminat',
    edit_button: 'Editeaza | ×',
    email: 'E-mail',
    email_label: 'E-mail',
    email_placeholder: 'Introduceți adresa de email',
    employee_profile_title: 'Profilul angajatului',
    enter_button: 'introdu',
    enter_forgot_button: 'Recuperează parola',
    enter_task_name: 'Introduceți numele sarcinii',
    error: '* A aparut o eroare',
    error_text: 'Eroare text',
    error_title: 'Eroare',
    expecting_reply_from_client: 'Așteptând răspunsul clientului',
    filter_title_sources: 'Surse',
    filter_title_status: 'Statusuri',
    filters: 'Filtru',
    finish_review_dialog_button: 'Trimite mesajul',
    finish_review_dialog_modal_description: 'Sigur vrei să termini dialogul?',
    finish_review_dialog_modal_title: 'Trimite mesajul',
    form: 'Formular',
    form_constructor_connection_case_label: 'Caz',
    form_constructor_connection_case_placeholder: 'Selectați caz',
    form_constructor_connection_go_to: 'mergi la',
    form_constructor_connection_render_minifield_connection_always: 'Mereu',
    form_constructor_connection_render_minifield_connection_type_texts_always: 'Toate celelalte cazuri',
    form_constructor_connection_render_minifield_connection_type_texts_if: 'Daca este',
    form_constructor_connection_select_field: 'Selectați câmpul',
    form_constructor_connection_select_with_any_items_label: 'Următorul câmp',
    form_constructor_connection_select_with_any_items_options_1_question: 'Nu a fost selectat',
    form_constructor_connection_select_with_any_items_placeholder: 'Selectați câmpul',
    form_constructor_connection_statement_label: 'Afirmație',
    form_constructor_connection_statement_placeholder: 'Selectați afirmația',
    form_constructor_delete_field_modal_description: 'Sigur doriti sa stergeti acest camp?',
    form_constructor_delete_field_modal_title: 'Avertizare!',
    form_constructor_diagram_fields_list_is_empty: 'Lista de câmpuri este goală',
    form_constructor_fields_form_content: 'Conţinut',
    form_constructor_fields_form_documents: 'documente',
    form_constructor_fields_form_no_fields_selected: 'Niciun câmp selectat',
    form_constructor_fields_form_send_button: 'butonul de trimitere',
    form_constructor_fields_form_thank_you_page: 'Pagina de multumesc',
    form_copy_save_error: '* Un formular cu același nume există deja pe nodul selectat',
    form_language_error: '* Aveți deja un formular cu limba selectată',
    form_name_error: '* Numele formularului trebuie să fie unic',
    form_save_error: '* A apărut o eroare la salvarea formularului',
    from: 'Din',
    full_name_label: 'Numele complet al angajatului',
    full_name_placeholder: 'Introduceți numele angajatului',
    generate_button: 'Genereaza',
    go_to_forms_page_button_title: 'Accesați formularele de feedback',
    go_to_issues_create: 'Accesați crearea problemelor',
    go_to_reviews_page_button_title: 'Accesați recenzii',
    go_to_task_create: 'Mergi la crearea sarcinii',
    in_progress: 'În curs',
    issues_add_issue: 'Adaugati o problema',
    issues_form_company_label: 'Companie',
    issues_form_company_placeholder: 'Selectați o companie',
    issues_form_connect_issue_label: 'Problemă de conexiune',
    issues_form_connect_issue_placeholder: 'Selectați o problemă',
    issues_form_create_new_issue_status: 'Creați un nou status de problemă',
    issues_form_create_new_issue_status_title: 'Creați status',
    issues_form_create_new_issue_type: 'Creați un nou tip de problemă',
    issues_form_create_new_issue_type_title: 'Creați tip',
    issues_form_must_be_unique: 'trebuie să fie unic',
    issues_form_name_label: 'Numele problemei',
    issues_form_name_placeholder: 'Introduceți numele formularului',
    issues_form_node_label: 'Nod',
    issues_form_node_placeholder: 'Selectați un nod',
    issues_form_priority_label: 'Prioritatea emiterii',
    issues_form_priority_placeholder: 'Selectați o prioritate',
    issues_form_responsible_label: 'Responsabil',
    issues_form_responsible_placeholder: 'Alegeți un responsabil',
    issues_form_review_label: 'Recenzie',
    issues_form_review_placeholder: 'Selectați o recenzie',
    issues_form_status_label: 'Statusul problemei',
    issues_form_status_name_label: 'Numele statusului problemei',
    issues_form_status_placeholder: 'Selectați un status',
    issues_form_type_label: 'Tipul problemei',
    issues_form_type_name_label: 'Numele tipului de problemă',
    issues_form_type_placeholder: 'Selectați un tip de problemă',
    issues_save_modal_create_title: 'Sunteți sigur că doriți să creați problema?',
    issues_save_modal_edit_title: 'Sunteți sigur că doriți să editați problema?',
    issues_search_by_name: 'Căutați problema după nume',
    issues_sub_header_create_issue_title: 'Creați o problemă',
    issues_sub_header_create_task_title: 'Crearea unei sarcini',
    issues_sub_header_edit_issue_title: 'Editați problema',
    issues_sub_header_title: 'Probleme',
    issues_table_empty: 'Selectați compania pentru a afișa problemele',
    issues_table_header_company: 'Companie',
    issues_table_header_date: 'Data creării',
    issues_table_header_name: 'Nume',
    issues_table_header_node: 'Nod',
    issues_table_header_priority: 'Prioritate',
    issues_table_header_status: 'Status',
    issues_table_header_type: 'Tipul problemei',
    language: 'Limba',
    languages_add_name_placeholder: 'Introdu numele',
    languages_add_short_name_label: 'Nume scurt',
    languages_add_short_name_placeholder: 'Introduceți un nume scurt',
    languages_add_title: 'Adăugarea unei limbi',
    languages_table_add_language_button: 'Adăugați limba',
    languages_table_header_full_name: 'Numele complet',
    languages_table_header_short_name: 'Nume scurt',
    languages_table_modal_delete_buttons_label_end: 'limba?',
    languages_table_modal_delete_buttons_label_start: 'ești sigur că vrei să ștergi',
    languages_table_modal_delete_title: 'Se șterge limba',
    languages_table_title: 'Limbi',
    leave_the_page: 'Părăsiți pagina',
    loading_error: '* A apărut o eroare la încărcarea datelor',
    login_recovery_auth: 'Întoarcere',
    login_recovery_complete: 'Un mesaj cu instrucțiuni despre cum să vă resetați parola va fi trimis în curând la adresa dvs. de e-mail',
    mass_mailing_type_by_addresses: 'Adresa postala',
    mass_mailing_type_by_company: 'De companie',
    max_size_error: '* Dimensiunea maximă permisă a fișierului a fost depășită',
    max_size_error_25: '* Dimensiunea maximă permisă a fișierului este de 25 Mb',
    max_table_column_settings_apply_warning: 'Maxim 6 setari active',
    more_detailed: 'Mai detaliat',
    name: 'Nume',
    new_task_name: 'Nume nou al sarcinii',
    next_button: 'Următorul',
    no_options_available: 'Nu există opțiuni disponibile',
    node: 'Nod',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes,',
    not_decided: 'Nerezolvat',
    not_viewed: 'Nu este vizualizat',
    nothing_find: 'Not found,',
    login_recovery_password_title: 'Recuperarea unei parole pierdute',
    open_issue_title: 'Deschide problemă',
    open_language_title: 'Deschide limba',
    open_permission_title: 'Deschide permisiunea',
    open_review_title: 'Deschide recenzia',
    open_role_title: 'Deschide rol',
    open_user_title: 'Deschide utilizator',
    other: 'Altele',
    overdue_status_text: 'Depasit',
    pagination_page_number_placeholder: 'Numărul paginii',
    pagination_title: 'Rânduri pe pagină',
    password: 'Parola',
    password_confirmation_label: 'repeta parola',
    password_confirmation_placeholder: 'Introduceți parola din nou',
    password_label: 'Parola',
    password_placeholder: 'Introdu parola',
    permission_create_title: 'Creați permisiunea',
    permission_edit_title: 'Editați permisiunea',
    permission_name_placeholder: 'Introduceți numele permisiunii',
    permission_name_title: 'Numele permisiunii',
    permission_resource_placeholder: 'Introduceți resursa permisiunii',
    permission_resource_title: 'Resursă',
    permissions_main_add_permission_button: 'Adăugați permisiunea',
    permissions_main_delete_description: 'Sigur doriți să ștergeți permisiunea?',
    permissions_main_delete_title: 'Ștergerea unei permisiuni',
    permissions_main_permissions_button: 'Permisiuni',
    permissions_main_table_create_header: 'Creați',
    permissions_main_table_delete_header: 'Șterge',
    permissions_main_table_permission_header: 'Numele permisiunii',
    permissions_main_table_read_header: 'Citiți',
    permissions_main_table_resource_header: 'Resursă',
    permissions_main_table_update_header: 'Actualizați',
    permissions_main_title: 'Permisiuni',
    phone: 'Telefon',
    phone_number_label: 'Număr de telefon',
    phone_number_placeholder: 'Introduceti numarul de telefon',
    photo: 'Fotografie',
    preview_button: 'previzualizare',
    privacy_policy: 'Acordul Utilizatorului',
    privacy_policy_confirm: 'Sunt de acord cu',
    record: 'Audio',
    refresh_all_fitlers: 'Sterge toate filtrele',
    registration_profile_completion: 'Finalizarea profilului',
    reopened: 'Deschis din nou',
    reset_reviews_filter: 'Resetati filtrul',
    reset_table_settings: 'Resetati setarile tabelului',
    review_choose_template: 'Alegeți un șablon de răspuns',
    review_created_at: 'Verificați data și ora',
    review_dialog_finished_text: 'Dialogul s-a încheiat',
    review_form_name: 'Numele formularului',
    review_form_source: 'Sursa formularului',
    review_handle_continue_dialog_error_message: 'Ceva este greșit. Vă rugăm să încercați din nou mai târziu.',
    review_message_input_placeholder: 'Scrie-ți mesajul',
    review_no_asscociated_issues: 'Fără probleme asociate',
    review_no_associated_tasks: 'Fără sarcini asociate',
    review_node_code: 'Codul nodului',
    review_node_name: 'Numele nodului',
    review_on_submit_3_error_message: 'Clientul are deja un dialog cu un alt manager. Vă rugăm să încercați din nou mai târziu.',
    review_open_task_button_text: 'Deschide activitatea',
    review_page_type_of_task: 'Tipul sarcinii',
    review_review_id: 'ID recenzie',
    review_save_modal_title: 'Sunteți sigur că doriți să editați recenzia?',
    review_updated_at: 'Data procesarii recenziei',
    reviews_page_choose_company_title: 'Selectati o companie din lista',
    reviews_table_comment_column_title: 'Comentariu',
    reviews_table_email_column_title: 'Email',
    reviews_table_filter_title: 'Filtru',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_node_column_title: 'Magazin',
    reviews_table_parent_node_column_title: 'Magazinul principal',
    reviews_table_phone_column_title: 'Numar de telefon',
    reviews_table_review_id_column_title: 'ID Recenzie',
    reviews_table_ukrposhta_settings_button: 'Recenzii neprocesate',
    reviews_table_user_name_column_title: 'Nume',
    reviewType: 'Tipul de recenzie',
    role_create_title: 'Creați un rol',
    role_edit_title: 'Editați rolul',
    role_name_placeholder: 'Introduceți numele rolului',
    role_name_title: 'Nume rol',
    role_page_create_title: 'Creați un rol',
    role_permissions: 'Permisiuni',
    role_select_permissions_placeholder: 'Selectați permisiunile',
    roles_main_add_role_button: 'Adăugați un rol',
    roles_main_delete_description: 'Sigur doriți să ștergeți rolul?',
    roles_main_delete_title: 'Ștergerea unui rol',
    roles_main_permissions_button: 'Permisiuni',
    roles_main_table_user_role_header: 'Nume rol',
    roles_main_title: 'Roluri',
    roles_part_analytic: 'Analitic',
    roles_part_companies: 'Companii',
    roles_part_documents: 'Documente',
    roles_part_forms: 'Formulare',
    roles_part_languages: 'Limbi',
    roles_part_mass_mailings: 'Trimiteri în masă',
    roles_part_nodes: 'Noduri',
    roles_part_reviews: 'Recenzii',
    roles_part_users: 'Utilizatori',
    roles_sub_permission_create: 'Creați',
    roles_sub_permission_delete: 'Ștergeți',
    roles_sub_permission_read: 'Citiți',
    roles_sub_permission_update: 'Actualizați',
    save_button: 'Salveaza',
    save_changes_button: 'Salvează modificările',
    save_document_button: 'Salveaza',
    save_draft_button: 'Salveaza schita',
    save_field_button: 'Salvare câmp',
    save_form_button: 'Salvare formular',
    save_form_without_changes_button: 'Salvați formularul fără modificări',
    search: 'Căutare...',
    search_for_name: 'Cauta dupa nume',
    search_for_email: 'Search by email',
    select_company: 'Selectați compania',
    select_form: 'Selectați formularul',
    select_language: 'Selecteaza limba',
    select_multiple_sources: 'Selectați sursele',
    select_multiple_statuses: 'Selectați statusuri',
    select_node: 'Selectați nodul',
    select_status: 'Selectați status',
    select_type: 'Selectați tipul',
    send_button: 'Trimite',
    sidebar_analytic: 'Date analitice',
    sidebar_companies: 'Companie',
    sidebar_exit: 'Deconectare profil',
    sidebar_issues: 'Probleme',
    sidebar_languages: 'Limbi',
    sidebar_profile: 'Profil',
    sidebar_reviews: 'Recenzii',
    sidebar_tasks: 'Atributii',
    sidebar_users: 'Utilizatori',
    sms: 'mesaj',
    social_networks_module_text: 'Alegeți o modalitate convenabilă de a comunica cu noi',
    solution_not_required: 'Soluția \n nu este necesară',
    solved: 'Rezolvat',
    source: 'Sursă',
    status: 'Statusul recenziei',
    status_code: 'Status cod',
    table_columns_settings_comment_option: 'Comentariu',
    table_columns_settings_communication_channel_option: 'Tipul de comunicare',
    table_columns_settings_company_option: 'Companie',
    table_columns_settings_created_at_option: 'Creat la',
    table_columns_settings_email_option: 'Email',
    table_columns_settings_estimation_option: 'Scor',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_language_option: 'Limba',
    table_columns_settings_name_option: 'Nume',
    table_columns_settings_node_option: 'Magazin',
    table_columns_settings_parent_node_option: 'Magazinul principal',
    table_columns_settings_phone_option: 'Numar de telefon',
    table_columns_settings_reviewId_option: 'ID recenzie',
    table_columns_settings_source_option: 'Sursa recenziei',
    table_columns_settings_status_option: 'Status',
    table_settings: 'Setarile tabelului',
    task_form_category_label: 'Categoria sarcinii',
    task_form_create_task: 'Creati atributia',
    task_form_list_category_label: 'Categorie',
    task_form_list_subcategory_label: 'Subcategorie',
    task_form_list_type_label: 'Tip',
    task_form_name_label: 'Numele sarcinii',
    task_form_priority_label: 'Prioritatea sarcinii',
    task_form_status_label: 'Statusul sarcinii',
    task_form_subcategory_label: 'Subcategoria sarcinii',
    task_form_type_label: 'Tipul sarcinii',
    telegram: 'Telegram',
    to: 'La',
    to_plug_button: 'A conecta',
    turn_off_button: 'Dezactivați',
    upload: 'Încărcați',
    upload_color_button: 'Încărcați culoarea',
    uploading_files: 'Încărcarea fișierelor',
    user_email_placeholder: 'Introduceți adresa de email',
    user_email_title: 'E-mail',
    user_name_placeholder: 'Introdu numele',
    user_name_title: 'Numele și prenumele',
    user_new_password_placeholder: 'Introduceți o nouă parolă',
    user_new_password_title: 'Parolă Nouă',
    user_old_password_placeholder: 'Introduceți vechea parolă',
    user_old_password_title: 'Parola veche',
    user_page_create: 'Creaza utilizator',
    user_page_edit: 'Editați utilizatorul',
    user_password_confirmation_placeholder: 'repeta parola',
    user_password_confirmation_title: 'Confirmarea parolei',
    user_password_placeholder: 'Introdu parola',
    user_password_title: 'Parola',
    user_role_placeholder: 'Selectați rolurile utilizatorului',
    user_role_title: 'Roluri',
    users_main_add_user_button: 'Adăugați utilizator',
    users_main_delete_description: 'Sigur doriți să ștergeți utilizatorul?',
    users_main_delete_title: 'Ștergerea unui utilizator',
    users_main_roles_button: 'Roluri',
    users_main_table_user_company_header: 'Companie',
    users_main_table_user_email_header: 'E-mail',
    users_main_table_user_name_header: 'Nume',
    users_main_table_user_notifications_header: 'Notificări',
    users_main_table_user_role_header: 'Rol',
    users_main_title: 'Utilizatori',
    users_main_upload_users_button: 'Încărcați utilizatori',
    validation_constructor_at_least_2_possible_answers: '* Întrebarea trebuie să aibă cel puțin 2 răspunsuri posibile',
    validation_constructor_should_have_at_least_1_document: '* Formularul trebuie să aibă cel puțin un document',
    validation_constructor_should_select_file_types: '* Trebuie să selectați tipurile de fișiere permise pentru încărcare sau să eliminați acest câmp din formular',
    validation_domain: 'Domeniul poate conține doar caractere englezești numere \'.\' și \'-\'',
    validation_form_should_have_minimum_1: '* Formularul trebuie să aibă cel puțin un câmp',
    validation_full_type_required: '* Trebuie să selectați fiecare parte a tipului',
    validation_invalid_email: '* Format de email invalid',
    validation_invalid_link: '* Format de link nevalid',
    validation_more_one_accountable: '* Trebuie să selectați cel puțin 1 manager de noduri',
    validation_more_one_option: '* Trebuie să selectați cel puțin 1 opțiune',
    validation_must_fill_all_fields_for: '* Trebuie să completați toate câmpurile pentru',
    validation_must_fill_this_field: '* Trebuie să completați acest câmp',
    validation_name_must_be_longer_4: '* Numele este prea scurt - trebuie să aibă cel puțin 4 caractere.',
    validation_required: '* Necesar',
    viber: 'Viber',
    video: 'Video',
    viewed: 'Vizualizat',
    your_changes_lose: 'Trebuie să salvați modificările. Altfel se vor pierde.',
    send_feedback: 'Trimite feedback',
    send_feedback_title: 'Părere',
    send_feedback_label: 'Dorințele sau comentariile dvs',
    send_feedback_complete: 'Multumim pentru feedback-ul dvs',
    sidebar_impex: 'Import/Export',
    impex_page_title: 'Import/Export',
    impex_import_tab: 'Import',
    impex_export_tab: 'Export',
    select_import_entity: 'Ce vrei să importi?',
    start_import_button_text: 'Începeți importul',
    choose_file_for_import: 'Alegeți fișierul pentru import',
    import_entity_selection_title: 'To import',
    import_file_sheet_name_label: 'The name of the sheet in the import file',
    import_file_sheet_name_input_placeholder: 'Enter name of the sheet',
    interrupt_import_script_button_text: 'Interrupt import',
    import_file_format_text: 'Format must be:',
    import_progress_bar_title: 'Import progress:',
    import_users_entity_name: 'Users',
    import_nodes_entity_name: 'Nodes',
    qr_title: 'Scanați QR-ul pentru a personaliza în continuare notificările',
    mass_mailings_channels_of_connections: 'Canale de conectare',
    mass_mailings_send_button: 'Trimite',
    yes_button_text: 'Da',
    no_button_text: 'Nu',
    confirm_send_mass_mailings_modal_title: 'Are you sure you want to make mass mailings?',
    modal_mass_mailings_sent_success_title: 'Messages sent successfully',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Error sending messages',
    mass_mailings_recipient_label: 'Recipient',
    mass_mailings_recipients_list_title: 'Recipients',
    add_at_least_one_recipient_error: 'At least one recipient must be added',
    sidebar_mass_mailings: 'Mass mailings',
    create_mass_mailings_page_title: 'Create mass mailings',
    create_mass_mailings_first_step_subheader_title: 'Mass mailing - Creating - Step 1 of 3',
    choose_mass_mailings_communication_channel: 'Choose communication channel',
    mass_mailings_add_recipient_button_text: 'Add recipient',
    download_recipients_file_label: 'Download a file with recipients',
    format: 'Format',
    choose_file: 'Choose a file',
    file_data_downloading_text: 'File data downloading',
    mass_mailings_mails_count: 'The number of mails that will be sent',
    create_sms_mass_mailings_second_step_subheader_title: 'Creating SMS-mailing - Step 2 of 3',
    create_viber_mass_mailings_second_step_subheader_title: 'Creating Viber-mailing - Step 2 of 3',
    create_sms_mass_mailings_third_step_subheader_title: 'Creating SMS-mailing - Step 3 of 3',
    create_viber_mass_mailings_third_step_subheader_title: 'Creating Viber-mailing - Step 3 of 3',
    mass_mailings_sender_account: 'Sender account',
    sms_text: 'SMS text',
    message: 'Message',
    message_text_placeholder: 'Message text',
    responsible: 'Responsabil',
    responsible_id: 'ID-ul inculpatului',
    companies_form_constructor_dividing_decorative_line: 'Parametrii liniei decorative de divizare a formei',
    companies_form_constructor_show_form_dividing_decorative_line: 'Afișați linia decorativă de separare a formularului',
    companies_form_constructor_dividing_decorative_line_width_in_percent: 'Lungimea liniei decorative în procente',
    companies_form_constructor_dividing_decorative_line_height: 'Înălțimea liniei decorative în pixeli',
    companies_form_constructor_dividing_decorative_line_height_placeholder: 'Specificați înălțimea liniei decorative',
    companies_form_constructor_dividing_decorative_line_color: 'Culoarea liniei decorative',
    companies_form_constructor_dividing_decorative_line_color_placeholder: 'Alegeți culoarea liniei decorative',
    export_entity_selection_title: 'To export',
    select_export_entity: 'Ce vrei să exporti?',
    export_button_text: 'Export',
    export_nodes_error_text: 'Eroare la exportul nodurilor',
    export_users_error_text: 'Eroare la exportul utilizatorilor',
    quarter: 'Last 3 months',
    months: 'Last 1 months',
    days7: 'Last 7 days',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Phone',
    email_network: 'Email',
    whatsapp_network: 'WhatsApp',
  },
  TR: {
    pass_changed_success: 'Password changed successfully',
    connect_tg: 'Connect Telegram bot',
    connect_vb: 'Connect Viber bot',
    notifiable_sources: 'Notifiable Sources',
    notifications: 'Notifications',
    reset_pass: 'Reset password',
    audit: 'History audit',
    CREATE: 'Create',
    UPDATE: 'Update',
    DELETE: 'Delete',
    review_audit: 'Review processing history',
    SYSTEM: 'System',
    USER: 'User',
    actor: 'Actor',
    action: 'Action',
    search_by_phone: 'Search by phone number',
    search_by_reviewID: 'Search by review ID',
    chat_error_no_user: 'The chat with the user was not found, maybe he did not connect or deleted the bot',
    chat_label: 'Chat with the client',
    comment_internal: 'Internal comment',
    changes_not_saved_modal: 'Değişiklikler kaydedilmedi. Sayfadan ayrılmak istediğinizden emin misiniz?',
    go_back: 'Geri dön',
    all_items_uploaded: 'Tüm öğeler yüklendi',
    login_title: 'Giriş yap',
    login_recovery_password: 'Şifre kurtarma',
    add_comment: 'Yorum ekle',
    add_button: 'Eklemek',
    add_option: 'Seçenek ekle',
    add_option_button: 'Varyant Ekle',
    apply_filters_button: 'Filtreleri uygula',
    apply_table_settings: 'Apply table settings',
    are_you_sure: 'Emin misin?',
    attached_files: 'Ekli dosyalar',
    bot_is_not_connected: 'Bot bağlı değil',
    cancel_button: 'İptal etmek',
    cant_decided: 'Çözemiyorum',
    change_new_form_button: 'Yeni formu değiştir',
    changes_saved_successfully: 'Değişiklikler başarıyla kaydedildi',
    channels_for_notifications: 'Bildirim kanalları',
    channels_of_connections: 'Bağlantı kanalları',
    chat_error_description: 'An error occurred while sending a message',
    chat_history_is_empty: 'Sohbet geçmişi boş',
    chat_is_finished: 'Chat finished',
    choose_file_button: 'Bir dosya seçin',
    choose_period: 'Dönem seçin',
    client_form_scale_field_h3: 'Şirketimiz hakkındaki izlenimleriniz',
    close_button: 'Kapatma',
    close_editing_button: 'Düzenlemeyi kapat',
    closed_auto_no_replay: 'Müşterinin cevabı olmadığı için otomatik olarak kapandı',
    color_picker_add_button: 'Renk ekle',
    comment_placeholder: 'Yorum girin',
    companies_columns_settings_confirmation_before_save_modal_title: 'Are you sure you want to edit the column settings for reviews page?',
    companies_design_add_color_button: 'Renk ekle',
    companies_design_add_color_placeholder: 'Renk adını yaz',
    companies_design_add_font_button: 'Add font',
    companies_design_add_font_placeholder: 'Enter font name',
    companies_design_colors_text: 'Renkler',
    companies_design_confirmation_before_save_modal_title: 'Are you sure that you want to edit company information?',
    companies_design_fonts_text: 'Fonts',
    companies_design_logos_add_button: 'Dosya yükle',
    companies_design_logos_add_placeholder: 'Logo adını yaz',
    companies_design_logos_text: 'Görüntüler',
    companies_documents_add_localization: 'Yerelleştirme',
    companies_documents_add_name_label: 'Adı',
    companies_documents_add_name_placeholder: 'Belge adını girin',
    companies_documents_add_title: 'Belge ekleme',
    companies_documents_add_title_editing: 'Belge düzenleme',
    companies_documents_file_caption: 'Dosya',
    companies_documents_file_format: 'Biçim',
    companies_documents_file_max_size: 'Maksimum dosya boyutu',
    companies_documents_file_not_loaded: 'yüklenmedi',
    companies_documents_file_save_button: 'Dosya yükle',
    companies_documents_file_text_editor_label: 'Belge metni',
    companies_documents_file_text_editor_placeholder: 'Belge metnini girin',
    companies_documents_table_add_document_button: 'Belge Ekle',
    companies_documents_table_delete_text: 'Belgeyi silmek istediğinizden emin misiniz?',
    companies_documents_table_delete_title: 'Bir belgeyi silme',
    companies_documents_table_header_edit_date: 'Değişim tarihi',
    companies_documents_table_header_edit_languages: 'Diller',
    companies_documents_table_header_name: 'Adı',
    companies_form_add_anonymous_checkbox_no: 'Hiçbir yanıt anonim olamaz.',
    companies_form_add_anonymous_checkbox_yes: 'Evet cevaplar anonim olabilir',
    companies_form_add_anonymous_label: 'Anonim yorum bırakma imkanı',
    companies_form_add_create_title: 'Geri bildirim formu ekle',
    companies_form_add_edit_title: 'Geri bildirim formunu düzenleme',
    companies_form_add_link_label: 'Form bağlantısı',
    companies_form_add_link_placeholder: 'Başvuru formunun bağlantısını girin',
    companies_form_add_main_language_label: 'Ana dil',
    companies_form_add_main_language_placeholder: 'Seçili değil',
    companies_form_add_multi_languages_label: 'Çok dilli',
    companies_form_add_multi_languages_placeholder: 'Dil seçimi',
    companies_form_add_name_label: 'Başvuru formunun adı',
    companies_form_add_name_placeholder: 'Başvuru formunun adını girin',
    companies_form_add_status_not_published: 'Yayınlanmadı',
    companies_form_add_status_published: 'Yayınlanan',
    companies_form_add_status_title: 'Durum',
    companies_form_add_tied_node_label: 'Bağlı düğüm',
    companies_form_add_tied_node_label_second: 'İkincisi düğümde sorumludur',
    companies_form_add_tied_node_placeholder: 'Düğüm',
    companies_form_constructor_add_an_option: 'Bir seçenek ekle',
    companies_form_constructor_add_document_button: 'Belge ekleme',
    companies_form_constructor_add_field_to_series: 'Add to series of fields',
    companies_form_constructor_add_question_button: 'Soru ekle',
    companies_form_constructor_background_color: 'Arka plân',
    companies_form_constructor_background_color_for_text_title: 'Background color for text',
    companies_form_constructor_background_color_title: 'Arka plan rengi',
    companies_form_constructor_caption_checkbox_label: 'Belge seç',
    companies_form_constructor_caption_placeholder: 'İhtiyacınız olan belgeyi seçin',
    companies_form_constructor_caption_title: 'Gizlilik politikası',
    companies_form_constructor_checked_elements_border_radius_title: 'Checked radio buttons border radius',
    companies_form_constructor_checked_elements_checked_size_title: 'Radio button checked size',
    companies_form_constructor_choice_description_info: 'Bu seçenek seçildiğinde kullanıcı yanıtını genişletmesini istiyorsanız bir metin alanı görüntüleyebilirsiniz',
    companies_form_constructor_choice_description_placeholder: 'Seçim açıklaması yer tutucusu...',
    companies_form_constructor_choice_description_required: 'Choice description required',
    companies_form_constructor_choice_description_title: 'Seçim açıklaması başlığı...',
    companies_form_constructor_choose_form_font_placeholder: 'Select the questionnaire font',
    companies_form_constructor_choose_logo_button: 'Logo seçin',
    companies_form_constructor_choose_logo_label: 'Logo',
    companies_form_constructor_choose_logo_title: 'Yüklenen şirket görsellerinden bir logo seçin',
    companies_form_constructor_choose_main_mascot_button: 'Bir maskot seçin',
    companies_form_constructor_choose_main_mascot_label: 'Ana maskot',
    companies_form_constructor_choose_main_mascot_title: 'Yüklenen şirket görsellerinden bir maskot seçin',
    companies_form_constructor_chose_field_placeholder: 'Alan seç',
    companies_form_constructor_communication_channels_call: 'Çağrı',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label: 'Otomatik cevaplama',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder: 'Otomatik yanıt metnini girin',
    companies_form_constructor_communication_channels_email: 'E-posta',
    companies_form_constructor_communication_channels_label: 'Form sayfasında hangi iletişim kanallarının kullanılabileceğini seçin',
    companies_form_constructor_communication_channels_placeholder: 'Geri bildirim için bir iletişim kanalı seçmeniz gerektiğine dair açıklama',
    companies_form_constructor_communication_channels_sms: 'SMS',
    companies_form_constructor_communication_channels_sub_label: 'Yanıtlar için kullanılan habercileri seçin',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Bağlantı kanalları',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer: 'Without answer',
    companies_form_constructor_country_code_not_editable: 'Country code not editable',
    companies_form_constructor_disabled_send_button_background_color: "Disabled button's background color",
    companies_form_constructor_display_node_address: 'Düğüm adresini görüntüleme',
    companies_form_constructor_display_scale_background_color: 'Ana ölçeğin arka plan rengini görüntüleme',
    companies_form_constructor_display_scale_background_color_info: 'Arka plan rengi, seçilen ana ölçek değerine bağlı olmalıdır',
    companies_form_constructor_display_scale_mascot: 'Ana ölçeğin maskotunu göster',
    companies_form_constructor_display_scale_mascot_info: 'Maskot, seçilen ana ölçek değerine bağlı olmalıdır',
    companies_form_constructor_document_description: 'İhtiyacınız olan belgeyi seçin',
    companies_form_constructor_document_description_placeholder: 'Belge açıklaması',
    companies_form_constructor_document_empty_list: 'Şirketin belgeleri yok',
    companies_form_constructor_document_placeholder: 'Belge seç',
    companies_form_constructor_document_title: 'Belge',
    companies_form_constructor_elements_border_width_title: 'Elements border width',
    companies_form_constructor_elements_color_title: 'Elemanların rengi',
    companies_form_constructor_email_placeholder: 'E-postanızı girin',
    companies_form_constructor_email_title: 'E-posta',
    companies_form_constructor_form_font_title: 'Questionnaire font',
    companies_form_constructor_form_name_label: 'Formun adı',
    companies_form_constructor_form_name_placeholder: 'Form adını girin',
    companies_form_constructor_full_answer_placeholder: 'İletişim için bir e-posta bırakırsanız mutlu oluruz',
    companies_form_constructor_full_answer_title: 'Ayrıntılı yanıt',
    companies_form_constructor_info_additional_field_placeholder: 'In this field, enter the placeholder for additional field of the question you want to ask the respondents.',
    companies_form_constructor_info_placeholder: 'Bu alana yanıtlayanlara sormak istediğiniz sorunun yer tutucusunu girin.',
    companies_form_constructor_info_question: 'Bu alana yanıtlayanlara sormak istediğiniz soruyu girin.',
    companies_form_constructor_info_scale_max: 'Bu alana maksimum ölçek değeri için kısa bir açıklama girin.',
    companies_form_constructor_info_scale_min: 'Bu alana minimum ölçek değeri için kısa bir açıklama girin.',
    companies_form_constructor_info_step_text: 'In this field, enter the text you want to show as a step text.',
    companies_form_constructor_info_thank_you_page_text: 'Bu alana Teşekkür sayfasında görüntülemek istediğiniz metni girin.',
    companies_form_constructor_info_thank_you_page_title: 'Bu alana Teşekkür sayfasında görüntülemek istediğiniz başlığı girin.',
    companies_form_constructor_key_question: 'Anahtar soru',
    companies_form_constructor_language_label: 'Formun dili',
    companies_form_constructor_language_placeholder: 'Dil',
    companies_form_constructor_localization_label: 'Tüm form için yerelleştirme',
    companies_form_constructor_logo_place_center: 'Ortalama',
    companies_form_constructor_logo_place_label: 'Logo konumu',
    companies_form_constructor_logo_place_left: 'Sol',
    companies_form_constructor_logo_place_right: 'Sağda',
    companies_form_constructor_main_question: 'Şirket derecelendirmenizi bırakın',
    companies_form_constructor_main_scale: 'Ana ölçek',
    companies_form_constructor_mascot: 'Maskot',
    companies_form_constructor_max_placeholder: 'Son derece inanılmaz',
    companies_form_constructor_min_placeholder: 'Olası olmayan',
    companies_form_constructor_name_field_required: 'Name input field required',
    companies_form_constructor_name_placeholder: 'İsminiz ne',
    companies_form_constructor_name_title: 'Adı',
    companies_form_constructor_next_field: 'Next field',
    companies_form_constructor_not_checked_elements_color_title: 'Color of not checked elements elements',
    companies_form_constructor_one_from_list_placeholder: 'Kuruluşumuzu ne zamandır ziyaret ediyorsunuz?',
    companies_form_constructor_one_from_list_title: 'Listeden biri',
    companies_form_constructor_phone_placeholder: 'Telefon numaranızı girin',
    companies_form_constructor_phone_title: 'Telefon numarası',
    companies_form_constructor_prev_field: 'Prev field',
    companies_form_constructor_privacy_policy_label: 'İhtiyacınız olan belgeyi seçin',
    companies_form_constructor_privacy_policy_placeholder: 'Belge seç',
    companies_form_constructor_privacy_policy_title: 'Gizlilik politikası',
    companies_form_constructor_quantity_button_signs_color: 'Button signs color',
    companies_form_constructor_quantity_button_signs_color_placeholder: 'Select button sings color',
    companies_form_constructor_quantity_buttons_background_color: 'Buttons background color',
    companies_form_constructor_quantity_buttons_background_color_placeholder: 'Select buttons background color',
    companies_form_constructor_quantity_default_value_title: 'Default value',
    companies_form_constructor_quantity_max_value_title: 'Max value',
    companies_form_constructor_quantity_min_value_title: 'Min value',
    companies_form_constructor_quantity_placeholder: 'How much times did you come to our place?',
    companies_form_constructor_quantity_title: 'Quantity',
    companies_form_constructor_required_question: 'Zorunlu soru',
    companies_form_constructor_scale_change_design_button: 'Ölçeğin tasarımını değiştirme',
    companies_form_constructor_scale_title: 'Ölçek',
    companies_form_constructor_select_field: 'Alan seç',
    companies_form_constructor_send_button: 'Düğme',
    companies_form_constructor_send_button_background_color: 'Düğmenin arka plan rengi',
    companies_form_constructor_send_button_link: 'Düğmenin bağlantısı',
    companies_form_constructor_send_button_text: 'Düğmenin metni',
    companies_form_constructor_send_button_text_color: 'Düğmenin metin rengi',
    companies_form_constructor_send_button_title: 'Gönder düğmesi',
    companies_form_constructor_send_disabled_button_text_color: "Disabled button's text color",
    companies_form_constructor_set_default_agreed: 'Set default agreed',
    companies_form_constructor_several_from_list_placeholder: 'Kuruluşumuzu ne zamandır ziyaret ediyorsunuz?',
    companies_form_constructor_several_from_list_title: 'Listeden birkaç',
    companies_form_constructor_show_additional_field: 'Show additional field',
    companies_form_constructor_show_name_input_field: 'Show name input field',
    companies_form_constructor_show_text_in_two_rows: 'Show text in two rows',
    companies_form_constructor_signature_label: 'İmza',
    companies_form_constructor_signature_max_label: 'maksimum',
    companies_form_constructor_signature_min_label: 'minimum',
    companies_form_constructor_start_page_button_width: 'Start page button width',
    companies_form_constructor_status_label: 'Durum',
    companies_form_constructor_step_text: 'Step text',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Write step text',
    companies_form_constructor_step_text_underline_color: 'Step text underline color',
    companies_form_constructor_step_text_underline_color_placeholder: 'Select step text underline color',
    companies_form_constructor_submit_button: 'Formu kaydet',
    companies_form_constructor_tab_diagram: 'Diagram',
    companies_form_constructor_tab_field: 'Field',
    companies_form_constructor_tab_fields: 'Fields',
    companies_form_constructor_tab_settings: 'Settings',
    companies_form_constructor_text: 'Metin...',
    companies_form_constructor_thank_you_page_field_title: 'Teşekkür sayfası',
    companies_form_constructor_thank_you_page_text: 'Teşekkür ederim sayfa metni',
    companies_form_constructor_thank_you_page_text_first_line: 'Thank you page text first line',
    companies_form_constructor_thank_you_page_text_second_line: 'Thank you page text second line',
    companies_form_constructor_thank_you_page_title: 'Teşekkürler sayfa başlığı',
    companies_form_constructor_title: 'Form oluşturucu',
    companies_form_constructor_title_additional_field_placeholder: 'Another',
    companies_form_constructor_title_additional_field_placeholder_title: 'Additional field placeholder',
    companies_form_constructor_title_placeholder: 'Bir inceleme bırakırsanız mutlu oluruz',
    companies_form_constructor_title_placeholder_title: 'Placeholder',
    companies_form_constructor_title_title: 'Başlık',
    companies_form_constructor_upload_files_label: 'Form sayfasında ne tür dosya yüklemelerinin kullanılabileceğini seçin',
    companies_form_constructor_upload_files_placeholder: 'Bir forma dosya eklemenin mümkün olduğunu açıklama',
    companies_form_constructor_upload_files_title: 'Dosya yükleme',
    companies_form_download_qr: 'Qr kodu indirin',
    companies_form_scale_constructor_change_background_checkbox_label: 'Derecelendirme değiştiğinde arka planı değiştir',
    companies_form_scale_constructor_change_background_select_label: 'Arka plan rengi',
    companies_form_scale_constructor_change_background_select_placeholder: 'Bir renk seçin',
    companies_form_scale_constructor_change_mascot_checkbox_label: 'Derecelendirme değiştiğinde maskotu değiştir',
    companies_form_scale_constructor_change_mascot_select_label: 'Maskotlar',
    companies_form_scale_constructor_change_mascot_select_placeholder: 'Bir maskot seçin',
    companies_form_scale_constructor_choose_color_placeholder: 'Bir renk seçin',
    companies_form_scale_constructor_file_not_uploaded: 'dosya yüklenmedi',
    companies_form_scale_constructor_main_gradient_colors_label: 'Temel degrade renkler',
    companies_form_scale_constructor_maximum_value_label: 'Maksimum değer',
    companies_form_scale_constructor_maximum_value_placeholder: 'Maksimum değeri girin',
    companies_form_scale_constructor_minimum_value_label: 'Varsayılan bir değer girin',
    companies_form_scale_constructor_minimum_value_placeholder: 'Varsayılan değer',
    companies_form_scale_constructor_numbers_chosen_colors_label: 'Seçilen cevabın rengi',
    companies_form_scale_constructor_numbers_colors_label: 'Sayıların rengi',
    companies_form_scale_constructor_pointer_color_label: 'İşaretçi rengi',
    companies_form_scale_constructor_show_default_value: 'Show default value',
    companies_form_scale_constructor_signature_text_color_label: 'Signature text color',
    companies_form_scale_constructor_star_chosen_color_label: 'Seçilen yıldızın rengi',
    companies_form_scale_constructor_star_not_chosen_color_label: 'Seçilmemiş yıldızın dolgu rengi',
    companies_form_scale_constructor_star_not_chosen_contour_color_label: 'Anahat yıldız anahat rengi',
    companies_form_scale_constructor_tab_title: 'Ölçek',
    companies_form_scale_constructor_title: 'Ölçek oluşturucu',
    companies_form_select_color_placeholder: 'Select color',
    companies_form_show_node_code: 'Show node code',
    companies_form_table_add_form_button: 'Formu ekle',
    companies_form_table_constructor_button: 'Form oluşturucu',
    companies_form_table_copy_button: 'Formu Kopyalama',
    companies_form_table_copy_confirm_text: 'Formu kopyalamak istediğinizden emin misiniz?',
    companies_form_table_delete_button: 'Formu Silme',
    companies_form_table_delete_confirm_text: 'Formu silmek istediğinizden emin misiniz?',
    companies_form_table_edit_button: 'Edit',
    companies_form_table_header_feedbacks: 'Yorumlar',
    companies_form_table_header_last_edit: 'Son değiştirilme tarihi',
    companies_form_table_header_link: 'Bağlantı',
    companies_form_table_header_name: 'Formun adı',
    companies_form_table_header_status: 'Durum',
    companies_form_table_new_form_language_placeholder: 'Yeni formun dili seçin',
    companies_form_table_new_form_language_title: 'Yeni formun dili',
    companies_form_table_new_form_name_placeholder: 'Yeni formun adı girin',
    companies_form_table_new_form_name_title: 'Yeni formun adı',
    companies_form_table_new_node_language_placeholder: 'Yeni düğümün dili seçin',
    companies_form_table_new_node_language_title: 'Yeni düğümün dili',
    companies_form_table_new_node_name_placeholder: 'Yeni düğümün adı girin',
    companies_form_table_new_node_name_title: 'Yeni düğümün adı',
    companies_form_table_node_to_copy: 'Formu nereye kopyalamak istiyorsunuz?',
    companies_form_table_thank_button: 'Teşekkür sayfası',
    companies_form_table_title: 'Şirket düğümü geri bildirim formları',
    companies_form_use_as_default_form: 'Varsayılan form olarak kullan',
    companies_form_with_steps: 'Form with steps',
    companies_info_add_field_button: 'Alan ekle',
    companies_info_after_save_modal_confirm_button: 'Şirket verilerini düzenle',
    companies_info_after_save_modal_title: 'Şirket başarıyla kuruldu',
    companies_info_choose_file_button: 'Bir dosya seç',
    companies_info_company_domain_label: 'Şirket etki alanı',
    companies_info_company_domain_placeholder: 'Şirket etki alanını girin',
    companies_info_company_extra_field_name_placeholder: 'Alan adını girin',
    companies_info_company_extra_field_value_placeholder: 'Alan değerini girin',
    companies_info_company_name_label: 'Şirketin adı',
    companies_info_company_name_placeholder: 'Şirket adını gir',
    companies_info_confirmation_before_save_modal_title: 'Are you sure that you want to edit company information?',
    companies_info_file_max_size: 'JPG GIF veya PNG. Maksimum boyut 800K',
    companies_info_file_not_selected: 'Dosya seçilmedi',
    companies_info_submit_create_button: 'Şirketi kaydet',
    companies_info_submit_edit_button: 'Değişiklikleri kaydet',
    companies_info_tab_base_info: 'Temel bilgiler',
    companies_info_tab_design: 'Dizayn',
    companies_info_tab_documents: 'Belgeler',
    companies_info_tab_issues_statuses_and_types: 'Sorun durumları ve türleri',
    companies_info_tab_review_table_columns_settings: 'Columns settings',
    companies_info_tab_sources: 'Sources',
    companies_info_tab_templates: 'Şablonlar',
    companies_info_title_add: 'Şirketi ekle',
    companies_info_title_edit: 'Şirketi düzenle',
    companies_issue_statuses_and_types_add_status_button: 'Durum ekle',
    companies_issue_statuses_and_types_add_status_placeholder: 'Durum adını girin',
    companies_issue_statuses_and_types_statuses_text: 'Durumlar',
    companies_issue_statuses_and_types_type_add_button: 'Tür ekle',
    companies_issue_statuses_and_types_type_add_placeholder: 'Tür adını yaz',
    companies_issue_statuses_and_types_types_text: 'Türler',
    companies_issues_and_types_confirmation_before_save_modal_title: 'Are you sure you want to edit statuses and issue types?',
    companies_main_add_company_button: 'Şirketi ekle',
    companies_main_delete_description: 'Şirketi silmek istediğinizden emin misiniz?',
    companies_main_delete_title: 'Bir şirketi silme',
    companies_main_forms_button: 'İletişim formları',
    companies_main_structure_button: 'Yapı',
    companies_main_table_company_name_header: 'Şirket',
    companies_main_title: 'Şirketler',
    companies_review_table_columns_settings_title: 'Columns',
    companies_review_title: 'Gözden geçir',
    companies_reviews_table_header_button_details: 'Detaylar',
    companies_reviews_table_header_contact: 'Kanal',
    companies_reviews_table_header_contact_type: 'İletişim türü',
    companies_reviews_table_header_create: 'Yaratılış tarihi',
    companies_reviews_table_header_estimate: 'Tahmin etmek',
    companies_reviews_table_header_files: 'Ekli dosyalar',
    companies_reviews_table_header_key_answer: 'Anahtar cevabı',
    companies_reviews_table_header_language: 'Dil',
    companies_reviews_table_header_location: 'Konumlar',
    companies_reviews_table_header_source: 'Source',
    companies_reviews_table_header_status: 'Durum',
    companies_reviews_table_title: 'Yorumlar',
    companies_source_must_be_unique_text: 'benzersiz olmalı',
    companies_source_name_label: 'Kaynağın adı',
    companies_source_name_max_length_error: 'Kaynak adı 100 karakterden az olmalıdır',
    companies_sources_add_source_button: 'Kaynak ekle',
    companies_sources_confirmation_before_save_modal_title: 'Are you sure you want to edit sources?',
    companies_sources_create_new_source_title: 'Yeni kaynak oluştur',
    companies_sources_create_source_text: 'Kaynak oluştur',
    companies_sources_placeholder: 'Kaynak adını girin',
    companies_sources_title: 'Kaynaklar',
    companies_structure: 'Yapı',
    companies_structure_create_accountable: 'Sorumlu (lar)',
    companies_structure_create_address_label: 'Adres',
    companies_structure_create_address_placeholder: 'Adresi girin',
    companies_structure_create_advanced_settings: 'Gelişmiş ayarlar',
    companies_structure_create_edit_title: 'Düğümü düzenle',
    companies_structure_create_email: 'E-posta',
    companies_structure_create_google_place_id_label: 'Google Yer Kimliği',
    companies_structure_create_integration_alpha_name_label: 'Alfa adı',
    companies_structure_create_integration_key_label: 'Anahtar',
    companies_structure_create_integration_title: 'Entegrasyon ayarları',
    companies_structure_create_modal_title: 'Düğüm oluşturmak istediğinizden emin misiniz?',
    companies_structure_create_node_code_label: 'Düğümün kodu',
    companies_structure_create_node_code_placeholder: 'Düğümün kodunu girin',
    companies_structure_create_node_name_label: 'Düğümün adı',
    companies_structure_create_node_name_placeholder: 'Düğüm adını girin',
    companies_structure_create_sms: 'SMS',
    companies_structure_create_telegram: 'Telgram',
    companies_structure_create_tied_form_placeholder: 'Form',
    companies_structure_create_tied_node_label: 'Bağlı düğüm',
    companies_structure_create_tied_node_placeholder: 'Düğüm',
    companies_structure_create_title: 'Düğüm oluştur',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay: 'Don\t use an expiration timer',
    companies_structure_lang_detection: 'Otomatik form dili algılamayı kullanma',
    companies_structure_table_accountable: 'Sorumlu',
    companies_structure_table_add_node_button: 'Düğüm ekle',
    companies_structure_table_delete_description_1: 'Düğümü silmek istediğinizden emin misiniz',
    companies_structure_table_delete_description_2: 'şirket yapıları',
    companies_structure_table_delete_description_3: 'Bu, iç içe geçmiş tüm düğümleri kaldıracaktır',
    companies_structure_table_delete_title: 'Şirket yapısı düğümünü silme',
    companies_structure_table_header_name: 'Düğümlüler',
    companies_structure_title: 'Şirket yapısı',
    companies_structure_update_modal_title: 'Düğümü düzenlemek istediğinizden emin misiniz?',
    company: 'Şirket',
    company_main_info_delete_title: 'Şirketin kaldırılması',
    company_main_info_edit_title: 'Şirket hakkında temel bilgileri düzenleyin \ncompany designcompany belgeleri',
    company_main_info_go_to_company_structure: 'Şirket yapısına git',
    company_structure_delete_button_title: 'Bir düğümü silme',
    company_structure_edit_button_title: 'Düğüm düzenleme',
    confirm_button: 'Confirm',
    connect_button: 'Takmak',
    continue_editing_button: 'Düzenlemeye devam et',
    continue_review_dialog_button: 'Diyaloğu devam et',
    copy_button: 'Kopyalamak',
    copy_error: '* Kopyalama sırasında bir hata oluştu',
    create_button: 'Yaratmak',
    data_save_error: '* Veri kaydedilirken bir hata oluştu',
    delete_button: 'Silmek',
    delete_error: '* Silme sırasında bir hata oluştu',
    document: 'Belge',
    done_button: 'Yapıldı',
    edit_button: 'Düzenlemek',
    email: 'E-posta',
    email_label: 'E-posta',
    email_placeholder: 'E-posta girin',
    employee_profile_title: 'Çalışan profili',
    enter_button: 'Giriş',
    enter_forgot_button: 'Şifre kurtarma',
    enter_task_name: 'Enter task name',
    error: '* Bir hata oluştu',
    error_text: 'Error text',
    error_title: 'Error',
    expecting_reply_from_client: 'Müşteri cevabını bekleniyor',
    filter_title_sources: 'Sources',
    filter_title_status: 'Durumlar',
    filters: 'Filtre',
    finish_review_dialog_button: 'Diyaloğu bitir',
    finish_review_dialog_modal_description: 'Diyaloğu bitirmek istediğinizden emin misiniz?',
    finish_review_dialog_modal_title: 'Diyaloğu bitir',
    form: 'Form',
    form_constructor_connection_case_label: 'Durum',
    form_constructor_connection_case_placeholder: 'Vaka seç',
    form_constructor_connection_go_to: '...git',
    form_constructor_connection_render_minifield_connection_always: 'Her zaman',
    form_constructor_connection_render_minifield_connection_type_texts_always: 'Diğer tüm durumlar',
    form_constructor_connection_render_minifield_connection_type_texts_if: 'Eğer öyleyse',
    form_constructor_connection_select_field: 'Alan seç',
    form_constructor_connection_select_with_any_items_label: 'Sonraki alan',
    form_constructor_connection_select_with_any_items_options_1_question: 'Seçili değil',
    form_constructor_connection_select_with_any_items_placeholder: 'Alan seç',
    form_constructor_connection_statement_label: 'Açıklama',
    form_constructor_connection_statement_placeholder: 'Select deyimi',
    form_constructor_delete_field_modal_description: 'Are you sure you want to delete this field?',
    form_constructor_delete_field_modal_title: 'Warning!',
    form_constructor_diagram_fields_list_is_empty: 'Alanlar listesi boş',
    form_constructor_fields_form_content: 'İçerik',
    form_constructor_fields_form_documents: 'Belge',
    form_constructor_fields_form_no_fields_selected: 'Seçili alan yok',
    form_constructor_fields_form_send_button: 'Gönder düğmesi',
    form_constructor_fields_form_thank_you_page: 'Teşekkür sayfası',
    form_copy_save_error: '* Seçili düğümde aynı ada sahip bir form zaten var',
    form_language_error: '* Zaten seçili dil ile formunuz var',
    form_name_error: '* Form adı benzersiz olmalıdır',
    form_save_error: '* Formu kaydederken bir hata oluştu',
    from: 'kimden',
    full_name_label: 'Çalışanın tam adı',
    full_name_placeholder: 'Çalışan adını girin',
    generate_button: 'Oluşturmak',
    go_to_forms_page_button_title: 'Geri bildirim formlarına git',
    go_to_issues_create: "Sorunlar oluştur'a gidin",
    go_to_reviews_page_button_title: 'İncelemelere git',
    go_to_task_create: 'Görev oluştura git',
    in_progress: 'Devam ediyor',
    issues_add_issue: 'Sorun ekleme',
    issues_form_company_label: 'Şirket',
    issues_form_company_placeholder: 'Bir şirket seçin',
    issues_form_connect_issue_label: 'Bağlantı sorunu',
    issues_form_connect_issue_placeholder: 'Bir sorun seçin',
    issues_form_create_new_issue_status: 'Yeni sorun durumu oluştur',
    issues_form_create_new_issue_status_title: 'Durum oluştur',
    issues_form_create_new_issue_type: 'Yeni sorun türü oluştur',
    issues_form_create_new_issue_type_title: 'Tür oluştur',
    issues_form_must_be_unique: 'benzersiz olmalı',
    issues_form_name_label: 'Sorunun adı',
    issues_form_name_placeholder: 'Form adını girin',
    issues_form_node_label: 'Düğüm',
    issues_form_node_placeholder: 'Bir düğüm seçin',
    issues_form_priority_label: 'Sorunun önceliği',
    issues_form_priority_placeholder: 'Bir öncelik seçin',
    issues_form_responsible_label: 'Sorumlu',
    issues_form_responsible_placeholder: 'Bir sorumlu seçin',
    issues_form_review_label: 'Gözden geçirmek',
    issues_form_review_placeholder: 'Bir inceleme seçin',
    issues_form_status_label: 'Sorunun durumu',
    issues_form_status_name_label: 'Sorun durumunun adı',
    issues_form_status_placeholder: 'Bir durum seçin',
    issues_form_type_label: 'Sorun türü',
    issues_form_type_name_label: 'Yayın türü adı',
    issues_form_type_placeholder: 'Bir sorun türü seçin',
    issues_save_modal_create_title: 'Sorun yaratmak istediğinizden emin misiniz?',
    issues_save_modal_edit_title: 'Sorunu düzenlemek istediğinizden emin misiniz?',
    issues_search_by_name: 'İsme göre arama sorunu',
    issues_sub_header_create_issue_title: 'Sorun oluştur',
    issues_sub_header_create_task_title: 'Görev oluşturma',
    issues_sub_header_edit_issue_title: 'Sorunu düzenle',
    issues_sub_header_title: 'Sorunlar',
    issues_table_empty: 'Sorunları görüntülemek için şirket seçin',
    issues_table_header_company: 'Şirket',
    issues_table_header_date: 'Yaratılış tarihi',
    issues_table_header_name: 'Adı',
    issues_table_header_node: 'Düğüm',
    issues_table_header_priority: 'Öncelik',
    issues_table_header_status: 'Durum',
    issues_table_header_type: 'Sorun türü',
    language: 'Dil',
    languages_add_name_placeholder: 'Adı girin',
    languages_add_short_name_label: 'Kısa adı',
    languages_add_short_name_placeholder: 'Kısa adı girin',
    languages_add_title: 'Dil ekleme',
    languages_table_add_language_button: 'Dil ekle',
    languages_table_header_full_name: 'Tam adı',
    languages_table_header_short_name: 'Kısa adı',
    languages_table_modal_delete_buttons_label_end: '',
    languages_table_modal_delete_buttons_label_start: 'Dili silmek istediğinizden emin misiniz?',
    languages_table_modal_delete_title: 'Dili silme',
    languages_table_title: 'Diller',
    leave_the_page: 'Sayfayı terk et',
    loading_error: '* Veri yüklenirken bir hata oluştu',
    login_recovery_auth: 'Geri dönmek',
    login_recovery_complete: 'Kısa süre içinde e-posta adresinize parolanızı nasıl sıfırlayacağınıza ilişkin talimatları içeren bir mesaj gönderilecektir',
    mass_mailing_type_by_addresses: 'Adres postalama',
    mass_mailing_type_by_company: 'Şirkete göre',
    max_size_error: '* İzin verilen maksimum dosya boyutu aşıldı',
    max_size_error_25: "* İzin verilen maksimum dosya boyutu 25 mb'dir",
    max_table_column_settings_apply_warning: 'Max 6 active settings',
    more_detailed: 'More detailed',
    name: 'İsim',
    new_task_name: 'Yeni görev adı',
    next_button: 'Sonraki',
    no_options_available: 'Seçenek yok',
    node: 'Düğüm',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes,',
    not_decided: 'Çözülmedi',
    not_viewed: 'Görüntülenmedi',
    nothing_find: 'Not found,',
    login_recovery_password_title: 'Kayıp bir parolanın kurtarılması',
    open_issue_title: 'Açık sorun',
    open_language_title: 'Açık dil',
    open_permission_title: 'Açık izin',
    open_review_title: 'Açık inceleme',
    open_role_title: 'Açık rol',
    open_user_title: 'Açık kullanıcı',
    other: 'Other',
    overdue_status_text: 'Gecikme',
    pagination_page_number_placeholder: 'Page number',
    pagination_title: 'Sayfa başına satır sayısı',
    password: 'Şifre',
    password_confirmation_label: 'Şifreyi tekrarla',
    password_confirmation_placeholder: 'Şifreyi tekrar girin',
    password_label: 'Şifre',
    password_placeholder: 'Şifreyi girin',
    permission_create_title: 'İzin oluştur',
    permission_edit_title: 'Düzenleme izni',
    permission_name_placeholder: 'İzin adını girin',
    permission_name_title: 'İzin adı',
    permission_resource_placeholder: 'İzin kaynağını girin',
    permission_resource_title: 'Kaynak',
    permissions_main_add_permission_button: 'İzin ekle',
    permissions_main_delete_description: 'İzni silmek istediğinizden emin misiniz?',
    permissions_main_delete_title: 'Bir izni silme',
    permissions_main_permissions_button: 'İzinler',
    permissions_main_table_create_header: 'Yaratma',
    permissions_main_table_delete_header: 'Silme',
    permissions_main_table_permission_header: 'İzin adı',
    permissions_main_table_read_header: 'Okumak',
    permissions_main_table_resource_header: 'Kaynak',
    permissions_main_table_update_header: 'Güncelleme',
    permissions_main_title: 'İzinler',
    phone: 'Telefon',
    phone_number_label: 'Telefon numarası',
    phone_number_placeholder: 'Telefon numarasını girin',
    photo: 'Fotoğraf',
    preview_button: 'Önizleme',
    privacy_policy: 'kullanıcı sözleşmesi',
    privacy_policy_confirm: 'Katılıyorum',
    record: 'Ses',
    refresh_all_fitlers: 'Tüm filtreleri sıfırla',
    registration_profile_completion: 'Profil tamamlama',
    reopened: 'Tekrar açıldı',
    reset_reviews_filter: 'Reset filter',
    reset_table_settings: 'Reset table settings',
    review_choose_template: 'Bir yanıt şablonu seçin',
    review_created_at: 'Review date and time',
    review_dialog_finished_text: 'Diyalog sona erdi',
    review_form_name: 'Formun adı',
    review_form_source: 'Form source',
    review_handle_continue_dialog_error_message: 'Bir terslik var. Lütfen, sonra tekrar deneyin.',
    review_message_input_placeholder: 'Mesajınızı yazın',
    review_no_asscociated_issues: 'İlişkili sorun yok',
    review_no_associated_tasks: 'İlişkili görev yok',
    review_node_code: 'Düğüm kodu',
    review_node_name: 'Düğümün adı',
    review_on_submit_3_error_message: 'Müşterinin zaten başka bir yönetici ile diyaloğu var. Lütfen, sonra tekrar deneyin.',
    review_open_task_button_text: 'Open task',
    review_page_type_of_task: 'Görev türü',
    review_review_id: 'İnceleme id',
    review_save_modal_title: 'İncelemeyi düzenlemek istediğinizden emin misiniz?',
    review_updated_at: 'Processing date,',
    reviews_page_choose_company_title: 'Select a company from the drop-down list',
    reviews_table_comment_column_title: 'Comment',
    reviews_table_email_column_title: 'Email',
    reviews_table_filter_title: 'Filter',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_node_column_title: 'Node',
    reviews_table_parent_node_column_title: 'Parent node',
    reviews_table_phone_column_title: 'Phone number',
    reviews_table_review_id_column_title: 'Review ID',
    reviews_table_ukrposhta_settings_button: 'Unprocesed reviews',
    reviews_table_user_name_column_title: 'Name',
    reviewType: 'İnceleme türü',
    role_create_title: 'Rol oluştur',
    role_edit_title: 'Rolü düzenle',
    role_name_placeholder: 'Rol adını girin',
    role_name_title: 'Rol adı',
    role_page_create_title: 'Rol oluştur',
    role_permissions: 'İzinler',
    role_select_permissions_placeholder: 'İzinleri seç',
    roles_main_add_role_button: 'Rol ekle',
    roles_main_delete_description: 'Rolü silmek istediğinizden emin misiniz?',
    roles_main_delete_title: 'Bir rolü silme',
    roles_main_permissions_button: 'İzinler',
    roles_main_table_user_role_header: 'Rol adı',
    roles_main_title: 'Roller',
    roles_part_analytic: 'Analitik',
    roles_part_companies: 'Şirketler',
    roles_part_documents: 'Belge',
    roles_part_forms: 'Formlar',
    roles_part_languages: 'Diller',
    roles_part_mass_mailings: 'Toplu postalar',
    roles_part_nodes: 'Düğümlüler',
    roles_part_reviews: 'Yorumlar',
    roles_part_users: 'Kullanıcı',
    roles_sub_permission_create: 'Yaratma',
    roles_sub_permission_delete: 'Silme',
    roles_sub_permission_read: 'Okuma',
    roles_sub_permission_update: 'Güncelleme',
    save_button: 'Kaydetmek',
    save_changes_button: 'Değişiklikleri Kaydet',
    save_document_button: 'Kaydetmek',
    save_draft_button: 'Taslağı kaydet',
    save_field_button: 'Save field',
    save_form_button: 'Formu kaydet',
    save_form_without_changes_button: 'Değişiklik yapmadan formu kaydet',
    search: 'Arama...',
    search_for_name: 'İsme göre ara',
    search_for_email: 'Search by email',
    select_company: 'Firma seçiniz',
    select_form: 'Form seç',
    select_language: 'Dil seçiniz',
    select_multiple_sources: 'Select sources',
    select_multiple_statuses: 'Select statuses',
    select_node: 'Düğüm seç',
    select_status: 'Durum seç',
    select_type: 'Türü seçin',
    send_button: 'Göndermek',
    sidebar_analytic: 'Analiz',
    sidebar_companies: 'Şirketler',
    sidebar_exit: 'Profilden çık',
    sidebar_issues: 'Sorunlar',
    sidebar_languages: 'Diller',
    sidebar_profile: 'Profil',
    sidebar_reviews: 'Yorumlar',
    sidebar_tasks: 'Görevler',
    sidebar_users: 'Kullanıcılar',
    sms: 'SMS',
    social_networks_module_text: 'Bizimle iletişim kurmanız için uygun bir yol seçin',
    solution_not_required: 'Çözüm gerekli değil',
    solved: 'Çözüldü',
    source: 'Kaynak',
    status: 'İnceleme durumu',
    status_code: 'Status code',
    table_columns_settings_comment_option: 'Comment',
    table_columns_settings_communication_channel_option: 'Communication type',
    table_columns_settings_company_option: 'Company',
    table_columns_settings_created_at_option: 'Created at',
    table_columns_settings_email_option: 'Email',
    table_columns_settings_estimation_option: 'Score',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_language_option: 'Language',
    table_columns_settings_name_option: 'Name',
    table_columns_settings_node_option: 'Node',
    table_columns_settings_parent_node_option: 'Parent node',
    table_columns_settings_phone_option: 'Phone number',
    table_columns_settings_reviewId_option: 'Review ID',
    table_columns_settings_source_option: 'Source',
    table_columns_settings_status_option: 'Status',
    table_settings: 'Table settings',
    task_form_category_label: 'Category of task',
    task_form_create_task: 'Create task',
    task_form_list_category_label: 'Category',
    task_form_list_subcategory_label: 'Subcategory',
    task_form_list_type_label: 'Type',
    task_form_name_label: 'Görevin adı',
    task_form_priority_label: 'Görev önceliği',
    task_form_status_label: 'Görev durumu',
    task_form_subcategory_label: 'Subcategory of task',
    task_form_type_label: 'Görev türü',
    telegram: 'Telgraf',
    to: 'kime',
    to_plug_button: 'Takmak',
    turn_off_button: 'Devre dışı bırakmak',
    upload: 'Yükle',
    upload_color_button: 'Renk yükle',
    uploading_files: 'Dosya yükleme',
    user_email_placeholder: 'E-posta girin',
    user_email_title: 'E-posta',
    user_name_placeholder: 'Adı girin',
    user_name_title: 'Adı soyadı',
    user_new_password_placeholder: 'Yeni şifre girin',
    user_new_password_title: 'Yeni şifre',
    user_old_password_placeholder: 'Eski şifreyi girin',
    user_old_password_title: 'Eski şifre',
    user_page_create: 'Kullanıcı oluştur',
    user_page_edit: 'Kullanıcıyı düzenle',
    user_password_confirmation_placeholder: 'Şifreyi tekrarla',
    user_password_confirmation_title: 'Şifre onayı',
    user_password_placeholder: 'Şifreyi girin',
    user_password_title: 'Şifre',
    user_role_placeholder: 'Kullanıcı rollerini seçin',
    user_role_title: 'Roller',
    users_main_add_user_button: 'Kullanıcı ekle',
    users_main_delete_description: 'Kullanıcıyı silmek istediğinizden emin misiniz?',
    users_main_delete_title: 'Bir kullanıcıyı silme',
    users_main_roles_button: 'Roller',
    users_main_table_user_company_header: 'Şirket',
    users_main_table_user_email_header: 'E-posta',
    users_main_table_user_name_header: 'İsim',
    users_main_table_user_notifications_header: 'Bildirimler',
    users_main_table_user_role_header: 'Rol',
    users_main_title: 'Kullanıcı',
    users_main_upload_users_button: 'Kullanıcıları yükle',
    validation_constructor_at_least_2_possible_answers: '* Sorunun en az 2 olası cevabı olmalıdır',
    validation_constructor_should_have_at_least_1_document: '* Form en az bir belgeye sahip olmalıdır',
    validation_constructor_should_select_file_types: '* Yüklemeye izin verilen dosya türlerini seçmeniz veya bu alanı formdan kaldırmanız gerekir',
    validation_domain: 'Etki alanı yalnızca ingilizce karakterler içerebilir numbers "." ve "-"',
    validation_form_should_have_minimum_1: '* Form en az bir alana sahip olmalıdır',
    validation_full_type_required: '* You must select each parts of type',
    validation_invalid_email: '* Geçersiz e-posta biçimi',
    validation_invalid_link: '* Geçersiz bağlantı biçimi',
    validation_more_one_accountable: '* En az 1 düğüm yöneticisi seçmelisiniz',
    validation_more_one_option: '* En az 1 seçenek seçmelisiniz',
    validation_must_fill_all_fields_for: '* Tüm alanları doldurmanız gerekir',
    validation_must_fill_this_field: '* Bu alanı doldurmanız gerekmektedir',
    validation_name_must_be_longer_4: '* İsim çok kısa - en az 4 karakter uzunluğunda olmalıdır.',
    validation_required: '* gerekli',
    viber: 'Viber',
    video: 'Video',
    viewed: 'Görüntülüyordu',
    your_changes_lose: 'Değişiklikleri kaydetmeniz gerekiyor. Aksi takdirde kaybolacaklar.',
    send_feedback: 'Geribildirim yolla',
    send_feedback_title: 'Geri bildirim',
    send_feedback_label: 'Dilekleriniz veya yorumlarınız',
    send_feedback_complete: 'geri bildiriminiz için teşekkür ederiz',
    sidebar_impex: 'Içe aktarmak/İhracat',
    impex_page_title: 'Içe aktarmak/İhracat',
    impex_import_tab: 'Içe aktarmak',
    impex_export_tab: 'İhracat',
    select_import_entity: 'Ne ithal etmek istiyorsunuz?',
    start_import_button_text: 'İçe aktarmayı başlat',
    choose_file_for_import: 'İçe aktarılacak dosyayı seçin',
    import_entity_selection_title: 'To import',
    import_file_sheet_name_label: 'The name of the sheet in the import file',
    import_file_sheet_name_input_placeholder: 'Enter name of the sheet',
    interrupt_import_script_button_text: 'Interrupt import',
    import_file_format_text: 'Format must be:',
    import_progress_bar_title: 'Import progress:',
    import_users_entity_name: 'Users',
    import_nodes_entity_name: 'Nodes',
    qr_title: "Bildirimleri daha da özelleştirmek için QR'yi tarayın",
    mass_mailings_channels_of_connections: 'bağlantı kanalları',
    mass_mailings_send_button: 'Göndermek',
    yes_button_text: 'Evet',
    no_button_text: 'HAYIR',
    confirm_send_mass_mailings_modal_title: 'Are you sure you want to make mass mailings?',
    modal_mass_mailings_sent_success_title: 'Messages sent successfully',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Error sending messages',
    mass_mailings_recipient_label: 'Recipient',
    mass_mailings_recipients_list_title: 'Recipients',
    add_at_least_one_recipient_error: 'At least one recipient must be added',
    sidebar_mass_mailings: 'Mass mailings',
    create_mass_mailings_page_title: 'Create mass mailings',
    create_mass_mailings_first_step_subheader_title: 'Mass mailing - Creating - Step 1 of 3',
    choose_mass_mailings_communication_channel: 'Choose communication channel',
    mass_mailings_add_recipient_button_text: 'Add recipient',
    download_recipients_file_label: 'Download a file with recipients',
    format: 'Format',
    choose_file: 'Choose a file',
    file_data_downloading_text: 'File data downloading',
    mass_mailings_mails_count: 'The number of mails that will be sent',
    create_sms_mass_mailings_second_step_subheader_title: 'Creating SMS-mailing - Step 2 of 3',
    create_viber_mass_mailings_second_step_subheader_title: 'Creating Viber-mailing - Step 2 of 3',
    create_sms_mass_mailings_third_step_subheader_title: 'Creating SMS-mailing - Step 3 of 3',
    create_viber_mass_mailings_third_step_subheader_title: 'Creating Viber-mailing - Step 3 of 3',
    mass_mailings_sender_account: 'Sender account',
    sms_text: 'SMS text',
    message: 'Message',
    message_text_placeholder: 'Message text',
    responsible: 'Responsabil',
    responsible_id: 'ID-ul inculpatului',
    companies_form_constructor_dividing_decorative_line: 'Dekoratif çizgi parametrelerini bölen form',
    companies_form_constructor_show_form_dividing_decorative_line: 'Formun ayırıcı dekoratif çizgisini göster',
    companies_form_constructor_dividing_decorative_line_width_in_percent: 'Dekoratif çizginin yüzde cinsinden uzunluğu',
    companies_form_constructor_dividing_decorative_line_height: 'Dekoratif çizginin piksel cinsinden yüksekliği',
    companies_form_constructor_dividing_decorative_line_height_placeholder: 'Dekoratif çizginin yüksekliğini belirtin',
    companies_form_constructor_dividing_decorative_line_color: 'Dekoratif çizginin rengi',
    companies_form_constructor_dividing_decorative_line_color_placeholder: 'Dekoratif çizginin rengini seçin',
    export_entity_selection_title: 'To export',
    select_export_entity: 'Neyi ihraç etmek istiyorsunuz?',
    export_button_text: 'İhracat',
    export_nodes_error_text: 'Düğümler dışa aktarılırken hata oluştu',
    export_users_error_text: 'Kullanıcılar dışa aktarılırken hata oluştu',
    quarter: 'Last 3 months',
    months: 'Last 1 months',
    days7: 'Last 7 days',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Phone',
    email_network: 'Email',
    whatsapp_network: 'WhatsApp',
  },
  RU: {
    pass_changed_success: 'Password changed successfully',
    connect_tg: 'Connect Telegram bot',
    connect_vb: 'Connect Viber bot',
    notifiable_sources: 'Notifiable Sources',
    notifications: 'Notifications',
    reset_pass: 'Reset password',
    audit: 'История обработки',
    CREATE: 'Публикация',
    UPDATE: 'Обновление',
    DELETE: 'Удаление',
    review_audit: 'Истрория обработки отзыва ',
    SYSTEM: 'Система',
    USER: 'Пользователь',
    actor: 'Исполнитель',
    action: 'Дия',
    search_by_phone: 'Поиск за номером телефона',
    search_by_reviewID: 'Поиск за индентификатором отзыва',
    chat_error_no_user: 'Чат с пользователем не найден, возможно, он не подключил или удалил бота',
    chat_label: 'Чат з клиентом',
    comment_internal: 'Внутренний комментарий',
    changes_not_saved_modal: 'Изменения не сохраняются. Вы уверены, что хотите оставить страницу?',
    go_back: 'Вернуться',
    all_items_uploaded: 'Все елементы загружено',
    login_title: 'Войти',
    login_recovery_password: 'Возобновление пароля',
    add_comment: 'Добавить комментарий',
    add_button: 'Добавить',
    add_option: 'Добавить вариант',
    add_option_button: 'Добавить вариант',
    apply_filters_button: 'Использовать фильтры',
    apply_table_settings: 'Применить найстройки',
    are_you_sure: 'Вы уверенны?',
    attached_files: 'Прикрепить файлы',
    bot_is_not_connected: 'Бот не подключен',
    cancel_button: 'Закрыть',
    cant_decided: 'Не может быть решено',
    change_new_form_button: 'Изменить новую форму',
    changes_saved_successfully: 'Изменения сохранены успешно',
    channels_for_notifications: 'Каналы для уведомления',
    channels_of_connections: 'Каналы обратной связи',
    chat_error_description: 'Возникла ошибка во время отправки сообщения',
    chat_history_is_empty: 'История чата пустая',
    chat_is_finished: 'Чат завершен',
    choose_file_button: 'Выбрать ',
    choose_period: 'Выбрать период',
    client_form_scale_field_h3: 'Ваши впечетления от нашей компании',
    close_button: 'Закрыть',
    close_editing_button: 'Закрыть редактирование',
    closed_auto_no_replay: 'Закрыто автоматически из-за отсутствия ответа от клиента ',
    color_picker_add_button: 'Добавить цвет',
    comment_placeholder: 'Введите комментарий, почему выбрали этот статус',
    companies_columns_settings_confirmation_before_save_modal_title: 'Вы уверены, что хотите редактировать настройки столбцов на странице отзывов?',
    companies_design_add_color_button: 'Добавить цвет',
    companies_design_add_color_placeholder: 'Введите название цвета',
    companies_design_add_font_button: 'Добавить шрифт',
    companies_design_add_font_placeholder: 'Введите название шрифта',
    companies_design_colors_text: 'Цвета',
    companies_design_confirmation_before_save_modal_title: 'Вы уверены, что хотите редактировать информацию о компании?',
    companies_design_fonts_text: 'Шрифты',
    companies_design_logos_add_button: 'Загрузить файл',
    companies_design_logos_add_placeholder: 'Введите название логотипа',
    companies_design_logos_text: 'Изображение',
    companies_documents_add_localization: 'Язык',
    companies_documents_add_name_label: 'Название',
    companies_documents_add_name_placeholder: 'Введите название документа',
    companies_documents_add_title: 'Добавление документа',
    companies_documents_add_title_editing: 'Редактирование документа',
    companies_documents_file_caption: 'Файл',
    companies_documents_file_format: 'Формат',
    companies_documents_file_max_size: 'Максимальный розмер файла',
    companies_documents_file_not_loaded: 'не загружено',
    companies_documents_file_save_button: 'Завантажити файл',
    companies_documents_file_text_editor_label: 'Текст документа',
    companies_documents_file_text_editor_placeholder: 'Введите текст документа',
    companies_documents_table_add_document_button: 'Добавить документ',
    companies_documents_table_delete_text: 'Ви уверенны, что хотите удалить документ?',
    companies_documents_table_delete_title: 'Удалить документ',
    companies_documents_table_header_edit_date: 'Дата редактирования',
    companies_documents_table_header_edit_languages: 'Языки',
    companies_documents_table_header_name: 'Название',
    companies_form_add_anonymous_checkbox_no: 'Неті. не может быть анонимной',
    companies_form_add_anonymous_checkbox_yes: 'Да. может быть анонимной',
    companies_form_add_anonymous_label: 'Возможность оставлять анонимные отзывы',
    companies_form_add_create_title: 'Добавить форму обратной связи',
    companies_form_add_edit_title: 'Редактировать форму обратной связи',
    companies_form_add_link_label: 'Ссылка на форму',
    companies_form_add_link_placeholder: 'Введите ссылку на форму',
    companies_form_add_main_language_label: 'Основной язык',
    companies_form_add_main_language_placeholder: 'Не выбрано',
    companies_form_add_multi_languages_label: 'Многоязычный',
    companies_form_add_multi_languages_placeholder: 'Виберите языки',
    companies_form_add_name_label: 'Название формы',
    companies_form_add_name_placeholder: 'Введите название формы',
    companies_form_add_status_not_published: 'Не опубликовано',
    companies_form_add_status_published: 'Опубликовано',
    companies_form_add_status_title: 'Статус',
    companies_form_add_tied_node_label: 'Связный узел',
    companies_form_add_tied_node_label_second: 'Второй отвественный в узлеі',
    companies_form_add_tied_node_placeholder: 'Узел',
    companies_form_constructor_add_an_option: 'Добавить вариант',
    companies_form_constructor_add_document_button: 'Добавить документ',
    companies_form_constructor_add_field_to_series: 'Добавить в серию полей',
    companies_form_constructor_add_question_button: 'Добавить вопрос',
    companies_form_constructor_background_color: 'Фон',
    companies_form_constructor_background_color_for_text_title: 'Цвет фона текста',
    companies_form_constructor_background_color_title: 'Цвет фона',
    companies_form_constructor_caption_checkbox_label: 'Виберите документ',
    companies_form_constructor_caption_placeholder: 'Виберите нужный документ',
    companies_form_constructor_caption_title: 'Политика конфидициальности',
    companies_form_constructor_checked_elements_border_radius_title: 'Радиус границы выбраных кнопок',
    companies_form_constructor_checked_elements_checked_size_title: 'Розмер выбранной кнопки',
    companies_form_constructor_choice_description_info: 'Вы можете отобразить текстовое поле, если хотите, чтобы пользователь добавил обьяснения, когда выбран этот вариант',
    companies_form_constructor_choice_description_placeholder: 'Виберите шаблон описания…',
    companies_form_constructor_choice_description_required: 'Обязательное описание выбора',
    companies_form_constructor_choice_description_title: 'Виберите описание надписи…',
    companies_form_constructor_choose_form_font_placeholder: 'Виберите шрифт анкети',
    companies_form_constructor_choose_logo_button: 'Виберите логотип',
    companies_form_constructor_choose_logo_label: 'Логотип',
    companies_form_constructor_choose_logo_title: 'Виберите логотип из загруженный файлов компании',
    companies_form_constructor_choose_main_mascot_button: 'Виберите москот',
    companies_form_constructor_choose_main_mascot_label: 'Главный москот ',
    companies_form_constructor_choose_main_mascot_title: 'Виберите москот из загруженных файлов компании',
    companies_form_constructor_chose_field_placeholder: 'Виберите файл',
    companies_form_constructor_communication_channels_call: 'Звонок',
    companies_form_constructor_communication_channels_checkbox_auto_answer_label: 'Автоответ',
    companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder: 'Введіть текст ответа',
    companies_form_constructor_communication_channels_email: 'Почта',
    companies_form_constructor_communication_channels_label: 'Виберите, какой канал комуникации будет доступный в форме отзыва',
    companies_form_constructor_communication_channels_placeholder: 'Объяснение, что нужно выбрать канал коммуникации для обратной связи',
    companies_form_constructor_communication_channels_sms: 'SMS',
    companies_form_constructor_communication_channels_sub_label: 'Выберите мессенджеры, которые будут использоваться для обратной связи',
    companies_form_constructor_communication_channels_telegram: 'Telegram',
    companies_form_constructor_communication_channels_whatsapp: 'WhatsApp',
    companies_form_constructor_communication_channels_title: 'Каналы коммуникации',
    companies_form_constructor_communication_channels_viber: 'Viber',
    companies_form_constructor_communication_channels_without_answer: 'Без ответа',
    companies_form_constructor_country_code_not_editable: 'Код страны не редактируется',
    companies_form_constructor_disabled_send_button_background_color: 'Цвет фона неактивной кнопки',
    companies_form_constructor_display_node_address: 'Отображать адрес узла',
    companies_form_constructor_display_scale_background_color: 'Отобразить цвет фона главной шкалы',
    companies_form_constructor_display_scale_background_color_info: 'Цвет фона зависит от выбранного значения главной шкалы',
    companies_form_constructor_display_scale_mascot: 'Отобразить маскот главной шкалы',
    companies_form_constructor_display_scale_mascot_info: 'Маскот зависит от значения главной шкалы',
    companies_form_constructor_document_description: 'Виберите нужный документ',
    companies_form_constructor_document_description_placeholder: 'Опис документа',
    companies_form_constructor_document_empty_list: 'У компании еще нет документов',
    companies_form_constructor_document_placeholder: 'Виберите документ',
    companies_form_constructor_document_title: 'Документ',
    companies_form_constructor_elements_border_width_title: 'Ширина границы элементов',
    companies_form_constructor_elements_color_title: 'Цвет элементов',
    companies_form_constructor_email_placeholder: 'Введите свой адрес электронной почты',
    companies_form_constructor_email_title: 'Почта',
    companies_form_constructor_form_font_title: 'Шрифт анкеты',
    companies_form_constructor_form_name_label: 'Название формы',
    companies_form_constructor_form_name_placeholder: 'Введите название формы',
    companies_form_constructor_full_answer_placeholder: 'Будем рады, если вы оставите свой адрес электронной почты для связи',
    companies_form_constructor_full_answer_title: 'Детальный ответ',
    companies_form_constructor_info_additional_field_placeholder: 'В этом поле введитеплейсхолдер для поля дополнительного вопроса',
    companies_form_constructor_info_placeholder: 'В этом поле введите шаблон вопроса, на который вы хотели бы получить ответ.',
    companies_form_constructor_info_question: 'В этом поле введите вопрос, на который вы хотели бы получить ответ.',
    companies_form_constructor_info_scale_max: 'В этом поле введите краткое описание для максимальной оценки в шкале',
    companies_form_constructor_info_scale_min: 'В этом поле введите краткое описание для минимальной оценки в шкале',
    companies_form_constructor_info_step_text: 'В этом поле введите текст, который вы хотите показать как текст шага.',
    companies_form_constructor_info_thank_you_page_text: 'В этом поле введите текст, который будет отображаться на странице благодарности.',
    companies_form_constructor_info_thank_you_page_title: 'В этом поле введите текст заголовка, который будет отображаться на странице благодарности.',
    companies_form_constructor_key_question: 'Ключевой вопрос',
    companies_form_constructor_language_label: 'Язык формы',
    companies_form_constructor_language_placeholder: 'Язык',
    companies_form_constructor_localization_label: 'Размещение для всей формы',
    companies_form_constructor_logo_place_center: 'По центу',
    companies_form_constructor_logo_place_label: 'Расположение логотипа',
    companies_form_constructor_logo_place_left: 'Слева',
    companies_form_constructor_logo_place_right: 'Справа',
    companies_form_constructor_main_question: 'Оставьте оценку компании',
    companies_form_constructor_main_scale: 'Главная шкала',
    companies_form_constructor_mascot: 'Москот',
    companies_form_constructor_max_placeholder: 'Очень вероятно',
    companies_form_constructor_min_placeholder: 'Маловероятно',
    companies_form_constructor_name_field_required: 'Необходимо поле ввода имени',
    companies_form_constructor_name_placeholder: 'Ваше имя',
    companies_form_constructor_name_title: 'Имя',
    companies_form_constructor_next_field: 'Следующее поле',
    companies_form_constructor_not_checked_elements_color_title: 'Цвет невыбранных элементов',
    companies_form_constructor_one_from_list_placeholder: 'Как давно вы посещаете наше заведение?',
    companies_form_constructor_one_from_list_title: 'Один из списка',
    companies_form_constructor_phone_placeholder: 'Введите номер телефона',
    companies_form_constructor_phone_title: 'Номер телефона',
    companies_form_constructor_prev_field: 'Предыдущее поле',
    companies_form_constructor_privacy_policy_label: 'Выберите нужный документ',
    companies_form_constructor_privacy_policy_placeholder: 'Виберите документ',
    companies_form_constructor_privacy_policy_title: 'Политика конфиденциальности',
    companies_form_constructor_quantity_button_signs_color: 'Цвет подписей кнопок',
    companies_form_constructor_quantity_button_signs_color_placeholder: 'Выбрать цвет подписей кнопок',
    companies_form_constructor_quantity_buttons_background_color: 'Цвет фона кнопок',
    companies_form_constructor_quantity_buttons_background_color_placeholder: 'Выберите цвет фона кнопок',
    companies_form_constructor_quantity_default_value_title: 'Значение по умолчанию',
    companies_form_constructor_quantity_max_value_title: 'Максимальное значение',
    companies_form_constructor_quantity_min_value_title: 'Минимальное значение',
    companies_form_constructor_quantity_placeholder: 'Сколько раз вы посещали нашу локацию?',
    companies_form_constructor_quantity_title: 'Колличество',
    companies_form_constructor_required_question: 'Обязательный вопрос',
    companies_form_constructor_scale_change_design_button: 'Изменить дизайн шкалы',
    companies_form_constructor_scale_title: 'Шкала',
    companies_form_constructor_select_field: 'Выбрать поле',
    companies_form_constructor_send_button: 'Кнопка',
    companies_form_constructor_send_button_background_color: 'Цвет фона кнопки',
    companies_form_constructor_send_button_link: 'Ссылка на кнопку',
    companies_form_constructor_send_button_text: 'Тест кнопки',
    companies_form_constructor_send_button_text_color: 'Цвет текста кнопки',
    companies_form_constructor_send_button_title: 'Кнопка отправки',
    companies_form_constructor_send_disabled_button_text_color: 'Цвет текста неактивной кнопки',
    companies_form_constructor_set_default_agreed: 'Вставить значение по умолчанию',
    companies_form_constructor_several_from_list_placeholder: 'Как давно вы посещаете наш заведение?',
    companies_form_constructor_several_from_list_title: 'Несколько со списка',
    companies_form_constructor_show_additional_field: 'Показать дополнительное поле',
    companies_form_constructor_show_name_input_field: 'Показать поле ввода имени',
    companies_form_constructor_show_text_in_two_rows: 'Показать текст в двух строках',
    companies_form_constructor_signature_label: 'Подпись',
    companies_form_constructor_signature_max_label: 'Максимум',
    companies_form_constructor_signature_min_label: 'Минимум',
    companies_form_constructor_start_page_button_width: 'Ширина кнопки начальной страницы',
    companies_form_constructor_status_label: 'Статус',
    companies_form_constructor_step_text: 'Текст шага',
    companies_form_constructor_step_text_color: 'Step text color',
    companies_form_constructor_step_text_placeholder: 'Введите текст шага',
    companies_form_constructor_step_text_underline_color: 'Цвет подчеркивание текста шага',
    companies_form_constructor_step_text_underline_color_placeholder: 'Выбрать цвет подчеркивания текста шага',
    companies_form_constructor_submit_button: 'Сохранить форму',
    companies_form_constructor_tab_diagram: 'Диаграмма',
    companies_form_constructor_tab_field: 'Поле',
    companies_form_constructor_tab_fields: 'Поля',
    companies_form_constructor_tab_settings: 'Настройки',
    companies_form_constructor_text: 'Текст…',
    companies_form_constructor_thank_you_page_field_title: 'Страница благодарности',
    companies_form_constructor_thank_you_page_text: 'Текст благодарения',
    companies_form_constructor_thank_you_page_text_first_line: "Текст страницы 'Спасибо' первая строка",
    companies_form_constructor_thank_you_page_text_second_line: "Текст страницы 'Спасибо' вторая строка",
    companies_form_constructor_thank_you_page_title: 'Название страницы благодарности',
    companies_form_constructor_title: 'Констурктор формы',
    companies_form_constructor_title_additional_field_placeholder: 'Другой',
    companies_form_constructor_title_additional_field_placeholder_title: 'Плейсхолдер дополнительного поля',
    companies_form_constructor_title_placeholder: 'Будем рады, если вы оставите отзыв',
    companies_form_constructor_title_placeholder_title: 'Плейсхолдер',
    companies_form_constructor_title_title: 'Подпись',
    companies_form_constructor_upload_files_label: 'Виберите, какие типы файлов можна будет загрузить в форму отзыва',
    companies_form_constructor_upload_files_placeholder: 'Объяснение, что к форме можно прикреплять файлы',
    companies_form_constructor_upload_files_title: 'Загрузить файл',
    companies_form_download_qr: 'Загрузить QR-код',
    companies_form_scale_constructor_change_background_checkbox_label: 'Изменить фон когда меняется оценка',
    companies_form_scale_constructor_change_background_select_label: 'Цвет фона',
    companies_form_scale_constructor_change_background_select_placeholder: 'Виберите цвет',
    companies_form_scale_constructor_change_mascot_checkbox_label: 'Изменить талисман когда меняется оценка',
    companies_form_scale_constructor_change_mascot_select_label: 'Талисманы',
    companies_form_scale_constructor_change_mascot_select_placeholder: 'Выбрать талисман',
    companies_form_scale_constructor_choose_color_placeholder: 'Выберите цвет',
    companies_form_scale_constructor_file_not_uploaded: 'Файл не загружен',
    companies_form_scale_constructor_main_gradient_colors_label: 'Базовые цвета градиента',
    companies_form_scale_constructor_maximum_value_label: 'Максимальное значение',
    companies_form_scale_constructor_maximum_value_placeholder: 'Введите максимальное значение',
    companies_form_scale_constructor_minimum_value_label: 'Введите значение по умолчанию',
    companies_form_scale_constructor_minimum_value_placeholder: 'Значение по умолчанию',
    companies_form_scale_constructor_numbers_chosen_colors_label: 'Цвет выбранного вопроса',
    companies_form_scale_constructor_numbers_colors_label: 'Цвет номеров',
    companies_form_scale_constructor_pointer_color_label: 'Цвет указателя',
    companies_form_scale_constructor_show_default_value: 'Показать значение по умолчанию',
    companies_form_scale_constructor_signature_text_color_label: 'Цвет текста подписи',
    companies_form_scale_constructor_star_chosen_color_label: 'Цвет выбранной звезды',
    companies_form_scale_constructor_star_not_chosen_color_label: 'Цвет заливки невыбранной звезды',
    companies_form_scale_constructor_star_not_chosen_contour_color_label: 'Цвет контура цвет звезды',
    companies_form_scale_constructor_tab_title: 'Шкала',
    companies_form_scale_constructor_title: 'Констурктор шкалы',
    companies_form_select_color_placeholder: 'Виберите цвет',
    companies_form_show_node_code: 'Показать код узла',
    companies_form_table_add_form_button: 'Добавить форму',
    companies_form_table_constructor_button: 'Конструктор формы',
    companies_form_table_copy_button: 'Копирование формы',
    companies_form_table_copy_confirm_text: 'Вы уверенны, что хотите скопировать форму?',
    companies_form_table_delete_button: 'Удалить форму',
    companies_form_table_delete_confirm_text: 'Ви уверенны, что хотите удалить форму?',
    companies_form_table_edit_button: 'Редактировать',
    companies_form_table_header_feedbacks: 'Отзывы',
    companies_form_table_header_last_edit: 'Последние изменения',
    companies_form_table_header_link: 'Ссылка',
    companies_form_table_header_name: 'Название формы',
    companies_form_table_header_status: 'Статус',
    companies_form_table_new_form_language_placeholder: 'Виберите язык для новой формы',
    companies_form_table_new_form_language_title: 'Язык новой формы',
    companies_form_table_new_form_name_placeholder: 'Введите название новой формы',
    companies_form_table_new_form_name_title: 'Название новой формы',
    companies_form_table_new_node_language_placeholder: 'Вибрать язык узла',
    companies_form_table_new_node_language_title: 'Язык узла',
    companies_form_table_new_node_name_placeholder: 'Введите название нового узла',
    companies_form_table_new_node_name_title: 'Назва нового вузла',
    companies_form_table_node_to_copy: 'Куда вы хотите скопировать форму?',
    companies_form_table_thank_button: 'Страница благодарности',
    companies_form_table_title: 'Формы обратной связи узла компании',
    companies_form_use_as_default_form: 'Использовать форму по умолчанию',
    companies_form_with_steps: 'Пошаговая форма',
    companies_info_add_field_button: 'Добавить поле',
    companies_info_after_save_modal_confirm_button: 'Редактировать данные компании',
    companies_info_after_save_modal_title: 'Компания успешно создана',
    companies_info_choose_file_button: 'Вибрать файл',
    companies_info_company_domain_label: 'Домен компании',
    companies_info_company_domain_placeholder: 'Введите домен компании',
    companies_info_company_extra_field_name_placeholder: 'Введите название поля',
    companies_info_company_extra_field_value_placeholder: 'Введите значение поля',
    companies_info_company_name_label: 'Назва компании',
    companies_info_company_name_placeholder: 'Введите название компании',
    companies_info_confirmation_before_save_modal_title: 'Вы уверены, что хотите редактировать информацию о компании?',
    companies_info_file_max_size: 'JPG, GIF или PNG. Максимальный размер 800К',
    companies_info_file_not_selected: 'Файл не выбрано',
    companies_info_submit_create_button: 'Сохранить компанию',
    companies_info_submit_edit_button: 'Сохранить изменения',
    companies_info_tab_base_info: 'Основная информация',
    companies_info_tab_design: 'Дизайн',
    companies_info_tab_documents: 'Документы',
    companies_info_tab_issues_statuses_and_types: 'Статусы і типы задач',
    companies_info_tab_review_table_columns_settings: 'Настройка столбцов',
    companies_info_tab_sources: 'Источники',
    companies_info_tab_templates: 'Шаблоны',
    companies_info_title_add: 'Добавить компанию',
    companies_info_title_edit: 'Редактировать компанию',
    companies_issue_statuses_and_types_add_status_button: 'Добавить статус',
    companies_issue_statuses_and_types_add_status_placeholder: 'Введите название статуса',
    companies_issue_statuses_and_types_statuses_text: 'Статусы',
    companies_issue_statuses_and_types_type_add_button: 'Добавить тип',
    companies_issue_statuses_and_types_type_add_placeholder: 'Введите название типа',
    companies_issue_statuses_and_types_types_text: 'Типы',
    companies_issues_and_types_confirmation_before_save_modal_title: 'Вы уверены, что хотите редактировать статусы и типы задач?',
    companies_main_add_company_button: 'Добавить компанию',
    companies_main_delete_description: 'Вы уверенны, что хотите удалить компанию?',
    companies_main_delete_title: 'Удалить компанию',
    companies_main_forms_button: 'Форма обратной связи',
    companies_main_structure_button: 'Структура',
    companies_main_table_company_name_header: 'Компания',
    companies_main_title: 'Компании',
    companies_review_table_columns_settings_title: 'Столбцы',
    companies_review_title: 'Отзыв',
    companies_reviews_table_header_button_details: 'Детали',
    companies_reviews_table_header_contact: 'Канал',
    companies_reviews_table_header_contact_type: 'Тип связи',
    companies_reviews_table_header_create: 'Дата создания',
    companies_reviews_table_header_estimate: 'Оценка',
    companies_reviews_table_header_files: 'Прикрепленные файлы',
    companies_reviews_table_header_key_answer: 'Ключевой ответ',
    companies_reviews_table_header_language: 'Язык',
    companies_reviews_table_header_location: 'Локации',
    companies_reviews_table_header_source: 'Источник',
    companies_reviews_table_header_status: 'Статус',
    companies_reviews_table_title: 'Отзывы',
    companies_source_must_be_unique_text: 'должно быть уникальным',
    companies_source_name_label: 'Название источника',
    companies_source_name_max_length_error: 'Название источника должно содержать менее 100 символов',
    companies_sources_add_source_button: 'Добавить источник',
    companies_sources_confirmation_before_save_modal_title: 'Вы уверены, что хотите редактировать источники?',
    companies_sources_create_new_source_title: 'Создать новый источник',
    companies_sources_create_source_text: 'Создать источник',
    companies_sources_placeholder: 'Введите название источника',
    companies_sources_title: 'Источники',
    companies_structure: 'Структура',
    companies_structure_create_accountable: 'Ответственный(ые)',
    companies_structure_create_address_label: 'Добавить адреса',
    companies_structure_create_address_placeholder: 'Введите адреса',
    companies_structure_create_advanced_settings: 'Дополнительные настройки',
    companies_structure_create_edit_title: 'Редактировать узел',
    companies_structure_create_email: 'Почта',
    companies_structure_create_google_place_id_label: 'Идентификатор Google Place',
    companies_structure_create_integration_alpha_name_label: 'Альфа имя',
    companies_structure_create_integration_key_label: 'Ключ',
    companies_structure_create_integration_title: 'Настройка интеграции',
    companies_structure_create_modal_title: 'Вы уверены, что хотите создать узел?',
    companies_structure_create_node_code_label: 'Код узла',
    companies_structure_create_node_code_placeholder: 'Ввести код узла',
    companies_structure_create_node_name_label: 'Назва узла',
    companies_structure_create_node_name_placeholder: 'Ввести название узла',
    companies_structure_create_sms: 'SMS',
    companies_structure_create_telegram: 'Telegram',
    companies_structure_create_tied_form_placeholder: 'Форма',
    companies_structure_create_tied_node_label: 'Связанный узел',
    companies_structure_create_tied_node_placeholder: 'Узел',
    companies_structure_create_title: 'Создать узел',
    companies_structure_create_viber: 'Viber',
    companies_structure_lang_delay: 'Не использовать таймер просроченности',
    companies_structure_lang_detection: 'Использовать автоматическое определение языка формы',
    companies_structure_table_accountable: 'Ответственный',
    companies_structure_table_add_node_button: 'Добавить узел',
    companies_structure_table_delete_description_1: 'Вы уверены, что хотите удалить узел?',
    companies_structure_table_delete_description_2: 'структури компании',
    companies_structure_table_delete_description_3: 'Это удалит все вложенные узлы',
    companies_structure_table_delete_title: 'Удалить узел структуры компании',
    companies_structure_table_header_name: 'Узлы',
    companies_structure_title: 'Структура компании',
    companies_structure_update_modal_title: 'Вы уверены, что хотите сменить узел?',
    company: 'Компания',
    company_main_info_delete_title: 'Удаление компании',
    company_main_info_edit_title: 'Редактировать основную информацию о компании',
    company_main_info_go_to_company_structure: 'Перейти к структуре компании',
    company_structure_delete_button_title: 'Удалить узел',
    company_structure_edit_button_title: 'Редактирование узла',
    confirm_button: 'Подтвердить',
    connect_button: 'Закрыть',
    continue_editing_button: 'Продолжить редактирование',
    continue_review_dialog_button: 'Продолжить диалог',
    copy_button: 'Копировать',
    copy_error: '* Во время копирования произошла ошибка',
    create_button: 'Создать',
    data_save_error: '* При сохранении данных произошла ошибка',
    delete_button: 'Удалить',
    delete_error: '* При удалении произошла ошибка',
    document: 'Документ',
    done_button: 'Готово',
    edit_button: 'Редактировать',
    email: 'Адрес электронной почты',
    email_label: 'Адрес электронной почты',
    email_placeholder: 'Введите свой адрес электронной почты',
    employee_profile_title: 'Профиль сотрудника',
    enter_button: 'Войти',
    enter_forgot_button: 'Возобновить пароль',
    enter_task_name: 'Введите название задач',
    error: '* Произошла ошибка',
    error_text: 'Текст ошибки',
    error_title: 'Ошибка',
    expecting_reply_from_client: 'Ждем ответа клиента',
    filter_title_sources: 'Источники',
    filter_title_status: 'Статусы',
    filters: 'Фильтры',
    finish_review_dialog_button: 'Завершить диалог с клиентом',
    finish_review_dialog_modal_description: 'Вы уверены, что хотите завершить диалог?',
    finish_review_dialog_modal_title: 'Завершить диалог',
    form: 'Форма',
    form_constructor_connection_case_label: 'Вариант',
    form_constructor_connection_case_placeholder: 'Виберите вариант',
    form_constructor_connection_go_to: 'переход до',
    form_constructor_connection_render_minifield_connection_always: 'Всегда',
    form_constructor_connection_render_minifield_connection_type_texts_always: 'Все остальные случаи',
    form_constructor_connection_render_minifield_connection_type_texts_if: 'Если',
    form_constructor_connection_select_field: 'Выберите поле',
    form_constructor_connection_select_with_any_items_label: 'Следующее поле',
    form_constructor_connection_select_with_any_items_options_1_question: 'Не выбрано',
    form_constructor_connection_select_with_any_items_placeholder: 'Выберите поле',
    form_constructor_connection_statement_label: 'Правило',
    form_constructor_connection_statement_placeholder: 'Выберите правило',
    form_constructor_delete_field_modal_description: 'Вы уверены, что хотите удалить это поле?',
    form_constructor_delete_field_modal_title: 'Внимание!',
    form_constructor_diagram_fields_list_is_empty: 'Список полей пуст',
    form_constructor_fields_form_content: 'Содержание',
    form_constructor_fields_form_documents: 'Документы',
    form_constructor_fields_form_no_fields_selected: 'Не выбрано поле',
    form_constructor_fields_form_send_button: 'Кнопка отправки',
    form_constructor_fields_form_thank_you_page: 'Страница благодарности',
    form_copy_save_error: '* Форма под таким названием уже существует в выбранном узле',
    form_language_error: '* Вы уже имеете форму с выбранным языком',
    form_name_error: '* Название формы должно быть уникальным',
    form_save_error: '* При сохранении формы произошла ошибка',
    from: 'От',
    full_name_label: 'Полное имя работника',
    full_name_placeholder: 'Введите полное имя работника',
    generate_button: 'Генерировать',
    go_to_forms_page_button_title: 'Перейти к форме обратной связи',
    go_to_issues_create: 'Перейти к созданию задачи',
    go_to_reviews_page_button_title: 'Перейти к отзывам',
    go_to_task_create: 'Перейти к созданию задачи',
    in_progress: 'В работе',
    issues_add_issue: 'Добавить задачу',
    issues_form_company_label: 'Компания',
    issues_form_company_placeholder: 'Виберите компанию',
    issues_form_connect_issue_label: 'Связанная задача',
    issues_form_connect_issue_placeholder: 'Выберите задачу',
    issues_form_create_new_issue_status: 'Создать новый статус задачи',
    issues_form_create_new_issue_status_title: 'Создать статус',
    issues_form_create_new_issue_type: 'Создать новый тип задачи',
    issues_form_create_new_issue_type_title: 'Создать тип',
    issues_form_must_be_unique: 'Должен быть уникальным',
    issues_form_name_label: 'Название задачи',
    issues_form_name_placeholder: 'Введите название формы',
    issues_form_node_label: 'Узел',
    issues_form_node_placeholder: 'Виберите узел',
    issues_form_priority_label: 'Приоритет задачи',
    issues_form_priority_placeholder: 'Выберите приоритет',
    issues_form_responsible_label: 'Ответственный',
    issues_form_responsible_placeholder: 'Выберите ответственного',
    issues_form_review_label: 'Отзыв',
    issues_form_review_placeholder: 'Виберите отзыв',
    issues_form_status_label: 'Статус задачи',
    issues_form_status_name_label: 'Название статуса задачи',
    issues_form_status_placeholder: 'Виберите статус',
    issues_form_type_label: 'Тип задачи',
    issues_form_type_name_label: 'Название типа задачи',
    issues_form_type_placeholder: 'Виберите тип задачи',
    issues_save_modal_create_title: 'Вы уверены, что хотите создать задачу?',
    issues_save_modal_edit_title: 'Вы уверены что хотите изменить задачу?',
    issues_search_by_name: 'Искать вопрос под названием',
    issues_sub_header_create_issue_title: 'Создать задачу',
    issues_sub_header_create_task_title: 'Создание задачи',
    issues_sub_header_edit_issue_title: 'Редактировать задачу',
    issues_sub_header_title: 'Задачи',
    issues_table_empty: 'Выберите компанию для отображения задачи',
    issues_table_header_company: 'Компания',
    issues_table_header_date: 'Дата создания',
    issues_table_header_name: 'Название задачи',
    issues_table_header_node: 'Узел',
    issues_table_header_priority: 'Приоритет',
    issues_table_header_status: 'Статус',
    issues_table_header_type: 'Тип задачи',
    language: 'Язык',
    languages_add_name_placeholder: 'Введите имя',
    languages_add_short_name_label: 'Короткое имя',
    languages_add_short_name_placeholder: 'Введите короткое имя',
    languages_add_title: 'Добавить язык',
    languages_table_add_language_button: 'Добавить язык',
    languages_table_header_full_name: 'Полное имя',
    languages_table_header_short_name: 'Короткое имя',
    languages_table_modal_delete_buttons_label_end: 'язык?',
    languages_table_modal_delete_buttons_label_start: 'Вы уверены, что хотите удалить',
    languages_table_modal_delete_title: 'Удалить язык',
    languages_table_title: 'Языки',
    leave_the_page: 'оставить страницу',
    loading_error: '* При загрузке данных произошла ошибка',
    login_recovery_auth: 'Вернуться',
    login_recovery_complete: 'Сообщения с инструкциями по сбросу пароля в ближайшее время будут отправлены на ваш электронный адрес',
    mass_mailing_type_by_addresses: 'Почтовый адрес',
    mass_mailing_type_by_company: 'За компанией',
    max_size_error: '* Максимально допустимый размер файла превышен',
    max_size_error_25: '* Максимально допустимый размер файла 25Мтб',
    max_table_column_settings_apply_warning: 'Одновременно может быть 6 активных настроек',
    more_detailed: 'Более детально',
    name: 'Имя',
    new_task_name: 'Новое название задачи',
    next_button: 'Далее',
    no_options_available: 'Нет доступных вариантов',
    node: 'Узел',
    non_submit_description: 'Please return and save changes before closing,',
    non_submit_title: 'You have not saved your changes,',
    not_decided: 'Не решено',
    not_viewed: 'Не просмотрен',
    nothing_find: 'Не найдено',
    login_recovery_password_title: 'Восстановление потерянного пароля',
    open_issue_title: 'Открыть задачу',
    open_language_title: 'Открыть язык',
    open_permission_title: 'Открыть разрешение',
    open_review_title: 'Открыть отзыв',
    open_role_title: 'Открыть роль',
    open_user_title: 'Открыть пользователя',
    other: 'Другое',
    overdue_status_text: 'Прострочено',
    pagination_page_number_placeholder: 'Номер страницы',
    pagination_title: 'Строков на странице',
    password: 'Пароль',
    password_confirmation_label: 'Повторите пароль',
    password_confirmation_placeholder: 'повторно введите пароль',
    password_label: 'Пароль',
    password_placeholder: 'Введите пароль',
    permission_create_title: 'Создать разрешение',
    permission_edit_title: 'Редактировать разрешение',
    permission_name_placeholder: 'Введите имя разрешения',
    permission_name_title: 'Название разрешения',
    permission_resource_placeholder: 'Введите ресурс разрешения',
    permission_resource_title: 'Ресурс',
    permissions_main_add_permission_button: 'Добавить разрешение',
    permissions_main_delete_description: 'Вы уверены, что хотите удалить разрешение?',
    permissions_main_delete_title: 'Удалить разрешение',
    permissions_main_permissions_button: 'Разрешения',
    permissions_main_table_create_header: 'Создать',
    permissions_main_table_delete_header: 'Удалить',
    permissions_main_table_permission_header: 'Название разрешения',
    permissions_main_table_read_header: 'Читать',
    permissions_main_table_resource_header: 'Ресурс',
    permissions_main_table_update_header: 'Обновить',
    permissions_main_title: 'Разрешения',
    phone: 'Телефон',
    phone_number_label: 'Номер телефона',
    phone_number_placeholder: 'Введите номер телефона',
    photo: 'Фото',
    preview_button: 'Предварительный вид',
    privacy_policy: 'Пользовательским соглашением',
    privacy_policy_confirm: 'Я соглашаюсь с',
    record: 'Аудио',
    refresh_all_fitlers: 'Сбросить все фильтры',
    registration_profile_completion: 'Заполнение профиля',
    reopened: 'Открыт снова',
    reset_reviews_filter: 'Сбросить фильтр',
    reset_table_settings: 'Сбросить настройки таблицы',
    review_choose_template: 'Выберите шаблон ответа',
    review_created_at: 'Дата и время публикации',
    review_dialog_finished_text: 'Диалог завершен',
    review_form_name: 'Название формы',
    review_form_source: 'Источник формы',
    review_handle_continue_dialog_error_message: 'Что-то не так. Пожалуйста, попробуйте позже.',
    review_message_input_placeholder: 'Напишите свое сообщение клиенту, или воспользуйтесь готовыми шаблонами, нажав кнопку справа со всеми шаблонами.',
    review_no_asscociated_issues: 'Никаких связанных задач',
    review_no_associated_tasks: 'Нет связанных задач',
    review_node_code: 'Код узла',
    review_node_name: 'Название узла',
    review_on_submit_3_error_message: 'Клиент уже ведет диалог з другим менеджером. Пожалуйста, попробуйте позже.',
    review_open_task_button_text: 'Открыть задачу',
    review_page_type_of_task: 'Тип задачи',
    review_review_id: 'Идентификатор отклика',
    review_save_modal_title: 'Вы уверены что хотите редактировать отзыв?',
    review_updated_at: 'Дата обработки',
    reviews_page_choose_company_title: 'Выберите компанию в раскрывающемся списке',
    reviews_table_comment_column_title: 'Комментарий',
    reviews_table_email_column_title: 'Почта',
    reviews_table_filter_title: 'Фильтр',
    reviews_table_key_question_column_title: 'Key question',
    reviews_table_node_column_title: 'Узел',
    reviews_table_parent_node_column_title: 'Родительский узел',
    reviews_table_phone_column_title: 'Телефон',
    reviews_table_review_id_column_title: 'ID отзыва',
    reviews_table_ukrposhta_settings_button: 'Необработанные отзывы',
    reviews_table_user_name_column_title: 'Имя',
    reviewType: 'Тип отзыва',
    role_create_title: 'Создать роль',
    role_edit_title: 'Редактировать роль',
    role_name_placeholder: 'Введите название роли',
    role_name_title: 'Название роли',
    role_page_create_title: 'Создать роль',
    role_permissions: 'Разрешения',
    role_select_permissions_placeholder: 'Выберите разрешения',
    roles_main_add_role_button: 'Добавить роль',
    roles_main_delete_description: 'Вы уверены, что хотите удалить роль?',
    roles_main_delete_title: 'Удалить роль',
    roles_main_permissions_button: 'Разрешения',
    roles_main_table_user_role_header: 'Название роли',
    roles_main_title: 'Роли',
    roles_part_analytic: 'Аналитика',
    roles_part_companies: 'Компании',
    roles_part_documents: 'Документы',
    roles_part_forms: 'Формы',
    roles_part_languages: 'Языки',
    roles_part_mass_mailings: 'Масовые разсылки',
    roles_part_nodes: 'Узлы',
    roles_part_reviews: 'Отзывы',
    roles_part_users: 'Пользователи',
    roles_sub_permission_create: 'Создать',
    roles_sub_permission_delete: 'Видалити',
    roles_sub_permission_read: 'Читать',
    roles_sub_permission_update: 'Обновить',
    save_button: 'Сохранить',
    save_changes_button: 'Сохранить изменения',
    save_document_button: 'Сохранить',
    save_draft_button: 'Сохранить шаблон',
    save_field_button: 'Сохранить поле',
    save_form_button: 'Сохранить форму',
    save_form_without_changes_button: 'Сохранить форму без изменений',
    search: 'Поиск…',
    search_for_name: 'Поиск по названию',
    search_for_email: 'Поиск по электронному адресу',
    select_company: 'Выбрать компанию',
    select_form: 'Выбрать форму',
    select_language: 'Выберите язык',
    select_multiple_sources: 'Выбрать источники',
    select_multiple_statuses: 'Выбрать статусы',
    select_node: 'Выбрать узел',
    select_status: 'Выбрать статус',
    select_type: 'Вибрати тип',
    send_button: 'Отправить',
    sidebar_analytic: 'Аналитика',
    sidebar_companies: 'Компании',
    sidebar_exit: 'Выйти из профиля',
    sidebar_issues: 'Задачи',
    sidebar_languages: 'Языки',
    sidebar_profile: 'Профиль',
    sidebar_reviews: 'Отзывы',
    sidebar_tasks: 'Задачи',
    sidebar_users: 'Пользователи',
    sms: 'SMS',
    social_networks_module_text: 'Выберите подходящий для вас метод связи с нами',
    solution_not_required: 'Решение не требуется',
    solved: 'Решено',
    source: 'Источник',
    status: 'Статус отзыва',
    status_code: 'Код статуса',
    table_columns_settings_comment_option: 'Комментарий к отзыву',
    table_columns_settings_communication_channel_option: 'Тип связи',
    table_columns_settings_company_option: 'Компания',
    table_columns_settings_created_at_option: 'Дата создания',
    table_columns_settings_email_option: 'Почта',
    table_columns_settings_estimation_option: 'Оценка',
    table_columns_settings_key_question_option: 'Key question',
    table_columns_settings_language_option: 'Язык',
    table_columns_settings_name_option: 'Имя',
    table_columns_settings_node_option: 'Узел',
    table_columns_settings_parent_node_option: 'Родительский узел',
    table_columns_settings_phone_option: 'Телефон',
    table_columns_settings_reviewId_option: 'ID отзыва',
    table_columns_settings_source_option: 'Источник',
    table_columns_settings_status_option: 'Статус',
    table_settings: 'Настройка таблицы',
    task_form_category_label: 'Категория задачи',
    task_form_create_task: 'Создать задачу',
    task_form_list_category_label: 'Категория',
    task_form_list_subcategory_label: 'Подкатегория',
    task_form_list_type_label: 'Тип',
    task_form_name_label: 'Название задачи',
    task_form_priority_label: 'Приоритет задачи',
    task_form_status_label: 'Статус задачи',
    task_form_subcategory_label: 'Подкатегория задачи',
    task_form_type_label: 'Тип задачи',
    telegram: 'Telegram',
    to: 'По',
    to_plug_button: 'Закрыть',
    turn_off_button: 'Выключить',
    upload: 'Загрузить',
    upload_color_button: 'Загрузить цвет',
    uploading_files: 'Загрузка файлов',
    user_email_placeholder: 'Введите адрес электронной почты',
    user_email_title: 'Адрес электронной почты',
    user_name_placeholder: 'Введите имя',
    user_name_title: 'Имя и фамилия',
    user_new_password_placeholder: 'Введите новый пароль',
    user_new_password_title: 'Новый пароль',
    user_old_password_placeholder: 'Введите старый пароль',
    user_old_password_title: 'старый пароль',
    user_page_create: 'Создать пользователя',
    user_page_edit: 'Редактировать пользователя',
    user_password_confirmation_placeholder: 'Повторите пароль',
    user_password_confirmation_title: 'Подтверждение пароля',
    user_password_placeholder: 'Введите пароль',
    user_password_title: 'Пароль',
    user_role_placeholder: 'Выберите роли пользователя',
    user_role_title: 'Роли',
    users_main_add_user_button: 'Добавить пользователя',
    users_main_delete_description: 'Вы уверены, что хотите удалить пользователя?',
    users_main_delete_title: 'Удалить пользователя',
    users_main_roles_button: 'Роли',
    users_main_table_user_company_header: 'Компания',
    users_main_table_user_email_header: 'Адрес электронной почты',
    users_main_table_user_name_header: 'Имя',
    users_main_table_user_notifications_header: 'Уведомления',
    users_main_table_user_role_header: 'Роль',
    users_main_title: 'Пользователи',
    users_main_upload_users_button: 'Скачать пользователей',
    validation_constructor_at_least_2_possible_answers: 'Вопрос должен иметь по крайней мере 2 варианта ответа',
    validation_constructor_should_have_at_least_1_document: 'Форма должна содержать минимум один документ',
    validation_constructor_should_select_file_types: 'Вы должны выбрать типы файлов разрешенные для загрузки или удалить это поле из формы',
    validation_domain: "Доменное имя может иметь только английские символы, цифры, '.' и '-'",
    validation_form_should_have_minimum_1: '* В форме должно быть по меньшей мере 1 поле',
    validation_full_type_required: '* Необходимо выбрать все данные',
    validation_invalid_email: '* Недействительный формат почты',
    validation_invalid_link: '* Недействительный формат ссылки',
    validation_more_one_accountable: '* Вы должны выбрать не менее 1 менеджера для узла',
    validation_more_one_option: '* Вы должны выбрать по меньшей мере 1 вариант',
    validation_must_fill_all_fields_for: '* Вы должны заполнить все поля для',
    validation_must_fill_this_field: '* Вы должны заполнить это поле',
    validation_name_must_be_longer_4: '* Название короткое - оно должно содержать не менее 4 символов',
    validation_required: '* Обязательно',
    viber: 'Viber',
    video: 'Відео',
    viewed: 'Просмотрено',
    your_changes_lose: 'Вам нужно сохранить изменения. В противном случае они будут потеряны.',
    send_feedback: 'Отправить отзыв',
    send_feedback_title: 'Обратная связь',
    send_feedback_label: 'Ваши пожелания или замечания',
    send_feedback_complete: 'Спасибо за Ваш отзыв',
    sidebar_impex: 'Импорт/Экспорт',
    impex_page_title: 'Импорт/Экспорт',
    impex_import_tab: 'Импорт',
    impex_export_tab: 'Экспорт',
    select_import_entity: 'Что вы хотите импортировать?',
    start_import_button_text: 'Начать импорт',
    choose_file_for_import: 'Выберите файл для импорта',
    import_entity_selection_title: 'Импортируем',
    import_file_sheet_name_label: 'Название письма в импортном файле',
    import_file_sheet_name_input_placeholder: 'Введите имя письма в файл для импорта',
    interrupt_import_script_button_text: 'Остановить импорт',
    import_file_format_text: 'Формат файла должен быть:',
    import_progress_bar_title: 'Прогресс выполнения импорта:',
    import_users_entity_name: 'Пользователи',
    import_nodes_entity_name: 'Узлы',
    qr_title: 'Отсканируйте QR для дальнейшей настройки уведомлений',
    mass_mailings_channels_of_connections: 'Каналы коммуникации',
    mass_mailings_send_button: 'Отправить',
    yes_button_text: 'Да',
    no_button_text: 'Нет',
    confirm_send_mass_mailings_modal_title: 'Вы уверены, что хотите сделать массовую отправку сообщений?',
    modal_mass_mailings_sent_success_title: 'Сообщения успешно отправлены',
    ok_button_text: 'Ok',
    mass_mailings_error_text: 'Ошибка отправки сообщений',
    mass_mailings_recipient_label: 'Получатель',
    mass_mailings_recipients_list_title: 'Получательі',
    add_at_least_one_recipient_error: 'Необходимо добавить хотя бы одного получателя',
    sidebar_mass_mailings: 'Массовые рассылки',
    create_mass_mailings_page_title: 'Создание массовой рассылки',
    create_mass_mailings_first_step_subheader_title: 'Создание массовой рассылки - Шаг 1 из 3',
    choose_mass_mailings_communication_channel: 'Выберите канал коммуникации',
    mass_mailings_add_recipient_button_text: 'Добавить получателя',
    download_recipients_file_label: 'Загрузка файла получателей',
    format: 'Формат',
    choose_file: 'Выбрать файл',
    file_data_downloading_text: 'Данные файла загружаются',
    mass_mailings_mails_count: 'Количество сообщений, которое будет отправлено',
    create_sms_mass_mailings_second_step_subheader_title: 'Создание SMS рассылки - Шаг 2 из 3',
    create_viber_mass_mailings_second_step_subheader_title: 'Создание Вайбер рассылки - Шаг 2 из 3',
    create_sms_mass_mailings_third_step_subheader_title: 'Создание SMS рассылки - Шаг 3 из 3',
    create_viber_mass_mailings_third_step_subheader_title: 'Создание Вайбер рассылки - Шаг 3 из 3',
    mass_mailings_sender_account: 'Аккаунт отправитель',
    sms_text: 'Текст SMS',
    message: 'Сообщение',
    message_text_placeholder: 'Текст сообщения',
    responsible: 'Ответственный',
    responsible_id: 'ID ответственного',
    companies_form_constructor_dividing_decorative_line: 'Настройка декоративной линии формы',
    companies_form_constructor_show_form_dividing_decorative_line: 'Показать раздельную декоративную линию формы',
    companies_form_constructor_dividing_decorative_line_width_in_percent: 'Длина декоративной линии в процентах',
    companies_form_constructor_dividing_decorative_line_height: 'Высота декоративной линии в пикселях',
    companies_form_constructor_dividing_decorative_line_height_placeholder: 'Укажите высоту декоративной линии',
    companies_form_constructor_dividing_decorative_line_color: 'Цвет декоративной линии',
    companies_form_constructor_dividing_decorative_line_color_placeholder: 'Выберите цвет декоративной линии',
    export_entity_selection_title: 'Экспортируем',
    select_export_entity: 'Что вы хотите экспортировать?',
    export_button_text: 'Экспортировать',
    export_nodes_error_text: 'Ошибка экспорта узлов',
    export_users_error_text: 'Ошибка экспорта пользователей',
    chat_tab1: 'Текущий отзыв',
    chat_tab2: 'Полная история чата',
    quarter: 'Прошли 3 месяца',
    months: 'Прошедший месяц',
    days7: 'Прошлые 7 дней',
    telegram_network: 'Telegram',
    viber_network: 'Viber',
    phone_network: 'Телефон',
    email_network: 'Электронный адрес',
    whatsapp_network: 'WhatsApp',
  },
};

export function getTranslationByLangOrEng(lang: string, key: string) {
  return translations[lang][key] ? translations[lang][key] : translations.EN[key];
}
