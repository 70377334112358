import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ReviewsEyeIcon: FC<TIcon> = React.memo(({ width = 22, height = 22, color = '#979797' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <path
      display="inline"
      fill="#FFFFFF"
      d="M328.3,461.4c-11.1-11.1-11.1-29.2,0-40.3l41.7-41.2l-110.4,0c-16,0-28.9-13-28.9-28.9v-1.9
  c0-16,12.9-28.9,28.9-28.9h110l-41.3-40.8c-11.1-11.1-11.1-29.2,0-40.3c11.3-11.1,29.5-11.1,40.7,0l91.9,91.1
  c11.1,11.1,11.1,29.2,0,40.3l-91.9,91C357.8,472.5,339.6,472.5,328.3,461.4L328.3,461.4z"
    />
    <path
      fill="#199085"
      d="M350,172.3C173.1,172.3,61.6,350,61.6,350S173.1,527.7,350,527.7S638.4,350,638.4,350S526.9,172.3,350,172.3
  L350,172.3z M297.7,261.1c20,0,35.7,16.6,35.7,36.6s-16.6,36.6-35.7,36.6c-19.2,0-35.7-16.6-35.7-36.6S277.7,261.1,297.7,261.1z
  M350,481.6C219.3,481.6,121.7,350,121.7,350s48.8-65.3,123.7-103.7c-14.8,21.8-23.5,47.1-23.5,75.8c0,74.1,59.2,133.3,132.4,133.3
  s132.4-60.1,132.4-133.3c0-25.3-7-48.8-19.2-68.8c68,39.2,111.5,96.7,111.5,96.7S480.7,481.6,350,481.6L350,481.6z"
    />
  </svg>
));
