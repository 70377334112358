import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import * as Yup from 'yup';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getTranslationByLangOrEng } from '../../i18n';
import { useAppDispatch, useAppSelector, setInterfaceLanguage } from '../../state';
import { ResetPasswordStyles } from './ResetPasswordStyles';
import { getInterfaceLanguage, getErrorMessage, handleKeyUp } from '../../utils';
import InputField from '../../components/form/inputField/InputField';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { Api } from '../../api';

interface FormValues {
    password: string;
    passwordConfirmation: string;
}

const initialValues: FormValues = {
  password: '',
  passwordConfirmation: '',
};

export const ResetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const code = queryParams.get('code');

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [complete, setComplete] = useState<boolean>(false);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    if (!code) {
      navigate('/auth/login')
    }
    dispatch(setInterfaceLanguage(getInterfaceLanguage() || 'EN'));
  }, []);

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setIsLoading(true)
    if (code) {
      Api.passwordReset(values, code).then((res) => {
        if (res.statusCode === 200) {
          setIsLoading(false)
          setComplete(true)

          setTimeout(() => {
            setComplete(false)
            navigate('/auth/login')
          }, 5000)
        }
      }).catch((e) => {
        setErrorMessage(e)
        setIsLoading(false)
      })
    }
    setSubmitting(false)
  }

  const validationSchema = Yup.object({
    password: Yup.string()
      .min(6, 'Min length 6 chard')
      .required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    passwordConfirmation: Yup.string()
      // eslint-disable-next-line react/no-this-in-sfc
      .test('check-password-equals', 'Passwords not equal', function (value) { return value === this.parent.password; }),
  })

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <InputField
        name="password"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('password', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_new_password_placeholder')}
        value={values.password}
        error={typeof errorMessage === 'object' ? getErrorMessage('password', errorMessage) : undefined}
        label={getTranslationByLangOrEng(interfaceLanguage, 'user_new_password_title')}
        type="password"
      />

      <InputField
        name="passwordConfirmation"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('passwordConfirmation', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_placeholder')}
        value={values.passwordConfirmation}
        error={typeof errorMessage === 'object' ? getErrorMessage('passwordConfirmation', errorMessage) : undefined}
        label={getTranslationByLangOrEng(interfaceLanguage, 'user_password_confirmation_title')}
        type="password"
      />

      <SubmitButton
        isLoading={isLoading}
        isError={touched && Object.keys(errors).length > 0}
      >
        {getTranslationByLangOrEng(interfaceLanguage, 'save_button')}
      </SubmitButton>

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      {complete && (<p className="passwordChanged">Password changed successfully</p>)}
    </Form>
  );

  return (
    <ResetPasswordStyles>
      <Helmet>
        <title>Reset Password</title>
      </Helmet>

      <div className="formContainer">

        <div className="titleContainer">
          <h1>{getTranslationByLangOrEng(interfaceLanguage, 'reset_pass')}</h1>
        </div>

        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>

        <div className="linkContainer">
          <Link to="/auth/login" className="form__link">{getTranslationByLangOrEng(interfaceLanguage, 'login_recovery_auth')}</Link>
        </div>
      </div>

    </ResetPasswordStyles>
  )
}
