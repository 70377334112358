import React, { Dispatch, SetStateAction } from 'react';
import { InfoCharIcon } from '../../../assets';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { StepTextSettingsStyles } from './StepTextSettingsStyles';

interface StepTextSettingsProps {
  stepsText: string,
  setFieldValue: (key: string, value: any) => void,
  handleKeyUp: (key: string, setErrorHandler: Dispatch<SetStateAction<any>>, error: any) => void,
  setErrorMessage: Dispatch<SetStateAction<any>>,
  errorMessage: any,
}

export default function StepTextSettings({
  stepsText,
  handleKeyUp,
  setFieldValue,
  setErrorMessage,
  errorMessage,
}: StepTextSettingsProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <StepTextSettingsStyles>
      <div className="stepTextContainer">
        <div className="inputRaw">
          <div className="nameContainer">
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_step_text')}</span>
          </div>
          <div className="inputElementContainer">
            <input
              id="stepsText"
              type="text"
              value={stepsText}
              onChange={(event) => setFieldValue('stepsText', event.target.value)}
              onKeyUp={() => handleKeyUp('stepsText', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_step_text_placeholder')}
            />
            <div className="infoContainer">
              <div className="infoIcon">
                <InfoCharIcon />
              </div>
              <div className="infoText">
                <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_step_text')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StepTextSettingsStyles>
  )
}
