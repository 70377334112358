import React, { CSSProperties, useEffect, useState } from 'react';
import { getBezierPath, Position } from 'reactflow';
import { useAppSelector } from '../../../../state';

interface CustomDiagramEdgeProps {
  id: string,
  source: string,
  target: string,
  sourceX: number,
  sourceY: number,
  targetX: number,
  targetY: number,
  sourcePosition: Position | undefined,
  targetPosition: Position | undefined,
  style?: CSSProperties | undefined,
  data?: any,
  markerEnd?: any,
}

export default function CustomDiagramEdge({
  id,
  source,
  target,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}: CustomDiagramEdgeProps) {
  const { notSavedExtraFields, notSavedDocuments } = useAppSelector((state) => state.notSavedForm);

  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    setRerender((prev) => !prev);
  }, [notSavedExtraFields, notSavedDocuments])

  function checkIsInSeries() {
    const sourceField = !![...notSavedExtraFields, ...notSavedDocuments].find((item) => item.id === +source)?.fieldInSeries;
    const targetField = !![...notSavedExtraFields, ...notSavedDocuments].find((item) => item.id === +target)?.fieldInSeries;

    return sourceField && targetField;
  }

  const [path] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  return (
    <path
      id={id}
      style={checkIsInSeries() ? { stroke: '#008aff' } : {}}
      className="react-flow__edge-path"
      d={path}
      markerEnd={markerEnd}
    />
  );
}
