import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const MicrophoneIcon: FC<TIcon> = React.memo(({ width = 384, height = 512, color = '#000' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 384 512"
  >
    <path fill={color} d="M192 352c53.03 0 96-42.97 96-96h-80c-8.8 0-16-7.2-16-16s7.2-16 16-16h80v-32h-80c-8.8 0-16-7.2-16-16s7.2-16 16-16h80v-32.9h-80c-8.836 0-16-7.164-16-16s7.164-16 16-16l80 .9c0-53.03-42.97-96-96-96S96 42.97 96 96v160c0 53 42.1 96 96 96zm152-160c-13.3 0-24 10.7-24 23.1V256c0 73.33-61.97 132.4-136.3 127.7C117.62 379.531 64 317.11 64 250.9v-35.8c0-12.4-10.75-23.1-24-23.1s-24 10.7-24 23.1v32.15c0 89.66 63.97 169.6 152 181.7V464h-40c-18.19 0-32.84 15.18-31.96 33.57C96.43 505.8 103.8 512 112 512h160c8.222 0 15.57-6.216 15.96-14.43C288.8 479.2 274.2 464 256 464h-40v-33.77C301.7 418.5 368 344.9 368 256v-40.9c0-12.4-10.7-23.1-24-23.1z" />
  </svg>
));
