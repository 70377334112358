import styled from 'styled-components';

export const FeedbackModalStyles = styled.div<{active: boolean}>`
  position: absolute; 
  right: 0;
  top: 56px;
  width: 30%;
  height: calc(100vh - 56px);
  background: #fff;
  padding: 0 10px;
  border-left: 1px solid #F0F0F0;
  box-shadow: 0 4px 4px rgba(0,0,0,0.04);
  transition: all 0.3s;
  transform: ${({ active }) => (active ? 'translateX(0%)' : 'translateX(100%)')};

    @media screen and (max-width: 768px) {
      width: 70%;
    }

    .completeMessage {
      width: 100%;
      text-align: center;
    }
    
    .feedback {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      &__title {
        width: 100%;
        padding: 15px 0;
        background: #f0f5f5;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        text-align: center;
        color: #000000;
        border: 1px solid #f0f5f5;
        border-radius: 4px 4px 0px 0px;
      }

      &__form {
        padding: 10px 0;
        width: 100%;
        background: #fff;
      }
    }

    .extraErrorMessage {
        font-size: 11px;
        line-height: 16px;
        color: #ff3333;
    }
`;
