import React, { FC, useRef, useState } from 'react'
import { ArrowDownIcon, CheckMark, SearchIcon } from '../../../assets';
import { IUserRole } from '../../../entities';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { RoleSelectStyles } from './RoleSelectStyles';

type TRoleSelect = {
    label?: string;
    name: string;
    required?: boolean;
    roles: IUserRole[]
    value: any;
    placeholder: string;
    search?: boolean;
    userRoles: number[];
    handleSelect: (role: IUserRole) => void;
    error?: string | undefined;
}

export const RoleSelect: FC<TRoleSelect> = React.memo(({
  label, name, required, roles, value, placeholder, search, userRoles, handleSelect, error,
}) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState(false);
  const [searchParams, setSearchParams] = useState<string>('');

  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);

  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);

  return (
    <RoleSelectStyles>
      {label && (
        <div className="labelContainer">
          <label htmlFor={name}>
            <span>
              {label}
              {required && <span className="required"> *</span>}
            </span>
          </label>
        </div>
      )}
      <div className="selectField">
        <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
          <span className="placeholder">{placeholder}</span>
          <div className={open ? 'arrowDownActive' : ''}>
            <ArrowDownIcon />
          </div>
        </div>

        {open && (
          <div
            className="options"
            ref={optionsListRef}
          >
              {search && (
                <div className="searchWrapper">
                  <SearchIcon />
                  <input
                    type="text"
                    value={searchParams}
                    onChange={(e) => setSearchParams(e.target.value)}
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
                  />
                </div>
              )}
              {roles && roles.filter((role) => role.name.toLowerCase().includes(searchParams.toLocaleLowerCase())).map((role) => (
                <div
                  className="option"
                  key={`selectOption:${role.id}`}
                >
                  <div className="rowSel">
                    <button
                      type="button"
                      onClick={() => {
                        handleSelect(role);
                      }}
                    >
                      <div className="labelContent">
                        <div className="default">
                          {userRoles.includes(role.id!) && <CheckMark />}
                        </div>
                        <span>{role.name}</span>
                      </div>
                    </button>
                  </div>
                </div>
              ))}
          </div>
        )}
      </div>
      <div className="formErrorContainer">
        {error && <span>{error}</span>}
      </div>
    </RoleSelectStyles>
  )
});
