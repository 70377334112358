import styled from 'styled-components';

export const ModalStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: grid;
  place-items: center;
  padding: 20px;
  box-sizing: border-box;
`;
