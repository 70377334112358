import FieldsSelect from '../fieldsSelect/FieldsSelect';
import { getDocumentTemplate, getFieldTemplatesList } from '../constructorFormFields';
import { SimplePlusIcon } from '../../../../assets';
import { Api } from '../../../../api';
import { IExtraField, IFormDocument, ICompanyDocument } from '../../../../entities';
import React, { forwardRef, useState } from 'react';
import {
  addNotSavedDocument,
  addNotSavedExtraField,
  useAppDispatch, useAppSelector,
  deleteNotSavedDocument,
  deleteNotSavedExtraField,
  updateNotSavedExtraField,
} from '../../../../state';
import SimpleField from '../../../../components/constuctor/simpleField/SimpleField';
import CheckboxField from '../../../../components/constuctor/checkboxField/CheckboxField';
import RadioField from '../../../../components/constuctor/radioField/RadioField';
import ScaleField from '../../../../components/constuctor/scaleField/ScaleField';
import FileUploadPermissionModule from '../../../../components/constuctor/fileUploadPermissionModule/FileUploadPermissionModule';
import SocialNetworksModule from '../../../../components/constuctor/socialNetworksModule/SocialNetworksModule';
import FormConstructorDocument from '../../../../components/constuctor/formConstructorDocument/FormConstructorDocument';
import SendButtonField from '../../../../components/constuctor/sendButtonField/SendButtonField';
import ThankYouPageField from '../../../../components/constuctor/thankYouPageField/ThankYouPageField';
import DraggableConstructorFormContent from './DraggableConstructorFormContent';
import DraggableConstructorFormDocuments from './DraggableConstructorFormDocuments';
import { useParams } from 'react-router-dom';
import FormConstructorItem from '../formConstructorItem/FormConstructorItem';
import { getTranslationByLangOrEng } from '../../../../i18n';
import QuantityField from '../../../../components/constuctor/quantityField/QuantityField';
import FieldsSwitcher from '../../../../components/constuctor/fieldsSwitcher/FieldsSwitcher';
import { DeleteModal } from '../../../../components';

interface FieldsFormProps {
  showAddFieldList: boolean;
  setShowAddFieldList: (value: boolean) => void;
  selectedFieldIndex: number | undefined;
  setSelectedFieldIndex: (value: number | undefined) => void;
  showContentList: boolean;
  setDocumentIds: (value: number[]) => void;
  companyDocuments: ICompanyDocument[],
  companyImages: { name: string, url: string }[],
  showFormSection: string | undefined,
  setShowFormSection: (value: string | undefined) => void,
  // handleOpenOnDiagram: (fieldSectionType: 'content' | 'documents', index: number) => void,
}

const FieldsForm = forwardRef<any, FieldsFormProps>(({
  showAddFieldList,
  setShowAddFieldList,
  selectedFieldIndex,
  setSelectedFieldIndex,
  showContentList,
  setDocumentIds,
  companyDocuments,
  companyImages,
  showFormSection,
  setShowFormSection,
  // handleOpenOnDiagram,
}, ref) => {
  const { formId } = useParams();

  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const {
    notSavedForm,
    notSavedExtraFields,
    notSavedDocuments,
    notSavedSendButton,
  } = useAppSelector((state) => state.notSavedForm);
  const { selectedNode: node } = useAppSelector((state) => state.company);

  const [selectedSectionType, setSelectedSectionType] = useState<'content' | 'documents' | 'sendButton' | 'thankYouPage'>();
  const [fieldCreating, setFieldCreating] = useState<boolean>(false);
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState<boolean>(false);
  const [fieldToDeleteSectionType, setFieldToDeleteSectionType] = useState<'content' | 'documents'>();
  const [fieldToDeleteIndex, setFieldToDeleteIndex] = useState<number>();

  async function handleAddField(config: IExtraField) {
    const fieldsListLength = notSavedExtraFields.length;

    if (notSavedExtraFields) {
      try {
        const res = await Api.createField({ ...config, position: `${fieldsListLength + 1}` });

        if (res.statusCode >= 200 && res.statusCode < 300) {
          const lastExtraField = fieldsListLength - 1 >= 0 ? { ...notSavedExtraFields[fieldsListLength - 1] } : undefined;
          if (lastExtraField) {
            lastExtraField.fieldID = res.data.id;
            dispatch(updateNotSavedExtraField(lastExtraField));
          }

          dispatch(addNotSavedExtraField(res.data));
        }

        setShowAddFieldList(false);
        setSelectedFieldIndex(fieldsListLength);
        setSelectedSectionType('content');
      } catch (e) {
        console.error('handleAddField Error:', e);
      }
    }
  }

  async function handleAddDocument(event: any) {
    event.stopPropagation();
    event.preventDefault();

    const fieldsListLength = notSavedExtraFields.length;
    const documentsListLength = notSavedDocuments.length;

    if (notSavedDocuments && notSavedExtraFields) {
      setFieldCreating(true);
      const res = await Api.createFormDocument({ ...getDocumentTemplate(interfaceLanguage, +formId!, `${documentsListLength + 1}`).config });

      if (res.statusCode >= 200 && res.statusCode < 300) {
        const lastExtraField = fieldsListLength - 1 >= 0 ? { ...notSavedExtraFields[fieldsListLength - 1] } : undefined;

        if (lastExtraField) {
          lastExtraField.fieldID = res.data.id;
          dispatch(updateNotSavedExtraField(lastExtraField));
        }

        dispatch(addNotSavedDocument(res.data));
      }

      setFieldCreating(false);
      setShowAddFieldList(false);
      setSelectedSectionType('documents');
      setSelectedFieldIndex(documentsListLength);
    }
  }

  // Remove field modal functions START
  function handleRemoveField(index: number, sectionType: 'content' | 'documents') {
    setShowDeleteFieldModal(true);
    setFieldToDeleteIndex(index);
    setFieldToDeleteSectionType(sectionType);
  }

  function closeRemoveFieldModal() {
    setShowDeleteFieldModal(false);
    setFieldToDeleteIndex(undefined);
    setFieldToDeleteSectionType(undefined);
  }

  function getPreviewText() {
    if (fieldToDeleteSectionType === 'content') {
      return notSavedExtraFields[fieldToDeleteIndex!].question;
    }

    return notSavedDocuments[fieldToDeleteIndex!].description;
  }

  async function removeField() {
    closeRemoveFieldModal();
    setSelectedFieldIndex(undefined);
    setSelectedSectionType(undefined);

    if (fieldToDeleteSectionType === 'content') {
      const res = await Api.deleteField(notSavedExtraFields[fieldToDeleteIndex!].id!);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        dispatch(deleteNotSavedExtraField(notSavedExtraFields[fieldToDeleteIndex!].id!));
      }
    } else {
      const res = await Api.deleteField(notSavedDocuments[fieldToDeleteIndex!].id!);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        dispatch(deleteNotSavedDocument(notSavedDocuments[fieldToDeleteIndex!].id!));
      }
    }
  }
  // Remove field modal functions END

  function getField(field: { type: any; }, index: number) {
    const fields = notSavedExtraFields ? [...notSavedExtraFields] : undefined;

    function handleChangeKeyQuestion() {
      if (fields) {
        fields.forEach((field: IExtraField, fieldIndex: number) => {
          if (fieldIndex !== index) {
            if (field.keyQuestion === true) {
              dispatch(updateNotSavedExtraField({ ...fields[fieldIndex], keyQuestion: false }));
            }
          } else {
            dispatch(updateNotSavedExtraField({ ...fields[fieldIndex], keyQuestion: !fields[fieldIndex].keyQuestion }));
          }
        });
      }
    }

    function handleChangeMainScale() {
      if (fields) {
        fields.forEach((field: IExtraField, fieldIndex: number) => {
          if (fieldIndex !== index) {
            if (field.mainScale === true) {
              dispatch(updateNotSavedExtraField({ ...fields[fieldIndex], mainScale: false }));
            }
          } else {
            dispatch(updateNotSavedExtraField({ ...fields[fieldIndex], mainScale: !fields[fieldIndex].mainScale }));
          }
        });
      }
    }

    switch (field?.type) {
      case 'title':
      case 'name':
      case 'email':
      case 'phone_number':
      case 'extra_question':
        return (
          <SimpleField
            index={index}
            squareCheckboxOnChange={handleChangeKeyQuestion}
            squareCheckboxExtraBlockStyles={{ marginBottom: 24 }}
            handleRemove={() => handleRemoveField(index, 'content')}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
      case 'checkbox':
        return (
          <CheckboxField
            index={index}
            squareCheckboxOnChange={handleChangeKeyQuestion}
            squareCheckboxExtraBlockStyles={{ marginBottom: 24 }}
            handleRemove={() => handleRemoveField(index, 'content')}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
      case 'radio':
        return (
          <RadioField
            index={index}
            squareCheckboxOnChange={handleChangeKeyQuestion}
            squareCheckboxExtraBlockStyles={{ marginBottom: 24 }}
            notFullClickable
            handleRemove={() => handleRemoveField(index, 'content')}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
      case 'scale':
        return (
          <ScaleField
            index={index}
            keyQuestionCheckboxOnChange={handleChangeKeyQuestion}
            keyQuestionCheckboxExtraBlockStyles={{ marginBottom: 24 }}
            mainScaleCheckboxOnChange={handleChangeMainScale}
            mainScaleCheckboxExtraBlockStyles={{ marginBottom: 24 }}
            handleRemove={() => handleRemoveField(index, 'content')}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
      case 'upload_files':
        return (
          <FileUploadPermissionModule
            index={index}
            handleRemove={() => handleRemoveField(index, 'content')}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
      case 'networks':
        return (
          <SocialNetworksModule
            index={index}
            handleRemove={() => handleRemoveField(index, 'content')}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
      case 'quantity':
        return (
          <QuantityField
            index={index}
            handleRemove={() => handleRemoveField(index, 'content')}
            squareCheckboxExtraBlockStyles={{ marginBottom: 24 }}
            // handleOpenOnDiagram={(index) => handleOpenOnDiagram(selectedSectionType as 'content' | 'documents', index)}
          />
        );
    }
  }

  function getDocument(index: number) {
    const documents = notSavedDocuments ? [...notSavedDocuments] : undefined;

    if (documents) {
      return (
        <FormConstructorDocument
          index={index}
          options={companyDocuments ? companyDocuments.filter((document) => !(documents!.map((doc: IFormDocument) => doc.id).includes(document.id))) : []}
          showInfo={false}
          handleRemove={() => handleRemoveField(index, 'documents')}
          // extraBlockStyles={(index === 0 || errors.documents?.[index]) ? { marginBottom: 0 } : { marginBottom: 0, marginTop: 24 }}
        />
      );
    }
  }

  function getSendButton() {
    const form = notSavedForm ? { ...notSavedForm } : undefined;

    if (form) {
      return (
        <SendButtonField />
      );
    }
  }

  function getThankYouPage() {
    const form = notSavedForm ? { ...notSavedForm } : undefined;

    if (form) {
      return (
        <ThankYouPageField companyImages={companyImages || []} />
      );
    }
  }

  function handleContentItemClick(type: 'content' | 'documents', index?: number) {
    setShowAddFieldList(false);
    if (index !== undefined) {
      setSelectedFieldIndex(index);
    }
    setSelectedSectionType(type);
  }

  function handleSecondaryItemClick(type: 'sendButton' | 'thankYouPage') {
    setShowFormSection(showFormSection !== type ? type : undefined);
    setSelectedFieldIndex(undefined);
    setSelectedSectionType(type);
  }

  return (
    <div>
      <div className="fieldsCreation">
        {notSavedForm && (
        <div className="selectedField">
          {showAddFieldList ? (
            <FieldsSelect
              items={getFieldTemplatesList(interfaceLanguage, +formId!, `${notSavedExtraFields.length}`, node?.address?.value)}
              handleAddField={handleAddField}
              handleClose={() => setShowAddFieldList(false)}
            />
          ) : (
            <>
              {(selectedSectionType && ['content', 'documents'].includes(selectedSectionType) && selectedFieldIndex !== undefined)
                && (
                  <FieldsSwitcher
                    selectedSectionType={selectedSectionType}
                    selectedFieldIndex={selectedFieldIndex}
                    handleContentItemClick={handleContentItemClick}
                  />
                )}
              {(selectedSectionType === 'content' && selectedFieldIndex !== undefined) && getField(notSavedExtraFields![selectedFieldIndex], selectedFieldIndex)}
              {(selectedSectionType === 'documents' && selectedFieldIndex !== undefined) && getDocument(selectedFieldIndex)}
              {(selectedSectionType === 'sendButton') && notSavedSendButton && getSendButton()}
              {(selectedSectionType === 'thankYouPage') && getThankYouPage()}
              {!selectedSectionType && <span className="noSelectedField">{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_no_fields_selected')}</span>}
            </>
          )}
        </div>
        )}

        {/* selectedTab === 'field' */}
        {showContentList && (
        <div className="formContentWrapper">
          <div className="extraFieldListContainer">
            <div
              className="formSectionTitle"
              onClick={() => setShowFormSection(showFormSection !== 'content' ? 'content' : undefined)}
            >
              <h4>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_content')}</h4>

              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  event.preventDefault();

                  setSelectedSectionType(undefined);
                  setShowAddFieldList(true);
                }}
              >
                <SimplePlusIcon width={16} height={16} color="#0E9285" />
              </button>
            </div>

            {showFormSection === 'content' && (
              <DraggableConstructorFormContent
                handleContentItemClick={handleContentItemClick}
                selectedFieldIndex={selectedFieldIndex}
                setSelectedFieldIndex={setSelectedFieldIndex}
              />
            )}

            <div
              className="formSectionTitle"
              onClick={() => setShowFormSection(showFormSection !== 'documents' ? 'documents' : undefined)}
            >
              <h4>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_documents')}</h4>

              {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
              <button type="button" onClick={handleAddDocument}>
                <SimplePlusIcon width={16} height={16} color="#0E9285" />
              </button>
            </div>

            {showFormSection === 'documents' && (
              <DraggableConstructorFormDocuments
                handleContentItemClick={handleContentItemClick}
                selectedFieldIndex={selectedFieldIndex}
                setSelectedFieldIndex={setSelectedFieldIndex}
              />
            )}

            <div className="formSectionTitle" onClick={() => handleSecondaryItemClick('sendButton')}>
              <h4>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_send_button')}</h4>
            </div>

            <div className="formSectionTitle" onClick={() => handleSecondaryItemClick('thankYouPage')}>
              <h4>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_thank_you_page')}</h4>
            </div>
          </div>
        </div>
        )}
      </div>

      {showDeleteFieldModal && fieldToDeleteIndex !== undefined && fieldToDeleteSectionType && (
        <DeleteModal
          onConfirm={removeField}
          onDecline={closeRemoveFieldModal}
          onClose={closeRemoveFieldModal}
          title={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_delete_field_modal_title')}
          description={getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_delete_field_modal_description')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          confirmText={getTranslationByLangOrEng(interfaceLanguage, 'confirm_button')}
        >
          <div className="targetDeleteFieldContainer">
            <div className="targetDeleteFieldWrapper">
              <FormConstructorItem
                type={(fieldToDeleteSectionType === 'content' ? notSavedExtraFields : notSavedDocuments)[fieldToDeleteIndex].type}
                previewText={getPreviewText() || ''}
              />
            </div>
          </div>
        </DeleteModal>
      )}
    </div>
  );
});

export default FieldsForm;
