import styled from 'styled-components';

export const FileUploadPermissionModuleStyles = styled.footer`
  .defaultFilePicker {
    display: none;
  }
  
  .options {
    display: flex;
    justify-content: space-around;
  }
  
  .checkboxFieldContainer {    
    button {
      width: 44px;
      height: 44px;
      border-radius: 22px;
      background-color: #F2F2F2;
      display: grid;
      place-items: center;
      
      svg {
        width: 20px;
        height: 20px;
      }
    }
  }

  .answers {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;

    .fileItem {
      display: grid;
      grid-template-columns: 72px 1fr 32px;
      grid-gap: 16px;
      align-items: center;
      background-color: #f9f9f9;
      height: 48px;
      padding: 0 16px;
      border-radius: 4px;

      span {
        text-overflow: ellipsis;
        font-size: 13px;
        color: #00729f;
      }

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
