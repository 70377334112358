import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const EyeIcon: FC<TIcon> = React.memo(({ fill = '#0E9285' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="16"
    fill="none"
    viewBox="0 0 17 16"
  >
    <g
      stroke={fill}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      clipPath="url(#clip0_1670_9079)"
    >
      <path d="M1.167 7.997S3.833 2.664 8.5 2.664s7.333 5.333 7.333 5.333-2.666 5.334-7.333 5.334-7.333-5.334-7.333-5.334z" />
      <path d="M8.5 10a2 2 0 100-4 2 2 0 000 4z" />
    </g>
    <defs>
      <clipPath id="clip0_1670_9079">
        <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.5)" />
      </clipPath>
    </defs>
  </svg>
));
