import styled from 'styled-components';

export const FieldSaveButtonStyles = styled.div`
  padding: 0 16px;
  box-sizing: border-box;


  .messageContainer {
    height: 24px;
    display: flex;
    align-items: center;
    
    .formErrorContainer,
    .formSuccessContainer {
      font-size: 11px;
      line-height: 16px;
    }

    .formErrorContainer {
      color: #ff3333;
    }

    .formSuccessContainer {
      color: #0E9285;
    }
  }
`;
