import { RadioFieldStyles } from './RadioFieldStyles';
import React, { CSSProperties, useState } from 'react';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import RadioButton from '../../form/radioButton/RadioButton';
import SimpleField from '../simpleField/SimpleField';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { FormErrorMessage } from '../../atoms';

interface RadioFieldProps {
  index: number;
  values: any;

  optionsName: string;
  optionsError: string | undefined;
  extraBlockStyles?: CSSProperties;
  disabled?: boolean;

  question: string;
  required: boolean;

  handleChangeOptionsState?: (key: string, value: string) => void;
  handleChangeChoiceDescription: (key: string, value: string) => void;
}

export default function RadioField({
  index,
  values,

  optionsName,
  optionsError,
  extraBlockStyles,
  disabled,

  question,
  required,

  handleChangeOptionsState,
  handleChangeChoiceDescription,
}: RadioFieldProps) {
  const { touched } = useFormikContext();

  const [errorMessage, setErrorMessage] = useState<any>({});

  return (
    <RadioFieldStyles style={extraBlockStyles}>
      <div className="titleContainer">
        {required && <span>*</span>}
        <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question}`}</h3>
      </div>

      <FieldArray
        name={`extraFields[${index}].options`}
        render={() => (
          <div className="options">
            {values.extraFields[index].options.map((field: any, subIndex: number) => {
              const descriptionIndex = values.extraFields[index].answerDescriptionFor?.indexOf(field.value);

              return (
                // eslint-disable-next-line react/no-array-index-key
                <div className="radioFieldContainer" key={subIndex}>
                  <RadioButton
                    name={`extraFields[${index}].selectedOption`}
                    value={values.extraFields[index].options[subIndex].value}
                    onChange={handleChangeOptionsState
                      ? (event) => handleChangeOptionsState(`extraFields[${index}].selectedOption`, event.target.value)
                      : () => null}
                    checked={values.extraFields[index].selectedOption === values.extraFields[index].options[subIndex].value}
                  >
                    <div className="radioText">
                      {field.value}
                    </div>
                  </RadioButton>

                  {values.extraFields[index].selectedOption === values.extraFields[index].options[subIndex].value
                    && values.extraFields[index].options[subIndex].showChoiceDescription && (
                      <div className="choiceDescription">
                        {!!values.extraFields[index].options[subIndex].choiceDescriptionTitle && (
                          <div className="answerTitleContainer">
                            <p>{values.extraFields[index].options[subIndex].choiceDescriptionTitle}</p>
                          </div>
                        )}
                        <SimpleField
                          index={index}
                          name={`extraFields[${index}].options[${subIndex}].choiceDescription`}
                          errorName={`extraFields[${index}].options[${subIndex}].choiceDescription`}
                          onChange={(key, value) => (values.extraFields[index].options[subIndex].showChoiceDescription
                            ? handleChangeChoiceDescription(`extraFields[${index}].options[${subIndex}].choiceDescription`, value)
                            : null)}
                          onKeyUp={() => handleKeyUp(`extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`, setErrorMessage, errorMessage)}
                          value={values.extraFields[index].answerDescription?.[descriptionIndex]}
                          error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].answerDescriptionFor[${descriptionIndex}]`, errorMessage) : undefined}
                          placeholder={values.extraFields[index].options[subIndex].choiceDescriptionPlaceholder}
                          inputType="text"
                          required={false}
                          hideError
                        />
                      </div>
                  )}
                </div>
              );
            })}

            <div className="formErrorContainer">
              {optionsError && <span>{optionsError}</span>}
              {!optionsError && touched && <ErrorMessage name={optionsName} component={FormErrorMessage} />}
            </div>
          </div>
        )}
      />
    </RadioFieldStyles>
  );
}
