import styled from 'styled-components';

export const MassMailingsViberStyles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #F0F0F0;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }

    .buttonsContainer {
      button {
        margin-left: 16px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .formSection {
    padding: 20px 32px 0 16px;
    box-sizing: border-box;

    .formFieldsContainer {
      .senderAccountInputContainer {
        .senderAccountInputTitle {
          display: inline-block;
          margin-bottom: 10px;
        }
      }
    }
  }

  .labelContainer {
    margin-bottom: 8px;
    font-weight: 600;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
  }

  .typeContainer {
    margin-bottom: 12px;

    .radioContainer {
      & > div {
        margin-bottom: 8px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .imageContentWrapper {
    display: grid;
    grid-template-columns: 160px 1fr;
    grid-gap: 32px;

    .logoContainer {
      height: 80px;
      background: #FFF;
      box-sizing: border-box;
      overflow: hidden;

      &.default {
        display: grid;
        place-items: center;
        background: #F0F0F0;
        border: 1px solid #E5E5E5;
        border-radius: 4px;
      }

      .selectedImage {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .infoContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .uploadErrorContainer {
        height: 24px;

        .uploadError {
          font-size: 11px;
          line-height: 16px;
          color: #ff3333;
        }
      }

      .description {
        font-size: 15px;
        line-height: 16px;
        color: #979797;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .descriptionsPart {
      display: flex;
      flex-direction: column;
      margin: 4px 0;
    }
  }

  .actionButtonContainer {
    .textFields {
      margin-top: 12px;
      display: grid;
      grid-template-columns: repeat(2, 244px);
      grid-gap: 24px;
    }
  }

  .finishButtonsContainer {
    width: 100%;
    max-width: 510px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    
    button {
      width: 100%;
    }
  }

  .previewContainer {
    width: 500px;
    max-width: 500px;
    background-color: #fff;
    border-radius: 4px;
    overflow: hidden;

    .header {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-right: 12px;
      box-sizing: border-box;

      h3 {
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .body {
      padding: 16px;
      border-bottom: 1px solid #e5e5e5;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      position: relative;

      .previewBodyContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        transform: translate(-50%, -50%);
        width: 205px;
        height: 444px;
      }

      & > img {
        //box-shadow: 2px 2px 10px rgba(0,0,0,0,15);
        filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15)) drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.15));
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
    }
  }

  .previewBody {
    position: relative;
    padding-top: 20px;
    box-sizing: border-box;
    width: 205px;
    height: 444px;
    -webkit-mask-box-image: url("https://voicerspace.fra1.digitaloceanspaces.com/43553c6881dfd71310862639283051aac5d94ece8747000bad8f698e4feac0f4.svg");
    mask-border: url("https://voicerspace.fra1.digitaloceanspaces.com/43553c6881dfd71310862639283051aac5d94ece8747000bad8f698e4feac0f4.svg");
    background-color: #1D2733;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 12px;

    .previewHeader {
      padding: 0 12px;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #424C58;
      box-sizing: border-box;
    }

    .previewChat {
      height: 338px;
      padding: 0 8px 16px 8px;
      box-sizing: border-box;
      //overflow-y: hidden;
      overflow-y: scroll;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .date {
        font-family: "Roboto", sans-serif;
        line-height: 28px;
        color: #fff;
        font-size: 9px;
        text-align: center;
      }

      .message {
        display: grid;
        grid-template-columns: 18px 150px;
        grid-gap: 4px;

        .senderLogo {
          width: 18px;
          height: 18px;
          border-radius: 6px;
          background-color: #fff;
          font-size: 14px;
          line-height: 16px;
          font-weight: bold;
          color: #0E9285;
          display: grid;
          place-items: center;
        }

        .messageContainer {
          font-size: 10px;
          line-height: 14px;
          word-wrap: break-word;
          background-color: #3F4C5C;
          border: 1px solid #3F4C5C;
          padding: 8px;
          border-radius: 0 6px 6px 6px;
          overflow: hidden;
          box-sizing: border-box;
          position: relative;
          
          .buttonContainer {
            &.withExtraMargin {
              margin-bottom: 8px;
            }
            
            button {
              width: 100%;
              height: 20px;
              font-size: 10px;
              line-height: 14px;
              color: #fff;
              background-color: #7C4C9D;
              margin-bottom: 8px;
              border-radius: 8px;
            }
          }

          .messageText {
            margin-bottom: 8px;

            a {
              color: #21a1ff;
            }
          }

          .time {
            position: absolute;
            bottom: 8px;
            right: 8px;
            font-size: 8px;
            line-height: 8px;
            text-align: right;
            color: #a0aebb;
          }

          img {
            width: 100%;
            height: auto;
            margin-bottom: 8px;
          }

          &.image {
            padding: 0;

            .buttonContainer {
              margin-bottom: 0;
              
              &.withExtraMargin {
                margin-bottom: 0;
              }
            }

            img {
              margin-bottom: 0;
            }

            .time {
              color: #fff;
            }
          }
        }
      }
    }

    .previewFooter {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 8px 8px 12px 8px;
      border-top: 1px solid #424C58;

      p {
        margin-bottom: 6px;
        font-family: "Roboto", sans-serif;
        font-size: 10px;
        line-height: 14px;
        color: #dcdcdc;
      }

      .icons {
        img {
          height: 14px;
          margin-right: 8px;
        }
      }

      & > img {
        width: 22px;
        height: 22px;
        position: fixed;
        right: 8px;
        bottom: 20px;
      }
    }
  }
`;
