import React, {
  Dispatch,
  FC, SetStateAction, useEffect, useRef, useState,
} from 'react'
import { Api } from '../../../api';
import { CompaniesMainInfo } from '../../../entities';
import { CompanySelect, MultiNodeSelect } from '../../molecules'
import { MultiTenancyStyles } from './MultiTenancyStyles'

type TMultiTenancy = {
    id: number;
    reQuery: boolean;
    setReQuery: Dispatch<SetStateAction<boolean>>
}

export const MultiTenancy: FC<TMultiTenancy> = React.memo(({ id, reQuery, setReQuery }) => {
  const [attachedCompanies, setAttachedCompanies] = useState<number[]>([])

  const companies = useRef<CompaniesMainInfo[]>([]);

  useEffect(() => {
    Api.getCompanies().then((responce) => {
      companies.current = responce.data
      Api.getMultiTenancy(id).then((res) => {
        setAttachedCompanies(res.data.structures.map((structure) => structure.companyID))
      })
    });
  }, [reQuery, id])

  return (
    <MultiTenancyStyles>
      <CompanySelect
        setReQuery={setReQuery}
        reQuery={reQuery}
        id={id}
        placeholder="Attach company"
        companies={companies.current}
        attachedCompanies={attachedCompanies}
        label="Attach company"
      />
      <div className="nodesSelects">
        {attachedCompanies.map((nodes) => (
          <MultiNodeSelect
            companyID={nodes}
            companies={companies.current}
            placeholder="Attach nodes"
            selectKey="name"
            key={nodes}
            name={nodes.toString()}
            search
            id={id}
          />
        ))}
      </div>
    </MultiTenancyStyles>
  )
})
