import {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import { ScaleFieldStyles } from './ScaleFieldStyles';
import FieldHeader from '../fieldHeader/FieldHeader';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getTranslationByLangOrEng } from '../../../i18n';
import ScaleFieldForm from './ScaleFieldForm';
import ScaleFieldSettingsForm from './ScaleFieldSettingsForm';
import { useAppSelector } from '../../../state';
import { FormikProps } from 'formik';
import {
  IExtraField, INpsScaleSettings,
  INumberScaleSettings, IStarScaleSettings,
  IColorScaleSettings,
} from '../../../entities';
import { ModalConfirmDecline } from '../../organisms';

interface ScaleFieldProps {
  index: number;
  extraBlockStyles?: CSSProperties;

  keyQuestionCheckboxOnChange?: () => void;
  keyQuestionCheckboxExtraBlockStyles?: CSSProperties;

  mainScaleCheckboxOnChange?: () => void;
  mainScaleCheckboxExtraBlockStyles?: CSSProperties;

  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;
  // handleOpenOnDiagram?: (index: number) => void;

  provided?: DraggableProvided;
}

export default function ScaleField({
  index,
  extraBlockStyles,

  keyQuestionCheckboxOnChange,
  keyQuestionCheckboxExtraBlockStyles,

  mainScaleCheckboxOnChange,
  mainScaleCheckboxExtraBlockStyles,

  draggable,
  handleCopy,
  handleRemove,
  // handleOpenOnDiagram,

  provided,
}: ScaleFieldProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const [selectedTab, setSelectedTab] = useState<string>('field');
  const [targetTab, setTargetTab] = useState<string>('settings');
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);
  const [formReinitialized, setFormReinitialized] = useState<boolean>(false);
  const [settingsFormReinitialized, setSettingsFormReinitialized] = useState<boolean>(false);

  const formRef = useRef<FormikProps<IExtraField>>(null);
  const settingsFormRef = useRef<FormikProps<IColorScaleSettings | INumberScaleSettings | IStarScaleSettings | INpsScaleSettings>>(null);

  useEffect(() => {
    setSelectedTab('field');
    setTargetTab('settings');
  }, [index])

  const tabs = [
    {
      key: 'field',
      text: getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_tab_field'),
    },
    {
      key: 'settings',
      text: getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_tab_settings'),
    },
  ];

  const hasChangedValues = (values: any, initialValues: any) => Object
    .entries(values)
    .reduce((acc: string[], [key, value]) => {
      // @ts-ignore
      const hasChanged = initialValues[key] !== value

      if (hasChanged) {
        acc.push(key)
      }

      return acc
    }, []).length > 0

  function checkIsChangesSaved(tabKey: string) {
    // console.log('checkIsChangesSaved', formRef.current?.values, formRef.current?.initialValues);
    // console.log('checkIsChangesSaved', settingsFormRef.current?.values, settingsFormRef.current?.initialValues);

    if (selectedTab === 'settings' && settingsFormRef.current && hasChangedValues(settingsFormRef.current.values, settingsFormRef.current.initialValues)) {
      setTargetTab(tabKey);
      setShowWarningModal(true);
    } else if (selectedTab === 'field' && formRef.current && hasChangedValues(formRef.current.values, formRef.current.initialValues)) {
      setTargetTab(tabKey);
      setShowWarningModal(true);
    } else {
      setSelectedTab(tabKey);
    }
  }

  function resetForm(values: IExtraField) {
    formRef.current?.resetForm({ values });
    setFormReinitialized(!formReinitialized);
  }

  function resetSettingsForm(values: IColorScaleSettings | INumberScaleSettings | IStarScaleSettings | INpsScaleSettings) {
    settingsFormRef.current?.resetForm({ values });
    setSettingsFormReinitialized(!formReinitialized);
  }

  return (
    <ScaleFieldStyles style={extraBlockStyles}>
      <FieldHeader
        type={notSavedExtraFields?.[index].type || ''}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_scale_title')}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
        // handleOpenOnDiagram={handleOpenOnDiagram ? () => handleOpenOnDiagram(index) : undefined}
      />

      <div className="tabsContainer">
        <div className="tabs">
          {tabs.map((tab) => (
            <button
              className={selectedTab === tab.key ? 'active' : ''}
              key={tab.key}
              type="button"
              onClick={() => checkIsChangesSaved(tab.key)}
            >
              {tab.text}
            </button>
          ))}
        </div>
      </div>

      {selectedTab === 'field' ? (
        <ScaleFieldForm
          ref={formRef}
          selectedTab={selectedTab}
          index={index}
          keyQuestionCheckboxOnChange={keyQuestionCheckboxOnChange}
          keyQuestionCheckboxExtraBlockStyles={keyQuestionCheckboxExtraBlockStyles}
          mainScaleCheckboxOnChange={mainScaleCheckboxOnChange}
          mainScaleCheckboxExtraBlockStyles={mainScaleCheckboxExtraBlockStyles}
          resetForm={resetForm}
          formReinitialized={formReinitialized}
        />
      ) : (
        <ScaleFieldSettingsForm
          ref={settingsFormRef}
          selectedTab={selectedTab}
          index={index}
          handleCancel={() => setSelectedTab('field')}
          resetForm={resetSettingsForm}
          formReinitialized={settingsFormReinitialized}
        />
      )}

      {showWarningModal && (
        <ModalConfirmDecline
          onClose={() => setShowWarningModal(false)}
          onConfirm={() => {
            setSelectedTab(targetTab);
            setShowWarningModal(false);
          }}
          onDecline={() => setShowWarningModal(false)}
          confirmText={getTranslationByLangOrEng(interfaceLanguage, 'confirm_button')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          title={getTranslationByLangOrEng(interfaceLanguage, 'changes_not_saved_modal')}
          firstButtonStyles={{ background: '#ff0000' }}
          secondButtonStyles={{ background: '#0E9285' }}
        />
      )}
    </ScaleFieldStyles>
  );
}
