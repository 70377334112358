import styled from 'styled-components';

export const AddRoleStyles = styled.div`
  .titleContainer {
    .buttonsContainer {
      display: flex;

      & > div {
        margin-left: 24px;
        min-width: 100px;

        & > button {
          width: 100%;
        }
      }
    }
  }

  .formSection {
    padding: 20px 32px 32px 16px;
    box-sizing: border-box;

    .labelContainer {
      display: flex;
      margin-bottom: 8px;

      .fakeLabel {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }
    }

    h2 {
      margin-bottom: 17px;
    }

    .twoColumns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      width: 100%;
      max-width: 524px;
    }
    
    .selectedPermissions {
      margin-bottom: 24px;

      .permission {
        width: 100%;
        max-width: 300px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }

  .checkboxContainer {
    display: grid;
    gap: 8px;

    .checkboxWrapper {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    .checkbox {
      border: 1px solid #E5E5E5;
      border-radius: 2px;
      width: 16px;
      height: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      &Selected {
        border: 1px solid #0E9285;
      }
    }
  }

  .editButtonsContainer {
    .menuOpenerContainer {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .menuOpenerButton {
        padding: 0 20px;
        cursor: pointer;
      }

      .menu {
        position: relative;

        .menuList {
          z-index: 99;
          position: absolute;
          top: calc(50% + 15px);
          right: 16px;
          width: 260px;
          border-radius: 4px;
          border: 1px solid #E5E5E5;
          overflow: hidden;
          filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

          list-style: none;
          font-size: 14px;
          color: #000000;

          li {
            height: 41px;
            background: #FFFFFF;
            border-bottom: 1px solid #E5E5E5;
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }
          }

          button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            transition: background-color 0.3s;

            &:hover {
              background-color: #F0F5F5;
              transition: background-color 0.3s;
            }
          }

          span {
            margin-left: 12px;
          }
        }
      }
    }

    .modalBody {
      .modalTitle {
        padding: 10px 16px;
        background: #F0F5F5;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
        border: 1px solid #F0F5F5;
        border-radius: 4px 4px 0px 0px;
      }

      .modalButtonsWrap {
        padding: 16px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 0px 0px 4px 4px;

        .modalButtonsCaption {
          margin-bottom: 16px;
          font-size: 14px;
          color: #000000;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      .modalError {
        font-size: 11px;
        line-height: 16px;
        color: #ff3333;
        margin-bottom: 0;
      }
    }
  }
  
  table {
    border: 1px solid #eee;
    border-bottom: none;
    margin-bottom: 24px;
  }
`;
