export enum UpdateType {
  Strict = 'strict',
  Tree = 'tree',
}

export interface ApiMultiTenancy {
  attachCompanyIDs?: number[];
  attachNodeIDs?: number[];
  detachCompanyIDs?: number[];
  detachNodeIDs?: number[];
  updateType: UpdateType;
}
