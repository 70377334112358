import React, { Fragment, useEffect, useState } from 'react';
import { StarIcon } from '../../../assets';
import { useNavigate } from 'react-router-dom';
import {
  networks, reviewsFilesExtraFieldText, reviewsFilesIcons, reviewsStatusIconsAndText,
} from '../../../constants';
import { INewAnswerStructure, ILanguage, IContact } from '../../../entities';
import { useAppSelector } from '../../../state';
import { getTypeOfContact, truncate } from '../../../utils';

export function NameExtraField({ nodeName }: {nodeName: string}) {
  return (
    <div className="nameContainer">
      <span>{nodeName}</span>
    </div>
  );
}

export function KeyAnswerExtraField({ answers }: {answers: { key: string, value: string, type: string, id: number, fieldID: number, clientID: number }[]}) {
  const [keyAnswers, setKeyAnswers] = useState<{ key?: string, value: string, type: string, fieldID: number, id: number, clientID: number }[]>([]);

  useEffect(() => {
    const keyAnswers = answers?.filter((answer) => !answer?.value?.includes('document') && answer?.value?.includes('true')).slice(0, 2);

    if (keyAnswers.find((answer) => answer?.type?.includes('checkbox'))) {
      const checkboxAnswers = keyAnswers.filter((answer) => answer.type.includes('checkbox'));

      const joinedCheckboxAnswers: { [fieldID: number]: INewAnswerStructure } = {};
      for (const answer of checkboxAnswers) {
        if (joinedCheckboxAnswers[answer.fieldID]) {
          joinedCheckboxAnswers[answer.fieldID].value = `${joinedCheckboxAnswers[answer.fieldID].value}|||${answer.value}`;
        } else {
          joinedCheckboxAnswers[answer.fieldID] = answer;
        }
      }

      setKeyAnswers([...answers.filter((answer) => !answer?.type?.includes('checkbox')), ...Object.values(joinedCheckboxAnswers)].sort((a, b) => +a.fieldID - +b.fieldID));
    }

    setKeyAnswers(keyAnswers);
  }, []);

  const getKeyAnswerType = (answer: { key?: string, value: string, type: string, fieldID: number, id: number, clientID: number }) => {
    switch (answer.type) {
      case 'scale_star':
        return (
          <div className="scaleContainer">
            <span>
              {answer.value.split('|||')[0]}
            </span>
            <div>
              <StarIcon color="#FF8C19" width={19} height={19} borderColor="#FF8C19" />
            </div>
          </div>
        );

      case 'scale_color':
      case 'scale_number':
      case 'scale_nps':
        return (
          <div className="scaleContainer">
            <span>{answer.value.split('|||')[0]}</span>
          </div>
        );
    }
  };

  return (
    <div className="answersContainer">
      {answers.map((answer) => (
        <Fragment key={answer.id}>
          {getKeyAnswerType(answer)}
        </Fragment>
      ))}
    </div>
  );
}

export function ReviewExtraField({ files }: {files: {[key : string] : string[]}}) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <div className="customReviewsWrapper">
      {Object.keys(files).map((item) => {
        const Icon = reviewsFilesIcons[item];
        if (files[item].length) {
          return (
            <div className="reviewContainer">
              <Icon width={16} height={16} color="#0E9285" />
              {reviewsFilesExtraFieldText(interfaceLanguage)[item]}
              {files[item].length > 1 && <span>{files[item].length}</span>}
            </div>
          );
        }

        return '';
      })}
    </div>
  );
}

export function ConnectionChannelExtraField({ answers, contacts, rewID } : {answers?: INewAnswerStructure[], contacts: IContact[], rewID: number }) {
  const networksTypes = contacts.map((contact) => getTypeOfContact(contact))
  const rewNetwork = answers?.find((answer) => answer.type === 'networks')?.value?.split('_')[1].replace('|||', '');
  const DisableNetworkIcon = (rewNetwork === 'telegram' && rewNetwork) ? networks[rewNetwork].icon : null;

  return (
    <div className="connectionChannelContainer">
      {rewNetwork && !networksTypes.includes(rewNetwork) && (
        <div className="networkDataContainer">
          {DisableNetworkIcon && <DisableNetworkIcon width={32} height={28} color="rgb(204, 204, 204)" />}
        </div>
      )}
      {networksTypes && (
        networksTypes.map((contact) => {
          const NetworkIcon = contact ? networks[contact].icon : null;
          return (
            <div key={contact} className="networkDataContainer">
              {NetworkIcon && <NetworkIcon width={32} height={28} color={networks[contact].color} key={contact} />}
            </div>
          );
        })
      )}
    </div>
  );
}

export function StatusExtraField({ status, lang }: {status: number, lang: string}) {
  const Icon = reviewsStatusIconsAndText(lang)[status].icon;

  return (
    <div className="statusContainer">
      <Icon />
      <span>{reviewsStatusIconsAndText(lang)[status].text}</span>
    </div>
  );
}

export function LanguagesExtraField({ lang }: {lang: ILanguage}) {
  return (
    <div className="connectionChannelContainer">
      <span>{`${lang.icon} ${lang.name}`}</span>
    </div>
  );
}

export function SourceExtraField({ reviewId, sources }: {reviewId: number, sources: { reviewId: number, value: string | null | undefined }[]}) {
  const source = sources?.find((item) => item.reviewId === reviewId)?.value;
  return (
    <div className="sourceContainer">
      {source ? (<span className="sourceTableCell">{source}</span>) : <div />}
    </div>
  );
}

export function EmailExtraField({ answers }: {answers: INewAnswerStructure[] }) {
  const emailAnswer = answers?.find((answer) => answer.type === 'email')?.value?.split('|||')[0];
  const networksAnswer = answers?.find((answer) => answer.type === 'networks')?.value?.includes('email');

  function getEmail() {
    let email: string | undefined;
    if (emailAnswer) {
      email = emailAnswer
    } else if (networksAnswer) {
      const answer = answers?.find((answer) => answer.type === 'networks')?.value?.split('|||')[0];
      email = answer?.split('_')[2]
    }
    return email;
  }

  return (
    <div>
      {(emailAnswer || networksAnswer) && <span>{getEmail()}</span>}
    </div>
  );
}

export function UserNameExtraField({ answers }: {answers: INewAnswerStructure[] }) {
  const nameAnswer = answers?.find((answer) => answer.type === 'name')?.value?.split('|||')[0];
  const networksAnswer = answers?.find((answer) => answer.type === 'networks')?.value;
  const nameInNetworksAnswer = networksAnswer?.split('|||')[0]?.split('_')[3];

  function getName() {
    let name: string | undefined;
    if (nameAnswer) {
      name = nameAnswer
    } else if (nameInNetworksAnswer) {
      name = nameInNetworksAnswer
    }
    return name;
  }

  return (
    <div>
      {(nameAnswer || nameInNetworksAnswer) && <span>{getName()}</span>}
    </div>
  );
}

export function PhoneNumberExtraField({ answers }: {answers: INewAnswerStructure[] }) {
  const phoneAnswer = answers?.find((answer) => answer.type === 'phone' || answer.type === 'phone_number')?.value?.split('|||')[0];
  const networksAnswer = answers?.find((answer) => answer.type === 'networks')?.value;
  const phoneInNetworksAnswer = networksAnswer?.split('|||')[0]?.split('_')[2];

  function getPhoneNumber() {
    let phone: string | undefined;
    if (phoneAnswer) {
      phone = phoneAnswer
    } else if (phoneInNetworksAnswer) {
      phone = phoneInNetworksAnswer
    }
    return phone;
  }

  return (
    <div>
      {(phoneAnswer || phoneInNetworksAnswer) && <span>{getPhoneNumber()}</span>}
    </div>
  );
}

export function CommentExtraField({ comment }: { comment: string }) {
  return (
    <div title={comment} className="commentContainer">
      <p className="commentField">
        {comment && truncate(comment, 36)}
      </p>
    </div>
  );
}

export function ParentNodeNameExtraField({ parentNodeName }: { parentNodeName: string | null | undefined }) {
  return (
    <div className="parentNodeNameContainer">
      {parentNodeName}
    </div>
  );
}

export function KeyQuestionAnswerField({ answers }: { answers: INewAnswerStructure[] }) {
  const keyExtraQuestionField = answers?.find((answer) => answer?.type === 'extra_question' && answer?.keyQuestion);
  const answerValueExtraQuestion = keyExtraQuestionField?.value.split('|||')[0];

  return (
    <div title={answerValueExtraQuestion} className="extraQuestionFieldAnswerContainer">
      <p className="extraQuestionFieldAnswer">
        {answerValueExtraQuestion && truncate(answerValueExtraQuestion, 36)}
      </p>
    </div>
  );
}
