import styled from 'styled-components';

export const AddUserStyles = styled.div`
  .titleContainer {    
    .buttonsContainer {
      display: flex;
      
      & > div {
        margin-left: 24px;
        min-width: 100px;
        
        & > button {
          width: 100%;
        }
      }
    }
  }

  .formSection {
    padding: 20px 32px 32px 16px;
    box-sizing: border-box;

    .contacts {
      margin-top: 15px;
      display: flex;
      flex-direction: column;
      gap: 5px;

      div {
        display: flex;
        align-items: center;
        gap: 15px;
      }
    }
    
    
    .row {
      margin: 10px 0;
      width: 100%;
      max-width: 600px;
      display: flex;
      align-items: center;
      gap: 24px;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        gap: 10px;
        flex-direction: column;
      }
      
      &.forEditUser {
        margin-bottom: 24px;
      }
    }

    .rolesContainer {
      &.notEmpty {
        margin-bottom: 24px;
      }

      ul {
        max-width: 428px;
        padding: 0;
        margin: 0;

        li {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border-bottom: 1px solid #eee;
          padding: 4px;

          &:first-child {
            border-top: 1px solid #eee;
          }

          span {
            margin-right: 16px;
          }

          button {
            height: 20px;
          }
        }
      }
    }

    .horizontalLine {
      width: 100%;
      height: 1px;
      background-color: #f0f0f0;
      margin-bottom: 24px;
      margin-top: 10px;
    }

    .extraErrorMessage {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }

    h3 {
      margin-bottom: 12px;
    }
  }

  .editButtonsContainer {
    .menuOpenerContainer {
      display: flex;
      justify-content: flex-end;
      position: relative;

      .menuOpenerButton {
        padding: 0 20px;
        cursor: pointer;
      }

      .menu {
        position: relative;

        .menuList {
          z-index: 99;
          position: absolute;
          top: calc(50% + 15px);
          right: 16px;
          width: 260px;
          border-radius: 4px;
          border: 1px solid #E5E5E5;
          overflow: hidden;
          filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

          list-style: none;
          font-size: 14px;
          color: #000000;

          li {
            height: 41px;
            background: #FFFFFF;
            border-bottom: 1px solid #E5E5E5;
            box-sizing: border-box;

            &:last-child {
              border-bottom: none;
            }
          }

          button {
            display: flex;
            align-items: center;
            width: 100%;
            height: 100%;
            padding: 0 16px;
            box-sizing: border-box;
            transition: background-color 0.3s;

            &:hover {
              background-color: #F0F5F5;
              transition: background-color 0.3s;
            }
          }

          span {
            margin-left: 12px;
          }
        }
      }
    }

    .modalBody {
      .modalTitle {
        padding: 10px 16px;
        background: #F0F5F5;
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
        border: 1px solid #F0F5F5;
        border-radius: 4px 4px 0px 0px;
      }

      .modalButtonsWrap {
        padding: 16px;
        background: #FFFFFF;
        border: 1px solid #F0F0F0;
        border-radius: 0px 0px 4px 4px;

        .modalButtonsCaption {
          margin-bottom: 16px;
          font-size: 14px;
          color: #000000;
        }

        div {
          display: flex;
          justify-content: space-between;
        }
      }

      .modalError {
        font-size: 11px;
        line-height: 16px;
        color: #ff3333;
        margin-bottom: 0;
      }
    }
  }

  table {
    border: 1px solid #eee;
    border-bottom: none;
    margin-bottom: 24px;
  }
`;
