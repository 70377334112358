import { CompanyIssueStatusesAndTypesStyles } from './CompanyIssueStatusesAndTypesStyles';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { getTranslationByLangOrEng } from '../../i18n';
import InputField from '../form/inputField/InputField';
import { DeleteIcon, JustPlusIcon, PencilIcon } from '../../assets';
import SubmitButton from '../form/submitButton/SubmitButton';
import React, { useEffect, useRef, useState } from 'react';
import { Api } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { IIssueType, IIssueStatus } from '../../entities';
import {
  formInitialValues2_1,
  formInitialValues2_2,
  FormValues,
  FormValues2,
  validationSchema,
  validationSchema2,
} from './formValuesAndValidation';
import { updateItemsList } from './utils';
import { useAppSelector } from '../../state';
import { Loader, TransparentButton } from '../atoms';
import { CompaniesInfoModal, Modal, ModalConfirmDecline } from '../organisms';
import { getErrorMessage, handleKeyUp } from '../../utils';

export default function CompanyIssueStatusesAndTypes() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [submitError, setSubmitError] = useState<string>('');
  const [accessibleStatusesEdit, setAccessibleStatusesEdit] = useState<boolean>(false);
  const [accessibleTypesEdit, setAccessibleTypesEdit] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [afterSaveModal, setAfterSaveModal] = useState<boolean>(false);
  const [openedModalType, setOpenedModalType] = useState<string>();
  const [issueTypeOrStatusCreateLoading, setIssueTypeOrStatusCreateLoading] = useState<boolean>(false);
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');

  const formRef = useRef<FormikProps<FormValues>>(null);
  const form2Ref = useRef<FormikProps<FormValues2>>(null);

  async function handleCreateIssueType(value: IIssueType) {
    formRef.current!.values.types = [...formRef.current!.values.types, value];
    setDataChanged((prev) => !prev);
  }

  async function handleCreateIssueStatus(value: IIssueStatus) {
    formRef.current!.values.statuses = [...formRef.current!.values.statuses, value];
    setDataChanged((prev) => !prev);
  }

  async function getDataBySelectedCompany(companyId: number) {
    const statuses = await Api.getTicketStatusesByCompany(companyId);

    const types = await Api.getTicketTypesByCompany(companyId);

    if (
      statuses.statusCode >= 200 && statuses.statusCode < 300
      && types.statusCode >= 200 && types.statusCode < 300
    ) {
      setInitialValues({ statuses: statuses.data, types: types.data });
    }
  }

  useEffect(() => {
    if (id) {
      setIsLoading(true);
      getDataBySelectedCompany(+id).finally(() => {
        setIsLoading(false);
      });
    }
  }, [id]);

  const onSaveModalConfirm = async (values: FormValues) => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        updateItemsList('status', +id!, initialValues ? initialValues.statuses : [], values.statuses);
        updateItemsList('type', +id!, initialValues ? initialValues.types : [], values.types);
      } catch (e) {
        setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      } finally {
        if (!submitError) {
          setConfirmSaveModalOpen(false);
          setAfterSaveModal(true);
        }
        setIsLoading(false);
      }
    }
  }

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setConfirmSaveModalOpen(true);

    setSubmitting(false);
  }

  function onSubmit2(
    values: FormValues2,
    { setSubmitting }: FormikHelpers<FormValues2>,
  ) {
    setSubmitting(false);

    setIssueTypeOrStatusCreateLoading(true);

    try {
      if (values.type === 'type') {
        handleCreateIssueType({ companyId: +id!, name: values.value });
      }

      if (values.type === 'status') {
        handleCreateIssueStatus({ companyId: +id!, name: values.value });
      }
    } catch (e) {
      console.log('ERROR', e);
    } finally {
      setIssueTypeOrStatusCreateLoading(false);
      setOpenedModalType(undefined);
    }
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="statuses"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_issue_statuses_and_types_statuses_text')}</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleStatusesEdit(!accessibleStatusesEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <TransparentButton
                        handleClick={() => setOpenedModalType('status')}
                        text={getTranslationByLangOrEng(interfaceLanguage, 'companies_issue_statuses_and_types_add_status_button')}
                      />
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.statuses.map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={accessibleStatusesEdit ? 'itemsWrapper edit' : 'itemsWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleStatusesEdit ? (
                          <InputField
                            name={`statuses[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`statuses[${index}].name`, setErrorMessage, errorMessage)}
                            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_add_color_placeholder')}
                            value={values.statuses[index].name}
                              // @ts-ignore*
                            error={typeof errorMessage === 'object' ? getErrorMessage(`statuses[${index}].name`, errorMessage) : undefined}
                            extraBlockStyles={{ width: '300px' }}
                          />
                        ) : field.name}
                      </div>
                      <div className="fileWrapper">
                        {accessibleStatusesEdit
                            && (
                              <button
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </button>
                            )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
      </div>

      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="types"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_issue_statuses_and_types_types_text')}</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleTypesEdit(!accessibleTypesEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <TransparentButton
                        handleClick={() => setOpenedModalType('type')}
                        text={getTranslationByLangOrEng(interfaceLanguage, 'companies_issue_statuses_and_types_type_add_button')}
                      />
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.types.map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={accessibleTypesEdit ? 'itemsWrapper edit' : 'itemsWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleTypesEdit ? (
                          <InputField
                            name={`types[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`types[${index}].name`, setErrorMessage, errorMessage)}
                            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_add_color_placeholder')}
                            value={values.types[index].name}
                              // @ts-ignore*
                            error={typeof errorMessage === 'object' ? getErrorMessage(`types[${index}].name`) : undefined}
                            extraBlockStyles={{ width: '300px' }}
                          />
                        ) : field.name}
                      </div>
                      <div className="fileWrapper">
                        {accessibleTypesEdit
                            && (
                              <button
                                type="button"
                                onClick={() => remove(index)}
                              >
                                <DeleteIcon />
                              </button>
                            )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
      </div>

      <SubmitButton extraBlockStyles={{ maxWidth: '544px', width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
      <span className="uploadError">{submitError}</span>
    </Form>
  );

  const renderForm2 = ({
    values,
    errors,
    setFieldValue,
  }: FormikProps<FormValues2>) => (
    <Form>
      <InputField
        extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
        name="value"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('value', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'issues_form_name_placeholder')}
        value={values.value}
        error={typeof errorMessage === 'object' ? getErrorMessage('value', errorMessage) : undefined}
        label={openedModalType === 'type' ? getTranslationByLangOrEng(interfaceLanguage, 'issues_form_type_name_label') : getTranslationByLangOrEng(interfaceLanguage, 'issues_form_status_name_label')}
        required
      />

      <SubmitButton extraBlockStyles={{ maxWidth: 250, width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
    </Form>
  );

  return (
    <CompanyIssueStatusesAndTypesStyles>
      {afterSaveModal && (
        <CompaniesInfoModal
          onConfirm={() => setAfterSaveModal(false)}
          onClose={() => {
            setAfterSaveModal(false);
          }}
          onDecline={() => navigate('/companies')}
        />
      )}

      {!initialValues ? <Loader /> : (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}

      {confirmSaveModalOpen && (
      <ModalConfirmDecline
        onClose={() => setConfirmSaveModalOpen(false)}
        onConfirm={() => onSaveModalConfirm(formRef.current!.values)}
        onDecline={() => setConfirmSaveModalOpen(false)}
        confirmText={getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}
        declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_issues_and_types_confirmation_before_save_modal_title')}
        error={!!dataSaveError}
        errorText={dataSaveError}
        firstButtonStyles={{ background: '#ff0000' }}
        secondButtonStyles={{ background: '#0E9285' }}
      />
      )}

      {openedModalType && (
        <Modal onClose={() => setOpenedModalType(undefined)} extraStyles={{ width: '100%', maxWidth: 500 }}>
          <div className="modalContainer">
            <div className="modalHeader">
              <h5>{openedModalType === 'type' ? getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_type') : getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_status')}</h5>
              <button type="button" onClick={() => setOpenedModalType(undefined)} disabled={issueTypeOrStatusCreateLoading}>
                <JustPlusIcon color="#999" width={20} height={20} />
              </button>
            </div>
            <div className="body">
              <div className="modalTitleContainer">
                <h6 style={{ textAlign: 'left' }}>{openedModalType === 'type' ? getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_type_title') : getTranslationByLangOrEng(interfaceLanguage, 'issues_form_create_new_issue_status_title')}</h6>
                <span>{`(${getTranslationByLangOrEng(interfaceLanguage, 'issues_form_must_be_unique')})`}</span>
              </div>

              <Formik
                innerRef={form2Ref}
                initialValues={openedModalType === 'type' ? formInitialValues2_2 : formInitialValues2_1}
                onSubmit={onSubmit2}
                validationSchema={validationSchema2(interfaceLanguage)}
              >
                {renderForm2}
              </Formik>
            </div>
            <div className="footer right">
              <div />
              <TransparentButton disabled={issueTypeOrStatusCreateLoading} handleClick={() => setOpenedModalType(undefined)} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} />
            </div>
          </div>
        </Modal>
      )}
    </CompanyIssueStatusesAndTypesStyles>
  );
}
