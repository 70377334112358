import React from 'react';
import { connectionChannelsMassMailings, massMailingStatuses, massMailingTypes } from '../../../constants';
import { PencilIcon } from '../../../assets';

export function TypeExtraField({ typeKey }: {typeKey: number}) {
  const type = Object.values(massMailingTypes).find((type) => type.key === typeKey);

  return (
    <div className="typeContainer">
      {type && type.text}
    </div>
  );
}

export function NodeExtraField({ type, name, data }: { type: number, name: string | undefined, data: any }) {
  if (type === 1) {
    return (
      <div className="nodeContainer">
        {name && name}
      </div>
    );
  }

  return (
    <div />
  );
}

export function ChannelOfConnectionExtraField({ connectionChannelKey }: {connectionChannelKey: number}) {
  const connectionChannel = Object.values(connectionChannelsMassMailings).find((channel) => channel.key === connectionChannelKey);

  const Icon = connectionChannel?.icon;

  return (
    <div className="connectionChannelContainer">
      {connectionChannel && (
        <>
          <span>{connectionChannel.name}</span>
          <Icon width={24} height={24} />
        </>
      )}
    </div>
  );
}

export function StatusExtraField({ statusKey }: {statusKey: number}) {
  const status = Object.values(massMailingStatuses).find((status) => status.key === statusKey);

  return (
    <div className="statusContainer">
      {status && status.text}
    </div>
  );
}

export function DateExtraField({ date }: {date: string}) {
  return (
    <div className="statusContainer">
      {date && date.split('T')[0].split('-').reverse().join('.')}
    </div>
  );
}

export function ExtraButtons({ id }: {id: number}) {
  return (
    <div className="statusContainer">
      {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
      <button type="button" onClick={() => {}}><PencilIcon /></button>
    </div>
  );
}
