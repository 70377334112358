import { RolesStyles } from './RolesStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Api, ApiResponse } from '../../api';
import { useClickOutside } from '../../hooks/useClickOutside';
import { DeleteIcon, ThreeDots } from '../../assets';
import { IUserRole } from '../../entities';
import { availablePageSizes } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import {
  Loader, ModalConfirmDecline, SubHeader, Table, TransparentButton,
} from '../../components';

function NameCustomField({ name, logo, textColor }: {name: string, logo?: string, textColor?: string}) {
  return (
    <div className="customNameWrapper">
      {logo && (
      <div className={logo ? 'imageContainer' : 'imageContainer default'}>
        <div className="image">
          {logo && <img src={logo} alt="logo" />}
        </div>
      </div>
      )}
      <span style={{ color: textColor || '#000' }}>{name}</span>
    </div>
  );
}

function EditButtons({ id, filterItemsAfterDelete }: {id: number, filterItemsAfterDelete: (id: number) => void}) {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [modalIndex, setModalIndex] = useState<number>(0);
  const [modalErrorIndex, setModalErrorIndex] = useState<number>(0);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const menuRef = useRef<any>();

  useClickOutside(menuRef, () => setMenuOpen(false));

  return (
    <div className="editButtonsContainer">
      <div className="menuOpenerContainer" ref={menuRef}>
        <button
          className="menuOpenerButton"
          type="button"
          onClick={(event) => {
            event.stopPropagation();
            setMenuOpen(!menuOpen);
          }}
        >
          <ThreeDots />
        </button>
        {menuOpen && (
          <ul className="menuList">
            <li>
              <button
                type="button"
                onClick={(event) => {
                  event.stopPropagation();
                  setModalIndex(1);
                }}
              >
                <DeleteIcon />
                {' '}
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}</span>
              </button>
            </li>
          </ul>
        )}
      </div>
      {modalIndex === 1 && (
        <ModalConfirmDecline
          onClose={() => setModalIndex(0)}
          onDecline={(event) => {
            event.stopPropagation();
            setModalIndex(0);
          }}
          onConfirm={(event) => {
            event.stopPropagation();
            Api.deleteRole(id)
              .then((res) => {
                if (res.statusCode >= 200 && res.statusCode < 300) {
                  filterItemsAfterDelete(id);
                  setModalIndex(0);
                } else {
                  setModalErrorIndex(1);
                }
              });
          }}
          title={getTranslationByLangOrEng(interfaceLanguage, 'languages_table_modal_delete_title')}
          buttonsLabel={getTranslationByLangOrEng(interfaceLanguage, 'users_main_delete_description')}
          confirmText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
          firstButtonStyles={{ border: '1px solid #C4C4C4', backgroundColor: '#ffffff', color: '#000000' }}
          secondButtonStyles={{ backgroundColor: '#FF0101', border: '1px solid #FF0101' }}
          error={modalErrorIndex === 1}
          errorText={getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
        />
      )}
    </div>
  );
}

export const Roles = () => {
  const { pageNumber } = useParams();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<string>('');
  const [itemsListChanged, setItemsListChanged] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [init, setInit] = useState<boolean>(true);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const shouldBeCanceled = useRef<boolean>(false);
  const rolesRef = useRef<IUserRole[]>();
  const itemsQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[0]);

  function workWithResponse(response: ApiResponse<IUserRole[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newItems = response.data;

      if (!shouldBeCanceled.current) {
        rolesRef.current = [...(Array.isArray(newItems)
          ? newItems
          : [])];
        itemsQuantityRef.current = response.count!;
        setUploading(false);
      }
    }
  }

  useEffect(() => {
    if (!uploading) {
      setUploading(true);

      setInit(true);
      rolesRef.current = undefined;
      Api.getRoles((pageNumber ? +pageNumber - 1 : 0) * pageSizeRef.current, pageSizeRef.current)
        .then((formsResult) => {
          if (!shouldBeCanceled.current) {
            workWithResponse(formsResult);
          }
        }).finally(() => {
          setInit(false);
        });
    }
  }, [pageNumber]);

  const filterItemsAfterDelete = useCallback((id: number) => {
    rolesRef.current = rolesRef.current?.filter((user) => user.id !== id) || [];
    setItemsListChanged(!itemsListChanged);
  }, [rolesRef.current]);

  const filteredItems = useMemo(() => rolesRef.current?.filter(
    (role) => role.name!.toLowerCase().includes(searchParams.toLowerCase()),
  ), [searchParams, rolesRef.current, uploading]);

  useEffect(() => {
    if (rolesRef.current?.length) {
      setTableHeaders([
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'roles_main_table_user_role_header'),
          accessor: 'role',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField name={data.row.original.name} textColor="#979797" />,
        },
        {
          Header: 'Edit buttons',
          accessor: 'editButtons',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <EditButtons id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
          ),
        },
      ]);
    }
  }, [rolesRef.current]);

  const headerColumnCounts = { name: filteredItems?.length || 0 };

  const sortableColumns = ['name'];

  const extraFieldsSettings = {
    editButtons: {
      justifyContent: 'flex-end',
    },
  };

  const onClickToRow = async (data: any) => {
    navigate(`/users/roles/${data.id}`);
  };

  return (
    <RolesStyles>
      <Helmet>
        <title>Roles Voicer</title>
      </Helmet>

      <div>
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_title')}>
          <div className="buttonsContainer">
            <TransparentButton handleClick={() => navigate('/users/permissions')} text={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_permissions_button')} filled />
            <TransparentButton handleClick={() => navigate('/users/roles/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'roles_main_add_role_button')} filled />
            <TransparentButton handleClick={() => navigate('/users')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
          </div>
        </SubHeader>

        {uploading && <Loader />}

        {!!rolesRef.current && !!tableHeaders && !uploading && (
          <Table
            pagination
            columns={tableHeaders}
            data={rolesRef.current}
            hideFieldsSplitters
            extraFieldsSettings={extraFieldsSettings}
            headerColumnCounts={{ role: itemsQuantityRef.current }}
            rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_role_title')}
            hiddenHeaders={['editButtons']}
            fullWidthColumns={['editButtons']}
            pageSize={pageSizeRef.current}
            currentPage={pageNumber ? +pageNumber : 1}
            rowsQuantity={itemsQuantityRef.current}
            pageNumberHandler={(page) => {
              if (page) {
                navigate(`/users/roles/page/${page}`);
              }
            }}
            onClickToRow={onClickToRow}
            sortableColumns={sortableColumns}
          />
        )}
      </div>
    </RolesStyles>
  );
}
