import React, { useEffect, useRef, useState } from 'react';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps, FieldArray,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  useAppDispatch, useAppSelector, setMassMailingMainInfoCompany,
} from '../../../../state';
import { getTranslationByLangOrEng } from '../../../../i18n';
import SubmitButton from '../../../../components/form/submitButton/SubmitButton';
import 'react-calendar/dist/Calendar.css';
import CustomSelect from '../../../../components/form/customSelect/CustomSelect';
import { connectionChannelsMassMailings } from '../../../../constants';
import { UploadFileButton } from '../../../../components/form/uploadFileButton/UploadFileButton';
import { FormValues, validationSchema } from './formValuesAndValidation';
import { CreateMassMailingsFirstStepStyles } from './CreateMassMailingsFirstStepStyles';
import { IMassMailingMainInfo } from '../../../../entities/IMassMailingMainInfo';
import { DeleteIcon, FileIcon } from '../../../../assets';
import { nanoid } from 'nanoid';
import { read, utils } from 'xlsx';
import { Helmet } from 'react-helmet-async';
import { Loader, SubHeader } from '../../../../components';

function getTabTexts(lang: string) {
  return [
    {
      key: 'addressMailing',
      text: getTranslationByLangOrEng(lang, 'mass_mailing_type_by_addresses'),
    },
  ];
}

export default function CreateMassMailingsFirstStep() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { massMailingMainInfo } = useAppSelector((state) => state.massMailing);

  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [recipient, setRecipient] = useState<string>('');
  const [isDataFromFileDownloading, setIsDataFromFileDownloading] = useState<boolean>(false);
  const [fileData, setFileData] = useState<{ recipient: string | number }[]>();
  const [addRecipientErrorMessage, setAddRecipientErrorMessage] = useState<string>('');

  let boundFieldArrayPushMethod;

  const bindPushMethod = (pushMethod: any) => {
    boundFieldArrayPushMethod = pushMethod;
  }

  function handleAddRecipient() {
    if (recipient && recipient.trim() && recipient.length >= 12) {
      boundFieldArrayPushMethod.push({ recipient: recipient.trim(), id: nanoid() })
      setRecipient('');
      setAddRecipientErrorMessage('');
    } else if (recipient && recipient.trim() && recipient.length < 12) {
      setAddRecipientErrorMessage('Incorrect data length');
    } else {
      setAddRecipientErrorMessage('It is necessary to enter recipient');
    }
  }

  async function getDataFromServer() {
    if (massMailingMainInfo) {
      const initialValuesFromRedux: FormValues = {
        mailingType: massMailingMainInfo.mailingType || 'addressMailing',
        connectionChannel: Object.values(connectionChannelsMassMailings).find((channel) => channel.key === massMailingMainInfo.connectionChannelKey) || undefined,
        recipients: massMailingMainInfo.recipients || [],
        contactsFile: massMailingMainInfo.recipientsFile,
      };

      setInitialValues(initialValuesFromRedux);
    } else {
      setInitialValues({
        mailingType: 'addressMailing',
        connectionChannel: undefined,
        recipients: [],
        contactsFile: undefined,
      });
    }
  }

  useEffect(() => {
    setIsLoading(true);
    getDataFromServer()
      .then((res) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (fileData && fileData.length > 0) {
      setIsDataFromFileDownloading(true)
      const modifiedWorksheetData = fileData.map((item) => `+${item.recipient}`);
      modifiedWorksheetData.forEach((item) => {
        boundFieldArrayPushMethod.push({ recipient: item.trim(), id: nanoid() })
      })
      setIsDataFromFileDownloading(false);
    }
  }, [fileData])

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);

    const data: IMassMailingMainInfo = {
      mailingType: values.mailingType,
      connectionChannelKey: values.connectionChannel!.key,
      recipientsFile: values.contactsFile,
      recipients: values.recipients,
    };

    dispatch(setMassMailingMainInfoCompany(data));
    navigate('/massMailings/create/step-2');
  }

  const handleFileDownloadAndParseData = async (file: any) => {
    setIsDataFromFileDownloading(true);
    const bufferData = await file.arrayBuffer();
    const workbook = read(bufferData);
    const worksheet = workbook.Sheets[workbook.SheetNames[0]];
    const worksheetDataInJson = utils.sheet_to_json<{ recipient: string }>(worksheet);
    setFileData(worksheetDataInJson);
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formContainer">
        <div className="formFieldsContainer">
          {/* TODO: PLEASE, DON'T DELETE! TEMPORARILY COMMENTED; WILL BE RETURNED SOON */}
          {/* <div className="tabsContainer">
            {getTabTexts(interfaceLanguage).map((tab) => (
              <button
                name="mailingType"
                className={values.mailingType === tab.key ? 'active' : ''}
                key={tab.key}
                type="button"
                onClick={() => setFieldValue('mailingType', tab.key)}
              >
                {tab.text}
              </button>
            ))}
          </div> */}

          {values.mailingType === 'addressMailing' && (
          <div className="connectionChannelsHelperWrapper">
            <div className="connectionChannelsContainer">
              <span className="connectionChannelsTitle">{getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_channels_of_connections')}</span>
              <CustomSelect
                name="connectionChannel"
                options={Object.values(connectionChannelsMassMailings) || []}
                selectKey="name"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'choose_mass_mailings_communication_channel')}
                value={values.connectionChannel}
                handleSelect={(formsArray) => setFieldValue('connectionChannel', formsArray)}
                search
                formGroupStyles={{ width: '400px' }}
              />
            </div>

            <div className="recipientsInputContainer">
              <label className="recipientInputLabel" htmlFor="recipient-input">
                <div className="recipientsInputInnerContainer">
                  <span className="recipientInputLabelText">
                    {getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_recipient_label')}
                  </span>
                  <div className="addRecipientContainer">
                    <input
                      maxLength={14}
                      id="recipient-input"
                      type="text"
                      value={recipient}
                      onChange={(event) => setRecipient(event.target.value)}
                      className="recipientInput"
                    />
                    <button
                      className="addRecipientButton"
                      type="button"
                      onClick={handleAddRecipient}
                    >
                      {getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_add_recipient_button_text')}
                    </button>
                  </div>
                </div>
              </label>
              {addRecipientErrorMessage && (<p className="extraErrorMessage">{addRecipientErrorMessage}</p>)}
              {errors && errors.recipients && (<p className="extraErrorMessage">{`* ${errors.recipients}`}</p>)}
            </div>

            {/* TODO: DON'T DELETE! TEMPORARILY COMMENTED; WILL BE RETURNED SOON */}
            {/* <div className="contentEditableWrapper">
              <div className="splitterContainer">
                <InputField
                  label="Splitter"
                  name="splitter"
                  onChange={setFieldValue}
                  onKeyUp={() => handleKeyUp('splitter')}
                  placeholder=""
                  value={values.splitter}
                  error={typeof errorMessage === 'object' ? getErrorMessage('splitter') : undefined}
                  extraBlockStyles={{ maxWidth: '50px' }}
                />
              </div>

              <div className="recipientsContainer">
                <div className="contentEditableAreaTitle">
                  <span>Recipients</span>
                </div>
                <div className="contentEditableAreaContainer">
                  <ContentEditable
                    className="contentEditable"
                    innerRef={textRef}
                    html={state} // innerHTML of the editable div
                    disabled={false} // use true to disable editing
                    onChange={(event) => highlight(event.target.value, values.splitter)} // handle innerHTML change
                    tagName="article"
                    placeholder="Recipients"
                  />

                  <div className="formErrorContainer">
                    {touched && getArrayOfRecipientsFromContentEditable(state, values.splitter).length === 0 && translations[interfaceLanguage].validation_required}
                  </div>
                </div>
              </div>
            </div> */}

            <div className="contactsFileContainer">
              <UploadFileButton
                name={getTranslationByLangOrEng(interfaceLanguage, 'choose_file')}
                onChange={(file) => {
                  setFieldValue('contactsFile', file)
                  handleFileDownloadAndParseData(file)
                }}
                formats=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                fieldName="contactsFile"
                label={getTranslationByLangOrEng(interfaceLanguage, 'download_recipients_file_label')}
                text={`${getTranslationByLangOrEng(interfaceLanguage, 'format')}: XLSX, CSV`}
              />

              {values.contactsFile instanceof File && (
              <div className="uploadedFileName">
                <FileIcon width={15} height={20} color="#0E9285" />
                <span>{values.contactsFile.name}</span>
              </div>
              )}
            </div>
          </div>
          )}
        </div>

        <div className={`${values.recipients && values.recipients.length > 0 ? 'recipientsListContainer' : 'recipientsListContainer hidden'}`}>
          <h3 className="recipientsListTitle">{getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_recipients_list_title')}</h3>
          <FieldArray
            name="recipients"
            render={(araryHelpers) => {
              bindPushMethod(araryHelpers);
              return (
                <div className="recipientsList">
                  {values.recipients.length > 0 && values.recipients.map((item: any, index) => (
                    <div key={`recipient:${item.id}`} className="recipientsListItem">
                      <span>{item.recipient}</span>
                      <button onClick={() => araryHelpers.remove(index)} className="deleteRecipientButton" type="button">
                        <DeleteIcon />
                      </button>
                    </div>
                  ))}
                </div>
              )
            }}
          />
        </div>
        {isDataFromFileDownloading && (<p>{`${getTranslationByLangOrEng(interfaceLanguage, 'file_data_downloading_text')}...`}</p>)}

        <div className="mailsCountContainer">
          <span>{`${getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_mails_count')}: ${values.recipients.length || 0}`}</span>
        </div>

        <div className="buttonsContainer">
          <SubmitButton extraBlockStyles={{ width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'next_button')}</SubmitButton>
        </div>

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <CreateMassMailingsFirstStepStyles>
      <Helmet>
        <title>{getTranslationByLangOrEng(interfaceLanguage, 'create_mass_mailings_page_title')}</title>
      </Helmet>
      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'create_mass_mailings_first_step_subheader_title')} />

      {!initialValues || isLoading ? <Loader /> : (
        <Formik
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}
    </CreateMassMailingsFirstStepStyles>
  );
}
