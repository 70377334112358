import styled from 'styled-components';

export const FieldHeaderStyles = styled.footer`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F0F5F5;
  padding: 0 16px;
  box-sizing: border-box;

  h3 {
    font-weight: 500;
    font-size: 15px;
    color: #000000;
  }

  .titleContainer {
    display: flex;
    align-items: center;

    & > div {
      width: 40px;
      margin-right: 16px;
      box-sizing: border-box;
    }
  }

  .buttons {
    height: 100%;
    display: flex;
    align-items: center;

    button, div {
      width: 24px;
      height: 24px;
      display: grid;
      place-items: center;
      margin-left: 16px;
    }
  }
`;
