import styled from 'styled-components';

export const FormsStyles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }

    .buttonsContainer {
      display: flex;
      align-items: center;

      button {
        width: fit-content;
        margin-left: 16px;

        &:first-child {
          margin-left: 0;

          div {
            display: flex;

            span {
              margin-left: 12px;
            }
          }
        }

        &:hover {
          path {
            transition: 0.3s all;
            fill: #0E9285;
          }
        }
      }
    }
  }

  .nameWrapper {
    background: green;
    display: flex;
    justify-content: center;
    align-content: center;
    padding: 4px 8px;
    border-radius: 67px;
  }

  .customUrlField {
    font-weight: 400;
    font-size: 14px;
    line-height: 130%;
    color: #0E9285;
    text-decoration: none;
  }

  .editButtonsContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
    }
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #F0F0F0;

    .searchField {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    .searchField::placeholder {
      font-size: 15px;
      color: #979797;
    }
  }

  .modalBody {
    .modalTitle {
      padding: 10px 16px;
      background: #F0F5F5;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      border: 1px solid #F0F5F5;
      border-radius: 4px 4px 0px 0px;
    }

    .modalButtonsWrap {
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 0px 0px 4px 4px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: 14px;
        color: #000000;
      }

      .deleteButton {
        border: 1px solid #FF0101;
        background: #FF0101;
        color: #FFFFFF;

        :hover {
          background: #FFFFFF;
          color: #FF0101;
        }
      }

      .confirmButton {
        border: 1px solid #0E9285;
        background: #0E9285;
        color: #FFFFFF;

        :hover {
          background: #FFFFFF;
          color: #0E9285;
        }
      }

      .declineButton {
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        color: #000000;

        :hover {
          color: #FFF;
          background: #C4C4C4;
        }
      }

      & > button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;
      }
    }

    .modalError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
      margin-bottom: 0;
    }
  }

  .updateAtWrapper {
    display: flex;
    flex-direction: column;

    span:first-child {
      margin-bottom: 4px;
    }
  }

  .statusWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    div {
      margin-bottom: 10px;
    }
  }

  .customConstructorField {
    font-weight: 500;
    font-size: 14px;
    color: #FFFFFF;
    text-decoration: none;

    div {
      transition: 0.3s all;
      border: 1px solid #0E9285;
      padding: 8px;
      background: #0E9285;
      border-radius: 4px;
      text-align: center;

      :hover {
        background: #FFFFFF;
        color: #0E9285;
      }
    }
  }
  
  .editLinks {
    display: flex;
  }

  .customEditLink {
    margin: 0 16px;
    font-weight: 500;
    font-size: 14px;
    color: #0E9285;
    text-decoration: none;

    div {
      min-width: 120px;
      transition: 0.3s all;
      border: 1px solid #0E9285;
      padding: 8px;
      background: #FFFFFF;
      border-radius: 4px;
      text-align: center;

      :hover {
        background: #0E9285;
        color: #FFFFFF;
      }
    }
  }

  .menu {
    position: relative;

    .menuList {
      z-index: 99;
      position: absolute;
      top: calc(50% + 15px);
      right: 16px;
      width: 260px;
      border-radius: 4px;
      border: 1px solid #E5E5E5;
      overflow: hidden;
      filter: drop-shadow(0px 0px 14px rgba(0, 0, 0, 0.14));

      list-style: none;
      font-size: 14px;
      color: #000000;

      li {
        height: 41px;
        background: #FFFFFF;
        border-bottom: 1px solid #E5E5E5;
        box-sizing: border-box;

        &:last-child {
          border-bottom: none;
        }
      }

      button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 16px;
        box-sizing: border-box;
        transition: background-color 0.3s;

        &:hover {
          background-color: #F0F5F5;
          transition: background-color 0.3s;
        }
      }

      span {
        margin-left: 12px;
      }
    }
  }
`;
