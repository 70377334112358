import React, {
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useEffect, useRef, useState,
} from 'react';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { getTranslationByLangOrEng } from '../../../i18n';
import { DeepArrayItem } from '../../../entities/DeepArrayItem';
import { filterDeepObjectArray, getNodesDeepArray } from '../../../utils';
import { useAppSelector } from '../../../state';
import {
  expandAllSelectedItemsAndTheirParents, expandSearchedItemsAndTheirParents,
  findParentNode, getNodesBySelectedCompany,
} from './utils';
import { useDebounce } from '../../../hooks/useDebounce';
import { MultiNodeSelectStyles } from './MultiNodeSelectStyles';
import { ArrowDownIcon, SearchIcon } from '../../../assets';
import { NodeFilterItem } from '../reviewsTableFilters/nodeFilter/additionalSettings';
import { markAllNodesWithAllSelectedChildren } from '../reviewsTableFilters/nodeFilter/utils';
import { MultiSelectNodeItem } from '../multiSelectINodeItem';
import { Loader } from '../../atoms';
import Checkbox from '../../form/checkbox/Checkbox';
import { CompaniesMainInfo } from '../../../entities';
import { Api } from '../../../api';

interface MultiNodeSelectProps {
  name: string;
  selectKey: string;
  search?: boolean;
  placeholder: string;
  id: number;
  companyID: number;
  companies: CompaniesMainInfo[];
}

export const MultiNodeSelect = ({
  name,
  selectKey,
  placeholder,
  search,
  id,
  companyID,
  companies,
}: MultiNodeSelectProps) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [open, setOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useState<string>('');
  const [nodesExpanded, setNodesExpanded] = useState<{[key: number]: boolean}>({});
  const [nodesFiltered, setNodesFiltered] = useState<boolean>(false);
  const [options, setOptions] = useState<DeepArrayItem[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [tree, setTree] = useState<boolean>(false);
  const [attachedNodes, setAttachedNodes] = useState<{company: string, companyID: number, nodeIDs: number[]}>();
  const [reQuery, setReQuery] = useState<boolean>(false);

  const optionsListRef = useRef<HTMLDivElement>(null);
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const nodesExpandedRef = useRef<{[key: number]: boolean}>({});
  const nodesFilteredRef = useRef<DeepArrayItem[]>([]);
  const nodesRef: MutableRefObject<DeepArrayItem[]> = useRef<DeepArrayItem[]>([]);
  const nodeNamesRef: MutableRefObject<NodeFilterItem[]> = useRef<NodeFilterItem[]>([]);

  const debouncedSearchParams = useDebounce(searchParams, 1500);

  function addToExpandedItemsList(id: number) {
    setNodesExpanded({ ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] });
    nodesExpandedRef.current = { ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] };
  }

  function clearExpandedItemsList() {
    setNodesExpanded({});
    nodesExpandedRef.current = {};
  }

  useClickOutside(optionsListRef, () => {
    setOpen(false);
  }, selectedValueFieldRef.current);

  useEffect(() => {
    nodesFilteredRef.current = options;
    setNodesFiltered(!nodesFiltered);
  }, [options]);

  const handleExpandRow = React.useCallback((id: number) => {
    if (nodesExpandedRef.current) {
      addToExpandedItemsList(id);
    }
  }, []);

  useEffect(() => {
    if (options) {
      if (search && debouncedSearchParams) {
        const nodesTree = filterDeepObjectArray(options, debouncedSearchParams, selectKey);
        nodesFilteredRef.current = nodesTree;
        setNodesFiltered(!nodesFiltered);
        clearExpandedItemsList();
        expandSearchedItemsAndTheirParents(nodesTree, addToExpandedItemsList);
      } else {
        nodesFilteredRef.current = [...options];
        setNodesFiltered(!nodesFiltered);
        clearExpandedItemsList();
        expandAllSelectedItemsAndTheirParents(options, addToExpandedItemsList);
      }
    }
  }, [debouncedSearchParams]);

  useEffect(() => {
    Api.getMultiTenancy(id).then((res) => {
      const resNodes = res.data.structures.map((structure) => {
        const company = companies.find((item) => item.id === structure.companyID)
        return { company: company?.name!, nodeIDs: structure.nodeIDs, companyID: structure.companyID }
      }).find((item) => item.companyID === companyID)
      setAttachedNodes(resNodes)
    })
  }, [reQuery, id])

  useEffect(() => {
    setLoading(true)
    if (attachedNodes) {
      getNodesBySelectedCompany(attachedNodes.companyID, nodesRef, nodeNamesRef).then(() => {
        const nodesArray = attachedNodes.nodeIDs && attachedNodes.nodeIDs.length > 0 ? nodeNamesRef.current?.map((node) => (attachedNodes.nodeIDs.includes(node.id) ? { ...node, selected: true } : node)) : nodeNamesRef.current;

        nodesArray.forEach((item) => {
          if (item.selected && item.deep !== 0) {
            findParentNode(nodesArray, item);
          }
        });

        const deepNodes = getNodesDeepArray(nodesArray);
        markAllNodesWithAllSelectedChildren(deepNodes);

        setOptions(deepNodes)
        setLoading(false)
      })
    }
  }, [id, reQuery, attachedNodes])

  useEffect(() => {
    setSearchParams('');
  }, [open]);

  return (
    <MultiNodeSelectStyles>
      {attachedNodes
        && (
          <div className="labelContainer">
            <label htmlFor={name}>
              {attachedNodes.company}
            </label>
            <div className="treeContainer">
              <Checkbox hideError name={`checkbox-${companyID}`} value={tree} onChange={() => setTree(!tree)} />
              <span>tree mode</span>
            </div>
          </div>
        )}

      <div className="selectField">
        <div className="selectedValue" onClick={() => setOpen(!open)} ref={selectedValueFieldRef}>
          <span className="placeholder">{placeholder}</span>
          <div className={open ? 'arrowDownActive' : ''}>
            <ArrowDownIcon />
          </div>
        </div>
        {open && (
          <div
            className="options"
            ref={optionsListRef}
          >
            {loading
              ? (<Loader margin={0} />)
              : (
                <>
                  {search
                  && (
                    <div className="searchWrapper">
                      <SearchIcon />
                      <input
                        type="text"
                        value={searchParams}
                        onChange={(e) => setSearchParams(e.target.value)}
                        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search')}
                      />
                    </div>
                  )}
                  {nodesExpandedRef.current && nodesFilteredRef.current.map((option, index) => (
                    <MultiSelectNodeItem
                      tree={tree}
                      id={id}
                      setReQuery={setReQuery}
                      reQuery={reQuery}
                      key={`itemKey:${[index]}`}
                      path={[index]}
                      option={option}
                      selectKey={selectKey}
                      childrenKey="children"
                      inputName={name}
                      expandedRows={nodesExpandedRef.current}
                      expandable={option.children.length > 0}
                      handleExpandRow={handleExpandRow}
                      setOpen={setOpen}
                      selectable
                      checkboxSelected={option.selected}
                      checkboxHasSelectedChildren={option.hasSelectedChildren}
                      checkboxAllChildrenSelected={option.allChildrenAreSelected}
                    />
                  ))}
                </>
              )}
          </div>
        )}
      </div>
    </MultiNodeSelectStyles>
  );
}
