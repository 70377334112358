import styled, { css } from 'styled-components';

export const TransparentButtonStyles = styled.div<{disabled: boolean}>`
  .linkContentWrapper {
    width: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    text-decoration: none;
  }
  
  a, button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 32px;
    background: ${({ disabled }) => (disabled ? '#FAFAFA' : '#FFFFFF')};
    border: ${({ disabled }) => (disabled ? '1px solid #E5E5E5' : '1px solid #0E9285')};
    box-sizing: border-box;
    border-radius: 4px;
    padding: 0 17px;
    transition: all 0.3s;

    span {
      font-size: 14px;
      color: ${({ disabled }) => (disabled ? 'rgb(154, 154, 154)' : '#0E9285')};
      transition: all 0.3s;
    }

    ${({ disabled }) => !disabled
      && css`
        &:hover {
          background: #0E9285;
          transition: all 0.3s;
    
          span {
            color: #FFFFFF;
            transition: all 0.3s;
          }
        }
      `}

    &.filled {
      background: #0E9285;

      span {
        color: #FFFFFF;
        transition: all 0.3s;
      }

      ${({ disabled }) => !disabled
        && css`
          &:hover {
            background: #FFFFFF;
            transition: all 0.3s;

            span {
              color: #0E9285;
              transition: all 0.3s;
            }
          }
        `}
    }
  }
`;
