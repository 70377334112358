import * as React from 'react';
import { TIcon } from './TIcon';

export const FileCompanyIcon: React.FC<TIcon> = React.memo(({ width = 384, height = 512, color = '#000' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 384 512"
    role="img"
    width={width}
    height={height}
  >
    <path fill={color} d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z" />
  </svg>
))
