import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const NotDecidedIcon: FC<TIcon> = React.memo(({ width = 22, height = 22, color = '#FF0101' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <g>
      <path fill="#f18908" d="M589,350c0-132-107-239-239-239S111,218,111,350s107,239,239,239S589,482,589,350z" />
      <g>
        <path
          fill="#FFFFFF"
          d="M412.9,462.9L238.1,288.1c-11.8-11.8-11.8-31.2,0-43l6-6c11.8-11.8,31.2-11.8,43,0l174.8,174.8 c11.8,11.8,11.8,31.2,0,43l-6,6C444.1,474.7,424.7,474.7,412.9,462.9z"
        />
        <path
          fill="#FFFFFF"
          d="M287.1,462.9l174.8-174.8c11.8-11.8,11.8-31.2,0-43l-6-6c-11.8-11.8-31.2-11.8-43,0L238.1,413.9 c-11.8,11.8-11.8,31.2,0,43l6,6C255.9,474.7,275.3,474.7,287.1,462.9z"
        />
      </g>
    </g>
  </svg>
));
