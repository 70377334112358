import React, { FC } from 'react'
import { useAppSelector } from '../../../../state';
import { EditUser } from '../../editUser';
import { Modal } from '../Modal';
import { EditUserModalStyles } from './EditUserModalStyles';

type TEditUserModal = {
    onClose: () => void;
    userId: number
}

export const EditUserModal: FC<TEditUserModal> = React.memo(({ onClose, userId }) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  return (
    <EditUserModalStyles>
      <Modal onClose={onClose}>
        <EditUser onClose={onClose} id={userId} />
      </Modal>
    </EditUserModalStyles>
  )
})
