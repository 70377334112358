import styled from 'styled-components';

export const UploadFileButtonStyles = styled.div`
  input {
    display: none;
  }
  
  .pathToSelectedFile {
    display: inline-block;
    font-size: 14px;
    color: #000;
    margin-top: 10px;
    margin-bottom: 8px;
  }
  
  .label {
    margin-bottom: 8px;
  }
`;
