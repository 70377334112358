import React from 'react';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { FieldSaveButtonStyles } from './FieldSaveButtonStyles';
import { useAppSelector } from '../../../state';

interface FieldSaveButtonProps {
  isLoading: boolean,
  touched: any,
  errors: any,
  submitError: string,
  submitSuccess: string,
}

export default function FieldSaveButton({
  submitSuccess, submitError, errors, touched, isLoading,
}: FieldSaveButtonProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <FieldSaveButtonStyles>
      <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'save_field_button')}</SubmitButton>

      <div className="messageContainer">
        {submitError && <div className="formErrorContainer"><span>{submitError}</span></div>}
        {submitSuccess && <div className="formSuccessContainer"><span>{submitSuccess}</span></div>}
      </div>
    </FieldSaveButtonStyles>
  );
}
