import styled from 'styled-components';

export const ToggleSwitcherStyles = styled.div`
  label {
    span {
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
    }
  }
  
  input {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .toggleSwitcherWrapper {
    display: flex;
    align-items: center;
  }

  .customizedToggleSwitcher {
    position: relative;
    width: 47px;
    height: 24px;
    margin-right: 12px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 12px;
    
    .circle {
      position: absolute;
      top: 1px;
      left: 2px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: #E5E5E5;
      transition: all 0.3s;
      
      &.checked {
        left: 24px;
        background-color: #0E9285;
        transition: all 0.3s;
      }
    }
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
