import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const StripArrowRight: FC<TIcon> = React.memo(({ color = 'black' }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M14 0H16V12H14V0ZM6 5H0V7H6V12L12 6L6 0V5Z" fill={color} />
  </svg>
));
