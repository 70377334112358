import { FileUploadPermissionModuleStyles } from './FileUploadPermissionModuleStyles';
import React, { CSSProperties, useRef } from 'react';
import {
  DocumentIcon, ImageIcon,
  JustPlusIcon, MicrophoneIcon,
  VideoIcon,
} from '../../../assets';
import { ErrorMessage, FieldArray, useFormikContext } from 'formik';
import { FormErrorMessage } from '../../atoms';

interface FileUploadPermissionModuleProps {
  name: string;
  index: number;
  values: any;
  error: string | undefined;
  setFieldValue: (key: string, value: File | undefined) => void;
  required: boolean;

  question?: string;
  extraBlockStyles?: CSSProperties;
}

export default function FileUploadPermissionModule({
  name,
  index,
  values,
  error,
  setFieldValue,
  required,

  question,
  extraBlockStyles,
}: FileUploadPermissionModuleProps) {
  const { touched } = useFormikContext();

  const selectedType = useRef<string>(null);

  const fileInput = useRef<any>(null);
  const imageInput = useRef<any>(null);
  const audioInput = useRef<any>(null);
  const videoInput = useRef<any>(null);

  const refs = {
    audio: fileInput,
    document: imageInput,
    image: audioInput,
    video: videoInput,
  };

  const handleClick = (type: 'audio' | 'document' | 'image' | 'video') => {
    // @ts-ignore
    selectedType.current = type;
    const ref = refs[type];

    if (ref.current) {
      // @ts-ignore
      ref.current.click();
    }
  };

  function getFileTypeImage(type: 'audio' | 'document' | 'image' | 'video') {
    const types = {
      audio: <MicrophoneIcon color="#636363" />,
      document: <DocumentIcon color="#636363" />,
      image: <ImageIcon color="#636363" />,
      video: <VideoIcon color="#636363" />,
    };

    return types[type];
  }

  function getFileTypeFormats(type: 'audio' | 'document' | 'image' | 'video') {
    const types = {
      audio: '.mp3, .wav',
      document: '.pdf',
      image: '.png, .jpg',
      video: '.mp4',
    };

    return types[type];
  }

  return (
    <FileUploadPermissionModuleStyles style={extraBlockStyles}>
      {question && (
        <div className="titleContainer">
          {required && <span>*</span>}
          <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question}`}</h3>
        </div>
      )}

      <FieldArray
        name={`extraFields[${index}].answer`}
        render={({ remove, push }) => (
          <div className="fieldBody">
            <div className="options">
              {values.options.filter((option: { value: string, selected: boolean }) => option.selected).map((field: any, subIndex: number) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="checkboxFieldContainer" key={subIndex}>
                  <input
                    name={`${name}[${field.value}]`}
                    autoComplete="false"
                    className="defaultFilePicker"
                    ref={refs[field.value as 'audio' | 'document' | 'image' | 'video']}
                    type="file"
                    accept={getFileTypeFormats(field.value)}
                    onChange={(event) => {
                      if (event.currentTarget.files?.[0]) {
                        push({ key: selectedType.current, value: event.currentTarget.files[0] });
                      }
                    }}
                  />
                  <button
                    type="button"
                    onClick={() => handleClick(field.value)}
                    className={values.answer?.[field.value] !== undefined ? 'selected' : ''}
                  >
                    <div className="iconContainer">
                      {getFileTypeImage(field.value)}
                    </div>
                  </button>
                </div>
              ))}
            </div>
            <div className="formErrorContainer">
              {error && <span>{error}</span>}
              {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
            </div>

            <div className="answers" style={values.answer?.filter((item: ({key: string, value: File} | undefined)) => item?.value).length > 0 ? { marginBottom: 42 } : {}}>
              {values.answer?.map((item: ({key: string, value: File} | undefined), index: number) => {
                if (item?.value) {
                  return (
                    <div className="fileItem">
                      <span>{item.key.charAt(0).toUpperCase() + item.key.substring(1)}</span>
                      <p>{item.value.name}</p>
                      <button type="button" onClick={() => remove(index)}>
                        <JustPlusIcon />
                      </button>
                    </div>
                  );
                }

                return null;
              })}
            </div>
          </div>
        )}
      />
    </FileUploadPermissionModuleStyles>
  );
}
