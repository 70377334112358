import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { getTranslationByLangOrEng } from '../../../../../i18n';
import { MassMailingsSmsStyles } from './MassMailingsSmsStyles';
import SubmitButton from '../../../../../components/form/submitButton/SubmitButton';
import TextArea from '../../../../../components/form/textArea/TextArea';
import {
  useAppDispatch, useAppSelector, MassMailingsSmsRedux, setMassMailingsSms,
} from '../../../../../state';
import InputField from '../../../../../components/form/inputField/InputField';
import { Helmet } from 'react-helmet-async';
import { getErrorMessage, handleKeyUp } from '../../../../../utils';
import { SubHeader, TransparentButton } from '../../../../../components';

interface FormValues {
  senderAccount: string;
  text: string;
}

const defaultInitialValues: FormValues = {
  senderAccount: 'Voicer',
  text: '',
};

export default function MassMailingsSms() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { massMailingMainInfo, massMailingsSms } = useAppSelector((state) => state.massMailing);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();

  function getInitialData() {
    if (massMailingsSms) {
      const initialValuesFromRedux: FormValues = {
        senderAccount: massMailingsSms.senderAccount,
        text: massMailingsSms.text || '',
      };
      setInitialValues(initialValuesFromRedux);
    } else {
      setInitialValues(defaultInitialValues);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  const validationSchema = Yup.object({
    senderAccount: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    text: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
  });

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    const recipientsPhones = massMailingMainInfo?.recipients?.map((item) => item.recipient)?.map((item) => item.replace('+', ''));
    const data: MassMailingsSmsRedux = {
      senderAccount: values.senderAccount,
      text: values.text,
      phones: recipientsPhones || [],
    };
    setSubmitting(false);
    setIsLoading(true);
    dispatch(setMassMailingsSms(data))
    navigate('/massMailings/create/step-3');
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formFieldsContainer">
        <div className="senderAccountInputContainer">
          <span>{getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_sender_account')}</span>
          <InputField
            name="senderAccount"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('senderAccount', setErrorMessage, errorMessage)}
            value={values.senderAccount}
            error={typeof errorMessage === 'object' ? getErrorMessage('senderAccount', errorMessage) : undefined}
            extraBlockStyles={{ maxWidth: '428px', width: '100%' }}
            placeholder="Enter sender name"
            disabled
          />
        </div>
        <div className="textContainer">
          <span>
            {getTranslationByLangOrEng(interfaceLanguage, 'sms_text')}
            :
            {'\n'}
            {`${values.text.length} symbols, 1 SMS`}
          </span>
          <TextArea
            name="text"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('text', setErrorMessage, errorMessage)}
            placeholder=""
            value={values.text}
            error={typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined}
            extraBlockStyles={{ maxWidth: '512px', width: '100%', height: '205px' }}
          />
        </div>
      </div>
      <div className="submitButtonContainer">
        <SubmitButton isLoading={isLoading} extraButtonStyles={{ width: '440px' }}>
          {getTranslationByLangOrEng(interfaceLanguage, 'preview_button')}
        </SubmitButton>
      </div>

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  return (
    <MassMailingsSmsStyles>
      <Helmet>
        <title>{getTranslationByLangOrEng(interfaceLanguage, 'create_mass_mailings_page_title')}</title>
      </Helmet>
      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'create_sms_mass_mailings_second_step_subheader_title')}>
        <TransparentButton handleClick={() => navigate('/massMailings/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
      </SubHeader>

      {initialValues && (
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {renderForm}
      </Formik>
      )}

    </MassMailingsSmsStyles>
  );
}
