import React, { useMemo, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HeaderStyles } from './HeaderStyles';
import {
  useAppDispatch, useAppSelector, setInterfaceLanguage, setSidebarFullWidth, changeUser,
} from '../../../state';
import {
  ArrowDownIcon, HelpIcon,
  LanguagesIcon, LogoIcon,
  LogoutIcon, MenuIcon,
  ProfileIcon,
} from '../../../assets';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { availableInterfaceLanguages } from '../../../constants';
import { useWindowSize } from 'usehooks-ts';
import { FeedbackModal } from '../../organisms';

interface LanguageMenuProps {
  handleClose: () => void;
}

function LanguageMenu({ handleClose }: LanguageMenuProps) {
  const dispatch = useAppDispatch();

  return (
    <ul className="topMenu">
      {availableInterfaceLanguages.map((language) => (
        <li key={language.code}>
          <button
            className="select"
            type="button"
            onClick={() => {
              dispatch(setInterfaceLanguage(language.code));
              localStorage.setItem('selectedLanguage', language.code);
              handleClose();
            }}
          >
            {language.code}
          </button>
        </li>
      ))}
    </ul>
  )
}

export const Header = React.memo(() => {
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { data: user, loggedIn } = useAppSelector((state) => state.user);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { fullWidth } = useAppSelector((state) => state.sidebar);
  const { width } = useWindowSize();

  const [openProfileModal, setOpenProfileModal] = useState<boolean>(false);
  const [languageMenuOpen, setLanguageMenuOpen] = useState<boolean>(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false);

  const topMenuRef = useRef<HTMLDivElement>(null);
  const languageMenuRef = useRef<HTMLDivElement>(null);

  const tabs = useMemo(() => ([
    {
      key: 'profile',
      icon: <ProfileIcon />,
      text: getTranslationByLangOrEng(interfaceLanguage, 'sidebar_profile'),
      url: '/profile',
    },
  ]), [interfaceLanguage]);

  function handleMenuButton() {
    dispatch(setSidebarFullWidth(!fullWidth));
  }

  const logout = () => {
    localStorage.removeItem('logged_in');
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expires_at');
    dispatch(changeUser(null));
  };

  useClickOutside(topMenuRef, () => {
    setOpenProfileModal(false);
  });

  useClickOutside(languageMenuRef, () => {
    setLanguageMenuOpen(false);
  });

  return (
    <HeaderStyles tabletVersion={width <= 768} mobileVersion={width <= 500}>
      <div className={width > 768 ? 'leftPart' : 'leftPart compact'}>
        {!pathname.startsWith('/auth') && (
          <button className="menuButton" type="button" onClick={handleMenuButton}>
            <MenuIcon />
          </button>
        )}
        <Link to="/">
          <LogoIcon />
        </Link>
      </div>

      <div className="rightBlock">
        {!pathname.startsWith('/auth') && loggedIn && width > 768 && (
          <div className="feedbackModal">
            <button title="Feedback form" type="button" onClick={() => setOpenFeedbackModal(!openFeedbackModal)}>
              <HelpIcon />
            </button>
          </div>
        )}

        {width > 768 && (
        <div className="languageSelect" ref={languageMenuRef}>
          <button
            onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
            type="button"
            className={languageMenuOpen ? 'active select' : 'select'}
          >
            <span>{availableInterfaceLanguages.find((item) => item.code === interfaceLanguage)?.code}</span>
            <ArrowDownIcon />
          </button>

          {languageMenuOpen && <LanguageMenu handleClose={() => setLanguageMenuOpen(false)} />}
        </div>
        )}

        {!pathname.startsWith('/auth') && loggedIn && width > 768 && (
        <div className="profileButtonContainer">
          <Link className="profileButton select" to="/profile">
            {user ? user.name : '?'}
          </Link>

          <button type="button" onClick={() => setOpenProfileModal(!openProfileModal)}>
            <ArrowDownIcon />
          </button>
        </div>
        )}
      </div>

      {width <= 768 && (
      <div className="rightPart">
        {!pathname.startsWith('/auth') && loggedIn && (
          <div className="feedbackModal">
            <button title="Feedback form" type="button" onClick={() => setOpenFeedbackModal(!openFeedbackModal)}>
              <HelpIcon />
            </button>
          </div>
        )}
        <div className="languageSelect" ref={languageMenuRef}>
          <button
            onClick={() => setLanguageMenuOpen(!languageMenuOpen)}
            type="button"
            className={languageMenuOpen ? 'selectMobile active' : 'selectMobile'}
          >
            <LanguagesIcon />
          </button>

          {languageMenuOpen && <LanguageMenu handleClose={() => setLanguageMenuOpen(false)} />}
        </div>

        {!pathname.startsWith('/auth') && loggedIn && (
        <div className="profileButtonContainer">
          <Link className="profileButtonMobile" to="/profile">
            <ProfileIcon />
          </Link>

          <button type="button" onClick={() => setOpenProfileModal(!openProfileModal)}>
            <ArrowDownIcon />
          </button>
        </div>
        )}
      </div>
      )}

      {!pathname.startsWith('/auth') && loggedIn && openProfileModal && (
        <div className="topMenu" ref={topMenuRef}>
          {tabs.map((tab) => (
            <Link to={tab.url} key={tab.key}>
              <div className="iconContainer">{tab.icon}</div>
              {fullWidth && <span>{tab.text}</span>}
            </Link>
          ))}

          <button
            type="button"
            onClick={logout}
          >
            <span className="iconContainer"><LogoutIcon /></span>
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'sidebar_exit')}</span>
          </button>
        </div>
      )}

      <FeedbackModal active={openFeedbackModal} onClose={() => setOpenFeedbackModal(false)} />
    </HeaderStyles>
  );
})
