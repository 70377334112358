import React, {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import autosize from 'autosize';
import { ErrorMessage, useFormikContext } from 'formik';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { SimpleFieldStyles } from './SimpleFieldStyles';
import { FormErrorMessage } from '../../atoms';

interface SimpleFieldProps {
  index: number;
  name: string;
  errorName: string;
  value: string;
  onChange: (key: string, value: string) => void;
  error: string | undefined;
  extraBlockStyles?: CSSProperties;
  onKeyUp?: () => void;
  disabled?: boolean;

  title?: string;
  question?: string;
  placeholder: string;
  inputType: 'text' | 'textarea' | 'phoneNumber';
  required: boolean;

  hideError?: boolean;
}

export default function SimpleField({
  index,
  name,
  errorName,
  error,
  value,
  onChange,
  onKeyUp,
  extraBlockStyles,
  disabled,

  title,
  question,
  placeholder,
  inputType,
  required,

  hideError = false,
}: SimpleFieldProps) {
  const { touched } = useFormikContext();

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [textareaRef.current]);

  function getField(type: 'text' | 'textarea' | 'phoneNumber') {
    switch (type) {
      case 'text':
        return (
          <input
            name={name}
            type="text"
            value={value}
            onChange={(event) => onChange(name, event.target.value)}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            disabled={disabled}
            className={disabled ? 'disabled' : ''}
          />
        );
      case 'textarea':
        return (
          <textarea
            name={name}
            ref={textareaRef}
            value={value}
            onChange={(event) => onChange(name, event.target.value)}
            onKeyUp={onKeyUp}
            placeholder={placeholder}
            disabled={disabled}
            className={disabled ? 'disabled' : ''}
          />
        );
      case 'phoneNumber':
        return (
          <PhoneInput
            country="us"
            value={value}
            onChange={(value) => onChange(name, value)}
            placeholder={placeholder}
            disabled={disabled}
            enableSearch
          />
        );
    }
  }

  return (
    <SimpleFieldStyles style={extraBlockStyles}>
      {question && (
        <div className="titleContainer">
          {required && <span>*</span>}
          <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question}`}</h3>
        </div>
      )}

      <div className="inputContainer">
        <div className="inputRaw">
          {getField(inputType)}
        </div>
        {!hideError && (
          <div className="formErrorContainer">
            {error && <span>{error}</span>}
            {!error && <ErrorMessage name={name} component={FormErrorMessage} />}
          </div>
        )}
      </div>
    </SimpleFieldStyles>
  );
}
