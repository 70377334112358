import styled from 'styled-components';

export const FooterStyles = styled.footer`
  width: 100%;
  height: 48px;
  z-index: 999;
  display: grid;
  place-items: center;
  background-color: #373737;
  
  p {
    margin: 0;
    font-size: 15px;
    color: #979797;
  }
`;
