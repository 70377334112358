import React, { useEffect, useRef, useState } from 'react';
import { FormStyles } from './FormStyles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { arrayUniqueByKey, getErrorMessage, handleKeyUp } from '../../../utils';
import * as Yup from 'yup';
import {
  IFormDocument, IExtraField, IColor, IForm, ILanguage,
} from '../../../entities';
import {
  FieldArray, Form as FormikForm, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { fieldsValidation, IFormField } from './formFields';
import SimpleField from '../../../components/clientForm/simpleField/SimpleField';
import CheckboxField from '../../../components/clientForm/checkboxField/CheckboxField';
import RadioField from '../../../components/clientForm/radioField/RadioField';
import ScaleField from '../../../components/clientForm/scaleField/ScaleField';
import { mapIColorsToStringsArray, mapIColorToString } from '../../../mappers';
import FileUploadPermissionModule
  from '../../../components/clientForm/fileUploadPermissionModule/FileUploadPermissionModule';
import SocialNetworksModule from '../../../components/clientForm/socialNetworksModule/SocialNetworksModule';
import { ArrowDownIcon } from '../../../assets';
import { useClickOutside } from '../../../hooks/useClickOutside';
import SquareCheckbox from '../../../components/form/squareCheckbox/SquareCheckbox';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { TransparentButton } from '../../../components/atoms/transparentButton/TransparentButton';
import { Api } from '../../../api';
import {
  setSelectedCompany,
  setNotSavedColors,
  setNotSavedDocuments,
  setNotSavedExtraFields,
  setNotSavedForm, setNotSavedSendButton,
  setNotSavedThankYouPage,
  useAppDispatch,
  useAppSelector,
} from '../../../state';

interface IFormValues {
  extraFields: IExtraField[];
  documents: (IFormDocument & { selectedInPreview: boolean })[];
}

const renderCount = 0;

const defaultMaxValue = {
  color: 10,
  number: 5,
  star: 5,
};
const defaultDefaultValue = {
  color: 1,
  number: 0,
  star: 0,
};

export default function Form() {
  const { id, nodeId, formId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<IFormValues>();
  const [submitError, setSubmitError] = useState<string>('');
  const [languages, setLanguages] = useState<string[]>();
  const [openLanguagesList, setOpenLanguagesList] = useState<boolean>(false);
  const [formLogo, setFormLogo] = useState<string>();
  const [formLogoLocation, setFormLogoLocation] = useState<string>('left');
  const [formName, setFormName] = useState<string>('');
  const [formTitle, setFormTitle] = useState<string>();
  const [formColors, setFormColors] = useState<IColor[]>();
  const [formBackgroundColor, setFormBackgroundColor] = useState<IColor>();
  const [nodeAddress, setNodeAddress] = useState<string>();
  const [addressMustBeShown, setAddressMustBeShown] = useState<boolean>(false);
  const [scaleSettings, setScaleSettings] = useState<IExtraField[]>();
  const [mainScaleAnswer, setMainScaleAnswer] = useState<number>();
  const [mainScaleSettings, setMainScaleSettings] = useState<IExtraField>();
  const [actualBackgroundColor, setActualBackgroundColor] = useState<IColor>();
  const [actualMascot, setActualMascot] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [selectedNetwork, setSelectedNetwork] = useState<boolean>(false);
  const [defaultMascot, setDefaultMascot] = useState<string>();

  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const selectedNetworkRef = useRef<number | undefined>(undefined);
  const networksRef = useRef<string[]>([]);
  const companyLanguagesRef = useRef<ILanguage[]>([]);

  const { colorScaleSettings, numberScaleSettings, starScaleSettings } = useAppSelector((state) => state.scaleSettings);
  const {
    notSavedForm, notSavedSendButton, notSavedDocuments, notSavedColors, notSavedThankYouPage, notSavedExtraFields,
  } = useAppSelector((state) => state.notSavedForm);
  const { selectedNode, selectedCompany } = useAppSelector((state) => state.company);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    if (notSavedForm) {
      setDefaultMascot(notSavedForm.mainMascot?.url);
    }
  }, [notSavedForm]);

  function getScaleFieldSettings(fieldsList: IExtraField[]) {
    const allFormScales = fieldsList.filter((field) => field.type.startsWith('scale'));
    const mainScale = allFormScales.find((field) => field.mainScale) || allFormScales[0];

    setScaleSettings(allFormScales);
    setMainScaleSettings(mainScale);
  }

  async function fillAllData(data: IForm) {
    if (!selectedCompany) {
      const res = await Api.getCompanyById(+id!);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        dispatch(setSelectedCompany(res.data));
      }
    }

    const colorsList: IColor[] = [...(selectedCompany?.colors || []), ...(data.colors || [])];

    const titleField: IExtraField | undefined = data.extraFields!.find((field) => field.type === 'title');

    if (titleField) {
      setFormTitle(titleField.question);
      setAddressMustBeShown(titleField.nodeAddressMustBeShown || false);
    }

    setFormLogo(typeof data.logo === 'string' ? data.logo : data.logo?.url);
    setFormLogoLocation(data.logoLocation);
    setFormBackgroundColor(data.backgroundColor);

    if (selectedNode) {
      setNodeAddress(selectedNode?.address?.value || '');
    } else {
      const res = await Api.getNodeById(+nodeId!);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        setNodeAddress(res.data?.address?.value || '');
      }
    }

    setFormColors(arrayUniqueByKey<IColor>('name', data.backgroundColor ? [...colorsList, data.backgroundColor] : colorsList));
    getScaleFieldSettings(data.extraFields!);
    networksRef.current = data.extraFields!.find((item) => item.type === 'networks')?.options?.filter((option) => option.selected).map((item) => item.value) || [];

    const fieldsList = data.extraFields!.filter((field) => field.type !== 'title');
    const networksFieldIndex = fieldsList.findIndex((item) => item.type === 'networks');

    if (networksFieldIndex >= 0) {
      fieldsList[networksFieldIndex] = {
        ...fieldsList[networksFieldIndex],
        answer: fieldsList[networksFieldIndex].options?.filter((option) => option.selected).map((option) => ({
          key: option.value,
          value: '',
        })),
      };
    }

    setInitialValues({
      extraFields: fieldsList,
      documents: data.documents?.map((item) => ({ ...item, selectedInPreview: false })) || [],
    });
  }

  useClickOutside(optionsListRef, () => {
    setOpenLanguagesList(false);
  }, selectedValueFieldRef.current);

  useEffect(() => {
    window.scrollTo(0, 0);

    if (id) {
      Api.getCompanyLanguages(+id!).then((res) => {
        companyLanguagesRef.current = (res.statusCode >= 200 && res.statusCode < 300) ? res.data : [];
      });

      if (formId) {
        if (notSavedForm) {
          fillAllData({
            ...notSavedForm,
            extraFields: notSavedExtraFields,
            documents: notSavedDocuments,
            colors: notSavedColors,
            sendButton: notSavedSendButton,
            thankYouPage: notSavedThankYouPage,
          });
        } else {
          Api.getFormById(+formId).then((res) => {
            if (res.statusCode >= 200 && res.statusCode < 300) {
              const {
                extraFields, documents, thankYouPage, sendButton, colors, ...rest
              } = res.data;

              dispatch(setNotSavedForm(rest));
              dispatch(setNotSavedExtraFields(extraFields || []));
              dispatch(setNotSavedDocuments(documents || []));
              dispatch(setNotSavedThankYouPage(thankYouPage));
              dispatch(setNotSavedSendButton(sendButton));
              dispatch(setNotSavedColors(colors || []));
              fillAllData(res.data);
            }
          });
        }
      } else if (notSavedForm) {
        fillAllData({
          ...notSavedForm,
          extraFields: notSavedExtraFields,
          documents: notSavedDocuments,
          colors: notSavedColors,
          sendButton: notSavedSendButton,
          thankYouPage: notSavedThankYouPage,
        });
      } else {
        navigate(`/companies/${id}/nodes/${nodeId}/forms`);
      }
    }
  }, [id, formId]);

  useEffect(() => {
    const background = mainScaleAnswer ? mainScaleSettings?.backgroundColors?.[mainScaleAnswer - 1] : formBackgroundColor;
    const mascot = mainScaleAnswer ? mainScaleSettings?.mascots?.[mainScaleAnswer ? (mainScaleAnswer - 1) : 0] : defaultMascot;

    if (!actualBackgroundColor || mainScaleSettings?.changeBackgroundColor) {
      setActualBackgroundColor(background);
    }
    if (!actualMascot || mainScaleSettings?.changeMascot) {
      setActualMascot(typeof mascot === 'object' ? mascot.url : mascot);
    }
  }, [mainScaleAnswer, mainScaleSettings?.type]);

  const validationSchema = Yup.object({
    extraFields: Yup.array().of(Yup.lazy((obj: IFormField | undefined) => (obj && fieldsValidation[obj?.type]) || Yup.object()) as any),
    documents: Yup.array().of(Yup.lazy((obj: IFormDocument | undefined) => (obj && fieldsValidation[obj?.type]) || Yup.object()) as any),
  });

  function onSubmit(
    values: IFormValues,
    { setSubmitting }: FormikHelpers<IFormValues>,
  ) {
    setSubmitting(false);

    if (pathname.includes('preview')) {
      setSuccess(true);
    }

    // TODO: add send form logic
  }

  function getScaleColorsByType(type: 'color' | 'number' | 'star') {
    const colorsByType = {
      color: {
        colors: (colorScaleSettings && colorScaleSettings.scaleGradientColors.length > 1) ? mapIColorsToStringsArray(colorScaleSettings.scaleGradientColors) : ['#EB5757', '#EF9644', '#F4E02D', '#18ECD6'],
        pointerColor: (colorScaleSettings && colorScaleSettings.scalePointerColor) ? mapIColorToString(colorScaleSettings.scalePointerColor) : '#EB5757',
      },
      number: {
        numbersColor: (numberScaleSettings && numberScaleSettings.numbersColor) ? mapIColorToString(numberScaleSettings.numbersColor) : '#000',
        selectedButtonColor: (numberScaleSettings && numberScaleSettings.selectedButtonColor) ? mapIColorToString(numberScaleSettings.selectedButtonColor) : '#eee',
      },
      star: {
        selectedStarColor: (starScaleSettings && starScaleSettings.selectedStarColor) ? mapIColorToString(starScaleSettings.selectedStarColor) : '#FF8C19',
        notSelectedStarBackground: (starScaleSettings && starScaleSettings.notSelectedStarBackground) ? mapIColorToString(starScaleSettings.notSelectedStarBackground) : '#fff',
        notSelectedStarBorder: (starScaleSettings && starScaleSettings.notSelectedStarBorder) ? mapIColorToString(starScaleSettings.notSelectedStarBorder) : '#FF8C19',
      },
    };

    return colorsByType[type];
  }

  function getField(
    field: { type: any; },
    setFieldValue: (key: string, value: any) => void,
    index: number,
    values: any,
    errors: any,
    handleChange: ((e: React.ChangeEvent<any>) => void) | undefined,
  ) {
    function createSimpleField(
      field: IExtraField,
      inputType: 'text' | 'textarea' | 'phoneNumber',
    ) {
      return (
        <SimpleField
          index={index}
          name={`extraFields[${index}].answer`}
          errorName={`extraFields[${index}].answer`}
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp(`extraFields[${index}].answer`, setErrorMessage, errorMessage)}
          value={values.extraFields[index].answer}
          error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].answer`, errorMessage) : undefined}
          question={values.extraFields[index].question}
          placeholder={values.extraFields[index].placeholder}
          inputType={inputType}
          required={field.required || false}
        />
      );
    }

    function createCheckboxField(
      field: IExtraField,
    ) {
      return (
        <CheckboxField
          index={index}
          values={values}
          name={`extraFields[${index}].question`}
          optionsName={`extraFields[${index}].options`}
          optionsError={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].options`, errorMessage) : undefined}
          handleChangeOptionsState={handleChange!}
          question={values.extraFields[index].question}
          required={field.required || false}
        />
      );
    }

    function createRadioField(
      field: IExtraField,
    ) {
      return (
        <RadioField
          index={index}
          values={values}
          optionsName={`extraFields[${index}].options`}
          optionsError={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].options`, errorMessage) : undefined}
          handleChangeOptionsState={setFieldValue}
          question={values.extraFields[index].question}
          required={field.required || false}
          handleChangeChoiceDescription={setFieldValue}
        />
      );
    }

    function createScaleField(
      field: IExtraField,
    ) {
      return (
        <ScaleField
          question={field.question}
          scaleType={field.selectedOption || 'color'}
          signatureMin={field.signatureMin}
          signatureMax={field.signatureMax}
          colors={getScaleColorsByType(field.selectedOption ? field.selectedOption as 'number' | 'color' | 'star' : 'color')}
          maxValues={field.maxValue || defaultMaxValue[(field.selectedOption as 'number' | 'color' | 'star' | undefined) || 'color']}
          defaultValues={field.defaultValue || defaultDefaultValue[(field.selectedOption as 'number' | 'color' | 'star' | undefined) || 'color']}
          showDefaultValue={field.showDefaultValue}
          value={+values.extraFields[index].answer}
          handleChange={(value) => {
            setFieldValue(`extraFields[${index}].answer`, typeof value === 'number' ? `${value}` : `${value![0]}`);
            if (mainScaleSettings && field.id === mainScaleSettings.id) {
              setMainScaleAnswer(typeof value === 'number' ? value : value![0]);
            }
          }}
          required={field.required || false}
          index={index}
        />
      );
    }

    function createFileUploadPermissionModule(
      field: IExtraField,
    ) {
      return (
        <FileUploadPermissionModule
          name={`values.extraFields[${index}].answer`}
          index={index}
          values={field}
          error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].options`, errorMessage) : undefined}
          setFieldValue={setFieldValue}
          required={field.required || false}
          question={values.extraFields[index].question}
        />
      );
    }

    function createSocialNetworksModule(
      field: IExtraField,
    ) {
      function handleClick(index: number) {
        selectedNetworkRef.current = selectedNetworkRef.current === index ? undefined : index;
        setSelectedNetwork(!selectedNetwork);
      }

      return (
        <SocialNetworksModule
          name={`values.extraFields[${index}].answer`}
          index={index}
          values={field}
          error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].options`, errorMessage) : undefined}
          setFieldValue={setFieldValue}
          required={field.required || false}
          handleClick={handleClick}
          selectedNetwork={selectedNetworkRef.current === undefined ? undefined : networksRef.current[selectedNetworkRef.current] as 'email' | 'sms' | 'call' | 'telegram' | 'whatsapp'}
          question={values.extraFields[index].question}
        />
      );
    }

    switch (field.type) {
      case 'name':
        return createSimpleField(values.extraFields[index], 'text');
      case 'email':
        return createSimpleField(values.extraFields[index], 'text');
      case 'phone_number':
        return createSimpleField(values.extraFields[index], 'phoneNumber');
      case 'extra_question':
        return createSimpleField(values.extraFields[index], 'textarea');
      case 'checkbox':
        return createCheckboxField(values.extraFields[index]);
      case 'radio':
        return createRadioField(values.extraFields[index]);
      case 'scale':
        return createScaleField(values.extraFields[index]);
      case 'upload_files':
        return createFileUploadPermissionModule(values.extraFields[index]);
      case 'networks':
        return createSocialNetworksModule(values.extraFields[index]);
    }
  }

  function renderForm({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<IFormValues>) {
    return (
      <FormikForm>
        <div className="body">
          <div className="header">
            <div className="languageSelect" ref={selectedValueFieldRef}>
              <button className={openLanguagesList ? 'selectedLanguage opened' : 'selectedLanguage'} type="button">
                {`${companyLanguagesRef.current.find((lang) => lang.id === notSavedForm?.language_id)?.icon} ${companyLanguagesRef.current.find((lang) => lang.id === notSavedForm?.language_id)?.name}`}
                <ArrowDownIcon color="#000000" />
              </button>
            </div>
          </div>

          <div className="topSection">
            <div className={`logoContainer ${formLogoLocation || 'left'}`}>
              <div className={`logo ${formLogoLocation || 'left'}`}>
                <img src={formLogo} alt={formName} />
                <h1>{formName}</h1>
                <div className="emptyBlock" />
              </div>
            </div>

            <div className="titleContainer">
              {addressMustBeShown && <p className="companyAddress">{nodeAddress}</p>}
              {formTitle && (<p className="title">{formTitle}</p>)}
            </div>

            {actualMascot && (
              <div className="mascotContainer">
                <img src={actualMascot} alt="Main mascot" />
              </div>
            )}
          </div>

          <div className="extraFieldListContainer">
            <FieldArray
              name="extraFields"
              render={() => (
                <div className="formExtraFields">
                  <div className="extraFieldsList">
                    {values.extraFields.map((field, index) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <div key={index} className="extraFieldContainer">
                        <div className="fields">
                          {getField(field, setFieldValue, index, values, errors, handleChange)}

                          {(field.type === 'networks' && selectedNetworkRef.current !== undefined) && (
                          <SimpleField
                            index={index}
                            name={`extraFields[${index}].answer[${selectedNetworkRef.current}].value`}
                            errorName={`extraFields[${index}].answer`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`extraFields[${index}].answer[${selectedNetworkRef.current}.value]`, setErrorMessage, errorMessage)}
                            value={values.extraFields[index].answer[selectedNetworkRef.current].value}
                            error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].answer`, errorMessage) : undefined}
                            placeholder={`${values.extraFields[index].answer[selectedNetworkRef.current].key}...`}
                            inputType="text"
                            required={false}
                          />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              )}
            />

            <FieldArray
              name="documents"
              render={() => (
                <div className="formExtraFields">
                  <div className="extraFieldsList">
                    {values.documents.map((field, index) => {
                      function getValueFromOptions(key: string) {
                        return field.options?.find((option) => option.key === key)?.value;
                      }

                      const name = getValueFromOptions('file_description');
                      const url = getValueFromOptions('file_url');

                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <SquareCheckbox
                          showError
                          name={`documents[${index}].selectedInPreview`}
                          value={field.selectedInPreview}
                          onChange={handleChange}
                          key={field.selected?.id}
                        >
                          {field.description}
                          {' '}
                          <a
                            href={field.selected?.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {field.selected?.description}
                          </a>
                        </SquareCheckbox>
                      );
                    })}
                  </div>
                </div>
              )}
            />

            {/* <SquareCheckbox showError name="privacyPolicy" value={values.privacyPolicy} onChange={handleChange}> */}
            {/*  {translations[interfaceLanguage].privacy_policy_confirm} */}
            {/*  {' '} */}
            {/*  <a href="https://policy.medium.com/medium-privacy-policy-f03bf92035c9" target="_blank" rel="noopener noreferrer">{translations[interfaceLanguage].privacy_policy}</a> */}
            {/* </SquareCheckbox> */}

            <p className="uploadError">{submitError}</p>

            <SubmitButton
              bgColor={notSavedSendButton?.backgroundColor ? mapIColorToString(notSavedSendButton.backgroundColor) : '#0E9285'}
              textColor={notSavedSendButton?.textColor ? mapIColorToString(notSavedSendButton.textColor) : '#FFF'}
            >
              {notSavedSendButton?.text || getTranslationByLangOrEng(interfaceLanguage, 'send_button')}
            </SubmitButton>
          </div>
        </div>
      </FormikForm>
    );
  }

  return (
    <FormStyles>
      <div
        className="formContainer"
        style={{
          backgroundColor: (success
            ? (notSavedThankYouPage?.displayMainScaleBackgroundColor ? (actualBackgroundColor ? mapIColorToString(actualBackgroundColor) : undefined)
              : (notSavedThankYouPage?.backgroundColor
                ? mapIColorToString(notSavedThankYouPage?.backgroundColor) : undefined))
            : (actualBackgroundColor
              ? mapIColorToString(actualBackgroundColor)
              : (notSavedForm?.backgroundColor ? mapIColorToString(notSavedForm?.backgroundColor) : undefined))) || '#d8d8d8',
          transition: 'background-color 0.5s linear',
        }}
      >
        <div className="buttonsContainer">
          <TransparentButton
            handleClick={() => {
              if (formId === 'new' || formId === undefined) {
                navigate(`/companies/${id}/nodes/${nodeId}/forms/create`);
              } else {
                navigate(`/companies/${id}/nodes/${nodeId}/forms/${formId}/constructor`);
              }
            }}
            text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')}
          />
        </div>

        {success && (
          <div className="success">
            <div className="body">
              <div className="topSection">
                {((notSavedThankYouPage?.displayMainScaleMascot && actualMascot) ? actualMascot : notSavedThankYouPage?.mascot) && (
                  <div className="mascotContainer">
                    <img
                      src={(notSavedThankYouPage?.displayMainScaleMascot && actualMascot
                        ? actualMascot
                        : notSavedThankYouPage?.mascot?.url)}
                      alt="Actual mascot"
                    />
                  </div>
                )}
                <h3>
                  {notSavedThankYouPage?.title}
                </h3>
                <p>{notSavedThankYouPage?.text}</p>
              </div>
              {notSavedThankYouPage?.buttonLink && notSavedThankYouPage?.buttonTextColor && notSavedThankYouPage?.buttonBackgroundColor && (
                <div className="completeButtonRaw">
                  <div className="completeButtonContainer">
                    <SubmitButton
                      bgColor={notSavedThankYouPage?.buttonBackgroundColor ? mapIColorToString(notSavedThankYouPage?.buttonBackgroundColor) : '#0E9285'}
                      textColor={notSavedThankYouPage?.buttonTextColor ? mapIColorToString(notSavedThankYouPage?.buttonTextColor) : '#FFF'}
                      onClick={() => window.open(notSavedThankYouPage?.buttonLink, '_blank')}
                    >
                      {notSavedThankYouPage?.buttonText || 'Complete'}
                    </SubmitButton>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {!success && initialValues && (
          <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {renderForm}
          </Formik>
        )}
      </div>
    </FormStyles>
  );
}
