import React, {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import { QuantityFieldStyles } from './QuantityFieldStyles';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import Checkbox from '../../form/checkbox/Checkbox';
import FieldHeader from '../fieldHeader/FieldHeader';
import { InfoCharIcon } from '../../../assets';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getTranslationByLangOrEng } from '../../../i18n';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import { IExtraField } from '../../../entities/IExtraField';
import { extraFieldValidationSchema } from '../validationSchemas';
import { Api } from '../../../api';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import Quantity from '../quantity/Quantity';
import { useParams } from 'react-router-dom';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
import { useCompanyColors } from '../../../hooks';
import StepTextSettings from '../stepTextSettings/StepTextSettings';
import { FormErrorMessage, Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface QuantityFieldFieldProps {
  index: number;
  extraBlockStyles?: CSSProperties;
  disabled?: boolean;

  checkboxExtraBlockStyles?: CSSProperties;
  checkboxDisabled?: boolean;

  squareCheckboxOnChange?: () => void;
  squareCheckboxExtraBlockStyles?: CSSProperties;
  squareCheckboxDisabled?: boolean;

  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;
  // handleOpenOnDiagram?: (index: number) => void;

  provided?: DraggableProvided;
}

export default function QuantityField({
  index,
  extraBlockStyles,
  disabled,

  checkboxExtraBlockStyles,
  checkboxDisabled,

  squareCheckboxOnChange,
  squareCheckboxExtraBlockStyles,
  squareCheckboxDisabled,

  draggable,
  handleCopy,
  handleRemove,
  // handleOpenOnDiagram,

  provided,
}: QuantityFieldFieldProps) {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedForm, notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const { companyColors, addCompanyColor } = useCompanyColors(companyId!);

  const [initialValues, setInitialValues] = useState<IExtraField>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    if (notSavedExtraFields) {
      setInitialValues(notSavedExtraFields[index]);
    }
  }, [index]);

  async function onSubmit(
    values: IExtraField,
    { setSubmitting }: FormikHelpers<IExtraField>,
  ) {
    setSubmitting(false);

    // @ts-ignore
    if (values.minValue === undefined || values.minValue === '') {
      setSubmitError('Min value required');
      return;
    }

    if (values.maxValue !== undefined && +values.maxValue < +values.minValue) {
      setSubmitError('Max value must be bigger then min value');
      return;
    }

    if (values.defaultValue !== undefined && +values.defaultValue < +values.minValue) {
      setSubmitError('Default value must be equal or bigger then min value');
      return;
    }

    if (values.defaultValue !== undefined && values.maxValue !== undefined && +values.defaultValue > +values.maxValue) {
      setSubmitError('Default value must be equal or less then max value');
      return;
    }

    setSubmitError('');

    setIsLoading(true);

    const res = await Api.updateField(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(updateNotSavedExtraField(values));
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IExtraField>) {
    const errorQuestion = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].question`, errorMessage) : undefined;
    const errorMinValue = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].minValue`, errorMessage) : undefined;
    const errorMaxValue = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].maxValue`, errorMessage) : undefined;
    const errorDefaultValue = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].defaultValue`, errorMessage) : undefined;

    return (
      <Form>
        <div className="body">
          <div className="inputContainer">
            <div className="inputRaw">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title_title')}</span>
              </div>
              <div className="inputElementContainer">
                <input
                  id="question"
                  type="text"
                  value={values.question}
                  onChange={(event) => setFieldValue('question', event.target.value)}
                  onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_placeholder')}
                  disabled={disabled}
                  className={disabled ? 'disabled' : ''}
                />
                <div className="infoContainer">
                  <div className="infoIcon">
                    <InfoCharIcon />
                  </div>
                  <div className="infoText">
                    <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_question')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="formErrorContainer">
              {errorQuestion && <span>{errorQuestion}</span>}
              {!errorQuestion && touched && <ErrorMessage name="question" component={FormErrorMessage} />}
            </div>
          </div>

          <div className="quantityCounterContainer">
            <Quantity
              defaultValue={values.defaultValue || 0}
              minValue={values.minValue || 0}
              maxValue={values.maxValue}
              step={values.step || 1}
              buttonsBackgroundColor={values.buttonsBackgroundColor || { name: 'Default', color: '#0E9285' }}
              buttonsSignsColor={values.buttonsSignsColor || { name: 'White', color: '#fff' }}
            />
          </div>

          <div className="inputsGrid">
            <div className="inputContainer">
              <div className="inputRaw">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_min_value_title')}</span>
                </div>
                <div className="inputElementContainer">
                  <input
                    id="minValue"
                    type="number"
                    value={values.minValue}
                    onChange={(event) => setFieldValue('minValue', event.target.value)}
                    onKeyUp={() => handleKeyUp('minValue', setErrorMessage, errorMessage)}
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_placeholder')}
                    disabled={disabled}
                    className={disabled ? 'disabled' : ''}
                  />
                </div>
              </div>
              <div className="formErrorContainer">
                {errorMinValue && <span>{errorMinValue}</span>}
                {!errorMinValue && touched && <ErrorMessage name="question" component={FormErrorMessage} />}
              </div>
            </div>

            <div className="inputContainer">
              <div className="inputRaw">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_max_value_title')}</span>
                </div>
                <div className="inputElementContainer">
                  <input
                    id="maxValue"
                    type="number"
                    value={values.maxValue}
                    onChange={(event) => setFieldValue('maxValue', event.target.value)}
                    onKeyUp={() => handleKeyUp('maxValue', setErrorMessage, errorMessage)}
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_placeholder')}
                    disabled={disabled}
                    className={disabled ? 'disabled' : ''}
                  />
                </div>
              </div>
              <div className="formErrorContainer">
                {errorMaxValue && <span>{errorMaxValue}</span>}
                {!errorMaxValue && touched && <ErrorMessage name="question" component={FormErrorMessage} />}
              </div>
            </div>

            <div className="inputContainer">
              <div className="inputRaw">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_default_value_title')}</span>
                </div>
                <div className="inputElementContainer">
                  <input
                    id="defaultValue"
                    type="number"
                    value={values.defaultValue}
                    onChange={(event) => setFieldValue('defaultValue', event.target.value)}
                    onKeyUp={() => handleKeyUp('defaultValue', setErrorMessage, errorMessage)}
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_placeholder')}
                    disabled={disabled}
                    className={disabled ? 'disabled' : ''}
                  />
                </div>
              </div>
              <div className="formErrorContainer">
                {errorDefaultValue && <span>{errorDefaultValue}</span>}
                {!errorDefaultValue && touched && <ErrorMessage name="question" component={FormErrorMessage} />}
              </div>
            </div>
          </div>

          <div className="inputsGrid">
            {companyColors ? (
              <div className="backgroundColorSelect">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_buttons_background_color')}</span>
                </div>
                <CustomColorsSelect
                  name="buttonsBackgroundColor"
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_buttons_background_color_placeholder')}
                  options={companyColors}
                  value={values.buttonsBackgroundColor}
                  handleSelect={(color) => setFieldValue('buttonsBackgroundColor', color)}
                  formGroupStyles={{
                    width: '300',
                    marginBottom: 0,
                  }}
                  extraComponentPosition="left"
                  extraStyles={{ flexGrow: 1 }}
                  handleAddColor={(color) => {
                    addCompanyColor(color);
                    setFieldValue('buttonsBackgroundColor', color);
                    setRerender(!rerender);
                  }}
                />
              </div>
            ) : <Loader margin={20} />}

            {companyColors ? (
              <div className="signsColorSelect">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_button_signs_color')}</span>
                </div>
                <CustomColorsSelect
                  name="buttonsSignsColor"
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_button_signs_color_placeholder')}
                  options={companyColors}
                  value={values.buttonsSignsColor}
                  handleSelect={(color) => setFieldValue('buttonsSignsColor', color)}
                  formGroupStyles={{ width: '300', marginBottom: 0 }}
                  extraComponentPosition="left"
                  extraStyles={{ flexGrow: 1 }}
                  handleAddColor={(color) => {
                    addCompanyColor(color);
                    setFieldValue('buttonsSignsColor', color);
                    setRerender(!rerender);
                  }}
                />
              </div>
            ) : <Loader margin={20} />}
          </div>

          {notSavedForm?.withSteps && values.type !== 'title' && (
            <StepTextSettings
              stepsText={values.stepsText || ''}
              handleKeyUp={handleKeyUp}
              setFieldValue={setFieldValue}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          )}

          <Checkbox
            name="required"
            value={!!values.required}
            onChange={() => setFieldValue('required', !values.required)}
            extraBlockStyles={checkboxExtraBlockStyles}
            disabled={checkboxDisabled}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question')}</span>
          </Checkbox>

          <SquareCheckbox
            name="keyQuestion"
            value={!!values.keyQuestion}
            onChange={() => setFieldValue('keyQuestion', !values.keyQuestion)}
            extraBlockStyles={squareCheckboxExtraBlockStyles}
            disabled={squareCheckboxDisabled}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_key_question')}</span>
          </SquareCheckbox>
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <QuantityFieldStyles style={extraBlockStyles}>
      <FieldHeader
        type={notSavedExtraFields?.[index].type || ''}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_quantity_title')}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
        // handleOpenOnDiagram={handleOpenOnDiagram ? () => handleOpenOnDiagram(index) : undefined}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // @ts-ignore
          validationSchema={notSavedExtraFields?.[index].type ? extraFieldValidationSchema[notSavedExtraFields![index].type] : undefined}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </QuantityFieldStyles>
  );
}
