import React from 'react';
import { ErrorPageStyles } from './ErrorPageStyles';
import { LogoIcon } from '../../assets';
import errorImage from '../../assets/images/error.png';
import { useNavigate, useParams } from 'react-router-dom';
import { errors } from '../../constants';
import { Helmet } from 'react-helmet-async';

export const ErrorPage = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  return (
    <ErrorPageStyles>
      <Helmet>
        <title>{`Error ${id} Voicer`}</title>
      </Helmet>

      {id && (
        <>
          <div className="headContainer">
            <div className="logoContainer" onClick={() => navigate('/')}><LogoIcon /></div>
          </div>
          <div className="contentContainer">
            <div className="errorContainer">
              <div className="errorImageContainer">
                <img src={errorImage} alt="Error" />
              </div>
              <div className="errorTextContainer">
                <span>
                  {errors[id] ? errors[id].code : errors['404'].code}
                </span>
                <span>
                  {errors[id] ? errors[id].title : errors['404'].title}
                </span>
                <span>
                  {errors[id] ? errors[id].description : errors['404'].description}
                </span>
              </div>
            </div>
          </div>
        </>
      )}
    </ErrorPageStyles>
  );
}
