import { DeepArrayItem } from '../../../../entities/DeepArrayItem';

export function expandAllSelectedItemsAndTheirParents(obj: DeepArrayItem[], addToExpandedItemsList: (id: number) => void) {
  obj.forEach((item) => {
    if (!item.allChildrenAreSelected) {
      if (item.selected || item.hasSelectedChildren) {
        addToExpandedItemsList(item.id);
      }

      if (item.hasSelectedChildren) {
        expandAllSelectedItemsAndTheirParents(item.children, addToExpandedItemsList)
      }
    }
  })
}

export function expandSearchedItemsAndTheirParents(obj: DeepArrayItem[], addToExpandedItemsList: (id: number) => void) {
  obj.forEach((item) => {
    addToExpandedItemsList(item.id);

    if (item.children.length) {
      expandSearchedItemsAndTheirParents(item.children, addToExpandedItemsList)
    }
  })
}
