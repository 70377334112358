import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const SolvedIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <path fill="#27C360" d="M589,350c0-132-107-239-239-239S111,218,111,350s107,239,239,239S589,482,589,350z" />
    <path
      fill="#FFFFFF"
      d="M231,299.9c11.2-11.2,29.3-11.2,40.5,0l71.2,72l101.2-102c11.2-11.2,29.3-11.2,40.5,0
  c11.1,11.3,11.2,29.6,0,40.9L362.8,433.1c-11.1,11.2-29.3,11.2-40.5,0L231,340.8C219.8,329.5,219.8,311.2,231,299.9L231,299.9z"
    />
    <path d="M-213.5,977.9L-213.5,977.9c33.4-37,94.8-11.6,92.3,38.2l0,0c-1.6,31.4,24.3,57.3,55.7,55.7h0c49.8-2.5,75.3,58.9,38.2,92.3
  l0,0c-23.3,21.1-23.3,57.7,0,78.7l0,0c37,33.4,11.6,94.8-38.2,92.3l0,0c-31.4-1.6-57.3,24.3-55.7,55.7l0,0
  c2.5,49.8-58.9,75.3-92.3,38.2l0,0c-21.1-23.3-57.7-23.3-78.7,0l0,0c-33.4,37-94.8,11.6-92.3-38.2v0c1.6-31.4-24.3-57.3-55.7-55.7
  l0,0c-49.8,2.5-75.3-58.9-38.2-92.3l0,0c23.3-21.1,23.3-57.7,0-78.7l0,0c-37-33.4-11.6-94.8,38.2-92.3h0
  c31.4,1.6,57.3-24.3,55.7-55.7v0c-2.5-49.8,58.9-75.3,92.3-38.2l0,0C-271.1,1001.2-234.5,1001.2-213.5,977.9z"
    />
    <path
      fill="#606060"
      d="M-379.5,1151.8c11.2-11.2,29.3-11.2,40.5,0l71.2,72l101.2-102c11.2-11.2,29.3-11.2,40.5,0
  c11.1,11.3,11.2,29.6,0,40.9L-247.6,1285c-11.1,11.2-29.3,11.2-40.5,0l-91.4-92.3C-390.6,1181.4-390.6,1163.1-379.5,1151.8
  L-379.5,1151.8z"
    />
  </svg>
));
