import React, { CSSProperties, useRef } from 'react';
import { ModalStyles } from './ModalStyles';

type Props = {
  extraStyles?: CSSProperties,
  onClose: () => void,
}

export const Modal: React.FC<Props> = React.memo(({ extraStyles, children, onClose }) => {
  const ref = useRef(null);

  function handleClose(event: any) {
    if (event.target.children[0]?.classList.contains('modalBody')) {
      onClose();
    }
  }

  return (
    <ModalStyles ref={ref} onClick={(event) => handleClose(event)}>
      <div className="modalBody" style={extraStyles}>
        {children}
      </div>
    </ModalStyles>
  );
});
