import styled from 'styled-components';

export const MassMailingTablePageStyles = styled.div`
  .titleWrapper {
    padding: 23px 38px 18px 30px;

    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    
    h1 {
      font-weight: 500;
      font-size: 24px;
      color: #000000;
    }   
    
    button {
      max-width: 203px;
      width: 100%;
    }
  }

  .tableFiltersWrapper {
    padding: 0 32px 0 16px;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr auto auto;
    grid-gap: 40px;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;

    .calendarWrapper {
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
      position: relative;

      .calendarOpenerWrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
        place-items: center;
        gap: 10px;
        cursor: pointer;

        .active {
          transform: rotate(180deg);
        }
      }

      .splitter {
        width: 1px;
        background: #F0F0F0;
        height: 100%;
      }
    }

    .filtersWrapper {
      position: relative;

      .filtersOpener {
        cursor: pointer;
        display: grid;
        gap: 10px;
        grid-template-columns: 1fr auto;
        align-items: center;

        &-active {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    
  }
  
  table {
    
    .connectionChannelContainer {
      display: grid;
      align-items: center;
      grid-template-columns: auto auto;
      gap: 16px;
    }
  }
  
`;
