import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';

export interface FormValues {
  name: string,
  email: string,
  // company?: number,
  passwords?: {
    oldPassword?: string,
    newPassword?: string,
    password?: string,
    passwordConfirmation?: string
  }
}

export const formInitialValues: FormValues = {
  name: '',
  email: '',
  passwords: {
    newPassword: '',
    passwordConfirmation: '',
  },
};

export const validationSchema1 = (lang: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  email: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  // company: Yup.number().required(translations[interfaceLanguage].validation_required),
  passwords: Yup.object().shape({
    password: Yup.string(),
    passwordConfirmation: Yup.string(),
  }).test('check-password-equals', 'Passwords not equal', (passwords) => passwords.password === passwords.passwordConfirmation),
});

export const validationSchema2 = (lang: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  email: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  // company: Yup.number().required(translations[interfaceLanguage].validation_required),
  passwords: Yup.object().shape({
    oldPassword: Yup.string().test(
      'check-password-equals',
      getTranslationByLangOrEng(lang, 'validation_required'),
      (value, ctx) => !(!!ctx.parent.newPassword && !ctx.parent.oldPassword),
    ),
    newPassword: Yup.string().test(
      'check-password-equals',
      getTranslationByLangOrEng(lang, 'validation_required'),
      (value, ctx) => !(!!ctx.parent.oldPassword && !ctx.parent.newPassword),
    ),
  }),
});
