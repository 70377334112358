import { useState, useRef, useEffect } from 'react';
import { Api } from '../api';

export function useCompanyImages(companyId: string) {
  const [prevCompanyId, setPrevCompanyId] = useState<string>('');
  const [companyImagesLoaded, setCompanyImagesLoaded] = useState<boolean>(false);
  const [companyImagesLoading, setCompanyImagesLoading] = useState<boolean>(false);

  const companyImagesRef = useRef<{ name: string, url: string }[]>();

  function addCompanyImage(image: { name: string, url: string }) {
    companyImagesRef.current = companyImagesRef.current ? [...companyImagesRef.current, image] : [image]
  }

  async function getCompanyMetadata(id: number) {
    const res = await Api.getCompanyMetadata(id);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      companyImagesRef.current = res.data.filter((item) => item.key.startsWith('image_')).map((item) => {
        const imageParts = item.value.split('|||');
        return { name: imageParts[0], url: imageParts[1] };
      });
    }
  }

  useEffect(() => {
    if (prevCompanyId !== companyId && !companyImagesLoaded && !companyImagesLoading) {
      setPrevCompanyId(companyId);
      setCompanyImagesLoading(true);
      getCompanyMetadata(+companyId!).finally(() => {
        setCompanyImagesLoading(false);
        setCompanyImagesLoaded(true);
      });
    }
  }, []);

  return {
    companyImagesLoaded,
    companyImagesLoading,
    companyImages: companyImagesRef.current,
    addCompanyImage,
  };
}
