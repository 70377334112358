import styled from 'styled-components';

export const ImgModalStyles = styled.div`
  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    padding: 20px;

    img {
        width: 100%;
        height: 80vh;
        object-fit: contain;
    }

    @media screen and (max-width: 768px) {
      width: 80%;
    }
  }
`;
