import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../../../i18n';

export interface FormValues {
  senderAccount: string | undefined;
  messageType: 'text' | 'image';
  text: string;
  image: {
    value: string | File,
    id?: number,
  } | undefined;
  actionButtonActive: boolean;
  buttonText: string;
  buttonUrl: string;
}

export const validationSchema = (lang: string) => Yup.object({
  senderAccount: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  messageType: Yup.string(),
  text: Yup.string().when(['messageType'], {
    is: (mailingType: 'text' | 'image') => mailingType === 'text',
    then: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.string().nullable(),
  }),
  image: Yup.object().when(['messageType'], {
    is: (mailingType: 'text' | 'image') => mailingType === 'image',
    then: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.object().nullable(),
  }),
  actionButtonActive: Yup.boolean(),
  buttonText: Yup.string().when(['actionButtonActive'], {
    is: true,
    then: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.string().nullable(),
  }),
  buttonUrl: Yup.string().when(['actionButtonActive'], {
    is: true,
    then: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.string().nullable(),
  }),
});
