import React from 'react';

export const DragIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      fill="#C4C4C4"
      d="M7 20h2V8h3L8 4 4 8h3v12zm13-4h-3V4h-2v12h-3l4 4 4-4z"
    />
  </svg>
);
