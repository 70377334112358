import React, { CSSProperties, useState } from 'react';
import { InputFieldStyles } from './InputFieldStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import { EyeIcon } from '../../../assets';
import { FormErrorMessage } from '../../atoms';

interface InputFieldProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (key: string, value: string) => void;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  extraInputStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  disabled?: boolean;
  type?: string;
  required?: boolean;
  children?: any;
}

export default function InputField({
  name,
  placeholder,
  label,
  error,
  showError = true,
  value,
  min,
  max,
  onChange,
  onKeyUp,
  extraBlockStyles,
  extraInputStyles,
  disabled,
  type,
  required,
  children,
}: InputFieldProps) {
  const { touched } = useFormikContext();
  const [hide, setHide] = useState<boolean>(type === 'password')

  return (
    <InputFieldStyles style={extraBlockStyles}>
      <label htmlFor={name}>
        {label
          && (
            <span>
              {label}
              {required && <span className="required"> *</span>}
            </span>
          )}
        <div className="inputWrap">
          <div className="inputContainer">
            <input
              id={name}
              type={(type === 'password' ? hide ? 'password' : 'text' : type) || 'text'}
              min={type === 'number' ? min : undefined}
              max={type === 'number' ? max : undefined}
              value={value}
              onChange={(event) => onChange(name, event.target.value)}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              disabled={disabled}
              className={disabled ? 'disabled' : ''}
              style={extraInputStyles}
            />
            {type === 'password' && (
              <button type="button" onClick={() => setHide(!hide)}>
                <EyeIcon fill={hide ? '#c6c6c6' : '#0E9285'} />
              </button>
            )}
            {showError && (
              <div className="formErrorContainer">
                {error && <span>{error}</span>}
                {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
              </div>
            )}
          </div>
          <div className="childrenContainer">
            {children}
          </div>
        </div>
      </label>
    </InputFieldStyles>
  );
}
