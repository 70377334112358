import styled from 'styled-components';

export const QRModalStyles = styled.div`
    .modalBody {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 30%;
        padding: 20px;

        @media screen and (max-width: 768px) {
            width: 80%;
          }

        .title {
            text-align: center;
            padding-bottom: 20px;
            margin-bottom: 20px;
            font-size: 20px;
            font-weight: bold;
            border-bottom: 1px solid #ccc;
        }

        p {
          margin: 10px 0;
          font-size: 16px;
          font-weight: bold;
        }
    }
`;
