import React, {
  Dispatch, FC, SetStateAction, useEffect, useRef, useState,
} from 'react'
import { Api, ApiChatTemplates } from '../../api'
import { CompleteIcon, DeleteIcon, PencilIcon } from '../../assets';

type TCompanyTemplate = {
    id: string;
    setReQuery: Dispatch<SetStateAction<boolean>>;
    reQuery: boolean
    template: ApiChatTemplates
}

export const CompanyTemplate: FC<TCompanyTemplate> = ({
  id, setReQuery, reQuery, template,
}) => {
  const [edit, setEdit] = useState<boolean>(false)
  const [content, setContent] = useState<string>(template.content)

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const handleDelete = (templateID: number) => {
    Api.deleteChatTemplate(parseInt(id!, 10), templateID).then((res) => {
      if (res.statusCode === 200) {
        setReQuery(!reQuery)
      } else {
        console.log(res.data.message)
      }
    })
  }

  const handleUpdate = (templateID: number, data: {key: string, content: string}) => {
    Api.updateChatTemplate(parseInt(id!, 10), templateID, data).then((res) => {
      if (res.statusCode === 200) {
        setReQuery(!reQuery)
      } else {
        console.log(res.data)
      }
    })
  }

  const resizeTextarea = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  useEffect(() => {
    resizeTextarea();
  }, []);

  return (
    <div className="templateContainer">
      <div className="templateKey">
        {template.key}
      </div>
      <div className="templateContent">
        <textarea ref={textareaRef} disabled={!edit} value={content} onChange={(e) => setContent(e.target.value)} />
      </div>
      <div className="buttons">
        {edit ? (
          <button
            type="button"
            onClick={() => {
              setEdit(false)
              handleUpdate(template.id, { key: template.key, content })
            }}
          >
            <CompleteIcon />
          </button>
        ) : (
          <button type="button" onClick={() => setEdit(true)}>
            <PencilIcon />
          </button>
        )}

        <button
          type="button"
          onClick={() => {
            handleDelete(template.id);
          }}
        >
          <DeleteIcon />
        </button>
      </div>
    </div>
  )
}
