import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const SendIcon: FC<TIcon> = React.memo(({ width = 28, height = 28, color = '#000' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 28 28"
  >
    <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
      <g fill={color} fillRule="nonzero">
        <path d="M3.79 2.772L24.86 12.85a1.25 1.25 0 010 2.255L3.79 25.183a1.25 1.25 0 01-1.746-1.457l2.108-7.728a.5.5 0 01.415-.364l10.21-1.387a.25.25 0 00.195-.149l.018-.063a.25.25 0 00-.157-.268l-.055-.014-10.2-1.387a.5.5 0 01-.414-.364l-2.12-7.773A1.25 1.25 0 013.79 2.772z" />
      </g>
    </g>
  </svg>
));
