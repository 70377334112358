import {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps, FieldArray,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { AddNodeStyles } from './AddNodeStyles';
import InputField from '../../../components/form/inputField/InputField';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import Checkbox from '../../../components/form/checkbox/Checkbox';
import { Api } from '../../../api';
import { getTranslationByLangOrEng } from '../../../i18n';
import {
  useAppDispatch, useAppSelector, setNodeNames, setNodeMetadata, setCompanySources,
} from '../../../state';
import CustomSelectTiedNode from '../../../components/form/customSelect/CustomSelectTiedNode';
import {
  getNodesDeepArray, getErrorMessage, handleKeyUp, getNodeNamesArray,
} from '../../../utils';
import { DeleteIcon } from '../../../assets';
import CustomSelect from '../../../components/form/customSelect/CustomSelect';
import { FormValues, networks, validationSchema } from './additionalSettings';
import { Helmet } from 'react-helmet-async';
import { ICompanyUser, ICompanyNodeExtraField } from '../../../entities';
import CustomSelectResponsible from '../../../components/form/customSelect/CustomSelectResponsible';
import {
  Loader, ModalConfirmDecline, SubHeader, TransparentButton,
} from '../../../components';

export default function AddNode() {
  const { companyId, nodeId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    selectedCompany, nodeNames, nodeMetadata, companySources,
  } = useAppSelector((state) => state.company);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [companyName, setCompanyName] = useState('');
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const [usersByCompany, setUsersByCompany] = useState<ICompanyUser[]>([])

  const nodeNameRef = useRef<FormikProps<FormValues>>(null);

  function getFormInitialData(nodes: {id: number, name: string, deep: number}[]) {
    if (companyId) {
      Api.getCompanyById(+companyId)
        .then((res) => setCompanyName(res.data.name));
      Api.getSourcesByCompanyId(+companyId).then((res) => {
        dispatch(setCompanySources(res.data))
      }).catch((e) => console.log('get request sources err', e));
    }
    if (nodeId) {
      Api.getNodeById(+nodeId)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(setNodeMetadata(res.data));
            const data = {
              name: res.data.name,
              slug: res.data.slug,
              code: res.data.code,
              useDefaultForm: res.data.useDefaultForm,
              tiedNode: res.data.tiedNode,
              secondTiedNode: res.data.secondTiedNode,
              address: res.data.address || { value: '' },
              nodes: getNodesDeepArray(nodes),
              googlePlaceID: res.data.googlePlaceID || { value: '' },
              sms: res.data.sms || {
                active: false,
                key: '',
                alphaName: '',
              },
              viber: res.data.viber || {
                active: false,
                key: '',
                alphaName: '',
              },
              telegram: res.data.telegram || {
                active: false,
                key: '',
                alphaName: '',
              },
              whatsapp: res.data.whatsapp || {
                active: false,
                key: '',
                alphaName: '',
              },
              email: res.data.email || {
                active: false,
                key: '',
                alphaName: '',
              },
              extraFields: res.data.extraFields || [],
              sourceId: res.data.sourceId,
              responsibleID: res.data.responsibleId,
              enableOverdue: res.data.enableOverdue,
            };

            setInitialValues({ ...data });
          }
        });
    } else {
      setInitialValues({
        name: '',
        slug: '',
        code: '',
        useDefaultForm: false,
        tiedNode: undefined,
        secondTiedNode: undefined,
        address: {
          value: '',
        },
        nodes: getNodesDeepArray(nodes),

        googlePlaceID: {
          value: '',
        },
        sms: {
          active: false,
          key: '',
          alphaName: '',
        },
        viber: {
          active: false,
          key: '',
          alphaName: '',
        },
        telegram: {
          active: false,
          key: '',
          alphaName: '',
        },
        whatsapp: {
          active: false,
          key: '',
          alphaName: '',
        },
        email: {
          active: false,
          key: '',
          alphaName: '',
        },
        extraFields: [],
        sourceId: null,
        responsibleID: undefined,
        enableOverdue: false,
      });
    }
  }

  function getFilteredNodeNames(initialArray: {id: number, name: string, deep: number}[], nodeId: string) {
    const currentNodeDeep = nodeId ? initialArray.find((item) => item.id === +nodeId)?.deep || 0 : undefined;
    let hisBranch = false;

    return initialArray.filter((item) => {
      if (item.id === +nodeId) {
        hisBranch = true;
        return false;
      }

      if (hisBranch && currentNodeDeep !== undefined && currentNodeDeep < item.deep) {
        return false;
      }

      hisBranch = false;
      return true;
    });
  }

  useEffect(() => {
    if (companyId !== undefined && +companyId === selectedCompany?.id && nodeNames) {
      const filteredNodeNames = [...nodeNames];
      getFormInitialData([{ id: 0, name: '-', deep: 0 }, ...(nodeId ? getFilteredNodeNames(filteredNodeNames, nodeId) : filteredNodeNames)]);
    } else if (companyId !== undefined) {
      Api.getCompanyNodes(+companyId!)
        .then((res) => {
          const nodeNamesArray: {id: number, name: string, deep: number}[] = [];

          getNodeNamesArray(nodeNamesArray, res.data || []);
          getFormInitialData([{ id: 0, name: '-', deep: 0 }, ...(nodeId ? getFilteredNodeNames(nodeNamesArray, nodeId) : nodeNamesArray)]);

          dispatch(setNodeNames(nodeNamesArray));
        });
    }
    Api.getUsersByCompany(+companyId!).then((res) => setUsersByCompany(res.data))
  }, [nodeId]);

  const handleCancel = () => {
    navigate(`/companies/${companyId}/nodes`);
  };

  const onSaveModalConfirm = useCallback(async (values: FormValues) => {
    const extraFieldsWithPosition: ICompanyNodeExtraField[] = values.extraFields.map((item, index) => ({
      ...item,
      position: String(index),
    }));

    const data = {
      name: values.name,
      useDetectLanguage: values.useDefaultForm,
      enableOverdue: values.enableOverdue,
      address: values.address,
      googlePlaceID: values.googlePlaceID,
      sms: values.sms,
      viber: values.viber,
      telegram: values.telegram,
      email: values.email,
      parentID: values.tiedNode || 0,
      referencedID: values.secondTiedNode || 0,
      responsibleID: values.responsibleID || 0,
      sourceID: values.sourceId || 0,
    };

    if (nodeId && companyId) {
      const res = await Api.updateNode(+nodeId, +companyId, data);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        if (values.address.id) {
          await Api.updateNodeMetadata(values.address.value, values.address.id);
        } else if (!values.address.id && values.address.value) {
          await Api.createNodeMetadata({
            key: 'address',
            parentID: +nodeId,
            value: values.address.value,
          });
        }

        if (values.googlePlaceID.id) {
          await Api.updateNodeMetadata(values.googlePlaceID.value, values.googlePlaceID.id);
        } else if (!values.googlePlaceID.id && values.googlePlaceID.value) {
          await Api.createNodeMetadata({
            key: 'googlePlaceID',
            parentID: +nodeId,
            value: values.googlePlaceID.value,
          });
        }

        const extraFieldToCreate = extraFieldsWithPosition?.filter((extraField) => !extraField.id);
        const extraFieldToUpdate = extraFieldsWithPosition?.filter((extraField) => {
          const fieldToCheck = nodeMetadata?.extraFields?.find((item) => item.id === extraField.id);

          if (fieldToCheck) {
            return fieldToCheck.value !== extraField.value || fieldToCheck.value !== extraField.name || fieldToCheck.position !== extraField.position;
          }

          return false;
        });
        const extraFieldToDelete = nodeMetadata?.extraFields?.filter((extraField) => extraFieldsWithPosition.every((item) => extraField.id !== item.id));

        if (extraFieldToDelete?.length) {
          extraFieldToDelete.forEach(async (item) => {
            await Api.deleteNodeMetadata(item.id!);
          });
        }

        if (extraFieldToCreate.length) {
          extraFieldToCreate.forEach(async (extraField) => {
            await Api.createNodeMetadata({ key: `extraField_${Math.random()}`, value: [extraField.name, extraField.value, extraField.position].join('|||'), parentID: +nodeId });
          });
        }

        if (extraFieldToUpdate.length) {
          extraFieldToUpdate.forEach(async (extraField) => {
            await Api.updateNodeMetadata([extraField.name, extraField.value, extraField.position].join('|||'), extraField.id!);
          });
        }

        const promises: Promise<any>[] = [];

        networks.forEach((socialNetwork) => {
          const socialNetworkKey: 'sms' | 'viber' | 'telegram' | 'whatsapp' | 'email' = socialNetwork.key;

          if (values[socialNetworkKey].keyId) {
            promises.push(Api.updateNodeMetadata(values[socialNetworkKey].key, values[socialNetworkKey].keyId!));
            promises.push(Api.updateNodeMetadata(values[socialNetworkKey].alphaName, values[socialNetworkKey].alphaNameId!));
            promises.push(Api.updateNodeMetadata(values[socialNetworkKey].active ? 'true' : 'false', values[socialNetworkKey].activeId!));
          }

          if (!values[socialNetworkKey].keyId && values[socialNetworkKey].key) {
            promises.push(Api.createNodeMetadata({
              key: `${socialNetworkKey}_key`,
              parentID: +nodeId,
              value: values[socialNetworkKey].key,
            }));
          }

          if (!values[socialNetworkKey].keyId && values[socialNetworkKey].alphaName) {
            promises.push(Api.createNodeMetadata({
              key: `${socialNetworkKey}_alphaName`,
              parentID: +nodeId,
              value: values[socialNetworkKey].alphaName,
            }));
          }

          promises.push(Api.createNodeMetadata({
            key: `${socialNetworkKey}_active`,
            parentID: +nodeId,
            value: values[socialNetworkKey].active ? 'true' : 'false',
          }));
        });

        await Promise.all(promises);
        handleCancel();
      } else {
        setErrorMessage(res.data);
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      }
    } else if (companyId) {
      const res = await Api.createNode({
        name: values.name,
        code: values.code,
        companyID: +companyId,
        parentID: values.tiedNode || 0,
        referencedID: values.secondTiedNode || 0,
        useDetectLanguage: values.useDefaultForm,
        enableOverdue: values.enableOverdue,
        sourceID: values.sourceId || 0,
        responsibleID: values.responsibleID || 0,
      });

      if (res.statusCode >= 200 && res.statusCode < 300) {
        if (values.address.value) {
          await Api.createNodeMetadata({ key: 'address', value: values.address.value, parentID: res.data.id });
        }

        if (values.googlePlaceID) {
          await Api.createNodeMetadata({ key: 'googlePlaceId', value: values.googlePlaceID.value || '', parentID: res.data.id });
        }

        if (extraFieldsWithPosition.length) {
          extraFieldsWithPosition.forEach(async (extraField) => {
            await Api.createNodeMetadata({
              key: `extraField_${Math.random()}`,
              value: [extraField.name, extraField.value, extraField.position].join('|||'),
              parentID: res.data.id,
            });
          });
        }

        const promises: Promise<any>[] = [];

        networks.forEach((socialNetwork) => {
          const socialNetworkKey: 'sms' | 'viber' | 'telegram' | 'whatsapp' | 'email' = socialNetwork.key;

          if (values[socialNetworkKey].key) {
            promises.push(Api.createNodeMetadata({ key: `${socialNetworkKey}_key`, value: values[socialNetworkKey].key, parentID: res.data.id }));
          }

          if (values[socialNetworkKey].alphaName) {
            promises.push(Api.createNodeMetadata({ key: `${socialNetworkKey}_alphaName`, value: values[socialNetworkKey].alphaName, parentID: res.data.id }));
          }

          if (values[socialNetworkKey].active) {
            promises.push(Api.createNodeMetadata({ key: `${socialNetworkKey}_active`, value: 'true', parentID: res.data.id }));
          }
        });

        await Promise.all(promises);
        handleCancel();
      } else {
        setErrorMessage(res.data);
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      }
    }
  }, [nodeMetadata]);

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setConfirmSaveModalOpen(true);

    setSubmitting(false);
  }

  const renderForm = ({
    values,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <div className="formSectionInnerWrap">
          <div>
            <InputField
              extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
              name="name"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_placeholder')}
              value={values.name}
              error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_label')}
              required
            />

            <InputField
              extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
              name="code"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('code', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_placeholder')}
              value={values.code}
              error={typeof errorMessage === 'object' ? getErrorMessage('code', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_label')}
              disabled={nodeId !== undefined}
            />

            <CustomSelectTiedNode
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_label')}
              name="tiedNode"
              options={values.nodes}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')}
              value={nodeNames?.find((node) => node.id === values.tiedNode)}
              handleSelect={(node) => {
                setFieldValue('tiedNode', node.id);
              }}
              formGroupStyles={{ width: '253px' }}
              search
              expandable
            />

            <CustomSelectTiedNode
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_tied_node_label_second')}
              name="secondTiedNode"
              options={values.nodes}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')}
              value={nodeNames?.find((node) => node.id === values.secondTiedNode)}
              handleSelect={(node) => {
                setFieldValue('secondTiedNode', node.id);
              }}
              formGroupStyles={{ width: '253px' }}
              search
              expandable
            />

            <InputField
              extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
              name="address.value"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('address.value', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_placeholder')}
              value={values.address.value}
              error={typeof errorMessage === 'object' ? getErrorMessage('address.value', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_label')}
            />

            <Checkbox
              name="useDefaultForm"
              value={values.useDefaultForm}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_lang_detection')}</span>
            </Checkbox>
            <Checkbox
              name="enableOverdue"
              value={values.enableOverdue}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_lang_delay')}</span>
            </Checkbox>
          </div>
          <div style={{ width: '100%', maxWidth: '300px' }}>
            <CustomSelect
              label={getTranslationByLangOrEng(interfaceLanguage, 'source')}
              name="sourceId"
              options={companySources || []}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'source')}
              value={companySources?.find((source) => source.id === values.sourceId)}
              handleSelect={(value) => {
                setFieldValue('sourceId', value.id);
              }}
              search
              emptyOption
              emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')}
            />

            <CustomSelectResponsible
              value={values.responsibleID}
              handleSelect={(value) => {
                setFieldValue('responsibleID', value.id);
              }}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'responsible_id')}
              search
              emptyOption
              label={getTranslationByLangOrEng(interfaceLanguage, 'responsible')}
              name="responsibleID"
              options={usersByCompany || []}
            />
          </div>
        </div>

        <FieldArray
          name="extraFields"
          render={({ insert, remove, push }) => (
            <div className="extraFieldsWrapper">
              {values.extraFields.length > 0
              && values.extraFields.map((field, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="extraFieldContainer" key={index}>
                  <div className="fields">
                    <InputField
                      name={`extraFields[${index}].name`}
                      onChange={setFieldValue}
                      onKeyUp={() => handleKeyUp(`extraFields[${index}].title`, setErrorMessage, errorMessage)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_extra_field_name_placeholder')}
                      value={values.extraFields[index].name}
                      // @ts-ignore*
                      error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].name`, errorMessage) : undefined}
                    />
                    <InputField
                      name={`extraFields[${index}].value`}
                      onChange={setFieldValue}
                      onKeyUp={() => handleKeyUp(`extraFields[${index}].value`, setErrorMessage, errorMessage)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_extra_field_value_placeholder')}
                      value={values.extraFields[index].value}
                      // @ts-ignore*
                      error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].value`, errorMessage) : undefined}
                    />
                  </div>

                  <div className="removeButtonContainer">
                    <button
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ))}

              <div className="addFieldButtonContainer">
                <TransparentButton handleClick={() => push({ name: '', value: '' })} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_add_field_button')} filled />
              </div>
            </div>
          )}
        />
      </div>
      <div className="formSection additionalSettings">
        <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_advanced_settings')}</h2>
        <InputField
          extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
          name="googlePlaceID.value"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('googlePlaceID.value', setErrorMessage, errorMessage)}
          placeholder=""
          value={values.googlePlaceID.value}
          error={typeof errorMessage === 'object' ? getErrorMessage('googlePlaceID.value', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_google_place_id_label')}
        />
        <SubmitButton extraButtonStyles={{ width: '100%', maxWidth: '500px' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <AddNodeStyles>
      <Helmet>
        <title>{nodeId ? `Editing settings ${nodeNameRef?.current?.values.name || 'New node'} ${companyName} Voicer` : `Creating a node ${companyName} Voicer`}</title>
      </Helmet>

      <SubHeader
        title={nodeId ? getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_edit_title') : getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_title')}
        pathArray={[
          { name: companyName, route: `/companies/${companyId}/nodes` },
          ...(nodeNameRef?.current?.values.name ? [{ name: nodeNameRef?.current?.values.name }] : [{ name: 'New node' }]),
        ]}
      >
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
      </SubHeader>

      {!initialValues ? <Loader /> : (
        <Formik
          innerRef={nodeNameRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}

      {confirmSaveModalOpen
        && (
          <ModalConfirmDecline
            onClose={() => setConfirmSaveModalOpen(false)}
            onConfirm={() => onSaveModalConfirm(nodeNameRef.current!.values)}
            onDecline={() => setConfirmSaveModalOpen(false)}
            confirmText={nodeId ? getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button') : getTranslationByLangOrEng(interfaceLanguage, 'create_button')}
            declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
            title={nodeId ? getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_update_modal_title') : getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_modal_title')}
            error={!!dataSaveError}
            errorText={dataSaveError}
            firstButtonStyles={{ background: '#ff0000' }}
            secondButtonStyles={{ background: '#0E9285' }}
          />
        )}
    </AddNodeStyles>
  );
}
