import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const SaveIcon: FC<TIcon> = React.memo(({ width = 35, height = 35, color = '#000' }) => (
  <svg
    width={width}
    height={height}
  >
    <path fill={color} d="M 17.09375 0.109375 C 16.804688 0.21875 16.570312 0.394531 16.398438 0.636719 C 16.109375 1.042969 16.128906 0.289062 16.128906 9.636719 C 16.128906 14.257812 16.117188 18.035156 16.105469 18.035156 C 16.085938 18.035156 14.492188 16.445312 12.554688 14.503906 C 9.796875 11.742188 8.996094 10.957031 8.855469 10.890625 C 8.484375 10.71875 7.964844 10.722656 7.589844 10.90625 C 7.339844 11.03125 7.027344 11.359375 6.894531 11.640625 C 6.800781 11.835938 6.789062 11.910156 6.792969 12.226562 C 6.796875 12.648438 6.851562 12.800781 7.125 13.164062 C 7.21875 13.289062 9.398438 15.492188 11.957031 18.0625 C 17.265625 23.378906 16.816406 22.980469 17.480469 22.984375 C 18.171875 22.984375 17.683594 23.421875 23.152344 17.921875 C 26.328125 14.726562 27.976562 13.042969 28.046875 12.921875 C 28.265625 12.53125 28.273438 12.011719 28.070312 11.589844 C 27.945312 11.335938 27.644531 11.042969 27.371094 10.90625 C 27.167969 10.804688 27.105469 10.792969 26.761719 10.792969 C 26.101562 10.792969 26.417969 10.523438 22.476562 14.476562 L 19.023438 17.9375 L 19.015625 9.515625 L 19.003906 1.089844 L 18.898438 0.871094 C 18.769531 0.589844 18.46875 0.289062 18.191406 0.160156 C 17.898438 0.0273438 17.386719 0 17.09375 0.109375 Z M 17.09375 0.109375 " />
    <path fill={color} d="M 1.039062 20.6875 C 0.628906 20.820312 0.257812 21.160156 0.105469 21.542969 L 0.0195312 21.761719 L 0.0195312 26.84375 L 0.140625 27.089844 C 0.296875 27.410156 0.527344 27.644531 0.835938 27.792969 L 1.085938 27.914062 L 33.914062 27.914062 L 34.164062 27.789062 C 34.300781 27.726562 34.445312 27.636719 34.488281 27.597656 C 34.667969 27.441406 34.851562 27.148438 34.925781 26.902344 C 35 26.65625 35.003906 26.570312 34.992188 24.1875 L 34.980469 21.722656 L 34.863281 21.472656 C 34.726562 21.183594 34.410156 20.867188 34.136719 20.738281 C 33.984375 20.671875 33.886719 20.65625 33.554688 20.652344 C 33.175781 20.652344 33.140625 20.660156 32.902344 20.773438 C 32.480469 20.984375 32.199219 21.367188 32.109375 21.855469 C 32.085938 21.96875 32.070312 22.703125 32.070312 23.539062 L 32.070312 25.027344 L 2.929688 25.027344 L 2.929688 23.539062 C 2.929688 22.703125 2.914062 21.96875 2.890625 21.855469 C 2.800781 21.378906 2.5 20.976562 2.082031 20.769531 C 1.90625 20.683594 1.800781 20.660156 1.53125 20.648438 C 1.316406 20.636719 1.140625 20.652344 1.039062 20.6875 Z M 1.039062 20.6875 " />
  </svg>
))
