import { RadioFieldStyles } from './RadioFieldStyles';
import React, { CSSProperties, useEffect, useState } from 'react';
import {
  ErrorMessage, FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import FieldHeader from '../fieldHeader/FieldHeader';
import Checkbox from '../../form/checkbox/Checkbox';
import InputField from '../../form/inputField/InputField';
import { DeleteIcon, InfoCharIcon } from '../../../assets';
import RadioButton from '../../form/radioButton/RadioButton';
import { DraggableProvided } from 'react-beautiful-dnd';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import { IExtraField } from '../../../entities/IExtraField';
import { Api } from '../../../api';
import { extraFieldValidationSchema } from '../validationSchemas';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import { getTranslationByLangOrEng, translations } from '../../../i18n';
import { useParams } from 'react-router-dom';
import { useCompanyColors } from '../../../hooks';
import StepTextSettings from '../stepTextSettings/StepTextSettings';
import { FormErrorMessage, Loader, TransparentButton } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface RadioFieldProps {
  index: number;
  extraBlockStyles?: CSSProperties;
  notFullClickable?: boolean;
  squareCheckboxOnChange?: () => void;
  squareCheckboxExtraBlockStyles?: CSSProperties;

  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;
  provided?: DraggableProvided;
}

export default function RadioField({
  index,
  extraBlockStyles,
  notFullClickable,
  squareCheckboxOnChange,
  squareCheckboxExtraBlockStyles,

  draggable,
  handleCopy,
  handleRemove,

  provided,
}: RadioFieldProps) {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedForm, notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const { companyColors, addCompanyColor } = useCompanyColors(companyId!);

  const [nextFieldOptions, setNextFieldOptions] = useState<IExtraField[]>();
  const [initialValues, setInitialValues] = useState<IExtraField>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    if (notSavedExtraFields) {
      // setNextFieldOptions(notSavedExtraFields.filter((item: IExtraField) => item.id !== notSavedExtraFields![index].id));
      setInitialValues(notSavedExtraFields[index]);
    }
  }, [index]);

  async function onSubmit(
    values: IExtraField,
    { setSubmitting }: FormikHelpers<IExtraField>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateField(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(updateNotSavedExtraField(values));
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IExtraField>) {
    const error = typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined;
    const optionsError = typeof errorMessage === 'object' ? getErrorMessage('answers', errorMessage) : undefined;

    return (
      <Form>
        <div className="body">

          <div className="inputContainer fieldCaption">
            <div className="inputRaw">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title_title')}</span>
              </div>
              <div className="inputElementContainer">
                <input
                  id="question"
                  type="text"
                  value={values.question}
                  onChange={(event) => setFieldValue('question', event.target.value)}
                  onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_one_from_list_placeholder')}
                />
                <div className="infoContainer">
                  <div className="infoIcon">
                    <InfoCharIcon />
                  </div>
                  <div className="infoText">
                    <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_question')}</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="formErrorContainer">
              {error && <span>{error}</span>}
              {!error && touched && <ErrorMessage name="question" component={FormErrorMessage} />}
            </div>
          </div>

          <div className="addOption">
            <FieldArray
              name="answers"
              render={({ insert, remove, push }) => (
                <div className="options">
                  {values.answers!.map((field: any, subIndex: number) => {
                    const optionErrorValue = typeof errorMessage === 'object' ? getErrorMessage(`answers[${subIndex}].value`, errorMessage) : undefined;

                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <div className="radioFieldContainer" key={subIndex}>
                        <RadioButton
                          name="selectedOption"
                          value={values.answers![subIndex].value}
                          onChange={(event) => setFieldValue('selectedOption', event.target.value)}
                          radioButtonWrapperStyles={{ alignItems: 'center' }}
                          // customizedRadioContainerStyles={handleChangeOptionsValue ? { marginBottom: 24 } : {}}
                          checked={values.selectedOption === values.answers![subIndex].value}
                          notFullClickable={notFullClickable}
                        >
                          <div className="radioText">
                            <InputField
                              type="text"
                              name={`answers[${subIndex}].value`}
                              value={values.answers![subIndex].value}
                              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_text')}
                              onChange={(key, value) => setFieldValue(key, value)}
                              onKeyUp={() => handleKeyUp(`answers[${subIndex}].value`, setErrorMessage, errorMessage)}
                              showError={false}
                            />
                          </div>
                        </RadioButton>

                        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                        <div className="rowItemContainer">
                          <button
                            type="button"
                            onClick={() => remove(subIndex)}
                          >
                            <DeleteIcon />
                          </button>
                        </div>

                        <div className="rowItemContainer">
                          <div className="verticalLine" />
                        </div>

                        <div className="rowItemContainer">
                          <div className="choiceDescriptionCheckboxContainer">
                            {/* <div className="iconContainer"> */}
                            {/*  <JustPlusIcon color="#FFF" /> */}
                            {/* </div> */}

                            <SquareCheckbox
                              name={`answers[${subIndex}].showChoiceDescription`}
                              value={values.answers![subIndex].showChoiceDescription!}
                              onChange={handleChange}
                              checkboxWrapperStyles={{ gridTemplateColumns: '1fr' }}
                            />
                          </div>
                        </div>

                        <div className="rowItemContainer">
                          <div className="infoContainer">
                            <div className="infoIcon">
                              <InfoCharIcon />
                            </div>
                            <div className="infoText">
                              <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choice_description_info')}</p>
                            </div>
                          </div>
                        </div>

                        {values.answers![subIndex].showChoiceDescription && (
                          <>
                            <div className="choicePlaceholderContainer">
                              <Checkbox
                                name={`answers[${subIndex}].choiceDescriptionRequired`}
                                value={values.answers![subIndex].choiceDescriptionRequired || false}
                                onChange={() => setFieldValue(`answers[${subIndex}].choiceDescriptionRequired`, !values.answers![subIndex].choiceDescriptionRequired)}
                                hideError
                              >
                                <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choice_description_required')}</span>
                              </Checkbox>
                            </div>

                            <div />
                            <div />
                            <div />
                            <div />
                          </>
                        )}

                        {values.answers![subIndex].showChoiceDescription && (
                          <>
                            <div className="choicePlaceholderContainer">
                              <div className="nameContainer">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title_title')}</div>
                              <InputField
                                type="text"
                                name={`answers[${subIndex}].choiceDescriptionTitle`}
                                value={values.answers![subIndex].choiceDescriptionTitle}
                                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choice_description_title')}
                                onChange={setFieldValue}
                                onKeyUp={() => handleKeyUp(`answers[${subIndex}].choiceDescriptionTitle`, setErrorMessage, errorMessage)}
                                showError={false}
                              />
                            </div>

                            <div />
                            <div />
                            <div />
                            <div />
                          </>
                        )}

                        {values.answers![subIndex].showChoiceDescription && (
                          <>
                            <div className="choicePlaceholderContainer">
                              <div className="nameContainer">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title_placeholder_title')}</div>
                              <InputField
                                type="text"
                                name={`answers[${subIndex}].choiceDescriptionPlaceholder`}
                                value={values.answers![subIndex].choiceDescriptionPlaceholder}
                                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choice_description_placeholder')}
                                onChange={setFieldValue}
                                onKeyUp={() => handleKeyUp(`answers[${subIndex}].choiceDescriptionPlaceholder`, setErrorMessage, errorMessage)}
                                showError={false}
                              />
                            </div>

                            <div />
                            <div />
                            <div />
                            <div />
                          </>
                        )}

                        <div className="formErrorContainer">
                          {optionErrorValue && <span>{optionErrorValue}</span>}
                          {!optionErrorValue && touched && <ErrorMessage name={`answers[${subIndex}].value`} component={FormErrorMessage} />}
                        </div>
                      </div>
                    );
                  })}

                  <div className="addFieldButtonContainer">
                    <TransparentButton
                      handleClick={() => push({ value: '', selected: false, showChoiceDescription: false })}
                      text={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_add_an_option')}
                      filled
                    />
                  </div>
                  <div className="formErrorContainer">
                    {optionsError && <span>{optionsError}</span>}
                    {!optionsError && touched && <ErrorMessage name="answers" component={FormErrorMessage} />}
                  </div>
                </div>
              )}
            />
          </div>

          {notSavedForm?.withSteps && values.type !== 'title' && (
            <StepTextSettings
              stepsText={values.stepsText || ''}
              handleKeyUp={handleKeyUp}
              setFieldValue={setFieldValue}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
            />
          )}

          <Checkbox
            name="required"
            value={values.required!}
            onChange={handleChange}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question')}</span>
          </Checkbox>

          {squareCheckboxOnChange && (
            <SquareCheckbox
              name="keyQuestion"
              value={values.keyQuestion!}
              onChange={(event) => {
                squareCheckboxOnChange();
                handleChange(event);
              }}
              extraBlockStyles={squareCheckboxExtraBlockStyles}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_key_question')}</span>
            </SquareCheckbox>
          )}
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <RadioFieldStyles style={extraBlockStyles}>
      <FieldHeader
        type={notSavedExtraFields?.[index].type || ''}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_one_from_list_title')}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
        // handleOpenOnDiagram={handleOpenOnDiagram ? () => handleOpenOnDiagram(index) : undefined}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // @ts-ignore
          validationSchema={notSavedExtraFields?.[index].type ? extraFieldValidationSchema[notSavedExtraFields![index].type] : undefined}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </RadioFieldStyles>
  );
}
