import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import {
  setCompanySources, setNodeMetadata, setNodeNames, useAppDispatch, useAppSelector,
} from '../../../state';
import {
  FormValues, getFilteredNodeNames, networks, validationSchema,
} from './EditNodeUtils';
import { ICompanyNodeExtraField, ICompanyUser } from '../../../entities';
import { Api } from '../../../api';
import {
  getErrorMessage, getNodeNamesArray, getNodesDeepArray, handleKeyUp,
} from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import InputField from '../../form/inputField/InputField';
import CustomSelectTiedNode from '../../form/customSelect/CustomSelectTiedNode';
import Checkbox from '../../form/checkbox/Checkbox';
import CustomSelect from '../../form/customSelect/CustomSelect';
import CustomSelectResponsible from '../../form/customSelect/CustomSelectResponsible';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { EditNodeStyles } from './EditNodeStyles';
import { Loader } from '../../atoms';

type TAddNode = {
  nodeId: string;
  companyId: number;
  onClose: () => void;
}

export const EditNode = React.memo(({ nodeId, companyId, onClose }: TAddNode) => {
  const dispatch = useAppDispatch();
  const {
    selectedCompany, nodeNames, nodeMetadata, companySources,
  } = useAppSelector((state) => state.company);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [initialValues, setInitialValues] = useState<FormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [usersByCompany, setUsersByCompany] = useState<ICompanyUser[]>([])

  const nodeNameRef = useRef<FormikProps<FormValues>>(null);

  function getFormInitialData(nodes: {id: number, name: string, deep: number}[]) {
    if (companyId) {
      Api.getSourcesByCompanyId(+companyId).then((res) => {
        dispatch(setCompanySources(res.data))
      }).catch((e) => console.log('get request sources err', e));
    }
    if (nodeId) {
      Api.getNodeById(+nodeId)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(setNodeMetadata(res.data));
            const data = {
              name: res.data.name,
              slug: res.data.slug,
              code: res.data.code,
              useDefaultForm: res.data.useDefaultForm,
              tiedNode: res.data.tiedNode,
              secondTiedNode: res.data.secondTiedNode,
              address: res.data.address || { value: '' },
              nodes: getNodesDeepArray(nodes),
              googlePlaceID: res.data.googlePlaceID || { value: '' },
              sms: res.data.sms || {
                active: false,
                key: '',
                alphaName: '',
              },
              viber: res.data.viber || {
                active: false,
                key: '',
                alphaName: '',
              },
              telegram: res.data.telegram || {
                active: false,
                key: '',
                alphaName: '',
              },
              whatsapp: res.data.whatsapp || {
                active: false,
                key: '',
                alphaName: '',
              },
              email: res.data.email || {
                active: false,
                key: '',
                alphaName: '',
              },
              extraFields: res.data.extraFields || [],
              sourceId: res.data.sourceId,
              responsibleID: res.data.responsibleId,
              enableOverdue: res.data.enableOverdue,
            };

            setInitialValues({ ...data });
            setIsLoading(false)
          }
        });
    }
  }

  useEffect(() => {
    setIsLoading(true)
    if (companyId !== undefined && +companyId === selectedCompany?.id && nodeNames) {
      const filteredNodeNames = [...nodeNames];
      getFormInitialData([{ id: 0, name: '-', deep: 0 }, ...(nodeId ? getFilteredNodeNames(filteredNodeNames, nodeId) : filteredNodeNames)]);
    } else if (companyId !== undefined) {
      Api.getCompanyNodes(+companyId!)
        .then((res) => {
          const nodeNamesArray: {id: number, name: string, deep: number}[] = [];

          getNodeNamesArray(nodeNamesArray, res.data || []);
          getFormInitialData([{ id: 0, name: '-', deep: 0 }, ...(nodeId ? getFilteredNodeNames(nodeNamesArray, nodeId) : nodeNamesArray)]);

          dispatch(setNodeNames(nodeNamesArray));
        });
    }
    Api.getUsersByCompany(+companyId!).then((res) => setUsersByCompany(res.data))
  }, [nodeId]);

  const onSaveModalConfirm = useCallback(async (values: FormValues) => {
    const extraFieldsWithPosition: ICompanyNodeExtraField[] = values.extraFields.map((item, index) => ({
      ...item,
      position: String(index),
    }));

    const data = {
      name: values.name,
      useDetectLanguage: values.useDefaultForm,
      enableOverdue: values.enableOverdue,
      address: values.address,
      googlePlaceID: values.googlePlaceID,
      sms: values.sms,
      viber: values.viber,
      telegram: values.telegram,
      email: values.email,
      parentID: values.tiedNode || 0,
      referencedID: values.secondTiedNode || 0,
      responsibleID: values.responsibleID || 0,
      sourceID: values.sourceId || 0,
    };

    if (nodeId && companyId) {
      const res = await Api.updateNode(+nodeId, +companyId, data);

      if (res.statusCode >= 200 && res.statusCode < 300) {
        if (values.address.id) {
          await Api.updateNodeMetadata(values.address.value, values.address.id);
        } else if (!values.address.id && values.address.value) {
          await Api.createNodeMetadata({
            key: 'address',
            parentID: +nodeId,
            value: values.address.value,
          });
        }

        if (values.googlePlaceID.id) {
          await Api.updateNodeMetadata(values.googlePlaceID.value, values.googlePlaceID.id);
        } else if (!values.googlePlaceID.id && values.googlePlaceID.value) {
          await Api.createNodeMetadata({
            key: 'googlePlaceId',
            parentID: +nodeId,
            value: values.googlePlaceID.value,
          });
        }

        const extraFieldToCreate = extraFieldsWithPosition?.filter((extraField) => !extraField.id);
        const extraFieldToUpdate = extraFieldsWithPosition?.filter((extraField) => {
          const fieldToCheck = nodeMetadata?.extraFields?.find((item) => item.id === extraField.id);

          if (fieldToCheck) {
            return fieldToCheck.value !== extraField.value || fieldToCheck.value !== extraField.name || fieldToCheck.position !== extraField.position;
          }

          return false;
        });
        const extraFieldToDelete = nodeMetadata?.extraFields?.filter((extraField) => extraFieldsWithPosition.every((item) => extraField.id !== item.id));

        if (extraFieldToDelete?.length) {
          extraFieldToDelete.forEach(async (item) => {
            await Api.deleteNodeMetadata(item.id!);
          });
        }

        if (extraFieldToCreate.length) {
          extraFieldToCreate.forEach(async (extraField) => {
            await Api.createNodeMetadata({ key: `extraField_${Math.random()}`, value: [extraField.name, extraField.value, extraField.position].join('|||'), parentID: +nodeId });
          });
        }

        if (extraFieldToUpdate.length) {
          extraFieldToUpdate.forEach(async (extraField) => {
            await Api.updateNodeMetadata([extraField.name, extraField.value, extraField.position].join('|||'), extraField.id!);
          });
        }

        const promises: Promise<any>[] = [];

        networks.forEach((socialNetwork) => {
          const socialNetworkKey: 'sms' | 'viber' | 'telegram' | 'email'| 'whatsapp' = socialNetwork.key;

          if (values[socialNetworkKey].keyId) {
            promises.push(Api.updateNodeMetadata(values[socialNetworkKey].key, values[socialNetworkKey].keyId!));
            promises.push(Api.updateNodeMetadata(values[socialNetworkKey].alphaName, values[socialNetworkKey].alphaNameId!));
            promises.push(Api.updateNodeMetadata(values[socialNetworkKey].active ? 'true' : 'false', values[socialNetworkKey].activeId!));
          }

          if (!values[socialNetworkKey].keyId && values[socialNetworkKey].key) {
            promises.push(Api.createNodeMetadata({
              key: `${socialNetworkKey}_key`,
              parentID: +nodeId,
              value: values[socialNetworkKey].key,
            }));
          }

          if (!values[socialNetworkKey].keyId && values[socialNetworkKey].alphaName) {
            promises.push(Api.createNodeMetadata({
              key: `${socialNetworkKey}_alphaName`,
              parentID: +nodeId,
              value: values[socialNetworkKey].alphaName,
            }));
          }

          promises.push(Api.createNodeMetadata({
            key: `${socialNetworkKey}_active`,
            parentID: +nodeId,
            value: values[socialNetworkKey].active ? 'true' : 'false',
          }));
        });

        await Promise.all(promises);
      } else {
        setErrorMessage(res.data);
      }
    }
  }, [nodeMetadata]);

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);
    onSaveModalConfirm(values)
    onClose()
  }

  const renderForm = ({
    values,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection additionalSettings">
        <div className="formSectionInnerWrap">
          <div>
            <InputField
              name="name"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_placeholder')}
              value={values.name}
              error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_name_label')}
              required
            />

            <InputField
              name="code"
              disabled
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('code', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_placeholder')}
              value={values.code}
              error={typeof errorMessage === 'object' ? getErrorMessage('code', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_node_code_label')}
            />

            <CustomSelectTiedNode
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_label')}
              name="tiedNode"
              options={values.nodes}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')}
              value={nodeNames?.find((node) => node.id === values.tiedNode)}
              handleSelect={(node) => {
                setFieldValue('tiedNode', node.id);
              }}
              search
              expandable
            />

            <CustomSelectTiedNode
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_tied_node_label_second')}
              name="secondTiedNode"
              options={values.nodes}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_tied_node_placeholder')}
              value={nodeNames?.find((node) => node.id === values.secondTiedNode)}
              handleSelect={(node) => {
                setFieldValue('secondTiedNode', node.id);
              }}
              formGroupStyles={{ width: '100%' }}
              search
              expandable
            />

            <InputField
              name="address.value"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('address.value', setErrorMessage, errorMessage)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_placeholder')}
              value={values.address.value}
              error={typeof errorMessage === 'object' ? getErrorMessage('address.value', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_address_label')}
            />

            <Checkbox
              name="useDefaultForm"
              value={values.useDefaultForm}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_lang_detection')}</span>
            </Checkbox>
            <Checkbox
              name="enableOverdue"
              value={values.enableOverdue}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_lang_delay')}</span>
            </Checkbox>

            <CustomSelect
              label={getTranslationByLangOrEng(interfaceLanguage, 'source')}
              name="sourceId"
              options={companySources || []}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'source')}
              value={companySources?.find((source) => source.id === values.sourceId)}
              handleSelect={(value) => {
                setFieldValue('sourceId', value.id);
              }}
              search
              emptyOption
              emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')}
            />

            <CustomSelectResponsible
              value={values.responsibleID}
              handleSelect={(value) => {
                setFieldValue('responsibleID', value.id);
              }}
              selectKey="name"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'responsible_id')}
              search
              emptyOption
              label={getTranslationByLangOrEng(interfaceLanguage, 'responsible')}
              name="responsibleID"
              options={usersByCompany || []}
            />

            <h2>{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_advanced_settings')}</h2>
            <InputField
              extraBlockStyles={{ width: '100%' }}
              name="googlePlaceID.value"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('googlePlaceID.value', setErrorMessage, errorMessage)}
              placeholder=""
              value={values.googlePlaceID.value}
              error={typeof errorMessage === 'object' ? getErrorMessage('googlePlaceID.value', errorMessage) : undefined}
              label={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_create_google_place_id_label')}
            />
            <SubmitButton>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
            {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
          </div>
        </div>
      </div>
    </Form>
  );

  return (
    <EditNodeStyles>
      {(!initialValues || isLoading) ? <Loader /> : (
        <Formik
          key={JSON.stringify(initialValues)}
          innerRef={nodeNameRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}
    </EditNodeStyles>
  );
})
