import { AddCompanyStyles } from './AddCompanyStyles';
import { useNavigate } from 'react-router-dom';
import CompanyInfoForm from '../../../components/companyInfoForm/CompanyInfoForm';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { Helmet } from 'react-helmet-async';
import { SubHeader, TransparentButton } from '../../../components';

export default function AddCompany() {
  const navigate = useNavigate();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  function handleCancel() {
    navigate('/companies');
  }

  return (
    <AddCompanyStyles>
      <Helmet>
        <title>Creating a company Voicer</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_title_add')}>
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
      </SubHeader>

      <div className="contentWrapper">
        <CompanyInfoForm />
      </div>
    </AddCompanyStyles>
  );
}
