import styled from 'styled-components';

export const CheckboxSquareStyles = styled.div`
  .default {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
  }

  .selected {
    border: 1px solid #0E9285;
  }

  .square {
    width: 10px;
    height: 10px;
    background-color: #0E9285;
  }
`
