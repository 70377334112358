import React, { useEffect, useRef, useState } from 'react';
import { getTranslationByLangOrEng } from '../../../i18n';
import {
  INode, DeepArrayItem, CompaniesMainInfo, ICompany,
} from '../../../entities';
import { useClickOutside } from '../../../hooks/useClickOutside';
import 'react-calendar/dist/Calendar.css';
import { useAppDispatch, useAppSelector } from '../../../state';
import { MassMailingTablePageStyles } from './MassMailingTablePageStyles';
import {
  ChannelOfConnectionExtraField,
  DateExtraField,
  ExtraButtons,
  NodeExtraField,
  StatusExtraField,
  TypeExtraField,
} from './MassMailingTablePageExtraFields';
import { massMailingsForTable } from '../../../fakeData';
import { Api } from '../../../api';
import { MassMailingTableFilters } from './MassMailingTableFilters';
import { ArrowDownIcon, CalendarIcon } from '../../../assets';
import { getNodeNamesArray, getNodesDictionary, getLanguages } from '../../../utils';
import {
  CalendarFilter, Loader, SubHeader, Table, TransparentButton,
} from '../../../components';

export default function MassMailingsTablePage() {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [massMailings, setMassMailings] = useState<any[]>();
  const [headers, setHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>([]);

  const [filtersOpen, setFilterOpen] = useState<boolean>(false);
  const [calendarsOpen, setCalendarsOpen] = useState<boolean>(false);
  const [reQuery, setReQuery] = useState<boolean>();

  const filtersRef = useRef<any>();
  const calendarsRef = useRef<any>();
  const filterParamsRef = useRef<{ [key: string]: number | string | string[] | number[] }>({});

  const [companies, setCompanies] = useState<{[key: string]: Partial<ICompany>}>();
  const [nodes, setNodes] = useState<{[key: string]: Partial<INode>}>();
  const [nodesWithChilds, setNodesWithChilds] = useState<DeepArrayItem[]>([]);

  const companiesRef = useRef<{[key: string]: CompaniesMainInfo}>({});
  const nodesRef = useRef<{[key: string]: Partial<INode>}>({});

  useClickOutside(filtersRef, () => setFilterOpen(false));
  useClickOutside(calendarsRef, () => setCalendarsOpen(false));

  function getCompanies(data: CompaniesMainInfo[]) {
    const newCompanies = data.reduce((acc, item) => {
      // @ts-ignore
      acc[`${item.id}`] = item;

      return acc;
    }, {});

    const companiesDictionary = companiesRef.current ? { ...companiesRef.current, ...newCompanies } : newCompanies;

    // @ts-ignore
    companiesRef.current = companiesDictionary;
    setCompanies(companiesDictionary);
  }

  function getDeepArray(initialArray: {id: number, name: string, deep: number}[]) {
    const nodesDeepArray: DeepArrayItem[] = [];

    function pushDeepNode(array: DeepArrayItem[], node: DeepArrayItem, deep: number) {
      if (deep === 0) {
        array.push(node);
      } else {
        pushDeepNode(array[array.length - 1].children, node, deep - 1);
      }
    }

    initialArray.forEach((node) => {
      const item: DeepArrayItem = { ...node, children: [] };

      pushDeepNode(nodesDeepArray, item, item.deep);
    });

    return nodesDeepArray;
  }

  async function getCompanyNodes(id: number) {
    const res = await Api.getCompanyNodes(id);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      const newNodesDictionary: {[key: number]: Partial<INode>} = {};
      const nodeNamesArray: {id: number, name: string, deep: number}[] = [];

      // @ts-ignore
      getNodesDictionary(newNodesDictionary, res.data);
      getNodeNamesArray(nodeNamesArray, res.data || []);

      const nodesDictionary = nodesRef.current ? { ...nodesRef.current, ...newNodesDictionary } : newNodesDictionary;

      if (Object.keys(nodesDictionary).length > 0) {
        // @ts-ignore
        nodesRef.current = nodesDictionary;
      }

      // eslint-disable-next-line no-use-before-define
      setNodes(nodesDictionary);

      return getDeepArray(nodeNamesArray);
    }
  }

  async function getDataFromServer() {
    await getLanguages(dispatch);
    const companies = await Api.getCompanies();
    await setMassMailings(massMailingsForTable);

    getCompanies(companies.data);
    const nodesWithChilds: any = await (await Promise.all(companies.data.map((company) => getCompanyNodes(company.id)))!).reduce((acc: any, item: any) => [...acc, ...item], []);

    setNodesWithChilds(nodesWithChilds);
  }

  useEffect(() => {
    setIsLoading(true);

    getDataFromServer()
      .then((res) => {
        setIsLoading(false);
      });
  }, []);

  useEffect(() => {
    if (nodes && massMailings && !isLoading) {
      const data = [
        {
          Header: 'Name',
          accessor: 'name',
        },
        {
          Header: 'Type of mailing',
          accessor: 'type',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <TypeExtraField typeKey={data.row.original.typeKey} />
          ),
        },
        {
          Header: 'Node',
          accessor: 'node',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <NodeExtraField data={nodes} name={nodes![data.row.original.nodeId] ? nodes![data.row.original.nodeId].name : undefined} type={data.row.original.typeKey} />
          ),
        },
        {
          Header: 'Channel of connection',
          accessor: 'connectionChannel',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <ChannelOfConnectionExtraField connectionChannelKey={data.row.original.connectionChannelKey} />
          ),
        },
        {
          Header: 'Dispatch date',
          accessor: 'date',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <DateExtraField date={data.row.original.date} />
          ),
        },
        {
          Header: 'Status',
          accessor: 'status',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <StatusExtraField statusKey={data.row.original.statusKey} />
          ),
        },
        {
          Header: 'edit',
          accessor: 'extraButtons',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <ExtraButtons id={data.row.original.id} />
          ),
        },
      ];
      setHeaders(data);
    }
  }, [nodes, isLoading]);

  const extraFieldsSettings = {
    status: {
      justifyContent: 'center',
    },
    date: {
      justifyContent: 'center',
    },
    connectionChannel: {
      justifyContent: 'center',
    },
    type: {
      justifyContent: 'center',
    },
    node: {
      justifyContent: 'center',
    },
  };

  return (
    <MassMailingTablePageStyles>
      <div>
        <SubHeader title="Mass mailings">
          <TransparentButton handleClick={() => {}} text="Create" />
        </SubHeader>
        <div className="tableFiltersWrapper">
          <div className="search" />
          <div className="filtersWrapper" ref={filtersRef}>
            <div className={filtersOpen ? 'filtersOpener filtersOpener-active' : 'filtersOpener'} onClick={() => setFilterOpen(!filtersOpen)}>
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'filters')}</span>
              <ArrowDownIcon />
            </div>
            {filtersOpen && (
            <MassMailingTableFilters
              setFilterParams={(params) => { filterParamsRef.current = params; }}
              filterParams={filterParamsRef.current}
              setReQuery={() => setReQuery(!reQuery)}
              onClose={() => setFilterOpen(false)}
              nodes={nodes!}
              companies={companies!}
              nodesWithChilds={nodesWithChilds}
            />
            )}
          </div>
          <div className="calendarWrapper" ref={calendarsRef}>
            <div className="splitter" />
            <div className="calendarOpenerWrapper" onClick={() => setCalendarsOpen(!calendarsOpen)}>
              <CalendarIcon color="#979797" />
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'choose_period')}</span>
              <div className={calendarsOpen ? 'active' : ''}>
                <ArrowDownIcon />
              </div>
            </div>
            {calendarsOpen && (
            <CalendarFilter
              setFilterParams={(params) => { filterParamsRef.current = params; }}
              filterParams={filterParamsRef.current}
              setReQuery={() => setReQuery(!reQuery)}
              onClose={() => setCalendarsOpen(false)}
              extraBlockStyles={{ right: '0px' }}
            />
            )}
          </div>
        </div>
        {isLoading && <Loader />}

        {!!massMailings && !!headers && !isLoading && (
          <Table
            pagination
            columns={headers}
            data={massMailings}
            hideFieldsSplitters
            extraFieldsSettings={extraFieldsSettings}
            hiddenHeaders={['extraButtons']}
            fullWidthColumns={['extraButtons']}
          />
        )}
      </div>
    </MassMailingTablePageStyles>
  );
}
