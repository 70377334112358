import { ApiReviewType, ApiResponse } from './api';
import { IEmailTemplate } from './entities/IEmailTemplate';

export const fakeReviewTypesResponse: ApiResponse<ApiReviewType[]> = {
  data: [
    { id: 1, name: 'Terrible', color: 'red' },
    { id: 2, name: 'Bad', color: 'pink' },
    { id: 3, name: 'Normal', color: 'orange' },
    { id: 4, name: 'Cool', color: 'yellow' },
    { id: 5, name: 'Excellent', color: 'green' },
  ],
  statusCode: 200,
};

export const massMailingsForTable: { name: string, typeKey: number, connectionChannelKey: number, date: string, statusKey: number, nodeId?: number}[] = [
  {
    name: 'SDY',
    typeKey: 1,
    date: '2022-05-19T12:16:23.049737Z',
    connectionChannelKey: 3,
    statusKey: 1,
    nodeId: 103,
  },
  {
    name: 'отображается уровень',
    typeKey: 2,
    date: '2022-05-19T12:16:23.049737Z',
    connectionChannelKey: 2,
    statusKey: 0,
  },
  {
    name: 'оммуникации по которому',
    typeKey: 1,
    date: '2022-05-19T12:16:23.049737Z',
    connectionChannelKey: 1,
    statusKey: 2,
    nodeId: 105,
  },
];

export const fakeEmail: IEmailTemplate = {
  id: 1,
  name: 'INA HR',
  emailBody: '\n'
    + '<!DOCTYPE html\n'
    + 'PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">\n'
    + '<html xmlns="http://www.w3.org/1999/xhtml">\n'
    + '<head>\n'
    + '<meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />\n'
    + '<link rel="preconnect" href="https://fonts.googleapis.com">\n'
    + '<link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>\n'
    + '<link href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" rel="stylesheet">\n'
    + '<title>HTML Template</title>\n'
    + '<meta name="viewport" content="width=device-width, initial-scale=1.0" />\n'
    + '<style>\n'
    + '\tbody {\n'
    + '\t\twidth: 100% !important;\n'
    + '\t\t-webkit-text-size-adjust: 100%;\n'
    + '\t\t-ms-text-size-adjust: 100%;\n'
    + '\t\tmargin: 0;\n'
    + '\t\tpadding: 0;\n'
    + '\t\tline-height: 100%;\n'
    + '\t}\n'
    + '\n'
    + '\thtml {\n'
    + '\t\tfont-family: \'Roboto\', sans-serif;\n'
    + '\t}\n'
    + '\n'
    + '\t[style*="Open Sans"] {font-family: \'Open Sans\', arial, sans-serif !important;}\n'
    + '\n'
    + '\timg {\n'
    + '\t\toutline: none;\n'
    + '\t\ttext-decoration: none;\n'
    + '\t\tborder:none;\n'
    + '\t\t-ms-interpolation-mode: bicubic;\n'
    + '\t\tmax-width: 100%!important;\n'
    + '\t\tmargin: 0;\n'
    + '\t\tpadding: 0;\n'
    + '\t\tdisplay: block;\n'
    + '\t}\n'
    + '\t\n'
    + '\ttable td {\n'
    + '\t\tborder-collapse: collapse;\n'
    + '\t}\n'
    + '\t\n'
    + '\ttable {\n'
    + '\t\tborder-collapse: collapse;\n'
    + '\t\tmso-table-lspace: 0pt;\n'
    + '\t\tmso-table-rspace: 0pt;\n'
    + '\t}\n'
    + '\n'
    + '\tp {\n'
    + '\t\tmargin-bottom: 0;\n'
    + '\t\tmargin-top: 0;\n'
    + '\t}\n'
    + '\n'
    + '\th1 {\n'
    + '\t\tpadding-bottom: 27px;\n'
    + '\t\tmargin-top: 0;\n'
    + '\t\tmargin-bottom: 0;\n'
    + '\n'
    + '\t\tfont-weight: 700;\n'
    + '\t\tfont-size: 50px;\n'
    + '\t\tline-height: 59px;\n'
    + '\n'
    + '\t\tcolor: #000000;\n'
    + '\t}\n'
    + '\n'
    + '\t.main {\n'
    + '\t\tbackground-color: #EFEFEF;\n'
    + '\t}\n'
    + '\n'
    + '\t.footer {\n'
    + '\t\tbackground-color: #404040;\n'
    + '\t}\n'
    + '\n'
    + '\t.greenTable {\n'
    + '\t\tbackground-color: #496EA6;\n'
    + '\t}\n'
    + '\n'
    + '\t.subtitle {\n'
    + '\t\tpadding-bottom: 54px;\n'
    + '\n'
    + '\t\tfont-weight: 400;\n'
    + '\t\tfont-size: 25px;\n'
    + '\t\tline-height: 29px;\n'
    + '\t\ttext-align: center;\n'
    + '\n'
    + '\t\tcolor: #000000;\n'
    + '\t\tmax-width: 485px;\n'
    + '\t}\n'
    + '\n'
    + '\t.greenTable {\n'
    + '\t\tborder-top-left-radius: 40px;\n'
    + '\t\tborder-top-right-radius: 40px;\n'
    + '\t}\n'
    + '\n'
    + '\t.logo {\n'
    + '\t\t\theight: 52px;\n'
    + '\t\t\twidth: 189px;\n'
    + '\t\t\tpadding-top: 39px;\n'
    + '\t\t\tpadding-bottom: 23px;\n'
    + '\t\t}\n'
    + '\n'
    + '\t.greenDescription {\n'
    + '\t\tpadding-bottom: 20px;\n'
    + '\t\tpadding-top: 30px;\n'
    + '\n'
    + '\t\tmax-width: 339px;\n'
    + '\t\tfont-weight: 700;\n'
    + '\t\tfont-size: 26px;\n'
    + '\t\tline-height: 29px;\n'
    + '\t\ttext-align: center;\n'
    + '\t\tcolor: #FFFFFF;\n'
    + '\t}\n'
    + '\n'
    + '\t.buttonText {\n'
    + '\t\tfont-weight: 500;\n'
    + '\t\tfont-size: 25px;\n'
    + '\t\tline-height: 29px;\n'
    + '\t\ttext-align: center;\n'
    + '\n'
    + '\t\tcolor: #3B3B3A;\n'
    + '\t}\n'
    + '\n'
    + '\t.locationName {\n'
    + '\t\tfont-weight: 400;\n'
    + '\t\tfont-size: 32px;\n'
    + '\t\tline-height: 38px;\n'
    + '\t\tcolor: #EFEFEF;\n'
    + '\t}\n'
    + '\n'
    + '\t.unsubscribe {\n'
    + '\t\tfont-weight: 400;\n'
    + '\t\tfont-size: 20px;\n'
    + '\t\tline-height: 23px;\n'
    + '\t\ttext-align: center;\n'
    + '\t\ttext-decoration-line: underline;\n'
    + '\t\tcolor: #EFEFEF;\n'
    + '\t}\n'
    + '\n'
    + '\t.footer p {\n'
    + '\t\tmax-width: 443px;\n'
    + '\t}\n'
    + '\n'
    + '\t.footer p a {\n'
    + '\t\t\tcolor: #000;\n'
    + '\t\t}\n'
    + '\n'
    + '\t@media (max-width: 600px) {\n'
    + '\t\t.main {\n'
    + '\t\t\twidth: 320px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.bannerImage {\n'
    + '\t\t\twidth: 320px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.mainTopPadding {\n'
    + '\t\t\theight: 60px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\th1 {\n'
    + '\t\t\tfont-size: 26px !important;\n'
    + '\n'
    + '\t\t\tpadding-bottom: 0 !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.subtitle {\n'
    + '\t\t\tfont-size: 13px !important;\n'
    + '    \tline-height: 15px !important;\n'
    + '\t\t\tmax-width: 227px;\n'
    + '\t\t\tpadding-bottom: 24px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.greenTable {\n'
    + '\t\t\twidth: 280px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.greenDescription {\n'
    + '\t\t\tfont-size: 10px !important;\n'
    + '    \tline-height: 12px !important;\n'
    + '\t\t\tmax-width: 160px;\n'
    + '\t\t\tpadding-top: 15px;\n'
    + '\t\t\tpadding-bottom: 25px;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.button a {\n'
    + '\t\t\tpadding-bottom: 7px !important;\n'
    + '\t\t\tpadding-top: 7px !important;\n'
    + '\t\t\twidth: 156px !important;\n'
    + '\t\t\theight: 30px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.button {\n'
    + '\t\t\tpadding-bottom: 23px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.buttonText {\n'
    + '\t\t\tfont-size: 18px !important;\n'
    + '\t\t\tline-height: 30px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.locationName {\n'
    + '\t\t\tfont-size: 10px !important;\n'
    + '    line-height: 12px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.footer {\n'
    + '\t\t\twidth: 280px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.paddingAfterBanner {\n'
    + '\t\t\theight: 20px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.footerPadding {\n'
    + '\t\t\theight: 13px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.locationPadding {\n'
    + '\t\t\theight: 23px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.unsubscribe {\n'
    + '\t\t\tfont-size: 12px !important;\n'
    + '    \tline-height: 15px !important;\n'
    + '\t\t}\n'
    + '\n'
    + '\t\t.footer p {\n'
    + '\t\t\tmax-width: 190px;\n'
    + '\t\t\tfont-size: 10px;\n'
    + '\t\t}\n'
    + '\t}\n'
    + '\n'
    + '</style>\n'
    + '</head>\n'
    + '\n'
    + '<body style="margin: 0; padding: 0;">\n'
    + '\t<div style="font-size:0px;font-color:#ffffff;opacity:0;visibility:hidden;width:0;height:0;display:none;">{{.Subject}}</div>\n'
    + '\t<table cellpadding="0" cellspacing="0" width="100%" align="center">\n'
    + '\t\t<tr>\n'
    + '\t\t\t<td align="center">\n'
    + '\t\t\t\t<table class="main" cellpadding="0" cellspacing="0" width="600" align="center" bgcolor="#EFEFEF">\n'
    + '\t\t\t\t\t\n'
    + '\t\t\t\t\t\n'
    + '\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t<img class="logo" src="https://dub01pap001files.storage.live.com/y4mtQdLS1DatnvT5Heag_uZek-j8-ePJ7KQH4o9t7k1WWRqEcXGRcG5V9arYaIRLhfEB43VPAmmVPIyOdgPC8iQFlZw8Oaj7pUwK9fTqlxKQhp5tLtFRG17Gs6EkZdNDWirpjJN6NNFWXTg-6tqv7M2kwD2RKJpVLV4uf-lGiFi-Q9SyIZRaEprX8eNGA2jb770?width=189&height=52&cropmode=none" alt="">\n'
    + '\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t</tr>\n'
    + '\t\t\t\t\t\n'
    + '\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t<td>\n'
    + '\t\t\t\t\t\t\t<img class="bannerImage" src="https://dub01pap001files.storage.live.com/y4mJ4T1WVCITfZISDFjYcD8qbLNdQ9_wvyDQyJKTnJzMvUatPubLOS6JFQMNDyp9Y4KP6y_ENG9v908HdkkHdB0xLtqXzY60U2PXrxnRYchnCN-j8Fgm9AyGJ7PfidbD8T5JKnkj32XmBNvvPDks3Gg0jylBoZYPZQDt4eIvOOpzP95ynSQCPxrtAq7xH_yQ6jJ?width=600&height=283&cropmode=none" alt="slovnaft" >\n'
    + '\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t<td height="54" class="paddingAfterBanner"></td>\n'
    + '\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t<td>\n'
    + '\t\t\t\t\t\t\t<h1 style="text-align: center;">Poštovani,</h1>\n'
    + '\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t<p class="subtitle">\n'
    + '\t\t\t\t\t\t\t\thvala Vam što ste posjetili naše maloprodajno mjesto ({{.Name}}) dana ({{.Date}}).\n'
    + '\t\t\t\t\t\t\t\tKontinurano radimo na poboljšanju naših usluga i osiguravamo najviši standard za vas, naše vjerne kupce. \n'
    + '\t\t\t\t\t\t\t</p>\n'
    + '\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t<table cellpadding="0" cellspacing="0" align="center" class="greenTable" width=\'521\' bgcolor="#496EA6">\n'
    + '\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t\t\t\t<p class="greenDescription">\n'
    + '\t\t\t\t\t\t\t\t\t\t\tVoljeli bismo čuti više o Vašem iskustvu. Anketa Vam neće oduzeti više od 2 minute vremena. Vaše povratne informacije pomoći će nam u daljnjem razvoju. \n'
    + '\t\t\t\t\t\t\t\t\t\t</p>\n'
    + '\t\t\t\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t<td align="center" style="border-collapse:collapse;padding-bottom: 29px;" class="button">\n'
    + '\t\t\t\t\t\t\t\t\t\t<a target="_blank" href="{{.Href}}" style="display: block; width: 293px; height: 63px; text-decoration: none; color: #000000; background-color: #FFFFFF;">\n'
    + '\t\t\t\t\t\t\t\t\t\t\t<span style="line-height: 250%;" class="buttonText">\n'
    + '\t\t\t\t\t\t\t\t\t\t\t\tPregled\n'
    + '\t\t\t\t\t\t\t\t\t\t\t</span\n'
    + '\t\t\t\t\t\t\t\t\t\t</a>\n'
    + '\t\t\t\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t\t\t\t<span class="locationName">\n'
    + '\t\t\t\t\t\t\t\t\t\t\tHvala Vam, \n'
    + '\t\t\t\t\t\t\t\t\t\t\t<br />\n'
    + '\t\t\t\t\t\t\t\t\t\t\tINA\n'
    + '\t\t\t\t\t\t\t\t\t\t</span>\n'
    + '\t\t\t\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t<td height=\'23\' class="locationPadding"></td>\n'
    + '\t\t\t\t\t\t\t\t</tr>\n'
    + '\n'
    + '\n'
    + '\t\t\t\t\t\t\t\t\n'
    + '\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t\t\t\t<table bgcolor="#404040" width="521" class="footer">\n'
    + '\t\t\t\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t\t<td height="26" class="footerPadding"></td>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t\t<td align="center">\n'
    + '\t\t\t\t\t\t\t\t\t\t\t\t\t<a href="" target="_blank" class="unsubscribe">\n'
    + '                            unsubscribe\n'
    + '                          </a>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t</tr>\n'
    + '\n'
    + '\t\t\t\t\t\t\t\t\t\t\t<tr>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t\t<td height="26" class="footerPadding"></td>\n'
    + '\t\t\t\t\t\t\t\t\t\t\t</tr>\n'
    + '\t\t\t\t\t\t\t\t\t\t</table>\n'
    + '\t\t\t\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t\t\t\t</tr>\n'
    + '\t\t\t\t\t\t\t</table>\n'
    + '\t\t\t\t\t\t</td>\n'
    + '\t\t\t\t\t</tr>\n'
    + '\t\t\t\t</table>\n'
    + '\t\t\t</td>\n'
    + '\t\t</tr>\n'
    + '\t</table>\n'
    + '</body>\n'
    + '\n'
    + '</html>',
  senderEmail: {
    name: 'INA@gmail.com',
    id: 1,
  },
  attachedFiles: [],
  senderName: 'INA',
  letterSubject: 'Please take a few minutes and give feedback on our location',
};
