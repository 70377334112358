import {
  CSSProperties, useEffect, useState,
} from 'react';
import { SimpleFieldStyles } from './SimpleFieldStyles';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import Checkbox from '../../form/checkbox/Checkbox';
import FieldHeader from '../fieldHeader/FieldHeader';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getTranslationByLangOrEng } from '../../../i18n';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import { IExtraField, IExtraFieldType } from '../../../entities/IExtraField';
import { extraFieldValidationSchema } from '../validationSchemas';
import { Api } from '../../../api';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import StepTextSettings from '../stepTextSettings/StepTextSettings';
import InputRaw from '../inputRaw/InputRaw';
import { Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface SimpleFieldProps {
  index: number;
  extraBlockStyles?: CSSProperties;
  disabled?: boolean;

  checkboxExtraBlockStyles?: CSSProperties;
  checkboxDisabled?: boolean;

  squareCheckboxOnChange?: () => void;
  squareCheckboxExtraBlockStyles?: CSSProperties;
  squareCheckboxDisabled?: boolean;

  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;

  provided?: DraggableProvided;
}

function getFieldTitle(lang: string, fieldType: IExtraFieldType) {
  switch (fieldType) {
    case 'name':
      return getTranslationByLangOrEng(lang, 'companies_form_constructor_name_title');
    case 'email':
      return getTranslationByLangOrEng(lang, 'companies_form_constructor_email_title');
    case 'phone_number':
      return getTranslationByLangOrEng(lang, 'companies_form_constructor_phone_title');
    case 'extra_question':
      return getTranslationByLangOrEng(lang, 'companies_form_constructor_full_answer_title');
    default:
      return getTranslationByLangOrEng(lang, 'companies_form_constructor_title_title');
  }
}

export default function SimpleField({
  index,
  extraBlockStyles,
  disabled,

  checkboxExtraBlockStyles,
  checkboxDisabled,

  squareCheckboxOnChange,
  squareCheckboxExtraBlockStyles,
  squareCheckboxDisabled,

  draggable,
  handleCopy,
  handleRemove,
  // handleOpenOnDiagram,

  provided,
}: SimpleFieldProps) {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedExtraFields, notSavedForm } = useAppSelector((state) => state.notSavedForm);

  const [nextFieldOptions, setNextFieldOptions] = useState<IExtraField[]>();
  const [initialValues, setInitialValues] = useState<IExtraField>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (notSavedExtraFields) {
      setInitialValues(notSavedExtraFields[index]);
    }
  }, [index]);

  async function onSubmit(
    values: IExtraField,
    { setSubmitting }: FormikHelpers<IExtraField>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateField(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(updateNotSavedExtraField(values));
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IExtraField>) {
    const titleError = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].question`, errorMessage) : undefined;
    const placeholderError = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].placeholder`, errorMessage) : undefined;
    const additionalFieldPlaceholderError = typeof errorMessage === 'object' ? getErrorMessage(`extraFields][${index}].additionalFieldPlaceholder`, errorMessage) : undefined;
    const placeholderName = values.type === 'title' ? undefined : 'placeholder';
    const checkboxName = values.type === 'title' ? 'nodeAddressMustBeShown' : 'required';
    const checkboxText = values.type === 'title'
      ? getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_display_node_address')
      : getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question');
    const squareCheckboxName = values.type === 'extra_question' ? 'keyQuestion' : undefined;

    return (
      <Form>
        <div className="body">
          <InputRaw
            name="question"
            value={values.question || ''}
            setFieldValue={setFieldValue}
            error={titleError}
            touched={touched}
            handleKeyUp={handleKeyUp}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            translationKeys={['companies_form_constructor_title_title', 'companies_form_constructor_title_placeholder', 'companies_form_constructor_info_question']}
          />

          {placeholderName && (
            <InputRaw
              name="placeholder"
              value={values.placeholder || ''}
              setFieldValue={setFieldValue}
              error={placeholderError}
              touched={touched}
              handleKeyUp={handleKeyUp}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              translationKeys={['companies_form_constructor_title_placeholder_title', 'companies_form_constructor_title_placeholder', 'companies_form_constructor_info_placeholder']}
            />
          )}

          {notSavedForm?.withSteps && values.type !== 'title' && (
            <StepTextSettings
              stepsText={values.stepsText || ''}
              handleKeyUp={handleKeyUp}
              setFieldValue={setFieldValue}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          )}

          {checkboxName && (
            <Checkbox
              name={checkboxName}
              value={values.type === 'title' ? !!values.nodeAddressMustBeShown : !!values.required}
              onChange={handleChange}
              extraBlockStyles={checkboxExtraBlockStyles}
              disabled={checkboxDisabled}
            >
              <span className="checkboxValue">{checkboxText}</span>
            </Checkbox>
          )}

          {squareCheckboxName && squareCheckboxOnChange && (
            <SquareCheckbox
              name={squareCheckboxName}
              value={values.keyQuestion!}
              onChange={(event) => {
                squareCheckboxOnChange();
                handleChange(event);
              }}
              extraBlockStyles={squareCheckboxExtraBlockStyles}
              disabled={squareCheckboxDisabled}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_key_question')}</span>
            </SquareCheckbox>
          )}
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <SimpleFieldStyles style={extraBlockStyles}>
      <FieldHeader
        type={notSavedExtraFields?.[index].type || ''}
        title={getFieldTitle(interfaceLanguage, notSavedExtraFields[index].type)}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // @ts-ignore
          validationSchema={notSavedExtraFields?.[index].type ? extraFieldValidationSchema[notSavedExtraFields![index].type] : undefined}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </SimpleFieldStyles>
  );
}
