import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const MessageIcon: FC<TIcon> = React.memo(({ width = 512, height = 512, color = '#000' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 512 512"
  >
    <path fill={color} d="M511.1 63.1v287.1c0 35.25-28.75 63.1-64 63.1h-144l-124.9 93.68c-7.875 5.75-19.12.05-19.12-9.7V413.3h-96c-35.25 0-64-28.75-64-63.1V63.1c0-35.25 28.75-63.1 64-63.1h384c36.12 0 64.02 28.75 64.02 63.1z" />
  </svg>
));
