import styled from 'styled-components';

export const TablePaginationStyles = styled.div<{mobileVersion: boolean}>`
  padding: ${({ mobileVersion }) => (mobileVersion ? '8px 18px 8px 8px' : '16px 36px 16px 16px')};
  border-bottom: 1px solid #F0F0F0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  select {
    border: none;
    background: none;
  }
  
  .arrowsContainer {
    box-sizing: border-box;
    display: grid;
    grid-template-columns: 48px auto 48px;
    grid-gap: ${({ mobileVersion }) => (mobileVersion ? '12px' : '24px')}24px;

    .arrows {
      display: flex;
      justify-content: space-between;
    }

    .pageCounter {
      color: #000;
      padding: 0 16px;

      span {
        font-weight: bold;
        font-size: ${({ mobileVersion }) => (mobileVersion ? '12px' : '14px')};
      }
      
      .currentPage  {
        color: #1BCAB9;
        width: ${({ mobileVersion }) => (mobileVersion ? '60px' : '80px')};
        height: ${({ mobileVersion }) => (mobileVersion ? '24px' : '32px')};
        font-size: ${({ mobileVersion }) => (mobileVersion ? '12px' : '14px')};
        background-color: #FFFFFF;
        border: 1px solid #E5E5E5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;

        &::placeholder {
          font-family: Roboto, sans-serif;
          font-size: ${({ mobileVersion }) => (mobileVersion ? '12px' : '14px')};
          color: #979797;
        }

        &.notValid {
          border: 1px solid #FF0101;
        }
      }
    }
  }
`;
