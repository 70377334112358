import styled from 'styled-components';

export const QuantityStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 300px;
  width: 100%;
  height: 50px;

  button {
    width: 25%;
    height: 100%;
    padding: 0;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-sizing: border-box;
    margin: 0;
    background: #fff1f9;
    font-size: 18px;
    font-weight: 600;
    color: #000000;
    
    &:first-of-type {
      border-radius: 8px 0 0 8px;
    }
    
    &:last-of-type {
      border-radius: 0 8px 8px 0;
    }
  }

  .valueContainer {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0;
    outline: none;
    border: 1px solid rgba(0, 0, 0, 0.03);
    box-sizing: border-box;

    input {
      height: 100%;
      min-width: 8px;
      
      padding: 0;
      outline: none;
      border: none;
      box-sizing: border-box;
      background-color: transparent;
      
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
    }

    span {
      margin-bottom: 5px;
      margin-left: 4px;
      text-transform: uppercase;
      font-weight: 800;
      font-size: 10px;
      line-height: 12px;
    }
  }
`;
