import styled from 'styled-components';

export const RadioButtonStyles = styled.div`
  .fieldName {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    margin-top: 0;
    margin-bottom: 8px;
  }
  
  .radioDefault {
    border: 0;
    clip: rect(0 0 0 0);
    clippath: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
  
  .radioWrapper {
    display: grid;
    grid-template-columns: 22px 1fr;
    grid-gap: 12px;
    cursor: pointer;
  }
  
  .customizedRadio {
    cursor: pointer;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 22px;
    height: 22px;
    background-color: #FFFFFF;
    border: 1px solid #E5E5E5;
    border-radius: 11px;
    box-sizing: border-box;
  }

  .customizedRadioChecked {
    border: 1px solid #0E9285;
  }

  .customizedRadioCheckedDisabled {
    border: 1px solid #E5E5E5;
  }
  
  .checked {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    background-color: #0E9285;
  }

  .innerRoundDisabled {
    background-color: #E5E5E5;
  }
  
  .radioText {
    line-height: 22px;
    font-size: 14px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
`;
