import { Dispatch, SetStateAction, useState } from 'react';
import { Link } from 'react-router-dom';
import { Api } from '../../../api';
import {
  ArrowDownIcon, DeleteIcon,
  FeedbacksListIcon, FormsListIcon,
  HorizontalDashedLineIcon, PencilIcon,
  VerticalDashedLineIcon,
} from '../../../assets';
import { Modal } from '../../../components';
import { INode } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';

export type TEditButtons = {
  haveChildren: boolean,
  nodeName: string,
  nodeId: number,
  companyId: number,
  companyName: string,
  handleDelete: (id: number) => void,
  setSelectedNode: Dispatch<SetStateAction<string>>
  setEditModal: Dispatch<SetStateAction<boolean>>
}

export const extraFieldsSettings = {
  companyNodes: {
    justifyContent: 'flex-start',
  },
  editButtons: {
    flexDirections: 'column',
    justifyContent: 'center',
    alignItems: 'flex-end',
  },
};

export const tabsStructure: (lang: string) => {[key: string]: string}[] = (lang) => ([
  {
    key: 'structure',
    text: getTranslationByLangOrEng(lang, 'companies_structure'),
  },
  {
    key: 'company-users',
    text: 'Company users',
  },
]);

export function EditButtons({
  haveChildren,
  nodeName,
  nodeId,
  companyId,
  companyName,
  handleDelete,
  setSelectedNode,
  setEditModal,
}: TEditButtons) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [modal, setModal] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);

  return (
    <>
      {modal && (
        <Modal onClose={() => setModal(false)} extraStyles={{ width: '544px' }}>
          <>
            <div className="modalTitle"><p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_title')}</p></div>
            <div className="modalButtonsWrap">
              <p className="modalButtonsCaption">
                {`${getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_description_1')} "${nodeName}" ${getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_description_2')} "${companyName}"? ${getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_delete_description_3')}!`}
              </p>
              <div>
                <button type="button" onClick={() => setModal(false)}>
                  {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
                </button>
                <button
                  type="button"
                  onClick={
                    () => {
                      Api.deleteNode(nodeId)
                        .then((res) => {
                          if (res.statusCode === 200) {
                            handleDelete(nodeId);
                            setModal(false);
                          } else {
                            setDeleteError(true);
                          }
                        });
                    }
                  }
                >
                  {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
                </button>
              </div>
              {deleteError && (
                <p className="modalError">{`* ${getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}`}</p>
              )}
            </div>
          </>
        </Modal>
      )}
      <div className="editButtonsContainer">
        <div className="editButtons">
          <div className="buttonContainer">
            <button
              type="button"
              onClick={() => {
                setSelectedNode(`${nodeId}`);
                setEditModal(true)
              }}
            >
              <PencilIcon />
            </button>
          </div>
          <div className="buttonContainer">
            <button
              type="button"
              onClick={() => {
                setModal(true);
              }}
              disabled={haveChildren}
            >
              <DeleteIcon color={haveChildren ? '#dedede' : '#FF0101'} />
            </button>
          </div>
          <div className="splitter">
            <div />
          </div>
          <div className="buttonContainer">
            <Link
              className="openButton"
              title={getTranslationByLangOrEng(interfaceLanguage, 'go_to_forms_page_button_title')}
              to={`/companies/${companyId}/nodes/${nodeId}/forms`}
            >
              <FormsListIcon />
            </Link>
          </div>
          <div className="buttonContainer">
            <Link
              className="openButton"
              title={getTranslationByLangOrEng(interfaceLanguage, 'go_to_reviews_page_button_title')}
              to={`/reviews/page/1?companyID=${companyId}&nodes=${nodeId}`}
            >
              <FeedbacksListIcon />
            </Link>
          </div>
        </div>
      </div>
    </>
  );
}

export function filterSubNodes(deletedRows: {[key: string]: boolean}, subNodes: INode[]) {
  const deletedRowsArray = Object.entries(deletedRows).filter((item) => item[1]).map((item) => item[0]);
  return subNodes.filter((item: INode) => !deletedRowsArray.includes(`${item.id}`));
}

export function Node({
  node,
  deep,
  expanded,
  deletedRows,
  numberOfVerticalBorders,
}: {
  node: any,
  deep: number | undefined,
  expanded?: boolean,
  deletedRows: {[key: string]: boolean},
  numberOfVerticalBorders?: number[],
}) {
  const subNodes = node.subNodes ? filterSubNodes(deletedRows, node.subNodes) : [];

  return (
    <div className={expanded ? 'nodeName' : 'nodeName notFirstNode'} style={{ marginLeft: deep ? deep * 33 : 0 }}>
      {deep !== undefined && deep > 0 && (
        <>
          <span className="horizontalLineContainer">
            <HorizontalDashedLineIcon />
          </span>

          {numberOfVerticalBorders && numberOfVerticalBorders.length > 0 && numberOfVerticalBorders.map((item, index) => {
            if (item) {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <span className="verticalLineContainer" key={`verticalLine:${node.id}:${index}`} style={{ left: -25 - (33 * index) }}>
                  <VerticalDashedLineIcon />
                </span>
              );
            }

            return null;
          })}
        </>
      )}
      <div className="arrowCircleContainer">
        {subNodes?.length > 0 && (
          <div className="arrowBG">
            <ArrowDownIcon width={10} color="#555" />
          </div>
        )}
      </div>
      <span>{node.name}</span>
    </div>
  );
}

export const getNodesWithChildsBySearchParams = (arr: Partial<INode>[], searchParams: string) => {
  const arrayCopy = JSON.parse(JSON.stringify(arr));

  return arrayCopy.filter((item: { name: string; subNodes: string | any[]; }) => {
    // @ts-ignore
    const found = item.name.toLowerCase().includes(searchParams.toLowerCase());

    if (item.subNodes && item.subNodes.length > 0) {
      item.subNodes = getNodesWithChildsBySearchParams(item.subNodes as Partial<INode>[], searchParams);
    }

    return found || (item.subNodes && item.subNodes.length > 0);
  });
}

export const countBorderNumber = (originalArray: [(string | number), number][], resultArray: [string, number[]][], item: [number| string, number], index: number) => {
  if (item[1] === 0) {
    resultArray.push([item[0] as string, []]);
  } else if (index === 0) {
    resultArray.push([item[0] as string, [1]]);
  } else {
    if (item[1] === originalArray[index - 1][1]) {
      resultArray.push([item[0] as string, [...resultArray[index - 1][1]]]);
    }

    if (item[1] - originalArray[index - 1][1] === 1) {
      resultArray.push([item[0] as string, Array(resultArray[index - 1][1].length + 1).fill(1)]);
    }

    if (item[1] - originalArray[index - 1][1] > 1) {
      resultArray.push([item[0] as string, [1, ...Array(item[1] - originalArray[index - 1][1] - 1).fill(0), ...resultArray[index - 1][1]]]);
    }

    if (item[1] < originalArray[index - 1][1]) {
      if (originalArray[index - 1][1] === 0) {
        resultArray.push([item[0] as string, [1]]);
      } else {
        const copy = [...resultArray[index - 1][1]];
        copy.splice(1, 1);

        resultArray.push([
          item[0] as string,
          copy,
        ]);
      }
    }
  }
}
