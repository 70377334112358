import React from 'react'
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';

type TReport = {
    accessToken: string
    reportId: string
    groupId: string
}

export const Report = React.memo(({ accessToken, reportId, groupId } : TReport) => (
  <div className="report">
    <PowerBIEmbed
      embedConfig={{
        type: 'report',
        id: reportId,
        embedUrl: `${process.env.REACT_APP_EMBEDED_URL}?reportId=${reportId}&groupId=${groupId}`,
        accessToken,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
            pageNavigation: {
              visible: true,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      }}
      eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.log(event?.detail); }],
          ])
        }
    />
  </div>
));
