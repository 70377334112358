import styled from 'styled-components';

export const FormStyles = styled.div`
  min-height: calc(100vh - 104px);
  
  .formContainer {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 100px 0;
    box-sizing: border-box;
    min-height: 100vh;
    
    .buttonsContainer {
      position: absolute;
      top: 32px;
      right: 32px;
    }
    
    form {
      width: 100%;
      max-width: 544px;
    }
  }

  .body {
    padding-bottom: 38px;
    width: 100%;
    background: #FFFFFF;
    backdrop-filter: blur(40px);
    box-shadow: 0 2px 5px #afafaf;
    border-radius: 4px;
    box-sizing: border-box;
    
    .header {
      height: 52px;
      padding: 0 32px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border-bottom: 1px solid #EEEEEE;

      .languageSelect {
        position: relative;
        color: #000000;
      }
      
      .selectedLanguage {
        color: #000;
        font-size: 18px;
        font-weight: 600;
        display: flex;
        align-items: center;
        
        &.opened {
          svg {
            transform: rotate(-180deg);
            transition: all .3s;
          }
        }
        
        svg {
          margin-left: 8px;
          margin-bottom: 2px;
          transition: all .3s;
        }
      }

      .languages {
        width: 52px;
        position: absolute;
        top: 26px;
        left: -4px;
        background-color: #fff;
        border-radius: 4px;
        display: grid;
        grid-template-columns: 1fr;
        padding: 4px 0;
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
        
        button {
          height: 28px;
          padding: 0 16px;
          display: flex;
          align-items: center;
          font-size: 14px;
          color: #000;
        }
      }
    }

    .topSection {
      display: grid;
      grid-template-columns: 1fr;
      padding: 16px 32px 0;

      .logoContainer {
        .logo {
          img, .emptyBlock {
            width: 80px;
          }

          h1 {
            flex-grow: 1;
            font-family: 'Roboto-Medium', sans-serif;
            text-align: center;
            font-weight: bold;
            font-size: 34px;
            line-height: 40px;
            color: #242424;
          }

          &.left, &.center, &.right {
            display: flex;
            align-items: center;
          }

          &.center {
            flex-direction: column;
          }

          &.right {
            flex-direction: row-reverse;
            justify-content: flex-end;
          }
        }
      }

      .titleContainer {
        width: 100%;

        .companyAddress {
          margin-top: 8px;
          font-size: 12px;
          line-height: 16px;
          text-align: center;
          color: rgba(0, 0, 0, 0.3);
        }

        .title {
          margin-top: 8px;
          margin-bottom: 21px;
          font-size: 18px;
          line-height: 22px;
          text-align: center;
          color: #202020;
        }
      }

      .mascotContainer {
        display: flex;
        justify-content: center;
        margin-bottom: 21px;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
    
    .extraFieldListContainer {
      display: flex;
      flex-direction: column;
      background-color: #fff;
      border-radius: 4px;
      padding: 32px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      box-sizing: border-box;
      
      .formExtraFields {
        width: 100%;
        
        .extraFieldContainer {
          margin-bottom: 18px;
        }
        
        .titleContainer {
          position: relative;
          
          span {
            position: absolute;
            top: 0;
            left: -8px;
            color: #FF0101;
          }
        }
        
        h3 {
          font-weight: 500;
          font-size: 18px;
          line-height: 22px;
          color: #202020;
          margin-top: 0;
          margin-bottom: 14px;
        }
      }
    }
    
    button[type="submit"] {
      margin-top: 18px;
    }
  }

  .success {
    width: 100%;
    max-width: 544px;
    min-height: calc(100vh - 200px);
    display: flex;
    justify-content: center;
    align-items: center;

    .body {
      padding: 48px 0;
    }

    h3 {
      font-weight: 900;
      font-size: 34px;
      line-height: 40px;
      text-align: center;
      margin-top: 16px;
      margin-bottom: 24px;
    }

    p {
      text-align: center;
      font-size: 18px;
      line-height: 24px;
    }
    
    .completeButtonRaw {
      display: flex;
      justify-content: center;

      .completeButtonContainer {
        width: 240px;
      }
    }
  }
`;
