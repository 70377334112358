import styled from 'styled-components';

export const SubHeaderStyles = styled.div<{tableVersion: boolean}>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${({ tableVersion }) => (tableVersion ? '48px' : '64px')};
  padding-left: ${({ tableVersion }) => (tableVersion ? '12px' : '16px')};
  padding-right: ${({ tableVersion }) => (tableVersion ? '24px' : '32px')};
  border-bottom: 1px solid #F0F0F0;
  box-sizing: border-box;
  
  &.withoutBorder {
    border-bottom: none;
  }

  h1 {
    font-weight: 500;
    font-size: ${({ tableVersion }) => (tableVersion ? '18px' : '24px')};
    line-height: ${({ tableVersion }) => (tableVersion ? '21px' : '28px')};
    color: #000000;
  }

  .titleContainer {
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    color: #0E9285;

    a {
      color: #0E9285;
    }

    .arrowRight {
      margin: 0 4px;
      font-weight: 600;
      color: #222;
    }
    
    .path {
      display: flex;
    }
  }
`;
