import { CSSProperties, useEffect, useState } from 'react';
import { ThankYouPageFieldStyles } from './ThankYouPageFieldStyles';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import FieldHeader from '../fieldHeader/FieldHeader';
import { getTranslationByLangOrEng } from '../../../i18n';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
import { mapIColorToString } from '../../../mappers';
import Checkbox from '../../form/checkbox/Checkbox';
import { InfoCharIcon } from '../../../assets';
import SelectWithAnyItems from '../../form/customSelect/SelectWithAnyItems';
import { thankYouPageValidationSchema } from '../validationSchemas';
import { useAppDispatch, useAppSelector, setNotSavedThankYouPage } from '../../../state';
import { IThankYouPage, IImage } from '../../../entities';
import { Api } from '../../../api';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import InputRaw from '../inputRaw/InputRaw';
import { FormErrorMessage, Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface ThankYouPageFieldProps {
  companyImages: IImage[] | undefined;
  extraBlockStyles?: CSSProperties;
}

export default function ThankYouPageField({
  companyImages,
  extraBlockStyles,
}: ThankYouPageFieldProps) {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const {
    notSavedThankYouPage,
    notSavedSendButton,
    notSavedColors,
  } = useAppSelector((state) => state.notSavedForm);

  const [initialValues, setInitialValues] = useState<IThankYouPage>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (notSavedThankYouPage) {
      // setNextFieldOptions(notSavedForm.extraFields.filter((item: IExtraField) => item.id !== notSavedForm.extraFields![index].id));
      setInitialValues(notSavedThankYouPage);
    }
  }, []);

  async function onSubmit(
    values: IThankYouPage,
    { setSubmitting }: FormikHelpers<IThankYouPage>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateThankYouPage(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(setNotSavedThankYouPage(values));
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IThankYouPage>) {
    const pageTitleError = typeof errorMessage === 'object' ? getErrorMessage('title', errorMessage) : undefined;
    const pageTextError = typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined;
    const pageTextFirstLineError = typeof errorMessage === 'object' ? getErrorMessage('textFirstLine', errorMessage) : undefined;
    const pageTextSecondLineError = typeof errorMessage === 'object' ? getErrorMessage('textSecondLine', errorMessage) : undefined;
    const displayScaleBackgroundColorError = typeof errorMessage === 'object' ? getErrorMessage('displayMainScaleBackgroundColor', errorMessage) : undefined;
    const displayScaleMascotError = typeof errorMessage === 'object' ? getErrorMessage('displayMainScaleMascot', errorMessage) : undefined;
    const buttonTextError = typeof errorMessage === 'object' ? getErrorMessage('buttonText', errorMessage) : undefined;
    const buttonLinkError = typeof errorMessage === 'object' ? getErrorMessage('buttonLink', errorMessage) : undefined;

    return (
      <Form>
        <div className="body">
          <InputRaw
            name="title"
            value={values.title}
            setFieldValue={setFieldValue}
            error={pageTitleError}
            touched={touched}
            handleKeyUp={handleKeyUp}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
            translationKeys={['companies_form_constructor_thank_you_page_title', 'companies_form_constructor_text', 'companies_form_constructor_info_thank_you_page_title']}
          />

          <Checkbox
            name="textInTwoRows"
            value={!!values.textInTwoRows}
            onChange={() => setFieldValue('textInTwoRows', !values.textInTwoRows)}
            // extraBlockStyles={checkboxExtraBlockStyles}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_show_text_in_two_rows')}</span>
          </Checkbox>

          {values.textInTwoRows ? (
            <>
              <InputRaw
                name="textFirstLine"
                value={values.textFirstLine}
                setFieldValue={setFieldValue}
                error={pageTextFirstLineError}
                touched={touched}
                handleKeyUp={handleKeyUp}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                translationKeys={['companies_form_constructor_thank_you_page_text_first_line', 'companies_form_constructor_text', 'companies_form_constructor_info_thank_you_page_text']}
              />
              <InputRaw
                name="textSecondLine"
                value={values.textSecondLine}
                setFieldValue={setFieldValue}
                error={pageTextSecondLineError}
                touched={touched}
                handleKeyUp={handleKeyUp}
                errorMessage={errorMessage}
                setErrorMessage={setErrorMessage}
                translationKeys={['companies_form_constructor_thank_you_page_text_second_line', 'companies_form_constructor_text', 'companies_form_constructor_info_thank_you_page_text']}
              />
            </>
          ) : (
            <InputRaw
              name="text"
              value={values.text}
              setFieldValue={setFieldValue}
              error={pageTextError}
              touched={touched}
              handleKeyUp={handleKeyUp}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              translationKeys={['companies_form_constructor_thank_you_page_text', 'companies_form_constructor_text', 'companies_form_constructor_info_thank_you_page_text']}
            />
          )}

          <div className="backgroundColorForText">
            <h4>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_background_color_for_text_title')}</h4>

            <div className="colorSelectContainer">
              <CustomColorsSelect
                name="backgroundColorForText"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={notSavedColors || []}
                value={values.backgroundColorForText}
                handleSelect={(color) => setFieldValue('backgroundColorForText', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  // setFieldValue(`colors[${colors!.length}]`, color);
                  setFieldValue('backgroundColorForText', color);
                }}
              />
            </div>
          </div>

          <div className="settingsContainer">
            <h4>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_background_color')}</h4>

            <div className="colorSelectContainer">
              <CustomColorsSelect
                name="backgroundColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={notSavedColors || []}
                value={values.backgroundColor}
                handleSelect={(color) => setFieldValue('backgroundColor', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  // setFieldValue(`colors[${colors!.length}]`, color);
                  setFieldValue('backgroundColor', color);
                }}
              />
            </div>

            <Checkbox
              name="displayMainScaleBackgroundColor"
              value={values.displayMainScaleBackgroundColor}
              onChange={handleChange}
              error={displayScaleBackgroundColorError}
              // extraBlockStyles={checkboxExtraBlockStyles}
              // disabled={checkboxDisabled}
            >
              <div className="checkboxWithInfo">
                <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_display_scale_background_color')}</span>

                <div className="infoContainer">
                  <div className="infoIcon">
                    <InfoCharIcon />
                  </div>
                  <div className="infoText">
                    <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_display_scale_background_color_info')}</p>
                  </div>
                </div>
              </div>
            </Checkbox>
          </div>

          <div className="settingsContainer">
            <h4>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_mascot')}</h4>

            <SelectWithAnyItems
              name="mascot"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_choose_main_mascot_button')}
              options={companyImages || []}
              value={values.mascot}
              handleSelect={(image) => setFieldValue('mascot', image)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              hideErrors
              renderItem={(option) => (
                <div className="imageOptionItem">
                  <div className="imageContainer">
                    <img src={option.url} alt={option.name} />
                  </div>

                  <p>{option.name}</p>
                </div>
              )}
              renderSelect={(value) => <p>{value.name}</p>}
              optionExtraClassName="imageOption"
              optionsExtraClassName="imageOptions"
              search
            />

            <Checkbox
              name="displayMainScaleMascot"
              value={values.displayMainScaleMascot}
              onChange={handleChange}
              error={displayScaleMascotError}
              // extraBlockStyles={checkboxExtraBlockStyles}
              // disabled={checkboxDisabled}
            >
              <div className="checkboxWithInfo">
                <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_display_scale_mascot')}</span>

                <div className="infoContainer">
                  <div className="infoIcon">
                    <InfoCharIcon />
                  </div>
                  <div className="infoText">
                    <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_display_scale_mascot_info')}</p>
                  </div>
                </div>
              </div>
            </Checkbox>
          </div>

          <div className="settingsContainer">
            <h4>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button')}</h4>

            <div className="inputContainer">
              <div className="inputRaw">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_text')}</span>
                </div>
                <div className="inputElementContainer">
                  <input
                    id="buttonText"
                    type="text"
                    value={values.buttonText}
                    onChange={(event) => setFieldValue('buttonText', event.target.value)}
                    onKeyUp={() => handleKeyUp('buttonText', setErrorMessage, errorMessage)}
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_text')}
                  />
                  <button
                    type="button"
                    className="send_button_preview"
                    style={{
                      backgroundColor: values.buttonBackgroundColor ? mapIColorToString(values.buttonBackgroundColor) : '#0E9285',
                      color: values.buttonTextColor ? mapIColorToString(values.buttonTextColor) : '#FFF',
                    }}
                  >
                    {values.buttonText || 'Send'}
                  </button>
                </div>
              </div>
              <div className="formErrorContainer">
                {buttonTextError && <span>{buttonTextError}</span>}
                {!buttonTextError && touched && <ErrorMessage name="buttonText" component={FormErrorMessage} />}
              </div>
            </div>

            <div className="colorSelectContainer">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_text_color')}</span>
              </div>
              <CustomColorsSelect
                name="buttonTextColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={notSavedColors || []}
                value={values.buttonTextColor}
                handleSelect={(color) => setFieldValue('buttonTextColor', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  // setFieldValue(`colors[${colors!.length}]`, color);
                  setFieldValue('buttonTextColor', color);
                }}
              />
            </div>

            <div className="colorSelectContainer">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_background_color')}</span>
              </div>
              <CustomColorsSelect
                name="buttonBackgroundColor"
                placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                options={notSavedColors || []}
                value={values.buttonBackgroundColor}
                handleSelect={(color) => setFieldValue('buttonBackgroundColor', color)}
                formGroupStyles={{ width: '300', marginBottom: 0 }}
                extraComponentPosition="left"
                handleAddColor={(color) => {
                  // setFieldValue(`colors[${colors!.length}]`, color);
                  setFieldValue('buttonBackgroundColor', color);
                }}
              />
            </div>

            <div className="inputContainer">
              <div className="inputRaw">
                <div className="nameContainer">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_link')}</span>
                </div>
                <div className="inputElementContainer">
                  <input
                    id="buttonLink"
                    type="text"
                    value={values.buttonLink}
                    onChange={(event) => setFieldValue('buttonLink', event.target.value)}
                    onKeyUp={() => handleKeyUp('buttonLink', setErrorMessage, errorMessage)}
                    placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_text')}
                  />
                </div>
              </div>
              <div className="formErrorContainer">
                {buttonLinkError && <span>{buttonLinkError}</span>}
                {!buttonLinkError && touched && <ErrorMessage name="buttonLink" component={FormErrorMessage} />}
              </div>
            </div>
          </div>
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <ThankYouPageFieldStyles style={extraBlockStyles}>
      <FieldHeader
        type="thankYouPage"
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_thank_you_page_field_title')}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={notSavedThankYouPage ? thankYouPageValidationSchema(interfaceLanguage) : undefined}
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </ThankYouPageFieldStyles>
  );
}
