import React from 'react';
import { Oval } from 'react-loader-spinner';
import styled from 'styled-components';

const LoaderStyles = styled.div<{margin: number}>`
  width: 100%;
  display: flex;
  justify-content: center;
  margin: ${({ margin }) => `${margin}px auto`};
`;

export const Loader = React.memo(({ height = 40, color = '#0E9285', margin = 88 } : {height?: number, color?: string, margin?: number}) => (
  <LoaderStyles margin={margin}>
    <Oval color={color} height={height} width={height} strokeWidth={height / 5} strokeWidthSecondary={height / 5} secondaryColor={`${color}99`} />
  </LoaderStyles>
))
