import { PermissionsStyles } from './PermissionsStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import { SearchIcon } from '../../assets';
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { Api, ApiResponse } from '../../api';
import { IPermission } from '../../entities';
import { availablePageSizes } from '../../constants';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import {
  EditButtons, extraFieldsSettings, NameCustomField, StaticCheckBox,
} from './PermissionsUtils';
import {
  Loader, SubHeader, Table, TransparentButton,
} from '../../components';

export const Permissions = () => {
  const navigate = useNavigate();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [searchParams, setSearchParams] = useState<string>('');
  const [itemsListChanged, setItemsListChanged] = useState<boolean>(false);
  const [tableHeaders, setTableHeaders] = useState<{Header: string, accessor: string, Cell?: any}[]>();
  const [uploading, setUploading] = useState<boolean>(false);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [init, setInit] = useState<boolean>(true);

  const permissionsRef = useRef<IPermission[]>();
  const itemsQuantityRef = useRef<number>(0);
  const pageSizeRef = useRef<number>(availablePageSizes[0]);

  function workWithResponse(response: ApiResponse<IPermission[]>) {
    if (response.statusCode >= 200 && response.statusCode < 300) {
      const newItems = response.data;

      permissionsRef.current = [
        ...(permissionsRef.current || []),
        ...(Array.isArray(newItems)
          ? newItems
          : [])];
      itemsQuantityRef.current = response.count!;
      setItemsListChanged(!itemsListChanged);
    }
  }

  useEffect(() => {
    setCurrentPage(0);
    setInit(true);
    setUploading(true);
    Api.getPermissions(currentPage * pageSizeRef.current, pageSizeRef.current)
      .then((response) => {
        workWithResponse(response);
      })
      .finally(() => {
        setInit(false);
        setUploading(false);
      });
  }, []);

  useEffect(() => {
    if (!uploading && !init) {
      setInit(true);
      setUploading(true);
      permissionsRef.current = undefined;
      Api.getPermissions(currentPage * pageSizeRef.current, pageSizeRef.current)
        .then((response) => {
          workWithResponse(response);
        })
        .finally(() => {
          setInit(false);
          setUploading(false);
        });
    }
  }, [currentPage]);

  const filterItemsAfterDelete = useCallback((id: number) => {
    permissionsRef.current = permissionsRef.current?.filter((user) => user.id !== id) || [];
    setItemsListChanged(!itemsListChanged);
  }, [permissionsRef.current]);

  const filteredItems = useMemo(() => permissionsRef.current?.filter(
    (permission) => permission.name!.toLowerCase().includes(searchParams.toLowerCase()),
  ), [searchParams, permissionsRef.current, uploading]);

  useEffect(() => {
    if (permissionsRef.current?.length) {
      setTableHeaders([
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_permission_header'),
          accessor: 'permission',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField name={data.row.original.name} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_resource_header'),
          accessor: 'resource',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <NameCustomField name={data.row.original.resource} textColor="#979797" />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_create_header'),
          accessor: 'create',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(0)} />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_read_header'),
          accessor: 'read',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(1)} />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_update_header'),
          accessor: 'update',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(2)} />,
        },
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_table_delete_header'),
          accessor: 'delete',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => <StaticCheckBox value={data.row.original.permissions.includes(3)} />,
        },
        {
          Header: 'Edit buttons',
          accessor: 'editButtons',
          // eslint-disable-next-line react/no-unstable-nested-components
          Cell: (data: any) => (
            <EditButtons id={data.row.original.id} filterItemsAfterDelete={filterItemsAfterDelete} />
          ),
        },
      ]);
    }
  }, [permissionsRef.current]);

  const onClickToRow = async (data: any) => {
    navigate(`/users/permissions/${data.id}`);
  };

  return (
    <PermissionsStyles>
      <Helmet>
        <title>Access rights Voicer</title>
      </Helmet>

      <div>
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_title')}>
          <div className="buttonsContainer">
            <TransparentButton handleClick={() => navigate('/users/permissions/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'permissions_main_add_permission_button')} filled />
            <TransparentButton handleClick={() => navigate('/users/roles')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
          </div>
        </SubHeader>
        <div className="searchWrapper">
          <SearchIcon />
          <input
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')}
            type="text"
            value={searchParams}
            onChange={(e) => setSearchParams(e.target.value)}
          />
        </div>

        {uploading && <Loader />}

        {!!permissionsRef.current && !!tableHeaders && !uploading && (
          <Table
            pagination
            columns={tableHeaders}
            data={filteredItems}
            hideFieldsSplitters
            extraFieldsSettings={extraFieldsSettings}
            headerColumnCounts={{ permission: itemsQuantityRef.current }}
            rowTitle={getTranslationByLangOrEng(interfaceLanguage, 'open_permission_title')}
            hiddenHeaders={['editButtons']}
            fullWidthColumns={['editButtons']}
            pageSize={pageSizeRef.current}
            currentPage={currentPage}
            rowsQuantity={itemsQuantityRef.current}
            pageNumberHandler={(pageNumber) => setCurrentPage(pageNumber)}
            onClickToRow={onClickToRow}
            sortableColumns={['name']}
          />
        )}
      </div>
    </PermissionsStyles>
  );
}
