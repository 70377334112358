import {
  CompaniesMainInfo, ICompany,
  IDocument, IIssueType,
  IIssueStatus, INode,
  IColor, ISocialNetwork,
  ICompanyNodeExtraField, IOneReviewForm,
  IReviewForm, IForm, IFormListItem,
  ICompanyDocument, ICompanyExtraField,
  IImage, ILanguage, IIssue,
  IUser, IUserRole, IPermission,
  IReviewDiscussionMessage, IReviewType,
  IExtraField, IOption,
  ISendButton, IThankYouPage,
  IExtraFieldType, IFormDocument,
  IUserPermission,
} from '../entities';
import {
  ApiCompanyDocument, ApiCompany,
  ApiDocument, ApiIssueStatus,
  ApiIssueType, ApiUser,
  ApiUserRole, ApiPermission,
  ApiNode, ApiReviewType,
  ApiReviewForm, ApiReviewFormOne,
  ApiReviewDiscussionMessage,
  ApiLanguage, ApiIssue,
  ApiMetadata, ApiField, ApiForm, ApiOption,
} from './entities';
import { format } from 'date-fns';
import { mapIImageToString } from '../mappers';
import { XYPosition } from '@reactflow/core/dist/esm/types';
import { addNewScaleAnswers, getColorFromString } from '../utils';

export function mapIColorToString(item: IColor) {
  return `${item.name}|||${typeof item.color === 'object'
    ? `rgba(${item.color.r}, ${item.color.g}, ${item.color.b}, ${item.color.a})`
    : item.color}`;
}

function mapApiColorSettingsToIColor(apiExtraField: ApiField, settingKey: string): IColor | undefined {
  const colorSettings = apiExtraField.options?.find((option) => option.key === settingKey)?.value.split('|||');
  return colorSettings ? {
    name: colorSettings[0],
    color: colorSettings[1],
  } : undefined;
}

function mapApiColorsSettingsToIColors(apiExtraField: ApiField, settingKey: string): IColor[] | undefined {
  return apiExtraField.options
    ?.filter((option) => option.key.startsWith(settingKey))
    .map((option) => {
      const colorSettings = option.value.split('|||');

      return {
        name: colorSettings[0],
        color: colorSettings[1],
      };
    });
}

function mapApiImagesSettingsToIImages(apiExtraField: ApiField, settingKey: string): IImage[] | undefined {
  return apiExtraField.options
    ?.filter((option) => option.key.startsWith(settingKey))
    .map((option) => {
      const imageSettings = option.value.split('|||');

      return {
        name: imageSettings[0],
        url: imageSettings[1],
      };
    });
}

export function mapApiLanguageToILanguage(apiLanguage: ApiLanguage): ILanguage {
  return {
    id: apiLanguage.id,
    name: apiLanguage.name,
    icon: apiLanguage.icon,
  };
}

export function mapApiLanguagesToILanguages(apiLanguages: ApiLanguage[]): ILanguage[] {
  return apiLanguages.map((lang) => mapApiLanguageToILanguage(lang));
}

export function mapILanguageToApiLanguage(iLanguage: ILanguage): ApiLanguage {
  return {
    id: iLanguage.id,
    name: iLanguage.name,
    icon: iLanguage.icon,
  };
}

export function mapILanguagesToApiLanguages(iLanguages: ILanguage[]): ApiLanguage[] {
  return iLanguages.map((lang) => mapILanguageToApiLanguage(lang));
}

export function mapApiExtraFieldToIExtraField(apiExtraField: ApiField): IExtraField {
  const type: IExtraFieldType = apiExtraField.type.startsWith('scale') ? 'scale' : apiExtraField.type as IExtraFieldType;
  const nodeAddressMustBeShown: boolean = apiExtraField.type === 'title'
    && apiExtraField.options?.find((option) => option.key === 'show_address')?.value === 'true';
  const nodeAddress: string = (apiExtraField.type === 'title'
    && apiExtraField.options?.find((option) => option.key === 'node_address')?.value) || '';

  const apiKeyQuestionFieldKeyValue = apiExtraField.options?.find((item) => item.key === 'key_question')?.value;
  const apiMainScaleFieldKeyValue = apiExtraField.options?.find((item) => item.key === 'isMain')?.value;
  const apiRequiredFieldKeyValue = apiExtraField.options?.find((option) => option.key === 'required')?.value;

  const placeholder: string | undefined = apiExtraField.options?.find((option) => option.key === 'placeholder')?.value;
  const keyQuestion: boolean | undefined = apiKeyQuestionFieldKeyValue !== undefined ? apiKeyQuestionFieldKeyValue === 'true' : undefined;
  const mainScale: boolean | undefined = apiMainScaleFieldKeyValue !== undefined ? apiMainScaleFieldKeyValue === 'true' : undefined;
  const required: boolean | undefined = apiRequiredFieldKeyValue !== undefined ? apiRequiredFieldKeyValue === 'true' : undefined;
  const position: string = apiExtraField.options?.find((option) => option.key === 'position')?.value || '1';
  const fieldInSeriesApi: string | undefined = apiExtraField.options?.find((option) => option.key === 'field_in_series')?.value;
  const fieldInSeries: boolean = fieldInSeriesApi ? fieldInSeriesApi === 'true' : false;
  let options: IOption[] | undefined;
  let answers: IOption[] | undefined;
  let selectedOption: string | undefined;
  const coordinates: XYPosition | undefined = apiExtraField.options?.find((option) => option.key === 'coordinates')?.value.split(';')
    .reduce((acc: {[key: string]: number}, coordinate, index) => {
      acc[index === 0 ? 'x' : 'y'] = +coordinate;
      return acc;
    }, {}) as (XYPosition | undefined);
  const questionDescription: string | undefined = apiExtraField.options?.find((option) => option.key === 'description')?.value;
  const stepsTextUnderlineColor: IColor | undefined = mapApiColorSettingsToIColor(apiExtraField, 'steps_text_underline_color');
  const stepsTextColor: IColor | undefined = mapApiColorSettingsToIColor(apiExtraField, 'steps_text_color');

  if (['checkbox', 'radio', 'scale_color', 'scale_number', 'scale_star', 'scale_nps'].includes(apiExtraField.type)) {
    let descriptions: string[][] | undefined;

    if (apiExtraField.type === 'radio' || apiExtraField.type === 'checkbox') {
      descriptions = apiExtraField.options?.filter((option) => option.key === 'answer_description_for').map((item) => item.value.split('|||'));
    }

    answers = apiExtraField.answers?.map((answer) => ({
      id: answer.id,
      value: answer.text,
      fieldID: answer.fieldID,
      nextField: answer.nextField,
      ...(apiExtraField.type === 'checkbox' ? { selected: false } : {}),
    })) || [];

    if (['checkbox', 'radio'].includes(apiExtraField.type)) {
      answers = answers!.map((option) => ({
        ...option,
        showChoiceDescription: descriptions?.map((item) => item[0]).includes(option.value) || false,
        choiceDescriptionTitle: descriptions?.find((item) => item[0] === option.value)?.[1] || '',
        choiceDescriptionPlaceholder: descriptions?.find((item) => item[0] === option.value)?.[2] || '',
        choiceDescriptionRequired: descriptions?.find((item) => item[0] === option.value)?.[3] === 'true' || false,
      }));
    }
  }

  if (['upload_files', 'networks'].includes(apiExtraField.type)) {
    const allFileTypes = ['document', 'image', 'audio', 'video'];
    const allNetworks = ['email', 'sms', 'call', 'telegram', 'viber', 'whatsapp', 'without answer'];
    const optionsString = apiExtraField.options?.find((option) => option.key === 'options')?.value || '';
    const existedOptions = optionsString.split('|||');

    options = (apiExtraField.type === 'upload_files' ? allFileTypes : allNetworks).map((item) => ({
      value: item,
      selected: false,
    }));

    existedOptions?.forEach((item) => {
      const optionIndex = (apiExtraField.type === 'upload_files' ? allFileTypes : allNetworks).indexOf(item);

      if (optionIndex >= 0) {
        options?.splice(optionIndex, 1);
        options?.splice(optionIndex, 0, {
          value: (apiExtraField.type === 'upload_files' ? allFileTypes : allNetworks)[optionIndex],
          selected: true,
        });
      }
    });
  }

  if (apiExtraField.type === 'radio') {
    const selectedOptionString = apiExtraField.options?.find((option) => option.key === 'options')?.value || '';
    selectedOption = selectedOptionString.split('|||')[0];
  }

  // if (apiExtraField.type === 'networks') {
  //   answer = apiExtraField.options?.find((item) => item.key === 'options')?.value.split('|||').map((type) => ({ key: type, value: '' }));
  // }

  let scaleGradientColors: IColor[] | undefined;
  let scalePointerColor: IColor | undefined;
  let numbersColor: IColor | undefined;
  let selectedButtonColor: IColor | undefined;
  let selectedStarColor: IColor | undefined;
  let notSelectedStarBorder: IColor | undefined;
  let notSelectedStarBackground: IColor | undefined;
  let maxValue: number | string | undefined;
  let defaultValue: number | string | undefined;
  let signatureMin: string | undefined;
  let signatureMax: string | undefined;
  let changeBackgroundColor: boolean | undefined;
  let backgroundColors: IColor[] | undefined;
  let changeMascot: boolean | undefined;
  let mascots: IImage[] | undefined;
  let signatureColor: IColor | undefined;
  let showDefaultValue: boolean | undefined;

  if (apiExtraField.type.startsWith('scale')) {
    selectedOption = apiExtraField.type.split('_')[1];
    options = [{ key: 'color', value: 'color' }, { key: 'number', value: 'number' }, { key: 'star', value: 'star' }, { key: 'nps', value: 'nps' }];

    if (selectedOption === 'color') {
      scaleGradientColors = mapApiColorsSettingsToIColors(apiExtraField, 'main_scale_color');
      scalePointerColor = mapApiColorSettingsToIColor(apiExtraField, 'pointer_color');
    }

    if (selectedOption === 'number') {
      numbersColor = mapApiColorSettingsToIColor(apiExtraField, 'numbers_color');
      selectedButtonColor = mapApiColorSettingsToIColor(apiExtraField, 'selected_button_color');
    }

    if (selectedOption === 'star') {
      selectedStarColor = mapApiColorSettingsToIColor(apiExtraField, 'selected_star_color');
      notSelectedStarBorder = mapApiColorSettingsToIColor(apiExtraField, 'not_selected_star_border');
      notSelectedStarBackground = mapApiColorSettingsToIColor(apiExtraField, 'not_selected_star_background');
    }

    if (['nps', 'star', 'number'].includes(selectedOption)) {
      const value = apiExtraField.options?.find((option) => option.key === 'show_default_value')?.value;
      showDefaultValue = value !== 'false';
    }

    maxValue = apiExtraField.options?.find((option) => option.key === 'max_value')?.value;
    maxValue = typeof maxValue === 'string' ? +maxValue : maxValue;
    defaultValue = apiExtraField.options?.find((option) => option.key === 'default_value')?.value;
    defaultValue = typeof defaultValue === 'string' ? +defaultValue : defaultValue;
    signatureMin = apiExtraField.options?.find((option) => option.key === 'signature_min')?.value;
    signatureMax = apiExtraField.options?.find((option) => option.key === 'signature_max')?.value;
    changeBackgroundColor = apiExtraField.options?.find((option) => option.key === 'change_background')?.value === 'true';
    backgroundColors = mapApiColorsSettingsToIColors(apiExtraField, 'background_color');
    changeMascot = apiExtraField.options?.find((option) => option.key === 'change_mascot')?.value === 'true';
    mascots = mapApiImagesSettingsToIImages(apiExtraField, 'mascot');
    signatureColor = mapApiColorSettingsToIColor(apiExtraField, 'signature_text_color');
  }

  let minValue: number | string | undefined;
  let buttonsBackgroundColor: IColor | undefined;
  let buttonsSignsColor: IColor | undefined;

  if (apiExtraField.type === 'quantity') {
    maxValue = apiExtraField?.options?.find((item) => item.key === 'max_quantity_value')?.value;
    maxValue = typeof maxValue === 'string' ? +maxValue : maxValue;
    minValue = apiExtraField?.options?.find((item) => item.key === 'min_quantity_value')?.value;
    minValue = typeof minValue === 'string' ? +minValue : minValue;
    defaultValue = apiExtraField?.options?.find((item) => item.key === 'default_quantity_value')?.value;
    defaultValue = typeof defaultValue === 'string' ? +defaultValue : defaultValue;
    buttonsBackgroundColor = apiExtraField?.options?.find((item) => item.key === 'quantity_buttons_background_color')?.value.split('|||').reduce((acc: IColor, item, index) => {
      acc[index === 0 ? 'name' : 'color'] = item;
      return acc;
    }, { name: '', color: '' });
    buttonsSignsColor = apiExtraField?.options?.find((item) => item.key === 'quantity_buttons_signs_color')?.value.split('|||').reduce((acc: IColor, item, index) => {
      acc[index === 0 ? 'name' : 'color'] = item;
      return acc;
    }, { name: '', color: '' });
  }

  let showNameInputField: boolean | undefined;
  let countryCodeNotEditable: boolean | undefined;
  const nameInputRequiredFor: {[key: string]: boolean} = {
    sms: false,
    call: false,
    telegram: false,
    viber: false,
    email: false,
  };

  if (apiExtraField.type === 'networks') {
    showNameInputField = apiExtraField?.options?.find((item) => item.key === 'show_name_input_for')?.value === 'true' || false;
    countryCodeNotEditable = apiExtraField?.options?.find((item) => item.key === 'country_code_not_editable')?.value === 'true' || false;

    apiExtraField?.options?.find((item) => item.key === 'name_input_required_for')?.value?.split('|||').forEach((item) => {
      nameInputRequiredFor[item] = true;
    })
  }

  let showAdditionalField: boolean | undefined;
  let additionalFieldPlaceholder: string | undefined;

  if (apiExtraField.type === 'extra_question') {
    showAdditionalField = apiExtraField?.options?.find((item) => item.key === 'show_additional_field')?.value === 'true' || false;
    additionalFieldPlaceholder = apiExtraField?.options?.find((item) => item.key === 'additional_field_placeholder')?.value || '';
  }

  return {
    // ...apiExtraField,
    id: apiExtraField.id,
    formID: apiExtraField.formID,
    fieldID: apiExtraField.fieldID,
    position,
    fieldInSeries,
    type,
    question: apiExtraField.question || '',
    stepsText: apiExtraField.options?.find((item) => item.key === 'steps_text')?.value,
    stepsTextUnderlineColor,
    stepsTextColor,
    ...(placeholder !== undefined && { placeholder }),
    ...(keyQuestion !== undefined && { keyQuestion }),
    ...(mainScale !== undefined && { mainScale }),
    ...(required !== undefined && { required }),
    ...(nodeAddressMustBeShown !== undefined && { nodeAddressMustBeShown }),
    ...(nodeAddress !== undefined && { nodeAddress }),
    ...(options !== undefined && { options }),
    ...(answers !== undefined && { answers }),
    ...(selectedOption !== undefined && { selectedOption }),
    ...(scaleGradientColors !== undefined && { scaleGradientColors }),
    ...(scalePointerColor !== undefined && { scalePointerColor }),
    ...(numbersColor !== undefined && { numbersColor }),
    ...(selectedButtonColor !== undefined && { selectedButtonColor }),
    ...(selectedStarColor !== undefined && { selectedStarColor }),
    ...(notSelectedStarBorder !== undefined && { notSelectedStarBorder }),
    ...(notSelectedStarBackground !== undefined && { notSelectedStarBackground }),
    ...(maxValue !== undefined && { maxValue: maxValue as number }),
    ...(defaultValue !== undefined && { defaultValue: defaultValue as number }),
    ...(signatureMin !== undefined && { signatureMin }),
    ...(signatureMax !== undefined && { signatureMax }),
    ...(changeBackgroundColor !== undefined && { changeBackgroundColor }),
    ...(backgroundColors !== undefined && { backgroundColors }),
    ...(changeMascot !== undefined && { changeMascot }),
    ...(mascots !== undefined && { mascots }),
    ...(questionDescription !== undefined && { questionDescription }),
    ...(signatureColor !== undefined && { signatureColor }),
    ...(minValue !== undefined && { minValue: minValue as number }),
    ...(buttonsBackgroundColor !== undefined && { buttonsBackgroundColor }),
    ...(buttonsSignsColor !== undefined && { buttonsSignsColor }),
    ...(coordinates !== undefined && { coordinates }),
    ...(showNameInputField !== undefined && { showNameInputField }),
    ...(countryCodeNotEditable !== undefined && { countryCodeNotEditable }),
    ...(showDefaultValue !== undefined && { showDefaultValue }),
    ...(apiExtraField.type === 'networks' && { nameInputRequiredFor }),
    ...(apiExtraField.type === 'extra_question' && { showAdditionalField }),
    ...(apiExtraField.type === 'extra_question' && { additionalFieldPlaceholder }),
  };
}

export function mapApiDocumentToIDocument(apiExtraField: ApiField): IFormDocument {
  const required: boolean = apiExtraField.options?.find((option) => option.key === 'required')?.value === 'true';
  const position: string = apiExtraField.options?.find((option) => option.key === 'position')?.value || '1';
  const fieldInSeriesApi: string | undefined = apiExtraField.options?.find((option) => option.key === 'field_in_series')?.value;
  const fieldInSeries: boolean = fieldInSeriesApi === 'true';
  const setTermsAgreedApi: string | undefined = apiExtraField.options?.find((option) => option.key === 'set_terms_agreed')?.value;
  const setTermsAgreed: boolean = setTermsAgreedApi === 'true';
  const coordinates: XYPosition | undefined = apiExtraField.options?.find((option) => option.key === 'coordinates')?.value.split(';')
    .reduce((acc: {[key: string]: number}, coordinate, index) => {
      acc[index === 0 ? 'x' : 'y'] = +coordinate;
      return acc;
    }, {}) as (XYPosition | undefined);
  // const documentId = apiExtraField.options?.find((option) => option.key === 'id')!.value!;

  const file_id = apiExtraField.options?.find((option) => option.key === 'file_id')?.value;

  const selected: ICompanyDocument | undefined = file_id === undefined ? undefined : {
    id: +(file_id),
    name: apiExtraField.options?.find((option) => option.key === 'file_name')!.value!,
    url: apiExtraField.options?.find((option) => option.key === 'file_url')!.value!,
    description: apiExtraField.options?.find((option) => option.key === 'file_description')!.value!,
  };

  return {
    formID: apiExtraField.formID,
    id: apiExtraField.id,
    type: 'document',
    required,
    position,
    description: apiExtraField.question!,
    selected,
    options: [],
    fieldID: apiExtraField.fieldID,
    coordinates,
    fieldInSeries,
    setTermsAgreed,
  };
}

function getColorPartsFromApiString(options: ApiOption[], key: string): string[] | undefined {
  return options.find((item) => item.key === key)?.value?.split('|||');
}

function getIColorFromColorParts(colorParts: string[] | undefined, defaultName: string, defaultColor: {r: number, g: number, b: number, a: number } | string): IColor {
  return {
    name: colorParts ? colorParts[0] : 'defaultName',
    color: colorParts ? getColorFromString(colorParts[1]) : defaultColor,
  }
}

export function mapApiSendButtonToISendButton(apiExtraField: ApiField | undefined): ISendButton {
  if (apiExtraField) {
    const position: string | undefined = apiExtraField.options?.find((option) => option.key === 'position')?.value;
    const text: string = apiExtraField.options?.find((option) => option.key === 'text')?.value || '';
    const textColorParts = getColorPartsFromApiString(apiExtraField.options || [], 'text_color');
    const backgroundColorParts = getColorPartsFromApiString(apiExtraField.options || [], 'background_color');
    const sendButtonDisabledBackgroundColorParts = getColorPartsFromApiString(apiExtraField.options || [], 'send_button_disabled_background_color');
    const sendButtonDisabledTextColor = getColorPartsFromApiString(apiExtraField.options || [], 'send_button_disabled_text_color');

    return {
      formID: apiExtraField.formID,
      id: apiExtraField.id,
      type: 'send_button',
      position,
      text,
      textColor: getIColorFromColorParts(textColorParts, 'White', '#fff'),
      backgroundColor: getIColorFromColorParts(backgroundColorParts, 'Default color', '#0E9285'),
      sendButtonDisabledBackgroundColor: getIColorFromColorParts(sendButtonDisabledBackgroundColorParts, 'Default disabled color', '#8c8c8c'),
      sendButtonDisabledTextColor: getIColorFromColorParts(sendButtonDisabledTextColor, 'White', '#fff'),
    };
  }

  return {
    formID: 0,
    type: 'send_button',
    text: '',
    textColor: { name: 'White', color: '#fff' },
    backgroundColor: { name: 'Default color', color: '#0E9285' },
    sendButtonDisabledBackgroundColor: { name: 'Default disabled color', color: '#8c8c8c' },
    sendButtonDisabledTextColor: { name: 'White', color: '#fff' },
  };
}

export function mapApiThankYouPageToIThankYouPage(apiExtraField: ApiField | undefined): IThankYouPage {
  if (apiExtraField) {
    const backgroundColorParts: string[] | undefined = apiExtraField.options?.find((item) => item.key === 'background_color')?.value?.split('|||');
    const mascotParts: string[] | undefined = apiExtraField.options?.find((item) => item.key === 'mascot')?.value?.split('|||');
    const buttonTextColorParts: string[] | undefined = apiExtraField.options?.find((item) => item.key === 'button_text_color')?.value?.split('|||');
    const buttonBackgroundColorParts: string[] | undefined = apiExtraField.options?.find((item) => item.key === 'button_background_color')?.value?.split('|||');
    const backgroundColorForTextParts: string[] | undefined = apiExtraField.options?.find((item) => item.key === 'background_color_for_text')?.value?.split('|||');

    return {
      id: apiExtraField.id,
      formID: apiExtraField.formID,
      type: 'thank_you_page',
      position: apiExtraField.options?.find((option) => option.key === 'position')?.value,
      title: apiExtraField.options?.find((option) => option.key === 'title')?.value || '',
      text: apiExtraField.options?.find((option) => option.key === 'text')?.value || '',
      textFirstLine: apiExtraField.options?.find((option) => option.key === 'text_first_line')?.value || '',
      textSecondLine: apiExtraField.options?.find((option) => option.key === 'text_second_line')?.value || '',
      textInTwoRows: apiExtraField.options?.find((option) => option.key === 'text_in_two_rows')?.value === 'true' || false,
      backgroundColorForText: backgroundColorForTextParts ? {
        name: backgroundColorForTextParts[0],
        color: getColorFromString(backgroundColorForTextParts[1]),
      } : undefined,
      backgroundColor: backgroundColorParts ? {
        name: backgroundColorParts[0],
        color: getColorFromString(backgroundColorParts[1]),
      } : undefined,
      displayMainScaleBackgroundColor: apiExtraField.options?.find((option) => option.key === 'display_main_scale_bg_color')?.value === 'true',
      mascot: mascotParts ? { name: mascotParts[0], url: mascotParts[1] } : undefined,
      displayMainScaleMascot: apiExtraField.options?.find((option) => option.key === 'display_main_scale_mascot')?.value === 'true',
      buttonText: apiExtraField.options?.find((option) => option.key === 'button_text')?.value || '',
      buttonTextColor: buttonTextColorParts ? {
        name: buttonTextColorParts[0],
        color: getColorFromString(buttonTextColorParts[1]),
      } : undefined,
      buttonBackgroundColor: buttonBackgroundColorParts ? {
        name: buttonBackgroundColorParts[0],
        color: getColorFromString(buttonBackgroundColorParts[1]),
      } : undefined,
      buttonLink: apiExtraField.options?.find((option) => option.key === 'button_link')?.value || '',
    };
  }

  return {
    formID: 0,
    type: 'thank_you_page',
    title: '',
    text: '',
    textFirstLine: '',
    textSecondLine: '',
    textInTwoRows: false,
    backgroundColorForText: { name: 'White', color: '#fff' },
    backgroundColor: undefined,
    displayMainScaleBackgroundColor: false,
    mascot: undefined,
    displayMainScaleMascot: false,
    buttonText: '',
    buttonTextColor: { name: 'White', color: '#fff' },
    buttonBackgroundColor: { name: 'Default color', color: '#0E9285' },
    buttonLink: '',
  };
}

export function mapIDocumentToApiDocument(iDocument: IFormDocument, form_id?: number): ApiField {
  const options: {key: string, value: string}[] = [
    { key: 'position', value: iDocument.position || '1' },
    { key: 'field_in_series', value: `${iDocument.fieldInSeries || false}` },
    { key: 'set_terms_agreed', value: `${iDocument.setTermsAgreed || false}` },
  ];

  options.push({ key: 'required', value: iDocument.required ? 'true' : 'false' });

  if (iDocument.selected?.id) {
    options.push({ key: 'file_id', value: `${iDocument.selected.id}` });
  }

  if (iDocument.selected?.name) {
    options.push({ key: 'file_name', value: iDocument.selected.name });
  }

  if (iDocument.selected?.url) {
    options.push({ key: 'file_url', value: iDocument.selected.url });
  }

  if (iDocument.selected?.description) {
    options.push({ key: 'file_description', value: iDocument.selected.description });
  }

  if (iDocument.coordinates !== undefined) {
    options.push({ key: 'coordinates', value: `${iDocument.coordinates.x};${iDocument.coordinates.y}` });
  }

  return {
    ...(form_id && { form_id }),
    ...(iDocument.id && { id: iDocument.id }),
    fieldID: iDocument.fieldID,
    type: 'document',
    options,
    question: iDocument.description,
    formID: iDocument.formID,
  };
}

export function mapISendButtonToApiSendButton(iSendButton: ISendButton, form_id?: number): ApiField {
  const options: {key: string, value: string}[] = [];

  options.push({ key: 'text', value: iSendButton.text! });
  options.push({ key: 'text_color', value: iSendButton.textColor ? mapIColorToString(iSendButton.textColor) : '' });
  options.push({ key: 'background_color', value: iSendButton.backgroundColor ? mapIColorToString(iSendButton.backgroundColor) : '' });
  options.push({ key: 'send_button_background_color', value: iSendButton.backgroundColor ? mapIColorToString(iSendButton.backgroundColor) : '' });
  options.push({ key: 'send_button_text_color', value: iSendButton.textColor ? mapIColorToString(iSendButton.textColor) : '' });
  options.push({
    key: 'send_button_disabled_background_color',
    value: iSendButton.sendButtonDisabledBackgroundColor
      ? mapIColorToString(iSendButton.sendButtonDisabledBackgroundColor)
      : '',
  });
  options.push({ key: 'send_button_disabled_text_color', value: iSendButton.sendButtonDisabledTextColor ? mapIColorToString(iSendButton.sendButtonDisabledTextColor) : '' });

  return {
    ...(form_id && { form_id }),
    id: iSendButton.id,
    type: 'send_button',
    options,
    question: 'asd',
    formID: iSendButton.formID,
  };
}

export function mapIThankYouPageToApiThankYouPage(iThankYouPage: IThankYouPage, form_id?: number): ApiField {
  const options: {key: string, value: string}[] = [];

  options.push({ key: 'title', value: iThankYouPage.title });
  options.push({ key: 'text_in_two_rows', value: `${iThankYouPage.textInTwoRows}` });
  options.push({ key: 'text', value: iThankYouPage.text || '' });
  options.push({ key: 'text_first_line', value: iThankYouPage.textFirstLine || '' });
  options.push({ key: 'text_second_line', value: iThankYouPage.textSecondLine || '' });
  options.push({ key: 'background_color_for_text', value: mapIColorToString(iThankYouPage.backgroundColorForText || { name: 'White', color: '#FFF' }) });
  options.push({ key: 'display_main_scale_background_color', value: `${iThankYouPage.displayMainScaleBackgroundColor}` });
  options.push({ key: 'display_main_scale_mascot', value: `${iThankYouPage.displayMainScaleMascot}` });

  if (iThankYouPage.backgroundColor) {
    options.push({ key: 'background_color', value: mapIColorToString(iThankYouPage.backgroundColor) });
  }

  if (iThankYouPage.mascot) {
    options.push({ key: 'mascot', value: mapIImageToString(iThankYouPage.mascot) });
  }

  if (iThankYouPage.buttonText) {
    options.push({ key: 'button_text', value: iThankYouPage.buttonText });
    options.push({ key: 'button_text_color', value: mapIColorToString(iThankYouPage.buttonTextColor!) });
    options.push({ key: 'button_background_color', value: mapIColorToString(iThankYouPage.buttonBackgroundColor!) });
    options.push({ key: 'button_link', value: iThankYouPage.buttonLink });
  }

  return {
    ...(form_id && { form_id }),
    id: iThankYouPage.id,
    type: 'thank_you_page',
    options,
    question: 'asd',
    formID: iThankYouPage.formID,
  };
}

export function mapIDocumentsToApiDocuments(iDocuments: IFormDocument[], form_id?: number): ApiField[] {
  return iDocuments.map((doc) => mapIDocumentToApiDocument(doc, form_id));
}

export function mapApiCompanyDocumentToICompanyDocument(apiDocument: ApiCompanyDocument): ICompanyDocument {
  return {
    companyID: apiDocument.companyID,
    name: apiDocument.name,
    description: apiDocument.description,
    url: apiDocument.url,
    id: apiDocument.file_id,
    created_at: apiDocument.created_at,
  };
}

export function mapApiFieldsToIExtraFields(apiExtraFields: ApiField[]): IExtraField[] {
  return apiExtraFields.map((field) => mapApiExtraFieldToIExtraField(field));
}

export function mapApiDocumentsToIDocuments(apiExtraFields: ApiField[]): IFormDocument[] {
  return apiExtraFields.map((field) => mapApiDocumentToIDocument(field));
}

export function mapApiCompanyDocumentsToICompanyDocuments(apiDocuments: ApiCompanyDocument[]): ICompanyDocument[] {
  return apiDocuments.map((document) => mapApiCompanyDocumentToICompanyDocument(document));
}

function addBooleanIFieldKeyToApiOptionKey(value: boolean | undefined, key: string, optionsArray: ApiOption[]) {
  if (value !== undefined) {
    optionsArray.push({ key, value: value ? 'true' : 'false' });
  }
}

export function mapIExtraFieldToApiExtraField(
  iExtraField: IExtraField,
  form_id?: number,
): ApiField {
  const type: 'title'
    | 'name'
    | 'email'
    | 'phone_number'
    | 'extra_question'
    | 'checkbox'
    | 'radio'
    | 'scale_color'
    | 'scale_number'
    | 'scale_star'
    | 'scale_nps'
    | 'upload_files'
    | 'networks'
    | 'document'
    | 'quantity' = iExtraField.type === 'scale'
      ? `${iExtraField.type}_${iExtraField.selectedOption}` as 'scale_color'
      | 'scale_number'
      | 'scale_star'
      | 'scale_nps'
      : iExtraField.type;

  const options: { key: string, value: string }[] = [
    { key: 'position', value: iExtraField.position || '1' },
    { key: 'field_in_series', value: `${iExtraField.fieldInSeries || false}` }];

  if (iExtraField.type === 'title') {
    options.push({ key: 'node_address', value: iExtraField.nodeAddress || '' });
  }

  if (iExtraField.type === 'networks') {
    options.push({ key: 'show_name_input_for', value: `${iExtraField.showNameInputField || false}` });
    options.push({ key: 'country_code_not_editable', value: `${iExtraField.countryCodeNotEditable || false}` });

    if (!!iExtraField.showNameInputField && !!iExtraField.nameInputRequiredFor) {
      const nameInputRequiredForItems: string[] = Object.keys(iExtraField.nameInputRequiredFor).filter((item) => !!iExtraField.nameInputRequiredFor![item]);
      options.push({ key: 'name_input_required_for', value: nameInputRequiredForItems.join('|||') });
    }
  }

  if (iExtraField.question !== undefined) {
    options.push({ key: 'question', value: iExtraField.question });
  }

  if (iExtraField.stepsText !== undefined) {
    options.push({ key: 'steps_text', value: iExtraField.stepsText });
  }

  if (iExtraField.stepsTextUnderlineColor !== undefined) {
    options.push({ key: 'steps_text_underline_color', value: mapIColorToString(iExtraField.stepsTextUnderlineColor) });
  }

  if (iExtraField.stepsTextColor !== undefined) {
    options.push({ key: 'steps_text_underline_color', value: mapIColorToString(iExtraField.stepsTextColor) });
  }

  if (iExtraField.placeholder !== undefined) {
    options.push({ key: 'placeholder', value: iExtraField.placeholder });
  }

  if (iExtraField.coordinates !== undefined) {
    options.push({ key: 'coordinates', value: `${iExtraField.coordinates.x};${iExtraField.coordinates.y}` });
  }

  addBooleanIFieldKeyToApiOptionKey(iExtraField.keyQuestion, 'key_question', options);
  addBooleanIFieldKeyToApiOptionKey(iExtraField.mainScale, 'isMain', options);
  addBooleanIFieldKeyToApiOptionKey(iExtraField.required, 'required', options);
  addBooleanIFieldKeyToApiOptionKey(iExtraField.nodeAddressMustBeShown, 'show_address', options);
  addBooleanIFieldKeyToApiOptionKey(iExtraField.changeBackgroundColor, 'change_background', options);
  addBooleanIFieldKeyToApiOptionKey(iExtraField.changeMascot, 'change_mascot', options);

  if (['checkbox', 'radio', 'scale'].includes(iExtraField.type) && iExtraField.answers !== undefined) {
    iExtraField.answers?.forEach((answer) => {
      if (answer.showChoiceDescription) {
        options.push(
          {
            key: 'answer_description_for',
            value: `${answer.value}|||${answer.choiceDescriptionTitle || ''}|||${answer.choiceDescriptionPlaceholder || ''}|||${answer.choiceDescriptionRequired || false}`,
          },
        );
      }
    });
  } else if ((iExtraField.options !== undefined && !iExtraField.selectedOption)
  || (iExtraField.options !== undefined && !['color', 'number', 'star'].includes(iExtraField.selectedOption!))) {
    const optionsList = iExtraField.options
      .filter((item) => (item.selected !== undefined ? item.selected : true))
      .map((item) => item.value).join('|||');
    options.push({ key: 'options', value: optionsList });
  }

  if (type === 'scale_color') {
    (iExtraField.scaleGradientColors || ['#EB5757', '#EF9644', '#F4E02D', '#18ECD6']).forEach((item, index) => {
      options.push({
        key: `main_scale_color_${index + 1}`,
        value: typeof item === 'string' ? `Default_gradient_color_${index + 1}|||${item}` : mapIColorToString(item),
      });
    });
  }

  if (type === 'scale_color') {
    options.push({ key: 'pointer_color', value: iExtraField.scalePointerColor ? mapIColorToString(iExtraField.scalePointerColor) : 'Default_pointer_color|||#EB5757' });
  }

  if (type === 'scale_number') {
    options.push({ key: 'numbers_color', value: iExtraField.numbersColor ? mapIColorToString(iExtraField.numbersColor) : 'Default_numbers_color|||#000' });
    options.push({
      key: 'selected_button_color',
      value: iExtraField.selectedButtonColor
        ? mapIColorToString(iExtraField.selectedButtonColor)
        : 'Default_selected_button_color|||#eee',
    });
  }

  if (type === 'scale_star') {
    options.push({ key: 'selected_star_color', value: iExtraField.selectedStarColor ? mapIColorToString(iExtraField.selectedStarColor) : 'Default_selected_star_color|||#FF8C19' });
    options.push({
      key: 'not_selected_star_border',
      value: iExtraField.notSelectedStarBorder
        ? mapIColorToString(iExtraField.notSelectedStarBorder)
        : 'Default_not_selected_star_border_color|||#FF8C19',
    });
    options.push({
      key: 'not_selected_star_background',
      value: iExtraField.notSelectedStarBackground
        ? mapIColorToString(iExtraField.notSelectedStarBackground)
        : 'Default_not_selected_star_background_color|||#fff',
    });
  }

  if (['scale_star', 'scale_number', 'scale_nps'].includes(type)) {
    options.push({ key: 'show_default_value', value: iExtraField.showDefaultValue === undefined ? 'false' : `${iExtraField.showDefaultValue}` });
  }

  const defaultMaxValue = {
    color: 10,
    number: 5,
    star: 5,
    nps: 10,
  };
  const defaultDefaultValue = {
    color: 1,
    number: 0,
    star: 0,
    nps: 0,
  };

  if (type.startsWith('scale')) {
    if (iExtraField.signatureColor) {
      options.push({ key: 'signature_text_color', value: mapIColorToString(iExtraField.signatureColor) });
    }
    options.push({
      key: 'max_value',
      value: iExtraField.maxValue !== undefined
        ? `${iExtraField.maxValue}`
        : `${defaultMaxValue[type.split('_')[1] as 'color' | 'number' | 'star' | 'nps']}`,
    });
    options.push({
      key: 'default_value',
      value: iExtraField.defaultValue !== undefined
        ? `${iExtraField.defaultValue}`
        : `${defaultDefaultValue[type.split('_')[1] as 'color' | 'number' | 'star' | 'nps']}`,
    });
  }

  if (iExtraField.signatureMin) {
    options.push({ key: 'signature_min', value: `${iExtraField.signatureMin}` });
  }

  if (iExtraField.signatureMax) {
    options.push({ key: 'signature_max', value: `${iExtraField.signatureMax}` });
  }

  (iExtraField.backgroundColors)?.forEach((item, index) => {
    options.push({ key: `background_color_${index + 1}`, value: mapIColorToString(item) });
  });

  (iExtraField.mascots)?.forEach((item, index) => {
    // TODO: change logic
    options.push({ key: `mascot_${index + 1}`, value: `${item.name}|||${item.url}` });
  });

  if (type === 'quantity') {
    options.push({ key: 'min_quantity_value', value: `${iExtraField.minValue}` });

    if (iExtraField.maxValue !== undefined) {
      options.push({ key: 'max_quantity_value', value: `${iExtraField.maxValue}` });
    }

    if (iExtraField.defaultValue !== undefined) {
      options.push({ key: 'default_quantity_value', value: `${iExtraField.defaultValue}` });
    }

    options.push({
      key: 'quantity_buttons_background_color',
      value: iExtraField.buttonsBackgroundColor
        ? mapIColorToString(iExtraField.buttonsBackgroundColor)
        : 'Default color|||#0E9285',
    });
    options.push({ key: 'quantity_buttons_signs_color', value: iExtraField.buttonsSignsColor ? mapIColorToString(iExtraField.buttonsSignsColor) : 'White|||#fff' });
  }

  if (type === 'extra_question') {
    options.push({ key: 'show_additional_field', value: `${iExtraField.showAdditionalField}` });
    options.push({ key: 'additional_field_placeholder', value: iExtraField.additionalFieldPlaceholder || '' });
  }

  const answers = iExtraField.answers && iExtraField.answers.length > 0 && type.startsWith('scale') && iExtraField.answers.length >= +iExtraField.maxValue!
    ? iExtraField.answers
      .map((item) => ({
        fieldID: item.fieldID,
        text: item.value,
        nextField: item.nextField,
      })).splice(0, +iExtraField.maxValue!)
    : iExtraField.answers && iExtraField.answers.length > 0 && type.startsWith('scale') && iExtraField.answers.length < +iExtraField.maxValue!
      ? iExtraField.answers
        .map((item) => ({
          fieldID: item.fieldID,
          text: item.value,
          nextField: item.nextField,
        })).concat(addNewScaleAnswers(iExtraField.answers, +iExtraField.maxValue!))
      : iExtraField.answers && iExtraField.answers.length > 0 && type === 'radio'
        ? iExtraField.answers
          .map((item) => ({
            fieldID: item.fieldID,
            text: item.value,
            nextField: item.nextField,
          }))
        : iExtraField.answers && iExtraField.answers.length > 0 && type === 'checkbox' ? iExtraField.answers
          .map((item) => ({
            fieldID: item.fieldID,
            text: item.value,
            nextField: item.nextField,
            selected: item.selected,
          })) : [];

  return {
    ...(form_id && { form_id }),
    id: iExtraField.id,
    formID: iExtraField.formID,
    fieldID: iExtraField.fieldID,
    type,
    question: iExtraField.question,
    options,
    answers,
  };
}

export function mapIExtraFieldsToApiExtraFields(
  iExtraFields: IExtraField[],
  form_id?: number,
): ApiField[] {
  return iExtraFields.map((field, index) => mapIExtraFieldToApiExtraField(
    field,
    form_id,
  ));
}

export function mapApiFormToIFormListItem(apiForm: ApiForm): IFormListItem {
  return {
    id: apiForm.id!,
    name: apiForm.name,
    // url: `https://${apiForm.alias}.voicer.software/reviews/${apiForm.slug}/${apiForm.language}`,
    updateAt: apiForm.updatedAt!,
    status: apiForm.status === 'true',
    language_id: apiForm.languageID,
    anonymous: ['true', true].includes(apiForm.anonymous),
    // feedbacks: apiForm.feedbacks,
    companyID: apiForm.companyID,
    node_id: apiForm.nodeID,
    withSteps: apiForm.options?.find((item) => item.key === 'withSteps')?.value === 'true' || apiForm.fieldID !== undefined,
  };
}

export function mapApiFormsToIFormListItems(apiForms: ApiForm[]): IFormListItem[] {
  return apiForms.map((form) => mapApiFormToIFormListItem(form));
}

export function mapApiAllTypeFieldsToIAllTypeFields(fields: ApiField[]): { extraFields?: IExtraField[];
  sendButton?: ISendButton;
  documents?: IFormDocument[];
  thankYouPage?: IThankYouPage;
} {
  const extraFields = mapApiFieldsToIExtraFields(fields.filter((field) => field.type !== 'document' && field.type !== 'send_button' && field.type !== 'thank_you_page'))
    .sort((a, b) => +a.position - +b.position);
  const documents = mapApiDocumentsToIDocuments(fields.filter((field) => field.type === 'document'))
    .sort((a, b) => ((a.position && b.position) ? (+a.position - +b.position) : 0));
  const sendButton = fields.find((field) => field.type === 'send_button');
  const thankYouPage = fields.find((field) => field.type === 'thank_you_page');

  return {
    extraFields,
    sendButton: sendButton ? mapApiSendButtonToISendButton(fields.find((field) => field.type === 'send_button')) : undefined,
    documents,
    thankYouPage: thankYouPage ? mapApiThankYouPageToIThankYouPage(fields.find((field) => field.type === 'thank_you_page')) : undefined,
  };
}

export function mapApiFormToIForm(apiForm: ApiForm): IForm {
  const backgroundColorParts: string[] | undefined = apiForm.styles?.find((item) => item.key === 'background_color')?.value?.split('|||');
  const elementsColorParts: string[] | undefined = apiForm.styles?.find((item) => item.key === 'elements_color')?.value?.split('|||');
  const sendButtonBackgroundColorParts = getColorPartsFromApiString(apiForm.styles || [], 'send_button_background_color');
  const sendButtonTextColor = getColorPartsFromApiString(apiForm.styles || [], 'send_button_text_color');
  const sendButtonDisabledBackgroundColorParts = getColorPartsFromApiString(apiForm.styles || [], 'send_button_disabled_background_color');
  const sendButtonDisabledTextColor = getColorPartsFromApiString(apiForm.styles || [], 'send_button_disabled_text_color');

  const logo: string[] | undefined = apiForm.options?.find((item) => item.key === 'logo')?.value?.split('|||');
  const font: string[] | undefined = apiForm.styles?.find((item) => item.key === 'font_family')?.value?.split('|||');
  const mainMascot: string[] | undefined = apiForm.options?.find((item) => item.key === 'default_mascot')?.value?.split('|||');

  const radioButtonBorderWidth: string | undefined = apiForm.styles?.find((item) => item.key === 'radio_button_border_width')?.value;
  const radioButtonCheckedBorderRadius: string | undefined = apiForm.styles?.find((item) => item.key === 'radio_button_checked_border_radius')?.value;
  const radioButtonCheckedSizes: string | undefined = apiForm.styles?.find((item) => item.key === 'radio_button_checked_sizes')?.value;
  const notCheckedElementsColorParts: string[] | undefined = getColorPartsFromApiString(apiForm.styles || [], 'not_checked_elements_color');
  const stepsTextUnderlineColorParts: string[] | undefined = getColorPartsFromApiString(apiForm.styles || [], 'steps_text_underline_color');
  const stepsTextColorParts: string[] | undefined = getColorPartsFromApiString(apiForm.styles || [], 'steps_text_color');
  const showDividingDecorativeLine: boolean = apiForm.styles?.find((item) => item.key === 'show_dividing_decorative_line')?.value === 'true';
  const dividingDecorativeLinePercentWidth: string = apiForm.styles?.find((item) => item.key === 'dividing_decorative_line_width_in_percent')?.value || '90';
  const dividingDecorativeLineHeight: string | undefined = apiForm.styles?.find((item) => item.key === 'dividing_decorative_line_height')?.value;
  const dividingDecorativeLineColorParts: string[] | undefined = getColorPartsFromApiString(apiForm.styles || [], 'dividing_decorative_line_color');
  const setFontWeightBold: boolean = apiForm.styles?.find((item) => item.key === 'font_weight_bold')?.value === 'true';

  return {
    id: apiForm.id,
    fieldID: apiForm.fieldID,
    name: apiForm.name,
    logo: logo ? { name: logo[0], url: logo[1] } : undefined,
    font: font ? { name: font[0], url: font[1] } : undefined,
    status: apiForm.status === 'true',
    language_id: apiForm.languageID,
    logoLocation: apiForm.styles?.find((item) => item.key === 'logoLocation')?.value || 'left',
    backgroundColor: (backgroundColorParts?.[0] && backgroundColorParts?.[1]) ? { name: backgroundColorParts[0], color: getColorFromString(backgroundColorParts[1]) } : undefined,
    elementsColor: (elementsColorParts?.[0] && elementsColorParts?.[1]) ? { name: elementsColorParts[0], color: getColorFromString(elementsColorParts[1]) } : undefined,
    mainMascot: mainMascot ? { name: mainMascot[0], url: mainMascot[1] } : undefined,
    anonymous: ['true', true].includes(apiForm.anonymous),
    isDefault: apiForm.isDefault,
    companyID: apiForm.companyID,
    node_id: apiForm.nodeID,
    withSteps: apiForm.options?.find((item) => item.key === 'withSteps')?.value === 'true' || (apiForm.fieldID !== undefined && apiForm.fieldID !== null),
    showNodeCode: apiForm.options?.find((item) => item.key === 'show_node_code')?.value !== 'false',
    startPageButtonPercentWidth: apiForm.styles?.find((item) => item.key === 'start_page_button_percent_width')?.value || '100',
    sendButtonDisabledBackgroundColor: getIColorFromColorParts(sendButtonDisabledBackgroundColorParts, 'Default disabled color', '#8c8c8c'),
    sendButtonDisabledTextColor: getIColorFromColorParts(sendButtonDisabledTextColor, 'White', '#fff'),
    sendButtonBackgroundColor: getIColorFromColorParts(sendButtonBackgroundColorParts, 'Default color', '#0E9285'),
    sendButtonTextColor: getIColorFromColorParts(sendButtonTextColor, 'White', '#fff'),
    radioButtonBorderWidth: radioButtonBorderWidth === undefined ? undefined : +radioButtonBorderWidth,
    radioButtonCheckedBorderRadius: radioButtonCheckedBorderRadius === undefined ? undefined : +radioButtonCheckedBorderRadius,
    radioButtonCheckedSizes: radioButtonCheckedSizes === undefined ? undefined : +radioButtonCheckedSizes,
    notCheckedElementsColor: getIColorFromColorParts(notCheckedElementsColorParts, 'Default', '#eee'),
    stepsTextUnderlineColor: stepsTextUnderlineColorParts ? getIColorFromColorParts(stepsTextUnderlineColorParts, 'Default', '#eee') : undefined,
    stepsTextColor: stepsTextColorParts ? getIColorFromColorParts(stepsTextColorParts, 'Default', '#eee') : undefined,
    showDividingDecorativeLine,
    dividingDecorativeLinePercentWidth,
    dividingDecorativeLineHeight: dividingDecorativeLineHeight === undefined ? undefined : +dividingDecorativeLineHeight,
    dividingDecorativeLineColor: dividingDecorativeLineColorParts ? getIColorFromColorParts(dividingDecorativeLineColorParts, 'Black', '#000000') : undefined,
    setFontWeightBold,
  };
}

export function mapIFormToApiForm(iForm: IForm): ApiForm {
  const styles = [
    { key: 'logoLocation', value: iForm.logoLocation },
    { key: 'font_weight_bold', value: iForm.setFontWeightBold ? 'true' : 'false' },
    {
      key: 'elements_color',
      value: iForm.elementsColor ? mapIColorToString(iForm.elementsColor) : '#0E9285',
    },
    {
      key: 'send_button_background_color',
      value: iForm.sendButtonBackgroundColor ? mapIColorToString(iForm.sendButtonBackgroundColor) : 'Default color|||#0E9285',
    },
    {
      key: 'send_button_text_color',
      value: iForm.sendButtonTextColor ? mapIColorToString(iForm.sendButtonTextColor) : 'White|||#fff',
    },
    {
      key: 'send_button_disabled_background_color',
      value: iForm.sendButtonDisabledBackgroundColor ? mapIColorToString(iForm.sendButtonDisabledBackgroundColor) : 'Default disabled color|||#8c8c8c',
    },
    {
      key: 'send_button_disabled_text_color',
      value: iForm.sendButtonDisabledTextColor ? mapIColorToString(iForm.sendButtonDisabledTextColor) : 'White|||#fff',
    },
    { key: 'start_page_button_percent_width', value: `${iForm.startPageButtonPercentWidth}` },
    ...(iForm.radioButtonBorderWidth !== undefined ? [{ key: 'radio_button_border_width', value: `${iForm.radioButtonBorderWidth}` }] : []),
    ...(iForm.radioButtonCheckedBorderRadius !== undefined ? [{ key: 'radio_button_checked_border_radius', value: `${iForm.radioButtonCheckedBorderRadius}` }] : []),
    ...(iForm.radioButtonCheckedSizes !== undefined ? [{ key: 'radio_button_checked_sizes', value: `${iForm.radioButtonCheckedSizes}` }] : []),
    {
      key: 'not_checked_elements_color',
      value: iForm.notCheckedElementsColor ? mapIColorToString(iForm.notCheckedElementsColor) : 'Default color|||#EEE',
    },
    ...(iForm.stepsTextUnderlineColor !== undefined ? [{
      key: 'steps_text_underline_color',
      value: mapIColorToString(iForm.stepsTextUnderlineColor),
    }] : []),
    ...(iForm.stepsTextColor !== undefined ? [{
      key: 'steps_text_color',
      value: mapIColorToString(iForm.stepsTextColor),
    }] : []),
    ...(iForm.font !== undefined ? [{ key: 'font_family', value: `${iForm.font!.name}|||${iForm.font!.url}` }] : []),
    { key: 'show_dividing_decorative_line', value: `${iForm.showDividingDecorativeLine}` },
    ...(iForm.showDividingDecorativeLine ? [{ key: 'dividing_decorative_line_width_in_percent', value: `${iForm.dividingDecorativeLinePercentWidth}` }] : []),
    ...(iForm.showDividingDecorativeLine ? [{ key: 'dividing_decorative_line_height', value: `${iForm.dividingDecorativeLineHeight}` }] : []),
    ...(iForm.showDividingDecorativeLine
      ? [{
        key: 'dividing_decorative_line_color',
        value: iForm.dividingDecorativeLineColor ? mapIColorToString(iForm.dividingDecorativeLineColor) : 'Black|||#000000',
      }] : []),
  ];

  const options: {key: string, value: string}[] = [
    { key: 'withSteps', value: `${iForm.withSteps}` },
    { key: 'show_node_code', value: `${iForm.showNodeCode}` },
    { key: 'logo', value: iForm.logo ? `${iForm.logo.name}|||${iForm.logo.url}` : '' },
    ...(iForm.mainMascot !== undefined ? [{ key: 'default_mascot', value: `${iForm.mainMascot!.name}|||${iForm.mainMascot!.url}` }] : []),
  ];

  if (iForm.backgroundColor) {
    styles.push({
      key: 'background_color',
      value: `${mapIColorToString(iForm.backgroundColor)}`,
    });
  }

  return {
    id: iForm.id,
    fieldID: iForm.fieldID,
    name: iForm.name,
    anonymous: iForm.anonymous,
    isDefault: iForm.isDefault,
    status: iForm.status ? 'true' : 'false',
    languageID: iForm.language_id,
    logo: iForm.logo?.url,
    styles,
    options,
    companyID: iForm.companyID,
    nodeID: iForm.node_id,
  };
}

export function mapFromApiMetadataToICompanyExtraField(data: ApiMetadata[]): ICompanyExtraField[] {
  return data?.map((item) => ({
    key: item.key,
    name: item.value.split('|||')[0],
    value: item.value.split('|||')[1],
    id: item.id,
    position: item.value.split('|||')[2],
  })).sort((a, b) => +a.position - +b.position);
}

export function mapFromApiMetadataToICompanyNodeExtraField(data: ApiMetadata[]): ICompanyNodeExtraField[] {
  return data?.map((item) => {
    const valueParts = item.value.split('|||');

    return {
      key: item.key,
      name: valueParts[0],
      value: valueParts[1],
      id: item.id,
      position: valueParts[2],
    }
  }).sort((a, b) => +a.position - +b.position);
}

export function mapFromApiCompanyToICompany(company: ApiCompany): CompaniesMainInfo {
  return {
    id: company.id,
    name: company.name,
    domain: company.alias,
    createAt: company.createdAt,
    updateAt: company.updatedAt,
  };
}

export function mapFromApiCompaniesToICompanies(companies: ApiCompany[]): CompaniesMainInfo[] {
  return companies.map((company) => mapFromApiCompanyToICompany(company));
}

export function mapApiMetadataToICompany(companyMetadata: ApiMetadata[]): Partial<ICompany> {
  if (companyMetadata.length) {
    const logo = companyMetadata.find((item) => item.key === 'logo');
    const extraFields = companyMetadata.filter((item) => item.key.startsWith('extraField'));
    const images = companyMetadata.filter((item) => item.key.startsWith('image'));
    const colors = companyMetadata.filter((item) => item.key.startsWith('color'));
    const fonts = companyMetadata.filter((item) => item.key.startsWith('font'));
    const reviewTableSettings = companyMetadata.filter((item) => item.key.startsWith('reviewTableSetting'));

    return {
      logo: {
        id: logo?.id,
        value: logo?.value,
      },
      extraFields: mapFromApiMetadataToICompanyExtraField(extraFields),
      logos: images.map((image) => {
        const imageParts = image.value.split('|||');

        return {
          id: image.id,
          name: imageParts[0],
          url: imageParts[1],
          created_at: image.createdAt,
        };
      }),
      colors: colors.map((color) => {
        const colorParts = color.value.split('|||');

        return {
          id: color.id,
          name: colorParts[0],
          color: getColorFromString(colorParts[1]),
          created_at: color.createdAt,
        };
      }),
      fonts: fonts.map((font) => {
        const fontParts = font.value.split('|||');

        return {
          id: font.id,
          name: fontParts[0],
          url: fontParts[1],
          created_at: font.createdAt,
        };
      }),
      reviewTableSettings: reviewTableSettings.map((setting) => ({
        id: setting.id,
        name: setting.value,
        created_at: setting.createdAt,
      })),
    };
  }

  return {
    logo: undefined,
    extraFields: undefined,
    logos: undefined,
    colors: undefined,
    reviewTableSettings: undefined,
  };
}

export function mapFromFileToFormData(file: File, width?: number, height?: number): FormData {
  const formData = new FormData();
  formData.append('file', file);

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}

export function mapFromApiMetadataToSocialNetwork(data: ApiMetadata[], socialNetwork: string): ISocialNetwork {
  const key = data.find((item) => item.key.startsWith(`${socialNetwork}_key`));
  const active = data.find((item) => item.key.startsWith(`${socialNetwork}_active`));
  const alphaName = data.find((item) => item.key.startsWith(`${socialNetwork}_alphaName`));

  return {
    key: key?.value || '',
    keyId: key?.id,
    active: active?.value === 'true',
    activeId: active?.id,
    alphaName: alphaName?.value || '',
    alphaNameId: alphaName?.id,
  };
}

export function mapFromApiDocumentToIDocument(data: ApiDocument): IDocument {
  return {
    name: data.description,
    id: data.file_id,
    url: data.url,
    fileName: data.name,
  };
}

export function mapFromApiDocumentsToIDocuments(data: ApiDocument[]): IDocument[] {
  return data?.map(mapFromApiDocumentToIDocument);
}

export function mapFromApiNodeToINode(data: ApiNode): INode {
  const address = data.metadata?.find((item) => item.key.startsWith('address'))!;
  const googlePlaceId = data.metadata?.find((item) => item.key.startsWith('googlePlaceId'))!;
  const accountable = data.metadata?.find((item) => item.key.startsWith('accountable'))!;
  const averageGoogleScoring = data.metadata?.find((item) => item.key.startsWith('average_rating'))!;
  const extraFields = data.metadata?.filter((item) => item.key.startsWith('extraField')) || [];
  const subNodes = data.children?.map((node) => mapFromApiNodeToINode(node));

  return {
    id: data.id,
    companyId: data.companyID!,
    name: data.name,
    useDefaultForm: data.useDetectLanguage || false,
    address: address?.id ? { id: address.id, value: address.value } : { value: '' },
    subNodes: subNodes || [],
    tiedNode: data.parentID || undefined,
    secondTiedNode: data.referencedID || undefined,
    googlePlaceID: googlePlaceId?.id ? { id: googlePlaceId.id, value: googlePlaceId.value } : { value: '' },
    averageGoogleScoring: averageGoogleScoring ? averageGoogleScoring.value : undefined,
    email: data.metadata ? mapFromApiMetadataToSocialNetwork(data.metadata, 'email') : undefined,
    sms: data.metadata ? mapFromApiMetadataToSocialNetwork(data.metadata, 'sms') : undefined,
    viber: data.metadata ? mapFromApiMetadataToSocialNetwork(data.metadata, 'viber') : undefined,
    telegram: data.metadata ? mapFromApiMetadataToSocialNetwork(data.metadata, 'telegram') : undefined,
    accountable: accountable ? { id: accountable.id, value: accountable.value.split('|||') } : { value: [] },
    slug: data.slug,
    code: data.code,
    extraFields: mapFromApiMetadataToICompanyNodeExtraField(extraFields),
    sourceId: data.sourceID,
    responsibleId: data.responsibleID,
    enableOverdue: data.enableOverdue,
  };
}

export function mapFromApiNodesToINodes(data: ApiNode[]): INode[] {
  return data.map((node) => mapFromApiNodeToINode(node));
}

export function mapFromApiReviewFormToIReviewForm(data: ApiReviewForm): IReviewForm {
  return {
    createAt: format(new Date(data.created_at), 'dd.MM.yyyy H:mm:ss'),
    id: data.id,
    formId: data.formID,
    contact: data.contact,
    formFields: mapApiAllTypeFieldsToIAllTypeFields(data.formFields),
    source: data.source,
    form: data.form,
    answers: data.answers || [],
    status: data.status,
    comment: data.comment,
    updatedAt: format(new Date(data.updated_at), 'dd.MM.yyyy H:mm:ss'),
    node: data.node,
  };
}

export function mapFromApiReviewFormToIOneReviewForm(data: ApiReviewFormOne): IOneReviewForm {
  return {
    createAt: format(new Date(data.created_at), 'dd.MM.yyyy H:mm:ss'),
    id: data.id,
    formId: data.formID,
    answers: data.answers || [],
    status: data.status,
    comment: data.comment,
    updatedAt: format(new Date(data.updated_at), 'dd.MM.yyyy H:mm:ss'),
    parentNode: data.node,
    wasOverdue: data.wasOverdue,
  };
}

export function mapFromApiReviewFormsToIOneReviewForms(data: ApiReviewFormOne[]): IOneReviewForm[] {
  return data.map(mapFromApiReviewFormToIOneReviewForm);
}

export function mapFromApiReviewFormsToIReviewForms(data: ApiReviewForm[]): IReviewForm[] {
  return data.map(mapFromApiReviewFormToIReviewForm);
}

export function mapIIssueToApiIssue(data: IIssue): ApiIssue {
  return {
    id: data.id,
    code: data.code,
    name: data.name,
    status_id: data.statusId,
    company_id: data.companyId,
    node_id: data.nodeId,
    type_id: data.typeId,
    user_id: data.userId,
    priority: data.priority,
    responsible_id: data.responsible,
    comment: data.comment,
    review_id: data.reviewId,
    connected_problem_id: data.connectedIssueId,
  };
}

export function mapApiIssueToIIssue(data: ApiIssue): IIssue {
  return {
    id: data.id,
    code: data.code,
    name: data.name,
    statusId: data.status_id,
    companyId: data.company_id,
    nodeId: data.node_id,
    typeId: data.type_id,
    userId: data.user_id,
    priority: data.priority,
    responsible: data.responsible_id,
    comment: data.comment,
    reviewId: data.review_id,
    connectedIssueId: data.connected_problem_id,
    createdAt: data.created_at,
  };
}

export function mapApiIssuesToIIssues(data: ApiIssue[]): IIssue[] {
  return data.map(mapApiIssueToIIssue);
}

export function mapApiIssueTypeToIIssueType(data: ApiIssueType): IIssueType {
  return {
    id: data.id,
    name: data.name,
    companyId: data.companyID,
    createdAt: data.created_at,
  };
}

export function mapApiIssueTypesToIIssueTypes(data: ApiIssueType[]): IIssueType[] {
  return data.map(mapApiIssueTypeToIIssueType);
}

export function mapIIssueTypeToApiIssueType(data: IIssueType): ApiIssueType {
  return {
    name: data.name,
    companyID: data.companyId,
  };
}

export function mapIIssueTypesToApiIssueTypes(data: IIssueType[]): ApiIssueType[] {
  return data.map(mapIIssueTypeToApiIssueType);
}

export function mapApiIssueStatusToIIssueStatus(data: ApiIssueStatus): IIssueStatus {
  return {
    id: data.id,
    name: data.name,
    companyId: data.companyID,
    createdAt: data.created_at,
  };
}

export function mapApiIssueStatusesToIIssueStatuses(data: ApiIssueStatus[]): IIssueStatus[] {
  return data.map(mapApiIssueStatusToIIssueStatus);
}

export function mapIIssueStatusToApiIssueStatus(data: IIssueStatus): ApiIssueStatus {
  return {
    name: data.name,
    companyID: data.companyId,
  };
}

export function mapIIssueStatusesToApiIssueStatuses(data: IIssueStatus[]): ApiIssueStatus[] {
  return data.map(mapIIssueStatusToApiIssueStatus);
}

export function mapApiPermissionToIPermission(apiPermission: ApiPermission): IUserPermission {
  return {
    id: apiPermission.id,
    name: apiPermission.name,
    label: apiPermission.label,
    resource: apiPermission.resource,
    permissions: apiPermission.permissions,
    createdAt: apiPermission.createdAt,
  };
}

export function mapIPermissionToApiPermission(iPermission: IUserPermission): ApiPermission {
  return {
    name: iPermission.name,
    label: iPermission.label || '',
    resource: iPermission.resource,
    permissions: iPermission.permissions,
  };
}

export function mapApiPermissionsToIPermissions(apiPermissions: ApiPermission[]): IPermission[] {
  return apiPermissions ? apiPermissions.map((permission) => mapApiPermissionToIPermission(permission)) : [];
}

export function mapIPermissionsToApiPermissions(iPermissions: IPermission[]): ApiPermission[] {
  return iPermissions.map((permission) => mapIPermissionToApiPermission(permission));
}

export function mapApiUserRoleToIUserRole(apiRole: ApiUserRole): IUserRole {
  return {
    id: apiRole.id,
    name: apiRole.name,
    permissions: mapApiPermissionsToIPermissions(apiRole.permissions),
  };
}

export function mapIUserRoleToApiUserRole(iRole: IUserRole): ApiUserRole {
  return {
    id: iRole.id,
    name: iRole.name,
    permissions: mapIPermissionsToApiPermissions(iRole.permissions),
  };
}

export function mapApiUserRolesToIUserRoles(apiRoles: ApiUserRole[]): IUserRole[] {
  return apiRoles.map((role) => mapApiUserRoleToIUserRole(role));
}

export function mapIUserRolesToApiUserRoles(iRoles: IUserRole[]): ApiUserRole[] {
  return iRoles.map((role) => mapIUserRoleToApiUserRole(role));
}

export function mapApiUserToIUser(apiUser: ApiUser): IUser {
  return {
    id: apiUser.id,
    email: apiUser.email,
    connectTelegramBot: apiUser.connect_telegram_bot,
    name: apiUser.name,
    company: apiUser.company,
    notificationsEnabled: apiUser.notificationsEnabled,
    roles: apiUser.roles ? mapApiUserRolesToIUserRoles(apiUser.roles) : [],
    createAt: format(new Date(apiUser.createdAt!), 'dd.MM.yyyy H:mm:ss'),
  };
}

export function mapApiUsersToIUsers(data: ApiUser[]): IUser[] {
  return data.map(mapApiUserToIUser);
}

export function mapApiReviewDiscussionMessageToIReviewDiscussionMessage(apiMessage: ApiReviewDiscussionMessage): IReviewDiscussionMessage {
  const author = apiMessage.external ? 'admin' : 'user';
  return {
    id: apiMessage.id,
    date: apiMessage.createdAt,
    text: apiMessage.text,
    author,
    attachmentUrl: apiMessage.attachmentUrl,
    attachmentType: apiMessage.attachmentType,
  };
}

export function mapApiReviewDiscussionMessagesToIReviewDiscussionMessages(apiMessages: ApiReviewDiscussionMessage[]): IReviewDiscussionMessage[] {
  return apiMessages.map(mapApiReviewDiscussionMessageToIReviewDiscussionMessage);
}

export function mapApiReviewTypeToIReviewType(apiReviewType: ApiReviewType): IReviewType {
  return {
    id: apiReviewType.id,
    name: apiReviewType.name,
    color: apiReviewType.color,
  };
}

export function mapApiReviewTypesToIReviewTypes(apiReviewTypes: ApiReviewType[]): IReviewType[] {
  return apiReviewTypes.map(mapApiReviewTypeToIReviewType);
}

export function mapImportDataToFormData(file: File, sheet: string, companyID: string | undefined, width?: number, height?: number): FormData {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('sheet', sheet);

  if (companyID) {
    formData.append('companyID', companyID);
  }

  if (width) {
    formData.append('width', width.toString());
  }

  if (height) {
    formData.append('height', height.toString());
  }

  return formData;
}

export function mapChatImg(file: File): FormData {
  const formData = new FormData();
  formData.append('attachment', file);
  return formData;
}
