import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import React, { FC, useState } from 'react';
import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import { getErrorMessage, handleKeyUp } from '../../../../utils';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import TextArea from '../../../form/textArea/TextArea';
import { FeedbackModalStyles } from './FeedbackModalStyles';

async function sendFeedback(lang: string, body: string, email: string, uID: number, companyID: string | null = null) {
  // eslint-disable-next-line no-undef
  const requestOptions: RequestInit = {
    method: 'GET',
    redirect: 'follow',
    cache: 'no-cache',
    mode: 'no-cors',
  };
  try {
    const response = await fetch(`https://docs.google.com/forms/d/e/1FAIpQLScz7_VKJizKM2XWHcxDjGho5We12DyqZnB6fnMI8-E6CQ1cKw/formResponse?usp=pp_url&entry.1894728564=${lang}&entry.1141386589=${body}&entry.1596560537=${email}&entry.474830291=${uID}&entry.2087620900=${companyID}&entry.1603678109=${window.location.href}`, requestOptions)
    const data = await response.json();
    return { data }
  } catch (e) {
    console.log(e)
  }
}

type Props = {
  onClose: () => void;
  active: boolean
};

interface FormValues {
  body: string;
}

const initialValues: FormValues = {
  body: '',
};

export const FeedbackModal: FC<Props> = React.memo(({ onClose, active }) => {
  const { data, companies } = useAppSelector((state) => state.user);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [complete, setComplete] = useState<boolean>(false);

  const validationSchema = Yup.object({
    body: Yup.string()
      .max(255, getTranslationByLangOrEng(interfaceLanguage, 'validation_required'))
      .required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
  });

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    const companyID = companies.join('-');
    setIsLoading(true)
    sendFeedback(interfaceLanguage, values.body, data?.email!, data?.id!, companyID)
    setComplete(true)
    setIsLoading(false)
    setSubmitting(false)

    setTimeout(() => {
      onClose()
      setComplete(false)
    }, 3000)
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>

      <TextArea
        name="body"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('body', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'send_feedback_label')}
        value={values.body}
        error={typeof errorMessage === 'object' ? getErrorMessage('body', errorMessage) : undefined}
        rows={7}
      />

      <SubmitButton
        isLoading={isLoading}
        isError={touched && Object.keys(errors).length > 0}
      >
        {getTranslationByLangOrEng(interfaceLanguage, 'send_feedback')}
      </SubmitButton>

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  return (
    <FeedbackModalStyles active={active}>
      <div className="feedback">
        <div className="feedback__title">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'send_feedback_title')}</h3>
        </div>
        <div className="feedback__form">
          {complete ? (
            <h1 className="completeMessage">{getTranslationByLangOrEng(interfaceLanguage, 'send_feedback_complete')}</h1>
          ) : (
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            >
              {renderForm}
            </Formik>
          )}
        </div>
      </div>
    </FeedbackModalStyles>
  )
});
