import React, { useEffect, useState } from 'react';
import { Range } from 'react-range';
import { ColorScaleStyles } from './ColorScaleStyles';
import { isSafari } from 'react-device-detect';

interface ColorScaleProps {
  colors: string[],
  pointerColor: string,
  maxValue: number,
  defaultValue: number,
  signatures: [string | undefined, string | undefined],
  value?: number[],
  handleChange?: (value: number[]) => void,
  signatureColor?: string,
}

export default function ColorScale({
  colors,
  pointerColor,
  maxValue,
  defaultValue,
  signatures,
  value,
  handleChange,
  signatureColor,
}: ColorScaleProps) {
  const [assessment, setAssessment] = useState<number[]>([1]);

  const numbers = new Array(maxValue + 1).fill(0);

  useEffect(() => {
    if (handleChange) {
      handleChange([defaultValue > maxValue ? maxValue : defaultValue]);
    } else {
      setAssessment([defaultValue > maxValue ? maxValue : defaultValue]);
    }
  }, [defaultValue]);

  return (
    <ColorScaleStyles signatureColor={signatureColor}>
      <div
        className="numbers"
        style={!isSafari ? {
          backgroundImage: `linear-gradient(90deg, ${colors.join(', ')})`,
          backgroundClip: 'text',
          backgroundColor: 'transparent',
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent',
        } : { color: '#000' }}
      >
        {numbers.map((number, index) => <span key={`item:${number + index}`}>{number + index}</span>)}
      </div>

      <Range
        step={1}
        min={0}
        max={maxValue}
        values={value || assessment}
        onChange={handleChange ? (values) => handleChange(values) : (values) => setAssessment(values)}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '14px',
              width: 'calc(100% - 20px)',
              borderRadius: 7,
              background: `linear-gradient(90deg, ${colors.join(', ')})`,
              boxShadow: 'inset 0px 0px 10px rgba(0, 0, 0, 0.25)',
            }}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: 35,
              width: 35,
              boxSizing: 'border-box',
              border: '5px solid #000',
              borderRadius: 18,
              backgroundColor: pointerColor,
              boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.15)',
            }}
          />
        )}
      />

      {signatures[0] && signatures[1] && (
        <div className="signatures">
          <span>{signatures[0]}</span>
          <span>{signatures[1]}</span>
        </div>
      )}
    </ColorScaleStyles>
  );
}
