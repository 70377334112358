import styled from 'styled-components';

export const NodeFilterStyles = styled.div`
  font-weight: 400;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #FFF;
  position: absolute;
  top: 90px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 200;
  width: 270px;
  padding: 16px;
  border-radius: 4px;
  height: 453px;

  .selectContainer {
    width: 100%;
    height: 100%;
    max-height: calc(100% - 102px);
    overflow-y: scroll;
    border-radius: 4px;
    border: 1px solid #E5E5E5;
  }
`;
