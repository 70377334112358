import { EditStyles } from './EditStyles';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CompanyInfoForm from '../../../components/companyInfoForm/CompanyInfoForm';
import CompanyDesignForm from '../../../components/companyDesignForm/CompanyDesignForm';
import Documents from '../documents/Documents';
import { Api } from '../../../api';
import { useAppDispatch, useAppSelector, setLocation } from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import CompanyIssueStatusesAndTypes from '../../../components/companyIssueStatusesAndTypes/CompanyIssueStatusesAndTypes';
import CompanyTemplates from '../../../components/companyTamplates/CompanyTemplates';
import CompanySources from '../../../components/companySources/CompanySources';
import CompanyReviewTableColumnsSettings from '../../../components/companyReviewTableColumnsSettings/CompanyReviewTableColumnsSettings';
import { tabsEdit } from './additionalSettings';
import { Helmet } from 'react-helmet-async';
import { CompanyIntegrations, SubHeader, TransparentButton } from '../../../components';

export default function EditCompany() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { selectedCompany, location } = useAppSelector((state) => state.company);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [companyName, setCompanyName] = useState('');
  const [selectedTab, setSelectedTab] = useState(tabsEdit(interfaceLanguage)[0].key);

  function handleCancel() {
    navigate('/companies');
  }

  useEffect(() => {
    if (location) {
      setSelectedTab(location);
      dispatch(setLocation(undefined));
    }
    if (selectedCompany) {
      setCompanyName(selectedCompany.name!);
    } else if (id) {
      Api.getCompanyById(+id)
        .then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setCompanyName(res.data.name!);
          }
        });
    }
  }, []);

  return (
    <EditStyles>
      <Helmet>
        <title>{`Editing settings ${companyName} Voicer`}</title>
      </Helmet>

      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_title_edit')}
        pathArray={[
          { name: companyName, route: `/companies/${id}/nodes` },
        ]}
        hideBorderBottom
      >
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
      </SubHeader>

      <div className="tabsContainer">
        {tabsEdit(interfaceLanguage).map((tab) => (
          <button
            className={selectedTab === tab.key ? 'active' : ''}
            key={tab.key}
            type="button"
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.text}
          </button>
        ))}
      </div>

      {selectedTab === 'info' && <CompanyInfoForm />}
      {selectedTab === 'design' && <CompanyDesignForm />}
      {selectedTab === 'documents' && <Documents />}
      {selectedTab === 'issuesStatusesAndTypes' && <CompanyIssueStatusesAndTypes />}
      {selectedTab === 'templates' && <CompanyTemplates />}
      {selectedTab === 'sources' && <CompanySources />}
      {selectedTab === 'reviewTableColumnSettings' && <CompanyReviewTableColumnsSettings />}
      {selectedTab === 'integrations' && <CompanyIntegrations />}

    </EditStyles>
  );
}
