import styled from 'styled-components';

export const UserProfileFormStyles = styled.div<{tabletVersion: boolean}>`
  .formContainer {
    box-sizing: border-box;
    width: 100%;
    padding: 24px 17px;
    max-width: 700px;

    form {
      .inputs {
        display: grid;
        grid-template-columns: ${({ tabletVersion }) => (tabletVersion ? '1fr' : '1fr 1fr')};
        grid-gap: ${({ tabletVersion }) => (tabletVersion ? '0' : '0 24px')};
        max-width: ${({ tabletVersion }) => (tabletVersion ? '240px' : '608px')};
      }

      .contacts-wrapper {
        margin 15px 0;
        .contacts {
          margin-top: 15px;
          display: flex;
          flex-direction: column;
          gap: 5px;

          div {
            display: flex;
            align-items: center;
            gap: 15px;
          }
        }
      }
    }
  }

  .channelsWrap {
    display: flex;
    flex-direction: column;
    max-width: 750px;
    width: 100%;

    .channelsManagementWrap {
      display: grid;
      grid-template-columns: auto 1fr;
      flex-grow: 1;
    }

    .channelsTitle {
      margin-bottom: 16px;
    }

    .channelsButtonsWrap {
      margin-bottom: 67px;
      flex-grow: 1;
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 20px;
      row-gap: 6px;
      max-width: 420px;
      width: 100%;

      span {
        font-size: 14px;
        color: #000000;
      }

      button {
        margin-right: 10px;
        width: 130px;
        height: 32px;
        border: 1px solid #0E9285;
        background: #0E9285;
        border-radius: 4px;
        color: #FFFFFF;
        font-size: 14px;
      }

      .disabled {
        background: #fafafa;
        color: #E5E5E5;
        border: 1px solid #E5E5E5;
      }
    }
  }

  .modalBody {
    background: #FFFFFF;
  }
`;
