import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { CompaniesStyles } from './CompaniesStyles';
import { Link, useNavigate } from 'react-router-dom';
import {
  DeleteIcon, FeedbacksListIcon,
  PencilIcon, SearchIcon,
} from '../../assets';
import { Api, ApiCompany } from '../../api';
import { useDispatch } from 'react-redux';
import { setSelectedCompany, useAppDispatch, useAppSelector } from '../../state';
import { CompaniesTableCompany, ICompany } from '../../entities';
import { getTranslationByLangOrEng } from '../../i18n';
import { Helmet } from 'react-helmet-async';
import {
  Loader, Modal, SubHeader, Table, TransparentButton,
} from '../../components';

function NameCustomField({ logo, name }: {logo: string, name: string}) {
  return (
    <div className="customNameWrapper">
      <div className={logo ? 'imageContainer' : 'imageContainer default'}>
        <div className="image">
          {logo && <img src={logo} alt="logo" />}
        </div>
      </div>
      <span>{name}</span>
    </div>
  );
}

function CustomField({ id, company }: {id: string, company: ICompany}) {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <div className="customButtonsWrapper">
      <Link
        className="customField"
        onClick={() => dispatch(setSelectedCompany(company))}
        to={`/companies/${id}/nodes`}
      >
        <div>
          {getTranslationByLangOrEng(interfaceLanguage, 'companies_main_structure_button')}
        </div>
      </Link>
    </div>
  );
}

function EditButtons({ id, company, filterCompaniesAfterDelete }: {id: string, company: ICompany, filterCompaniesAfterDelete: (id: number) => void}) {
  const dispatch = useDispatch();

  const [modal, setModal] = useState<boolean>(false);
  const [deleteError, setDeleteError] = useState<boolean>(false);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <>
      {modal && (
        <Modal onClose={() => setModal(false)} extraStyles={{ width: '544px' }}>
          <>
            <div className="modalTitle"><p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_main_delete_title')}</p></div>
            <div className="modalButtonsWrap">
              <p className="modalButtonsCaption">{getTranslationByLangOrEng(interfaceLanguage, 'companies_main_delete_description')}</p>
              <div>
                <button type="button" onClick={() => setModal(false)}>
                  {getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
                </button>
                <button
                  type="button"
                  onClick={
                    () => {
                      Api.deleteCompany(+id)
                        .then((res) => {
                          if (res.statusCode === 200) {
                            filterCompaniesAfterDelete(+id);
                          } else {
                            setDeleteError(true);
                          }
                        });
                    }
                  }
                >
                  {getTranslationByLangOrEng(interfaceLanguage, 'delete_button')}
                </button>
              </div>
              {deleteError && <p className="modalError">{getTranslationByLangOrEng(interfaceLanguage, 'delete_error')}</p>}
            </div>
          </>
        </Modal>
      )}
      <div className="editButtonsContainer">
        <div className="editButtons">
          <Link
            onClick={() => dispatch(setSelectedCompany(company))}
            to={`/companies/${id}/edit`}
          >
            <PencilIcon />
          </Link>
          <button
            type="button"
            onClick={() => {
              setModal(true);
            }}
          >
            <DeleteIcon />
          </button>
          <div className="splitter">
            <div />
          </div>

          <Link
            className="openButton"
            title={getTranslationByLangOrEng(interfaceLanguage, 'go_to_reviews_page_button_title')}
            to={`/reviews/${id}`}
          >
            <FeedbacksListIcon />
          </Link>
        </div>
      </div>
    </>
  );
}

async function addLogoToCompany(data: Partial<ApiCompany>[]) {
  const results: Partial<CompaniesTableCompany>[] = await Promise.all(data.map(async (item) => {
    const metaData = await Api.getCompanyMetadataByCompanyId(+item!.id!);
    const logo = metaData.data.logo;
    return {
      ...item,
      logo: !(logo?.value instanceof File) ? logo?.value : undefined,
    };
  }));

  return results;
}

export const Companies = () => {
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useState<string>('');
  const [companies, setCompanies] = useState<Partial<CompaniesTableCompany>[]>([]);
  const [uploading, setUploading] = useState<boolean>(false);

  const { data: userdata } = useAppSelector((state) => state.user);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    setUploading(true);
    Api.getCompanies()
      .then(async (res) => {
        if (res.statusCode >= 200 && res.statusCode <= 200) {
          const data = addLogoToCompany(userdata?.id === 44 ? res.data.filter((el) => el.id === 109) : res.data);
          setCompanies(await data);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setUploading(false);
      });
  }, []);

  const filterCompaniesAfterDelete = useCallback((id: number) => {
    setCompanies(companies.filter((company) => company.id !== id));
  }, [companies]);

  const filteredCompanies = useMemo(() => companies.filter(
    (company) => company.name!.toLowerCase().includes(searchParams.toLowerCase()),
  ), [searchParams, companies, uploading]);

  const companiesTableHeaders = React.useMemo(() => [
    {
      Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_main_table_company_name_header'),
      accessor: 'name',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <NameCustomField name={data.row.original.name} logo={data.row.original.logo} />
      ),

    },
    {
      Header: 'Custom field',
      accessor: 'customField',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <CustomField id={data.row.original.id} company={data.row.original} />
      ),
    },
    {
      Header: 'Edit buttons',
      accessor: 'editButtons',
      // eslint-disable-next-line react/no-unstable-nested-components
      Cell: (data: any) => (
        <EditButtons id={data.row.original.id} company={data.row.original} filterCompaniesAfterDelete={filterCompaniesAfterDelete} />
      ),
    },
  ], [companies, interfaceLanguage]);

  const headerColumnCounts = { company: filteredCompanies.length };

  const extraFieldsSettings = {
    customField: {
      alignItems: 'center',
    },
  };

  return (
    <CompaniesStyles>
      <Helmet>
        <title>Companies Voicer</title>
      </Helmet>

      <div>
        <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'companies_main_title')}>
          <TransparentButton handleClick={() => navigate('/companies/create')} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_main_add_company_button')} filled />
        </SubHeader>
        <div className="searchWrapper">
          <SearchIcon />
          <input
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')}
            type="text"
            value={searchParams}
            onChange={(e) => setSearchParams(e.target.value)}
          />
        </div>
        {uploading ? <Loader /> : (
          <Table
            pagination
            columns={companiesTableHeaders}
            data={filteredCompanies}
            hideFieldsSplitters
            extraFieldsSettings={extraFieldsSettings}
            headerColumnCounts={headerColumnCounts}
            pageSize={filteredCompanies.length}
            sortableColumns={['name']}
            hiddenHeaders={['customField', 'editButtons', 'customFormsField']}
            fullWidthColumns={['editButtons']}
          />
        )}
      </div>
    </CompaniesStyles>
  );
}
