import styled from 'styled-components';

export const CompanyStructureStyles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border: none;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }

    .buttonsContainer {
      display: flex;
      
      & > div {
        margin-left: 16px;
        
        &:first-child {
          margin-left: 0;
        }
      }
    }
  }

  .tabsContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    flex-wrap: wrap;
    display: flex;
    box-sizing: border-box;
    border-bottom: 1px solid #F0F0F0;

    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #FFF;
      margin-right: 32px;

      font-size: 15px;
      line-height: 16px;
      color: #7C7878;
      transition: all 0.3s;
      
      &:last-child {
        margin-right: 0;
      }
      
      &.active {
        border-bottom: 3px solid #0E9285;
        color: #0E9285;
        transition: all 0.3s;
      }
    }
  }
  
  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #F0F0F0;

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    button {
      min-width: 130px;
    }

    input::placeholder {
      font-size: 15px;
      color: #979797;
    }
  }
  
  .nodeName {
    position: relative;
    display: flex;
    align-items: center;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    
    &.notFirstNode {
      .arrowCircleContainer {
        transform: rotateZ(-90deg);
      }
    }
    
    .arrowCircleContainer {
      position: relative;
      z-index: 10;
      width: 18px;
      height: 18px;
      margin-right: 12px;
      display: grid;
      place-items: center;
      
      .arrowBG {
        width: 16px;
        height: 16px;
        background-color: #e2e2e2;
        border-radius: 15px;
        display: grid;
        place-items: center;
        box-sizing: border-box;
        padding-top: 2px;
      }
    }
    
    .horizontalLineContainer {
      position: absolute;
      bottom: 6px;
      left: -25px;
    }
    
    .verticalLineContainer {
      position: absolute;
      bottom: 6px;
      left: -25px;
    }
  }

  tr {  
    
    &.emptyRow {
      display: table-row;
      grid-template-columns: none;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      
      p {
        width: 100%;
      }
    }
  }
  
  .accountable {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    
    div {
      margin-bottom: 9px;
      
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .editButtonsContainer {
    height: 100%;
    display: flex;
    justify-content: center;

    a {
      display: flex;
      align-items: center;
    }

    button {
      display: flex;
      align-items: center;
    }
  }
  
  .openButton {
    position: relative;

    svg {
      height: 20px;
    }
  }

  .editButtons {
    width: 180px;
    display: grid;
    grid-template-columns: 1fr 1fr 20px 1fr 1fr;
    align-items: center;
    
    .buttonContainer {
      display: flex;
      justify-content: center;
    }
    
    button {
      display: flex;
      justify-content: center;
    }
    
    .splitter {
      height: 24px;
      display: flex;
      justify-content: center;
      
      > div {
        width: 1px;
        height: 100%;
        background-color: #eee;
      }
    }
  }

  .modalBody {
    .modalTitle {
      padding: 10px 16px;
      background: #F0F5F5;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      border: 1px solid #F0F5F5;
      border-radius: 4px 4px 0px 0px;
    }

    .modalButtonsWrap {
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 0px 0px 4px 4px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: 14px;
        color: #000000;
      }

      div {
        display: flex;
        justify-content: space-between;
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;

        :nth-child(1) {
          background: #FFFFFF;
          border: 1px solid #C4C4C4;
          color: #000000;

          :hover {
            color: #FFF;
            background: #C4C4C4;
          }
        }

        :nth-child(2) {
          border: 1px solid #FF0101;
          background: #FF0101;
          color: #FFFFFF;

          :hover {
            background: #FFFFFF;
            color: #FF0101;
          }
        }
      }
    }

    .modalError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
