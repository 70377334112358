import styled from 'styled-components';

export const MassMailingsSmsStyles = styled.div`
  form {
    padding: 16px 32px 32px 16px;
    .formFieldsContainer {
      margin-bottom: 75px;
      .senderAccountInputContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: 600px;
        
        span {
          height: 40px;
        }
      }
      .textContainer {
        display: flex;
        justify-content: space-between;
        max-width: 682px;
        white-space: pre-line;
      }
    }

    .submitButtonContainer {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      max-width: 640px;
    }
  }
`;
