import styled from 'styled-components';

export const CompanyUsersStyles = styled.div`
.searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #F0F0F0;

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    button {
      min-width: 130px;
    }

    input::placeholder {
      font-size: 15px;
      color: #979797;
    }
  }

  .tableUsers {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: space-around;
    align-items: flex-start;
  }
`;
