import React, { useEffect, useRef, useState } from 'react';
import {
  ErrorMessage,
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { getTranslationByLangOrEng } from '../../../../../i18n';
import { TransparentButton } from '../../../../../components/atoms/transparentButton/TransparentButton';
import SubmitButton from '../../../../../components/form/submitButton/SubmitButton';
import {
  useAppDispatch, useAppSelector, MassMailingsViberRedux, setMassMailingsViber,
} from '../../../../../state';
import { MassMailingsViberStyles } from './MassMailingsViberStyles';
import InputField from '../../../../../components/form/inputField/InputField';
import { UploadFileButton } from '../../../../../components/form/uploadFileButton/UploadFileButton';
import { Api } from '../../../../../api';
import DefaultCompanyImage from '../../../../../assets/images/defaultCompanyImage.png';
import { JustPlusIcon, ThreeDots } from '../../../../../assets';
import Union from './images/Union.svg';
import ViberImage from './images/viber_image.png';
import ViberPhoto from './images/viber_photo.png';
import ViberSend from './images/viber_send.png';
import { format } from 'date-fns';
import { FormValues, validationSchema } from './formValuesAndValidation';
import { Helmet } from 'react-helmet-async';
import { Loader, Modal, TextEditor } from '../../../../../components';
import { getErrorMessage, handleKeyUp } from '../../../../../utils';

const defaultInitialValues: FormValues = {
  senderAccount: 'Voicer',
  text: '',
  image: undefined,
  messageType: 'text',
  actionButtonActive: false,
  buttonText: '',
  buttonUrl: '',
};

const maxSize = 512000;

export default function MassMailingsViber() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [fileErrorMessage, setFileErrorMessage] = useState<string>('');
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FormValues>();

  const formDataRef = useRef<FormValues>(defaultInitialValues);
  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  const { massMailingsViber } = useAppSelector((state) => state.massMailing);

  function getInitialData() {
    if (massMailingsViber) {
      const initialValuesFromRedux: FormValues = {
        senderAccount: massMailingsViber.senderAccount,
        text: massMailingsViber.text || '',
        image: massMailingsViber.image,
        messageType: 'text',
        actionButtonActive: massMailingsViber.actionButtonActive,
        buttonText: massMailingsViber.buttonText,
        buttonUrl: massMailingsViber.buttonUrl,
      };
      setInitialValues(initialValuesFromRedux);
    } else {
      setInitialValues(defaultInitialValues);
    }
  }

  useEffect(() => {
    // getDataFromServer();
    getInitialData();
  }, []);

  const logoExamination = (file: File | undefined) => {
    if (file!.size > maxSize) {
      setFileErrorMessage(`${getTranslationByLangOrEng(interfaceLanguage, 'max_size_error')} (KB)`);
      return;
    }

    let img: any;
    if (file) {
      img = new Image();
      const objectUrl = URL.createObjectURL(file);
      img.onload = function () {
        if (img.width > 1600 || img.height > 1600) {
          return setFileErrorMessage(`${getTranslationByLangOrEng(interfaceLanguage, 'max_size_error')} (px)`);
        }
        URL.revokeObjectURL(objectUrl);
      };
      img.src = objectUrl;
    }

    setFileErrorMessage('');
  };

  function saveViberDataToRedux() {
    if (formikRef.current && formikRef.current.values) {
      const data: MassMailingsViberRedux = {
        senderAccount: formikRef.current.values.senderAccount,
        actionButtonActive: formikRef.current.values.actionButtonActive,
        buttonText: formikRef.current.values.buttonText,
        buttonUrl: formikRef.current.values.buttonUrl,
        image: formikRef.current.values.image,
        text: formikRef.current.values.text,
        messageType: formikRef.current.values.messageType,
      };

      dispatch(setMassMailingsViber(data));
    }
  }

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);
    setIsLoading(true);
    saveViberDataToRedux();
    navigate('/massMailings/create/step-3');
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <div className="formFieldsContainer">
          <div className="senderAccountInputContainer">
            <span className="senderAccountInputTitle">{getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_sender_account')}</span>
            <InputField
              name="senderAccount"
              onChange={setFieldValue}
              onKeyUp={() => handleKeyUp('senderAccount', setErrorMessage, errorMessage)}
              value={values.senderAccount}
              error={typeof errorMessage === 'object' ? getErrorMessage('senderAccount', errorMessage) : undefined}
              extraBlockStyles={{ maxWidth: '428px', width: '100%' }}
              placeholder="Enter sender name"
              disabled
            />
          </div>

          <div className="messageContentContainer">
            <div className="typeContainer">
              <div className="labelContainer">
                <span>
                  {getTranslationByLangOrEng(interfaceLanguage, 'message')}
                </span>
              </div>
            </div>

            <TextEditor
              onChange={setFieldValue}
              name="text"
              value={values.text}
              onKeyUp={() => handleKeyUp('text', setErrorMessage, errorMessage)}
              error={typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined}
              extraBlockStyles={{ maxWidth: '512px', width: '100%' }}
              toolbarItems={['emoticons']}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'message_text_placeholder')}
              height={255}
              outputEditorFormat="text"
            />

            {values.messageType === 'image' && (
              <div className="imageContentWrapper">
                <div className={values.image?.value ? 'logoContainer' : 'logoContainer default'}>
                  {values.image?.value ? (
                    <img
                      className="selectedImage"
                      src={typeof values.image.value === 'string' ? values.image.value : URL.createObjectURL(values.image.value)}
                      alt=""
                    />
                  ) : (
                    <img
                      src={DefaultCompanyImage}
                      alt="logo"
                    />
                  )}
                </div>

                <div className="infoContainer">
                  <UploadFileButton
                    onChange={(file) => {
                      if (file) {
                        logoExamination(file);
                      }
                      setFieldValue('image.value', file);
                    }}
                    text="Choose an image"
                    formats="image/*"
                  />
                  <div className="descriptionsPart">
                    <span className="description">Maximum image size is 500 KB</span>
                    <span className="description">We recommend using a 200x200 px image</span>
                    <span className="description">Maximum image size 1600x1600 px</span>
                  </div>
                  <div className="formErrorContainer">
                    <p className="uploadError">{fileErrorMessage}</p>
                    {touched && errors && <ErrorMessage name="image" />}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className="finishButtonsContainer">
          <TransparentButton
            extraStyles={{ height: 40 }}
            handleClick={() => {
              formDataRef.current = values;
              setShowPreviewModal(true);
            }}
            text={getTranslationByLangOrEng(interfaceLanguage, 'preview_button')}
          />
          <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'next_button')}</SubmitButton>
        </div>

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <MassMailingsViberStyles>
      <Helmet>
        <title>{getTranslationByLangOrEng(interfaceLanguage, 'create_mass_mailings_page_title')}</title>
      </Helmet>
      {!initialValues ? <Loader /> : (
        <div>
          <div className="titleWrapper">
            <h1>{getTranslationByLangOrEng(interfaceLanguage, 'create_viber_mass_mailings_second_step_subheader_title')}</h1>
            <div className="buttonsContainer">
              <TransparentButton
                handleClick={() => {
                  saveViberDataToRedux();
                  navigate('/massMailings/create');
                }}
                text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')}
              />
            </div>
          </div>

          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema(interfaceLanguage)}
          >
            {renderForm}
          </Formik>

          {showPreviewModal && (
          <Modal onClose={() => setShowPreviewModal(false)}>
            <div className="previewContainer">
              <div className="header">
                <h3>Viber preview</h3>
                <button type="button" onClick={() => setShowPreviewModal(false)}>
                  <JustPlusIcon color="#999" width={20} height={20} />
                </button>
              </div>

              <div className="body">
                <div className="previewBodyContainer">
                  <div className="previewBody">
                    <div className="previewHeader">
                      <span>Voicer</span>
                      <ThreeDots width={3} height={12} color="#7C4C9D" />
                    </div>

                    <div className="previewChat">
                      <p className="date">{format(new Date(), 'MM/dd/yyyy')}</p>
                      <div className="message">
                        <div className="senderLogo">V</div>
                        <div className={`messageContainer${!(formDataRef.current.actionButtonActive
                          && formDataRef.current.buttonText
                          && formDataRef.current.buttonUrl)
                        && formDataRef.current.messageType === 'image'
                        && formDataRef.current.image?.value
                          ? ' image'
                          : ''}`}
                        >
                          {formDataRef.current.messageType === 'text' && formDataRef.current.text && <div className="messageText" dangerouslySetInnerHTML={{ __html: formDataRef.current.text }} />}
                          {formDataRef.current.messageType === 'image' && formDataRef.current.image?.value && (
                          <img
                            src={typeof formDataRef.current.image?.value === 'string'
                              ? formDataRef.current.image?.value
                              : URL.createObjectURL(formDataRef.current.image?.value)}
                            alt=""
                          />
                          )}
                          <div className={`buttonContainer${!(formDataRef.current.messageType === 'image'
                          && !(formDataRef.current.actionButtonActive
                          && formDataRef.current.buttonText
                          && formDataRef.current.buttonUrl)) ? ' withExtraMargin' : ''}`}
                          >
                            {formDataRef.current.actionButtonActive && formDataRef.current.buttonText && formDataRef.current.buttonUrl
                            && (
                              <button type="button" className="button" onClick={() => window.open(formDataRef.current.buttonUrl, '_blank')}>
                                {formDataRef.current.buttonText}
                              </button>
                            )}
                          </div>
                          <p className="time">{format(new Date(), 'hh:mm a')}</p>
                        </div>
                      </div>
                    </div>

                    <div className="previewFooter">
                      <p>Type a message...</p>
                      <div className="icons">
                        <img src={ViberImage} alt="" />
                        <img src={ViberPhoto} alt="" />
                      </div>

                      <img src={ViberSend} alt="" />
                    </div>
                  </div>
                </div>

                <img src={Union} alt="" />
              </div>

              <div className="footer">
                <TransparentButton handleClick={() => setShowPreviewModal(false)} text={getTranslationByLangOrEng(interfaceLanguage, 'close_button')} />
              </div>
            </div>
          </Modal>
          )}
        </div>
      )}
    </MassMailingsViberStyles>
  );
}
