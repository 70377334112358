import React, { useState, useRef } from 'react';
import { FilterSelectInputStyles } from './FilterSelectInputStyles';
import { translations } from '../../../../i18n';
import { ArrowDownIcon, TableFilterIcon } from '../../../../assets';
import { useAppSelector } from '../../../../state';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { IReviewStatus } from '../../../../entities/IReviewStatus';
import { CalendarFilter } from '../calendarFilter';
import { NodeFilter } from '../nodeFilter';
import { SourceFilter } from '../sourceFilter';
import { StatusFilter } from '../statusFilter';
import { NetworkFilter } from '../networkFilter';

interface IFilterSelectInput {
  columnName: string;
  title?: string,
  filterParams: {[key: string]: number | string | any },
  setReQuery: () => void,
  setApply: (params: {[key: string] : number | string }) => void,
  setFilterParams: (params: {[key: string] : number | string }) => void,
  status?: IReviewStatus[];
  containerExtraStyles?: string;
  extraFilterFormStyles?: string;
  optionsExtraStyles?: string;
}

export const FilterSelectInput = React.memo(({
  columnName, title, filterParams, setReQuery, setApply, setFilterParams, status, containerExtraStyles, extraFilterFormStyles, optionsExtraStyles,
}: IFilterSelectInput) => {
  const [isFilterOpened, setIsFilterOpened] = useState<boolean>(false);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const filtersRef = useRef<any>();

  useClickOutside(filtersRef, () => setIsFilterOpened(false));

  return (
    <FilterSelectInputStyles ref={filtersRef}>
      <div className="entityTitle">
        <span>{title}</span>
        {((Array.isArray(filterParams[columnName]) ? !!filterParams[columnName].length : !!filterParams[columnName]) || (columnName === 'createdAt' && filterParams.from && filterParams.to)) && <TableFilterIcon />}
      </div>
      <div className={isFilterOpened ? 'filtersOpener filtersOpener-active' : 'filtersOpener'} onClick={() => setIsFilterOpened(!isFilterOpened)}>
        <span className="filterTitle">{translations[interfaceLanguage].reviews_table_filter_title}</span>
        <ArrowDownIcon />
      </div>
      {isFilterOpened && (
        <div>
          {columnName === 'nodes' && (
            <NodeFilter
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              setReQuery={setReQuery}
              setApply={setApply}
              onClose={() => setIsFilterOpened(false)}
              resizable
              containerExtraStyles={containerExtraStyles}
              extraFilterFormStyles={extraFilterFormStyles}
              optionsExtraStyles={optionsExtraStyles}
            />
          )}
          {columnName === 'statuses' && (
            <StatusFilter
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              setReQuery={setReQuery}
              setApply={setApply}
              status={status}
              onClose={() => setIsFilterOpened(false)}
            />
          )}

          {columnName === 'sources' && (
            <SourceFilter
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              setReQuery={setReQuery}
              setApply={setApply}
              onClose={() => setIsFilterOpened(false)}
            />
          )}

          {columnName === 'contacts' && (
            <NetworkFilter
              filterParams={filterParams}
              setFilterParams={setFilterParams}
              setReQuery={setReQuery}
              setApply={setApply}
              onClose={() => setIsFilterOpened(false)}
            />
          )}

          {columnName === 'createdAt' && (
            <div style={{ width: '100%' }}>
              <CalendarFilter
                setFilterParams={setFilterParams}
                filterParams={filterParams}
                setReQuery={setReQuery}
                onClose={() => setIsFilterOpened(false)}
                extraBlockStyles={{ top: '100px', left: '0px' }}
                setApply={setApply}
              />
            </div>
          )}
        </div>
      )}
    </FilterSelectInputStyles>
  )
})
