import React from 'react';

export const ClosedLock = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17"
    height="20"
    fill="none"
    viewBox="0 0 17 20"
  >
    <path
      fill="#979797"
      d="M16.5 10c0-1.103-.897-2-2-2h-1V5c0-2.757-2.243-5-5-5s-5 2.243-5 5v3h-1c-1.103 0-2 .897-2 2v8c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-8zm-11-5c0-1.654 1.346-3 3-3s3 1.346 3 3v3h-6V5z"
    />
  </svg>
));
