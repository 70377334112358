import React, { useEffect } from 'react';
import { useAppSelector } from '../../../../state';
import MassMailingsSms from '../connectionChannels/sms/MassMailingsSms';
import MassMailingsViber from '../connectionChannels/viber/MassMailingsViber';
import MassMailingCreatingEmailStep2 from '../connectionChannels/email/step2/MassMailingCreatingEmailStep2';
import { useNavigate } from 'react-router-dom';

function MassMailingCreationStep2() {
  const navigate = useNavigate();

  const { massMailingMainInfo } = useAppSelector((state) => state.massMailing);
  const getMassMailingConnectionChannelForm = () => {
    if (massMailingMainInfo) {
      switch (massMailingMainInfo.connectionChannelKey) {
        case 1:
          return (
            <MassMailingCreatingEmailStep2 />
          );
        // case 2:
        //   return (
        //     <MassMailingsTelegram />
        //   );
        case 3:
          return (
            <MassMailingsViber />
          );
        case 4:
          return (
            <MassMailingsSms />
          );

        default:
          return 'Connection type not found';
      }
    }
  };

  useEffect(() => {
    if (!massMailingMainInfo) {
      navigate('/massMailings/create');
    }
  }, []);

  // TODO: change default

  return (
    <div>
      {getMassMailingConnectionChannelForm()}
    </div>
  );
}

export default MassMailingCreationStep2;
