import React, {
  useEffect, useState, useCallback,
} from 'react';
import { debounce } from 'lodash';
import { QuantityFieldStyles } from './QuantityFieldStyles';
import { QuantityMinusIcon, QuantityPlusIcon } from '../../../assets';

interface IQuantityFieldProps {
  index: number;
  name: string;
  value: string;
  question?: string;
  required: boolean;
  description?: string | undefined;
  minValue?: number;
  maxValue?: number;
  defaultValue?: number;
  buttonsBackgroundColor?: string;
  signsColor?: string;
  onChange: (key: string, value: string) => void;
  onKeyUp?: () => void;
  showDescription?: boolean;
  extraBlockStyles?: string;
  quantityCounterContainerExtraStyles?: string;
}

function QuantityField({
  index,
  name,
  value,
  question,
  required,
  description,
  minValue,
  maxValue,
  defaultValue,
  buttonsBackgroundColor,
  signsColor,
  onChange,
  onKeyUp,
  showDescription = false,
  extraBlockStyles,
  quantityCounterContainerExtraStyles,
}: IQuantityFieldProps) {
  const valueAsNumber = Number(value);
  const [quantity, setQuantity] = useState<number>(valueAsNumber);

  const increaseQuantity = () => {
    if (value === '') {
      onChange(name, `${minValue}`);
    }
    if (quantity < maxValue!) {
      setQuantity(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (value === '') {
      onChange(name, `${minValue}`);
    }
    if (quantity > minValue!) {
      setQuantity(quantity - 1);
    }
  };

  const onChangeHandler = (event: any) => {
    setQuantity(Number(event.target.value));
  };

  const debouncedChangeHandler = useCallback(
    debounce(onChangeHandler, 50),
    [],
  );

  useEffect(() => {
    if (onChange && defaultValue) {
      onChange(name, `${defaultValue}`);
      setQuantity(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (value !== '') {
      onChange(name, `${quantity}`);
    }
  }, [quantity]);

  return (
    <QuantityFieldStyles className={extraBlockStyles} buttonsBackgroundColor={buttonsBackgroundColor}>
      {question && (
        <div className="titleContainer">
          {required && <span>*</span>}
          <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question}`}</h3>
        </div>
      )}

      {showDescription && description && (
        <div className="titleContainer">
          <h3>{description}</h3>
        </div>
      )}
      <div className={`quantityCounterContainer${quantityCounterContainerExtraStyles ? ` ${quantityCounterContainerExtraStyles}` : ''}`}>
        <div className="quantityCounterWrapper">
          <button
            className="quantityCounterButton quantityMinusButton"
            type="button"
            onClick={decreaseQuantity}
          >
            <QuantityMinusIcon color={signsColor} />
          </button>
          <input
            className="quantityInput"
            name="quantityName"
            type="number"
            value={value}
            onChange={onChange ? (event) => {
              onChange(name, event.target.value);
              debouncedChangeHandler(event);
            } : () => null}
            onKeyUp={onKeyUp}
          />
          <button
            className="quantityCounterButton quantityPlusButton"
            type="button"
            onClick={increaseQuantity}
          >
            <QuantityPlusIcon color={signsColor} />
          </button>
        </div>
      </div>
    </QuantityFieldStyles>
  );
}

export default QuantityField;
