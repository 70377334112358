import React from 'react';

export const VerticalDashedLineIcon = React.memo(() => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1"
    height="81"
    fill="none"
    viewBox="0 0 1 81"
  >
    <path stroke="#000" strokeDasharray="2 2" d="M0.5 0L0.5 81" />
  </svg>
));
