import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const CrossedOutEye: FC<TIcon> = React.memo(({ width = 22, height = 20, color = '#979797' }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
    enableBackground="new 0 0 700 700"
  >
    <path
      fill="#949494"
      d="M307,333l-63,63c-14.2-21.1-22.5-46.6-22.5-74c0-27.9,8.6-53.7,23.2-75.1c-75,38.3-124.1,103.3-124.1,103.3 s33.6,44.4,87.6,81.5l-34.8,34.8c-71.9-51.3-113-116.3-113-116.3s112-177.2,290-177.2c35.5,0,68.3,7,98.2,18.3L333.2,306.7 c0.7-2.8,1.1-5.8,1.1-8.9c0-20-16.2-36.3-36.3-36.3c-20,0-36.2,16.2-36.2,36.3c0,20,16.2,36.2,36.2,36.2 C301.1,334.1,304.1,333.7,307,333L307,333z M152.7,526.4c-7,9.4-6.2,22.9,2.4,31.4l8.4,8.4c9.5,9.5,24.8,9.4,34.2,0l56.5-56.5 c29.3,10.8,61.5,17.6,96.2,17.6c178,0,290-177.2,290-177.2S599.9,286.1,529,235l50.8-50.8c9.4-9.4,9.4-24.8,0-34.2l-8.4-8.4 c-8.6-8.6-22-9.4-31.4-2.4L152.7,526.4z M494.2,269.8c53.1,36.8,86,80.4,86,80.4s-98.7,130.6-229.8,130.6c-20.4,0-40.1-3.2-58.6-8.5 L315,449c12.5,3.9,25.7,5.9,39.4,5.9c73.4,0,132.9-59.5,132.9-132.9c0-13.7-2.1-27-5.9-39.4L494.2,269.8z"
    />
  </svg>
))
