import { IContact } from '../entities';

export const getTypeOfContact = (contact: IContact): string => {
  if (contact.phone) {
    return 'call'
  }
  if (contact.telegramID) {
    return 'telegram'
  }
  if (contact.whatsappID) {
    return 'whatsapp'
  }
  if (contact.viberID) {
    return 'viber'
  }
  return ''
};
