export const extraFieldsSettings = {
  id: {
    width: '10%',
  },
  nodesCount: {
    width: '10%',
  },
  responsibleCount: {
    width: '10%',
  },
}
