import styled from 'styled-components';

export const AddIssueStyles = styled.div`
  .formSection {
    padding: 20px 32px 32px 16px;
    box-sizing: border-box;

    .labelContainer {
      display: flex;
      margin-bottom: 8px;

      .fakeLabel {
        font-weight: 500;
        font-size: 15px;
        line-height: 16px;
        color: #000000;
      }
    }

    h2 {
      margin-bottom: 17px;
    }
    
    .twoColumns {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 24px;
      width: 100%;
      max-width: 524px;
    }
  }

  .formSection {
    .issueSelectContainer {
      border-top: 1px dashed #e5e5e5;
      border-bottom: 1px dashed #e5e5e5;
      padding-top: 24px;
      margin-bottom: 24px;
    }
  }

  .modalContainer {
    background: #fff;
    color: #000000;
    border-radius: 4px;

    .modalHeader {
      width: 100%;
      height: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e5e5;
      padding-left: 16px;
      padding-right: 12px;
      box-sizing: border-box;

      h5 {
        font-size: 14px;
        color: #000;
      }

      button {
        width: 32px;
        height: 32px;
        display: grid;
        place-items: center;

        svg {
          transform: rotate(45deg);
        }
      }
    }

    .body {
      padding: 16px;
      border-bottom: 1px solid #e5e5e5;
      
      .modalTitleContainer {
        display: flex;
        align-items: center;
        margin-bottom: 16px;
        
        h6 {
          margin-bottom: 0;
        }
        
        span {
          margin-top: 2px;
          margin-left: 4px;
          font-size: 12px;
        }
      }

      h6 {
        font-size: 18px;
        margin-top: 0;
        margin-bottom: 16px;
        text-align: center;
      }

      p {
        font-size: 14px;
        margin: 0;
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      box-sizing: border-box;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      
      &.right {
        grid-template-columns: 1fr auto;
      }

      & > div, a {
        display: grid;
        grid-template-columns: 1fr;
        place-items: center;
      }

      & > div {
        width: 100%;
        margin: 0;

        a, button {
          width: 100%;
        }
      }
    }
  }

  .reviewFieldRow {
    display: grid;
    grid-template-columns: auto minmax(140px, 1fr);
    grid-gap: 24px;
    align-items: center;

    .openReviewContain {
      display: flex;
    }
  }
`;
