import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const CrossInCircle: FC<TIcon> = React.memo(({ color = '#FFFFFF' }) => (
  <svg
    width="17"
    height="16"
    fill="none"
    viewBox="0 0 17 16"
  >
    <path
      fill={color}
      d="M9.167 4.664H7.833v2.667H5.167v1.333h2.666v2.667h1.334V8.664h2.666V7.331H9.167V4.664z"
    />
    <path
      fill={color}
      d="M8.5 1.336a6.674 6.674 0 00-6.667 6.667A6.674 6.674 0 008.5 14.669a6.674 6.674 0 006.667-6.666A6.674 6.674 0 008.5 1.336zm0 12a5.34 5.34 0 01-5.333-5.333A5.34 5.34 0 018.5 2.669a5.34 5.34 0 015.333 5.334A5.34 5.34 0 018.5 13.336z"
    />
  </svg>
));
