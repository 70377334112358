export function validateInputNumber(str: string) {
  if (str === '') {
    return true;
  }

  if (!Number.isNaN(+str)) {
    const numbersReg = /^[0-9]+$/;
    return numbersReg.test(str);
  }

  return false;
}
