import { CompanyInfoFormStyles } from './CompanyInfoFormStyles';
import React, { useEffect, useRef, useState } from 'react';
import DefaultCompanyImage from '../../assets/images/defaultCompanyImage.png';
import { UploadFileButton } from '../form/uploadFileButton/UploadFileButton';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import * as Yup from 'yup';
import InputField from '../form/inputField/InputField';
import SubmitButton from '../form/submitButton/SubmitButton';
import { DeleteIcon } from '../../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { Api, mapFromApiCompanyToICompany } from '../../api';
import {
  useAppDispatch, useAppSelector, setCompanyMetadata, setSelectedCompany,
} from '../../state';
import { ICompanyExtraField, ILanguage } from '../../entities';
import { useClickOutside } from '../../hooks/useClickOutside';
import CustomSelectWithMultipleCheckboxes from '../form/customSelect/CustomSelectWithMultipleCheckboxes';
import { getLanguages, getErrorMessage, handleKeyUp } from '../../utils';
import { getTranslationByLangOrEng } from '../../i18n';
import { Loader, TransparentButton } from '../atoms';
import { CompaniesInfoModal, ModalConfirmDecline } from '../organisms';

interface FormValues {
  name: string,
  logo: {
    value: string | File,
    id?: number,
  },
  domain: string,
  extraFields: {id?: number, name: string, value: string, key?: string, position: string}[],
  languages: (ILanguage & {value: boolean})[],
}

export default function CompanyInfoForm() {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const company = useAppSelector((state) => state.company.selectedCompany);
  const companyMetadata = useAppSelector((state) => state.company.companyMetadata);
  const { languages: languagesState } = useAppSelector((state) => state.languages);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [maxSizeError, setMaxSizeError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [submitError, setSubmitError] = useState<string>('');
  const [afterSaveModal, setAfterSaveModal] = useState<number>(0);
  const [languageSearchParams, setLanguageSearchParams] = useState<string>('');
  const [multiLanguagesSelectActive, setMultiLanguagesSelectActive] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');

  const multiLanguageSelectRef = useRef<any>();
  const initialCompanyLanguagesRef = useRef<ILanguage[] | undefined>();
  const companyInfoRef = useRef<FormikProps<FormValues>>(null);

  const maxSize = 819200;

  const getData = async () => {
    let companyLanguages: ILanguage[] = id ? await Api.getCompanyLanguages(+id).then((res) => res.data) : [];

    if (!companyLanguages) {
      companyLanguages = [];
    }

    initialCompanyLanguagesRef.current = [...companyLanguages];

    const companyLanguageIds = companyLanguages.map((item) => item.id) || [];

    if (id && company && company.id === +id) {
      Api.getCompanyMetadataByCompanyId(+id)
        .then((res) => {
          dispatch(setCompanyMetadata(res.data));
          setInitialValues({
            name: company.name!,
            logo: {
              value: res.data.logo?.value || '',
              id: res.data.logo?.id,
            },
            domain: company.domain!,
            extraFields: res.data.extraFields || [],
            languages: languagesState?.map((lang) => ({ ...lang, value: companyLanguageIds.includes(lang.id) })) || [],
          });
        });
    } else if (id) {
      Api.getCompanyById(+id)
        .then((result) => {
          dispatch(setSelectedCompany(result.data));
          Api.getCompanyMetadataByCompanyId(+id)
            .then((res) => {
              dispatch(setCompanyMetadata(res.data));
              setInitialValues({
                name: result.data.name!,
                logo: {
                  value: res.data.logo?.value || '',
                  id: res.data.logo?.id,
                },
                domain: result.data.domain!,
                extraFields: res.data.extraFields || [],
                languages: languagesState?.map((lang) => ({ ...lang, value: companyLanguageIds.includes(lang.id) })) || [],
              });
            });
        });
    } else if (!id) {
      setInitialValues({
        name: '',
        logo: {
          value: '',
        },
        domain: '',
        extraFields: [],
        languages: languagesState?.map((lang) => ({ ...lang, value: false })) || [],
      });
    } else {
      navigate('/companies');
    }
  };

  useEffect(() => {
    if (languagesState) {
      getData();
    } else {
      getLanguages(dispatch);
    }
  }, [id, languagesState]);

  useClickOutside(multiLanguageSelectRef, () => {
    setMultiLanguagesSelectActive(false);
  });

  useEffect(() => {
    setLanguageSearchParams('');
  }, [multiLanguagesSelectActive]);

  const logoExamination = (file: File | undefined) => {
    if (file!.size > maxSize) {
      setMaxSizeError(getTranslationByLangOrEng(interfaceLanguage, 'max_size_error'));
      return;
    }
    setMaxSizeError('');
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
    domain: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')).matches(
      /^[a-z0-9]+([.-]{0,1}([a-z0-9]+))+([a-z0-9]*)$/gm,
      getTranslationByLangOrEng(interfaceLanguage, 'validation_domain'),
    ),
    languages: Yup.array().of(
      Yup.object().shape({
        id: Yup.number().required(),
        name: Yup.string().required(),
        icon: Yup.string().required(),
        value: Yup.boolean().default(false),
      }),
    ).min(1).test('at-least-one-selected', 'At least one language must be selected', (values) => {
      if (!Array.isArray(values)) {
        return false;
      }
      return values.some((value) => value.value === true);
    }),
  });

  const onSaveModalConfirm = async (values: FormValues) => {
    setIsLoading(true);

    const selectedLanguages = values.languages.filter((lang) => lang.value);

    const extraFieldsWithPosition: ICompanyExtraField[] = values.extraFields.map((item, index) => ({
      ...item,
      position: String(index),
    }));

    if (id && company && companyMetadata) {
      const extraFieldToDelete = companyMetadata.extraFields?.filter((extraField) => extraFieldsWithPosition.every((item) => extraField.id !== item.id));
      const extraFieldToCreate = extraFieldsWithPosition?.filter((extraField) => !extraField.id);
      const extraFieldToUpdate = extraFieldsWithPosition?.filter((extraField) => {
        const fieldToCheck = companyMetadata.extraFields?.find((item) => item.id === extraField.id);

        if (fieldToCheck) {
          return fieldToCheck.value !== extraField.value || fieldToCheck.value !== extraField.name || fieldToCheck.position !== extraField.position;
        }

        return false;
      });

      if (initialCompanyLanguagesRef.current?.length !== 0 && selectedLanguages.length === 0) {
        Api.updateCompanyLanguages(+id, selectedLanguages);
      } else if (initialCompanyLanguagesRef.current?.length === 0 && selectedLanguages.length !== 0) {
        Api.updateCompanyLanguages(+id, selectedLanguages);
      } else if (selectedLanguages.length !== initialCompanyLanguagesRef.current?.length || selectedLanguages.filter((lang) => initialCompanyLanguagesRef.current?.map((language) => language.id!).includes(lang.id!)).length !== initialCompanyLanguagesRef.current?.length) {
        Api.updateCompanyLanguages(+id, selectedLanguages);
      }

      if (extraFieldToDelete?.length) {
        extraFieldToDelete.forEach(async (item) => {
          await Api.deleteCompanyMetadata(item.id!);
        });
      }

      if (extraFieldToUpdate.length) {
        extraFieldToUpdate.forEach(async (item) => {
          await Api.updateCompanyMetadata(item.id!, [item.name, item.value, item.position].join('|||'));
        });
      }

      if (extraFieldToCreate.length) {
        extraFieldToCreate.forEach(async (extraField) => {
          await Api.createCompaniesMetadata({ key: `extraField_${Math.random()}`, value: [extraField.name, extraField.value, extraField.position].join('|||') }, +id);
        });
      }

      if (company.name !== values.name || company.domain !== values.domain) {
        await Api.editCompanyMainInfo({ name: values.name, alias: values.domain, id: +id });
      }

      if (values.logo.value instanceof File) {
        await Api.createFile({ file: values.logo.value })
          .then(async (res) => {
            if (values.logo.id) {
              await Api.updateCompanyMetadata(values.logo.id, res.data.url);
            } else {
              await Api.createCompaniesMetadata({ key: 'logo', value: res.data.url }, +id);
            }
          });
      }

      if (!submitError) {
        setAfterSaveModal(2);
        setConfirmSaveModalOpen(false);
      } else {
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      }
    } else {
      await Api.createCompany({ name: values.name, alias: values.domain })
        .then(async (res) => {
          if (selectedLanguages.length) {
            Api.updateCompanyLanguages(+res.data.id, selectedLanguages);
          }
          if (extraFieldsWithPosition.length) {
            extraFieldsWithPosition.forEach(async (extraField) => {
              await Api.createCompaniesMetadata({ key: `extraField_${Math.random()}`, value: [extraField.name, extraField.value, extraField.position].join('|||') }, res.data.id);
            });
          }

          if (values.logo.value instanceof File) {
            await Api.createFile({ file: values.logo.value })
              .then(async (fileRes) => {
                await Api.createCompaniesMetadata({ key: 'logo', value: fileRes.data.url }, res.data.id);
              });
          }

          dispatch(setSelectedCompany(mapFromApiCompanyToICompany(res.data)));
          setAfterSaveModal(1);
        }).catch((e) => {
          console.log('e', e);
          setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
        });
    }

    setIsLoading(false);
  }

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    if (id && company && companyMetadata) {
      setConfirmSaveModalOpen(true);
      setSubmitting(false);
    } else {
      const selectedLanguages = values.languages.filter((lang) => lang.value);
      const extraFieldsWithPosition: ICompanyExtraField[] = values.extraFields.map((item, index) => ({
        ...item,
        position: String(index),
      }));

      await Api.createCompany({ name: values.name, alias: values.domain })
        .then(async (res) => {
          if (selectedLanguages.length) {
            Api.updateCompanyLanguages(+res.data.id, selectedLanguages);
          }
          if (extraFieldsWithPosition.length) {
            extraFieldsWithPosition.forEach(async (extraField) => {
              await Api.createCompaniesMetadata({ key: `extraField_${Math.random()}`, value: [extraField.name, extraField.value, extraField.position].join('|||') }, res.data.id);
            });
          }

          if (values.logo.value instanceof File) {
            await Api.createFile({ file: values.logo.value })
              .then(async (fileRes) => {
                await Api.createCompaniesMetadata({ key: 'logo', value: fileRes.data.url }, res.data.id);
              });
          }

          dispatch(setSelectedCompany(mapFromApiCompanyToICompany(res.data)));
          setAfterSaveModal(1);
        }).catch((e) => {
          console.log('e', e);
        });
      setSubmitting(false);
    }
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="addLogoWrapper">
        <div className={values.logo.value ? 'logoContainer' : 'logoContainer default'}>
          {values.logo.value ? (
            <img
              className="selectedImage"
              src={typeof values.logo.value === 'string' ? values.logo.value : URL.createObjectURL(values.logo.value)}
              alt=""
            />
          ) : (
            <img
              src={DefaultCompanyImage}
              alt="logo"
            />
          )}
        </div>

        <div className="infoContainer">
          <UploadFileButton
            onChange={(file) => {
              logoExamination(file);
              setFieldValue('logo.value', file);
            }}
            text={!values.logo ? getTranslationByLangOrEng(interfaceLanguage, 'companies_info_file_not_selected') : typeof values.logo.value === 'string' ? values.logo.value.split('/')[values.logo.value.split('/').length - 1] : values.logo.value.name}
            formats="image/*"
          />
          <p className="uploadError">{maxSizeError}</p>
          <span className="description">{getTranslationByLangOrEng(interfaceLanguage, 'companies_info_file_max_size')}</span>
        </div>
      </div>

      <InputField
        name="name"
        onChange={setFieldValue}
        onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_name_placeholder')}
        value={values.name}
        error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
        label={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_name_label')}
      />
      <div className="domainInputWrapper">
        <InputField
          name="domain"
          type="text"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('domain', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_domain_placeholder')}
          value={values.domain}
          error={typeof errorMessage === 'object' ? getErrorMessage('domain', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_domain_label')}
        >
          <span className="labelDomain">.voicer.digital</span>
        </InputField>
      </div>

      <CustomSelectWithMultipleCheckboxes
        name="languages"
        handleChange={handleChange}
        options={values.languages}
        search
        error={typeof errorMessage === 'object' ? getErrorMessage('languages', errorMessage) : undefined}
        optionsContainerStyles={{ maxWidth: '253px', width: '100%' }}
        formGroupStyles={{ maxWidth: '253px', width: '100%', marginBottom: '47px' }}
        label={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_multi_languages_label')}
        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_add_multi_languages_placeholder')}
      />

      <FieldArray
        name="extraFields"
        render={({ insert, remove, push }) => (
          <div>
            {values.extraFields.length > 0
              && values.extraFields.map((field, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <div className="extraFieldContainer" key={index}>
                  <div className="fields">
                    <InputField
                      name={`extraFields[${index}].name`}
                      onChange={setFieldValue}
                      onKeyUp={() => handleKeyUp(`extraFields[${index}].title`, setErrorMessage, errorMessage)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_extra_field_name_placeholder')}
                      value={values.extraFields[index].name}
                      // @ts-ignore*
                      error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].name`, errorMessage) : undefined}
                    />
                    <InputField
                      name={`extraFields[${index}].value`}
                      onChange={setFieldValue}
                      onKeyUp={() => handleKeyUp(`extraFields[${index}].value`, setErrorMessage, errorMessage)}
                      placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_company_extra_field_value_placeholder')}
                      value={values.extraFields[index].value}
                      // @ts-ignore*
                      error={typeof errorMessage === 'object' ? getErrorMessage(`extraFields[${index}].value`, errorMessage) : undefined}
                    />
                  </div>

                  <div className="removeButtonContainer">
                    <button
                      type="button"
                      onClick={() => remove(index)}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
              ))}

            <div className="addFieldButtonContainer">
              <TransparentButton handleClick={() => push({ name: '', value: '' })} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_add_field_button')} filled />
            </div>
          </div>
        )}
      />

      {id ? <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'companies_info_submit_edit_button')}</SubmitButton> : <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'companies_info_submit_create_button')}</SubmitButton>}

      {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
    </Form>
  );

  return (
    <CompanyInfoFormStyles>
      {afterSaveModal === 1
        && (
        <CompaniesInfoModal
          onClose={() => setAfterSaveModal(0)}
          onDecline={() => navigate('/companies')}
          onConfirm={() => navigate(`/companies/${company?.id}/edit`)}
          confirmText={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_after_save_modal_confirm_button')}
          title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_after_save_modal_title')}
          declineText={getTranslationByLangOrEng(interfaceLanguage, 'go_back')}
        />
        )}
      {afterSaveModal === 2 && (
      <CompaniesInfoModal
        onClose={() => setAfterSaveModal(0)}
        onDecline={() => navigate('/companies')}
        onConfirm={() => setAfterSaveModal(0)}
      />
      )}
      {!initialValues ? <Loader /> : (
        <div className="formContainer">
          <Formik
            innerRef={companyInfoRef}
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {renderForm}
          </Formik>
          <p className="uploadError">{submitError}</p>
        </div>
      )}

      {confirmSaveModalOpen && (
      <ModalConfirmDecline
        onClose={() => setConfirmSaveModalOpen(false)}
        onConfirm={() => onSaveModalConfirm(companyInfoRef.current!.values)}
        onDecline={() => setConfirmSaveModalOpen(false)}
        confirmText={getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}
        declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_info_confirmation_before_save_modal_title')}
        error={!!dataSaveError}
        errorText={dataSaveError}
        firstButtonStyles={{ background: '#ff0000' }}
        secondButtonStyles={{ background: '#0E9285' }}
      />
      )}
    </CompanyInfoFormStyles>
  );
}
