import React from 'react';

import { ArrowDownIcon } from '../../../../assets';
import { DeepArrayItem } from '../../../../entities/DeepArrayItem';
import { CheckboxSquare } from '../../squareCheckbox/checkboxSquare/CheckboxSquare';
import { SelectItemStyles } from './SelectItemStyles';

interface SelectItemProps {
  option: DeepArrayItem,
  selectKey: string,
  inputName: string,
  handleSelect: (value: any) => any,
  setOpen: (open: boolean) => void,
  imageProperty?: string,
  expandable?: boolean,
  handleExpandRow?: (id: number) => void,
  expandedRows?: {[key: number]: boolean},
  childrenKey?: string,
  selectable?: boolean,
  checkboxSelected?: boolean,
  checkboxHasSelectedChildren?: boolean,
}

export default function SelectItem({
  option,
  selectKey,
  inputName,
  handleSelect,
  imageProperty,
  expandable,
  handleExpandRow,
  expandedRows,
  childrenKey,
  setOpen,
  selectable,
  checkboxSelected,
  checkboxHasSelectedChildren,
}: SelectItemProps) {
  return (
    <SelectItemStyles
        // @ts-ignore
      key={`selectOption:${option.id}:${option[selectKey]}`}
      selectable={!!selectable}
    >
      <div className="row">
        <input
          autoComplete="false"
          type="radio"
          name={inputName}
            // @ts-ignore
          value={option[selectKey]}
            // @ts-ignore
          id={`${option[selectKey]}:${option.id}`}
        />
        <button
          type="button"
          className={imageProperty ? 'withImage' : ''}
          onClick={() => {
            handleSelect(option);
            setOpen(false);
          }}
        >
          <div className="labelContent" style={{ paddingLeft: 16 + option.deep * 16, textAlign: option.name === '-' ? 'center' : 'left' }}>
            {selectable && (
              <CheckboxSquare
                selected={!!checkboxSelected}
                hasSelectedChildren={checkboxHasSelectedChildren}
                handleOnClick={() => null}
              />
            )}
            {imageProperty && (
            // @ts-ignore
            <span className="emoji">{option[imageProperty]}</span>
            )}
            {/* @ts-ignore */}
            <span>{option[selectKey]}</span>
          </div>
        </button>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {expandable && option.children.length > 0 && <button className="expandButton" type="button" onClick={() => (handleExpandRow && handleExpandRow(option.id))}><ArrowDownIcon /></button>}
      </div>

      {expandable
          // @ts-ignore
          && expandedRows![option.id]
          && !!childrenKey
          // @ts-ignore
          && option[childrenKey]?.map((subItem: any) => (
            <SelectItem
              option={subItem}
              selectKey={selectKey}
              childrenKey={childrenKey}
              inputName={`${inputName}:${subItem.id}`}
              expandedRows={expandedRows}
              expandable={expandable && subItem.children.length > 0}
              handleExpandRow={handleExpandRow}
              imageProperty={imageProperty}
              handleSelect={handleSelect}
              setOpen={setOpen}
              selectable={selectable}
              checkboxSelected={selectable ? subItem.selected : undefined}
              checkboxHasSelectedChildren={selectable ? subItem.hasSelectedChildren : undefined}
              // handleCheckbox={selectable ? handleCheckbox : undefined}
            />
          ))}
    </SelectItemStyles>
  )
}
