import { FileUploadPermissionModuleStyles } from './FileUploadPermissionModuleStyles';
import React, { CSSProperties, useEffect, useState } from 'react';
import FieldHeader from '../fieldHeader/FieldHeader';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import Checkbox from '../../form/checkbox/Checkbox';
import {
  DocumentIcon, ImageIcon,
  InfoCharIcon, MicrophoneIcon,
  VideoIcon,
} from '../../../assets';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import { IExtraField } from '../../../entities/IExtraField';
import { Api } from '../../../api';
import { extraFieldValidationSchema } from '../validationSchemas';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import StepTextSettings from '../stepTextSettings/StepTextSettings';
import { useCompanyColors } from '../../../hooks';
import { FormErrorMessage, Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface FileUploadPermissionModuleProps {
  index: number;
  extraBlockStyles?: CSSProperties;
  provided?: DraggableProvided;
  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;
}

export default function FileUploadPermissionModule({
  index,
  extraBlockStyles,

  draggable,
  handleCopy,
  handleRemove,
  // handleOpenOnDiagram,
  provided,
}: FileUploadPermissionModuleProps) {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedExtraFields, notSavedForm } = useAppSelector((state) => state.notSavedForm);

  const { companyColors, addCompanyColor } = useCompanyColors(companyId!);

  const [nextFieldOptions, setNextFieldOptions] = useState<IExtraField[]>();
  const [initialValues, setInitialValues] = useState<IExtraField>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    if (notSavedExtraFields) {
      setInitialValues(notSavedExtraFields[index]);
    }
  }, [index]);

  async function onSubmit(
    values: IExtraField,
    { setSubmitting }: FormikHelpers<IExtraField>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateField(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    setIsLoading(false);
    dispatch(updateNotSavedExtraField(values));
  }

  function getFileTypeImage(type: 'audio' | 'document' | 'image' | 'video') {
    const types = {
      audio: <MicrophoneIcon color="#0E9285" />,
      document: <DocumentIcon color="#0E9285" />,
      image: <ImageIcon color="#0E9285" />,
      video: <VideoIcon color="#0E9285" />,
    };

    return types[type];
  }

  function getFileTypeName(type: 'audio' | 'document' | 'image' | 'video') {
    const types = {
      audio: getTranslationByLangOrEng(interfaceLanguage, 'record'),
      document: getTranslationByLangOrEng(interfaceLanguage, 'document'),
      image: getTranslationByLangOrEng(interfaceLanguage, 'photo'),
      video: getTranslationByLangOrEng(interfaceLanguage, 'video'),
    };

    return types[type];
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IExtraField>) {
    const error = typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined;

    return (
      <Form>
        <div className="body">

          <div className="inputContainer">
            <div className="inputRaw">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title_title')}</span>
              </div>
              <div className="inputElementContainer">
                <input
                  id="question"
                  type="text"
                  value={values.question}
                  onChange={(event) => setFieldValue('question', event.target.value)}
                  onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_upload_files_placeholder')}
                />
                <div className="infoContainer">
                  <div className="infoIcon">
                    <InfoCharIcon />
                  </div>
                  <div className="infoText">
                    <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_question')}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_upload_files_label')}</h3>

          <div className="options">
            {values.options!.map((field: any, subIndex: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="checkboxFieldContainer" key={subIndex}>
                <SquareCheckbox
                  name={`options[${subIndex}].selected`}
                  value={values.options![subIndex].selected!}
                  onChange={handleChange}
                  checkboxWrapperStyles={{ alignItems: 'center' }}
                  customizedCheckboxContainerStyles={{ marginBottom: 24 }}
                >
                  <div className="checkboxBody">
                    <div className="iconContainer">
                      {getFileTypeImage(field.value)}
                    </div>
                    <span>{getFileTypeName(field.value)}</span>
                  </div>
                </SquareCheckbox>
              </div>
            ))}
          </div>
          <div className="formErrorContainer">
            {error && <span>{error}</span>}
            {!error && touched && <ErrorMessage name="options" component={FormErrorMessage} />}
          </div>

          {notSavedForm?.withSteps && values.type !== 'title' && (
            <StepTextSettings
              stepsText={values.stepsText || ''}
              handleKeyUp={handleKeyUp}
              setFieldValue={setFieldValue}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          )}

          <Checkbox
            name="required"
            value={values.required!}
            onChange={handleChange}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question')}</span>
          </Checkbox>
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <FileUploadPermissionModuleStyles style={extraBlockStyles}>
      <FieldHeader
        type={notSavedExtraFields?.[index].type || ''}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_upload_files_title')}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // @ts-ignore
          validationSchema={notSavedExtraFields?.[index].type ? extraFieldValidationSchema[notSavedExtraFields![index].type] : undefined}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </FileUploadPermissionModuleStyles>
  );
}
