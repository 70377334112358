import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const OverdueIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <path fill="#F00801" d="M589,350c0-132-107-239-239-239S111,218,111,350s107,239,239,239S589,482,589,350z" />
    <path
      fill="#FFFFFF"
      d="M454.9,380.3H245.1c-14.4,0-26.1-11.7-26.1-26.1v-6.7c0-14.4,11.7-26.1,26.1-26.1h209.9
  c14.4,0,26.1,11.7,26.1,26.1v6.7C481,368.5,469.3,380.3,454.9,380.3z"
    />
  </svg>
));
