import React, { useEffect } from 'react';
import { useAppSelector } from '../../../../state';
import MassMailingCreatingEmailStep3 from '../connectionChannels/email/step3/MassMailingCreatingEmailStep3';
import { useNavigate } from 'react-router-dom';
import MassMailingCreatingViberStep3 from '../connectionChannels/viber/step3/MassMailingCreatingViberStep3';
import MassMailingCreatingSmsStep3 from '../connectionChannels/sms/step3/MassMailingCreatingSmsStep3';

function MassMailingCreationStep3() {
  const navigate = useNavigate();

  const { massMailingMainInfo } = useAppSelector((state) => state.massMailing);

  const getMassMailingConnectionChannelForm = () => {
    if (massMailingMainInfo) {
      switch (massMailingMainInfo.connectionChannelKey) {
        case 1:
          return (
            <MassMailingCreatingEmailStep3 />
          );
        case 3:
          return (
            <MassMailingCreatingViberStep3 />
          );
        case 4:
          return (
            <MassMailingCreatingSmsStep3 />
          );

        default:
          return 'Connection type not found';
      }
    }
  };

  useEffect(() => {
    if (!massMailingMainInfo) {
      navigate('/massMailings/create');
    }
  }, []);

  // TODO: change default

  return (
    <div>
      {getMassMailingConnectionChannelForm()}
    </div>
  );
}

export default MassMailingCreationStep3;
