import styled from 'styled-components';

export const CompanyIntegrationsStyles = styled.div`
    padding: 20px 30px;

    .formContainer {
        max-width: 1200px;

        .dot {
            border-radius: 50%;
            width: 15px;
            height: 15px;
            background: red;
        }
        .active {
            background: green;
        }

        .integrationHeader {
            background: rgb(240, 245, 245);
            padding: 10px 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        form {
            border: 1px solid rgb(240, 240, 240);

            .connected {
                padding: 20px;
            }
        }

        
        button {
            width: 100%;
        }

        .accountsContainer {
            width: 100%;
            padding: 15px 20px;
            display: flex;
            gap: 20px;
            align-items: center;
            flex-wrap: wrap;

            button {
                max-width: 250px;
            }
        }
    }
`;
