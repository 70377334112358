import { useState } from 'react'
import { ImpexStyles } from './ImpexStyles'
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { getTranslationByLangOrEng } from '../../i18n';
import { Export, SubHeader, Import } from '../../components';

export const tabs: (lang: string) => {[key: string]: string}[] = (lang) => ([
  {
    key: 'import',
    text: getTranslationByLangOrEng(lang, 'impex_import_tab'),
  },
  {
    key: 'export',
    text: getTranslationByLangOrEng(lang, 'impex_export_tab'),
  },
]);

export const Impex = () => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [selectedTab, setSelectedTab] = useState('import');

  return (
    <ImpexStyles>
      <Helmet>
        <title>Import/Export Voicer</title>
      </Helmet>
      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'impex_page_title')}
        hideBorderBottom
      />
      <div className="tabsContainer">
        {tabs(interfaceLanguage).map((tab) => (
          <button
            className={selectedTab === tab.key ? 'active' : ''}
            key={tab.key}
            type="button"
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.text}
          </button>
        ))}
      </div>

      <div className="contentWrapper">
        {selectedTab === 'import' && <Import />}
        {selectedTab === 'export' && <Export />}
      </div>
    </ImpexStyles>
  )
}
