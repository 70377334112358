import styled from 'styled-components';

export const EditStyles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;

    h2 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
  }
  
  .tabsContainer {
    width: 100%;
    padding-left: 16px;
    padding-right: 32px;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    border-bottom: 1px solid #F0F0F0;

    button {
      height: 24px;
      display: flex;
      align-items: flex-start;
      border-bottom: 3px solid #FFF;
      margin-right: 32px;

      font-size: 15px;
      line-height: 16px;
      color: #7C7878;
      transition: all 0.3s;
      
      &:last-child {
        margin-right: 0;
      }
      
      &.active {
        border-bottom: 3px solid #0E9285;
        color: #0E9285;
        transition: all 0.3s;
      }
    }
  }
`;
