import React, { useEffect, useState, useRef } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { getTranslationByLangOrEng } from '../../../../../../i18n';
import { MassMailingCreatingSmsStep3Styles } from './MassMailingCreatingSmsStep3Styles';
import SubmitButton from '../../../../../../components/form/submitButton/SubmitButton';
import {
  useAppSelector, useAppDispatch, setMassMailingsSms, setMassMailingMainInfoCompany,
} from '../../../../../../state';
import { Helmet } from 'react-helmet-async';
import { ModalConfirmDecline, SubHeader, TransparentButton } from '../../../../../../components';
import { Api } from '../../../../../../api';

interface FormValues {
  senderAccount: string;
  text: string;
  phones: string[];
}

export default function MassMailingCreatingSmsStep3() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { massMailingsSms, massMailingMainInfo } = useAppSelector((state) => state.massMailing);

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const smsMassMailingsInfoRef = useRef<FormikProps<FormValues>>(null);
  const [successSendingModalOpen, setSuccessSendingModalOpen] = useState<boolean>(false);

  function getInitialDataFromRedux() {
    if (massMailingsSms) {
      const initialDataFromRedux: FormValues = {
        senderAccount: massMailingsSms.senderAccount,
        text: massMailingsSms.text,
        phones: massMailingsSms.phones,
      }
      setInitialValues(initialDataFromRedux)
    }
  }

  function resetMassMailingsReduxData() {
    setSuccessSendingModalOpen(false)
    if (massMailingsSms) {
      dispatch(setMassMailingsSms(null))
    }
    if (massMailingMainInfo) {
      dispatch(setMassMailingMainInfoCompany(null))
    }
    navigate('/massMailings/create');
  }

  const onSendMassMailingsSmsModalConfirm = async (values: FormValues) => {
    setIsLoading(true);
    setConfirmModalOpen(false);

    Api.sendMassMailingsSms({ phones: values.phones, data: values.text })
      .then((res) => {
        if (res.statusCode === 200) {
          setSuccessSendingModalOpen(true);
        } else {
          setDataSaveError(`* ${getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_error_text')}: ${res.statusCode}`);
        }
      })
      .catch((e) => {
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_error_text'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setConfirmModalOpen(true);
    setSubmitting(false);
  }

  useEffect(() => {
    getInitialDataFromRedux()
    setIsLoading(false);
  }, []);

  const renderForm = ({
    values,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formFieldsContainer">
        <div className="senderAccountInputContainer">
          <span className="title">{getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_sender_account')}</span>
          <span className="senderAccountName">{values.senderAccount}</span>
        </div>
        <div className="textContainer">
          <span className="title">{`${getTranslationByLangOrEng(interfaceLanguage, 'sms_text')}:`}</span>
          <p className="messageText">{values.text}</p>
        </div>
      </div>

      <div className="mailsCountContainer">
        <span>{`${getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_mails_count')}: ${values.phones.length || 0}`}</span>
      </div>
      <SubmitButton disabled={isLoading} isLoading={isLoading} extraButtonStyles={{ maxWidth: '540px', width: '100%' }}>
        {getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_send_button')}
      </SubmitButton>

      {dataSaveError && (<p className="extraErrorMessage">{`* ${dataSaveError}`}</p>)}
    </Form>
  );

  return (
    <MassMailingCreatingSmsStep3Styles>
      <Helmet>
        <title>{getTranslationByLangOrEng(interfaceLanguage, 'create_mass_mailings_page_title')}</title>
      </Helmet>
      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'create_sms_mass_mailings_third_step_subheader_title')}>
        <TransparentButton
          disabled={isLoading}
          handleClick={() => navigate('/massMailings/create/step-2')}
          text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')}
        />
      </SubHeader>

      {initialValues && (
      <Formik
        innerRef={smsMassMailingsInfoRef}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {renderForm}
      </Formik>
      )}

      {confirmModalOpen && (
      <ModalConfirmDecline
        onClose={() => setConfirmModalOpen(false)}
        onConfirm={() => onSendMassMailingsSmsModalConfirm(smsMassMailingsInfoRef.current!.values)}
        onDecline={() => setConfirmModalOpen(false)}
        confirmText={getTranslationByLangOrEng(interfaceLanguage, 'yes_button_text')}
        declineText={getTranslationByLangOrEng(interfaceLanguage, 'no_button_text')}
        title={getTranslationByLangOrEng(interfaceLanguage, 'confirm_send_mass_mailings_modal_title')}
        firstButtonStyles={{ background: '#ff0000' }}
        secondButtonStyles={{ background: '#0E9285' }}
      />
      )}

      {successSendingModalOpen && (
      <ModalConfirmDecline
        onDecline={resetMassMailingsReduxData}
        onClose={() => setSuccessSendingModalOpen(false)}
        title={getTranslationByLangOrEng(interfaceLanguage, 'modal_mass_mailings_sent_success_title')}
        firstButtonStyles={{ backgroundColor: '#0E9285', border: '1px solid #0E9285' }}
        declineText={getTranslationByLangOrEng(interfaceLanguage, 'ok_button_text')}
        isConfirmButtonShown={false}
      />
      )}

    </MassMailingCreatingSmsStep3Styles>
  );
}
