import React, { CSSProperties, useEffect, useState } from 'react';
import { SendButtonFieldStyles } from './SendButtonFieldStyles';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import FieldHeader from '../fieldHeader/FieldHeader';
import { getTranslationByLangOrEng } from '../../../i18n';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
import { mapIColorToString } from '../../../mappers';
import {
  useAppDispatch, useAppSelector, setNotSavedForm, setNotSavedSendButton,
} from '../../../state';
import { ISendButton } from '../../../entities/IExtraField';
import { Api } from '../../../api';
import { sendButtonValidationSchema } from '../validationSchemas';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import { FormErrorMessage, Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface SendButtonFieldProps {
  extraBlockStyles?: CSSProperties;
}

export default function SendButtonField({ extraBlockStyles }: SendButtonFieldProps) {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedForm, notSavedSendButton, notSavedColors } = useAppSelector((state) => state.notSavedForm);

  const [initialValues, setInitialValues] = useState<ISendButton>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (notSavedSendButton) {
      setInitialValues(notSavedSendButton);
    }
  }, []);

  async function onSubmit(
    values: ISendButton,
    { setSubmitting }: FormikHelpers<ISendButton>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateFormSendButton(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(setNotSavedSendButton(values));

    dispatch(setNotSavedForm({
      ...notSavedForm!,
      sendButtonBackgroundColor: values.backgroundColor || {
        name: 'Default color',
        color: '#0E9285',
      },
      sendButtonTextColor: values.textColor || {
        name: 'White',
        color: '#fff',
      },
      sendButtonDisabledBackgroundColor: values.sendButtonDisabledBackgroundColor || {
        name: 'Default disabled color',
        color: '#8c8c8c',
      },
      sendButtonDisabledTextColor: values.sendButtonDisabledTextColor || {
        name: 'White',
        color: '#fff',
      },
    }));

    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<ISendButton>) {
    const textError = typeof errorMessage === 'object' ? getErrorMessage('text', errorMessage) : undefined;

    return (
      <Form>
        <div className="body">
          <div className="inputContainer">
            <div className="inputRaw">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_text')}</span>
              </div>
              <div className="inputElementContainer">
                <input
                  id="text"
                  type="text"
                  value={values.text}
                  onChange={(event) => setFieldValue('text', event.target.value)}
                  onKeyUp={() => handleKeyUp('text', setErrorMessage, errorMessage)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_text')}
                />
                <div className="buttonPreviews">
                  <button
                    type="button"
                    className="send_button_preview"
                    style={{
                      backgroundColor: values.backgroundColor ? mapIColorToString(values.backgroundColor) : '#0E9285',
                      color: values.textColor ? mapIColorToString(values.textColor) : '#FFF',
                    }}
                  >
                    {values.text || 'Send'}
                  </button>
                  <button
                    type="button"
                    className="send_button_preview"
                    style={{
                      backgroundColor: values.sendButtonDisabledBackgroundColor ? mapIColorToString(values.sendButtonDisabledBackgroundColor) : '#8c8c8c',
                      color: values.sendButtonDisabledTextColor ? mapIColorToString(values.sendButtonDisabledTextColor) : '#FFF',
                    }}
                  >
                    {values.text || 'Send'}
                  </button>
                </div>
              </div>
            </div>
            <div className="formErrorContainer">
              {textError && <span>{textError}</span>}
              {!textError && touched && <ErrorMessage name="text" component={FormErrorMessage} />}
            </div>
          </div>

          <div className="colorSelectContainer">
            <div className="nameContainer">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_text_color')}</span>
            </div>
            <CustomColorsSelect
              name="textColor"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
              options={notSavedColors || []}
              value={values.textColor}
              handleSelect={(color) => setFieldValue('textColor', color)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              extraComponentPosition="left"
              handleAddColor={(color) => {
                // onChange(`colors[${colors!.length}]`, color);
                setFieldValue('textColor', color);
              }}
            />
          </div>

          <div className="colorSelectContainer">
            <div className="nameContainer">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_disabled_button_text_color')}</span>
            </div>
            <CustomColorsSelect
              name="sendButtonDisabledTextColor"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
              options={notSavedColors || []}
              value={values.sendButtonDisabledTextColor}
              handleSelect={(color) => setFieldValue('sendButtonDisabledTextColor', color)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              extraComponentPosition="left"
              handleAddColor={(color) => {
                // onChange(`colors[${colors!.length}]`, color);
                setFieldValue('sendButtonDisabledTextColor', color);
              }}
            />
          </div>

          <div className="colorSelectContainer">
            <div className="nameContainer">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_background_color')}</span>
            </div>
            <CustomColorsSelect
              name="backgroundColor"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
              options={notSavedColors || []}
              value={values.backgroundColor}
              handleSelect={(color) => setFieldValue('backgroundColor', color)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              extraComponentPosition="left"
              handleAddColor={(color) => {
                // onChange(`colors[${colors!.length}]`, color);
                setFieldValue('backgroundColor', color);
              }}
            />
          </div>

          <div className="colorSelectContainer">
            <div className="nameContainer">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_disabled_send_button_background_color')}</span>
            </div>
            <CustomColorsSelect
              name="sendButtonDisabledBackgroundColor"
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
              options={notSavedColors || []}
              value={values.sendButtonDisabledBackgroundColor}
              handleSelect={(color) => setFieldValue('sendButtonDisabledBackgroundColor', color)}
              formGroupStyles={{ width: '300', marginBottom: 0 }}
              extraComponentPosition="left"
              handleAddColor={(color) => {
                // onChange(`colors[${colors!.length}]`, color);
                setFieldValue('sendButtonDisabledBackgroundColor', color);
              }}
            />
          </div>
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <SendButtonFieldStyles style={extraBlockStyles}>
      <FieldHeader
        type="sendButton"
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_send_button_title')}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={notSavedSendButton ? sendButtonValidationSchema(interfaceLanguage) : undefined}
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </SendButtonFieldStyles>
  );
}
