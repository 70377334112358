import styled from 'styled-components';

export const StepTextSettingsStyles = styled.div`
  .stepTextContainer {
    margin-bottom: 24px;
  }

  .stepTextUnderlineColorSelect {
    width: 100%;
    max-width: 300px;
  }
`
