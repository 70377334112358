import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useNavigate, useParams } from 'react-router-dom';
import { AddDocumentStyles } from './AddDocumentStyles';
import InputField from '../../../components/form/inputField/InputField';
import SubmitButton from '../../../components/form/submitButton/SubmitButton';
import {
  useAppDispatch, useAppSelector, setDocument, setLocation,
} from '../../../state';
import { getTranslationByLangOrEng } from '../../../i18n';
import { UploadFileButton } from '../../../components/form/uploadFileButton/UploadFileButton';
import { Api, ApiFile } from '../../../api';
import { Helmet } from 'react-helmet-async';
import { useCompanyName } from '../../../hooks/useCompanyName';
import { Loader, SubHeader, TransparentButton } from '../../../components';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface FormValues {
  languages: {id: number, key: string, name: string}[];
  name: string;
  document: File | string;
  url: string;
}

export default function AddDocument() {
  const navigate = useNavigate();
  const { companyId, documentId } = useParams();
  const { companyName } = useCompanyName(companyId);

  const { document } = useAppSelector((state) => state.company);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const dispatch = useAppDispatch();

  const [submittingError, setSubmittingError] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] = useState<number>(0);
  const [sending, setSending] = useState<boolean>(false);
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [fileDocument, setFileDocument] = useState<ApiFile | undefined>();
  const [maxSizeError, setMaxSizeError] = useState<string>('');

  useEffect(() => {
    try {
      setIsLoaded(false);
      if (documentId && companyId) {
        Api.getDocumentById(+documentId)
          .then((res) => {
            setInitialValues({
              name: res.data.name,
              document: res.data.fileName,
              languages: [],
              url: res.data.url,
            });
          });
      } else if (companyId) {
        const languages: { key: string, name: string, id: number }[] = [];
        const name: string = '';
        const document: File | string = '';

        setInitialValues({
          languages,
          name,
          document,
          url: '',
        });
      } else {
        navigate(`/companies/${companyId}/edit`);
      }
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoaded(true);
    }
  }, [companyId]);

  function handleCancel() {
    dispatch(setDocument(undefined));
    dispatch(setLocation('documents'));
    navigate(`/companies/${companyId}/edit`);
  }

  const validationSchema = Yup.object({
    name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
  });

  const documentSizeExamination = (document: File) => {
    if (document.size >= 100000000) {
      return setMaxSizeError(getTranslationByLangOrEng(interfaceLanguage, 'max_size_error'));
    }

    setMaxSizeError('');

    if (documentId) {
      Api.updateFile(+documentId, { file: document })
        .then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setFileDocument(res.data);
          }
        });
    } else {
      Api.createFile({ file: document })
        .then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setFileDocument(res.data);
          }
        });
    }

    dispatch(setLocation('documents'));
  };

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);
    setSending(true);

    if (documentId && companyId) {
      Api.updateDocument({
        description: values.name,
        name: fileDocument?.name || (typeof values.document === 'string' ? values.document : values.document.name),
        url: fileDocument?.url || values.url,
      }, +documentId)
        .then((res) => {
          dispatch(setDocument(undefined));
          dispatch(setLocation('documents'));
          navigate(`/companies/${companyId}/edit`);
        })
        .catch((e) => {
          setSubmittingError(true);
        }).finally(() => {
          setSending(false);
        });
    } else if (!documentId && companyId && fileDocument) {
      Api.createDocument({
        company_id: +companyId, url: fileDocument.url, file_id: fileDocument.ID, name: fileDocument.name, description: values.name,
      })
        .then((res) => {
          navigate(`/companies/${companyId}/edit`);
        })
        .catch((error) => {
          setSubmittingError(true);
        }).finally(() => {
          setSending(false);
        });
    }
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <div className="languagesSection">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_add_localization')}</h3>
        </div>

        <InputField
          extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
          name="name"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_add_name_placeholder')}
            // @ts-ignore
          value={values.name}
          error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_add_name_label')}
          required
        />

        <div className="fileContainer">
          <div className="fileNameContainer">
            <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_file_caption')}</span>
            :
            {' '}
            <span>
              {values.document && values.document instanceof File
                ? values.document.name
                : (values.document ? values.document : getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_file_not_loaded'))}
            </span>
          </div>
          <div className="sizeContainer">
            <span>
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_file_max_size')}
              {' '}
              100 MB
            </span>
            <span>
              {getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_file_format')}
              {' '}
              .pdf
            </span>
          </div>
          <div>
            <UploadFileButton
              onChange={(file) => {
                setFieldValue('document', file);
                if (file) {
                  documentSizeExamination(file);
                }
              }}
              formats="application/pdf"
              name={getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_file_save_button')}
              extraButtonStyles={{ maxWidth: '272px', width: '100%', height: '40px' }}
            />
            <p className="error">{maxSizeError}</p>
          </div>
        </div>

        <div className="submitWrapper">
          <SubmitButton isLoading={sending} extraBlockStyles={{ maxWidth: 500, width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_document_button')}</SubmitButton>
          {submittingError && <p className="error">{getTranslationByLangOrEng(interfaceLanguage, 'data_save_error')}</p>}
        </div>

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <AddDocumentStyles>
      <Helmet>
        <title>{`Creating a document ${companyName} Voicer`}</title>
      </Helmet>

      <SubHeader title={documentId ? getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_add_title_editing') : getTranslationByLangOrEng(interfaceLanguage, 'companies_documents_add_title')}>
        <TransparentButton handleClick={handleCancel} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
      </SubHeader>

      {(!isLoaded || !initialValues) ? <Loader /> : (
        <Formik
          initialValues={initialValues!}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>
      )}
    </AddDocumentStyles>
  );
}
