import { ErrorMessage } from 'formik';
import { Dispatch, SetStateAction } from 'react';
import { InfoCharIcon } from '../../../assets';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';
import { FormErrorMessage } from '../../atoms';

interface InputRawProps {
  name: string,
  value: string,
  setFieldValue: (key: string, value: any) => void,
  error: string,
  touched: any,
  handleKeyUp: (key: string, setErrorHandler: Dispatch<SetStateAction<any>>, error: any) => void,
  translationKeys: [string | undefined, string, string],
  setErrorMessage: Dispatch<SetStateAction<any>>,
  errorMessage: any,
}

export default function InputRaw({
  name,
  value,
  setFieldValue,
  error,
  touched,
  handleKeyUp,
  translationKeys,
  setErrorMessage,
  errorMessage,
}: InputRawProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <div className="inputContainer">
      <div className="inputRaw">
        {translationKeys[0] && (
          <div className="nameContainer">
            <span>{getTranslationByLangOrEng(interfaceLanguage, translationKeys[0])}</span>
          </div>
        )}
        <div className="inputElementContainer">
          <input
            id={name}
            type="text"
            value={value}
            onChange={(event) => setFieldValue(name, event.target.value)}
            onKeyUp={() => handleKeyUp(name, setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, translationKeys[1])}
          />
          <div className="infoContainer">
            <div className="infoIcon">
              <InfoCharIcon />
            </div>
            <div className="infoText">
              <p>{getTranslationByLangOrEng(interfaceLanguage, translationKeys[2])}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="formErrorContainer">
        {error && <span>{error}</span>}
        {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
      </div>
    </div>
  )
}
