import styled from 'styled-components';

export const MassMailingCreatingViberStep3Styles = styled.div`
  .titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
    padding-left: 16px;
    padding-right: 32px;
    border-bottom: 1px solid #F0F0F0;
    box-sizing: border-box;

    h1 {
      font-weight: 500;
      font-size: 24px;
      line-height: 28px;
      color: #000000;
    }
  }
  
  .formContainer {
    padding: 16px 32px 32px 16px;

    .serverErrorMessage {
      color: #ff0000;
      text-align: center;
      max-width: 540px;
    }
  }

  .infoWrapper {
    padding: 8px 8px;
    // border-top: 1px solid #F0F0F0;
    font-weight: 400;
    font-size: 15px;
    line-height: 16px;
    color: #000000;
    display: flex;

    &.name {
      border-top: none;
      padding-top: 0;
    }

    span:nth-child(1) {
      margin-right: 8px;
    }

    span:nth-child(2) {
      font-weight: 600;
    }
  }

  .mailingInfoContainer {
    .infoWrapper {
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }

  .mailsCountContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 540px;
  
    span {
      font-weight: 700;
    }
  }

  .buttonsContainer {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    width: 540px;
  }

  .previewWrapper {
    width: 524px;
    margin-bottom: 24px;

    .body {
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      position: relative;

      .previewBodyContainer {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 100;
        transform: translate(-50%, -50%);
        width: 205px;
        height: 444px;
      }

      & > img {
        //box-shadow: 2px 2px 10px rgba(0,0,0,0,15);
        filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, 0.15)) drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.15));
      }
    }

    .footer {
      height: 48px;
      padding: 0 16px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      box-sizing: border-box;
    }
  }

  .previewBody {
    position: relative;
    padding-top: 20px;
    box-sizing: border-box;
    width: 205px;
    height: 444px;
    -webkit-mask-box-image: url("https://voicerspace.fra1.digitaloceanspaces.com/43553c6881dfd71310862639283051aac5d94ece8747000bad8f698e4feac0f4.svg");
    mask-border: url("https://voicerspace.fra1.digitaloceanspaces.com/43553c6881dfd71310862639283051aac5d94ece8747000bad8f698e4feac0f4.svg");
    background-color: #1D2733;
    color: #fff;
    font-family: "Roboto", sans-serif;
    font-size: 12px;

    .previewHeader {
      padding: 0 12px;
      height: 28px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #424C58;
      box-sizing: border-box;
    }

    .previewChat {
      height: 338px;
      padding: 0 8px 16px 8px;
      box-sizing: border-box;
      //overflow-y: hidden;
      overflow-y: scroll;

      -ms-overflow-style: none; /* Internet Explorer 10+ */
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none; /* Safari and Chrome */
      }

      .date {
        font-family: "Roboto", sans-serif;
        line-height: 28px;
        color: #fff;
        font-size: 9px;
        text-align: center;
      }

      .message {
        display: grid;
        grid-template-columns: 18px 150px;
        grid-gap: 4px;

        .senderLogo {
          width: 18px;
          height: 18px;
          border-radius: 6px;
          background-color: #fff;
          font-size: 14px;
          line-height: 16px;
          font-weight: bold;
          color: #0E9285;
          display: grid;
          place-items: center;
        }

        .messageContainer {
          font-size: 10px;
          line-height: 14px;
          word-wrap: break-word;
          background-color: #3F4C5C;
          border: 1px solid #3F4C5C;
          padding: 8px;
          border-radius: 0 6px 6px 6px;
          overflow: hidden;
          box-sizing: border-box;
          position: relative;

          .buttonContainer {
            &.withExtraMargin {
              margin-bottom: 8px;
            }

            button {
              width: 100%;
              height: 20px;
              font-size: 10px;
              line-height: 14px;
              color: #fff;
              background-color: #7C4C9D;
              margin-bottom: 8px;
              border-radius: 8px;
            }
          }

          .messageText {
            margin-bottom: 8px;

            a {
              color: #21a1ff;
            }
          }

          .time {
            position: absolute;
            bottom: 8px;
            right: 8px;
            font-size: 8px;
            line-height: 8px;
            text-align: right;
            color: #a0aebb;
          }

          img {
            width: 100%;
            height: auto;
            margin-bottom: 8px;
          }

          &.image {
            padding: 0;

            .buttonContainer {
              margin-bottom: 0;

              &.withExtraMargin {
                margin-bottom: 0;
              }
            }

            img {
              margin-bottom: 0;
            }

            .time {
              color: #fff;
            }
          }
        }
      }
    }

    .previewFooter {
      position: fixed;
      right: 0;
      bottom: 0;
      left: 0;
      padding: 8px 8px 12px 8px;
      border-top: 1px solid #424C58;

      p {
        margin-bottom: 6px;
        font-family: "Roboto", sans-serif;
        font-size: 10px;
        line-height: 14px;
        color: #dcdcdc;
      }

      .icons {
        img {
          height: 14px;
          margin-right: 8px;
        }
      }

      & > img {
        width: 22px;
        height: 22px;
        position: fixed;
        right: 8px;
        bottom: 20px;
      }
    }
  }

`;
