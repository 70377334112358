import { AddPermissionStyles } from './AddPermissionStyles';
import { getTranslationByLangOrEng } from '../../i18n';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { formInitialValues, FormValues, validationSchema } from './formValuesAndValidation';
import { Api } from '../../api';
import InputField from '../../components/form/inputField/InputField';
import SquareCheckbox from '../../components/form/squareCheckbox/SquareCheckbox';
import SubmitButton from '../../components/form/submitButton/SubmitButton';
import { IUserPermission } from '../../entities';
import { useAppSelector } from '../../state';
import { Helmet } from 'react-helmet-async';
import { Loader, SubHeader, TransparentButton } from '../../components';
import { getErrorMessage, handleKeyUp } from '../../utils';

export const AddPermission = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();

  useEffect(() => {
    setIsLoading(true);
    try {
      if (id) {
        Api.getPermission(+id).then((res) => {
          if (res.statusCode >= 200 && res.statusCode < 300) {
            setInitialValues({
              name: res.data.name,
              resource: res.data.resource,
              permissions: {
                create: res.data.permissions.includes(0),
                read: res.data.permissions.includes(1),
                update: res.data.permissions.includes(2),
                delete: res.data.permissions.includes(3),
              },
            });
          }
        });
      } else {
        setInitialValues(formInitialValues);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);

    let res;
    const data: IUserPermission = {
      name: values.name,
      resource: values.resource,
      permissions: [
        ...(values.permissions.create ? [0] as (0 | 1 | 2 | 3)[] : []),
        ...(values.permissions.read ? [1] as (0 | 1 | 2 | 3)[] : []),
        ...(values.permissions.update ? [2] as (0 | 1 | 2 | 3)[] : []),
        ...(values.permissions.delete ? [3] as (0 | 1 | 2 | 3)[] : []),
      ],
    };

    try {
      if (id) {
        res = await Api.updatePermission(+id, data);
      } else {
        res = await Api.createPermission(data);
      }

      if (res.statusCode >= 200 && res.statusCode < 300) {
        navigate('/users/permissions');
      }
    } catch (e) {
      console.log('ON SUBMIT ERROR', e);
    }
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <InputField
          extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
          name="name"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('name', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'permission_name_placeholder')}
          value={values.name}
          error={typeof errorMessage === 'object' ? getErrorMessage('name', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'permission_name_title')}
          required
        />

        <InputField
          extraBlockStyles={{ width: '100%', maxWidth: '524px' }}
          name="resource"
          onChange={setFieldValue}
          onKeyUp={() => handleKeyUp('resource', setErrorMessage, errorMessage)}
          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'permission_resource_placeholder')}
          value={values.resource}
          error={typeof errorMessage === 'object' ? getErrorMessage('resource', errorMessage) : undefined}
          label={getTranslationByLangOrEng(interfaceLanguage, 'permission_resource_title')}
          required
        />

        <div className="permissionsContainer">
          <span className="sectionTitle">
            Permissions
            <span className="required">*</span>
          </span>

          <div className="permissions">
            <SquareCheckbox
              name="permissions.create"
              value={values.permissions.create}
              onChange={handleChange}
              error={typeof errorMessage === 'object' ? getErrorMessage('permissions.create', errorMessage) : undefined}
            >
              <span>Create</span>
            </SquareCheckbox>

            <SquareCheckbox
              name="permissions.read"
              value={values.permissions.read}
              onChange={handleChange}
              error={typeof errorMessage === 'object' ? getErrorMessage('permissions.read', errorMessage) : undefined}
            >
              <span>Read</span>
            </SquareCheckbox>

            <SquareCheckbox
              name="permissions.update"
              value={values.permissions.update}
              onChange={handleChange}
              error={typeof errorMessage === 'object' ? getErrorMessage('permissions.update', errorMessage) : undefined}
            >
              <span>Update</span>
            </SquareCheckbox>

            <SquareCheckbox
              name="permissions.delete"
              value={values.permissions.delete}
              onChange={handleChange}
              error={typeof errorMessage === 'object' ? getErrorMessage('permissions.delete', errorMessage) : undefined}
            >
              <span>Delete</span>
            </SquareCheckbox>
          </div>
        </div>

        <SubmitButton extraBlockStyles={{ maxWidth: 250, width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
      </div>
    </Form>
  );

  return (
    <AddPermissionStyles>
      <Helmet>
        <title>{id ? 'Editing access rights Voicer' : 'Creating access rights Voicer'}</title>
      </Helmet>

      <SubHeader title={id ? getTranslationByLangOrEng(interfaceLanguage, 'permission_edit_title') : getTranslationByLangOrEng(interfaceLanguage, 'permission_create_title')}>
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/users/permissions')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      {(isLoading || !initialValues) ? <Loader /> : (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}
    </AddPermissionStyles>
  );
}
