import React, {
  ReactElement, useEffect, useRef, useState,
} from 'react';
import { CompanyStructureStyles } from './CompanyStructureStyles';
import { useNavigate, useParams } from 'react-router-dom';
import { Api } from '../../../api';
import { getNodeNamesArray, getNodesDeepDictionary, getNodesDeepEntriesArray } from '../../../utils';
import {
  useAppDispatch, useAppSelector, setNodeNames, setSelectedCompany,
} from '../../../state';
import { INode } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';
import { SearchIcon } from '../../../assets';
import { DebounceInput } from 'react-debounce-input';
import { Helmet } from 'react-helmet-async';
import {
  countBorderNumber,
  EditButtons, extraFieldsSettings,
  filterSubNodes, getNodesWithChildsBySearchParams,
  Node, tabsStructure,
} from './CompanyStructureUtils';
import {
  Loader, Table, CompanyUsers, SubHeader, EditNodeModal, TransparentButton,
} from '../../../components';

export default function CompanyStructure() {
  const navigate = useNavigate();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [headers, setHeaders] = useState<{ Header: string; accessor: string; Cell?:(data: any) => ReactElement<any, any> | null; }[] | null>(null);
  const [searchParams, setSearchParams] = useState<string>('');
  const [companyName, setCompanyName] = useState<string>();
  const [companyNodes, setCompanyNodes] = useState<Partial<INode>[]>();
  const [nodesDeep, setNodesDeep] = useState<{[key: number]: number}>({});
  const [nodesExpanded, setNodesExpanded] = useState<{[key: number]: boolean}>({});
  const [numberOfVerticalBorders, setNumberOfVerticalBorders] = useState<{[key: number]: number[]}>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedNode, setSelectedNode] = useState<string>('');
  const [selectedTab, setSelectedTab] = useState(tabsStructure(interfaceLanguage)[0].key);
  const [editModal, setEditModal] = useState<boolean>(false);

  const { selectedCompany: company } = useAppSelector((state) => state.company);

  const companyNodesRef = useRef<Partial<INode>[]>();
  const nodesExpandedRef = useRef<{[key: number]: boolean}>();
  const nodesDeletedRef = useRef<{[key: number]: boolean}>({});
  const numberOfVerticalBordersArrayRef = useRef<[string, number[]][]>([]);
  const numberOfVerticalBordersRef = useRef<{[key: number]: number[]}>();

  const [filteredNodesDeep, setFilteredNodesDeep] = useState<{[key: number]: number}>({});
  const [filteredNumberOfVerticalBorders, setFilteredNumberOfVerticalBorders] = useState<{[key: number]: number[]}>({});
  const [isFiltered, setIsFiltered] = useState<boolean>(false);

  const companyNodesRefFiltered = useRef<Partial<INode>[]>();
  const filteredNodesExpandedRef = useRef<{[key: number]: boolean}>();
  const filteredNodesDeletedRef = useRef<{[key: number]: boolean}>({});
  const filteredNumberOfVerticalBordersArrayRef = useRef<[string, number[]][]>([]);
  const filteredNumberOfVerticalBordersRef = useRef<{[key: number]: number[]}>();

  const handleExpandRow = React.useCallback((id: number) => {
    if (nodesExpandedRef.current) {
      setNodesExpanded({ ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] });
      nodesExpandedRef.current = { ...nodesExpandedRef.current, [id]: !nodesExpandedRef.current[id] };
    }
  }, [id]);

  const handleDeleteRow = React.useCallback((id: number) => {
    if (nodesDeletedRef.current) {
      nodesDeletedRef.current = { ...nodesDeletedRef.current, [id]: true };

      if (companyNodesRef.current && companyNodes) {
        companyNodesRef.current = [...companyNodesRef.current.filter((item) => item.id !== id)];
        setCompanyNodes([...companyNodes.filter((item) => item.id !== id)]);
      }
    }
  }, [id]);

  // eslint-disable-next-line react/no-unstable-nested-components
  const SubRow: React.FC<{handleDelete: (id: number) => void, handleExpandRow: (id: number) => void, subRow: any, companyName: string, companyId: number}> = ({
    handleDelete, handleExpandRow, subRow, companyName, companyId,
  }) => {
    if (nodesDeletedRef.current?.[subRow.id]) {
      return null;
    }

    return (
      <>
        <tr>
          <td
            style={{ borderRight: 'none' }}
            onClick={() => handleExpandRow(subRow.id)}
          >
            <div className="fieldContent">
              <Node
                node={subRow}
                deep={isFiltered ? filteredNodesDeep[subRow.id] : nodesDeep[subRow.id]}
                expanded={isFiltered ? filteredNodesExpandedRef.current?.[subRow.id] : nodesExpandedRef.current?.[subRow.id]}
                deletedRows={nodesDeletedRef.current!}
                numberOfVerticalBorders={isFiltered ? filteredNumberOfVerticalBordersRef.current![subRow.id] : numberOfVerticalBordersRef.current ? numberOfVerticalBordersRef.current[subRow.id] : []}
              />
            </div>
          </td>
          <td style={{ borderRight: 'none' }}>
            <div
              className="fieldContent"
              style={{
                flexDirection: 'column',
                alignItems: 'flex-end',
              }}
            >
              <EditButtons
                setEditModal={setEditModal}
                haveChildren={filterSubNodes(nodesDeletedRef.current, subRow.subNodes).length > 0}
                nodeName={subRow.name}
                setSelectedNode={setSelectedNode}
                nodeId={subRow.id}
                companyName={companyName}
                companyId={companyId}
                handleDelete={handleDelete}
              />
            </div>
          </td>
        </tr>
        {Object.keys(nodesDeep).length > 0 && !nodesDeletedRef.current?.[subRow.id] && nodesExpandedRef.current?.[subRow.id] && subRow.subNodes?.map((subNode: any) => (
          <SubRow
            handleDelete={handleDelete}
            handleExpandRow={handleExpandRow}
            key={subNode.id}
            subRow={subNode}
            companyName={companyName}
            companyId={companyId}
          />
        ))}
      </>
    );
  };

  function getNodesBySearchParams() {
    if (companyNodes) {
      const filteredNodes: Partial<INode>[] = getNodesWithChildsBySearchParams(companyNodes!, searchParams);

      const filteredNodesDeepDictionary: {[key: number]: number} = {};
      const filteredNodeNamesArray: {id: number, name: string, deep: number}[] = [];
      const filteredNodesExpandedDictionary: {[key: number]: boolean} = {};
      const filteredNodesDeletedDictionary: {[key: number]: boolean} = {};

      getNodesDeepDictionary(filteredNodesDeepDictionary, filteredNodes || []);
      getNodeNamesArray(filteredNodeNamesArray, filteredNodes || []);

      Object.keys(filteredNodesDeepDictionary).forEach((item) => {
        filteredNodesExpandedDictionary[+item] = false;
        filteredNodesDeletedDictionary[+item] = false;
      });

      companyNodesRefFiltered.current = filteredNodes || [];
      setFilteredNodesDeep({ ...filteredNodesDeepDictionary });
      filteredNodesExpandedRef.current = { ...filteredNodesExpandedDictionary };
      filteredNodesDeletedRef.current = { ...filteredNodesDeletedDictionary };

      const nodesDeepArray: [number | string, number][] = [];

      getNodesDeepEntriesArray(nodesDeepArray, filteredNodes || []);

      const reversedNodesDeepArray = nodesDeepArray.reverse();

      reversedNodesDeepArray
        .forEach((item, index) => {
          countBorderNumber(reversedNodesDeepArray, filteredNumberOfVerticalBordersArrayRef.current, item, index);
        });

      filteredNumberOfVerticalBordersRef.current = Object.fromEntries(filteredNumberOfVerticalBordersArrayRef.current);
      setFilteredNumberOfVerticalBorders(Object.fromEntries(filteredNumberOfVerticalBordersArrayRef.current));
    }
  }

  useEffect(() => {
    getNodesBySearchParams();

    if (searchParams.length > 0) {
      setIsFiltered(true);
    }

    if (searchParams.length === 0) {
      setIsFiltered(false);
    }
  }, [searchParams]);

  async function getData() {
    if (id) {
      if (company) {
        setCompanyName(company.name);
      } else {
        const res = await Api.getCompanyById(+id);

        if (res.statusCode >= 200 && res.statusCode < 300) {
          setCompanyName(res.data.name);
          dispatch(setSelectedCompany(res.data));
        }
      }

      const companyData = await Api.getCompanyNodes(+id);

      if (companyData.statusCode === 200) {
        const nodesDeepDictionary: {[key: number]: number} = {};
        const nodeNamesArray: {id: number, name: string, deep: number}[] = [];
        const nodesExpandedDictionary: {[key: number]: boolean} = {};
        const nodesDeletedDictionary: {[key: number]: boolean} = {};

        getNodesDeepDictionary(nodesDeepDictionary, companyData.data || []);
        getNodeNamesArray(nodeNamesArray, companyData.data || []);

        dispatch(setNodeNames(nodeNamesArray));

        Object.keys(nodesDeepDictionary).forEach((item) => {
          nodesExpandedDictionary[+item] = false;
          nodesDeletedDictionary[+item] = false;
        });

        setCompanyNodes(companyData.data || []);
        companyNodesRef.current = companyData.data || [];
        setNodesDeep({ ...nodesDeepDictionary });
        setNodesExpanded({ ...nodesExpandedDictionary });
        nodesExpandedRef.current = { ...nodesExpandedDictionary };
        nodesDeletedRef.current = { ...nodesDeletedDictionary };

        const nodesDeepArray: [number | string, number][] = [];

        getNodesDeepEntriesArray(nodesDeepArray, companyData.data || []);

        const reversedNodesDeepArray = nodesDeepArray.reverse();

        reversedNodesDeepArray
          .forEach((item, index) => {
            countBorderNumber(reversedNodesDeepArray, numberOfVerticalBordersArrayRef.current, item, index);
          });

        numberOfVerticalBordersRef.current = Object.fromEntries(numberOfVerticalBordersArrayRef.current);
        setNumberOfVerticalBorders(Object.fromEntries(numberOfVerticalBordersArrayRef.current));
      }
    }
  }

  useEffect(() => {
    setLoading(true);
    getData().finally(() => setLoading(false));
  }, []);

  const CompanyNodesCell = React.useCallback((data: any) => {
    if (!nodesDeletedRef.current?.[data.row.original.id] && data.row.original) {
      return (
        <Node
          key={`node-${data.row.original.id}`}
          deletedRows={isFiltered ? filteredNodesDeletedRef.current! : nodesDeletedRef.current!}
          numberOfVerticalBorders={isFiltered && filteredNumberOfVerticalBordersRef.current ? filteredNumberOfVerticalBordersRef.current[data.row.original.id] : numberOfVerticalBordersRef.current ? numberOfVerticalBordersRef.current[data.row.original.id] : []}
          expanded={isFiltered ? filteredNodesExpandedRef.current?.[data.row.original.id] : nodesExpandedRef.current?.[data.row.original.id]}
          node={data.row.original}
          deep={isFiltered ? filteredNodesDeep[data.row.original.id] : nodesDeep[data.row.original.id]}
          {...data.row.getToggleRowExpandedProps()}
        />
      );
    }
    return null;
  }, [id, searchParams]);

  const EditButtonsCell = React.useCallback((data: any) => {
    if (!nodesDeletedRef.current?.[data.row.original.id] && data.row.original) {
      return (
        <EditButtons
          setEditModal={setEditModal}
          haveChildren={filterSubNodes(nodesDeletedRef.current, data.row.original.subNodes).length > 0}
          nodeName={data.row.original.name}
          setSelectedNode={setSelectedNode}
          nodeId={data.row.original.id}
          companyId={+id!}
          companyName={companyName!}
          handleDelete={handleDeleteRow}
        />
      );
    }
    return null;
  }, [id, companyName]);

  useEffect(() => {
    if (!!id && !!companyName) {
      setHeaders([
        {
          Header: getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_header_name'),
          accessor: 'companyNodes',
          Cell: CompanyNodesCell,
        },
        {
          Header: 'Edit buttons',
          accessor: 'editButtons',
          Cell: EditButtonsCell,
        },
      ]);
    }
  }, [id, companyName, interfaceLanguage]);

  const SubNode = React.useCallback(({ subRow }) => {
    if (id !== undefined && !nodesDeletedRef.current?.[subRow.id] && companyName && Object.keys(nodesDeep).length > 0 && numberOfVerticalBorders) {
      return (
        <SubRow
          handleDelete={handleDeleteRow}
          handleExpandRow={handleExpandRow}
          subRow={subRow}
          companyId={+id}
          companyName={companyName}
        />
      );
    }

    return null;
  }, [id, companyName, nodesDeep, numberOfVerticalBorders, filteredNumberOfVerticalBorders, filteredNodesDeep]);

  return (
    <CompanyStructureStyles>
      <Helmet>
        <title>{`${company?.name || 'Company'} structure`}</title>
      </Helmet>

      <SubHeader
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_title')}
        pathArray={[
          ...(company ? [{ name: company.name! }] : []),
        ]}
      >
        <div className="buttonsContainer">
          <TransparentButton handleClick={() => navigate('/companies')} text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')} />
        </div>
      </SubHeader>

      <div className="tabsContainer">
        {tabsStructure(interfaceLanguage).map((tab) => (
          <button
            className={selectedTab === tab.key ? 'active' : ''}
            key={tab.key}
            type="button"
            onClick={() => setSelectedTab(tab.key)}
          >
            {tab.text}
          </button>
        ))}
      </div>

      {selectedTab === 'structure' && (
        <>
          <div className="searchWrapper">
            <SearchIcon />

            <DebounceInput
              minLength={2}
              debounceTimeout={1000}
              onChange={(event) => setSearchParams(event.target.value)}
              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'search_for_name')}
            />
            <TransparentButton handleClick={() => navigate(`/companies/${id}/nodes/add`)} text={getTranslationByLangOrEng(interfaceLanguage, 'companies_structure_table_add_node_button')} filled />
          </div>
          {loading && <Loader />}
          {headers && companyNodesRef.current && (
            <Table
              columns={headers}
              data={isFiltered ? companyNodesRefFiltered.current : companyNodesRef.current}
              hideFieldsSplitters
              extraFieldsSettings={extraFieldsSettings}
              headerColumnCounts={{ companyNodes: isFiltered ? companyNodesRefFiltered.current!.length : companyNodesRef.current.length }}
              sortableColumns={['companyNodes']}
              hiddenHeaders={['customField', 'editButtons']}
              fullWidthColumns={['companyNodes']}
              subRowKey="subNodes"
              // eslint-disable-next-line react/no-unstable-nested-components
              subRowsComponent={(item) => <SubNode key={`subNode-${item.id}`} subRow={item} />}
              expands
              expandKey="companyNodes"
              expandedRows={nodesExpanded}
              deletedRows={nodesDeletedRef.current}
              handleExpandRow={handleExpandRow}
              handleDeleteRow={handleDeleteRow}
            />
          )}
        </>
      )}
      {selectedTab === 'company-users' && <CompanyUsers />}
      {selectedNode && editModal && <EditNodeModal onClose={() => setEditModal(false)} companyId={+id!} nodeId={selectedNode} />}
    </CompanyStructureStyles>
  );
}
