import styled from 'styled-components';

export const IssuesTableFilterStyles = styled.div<{mobileVersion: boolean}>`
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  background: #FFF;
  position: absolute;
  top: 42px;
  left: ${({ mobileVersion }) => (mobileVersion ? '50%' : 'none')};
  /* right: ${({ mobileVersion }) => (mobileVersion ? 'none' : '50%')}; */
  transform: translateX(-50%);
  z-index: 200;
  width: ${({ mobileVersion }) => (mobileVersion ? '240px' : '300px')};
  padding: ${({ mobileVersion }) => (mobileVersion ? '8px' : '16px')};
  border-radius: 4px;

  .options {
    max-height: ${({ mobileVersion }) => (mobileVersion ? '200px' : '280px')};
  }
`;
