import styled from 'styled-components';

export const CompanyDesignFormStyles = styled.div`
  padding: 26px 32px 26px 16px;

  .designWrapper {
    max-width: 714px;
    width: 100%;
    border: 1px solid #F0F0F0;
    margin-bottom: 25px;
    
    .headWrapper {
      padding: 9px 11px 9px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #F0F5F5;
      
      span {
        font-weight: 500;
        font-size: 15px;
        color: #000000;
      }
      
      .buttonsWrapper {
        width: 274px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        
        .addButtonWrapper {
          width: 232px;

          button {
            width: 232px;
            height: 32px;
            color: #FFFFFF;
            font-weight: 500;
            font-size: 14px;
            background-color: #0E9285;
            border: 1px solid #0E9285;
            border-radius: 4px;
            transition: 0.3s;
            
            span {
              color: #FFF;
            }

            :hover {
              color: #0E9285;
              background-color: #FFFFFF;
              
              span {
                color: #0E9285;
              }
            }
          }
        }
        .addButton {
          
        }
      }
    }
  }
  
  form {
    margin-bottom: 8px;
    
    .arrayWrapper {      
      .logosWrapper, .colorsWrapper, .fontsWrapper {
        height: 56px;
        padding: 0 16px;
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 281px;
        grid-gap: 24px;
        border-bottom: 1px solid #f0f0f0;
        
        &.edit {
          height: 88px;
          align-items: flex-end;
        }
        
        &:last-child {
          border-bottom: none;
        }
        
        .inputWrapper {
          font-size: 14px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .fileWrapperEditMargin {
          margin-bottom: 24px;
        }
        
        .fileWrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          
          .colorSquare {
            width: 40px;
            height: 40px;
          }
          
          .logoImage {
            width: 40px;
            height: 40px;
            object-fit: contain;
          }
        }
      }
    }
    
    .errorWrapper {
      text-align: right;
      padding-right: 12px;
    }
    
    .uploadError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
    }
  }
`;
