import React from 'react';
import { Handle, Position } from 'reactflow';
import { CustomDiagramNodeStyles } from './CustomDiagramNodeStyles';
import FormConstructorItem from '../formConstructorItem/FormConstructorItem';
import { useAppSelector } from '../../../../state';

interface CustomDiagramNodeProps {
  id: string,
  type: string,
  data: {type: string, question: string}
}

export default function CustomDiagramNode({ id, type, data }: CustomDiagramNodeProps) {
  const { notSavedExtraFields, notSavedDocuments } = useAppSelector((state) => state.notSavedForm);

  return (
    <CustomDiagramNodeStyles inSeries={[...notSavedExtraFields, ...notSavedDocuments].find((item) => item.id === +id)?.fieldInSeries}>
      <FormConstructorItem
        type={data.type}
        previewText={data.question}
      />

      {data.type !== 'title' && <Handle type="target" position={Position.Top} className="handler" />}
      <Handle type="source" position={Position.Bottom} className="handler" />
    </CustomDiagramNodeStyles>
  );
}
