import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const UsersIcon: FC<TIcon> = React.memo(({ color = '#000' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
  >
    <path fill={color} d="M224 256c70.7 0 128-57.31 128-128S294.7 0 224 0 96 57.31 96 128s57.3 128 128 128zm50.7 48H173.3C77.57 304 0 381.6 0 477.3 0 496.5 15.52 512 34.66 512H413.3c19.2 0 34.7-15.5 34.7-34.7 0-95.7-77.6-173.3-173.3-173.3zm204.4 16h-73.85C451.2 357.7 480 414.1 480 477.3c0 12.8-3.8 24.6-10 34.7h138c17.7 0 32-14.4 32-32.9 0-87.5-71.6-159.1-160.9-159.1zM432 256c61.9 0 112-50.1 112-112S493.9 32 432 32c-25.11 0-48.04 8.555-66.72 22.51C376.8 76.63 384 101.4 384 128c0 35.52-11.93 68.14-31.59 94.71C372.7 243.2 400.8 256 432 256z" />
  </svg>
))
