export const extraFieldsSettings = {
  editButtons: {
    justifyContent: 'flex-end',
  },
  create: {
    justifyContent: 'center',
  },
  read: {
    justifyContent: 'center',
  },
  update: {
    justifyContent: 'center',
  },
  delete: {
    justifyContent: 'center',
  },
};
