import React, { CSSProperties } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { ErrorMessage, useFormikContext } from 'formik';
import { TextEditorStyles } from './TextEditorStyles';
import { FormErrorMessage } from '../../atoms';

interface TextEditorProps {
  name: string;
  value: string | undefined;
  placeholder?: string;
  onChange: (key: string, value: string) => void;
  error?: string | undefined;
  showError?: boolean;
  extraBlockStyles?: CSSProperties;
  label?: string;
  onKeyUp?: () => void;
  required?: boolean;
  withMenuBar?: boolean;
  toolbarItems?: string[];
  height?: number;
  outputEditorFormat?: 'html' | 'text';
}

export const TextEditor = ({
  name, placeholder,
  label, error,
  showError = true,
  value, onChange,
  onKeyUp, extraBlockStyles,
  required, withMenuBar,
  toolbarItems, height,
  outputEditorFormat,
}: TextEditorProps) => {
  const { touched } = useFormikContext();

  return (
    <TextEditorStyles>
      <div style={extraBlockStyles}>
        <label htmlFor={name}>
          {label
          && (
            <span>
              {label}
              {required && <span className="required"> *</span>}
            </span>
          )}
          <div className="inputWrap">
            <div className="inputContainer">
              <Editor
                apiKey="qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc"
                value={value}
                textareaName={name}
                init={{
                  language: 'en',
                  placeholder,
                  height: height || 400,
                  menubar: !!withMenuBar,
                  statusbar: false,
                  plugins: [
                    'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor',
                    'searchreplace', 'visualblocks', 'code', 'fullscreen',
                    'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount', 'emoticons',
                  ],
                  textcolor_rows: '2',
                  toolbar:
                  toolbarItems || ['bold italic underline strikethrough forecolor backcolor emoticons | fontsize | styles numlist bullist alignleft aligncenter alignright | image link media code'],
                  font_size_formats: '8px 10px 12px 14px 16px 18px 24px 36px 48px',
                }}
                onEditorChange={(stringifiedHtmlValue, editor) => {
                  onChange(name, editor.getContent({ format: outputEditorFormat === 'text' ? 'text' : 'html' }));
                }}
              // @ts-ignore
                outputFormat="html"
              />
              {showError && (
              <div className="formErrorContainer">
                {error && <span>{error}</span>}
                {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
              </div>
              )}
            </div>
          </div>
        </label>
      </div>
    </TextEditorStyles>
  );
}
