import React, { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { getTranslationByLangOrEng } from '../../../../../../i18n';
import {
  useAppDispatch,
  useAppSelector,
  MassMailingsViberRedux,
  setMassMailingMainInfoCompany,
  setMassMailingsViber,
} from '../../../../../../state';
import { MassMailingCreatingViberStep3Styles } from './MassMailingCreatingViberStep3Styles';
import { ThreeDots } from '../../../../../../assets';
import { format } from 'date-fns';
import ViberImage from '../images/viber_image.png';
import ViberPhoto from '../images/viber_photo.png';
import ViberSend from '../images/viber_send.png';
import Union from '../images/Union.svg';
import SubmitButton from '../../../../../../components/form/submitButton/SubmitButton';
import { Helmet } from 'react-helmet-async';
import { Loader, ModalConfirmDecline, TransparentButton } from '../../../../../../components';

interface InitialValues extends MassMailingsViberRedux {
  dateAndTime: string;
}

async function sendMassMailingsViberRequest(data?: any): Promise<any> {
  const accessToken = localStorage.getItem('access_token');
  const myHeaders: { [key: string]: string } = {};
  myHeaders['Content-Type'] = 'application/json';

  if (accessToken) {
    myHeaders.Authorization = `Bearer ${accessToken}`;
  }

  // eslint-disable-next-line no-undef
  const requestOptions: RequestInit = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify(data),
    redirect: 'follow',
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'same-origin', // include, *same-origin, omit
    referrerPolicy: 'no-referrer', // no-referrer, *client
  };

  try {
    const response = await fetch('https://api.voicer.software/api/v1/viber/bulk', { ...requestOptions });
    const statusCode = response.status;

    return {
      statusCode,
    };
  } catch (err) {
    console.log('error', err);
  }
}

export default function MassMailingCreatingViberStep3() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { massMailingMainInfo, massMailingsViber } = useAppSelector((state) => state.massMailing);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [initialValues, setInitialValues] = useState<InitialValues>();
  const [confirmModalOpen, setConfirmModalOpen] = useState<boolean>(false);
  const [successSendingModalOpen, setSuccessSendingModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');

  const viberMassMailingsInfoRef = useRef<any>(null);

  function resetMassMailingsReduxData() {
    setSuccessSendingModalOpen(false)
    if (massMailingsViber) {
      dispatch(setMassMailingsViber(null))
    }
    if (massMailingMainInfo) {
      dispatch(setMassMailingMainInfoCompany(null))
    }
    navigate('/massMailings/create');
  }

  const onSendMassMailingsViberModalConfirm = async (values: { data: any, phones: string[]}) => {
    setIsLoading(true);
    setConfirmModalOpen(false);

    await sendMassMailingsViberRequest({ phones: values.phones, data: values.data })
      .then((res) => {
        if (res.statusCode === 200) {
          setSuccessSendingModalOpen(true);
        } else {
          setDataSaveError(`* ${getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_error_text')}: ${res.statusCode}`);
        }
      })
      .catch((e) => {
        setDataSaveError(getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_error_text'));
      })
      .finally(() => {
        setIsLoading(false);
      });
  }

  function onSubmit() {
    setConfirmModalOpen(true);
  }

  function getInitialData() {
    if (massMailingsViber) {
      const initialValuesFromRedux: InitialValues = {
        dateAndTime: `${massMailingMainInfo?.date} ${massMailingMainInfo?.time}`,
        senderAccount: massMailingsViber.senderAccount!,
        text: massMailingsViber.text || '',
        image: massMailingsViber.image,
        messageType: massMailingsViber.messageType,
        buttonUrl: massMailingsViber.buttonUrl,
        buttonText: massMailingsViber.buttonText,
        actionButtonActive: massMailingsViber.actionButtonActive,
      };
      setInitialValues(initialValuesFromRedux);
      if (massMailingMainInfo) {
        const revipientsViberContacts = massMailingMainInfo?.recipients?.map((item) => item.recipient)?.map((item) => item.replace('+', ''));
        viberMassMailingsInfoRef.current = { data: massMailingsViber.text, phones: revipientsViberContacts }
      }
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return (
    <MassMailingCreatingViberStep3Styles>
      <Helmet>
        <title>{getTranslationByLangOrEng(interfaceLanguage, 'create_mass_mailings_page_title')}</title>
      </Helmet>
      {!initialValues ? <Loader /> : (
        <>
          <div className="titleWrapper">
            <h1>{getTranslationByLangOrEng(interfaceLanguage, 'create_viber_mass_mailings_third_step_subheader_title')}</h1>
            <TransparentButton
              handleClick={() => {
                navigate('/massMailings/create/step-2');
              }}
              text={getTranslationByLangOrEng(interfaceLanguage, 'go_back')}
              disabled={isLoading}
            />
          </div>
          <div className="formContainer">
            {massMailingMainInfo && massMailingMainInfo.name && (
              <div className="infoWrapper name">
                <span>Name:</span>
                <span>{massMailingMainInfo.name}</span>
              </div>
            )}
            {initialValues && (
              <div className="mailingInfoContainer">
                <div className="infoWrapper">
                  <span>{`${getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_sender_account')}:`}</span>
                  <span>{initialValues.senderAccount}</span>
                </div>

                {/* {initialValues.dateAndTime && (
                <div className="infoWrapper">
                  <span>Date and time:</span>
                  <span>{initialValues.dateAndTime}</span>
                </div>
                )} */}
              </div>
            )}

            <div className="previewWrapper">
              <div className="body">
                <div className="previewBodyContainer">
                  <div className="previewBody">
                    <div className="previewHeader">
                      <span>Voicer</span>
                      <ThreeDots width={3} height={12} color="#7C4C9D" />
                    </div>

                    <div className="previewChat">
                      <p className="date">{format(new Date(), 'MM/dd/yyyy')}</p>
                      <div className="message">
                        <div className="senderLogo">V</div>
                        <div className={`messageContainer${!(initialValues.actionButtonActive
                          && initialValues.buttonText
                          && initialValues.buttonUrl)
                        && initialValues.messageType === 'image'
                        && initialValues.image?.value
                          ? ' image'
                          : ''}`}
                        >
                          {initialValues.messageType === 'text' && initialValues.text && <div className="messageText" dangerouslySetInnerHTML={{ __html: initialValues.text }} />}
                          {initialValues.messageType === 'image' && initialValues.image?.value && (
                            <img
                              src={typeof initialValues.image.value === 'string'
                                ? initialValues.image.value
                                : URL.createObjectURL(initialValues.image.value)}
                              alt=""
                            />
                          )}
                          <div className={`buttonContainer${!(initialValues.messageType === 'image'
                            && !(initialValues.actionButtonActive
                              && initialValues.buttonText
                              && initialValues.buttonUrl)) ? ' withExtraMargin' : ''}`}
                          >
                            {initialValues.actionButtonActive && initialValues.buttonText && initialValues.buttonUrl
                              && (
                                <button type="button" className="button" onClick={() => window.open(initialValues.buttonUrl, '_blank')}>
                                  {initialValues.buttonText}
                                </button>
                              )}
                          </div>
                          <p className="time">{format(new Date(), 'hh:mm a')}</p>
                        </div>
                      </div>
                    </div>

                    <div className="previewFooter">
                      <p>Type a message...</p>
                      <div className="icons">
                        <img src={ViberImage} alt="" />
                        <img src={ViberPhoto} alt="" />
                      </div>

                      <img src={ViberSend} alt="" />
                    </div>
                  </div>
                </div>

                <img src={Union} alt="" />
              </div>
            </div>
            {massMailingMainInfo && massMailingMainInfo.recipients && (
            <div className="mailsCountContainer">
              <span>{`${getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_mails_count')}: ${massMailingMainInfo.recipients.length || 0}`}</span>
            </div>
            )}

            <div className="buttonsContainer">
              <SubmitButton
                extraBlockStyles={{ width: '100%', maxWidth: '500px' }}
                disabled={isLoading}
                isLoading={isLoading}
                onClick={onSubmit}
              >
                {getTranslationByLangOrEng(interfaceLanguage, 'mass_mailings_send_button')}
              </SubmitButton>
            </div>
            {dataSaveError && (<p className="serverErrorMessage">{dataSaveError}</p>)}
          </div>

          {confirmModalOpen && (
          <ModalConfirmDecline
            onClose={() => setConfirmModalOpen(false)}
            onConfirm={() => onSendMassMailingsViberModalConfirm(viberMassMailingsInfoRef.current)}
            onDecline={() => setConfirmModalOpen(false)}
            confirmText={getTranslationByLangOrEng(interfaceLanguage, 'yes_button_text')}
            declineText={getTranslationByLangOrEng(interfaceLanguage, 'no_button_text')}
            title={getTranslationByLangOrEng(interfaceLanguage, 'confirm_send_mass_mailings_modal_title')}
            firstButtonStyles={{ background: '#ff0000' }}
            secondButtonStyles={{ background: '#0E9285' }}
          />
          )}

          {successSendingModalOpen && (
          <ModalConfirmDecline
            onDecline={resetMassMailingsReduxData}
            onClose={() => setSuccessSendingModalOpen(false)}
            title={getTranslationByLangOrEng(interfaceLanguage, 'modal_mass_mailings_sent_success_title')}
            firstButtonStyles={{ backgroundColor: '#0E9285', border: '1px solid #0E9285' }}
            declineText={getTranslationByLangOrEng(interfaceLanguage, 'ok_button_text')}
            isConfirmButtonShown={false}
          />
          )}
        </>
      )}
    </MassMailingCreatingViberStep3Styles>
  );
}
