import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const MenuIcon: FC<TIcon> = React.memo(({ width = 24, height = 24, color = '#000' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 24 24"
  >
    <path fill={color} d="M4 6h16v2H4V6zm0 5h16v2H4v-2zm0 5h16v2H4v-2z" />
  </svg>
));
