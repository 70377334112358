import React, { useState, useRef, useEffect } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { SourceFilterStyles } from './SourceFilterStyles';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { ISource } from '../../../../entities/ISource';
import { Api } from '../../../../api';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';
import { Loader, TransparentButton } from '../../../atoms';

type SourceExtendedType = ISource & {
    value: boolean;
}

interface SourceFilterValues {
    sources: SourceExtendedType[] | undefined;
}

interface ISourceFilter {
  filterParams: {[key: string]: number | string | any },
  setReQuery: () => void,
  setApply: (params: {[key: string] : number | string }) => void,
  setFilterParams: (params: {[key: string] : number | string }) => void,
  onClose: () => void,
}

export const SourceFilter = React.memo(({
  filterParams, setReQuery, setApply, setFilterParams, onClose,
}: ISourceFilter) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<SourceFilterValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});

  const ref = useRef<any>();
  const formRef = useRef<FormikProps<SourceFilterValues>>(null);
  const sourcesRef = useRef<SourceExtendedType[]>([]);

  async function onSubmit(
    values: SourceFilterValues,
    { setSubmitting }: FormikHelpers<SourceFilterValues>,
  ) {
    const params: { [key: string]: number | string | any} = {};

    const checkedSources = values?.sources?.filter((item) => item.value)?.map((item) => +item.id);
    if (checkedSources && checkedSources?.length > 0) {
      params.sources = checkedSources;
    } else if (filterParams?.sources && checkedSources && checkedSources?.length <= 0) {
      delete filterParams.sources;
    }

    const resultParams: {[key: string]: any} = {};

    Object.entries({ ...filterParams, ...params }).forEach((item) => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });

    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();

    setSubmitting(false);
  }

  const additionalResetHandlers = () => {
    if (filterParams.sources) {
      delete filterParams.sources;
    }
    setFilterParams({ ...filterParams });
    setApply({ ...filterParams });
    setReQuery();
    onClose()
  }

  async function getSourcesBySelectedCompany(companyId: number) {
    setLoadingDataByCompany(true);
    const sources = await Api.getSourcesByCompanyId(companyId);

    if (sources.statusCode >= 200 && sources.statusCode < 300 && sources.data !== null) {
      const sourcesData = sources.data.map((source) => ({
        ...source, value: false,
      }));
      sourcesRef.current = sourcesData;
    }
    setLoadingDataByCompany(false);
  }

  useEffect(() => {
    getSourcesBySelectedCompany(+filterParams.companyID).then(() => {
      const sourceData = filterParams?.sources && filterParams.sources.length > 0
        ? sourcesRef.current.map((source) => (filterParams?.sources?.includes(source.id) ? { ...source, value: true } : source)) : sourcesRef.current;
      setInitialValues({ sources: sourceData });
    })
  }, [])

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    resetForm,
  }: FormikProps<SourceFilterValues>) => (
    <Form>
      <div>
        <SubmitButton extraButtonStyles={{ width: '100%', maxWidth: '500px', height: 32 }}>{translations[interfaceLanguage].apply_filters_button}</SubmitButton>
        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
        <TransparentButton
          handleClick={() => {
            resetForm();
            additionalResetHandlers();
          }}
          text={translations[interfaceLanguage].reset_reviews_filter}
          extraStyles={resetFilterButtonStyles}
          extraButtonTextStyles={{ color: '#ffffff' }}
        />

        <CustomSelectWithMultipleCheckboxes
          name="sources"
          handleChange={handleChange}
          options={values.sources || []}
          extraOneOptionStyles={extraOneOptionStyles}
          optionsContainerStyles={{ width: '100%' }}
          placeholder={translations[interfaceLanguage].select_multiple_sources}
          placeholderStyles={{ color: '#979797' }}
          oneOptionValueStyles={{ marginLeft: '4px' }}
          isLoading={loadingDataByCompany}
          disabled={!sourcesRef.current.length}
          alwaysOpened
        />
      </div>
    </Form>
  );

  return (
    <SourceFilterStyles ref={ref}>
      {initialValues ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {renderForm}
        </Formik>
      ) : <Loader margin={0} />}
    </SourceFilterStyles>
  )
})
