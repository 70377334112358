import React from 'react';
import { QRModalStyles } from './QRModalStyles';
import QRCode from 'qrcode.react';
import { useAppSelector } from '../../../../state';
import { Modal } from '../Modal';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { TransparentButton } from '../../../atoms';

type Props = {
  onClose: () => void,
  url: string;
}

export const QRModal = React.memo(({ onClose, url }: Props) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <QRModalStyles>
      <Modal onClose={onClose}>
        <div className="title">{getTranslationByLangOrEng(interfaceLanguage, 'qr_title')}</div>
        <QRCode value={url} size={256} renderAs="svg" />
        <p>or</p>
        <TransparentButton
          text="Connect"
          handleClick={() => window.open(url, '_blank')}
          targetIsBlank
          extraStyles={{ marginBottom: 24 }}
        />
      </Modal>
    </QRModalStyles>
  );
});
