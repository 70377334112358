import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ArrowRight: FC<TIcon> = React.memo(({ color = 'black' }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path d="M13 6L6 0V5H0V7H6V12L13 6Z" fill={color} />
  </svg>
));
