import React, { forwardRef, useEffect, useState } from 'react';
import { NumbersScaleConstructorStyles } from './NpsScaleConstructorStyles';
import {
  ErrorMessage, FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useParams } from 'react-router-dom';
import { numberScaleValidationSchema } from '../scaleFormsValidation';
import CustomColorsSelect from '../../form/customSelect/CustomColorsSelect';
import { DeleteIcon, SimplePlusIcon } from '../../../assets';
import InputField from '../../form/inputField/InputField';
import Checkbox from '../../form/checkbox/Checkbox';
import DefaultCompanyImage from '../../../assets/images/defaultCompanyImage.png';
import SubmitButton from '../../form/submitButton/SubmitButton';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import SelectWithAnyItems from '../../form/customSelect/SelectWithAnyItems';
import {
  IImage, INumberScaleSettings,
  INpsScaleSettings, IColor,
} from '../../../entities';
import { arrayUniqueByKey, getErrorMessage, handleKeyUp } from '../../../utils';
import { getTranslationByLangOrEng } from '../../../i18n';
import NpsScale from '../../form/scales/npsScale/NpsScale';
import { useForwardRef } from '../../../hooks';
import { FormErrorMessage, TransparentButton } from '../../atoms';

interface NpsScaleConstructorProps {
  selectedTab: string,
  scaleIndex: number,
  companyImages: { name: string, url: string }[],
  companyColors: IColor[],
  settings: INpsScaleSettings,
  handleCancel: () => void,
  resetForm?: (values: INpsScaleSettings) => void;
}

const NpsScaleConstructor = forwardRef<FormikProps<INpsScaleSettings>, NpsScaleConstructorProps>(({
  selectedTab,
  scaleIndex,
  companyImages,
  companyColors,
  settings,
  handleCancel,
  resetForm,
}, ref) => {
  const { formId } = useParams();
  const dispatch = useAppDispatch();

  const { notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [extraBackgroundColor, setExtraBackgroundColor] = useState<IColor>();
  const [extraMascot, setExtraMascot] = useState<IImage>();
  const [isLoading, setIsLoading] = useState(false);
  const [submitError, setSubmitError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<INpsScaleSettings>();

  const formRef = useForwardRef<FormikProps<INpsScaleSettings>>(ref);

  useEffect(() => {
    if (companyColors) {
      const colorsList = [...companyColors, ...(settings.backgroundColors || [])];
      const colorsUniqueList = arrayUniqueByKey<IColor>('name', colorsList);

      setInitialValues({
        colors: colorsUniqueList,
        defaultValue: settings.defaultValue,
        showDefaultValue: settings.showDefaultValue,
        changeBackgroundColor: settings.changeBackgroundColor,
        backgroundColors: settings.backgroundColors,
        changeMascot: settings.changeMascot,
        mascots: settings.mascots,
        signatureColor: settings.signatureColor,
      });
    }
  }, [settings, formId, companyColors]);

  function onSubmit(
    values: INpsScaleSettings,
    { setSubmitting }: FormikHelpers<INpsScaleSettings>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    dispatch(updateNotSavedExtraField({
      ...notSavedExtraFields[scaleIndex],
      ...{
        ...values,
        defaultValue: values.defaultValue,
      },
    }));
    setIsLoading(false);

    if (resetForm) {
      resetForm({
        ...notSavedExtraFields[scaleIndex],
        ...{
          ...values,
          defaultValue: values.defaultValue,
        },
      })
    }
    handleCancel();
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<INumberScaleSettings>) {
    return (
      <Form>
        <div className="scaleContainer">
          <NpsScale
            defaultValue={values.defaultValue}
            showDefaultValue={values.showDefaultValue}
            signatures={[getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_min_placeholder'), getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_max_placeholder')]}
            signatureColor={values.signatureColor?.color as string}
          />
        </div>

        <div className="pointerColorContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_signature_text_color_label')}</h3>
          <CustomColorsSelect
            name="signatureColor"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_select_color_placeholder')}
            options={values.colors}
            value={values.signatureColor}
            handleSelect={(color) => setFieldValue('signatureColor', color)}
            formGroupStyles={{ width: '300', marginBottom: 0 }}
            extraComponentPosition="left"
            extraStyles={{ flexGrow: 1 }}
            handleAddColor={(color) => {
              setFieldValue(`colors[${values.colors.length}]`, color);
              setFieldValue('signatureColor', color);
            }}
          />
        </div>

        <div className="defaultValueContainer">
          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_placeholder')}</h3>
          <InputField
            type="number"
            min={0}
            max={values.maxValue || 10}
            extraBlockStyles={{ width: '100%', maxWidth: '250px' }}
            name="defaultValue"
            onChange={(key, value) => {
              if (Number.isNaN(+value) || +value < 0) {
                setFieldValue(key, 0);
              } else if (+value > (values.maxValue || 10)) {
                setFieldValue(key, values.maxValue || 10);
              } else {
                setFieldValue(key, value);
              }
            }}
            onKeyUp={() => handleKeyUp('defaultValue', setErrorMessage, errorMessage)}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_minimum_value_label')}
            value={values.defaultValue}
            error={typeof errorMessage === 'object' ? getErrorMessage('defaultValue', errorMessage) : undefined}
          />
        </div>

        <Checkbox
          name="showDefaultValue"
          value={values.showDefaultValue}
          onChange={handleChange}
        >
          <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_show_default_value')}</span>
        </Checkbox>

        <div className="horizontalLine" />

        <div className="selectBackgroundsContainer">
          <div className="changeBackgroundContainer">
            <Checkbox
              name="changeBackgroundColor"
              value={values.changeBackgroundColor}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_background_checkbox_label')}</span>
            </Checkbox>
          </div>

          {values.changeBackgroundColor && (
            <div
              className="selectMultipleColorsContainer"
              style={values.backgroundColors.length === (values.maxValue ? +values.maxValue : 10) ? { marginBottom: 24 } : {}}
            >
              <div className="titleContainer">
                <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_background_select_label')}</h3>
                <div className="quantityContainer">
                  <span>{`${values.backgroundColors.length}/${values.maxValue || 10}`}</span>
                </div>
              </div>

              <FieldArray
                name="backgroundColors"
                render={({ remove, push }) => (
                  <div className="selectedColorsContainer">
                    {values.backgroundColors.map((field, index) => (
                      <div className="selectedColor">
                        <span className="colorNumber">{index + 1}</span>

                        <div className="valueContainer colors">
                          <div
                            className="color"
                            style={{
                              backgroundColor: typeof field.color === 'string'
                                ? field.color
                                : `rgba(${field.color.r}, ${field.color.g}, ${field.color.g}, ${field.color.a || 100}`,
                            }}
                          />
                          <span>{field.name}</span>
                        </div>

                        <button
                          className="removeColorButton"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ))}

                    {values.backgroundColors.length < (values.maxValue || 10) && (
                      <div className="addColorContainer">
                        <CustomColorsSelect
                          name="extraBackgroundColor"
                          placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_choose_color_placeholder')}
                          options={values.colors}
                          value={extraBackgroundColor}
                          handleSelect={(color) => setExtraBackgroundColor(color)}
                          formGroupStyles={{ width: '300', marginBottom: 0 }}
                          extraComponentPosition="left"
                          extraStyles={{ flexGrow: 1 }}
                          hideErrors
                          handleAddColor={(color) => {
                            setFieldValue(`colors[${values.colors.length}]`, color);
                            setExtraBackgroundColor(color);
                          }}
                        />

                        <button
                          className="addColorButton"
                          type="button"
                          onClick={() => {
                            push(extraBackgroundColor);
                            setExtraBackgroundColor(undefined);
                          }}
                          disabled={!extraBackgroundColor}
                        >
                          <SimplePlusIcon color="#FFF" />
                        </button>
                      </div>
                    )}

                    {values.backgroundColors.length < (values.maxValue || 10) && (
                      <div className="formErrorContainer">
                        {typeof errorMessage === 'object' ? getErrorMessage('backgroundColors', errorMessage) : undefined}
                        {errors.backgroundColors && <ErrorMessage name="backgroundColors" component={FormErrorMessage} />}
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div
          className="horizontalLine"
          style={(values.backgroundColors.length === (values.maxValue ? +values.maxValue : 10) && values.changeBackgroundColor)
            ? { marginTop: 32 }
            : { marginTop: 16 }}
        />

        <div className="selectBackgroundsContainer">
          <div className="changeBackgroundContainer">
            <Checkbox
              name="changeMascot"
              value={values.changeMascot}
              onChange={handleChange}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_mascot_checkbox_label')}</span>
            </Checkbox>
          </div>

          {values.changeMascot && (
            <div
              className="selectMultipleFilesContainer"
              style={values.mascots.length === (values.maxValue ? +values.maxValue : 10) ? { marginBottom: 24 } : {}}
            >
              <div className="titleContainer">
                <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_mascot_select_label')}</h3>
                <div className="quantityContainer">
                  <span>{`${values.mascots.length}/${values.maxValue || 11}`}</span>
                </div>
              </div>

              <FieldArray
                name="mascots"
                render={({ remove, push }) => (
                  <div className="selectedColorsContainer mascotsContainer">
                    {values.mascots.map((field, index) => (
                      <div className="selectedMascot">
                        <span className="colorNumber">{index}</span>

                        <div className="valueContainer mascots">
                          {/* {JSON.stringify(field)} */}
                          <div className="uploadedMascotContainer">
                            <img
                              className="selectedImage"
                              src={field.url}
                              alt={field.name}
                            />
                          </div>
                          <span>{typeof field === 'string' ? field : field.name}</span>
                        </div>

                        <button
                          className="removeColorButton"
                          type="button"
                          onClick={() => remove(index)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ))}

                    {values.mascots.length < (values.maxValue || 11) && (
                      <div className="addMascotContainer">
                        <div className="addMascotWrapper">
                          <div className={extraMascot ? 'logoContainer' : 'logoContainer default'}>
                            {extraMascot ? (
                              <img
                                className="selectedImage"
                                src={extraMascot.url}
                                alt={extraMascot.name}
                              />
                            ) : (
                              <img
                                src={DefaultCompanyImage}
                                alt=""
                              />
                            )}
                          </div>

                          <div className="uploadButtonFieldContainer">
                            <SelectWithAnyItems
                              name="mascots"
                              placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_scale_constructor_change_mascot_select_placeholder')}
                              options={companyImages}
                              value={extraMascot}
                              handleSelect={(file) => setExtraMascot(file)}
                              formGroupStyles={{ width: '300', marginBottom: 0 }}
                              hideErrors
                              renderItem={(option) => (
                                <div className="mascotOptionItem">
                                  <div className="imageContainer">
                                    <img src={option.url} alt={option.name} />
                                  </div>

                                  <p>{option.name}</p>
                                </div>
                              )}
                              renderSelect={(value) => <p>{value.name}</p>}
                              optionExtraClassName="mascotOption"
                              optionsExtraClassName="mascotOptions"
                              search
                            />

                            <TransparentButton
                              handleClick={() => {
                                if (extraMascot) {
                                  setFieldValue(`mascots[${values.mascots.length}]`, extraMascot);
                                  setExtraMascot(undefined);
                                }
                              }}
                              filled
                              childrenPosition="left"
                              text={getTranslationByLangOrEng(interfaceLanguage, 'add_button')}
                              extraStyles={{
                                marginTop: 12,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              />
            </div>
          )}
        </div>

        <div className="submitButtonContainer">
          <SubmitButton isLoading={isLoading} isError={touched && Object.keys(errors).length > 0}>{getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}</SubmitButton>
        </div>

        <p className="uploadError">{submitError}</p>
      </Form>
    );
  }

  return (
    <NumbersScaleConstructorStyles>
      {initialValues && (
        <Formik
          innerRef={ref}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={numberScaleValidationSchema}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      )}
    </NumbersScaleConstructorStyles>
  );
});

export default NpsScaleConstructor;
