import styled from 'styled-components';

export const NpsScaleStyles = styled.div<{signatureColor: string | undefined}>`
display: flex;
flex-direction: column;
align-items: center;

.buttons {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-between;
  padding: 0 -10px;
  border: 1px solid #E5E5E5;
  border-radius: 20px;
  overflow: hidden;

  button {
    width: 100%;
    display: grid;
    place-items: center;
    border-left: 1px solid #E5E5E5;
    margin: 0;

    &:first-child {
      border-left: none;
    }

    &.selected {
      background: #aaa;
      color: #fff;
    }
  }

  span {
    width: 20px;
    font-weight: 700;
    text-align: center;
  }
}

input {
  width: 200px;
}

.signatures {
  margin-top: 8px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  color: ${({ signatureColor }) => `${signatureColor || '#b4b4b4'}`};
}
`;
