import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IColorScaleSettings, INumberScaleSettings, IStarScaleSettings } from '../../entities';

export type NotSavedScaleSettings = {
  index: number,
  settings: IColorScaleSettings | INumberScaleSettings | IStarScaleSettings
} | undefined;

export interface ScaleSettingsState {
  selectedScaleType: string | undefined;
  notSavedScaleSettings: NotSavedScaleSettings,
  colorScaleSettings: IColorScaleSettings | undefined;
  numberScaleSettings: INumberScaleSettings | undefined;
  starScaleSettings: IStarScaleSettings | undefined;
}

const initialState: ScaleSettingsState = {
  selectedScaleType: undefined,
  notSavedScaleSettings: undefined,
  colorScaleSettings: undefined,
  numberScaleSettings: undefined,
  starScaleSettings: undefined,
};

export const scaleSettingsSlice = createSlice({
  name: 'scaleSettings',
  initialState,
  reducers: {
    // Reducer comes here
    setSelectedScaleType: (state, action: PayloadAction<string | undefined>) => ({ ...state, selectedScaleType: action.payload }),
    setNotSavedScaleSettings: (state, action: PayloadAction<NotSavedScaleSettings>) => ({ ...state, notSavedScaleSettings: action.payload }),
    setColorScaleSettings: (state, action: PayloadAction<IColorScaleSettings>) => ({ ...state, colorScaleSettings: action.payload }),
    setNumberScaleSettings: (state, action: PayloadAction<INumberScaleSettings>) => ({ ...state, numberScaleSettings: action.payload }),
    setStarScaleSettings: (state, action: PayloadAction<IStarScaleSettings>) => ({ ...state, starScaleSettings: action.payload }),
  },
  extraReducers: (builder) => {},
});

export const { actions: scaleSettingsActions, reducer: scaleSettingsReducer } = scaleSettingsSlice;
// Extract and export each action creasetSelectedScaleTypetor by name
export const {
  setSelectedScaleType,
  setNotSavedScaleSettings,
  setColorScaleSettings,
  setNumberScaleSettings,
  setStarScaleSettings,
} = scaleSettingsActions;
