import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const PlayInCircle: FC<TIcon> = React.memo(({ width = 16, height = 16, color = '#0E9285' }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill={color}
      d="M8 1.336a6.674 6.674 0 00-6.667 6.667A6.674 6.674 0 008 14.669a6.674 6.674 0 006.667-6.666A6.674 6.674 0 008 1.336zm0 12a5.34 5.34 0 01-5.333-5.333A5.34 5.34 0 018 2.669a5.34 5.34 0 015.334 5.334A5.34 5.34 0 018 13.336z"
    />
    <path fill="#0E9285" d="M6 11.33l5.333-3.333L6 4.664v6.667z" />
  </svg>
))
