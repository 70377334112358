import { SocialNetworksModuleStyles } from './SocialNetworksModuleStyles';
import React, { CSSProperties, useEffect, useState } from 'react';
import FieldHeader from '../fieldHeader/FieldHeader';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import {
  EmailIcon, InfoCharIcon,
  MessageIcon, MuteIcon,
  PhoneIcon, TelegramIcon,
  ViberIcon, WhatsAppIcon,
} from '../../../assets';
import {
  ErrorMessage, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { DraggableProvided } from 'react-beautiful-dnd';
import { getTranslationByLangOrEng } from '../../../i18n';
import Checkbox from '../../form/checkbox/Checkbox';
import InputField from '../../form/inputField/InputField';
import CustomSelect from '../../form/customSelect/CustomSelect';
import { extraFieldValidationSchema } from '../validationSchemas';
import { useAppDispatch, useAppSelector, updateNotSavedExtraField } from '../../../state';
import { IExtraField } from '../../../entities/IExtraField';
import { Api } from '../../../api';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import { useParams } from 'react-router-dom';
import { useCompanyColors } from '../../../hooks';
import StepTextSettings from '../stepTextSettings/StepTextSettings';
import { FormErrorMessage, Loader } from '../../atoms';
import { getErrorMessage, handleKeyUp } from '../../../utils';

interface SocialNetworksModuleProps {
  index: number;
  extraBlockStyles?: CSSProperties;
  provided?: DraggableProvided;
  draggable?: boolean;
  handleCopy?: () => void;
  handleRemove?: () => void;
  // handleOpenOnDiagram?: (index: number) => void;
}

const initialAutoAnswers = {
  sms: {
    isOn: false,
    value: '',
  },
  call: {
    isOn: false,
    value: '',
  },
  telegram: {
    isOn: false,
    value: '',
  },
  whatsapp: {
    isOn: false,
    value: '',
  },
  viber: {
    isOn: false,
    value: '',
  },
  email: {
    isOn: false,
    value: '',
  },
  'without answer': {
    isOn: false,
    value: '',
  },
};

function getSocialNetworkIconAndName(lang: string, type: 'sms' | 'call' | 'telegram' | 'viber' | 'email' | 'whatsapp') {
  const types = {
    email:
  <div className="socialItem">
    <EmailIcon color="#0E9285" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_email')}</span>
  </div>,
    sms:
  <div className="socialItem">
    <MessageIcon color="#0E9285" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_sms')}</span>
  </div>,
    call:
  <div className="socialItem">
    <PhoneIcon color="#0E9285" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_call')}</span>
  </div>,
    telegram:
  <div className="socialItem">
    <TelegramIcon color="#0E9285" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_telegram')}</span>
  </div>,
    whatsapp:
  <div className="socialItem">
    <WhatsAppIcon color="#0E9285" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_whatsapp')}</span>
  </div>,
    viber:
  <div className="socialItem">
    <ViberIcon color="#0E9285" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_viber')}</span>
  </div>,
    'without answer':
  <div className="socialItem">
    <MuteIcon color="#8D8D8D" />
    <span>{getTranslationByLangOrEng(lang, 'companies_form_constructor_communication_channels_without_answer')}</span>
  </div>
    ,
  };

  return types[type];
}

export default function SocialNetworksModule({
  index,
  extraBlockStyles,
  provided,
  draggable,
  handleCopy,
  handleRemove,
  // handleOpenOnDiagram,
}: SocialNetworksModuleProps) {
  const { companyId } = useParams();
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedForm, notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const { companyColors, addCompanyColor } = useCompanyColors(companyId!);

  const [autoAnswerInitialValues, setAutoAnswerInitialValues] = useState<{[key: string]: {value: string, isOn: boolean}}>(initialAutoAnswers);
  const [nextFieldOptions, setNextFieldOptions] = useState<IExtraField[]>();
  const [initialValues, setInitialValues] = useState<IExtraField>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);
  const [rerender, setRerender] = useState<boolean>(false);

  useEffect(() => {
    if (notSavedExtraFields) {
      setInitialValues(notSavedExtraFields[index]);
    }
  }, [index]);

  async function onSubmit(
    values: IExtraField,
    { setSubmitting }: FormikHelpers<IExtraField>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateField(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(updateNotSavedExtraField(values));
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IExtraField>) {
    const error = typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined;
    const optionsError = typeof errorMessage === 'object' ? getErrorMessage('options', errorMessage) : undefined;

    function getAdditionalSettingsClassName(field: any) {
      let additionalSettingsClassName = 'additionalSettingsWrapper';

      if (values.showNameInputField) {
        additionalSettingsClassName += ' topSpace';
      }

      if (field.value !== 'call') {
        if (autoAnswerInitialValues[field.value].isOn) {
          additionalSettingsClassName += ' bottomSpace';
        }
      }

      return additionalSettingsClassName;
    }

    return (
      <Form>
        <div className="body">

          <div className="inputContainer">
            <div className="inputRaw">
              <div className="nameContainer">
                <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_title_title')}</span>
              </div>
              <div className="inputElementContainer">
                <input
                  id="question"
                  type="text"
                  value={values.question}
                  onChange={(event) => setFieldValue('question', event.target.value)}
                  onKeyUp={() => handleKeyUp('question', setErrorMessage, errorMessage)}
                  placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_main_question')}
                />
                <div className="infoContainer">
                  <div className="infoIcon">
                    <InfoCharIcon />
                  </div>
                  <div className="infoText">
                    <p>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_info_question')}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="formErrorContainer">
              {error && <span>{error}</span>}
              {!error && touched && <ErrorMessage name="options" component={FormErrorMessage} />}
            </div>
          </div>

          <div className="checboxesWrapper">
            <Checkbox name="countryCodeNotEditable" value={!!values.countryCodeNotEditable} onChange={() => setFieldValue('countryCodeNotEditable', !values.countryCodeNotEditable)} hideError>
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_country_code_not_editable')}</span>
            </Checkbox>

            <Checkbox name="showNameInputField" value={!!values.showNameInputField} onChange={handleChange} hideError>
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_show_name_input_field')}</span>
            </Checkbox>
          </div>

          <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_communication_channels_label')}</h3>
          <div className="options">
            {values.options!.map((field: any, subIndex: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <div className="checkboxFieldContainer" key={subIndex}>
                <div className="checkboxesWrapper">
                  <SquareCheckbox
                    name={`options[${subIndex}].selected`}
                    value={values.options![subIndex].selected!}
                    onChange={handleChange}
                    checkboxWrapperStyles={{ alignItems: 'center' }}
                    customizedCheckboxContainerStyles={{ marginBottom: 24 }}
                  >
                    <div className="iconContainer">
                      {getSocialNetworkIconAndName(interfaceLanguage, field.value)}
                    </div>
                  </SquareCheckbox>

                  {field.value !== 'without answer' && values.options![subIndex].selected && (
                    <div className={getAdditionalSettingsClassName(field)}>
                      {values.showNameInputField && values.nameInputRequiredFor && (
                        <Checkbox name={`nameInputRequiredFor[${field.value}]`} value={values.nameInputRequiredFor[field.value]} onChange={handleChange} hideError>
                          <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_name_field_required')}</span>
                        </Checkbox>
                      )}
                    </div>
                  )}
                </div>

                {values.options![subIndex].selected && autoAnswerInitialValues[field.value].isOn && (
                  field.value === 'email' ? (
                    <CustomSelect
                      name="senderAccount"
                      options={['Template 1', 'Template 2']}
                      placeholder="Choose template"
                      value={autoAnswerInitialValues[field.value].value}
                      disabled={!autoAnswerInitialValues[field.value].isOn}
                      handleSelect={(name) => {
                        setAutoAnswerInitialValues(
                          {
                            ...autoAnswerInitialValues,
                            [field.value]: {
                              isOn: autoAnswerInitialValues[field.value].isOn,
                              value: name,
                            },
                          },
                        );
                      }}
                      search
                      formGroupStyles={{ width: '428px' }}
                    />
                  ) : field.value !== 'call' && field.value !== 'without answer'
                    ? (
                      <InputField
                        name={field.value}
                        onChange={(e, value) => {
                          setAutoAnswerInitialValues(
                            {
                              ...autoAnswerInitialValues,
                              [field.value]: {
                                isOn: autoAnswerInitialValues[field.value].isOn,
                                value,
                              },
                            },
                          );
                        }}
                        placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_communication_channels_checkbox_auto_answer_placeholder')}
                    // @ts-ignore
                        value={autoAnswerInitialValues[field.value].value}
                    // @ts-ignore
                        disabled={!autoAnswerInitialValues[field.value].isOn}
                        extraBlockStyles={{ maxWidth: 426, width: '100%' }}
                      />
                    ) : ''
                )}
              </div>
            ))}
          </div>

          {notSavedForm?.withSteps && values.type !== 'title' && (
            <StepTextSettings
              stepsText={values.stepsText || ''}
              handleKeyUp={handleKeyUp}
              setFieldValue={setFieldValue}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
            />
          )}

          <Checkbox
            name="required"
            value={values.required!}
            onChange={handleChange}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question')}</span>
          </Checkbox>
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return (
    <SocialNetworksModuleStyles style={extraBlockStyles}>
      <FieldHeader
        type={notSavedExtraFields?.[index].type || ''}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_communication_channels_title')}
        draggable={draggable}
        handleRemove={handleRemove}
        handleCopy={handleCopy}
        provided={provided}
        // handleOpenOnDiagram={handleOpenOnDiagram ? () => handleOpenOnDiagram(index) : undefined}
      />

      {initialValues ? (
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
          // @ts-ignore
          validationSchema={notSavedExtraFields?.[index].type ? extraFieldValidationSchema[notSavedExtraFields![index].type] : undefined}
          enableReinitialize
        >
          {renderForm}
        </Formik>
      ) : <Loader />}
    </SocialNetworksModuleStyles>
  );
}
