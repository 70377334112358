import { INewAnswerStructure, IReviewType, IIssue } from '../../../entities';
import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';

export interface FormValues {
  status: any;
  comment: string;
}

export interface FormValues2 {
  reviewType: IReviewType | undefined;
}

export interface FormMessageValues {
  text: string;
  close: boolean;
  attachmentUrl?: string;
}

export interface FormValues4 {
  name: string;
  color: {
    rgb: {r: number, g: number, b: number, a?: number},
    hex: string
  };
}

export const formInitialValues4: FormValues4 = {
  name: '',
  color: {
    rgb: {
      r: 255, g: 255, b: 255, a: 1,
    },
    hex: '#FFFFFF',
  },
};

export interface ReviewData {
  companyId: number;
  reviewId: number;
  nodeId: number;
  companyName: string;
  nodeName: string;
  formName: string;
  nodeCode?: string;
  userName?: string;
  phone?: string;
  email?: string;
  connectionChannels?: {
    [key : string]: string,
  };
  googlePlaceID?: {
    id?: number,
    value: string
  };
  averageGoogleScoring?: string;
  files?: INewAnswerStructure[];
  formSource?: {
    name: string | number;
    id: number
  } | null,
  reviewCreatedAt: string;
  responsible: string | undefined;
  reviewUpdatedAt: string;
  wasOverdue: boolean;
}

export const validationSchema4 = (lang: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  color: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
});

export interface IssuesFormValues {
  issues: IIssue[];
  newIssueName: string | undefined;
  newIssueTypeId: number | undefined;
  newIssueType: string | undefined;
  newIssueCategory: string | undefined;
  newIssueSubcategory: string | undefined;
  newIssueStatus: number | undefined;
}

export const closeDialogData: FormMessageValues = {
  close: true,
  text: '',
};

export const reopenDialogData: FormMessageValues = {
  close: false,
  text: '',
};

export const tabsEdit: (lang: string) => {[key: string]: string}[] = (lang) => ([
  {
    key: 'info',
    text: 'General Info',
  },
  {
    key: 'status',
    text: 'Issues & Status',
  },
  {
    key: 'chat',
    text: 'Chat',
  },
  {
    key: 'audit',
    text: 'Audit',
  },
]);

export const possibleOptions = ['extra_question', 'radio', 'scale_color', 'scale_number', 'scale_star', 'scale_nps', 'quantity'];
export const arrNetworkValues = ['telegram', 'viber', 'whatsapp', 'google'];
