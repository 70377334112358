import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useEffect, useRef, useState } from 'react';
import Connection from '../connection/Connection';
import SubmitButton from '../../../../components/form/submitButton/SubmitButton';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { IExtraField, IFormDocument, INodeSettings } from '../../../../entities';
import { useAppSelector } from '../../../../state';
import Checkbox from '../../../../components/form/checkbox/Checkbox';
import { FormConstructorItemStyles } from '../formConstructorItem/FormConstructorItemStyles';
import { FieldIcon, TransparentButton } from '../../../../components';

interface NodeSettingsFormProps {
  selectedDiagramNodeId: number,
  selectedDiagramNode: IExtraField | IFormDocument,
  diagramPlacedFieldIds: number[],
  initialValues: INodeSettings,
  onSubmitConnectionForm: (
    values: INodeSettings,
    { setSubmitting }: FormikHelpers<INodeSettings>,
  ) => void
}

const types = [{ key: 'always', text: 'All other cases' }, { key: 'if', text: 'If' }];

export default function NodeSettingsForm({
  selectedDiagramNodeId,
  selectedDiagramNode,
  diagramPlacedFieldIds,
  initialValues,
  onSubmitConnectionForm,
}: NodeSettingsFormProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedExtraFields, notSavedDocuments } = useAppSelector((state) => state.notSavedForm);

  const [rerenderForm, setRerenderForm] = useState<boolean>(false);

  const formRef = useRef<FormikProps<INodeSettings>>(null);

  useEffect(() => {
    if (formRef.current) {
      formRef.current.resetForm({ values: initialValues });
      setRerenderForm(!rerenderForm);
    }
  }, [selectedDiagramNodeId]);

  function renderNodeSettingsForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    resetForm,
  }: FormikProps<INodeSettings>) {
    return (
      <Form>
        <div className="settingsWrapper">
          <div className="selectedFieldPreviewContainer">
            <FormConstructorItemStyles>
              <FieldIcon type={selectedDiagramNode.type} />

              {/* @ts-ignore */}
              <p>{selectedDiagramNode.question || selectedDiagramNode.description}</p>
            </FormConstructorItemStyles>
          </div>

          <Checkbox name="inSeries" value={!!values.inSeries} onChange={handleChange} hideError extraBlockStyles={{ marginBottom: 12 }}>
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_add_field_to_series')}</span>
          </Checkbox>

          <FieldArray
            name="connections"
            render={({
              insert, remove, push, move,
            }) => (
              <div>
                {values.connections && values.connections.length > 0 && (
                <div className="connectionsList">
                  {values.connections.map((item, index) => {
                    const simpleField = selectedDiagramNode?.type && !['radio', 'scale', 'document'].includes(selectedDiagramNode?.type);

                    const connectionValues = {
                      ...(!simpleField && { type: item.type ? (types.find((type) => type.key === item.type) || types[0]).key : types[0].key }),
                      ...(!simpleField && { selectedCase: item.selectedCase }),
                      ...(
                        selectedDiagramNode?.type !== 'document'
                        && !simpleField
                        && { caseIndex: selectedDiagramNode?.answers?.findIndex((option) => option.id === item.selectedCase) }
                      ),
                      selectedField: ([...(notSavedExtraFields || []), ...(notSavedDocuments || [])]).find((field) => field.id === item.selectedField)?.id,
                      nextFieldIndex: ([...(notSavedExtraFields || []), ...(notSavedDocuments || [])]).findIndex((field) => field.id === item.selectedField),
                      ...(
                        selectedDiagramNode?.type !== 'document'
                        && !simpleField
                        && { caseOptionAnswer: selectedDiagramNode?.answers?.find((option) => option.id === item.selectedCase)?.value }
                      ),
                    };

                    return (
                      <Connection
                        key={`connections-${index + 1}`}
                        name={`connections[${index}]`}
                        fieldType={selectedDiagramNode?.type || ''}
                        isOpen={item.isOpen}
                        types={types}
                        cases={selectedDiagramNode?.hasOwnProperty('answers') ? ((selectedDiagramNode as IExtraField).answers || []) : []}
                        fields={[
                          ...notSavedExtraFields.filter((field) => (diagramPlacedFieldIds.includes(field.id!) && field.type !== 'title' && field.id !== selectedDiagramNode!.id)),
                          ...notSavedDocuments.filter((field) => (diagramPlacedFieldIds.includes(field.id!) && field.id !== selectedDiagramNode!.id)),
                        ]}
                        handleDelete={() => remove(index)}
                        handleOpen={(value) => setFieldValue(`connections[${index}].isOpen`, value)}
                        setFieldValue={setFieldValue}
                        {...connectionValues}
                      />
                    );
                  })}
                </div>
                )}

                {!(selectedDiagramNode?.type && !['radio', 'scale'].includes(selectedDiagramNode?.type))
                  && (
                    <div className="addCaseWrapper">
                      <TransparentButton handleClick={() => push({ type: types[0].key, isOpen: true })} text="Add case" />
                    </div>
                  )}

                <SubmitButton extraBlockStyles={{ maxWidth: '544px', width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
              </div>
            )}
          />
        </div>
      </Form>
    );
  }

  return (
    <Formik
      formRef={formRef}
      initialValues={initialValues}
      onSubmit={onSubmitConnectionForm}
      enableReinitialize
    >
      {renderNodeSettingsForm}
    </Formik>
  );
}
