import styled from 'styled-components';

export const CreateMassMailingsFirstStepStyles = styled.div`
  .formContainer {
    padding: 32px 32px 32px 16px;

    .tabsContainer {
      margin-bottom: 24px;

      button {
        height: 36px;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #0e9285;
        border-radius: 24px;

        font-size: 15px;
        line-height: 16px;
        color: #7c7878;
        transition: all 0.3s;

        &:last-child {
          margin-right: 0;
        }

        &.active {
          color: #fff;
          transition: all 0.3s;
        }
      }
    }

    .connectionChannelsHelperWrapper {
      padding-left: 14px;
      .connectionChannelsContainer {
        display: flex;
        align-items: center;
        width: 50%;

        .connectionChannelsTitle {
          font-weight: 700;
          margin-right: 30px;
          height: 40px;
        }
      }

      .recipientsContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 540px;
        margin-bottom: 30px;

        .contentEditableAreaTitle {
          font-weight: 700;
          font-size: 15px;
          line-height: 16px;
          color: #000000;
        }

        .contentEditableAreaContainer {
          max-width: 428px;
          width: 100%;
          .contentEditable {
            max-width: 428px;
            width: 100%;
            height: 40px;
            font-size: 14px;
            color: #000;
            background-color: #ffffff;
            border: 1px solid #e5e5e5;
            box-sizing: border-box;
            border-radius: 4px;
            white-space: pre-wrap;
            overflow: clip;
            word-wrap: break-word;
            overflow-y: scroll;

            span {
              margin: 4px;
              display: inline-block;
            }

            &::placeholder {
              font-family: Roboto, sans-serif;
              font-size: 14px;
              color: #979797;
            }
          }
        }
      }

      .contactsFileContainer {
        display: flex;
        align-items: center;
        margin-bottom: 24px;

        .label {
          font-weight: 700;
          font-size: 15px;
          line-height: 16px;
          color: #000000;
          margin-bottom: 8px;
        }

        .uploadedFileName {
          display: flex;
          align-items: center;

          span {
            margin-left: 8px;
          }

          margin-top: 12px;

          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #000;
        }

        .filesFormat {
          height: 16px;
        }
      }

      .recipientsInputContainer {
        margin-top: 30px;
        margin-bottom: 30px;
      }
      .recipientsInputInnerContainer {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;

        .recipientInputLabelText {
          display: inline-block;
          margin-bottom: 8px;
          font-weight: 700;
        }

        .addRecipientContainer {
          display: flex;
          width: 100%;
          .recipientInput {
            font-family: Roboto, sans-serif;
            font-size: 14px;
            color: #000;
            font-weight: 500;
            border: none;
            padding: 0;
            margin: 0;
            border: 1px solid #e5e5e5;
            border-radius: 4px;
            background-color: #fff;
            padding-left: 10px;
            height: 30px;
            width: 100%;
            max-width: 220px;
            margin-right: 20px;
          }

          .addRecipientButton {
            background-color: #0e9285;
            color: #fff;
            width: 100%;
            min-width: 160px;
            justify-content: center;
            width: 120px;
            height: 32px;
            box-sizing: border-box;
            border-radius: 4px;
          }
        }
      }
    }

    .recipientsListContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 540px;
      margin-bottom: 30px;
      visibility: visible;

      &.hidden {
        visibility: hidden;
      }

      .recipientsListTitle {
        margin-bottom: 10px;
      }
      .recipientsList {
        overflow: auto;
        padding: 10px 0px;
        border: 1px solid #e5e5e5;
        width: 100%;
        max-width: 500px;
        height: 200px;

        .recipientsListItem {
          display: flex;
          align-items: center;
          padding: 0px 12px;
          justify-content: space-between;
          height: 60px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          &:first-child {
            border-top: 1px solid rgba(0, 0, 0, 0.5);
          }

          .deleteRecipientButton {
            height: 20px;
          }
        }
      }
    }

    .mailsCountContainer {
      display: flex;
      margin-bottom: 30px;
      justify-content: center;
      width: 100%;
      max-width: 540px;

      span {
        font-weight: 700;
      }
    }

    .buttonsContainer {
      display: flex;

      button {
        width: 100%;
        max-width: 540px;
        background-color: #0e9285;
        font-weight: 700;
        color: #fff;
        height: 40px;
      }

      .saveDraftButton {
        border-radius: 4px;
      }
    }

    .extraErrorMessage {
      color: #ff0000;
      font-size: 12px;
      margin-top: 8px;
    }
  }
`;
