import { IIssueStatus, IIssueType } from '../../entities';
import * as Yup from 'yup';
import { getTranslationByLangOrEng, translations } from '../../i18n';

export interface FormValues {
  statuses: IIssueStatus[],
  types: IIssueType[],
}

export interface FormValues2 {
  type: 'type' | 'status',
  value: string;
}

export const formInitialValues2_1: FormValues2 = {
  type: 'status',
  value: '',
};

export const formInitialValues2_2: FormValues2 = {
  type: 'type',
  value: '',
};

export const validationSchema = (lang: string) => Yup.object({
  statuses: Yup.array().of(
    Yup.object({
      name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
  ),
  types: Yup.array().of(
    Yup.object({
      name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    }),
  ),
});

export const validationSchema2 = (lang: string) => Yup.object({
  value: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
});
