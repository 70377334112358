import React, { CSSProperties } from 'react';
import { ScaleFieldStyles } from './ScaleFieldStyles';
import ColorScale from '../../form/scales/colorScale/ColorScale';
import NumberScale from '../../form/scales/numberScale/NumberScale';
import StarScale from '../../form/scales/starScale/StarScale';
import NpsScale from '../../form/scales/npsScale/NpsScale';
import { getTranslationByLangOrEng } from '../../../i18n';
import { useAppSelector } from '../../../state';

interface ColorScaleColors {
  colors: string[],
  pointerColor: string,
}

interface NumberScaleColors {
  numbersColor: string,
  selectedButtonColor: string,
}

interface StarScaleColors {
  selectedStarColor: string,
  notSelectedStarBorder: string,
  notSelectedStarBackground: string,
}
interface NpsScaleColors {
  signatureColor: string,
}

interface ScaleFieldProps {
  index: number;
  value: number;
  scaleType: string;
  signatureMin: string | undefined;
  signatureMax: string | undefined;
  colors: ColorScaleColors | NumberScaleColors | StarScaleColors | NpsScaleColors,
  maxValues: number,
  defaultValues: number,
  showDefaultValue: boolean | undefined,
  handleChange: (value: number[] | number | undefined) => void;
  required: boolean;

  withoutTitle?: boolean;
  question?: string;
  extraBlockStyles?: CSSProperties;
}

const defaultMaxValues = [10, 5, 5];
const defaultDefaultValues = [0, 0, 0];

export default function ScaleField({
  index,
  value,
  scaleType,
  signatureMin,
  signatureMax,
  colors,
  maxValues,
  defaultValues,
  showDefaultValue,
  handleChange,
  required,

  withoutTitle,
  question,
  extraBlockStyles,
}: ScaleFieldProps) {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <ScaleFieldStyles style={extraBlockStyles}>
      {!withoutTitle && (
        <div className="titleContainer">
          {required && <span>*</span>}
          <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question || getTranslationByLangOrEng(interfaceLanguage, 'client_form_scale_field_h3')}`}</h3>
        </div>
      )}

      {scaleType === 'color' && (
        <ColorScale
          colors={(colors as ColorScaleColors).colors}
          pointerColor={(colors as ColorScaleColors).pointerColor}
          maxValue={+maxValues || defaultMaxValues[0]}
          defaultValue={+defaultValues || defaultDefaultValues[0]}
          signatures={[signatureMin, signatureMax]}
          value={[value]}
          handleChange={handleChange}
        />
      )}
      {scaleType === 'number' && (
        <NumberScale
          numbersColor={(colors as NumberScaleColors).numbersColor}
          selectedButtonColor={(colors as NumberScaleColors).selectedButtonColor}
          maxValue={+maxValues || defaultMaxValues[1]}
          defaultValue={+defaultValues || defaultDefaultValues[1]}
          showDefaultValue={showDefaultValue!}
          signatures={[signatureMin, signatureMax]}
          value={value}
          handleChange={handleChange}
        />
      )}
      {scaleType === 'star' && (
        <StarScale
          selectedStarColor={(colors as StarScaleColors).selectedStarColor}
          notSelectedStarBorder={(colors as StarScaleColors).notSelectedStarBorder}
          notSelectedStarBackground={(colors as StarScaleColors).notSelectedStarBackground}
          maxValue={+maxValues || defaultMaxValues[2]}
          defaultValue={+defaultValues || defaultDefaultValues[2]}
          showDefaultValue={showDefaultValue!}
          signatures={[signatureMin, signatureMax]}
          value={value}
          handleChange={handleChange}
        />
      )}
      {scaleType === 'nps' && (
        <NpsScale
          signatureColor={(colors as NpsScaleColors).signatureColor}
          defaultValue={+defaultValues || defaultDefaultValues[1]}
          showDefaultValue={showDefaultValue!}
          signatures={[signatureMin || '', signatureMax || '']}
          value={value}
          handleChange={handleChange}
        />
      )}
    </ScaleFieldStyles>
  );
}
