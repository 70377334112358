import styled from 'styled-components';

export const CompanySourcesStyles = styled.div`
padding: 26px 32px 80px 16px;
.designWrapper {
  max-width: 544px;
  width: 100%;
  border: 1px solid #F0F0F0;
  margin-bottom: 25px;
  .headWrapper {
    padding: 9px 11px 9px 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #F0F5F5;
    span {
      font-weight: 500;
      font-size: 15px;
      color: #000000;
    }
    .buttonsWrapper {
      width: 274px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .addButtonWrapper {
        width: 232px;
        button {
          width: 232px;
          height: 32px;
          color: #FFFFFF;
          font-weight: 500;
          font-size: 14px;
          background-color: #0E9285;
          border: 1px solid #0E9285;
          border-radius: 4px;
          transition: 0.3s;
          span {
            color: #FFF;
          }
          :hover {
            color: #0E9285;
            background-color: #FFFFFF;
            span {
              color: #0E9285;
            }
          }
        }
      }
    }
  }
}
form {
  margin-bottom: 8px;
  .arrayWrapper {
    .itemsWrapper {
      height: 56px;
      padding: 0 16px;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr auto;
      grid-gap: 24px;
      border-bottom: 1px solid #f0f0f0;
      &.edit {
        height: 88px;
        align-items: flex-end;
      }
      &:last-child {
        border-bottom: none;
      }
      .inputWrapper {
        font-size: 14px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
      .deleteButtonWrapper {
        height: 100%;
        display: flex;
        align-items: center;
        
        button {
          height: 20px;
        }
      }
    }
  }
  .saveError {
    font-size: 11px;
    line-height: 16px;
    color: #ff3333;
  }
}
.modalContainer {
  background: #fff;
  color: #000000;
  border-radius: 4px;
  .modalHeader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 16px;
    padding-right: 12px;
    box-sizing: border-box;
    h5 {
      font-size: 14px;
      color: #000;
    }
    button {
      width: 32px;
      height: 32px;
      display: grid;
      place-items: center;
      svg {
        transform: rotate(45deg);
      }
    }
  }
  .body {
    padding: 16px;
    border-bottom: 1px solid #e5e5e5;
    .modalTitleContainer {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      h6 {
        margin-bottom: 0;
      }
      span {
        margin-top: 2px;
        margin-left: 4px;
        font-size: 12px;
      }
    }
    h6 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
    }
    p {
      font-size: 14px;
      margin: 0;
    }
  }
  .footer {
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    &.right {
      grid-template-columns: 1fr auto;
    }
    & > div, a {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
    }
    & > div {
      width: 100%;
      margin: 0;
      a, button {
        width: 100%;
      }
    }
  }
}
`;
