import * as Yup from 'yup';
import { getTranslationByLangOrEng, translations } from '../../../../../../i18n';

export interface FormValues {
  senderName: string,
  letterSubject: string,
  senderEmail: { name: string, id: number } | undefined,
  emailBody: string,
  attachedFiles: (string | File)[],
}

export interface FormValues2 {
  previewType: 'desktop' | 'phone'
}

export const validationSchema = (lang: string) => Yup.object({
  senderName: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  letterSubject: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  senderEmail: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
  emailBody: Yup.string(),
  attachedFiles: Yup.array().max(6, 'Max allowed quantity is 6'),
});
