import styled from 'styled-components';

export const SidebarStyles = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 990;
  background-color: #fff;
  height: 100vh;
  padding-top: 72px;
  padding-bottom: 48px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow: hidden;
  border-right: 1px solid #F0F0F0;
  transition: width 0.3s;
  
  ul {
    list-style: none;
  }

  svg {
    filter: invert(92%) sepia(5%) saturate(0%) hue-rotate(150deg) brightness(87%) contrast(86%);
    transition: all 0.2s;
    height: 18px;
  }

  svg:hover {
    filter: invert(78%) sepia(29%) saturate(3090%) hue-rotate(118deg) brightness(90%) contrast(79%);
    transition: all 0.2s;
  }
  
  li {
    width: 100%;
    height: 48px;
    padding: 8px 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    position: relative;
    
    &.active {
      background-color: #F0F5F5;
      
      svg {
        filter: invert(78%) sepia(29%) saturate(3090%) hue-rotate(118deg) brightness(90%) contrast(79%);
      }
    }
    
    a {
      height: 100%;
      display: flex;
      align-items: center;
      text-decoration: none;
    }
    
    .verticalLine {
      width: 3px;
      height: 32px;
      background-color: #FFF;
      border-radius: 1px;
      margin-right: 13px;
      transition: all 0.3s;
      
      &.active {
        background-color: #1BCAB9;
      }
      
      &.hidden {
        width: 0;
        margin-right: 0;
        opacity: 0;
        transition: all 0.3s;
      }
    }
    
    .iconContainer {
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    
    span {
      margin-left: 8px;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
    }
  }

  .count {
    font-size: 12px;
    font-weight: 700;
    background-color: #FF0101;
    color: #fff;
    padding: 0px 7px;
    border-radius: 20px; 
  }

  .count-abs {
    position: absolute;
    bottom: 0;
    left: 35%;
    transform: translate(-50%);
  }
  
  button {
    height: 48px;
    padding: 0;
    font-size: 15px;
    color: #FF0101;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
