import React, { useEffect, useRef, useState } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { getTranslationByLangOrEng } from '../../../i18n';
import SubmitButton from '../../form/submitButton/SubmitButton';
import CustomSelect from '../../form/customSelect/CustomSelect';
import { IssuesTableFilterStyles } from './IssuesTableFilterStyles';
import { Api } from '../../../api';
import CustomSelectTiedNode from '../../form/customSelect/CustomSelectTiedNode';
import { getNodeNamesArray, getNodesDeepArray } from '../../../utils';
import {
  DeepArrayItem, IFormListItem,
  ILanguage, IReviewStatus,
  ISource, CompaniesMainInfo,
} from '../../../entities';
import { useAppSelector } from '../../../state';
import CustomSelectWithMultipleCheckboxes from '../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import { useWindowSize } from 'usehooks-ts';
import { extraOneOptionStyles, resetFilterButtonStyles } from './IssuesTableFilterUtils';
import { Loader, TransparentButton } from '../../atoms';

type SourceExtendedType = ISource & {
  value: boolean;
}

interface FilterFormValues {
  company: { id: number; name: string; } | undefined;
  node: { id: number; name: string; deep: number; } | undefined;
  form: IFormListItem | undefined;
  language: ILanguage | undefined;
  status: { id: number, name: string, icon: any, value: boolean }[] | undefined;
  source: SourceExtendedType[] | undefined;
}

export const IssuesTableFilter = React.memo(({
  companies, filterParams, setFilterParams, setReQuery, setApply, onClose, languages, status,
}: {
  setReQuery: () => void,
  setApply: (params: {[key: string] : number | string }) => void,
  setFilterParams: (params: {[key: string] : number | string }) => void,
  filterParams: {[key: string]: number | string | any },
  companies: {[key: string]: CompaniesMainInfo},
  onClose: () => void,
  languages: ILanguage[],
  status?: IReviewStatus[]
}) => {
  const { width } = useWindowSize();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [loadingDataByCompany, setLoadingDataByCompany] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<FilterFormValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});

  const formRef = useRef<FormikProps<FilterFormValues>>(null);
  const ref = useRef<any>();
  const companiesRef = useRef<{ id: number; name: string; }[]>([]);
  const nodeNamesRef = useRef<{ id: number, name: string, deep: number }[]>([]);
  const nodesRef = useRef<DeepArrayItem[]>([]);
  const formsRef = useRef<IFormListItem[]>([]);
  const languagesRef = useRef<ILanguage[]>([]);
  const sourcesRef = useRef<SourceExtendedType[]>([]);

  const additionalResetHandlers = () => {
    setFilterParams({});
    setApply({});
    setReQuery();
    onClose();
  }

  async function onSubmit(
    values: FilterFormValues,
    { setSubmitting }: FormikHelpers<FilterFormValues>,
  ) {
    // @ts-ignore
    const params: { [key: string]: number | string | any} = {};

    if (values.company?.id) {
      params.companyID = +values.company.id;
    } else if (filterParams?.companyID && !values.company) {
      delete filterParams.companyID;
    }

    if (values.node?.id) {
      params.node_id = values.node.id;
    } else if (filterParams?.node_id && !values.node) {
      delete filterParams.node_id;
    }

    if (values.form?.id) {
      params.form_id = values.form.id;
    } else if (filterParams?.form_id && !values.form) {
      delete filterParams.form_id;
    }

    if (values.language?.name) {
      params.language = values.language.name;
    } else if (filterParams?.language && !values.language) {
      delete filterParams.language;
    }

    const checkedStatuses = values?.status?.filter((item) => item.value)?.map((item) => item?.id);
    if (checkedStatuses && checkedStatuses?.length > 0) {
      params.status = checkedStatuses;
    } else if (filterParams?.status && checkedStatuses && checkedStatuses?.length <= 0) {
      delete filterParams.status;
    }

    const checkedSources = values?.source?.filter((item) => item.value)?.map((item) => item?.id);
    if (checkedSources && checkedSources?.length > 0) {
      params.source = checkedSources;
    } else if (filterParams?.source && checkedSources && checkedSources?.length <= 0) {
      delete filterParams.source;
    }

    const resultParams: {[key: string]: any} = {};

    Object.entries({ ...filterParams, ...params }).forEach((item) => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });

    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    onClose();

    setSubmitting(false);
  }

  async function getDataBySelectedNode(companyId: number, nodeId: number) {
    const forms = nodeId ? await Api.getFormsByNode(nodeId) : undefined;

    if (forms && forms.statusCode >= 200 && forms.statusCode < 300) {
      const formLanguages = forms.data.map((form) => form.language_id);

      formsRef.current = forms.data;
      languagesRef.current = languages.filter((lang) => formLanguages.includes(lang.id!));
    }
  }

  async function getDataBySelectedCompany(companyId: number) {
    // NODES
    const nodes = await Api.getCompanyNodes(companyId);

    if (nodes.statusCode >= 200 && nodes.statusCode < 300) {
      const nodeNamesArray: { id: number, name: string, deep: number }[] = [];

      getNodeNamesArray(nodeNamesArray, nodes.data || []);

      nodeNamesRef.current = nodeNamesArray;
      nodesRef.current = getNodesDeepArray(nodeNamesArray);
    }

    // SOURCES
    const sources = await Api.getSourcesByCompanyId(companyId);

    if (sources.statusCode >= 200 && sources.statusCode < 300 && sources.data !== null) {
      const sourcesData = sources.data.map((source) => ({
        ...source, value: false,
      }));
      sourcesRef.current = sourcesData;
    }
  }

  async function getExtraFormData(companyId: number | undefined, nodeId: number | undefined, init?: boolean) {
    setLoadingDataByCompany(true);

    if (init) {
      if (companyId) {
        await getDataBySelectedCompany(companyId);
      }
      if (nodeId) {
        await getDataBySelectedNode(companyId!, nodeId);
      }
    } else if (nodeId) {
      await getDataBySelectedNode(companyId!, nodeId);
    } else if (companyId) {
      await getDataBySelectedCompany(companyId);
    } else {
      formsRef.current = [];
      nodeNamesRef.current = [];
      nodesRef.current = [];
      sourcesRef.current = [];
    }

    setLoadingDataByCompany(false);
  }

  useEffect(() => {
    const companiesArray = Object.keys(companies).map((key) => ({ id: +key, name: companies[key].name }));
    const statusesFormattedArray = status?.map((status) => ({
      id: +status.value, name: status.text, icon: status.icon, value: false,
    }));

    companiesRef.current = companiesArray;

    getExtraFormData(filterParams?.companyID ? +filterParams.companyID : undefined, filterParams?.node_id ? +filterParams.node_id : undefined, true).then(() => {
      const language = languages.find((lang) => lang.name === filterParams?.language);
      const company = filterParams?.companyID ? companiesArray.find((company) => +company.id === +filterParams.companyID) : undefined;
      const node = filterParams?.node_id ? nodeNamesRef.current.find((node) => node.id === +filterParams.node_id) : undefined;
      const form = filterParams?.form_id ? formsRef.current.find((form) => form.id === +filterParams.form_id) : undefined;
      const statusesArray = filterParams?.status && filterParams.status.length > 0
        ? statusesFormattedArray?.map((status) => (filterParams?.status?.includes(`${status.id}`) ? { ...status, value: true } : status)) : statusesFormattedArray;
      const sourceData = filterParams?.source && filterParams.source.length > 0
        ? sourcesRef.current.map((source) => (filterParams?.source?.includes(`${source.id}`) ? { ...source, value: true } : source)) : sourcesRef.current;

      setInitialValues({
        company,
        node,
        form,
        language,
        status: statusesArray,
        source: sourceData,
      });
    });
  }, []);

  const renderForm = ({
    values,
    errors,
    resetForm,
    setFieldValue,
    handleChange,
  }: FormikProps<FilterFormValues>) => (
    <Form>
      <div className="formSection">
        <div className="formSection additionalSettings">
          <CustomSelectWithMultipleCheckboxes
            name="status"
            handleChange={handleChange}
            options={values.status || []}
            search
            extraOneOptionStyles={extraOneOptionStyles}
            optionsContainerStyles={{ width: '100%' }}
            formGroupStyles={{ marginBottom: '22px' }}
            label={getTranslationByLangOrEng(interfaceLanguage, 'filter_title_status')}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_multiple_statuses')}
            placeholderStyles={{ color: '#979797' }}
            oneOptionValueStyles={{ marginLeft: '4px' }}
          />

          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'company')}
            name="company"
            options={companiesRef.current}
            selectKey="name"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_company')}
            value={values.company}
            handleSelect={(value) => {
              setFieldValue('company', value);
              setFieldValue('node', undefined);
              setFieldValue('form', undefined);
              setFieldValue('language', undefined);
              getExtraFormData(value?.id ? +value.id : undefined, undefined).then(() => {
                setFieldValue('source', sourcesRef.current)
              });
            }}
            search
            emptyOption
            emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')}
          />

          <CustomSelectTiedNode
            label={getTranslationByLangOrEng(interfaceLanguage, 'node')}
            name="node"
            options={nodesRef.current}
            selectKey="name"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_node')}
            value={values.node}
            handleSelect={(value) => {
              setFieldValue('node', value);
              setFieldValue('form', undefined);
              setFieldValue('language', undefined);
              getExtraFormData(+values.company!, value?.id ? +value.id : undefined);
            }}
            search
            expandable
            required
            isLoading={loadingDataByCompany}
            disabled={!values.company || !nodesRef.current.length}
          />

          <CustomSelectWithMultipleCheckboxes
            name="source"
            handleChange={handleChange}
            options={values.source || []}
            search
            extraOneOptionStyles={extraOneOptionStyles}
            optionsContainerStyles={{ width: '100%' }}
            formGroupStyles={{ marginBottom: '22px' }}
            label={getTranslationByLangOrEng(interfaceLanguage, 'filter_title_sources')}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_multiple_sources')}
            placeholderStyles={{ color: '#979797' }}
            oneOptionValueStyles={{ marginLeft: '4px' }}
            isLoading={loadingDataByCompany}
            disabled={!values.company || !sourcesRef.current.length}
          />

          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'form')}
            name="form"
            options={formsRef.current}
            selectKey="name"
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_form')}
            value={values.form}
            handleSelect={(value) => setFieldValue('form', value)}
            search
            emptyOption
            emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')}
            disabled={!values.node || !formsRef.current.length}
          />

          <CustomSelect
            label={getTranslationByLangOrEng(interfaceLanguage, 'language')}
            name="language"
            selectKey="name"
            options={languagesRef.current}
            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'select_language')}
            value={values.language}
            handleSelect={(languages) => setFieldValue('language', languages)}
            search
            emptyOption
            emptyList={getTranslationByLangOrEng(interfaceLanguage, 'no_options_available')}
            disabled={!values.node || !languagesRef.current.length}
          />

        </div>
        <SubmitButton extraButtonStyles={{ width: '100%', maxWidth: '500px' }}>{getTranslationByLangOrEng(interfaceLanguage, 'apply_filters_button')}</SubmitButton>
        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
        <TransparentButton
          handleClick={() => {
            resetForm();
            additionalResetHandlers();
          }}
          text={getTranslationByLangOrEng(interfaceLanguage, 'reset_reviews_filter')}
          extraStyles={resetFilterButtonStyles}
          extraButtonTextStyles={{ color: '#ffffff' }}
        />
      </div>
    </Form>
  );
  return (
    <IssuesTableFilterStyles ref={ref} mobileVersion={width < 500}>
      {initialValues ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {renderForm}
        </Formik>
      ) : <Loader margin={0} />}
    </IssuesTableFilterStyles>
  );
})
