import * as Yup from 'yup';

const colorSchema = Yup.object({
  id: Yup.number(),
  name: Yup.string(),
  color: Yup.lazy((value) => {
    switch (typeof value) {
      case 'object':
        return Yup.object(); // schema for object
      case 'string':
        return Yup.string(); // schema for string
      default:
        return Yup.mixed(); // here you can decide what is the default
    }
  }),
});

export const colorScaleValidationSchema = Yup.object({
  mainScaleColors: Yup.array().of(colorSchema),
  pointerColor: colorSchema,
  maxValue: Yup.number(),
  defaultValue: Yup.number(),
  changeBackgroundColor: Yup.boolean(),
  backgroundColors: Yup.array().of(colorSchema)
    .test('background-colors-right-quantity', '* Количество значений должно равняться максимальному значению шкалы', (val, ctx) => {
      const { maxValue, changeBackgroundColor } = ctx.parent;
      return changeBackgroundColor ? val!.length >= (maxValue || 10) : true;
    }),
  changeMascot: Yup.boolean(),
  mascots: Yup.array()
    .test('mascots-right-quantity', '* Количество значений должно равняться максимальному значению шкалы', (val, ctx) => {
      const { maxValue, changeMascot } = ctx.parent;
      return changeMascot ? val!.length >= (maxValue || 10) : true;
    }),
});

export const numberScaleValidationSchema = Yup.object({
  numbersColor: colorSchema,
  selectedButtonColor: colorSchema,
  maxValue: Yup.number(),
  defaultValue: Yup.number(),
  changeBackgroundColor: Yup.boolean(),
  backgroundColors: Yup.array().of(colorSchema)
    .test('background-colors-right-quantity', '* Количество значений должно равняться максимальному значению шкалы', (val, ctx) => {
      const { maxValue, changeBackgroundColor } = ctx.parent;
      return changeBackgroundColor ? val!.length >= (maxValue || 5) : true;
    }),
  changeMascot: Yup.boolean(),
  mascots: Yup.array()
    .test('mascots-right-quantity', '* Количество значений должно равняться максимальному значению шкалы', (val, ctx) => {
      const { maxValue, changeMascot } = ctx.parent;
      return changeMascot ? val!.length >= (maxValue || 5) : true;
    }),
});

export const starScaleValidationSchema = Yup.object({
  selectedStarColor: colorSchema,
  notSelectedStarBorder: colorSchema,
  notSelectedStarBackground: colorSchema,
  maxValue: Yup.number(),
  defaultValue: Yup.number(),
  changeBackgroundColor: Yup.boolean(),
  backgroundColors: Yup.array().of(colorSchema)
    .test('background-colors-right-quantity', '* Количество значений должно равняться максимальному значению шкалы', (val, ctx) => {
      const { maxValue, changeBackgroundColor } = ctx.parent;
      return changeBackgroundColor ? val!.length >= (maxValue || 5) : true;
    }),
  changeMascot: Yup.boolean(),
  mascots: Yup.array()
    .test('mascots-right-quantity', '* Количество значений должно равняться максимальному значению шкалы', (val, ctx) => {
      const { maxValue, changeMascot } = ctx.parent;
      return changeMascot ? val!.length >= (maxValue || 5) : true;
    }),
});
