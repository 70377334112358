import React, { useEffect, useState } from 'react';
import { ProfileStyles } from './ProfileStyles';
import { useAppDispatch, useAppSelector, setUser } from '../../state';
import { getTranslationByLangOrEng } from '../../i18n';
import { Api } from '../../api';
import { Helmet } from 'react-helmet-async';
import { SubHeader, TransparentButton, UserProfileForm } from '../../components';
import ReactGA from 'react-ga';

interface FormValues {
  name: string;
  email: string;
  oldPassword: string;
  newPassword: string;
  phoneNumber: string;
}

export const Profile = () => {
  const [allowToEdit, setAllowToEdit] = useState(false);
  const dispatch = useAppDispatch();
  const { data: user, loggedIn } = useAppSelector((state) => state.user);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  useEffect(() => {
    Api.getMyAccount()
      .then((res) => {
        ReactGA.event({
          category: 'User',
          action: 'Sign In',
          label: 'Profile page',
          value: res.data.id,
        });
        dispatch(setUser(res.data));
      });
  }, []);

  function handleAllowEditButton() {
    setAllowToEdit(!allowToEdit);
  }

  return (
    <ProfileStyles>
      <Helmet>
        <title>Profile Voicer</title>
      </Helmet>

      <SubHeader title={getTranslationByLangOrEng(interfaceLanguage, 'employee_profile_title')}>
        <TransparentButton
          text={allowToEdit ? getTranslationByLangOrEng(interfaceLanguage, 'close_editing_button') : getTranslationByLangOrEng(interfaceLanguage, 'edit_button')}
          handleClick={handleAllowEditButton}
        />
      </SubHeader>

      {user && loggedIn && <UserProfileForm setAllowToEdit={setAllowToEdit} allowToEdit={allowToEdit} />}
    </ProfileStyles>
  );
}
