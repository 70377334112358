import { CopyIcon, DeleteIcon, DragIcon } from '../../../assets';
import { FieldHeaderStyles } from './FieldHeaderStyles';
import { DraggableProvided } from 'react-beautiful-dnd';
import { FieldIcon } from '../../atoms';

interface FieldHeaderProps {
  type: string;
  title: string;
  draggable?: boolean;
  handleCopy?: () => void | undefined;
  handleRemove?: () => void | undefined;
  provided?: DraggableProvided;
}

export default function FieldHeader({
  type, title, draggable, handleCopy, handleRemove, provided,
}: FieldHeaderProps) {
  return (
    <FieldHeaderStyles {...(provided ? provided.dragHandleProps : {})}>
      <div className="titleContainer">
        <FieldIcon type={type} />

        <span>{title}</span>
      </div>

      <div className="buttons">
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {draggable && <div><DragIcon /></div>}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {handleCopy && <button type="button" onClick={handleCopy}><CopyIcon /></button>}
        {/* {handleOpenOnDiagram && <button type="button" onClick={handleOpenOnDiagram}><DiagramIcon width={20 * (576 / 512)} height={20} color="#C4C4C4" /></button>} */}
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {handleRemove && <button type="button" onClick={handleRemove}><DeleteIcon color="#C4C4C4" /></button>}
      </div>
    </FieldHeaderStyles>
  );
}
