import styled from 'styled-components';

export const ModalConfirmDeclineStyles = styled.div`
  .modalBody {
    .modalTitle {
      padding: 10px 16px;
      background: #F0F5F5;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      border: 1px solid #F0F5F5;
      border-radius: 4px 4px 0px 0px;
    }

    .modalButtonsWrap {
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 0px 0px 4px 4px;

      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: 14px;
        color: #000000;
      }

      .modalButtonsInnerWrap {
        display: flex;
        gap: 30px;
        align-items: center;

        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }

        &.divNoGap {
          gap: 0px;
        }
      }

      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        color: #ffffff;
      }
    }

    .modalError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
