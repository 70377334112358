import { JustPlusIcon } from '../../../../assets';
import { getTranslationByLangOrEng } from '../../../../i18n';
import React from 'react';
import { LeavePageModalStyles } from './LeavePageModalStyles';
import { useAppSelector } from '../../../../state';
import { TransparentButton } from '../../../atoms';
import { Modal } from '../Modal';

interface LeavePageModalProps {
  setPathToNavigate: (value: string) => void,
  pathToNavigate: string
}

export const LeavePageModal = React.memo(({ pathToNavigate, setPathToNavigate }: LeavePageModalProps) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  return (
    <Modal onClose={() => setPathToNavigate('')}>
      <LeavePageModalStyles>
        <div className="modalHeader">
          <h5>Warning</h5>
          <button type="button" onClick={() => setPathToNavigate('')}>
            <JustPlusIcon color="#999" width={20} height={20} />
          </button>
        </div>
        <div className="body">
          <h6>{getTranslationByLangOrEng(interfaceLanguage, 'are_you_sure')}</h6>
          <p>{getTranslationByLangOrEng(interfaceLanguage, 'your_changes_lose')}</p>
        </div>
        <div className="footer">
          <TransparentButton handleClick={() => setPathToNavigate('')} text={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')} filled />
          <TransparentButton isLink linkAddress={pathToNavigate} text={getTranslationByLangOrEng(interfaceLanguage, 'leave_the_page')} />
        </div>
      </LeavePageModalStyles>
    </Modal>
  );
})
