import styled from 'styled-components';

export const CustomDiagramNodeStyles = styled.div<{inSeries: boolean | undefined}>`
  width: 267px;
  border-radius: 4px;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.1);
  border: ${({ inSeries }) => (inSeries ? '2px solid #008aff' : '2px solid #fff')};
  box-sizing: border-box;

  .handler {
    width: 0;
    height: 0;
  }
`;
