import { CompanyDesignFormStyles } from './CompanyDesignFormStyles';
import React, { useEffect, useState, useRef } from 'react';
import { UploadFileButton } from '../form/uploadFileButton/UploadFileButton';
import {
  FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import * as Yup from 'yup';
import InputField from '../form/inputField/InputField';
import { DeleteIcon, PencilIcon } from '../../assets';
import { useNavigate, useParams } from 'react-router-dom';
import { Api, ApiMetadata, ApiResponse } from '../../api';
import ColorPicker from '../form/colorPicker/ColorPicker';
import SubmitButton from '../form/submitButton/SubmitButton';
import { IColor, IImage } from '../../entities';
import { useAppDispatch, useAppSelector, setCompanyMetadata } from '../../state';
import { sort } from 'fast-sort';
import { getTranslationByLangOrEng } from '../../i18n';
import { getErrorMessage, handleKeyUp } from '../../utils';
import { Loader, TransparentButton } from '../atoms';
import { CompaniesInfoModal, Modal, ModalConfirmDecline } from '../organisms';

type CompanyInfoProps = {
  handleName?:() => void;
}

interface FormValues {
  // fonts: {name: string, file: File, id: number}[],
  colors: IColor[],
  logos: IImage[],
  fonts: IImage[],
}

export default function CompanyDesignForm({ handleName }: CompanyInfoProps) {
  const { id } = useParams();
  const navigate = useNavigate();

  const dispatch = useAppDispatch();
  const companyMetadata = useAppSelector((state) => state.company.companyMetadata);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [maxSizeError, setMaxSizeError] = useState<string>('');
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [submitError, setSubmitError] = useState<string>('');
  const [color, setColor] = useState<{
    rgb: {r: number, g: number, b: number, a?: number},
    hex: string
  }>({
    rgb: {
      r: 255, g: 255, b: 255, a: 1,
    },
    hex: '#FFFFFF',
  });
  const [accessibleLogosEdit, setAccessibleLogosEdit] = useState<boolean>(false);
  const [accessibleFontsEdit, setAccessibleFontsEdit] = useState<boolean>(false);
  const [accessibleColorsEdit, setAccessibleColorsEdit] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [afterSaveModal, setAfterSaveModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [confirmSaveModalOpen, setConfirmSaveModalOpen] = useState<boolean>(false);
  const [dataSaveError, setDataSaveError] = useState<string>('');
  const companyDesignRef = useRef<FormikProps<FormValues>>(null);

  const maxSize = 25000000;

  // TODO: delete fonts???

  useEffect(() => {
    if (companyMetadata && id) {
      const colors = sort([...(companyMetadata.colors || [])]).desc((item) => (item.created_at ? new Date(item.created_at).getTime() : 0));
      const logos = sort([...(companyMetadata.logos || [])]).desc((item) => (item.created_at ? new Date(item.created_at).getTime() : 0));
      const fonts = sort([...(companyMetadata.fonts || [])]).desc((item) => (item.created_at ? new Date(item.created_at).getTime() : 0));

      setInitialValues({ colors, logos, fonts });
    } else if (id) {
      Api.getCompanyMetadataByCompanyId(+id)
        .then((res) => {
          if (res.statusCode === 200) {
            dispatch(setCompanyMetadata(res.data));
            const colors = sort([...(res.data.colors || [])]).desc((item) => (item.created_at ? new Date(item.created_at).getTime() : 0));
            const logos = sort([...(res.data.logos || [])]).desc((item) => (item.created_at ? new Date(item.created_at).getTime() : 0));
            const fonts = sort([...(res.data.fonts || [])]).desc((item) => (item.created_at ? new Date(item.created_at).getTime() : 0));

            setInitialValues({ colors, logos, fonts });
          }
        });
    } else {
      setInitialValues({
        colors: [],
        logos: [],
        fonts: [],
      });
    }
  }, [id]);

  const logoExamination = (file: File | undefined, insert: (index: number, value: any) => void) => {
    if (file!.size > maxSize) {
      setMaxSizeError(`* ${getTranslationByLangOrEng(interfaceLanguage, 'max_size_error_25')}`);
      return;
    }

    if (file) {
      Api.createFile({ file })
        .then((res) => {
          insert(0, {
            url: res.data.url,
            name: file!.name.split('.')[0],
          });
        });
    }
    setMaxSizeError('');
  };

  const validationSchema = Yup.object({
    colors: Yup.array().of(
      Yup.object({
        name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
      }),
    ),
    logos: Yup.array().of(
      Yup.object({
        name: Yup.string().required(getTranslationByLangOrEng(interfaceLanguage, 'validation_required')),
      }),
    ),
  });

  const onSaveModalConfirm = async (values: FormValues) => {
    if (!isLoading) {
      setIsLoading(true);

      try {
        const colorsToDelete = companyMetadata?.colors?.filter((color) => values.colors.every((item) => color.id !== item.id));
        const colorsToCreate = values.colors?.filter((color) => !color.id);
        const colorsToUpdate = values.colors?.filter((color) => {
          const fieldToCheck = companyMetadata?.colors?.find((item) => item.id === color.id);
          if (fieldToCheck) {
            return fieldToCheck.color !== color.color || fieldToCheck.color !== color.name;
          }

          return false;
        });

        if (colorsToDelete?.length) {
          const promises: Promise<ApiResponse<{code: number, message: string}>>[] = [];

          colorsToDelete.forEach((item) => {
            promises.push(Api.deleteCompanyMetadata(item.id!));
          });

          await Promise.all(promises);
        }

        if (colorsToUpdate.length) {
          const promises: Promise<ApiResponse<{code: number, message: string}>>[] = [];

          colorsToUpdate.forEach((item) => {
            promises.push(Api.updateCompanyMetadata(item.id!, [item.name, item.color].join('|||')));
          });

          await Promise.all(promises);
        }

        if (colorsToCreate.length) {
          const promises: Promise<ApiResponse<ApiMetadata>>[] = [];

          colorsToCreate.forEach((color) => {
            promises.push(Api.createCompaniesMetadata({ key: `color_${Math.random()}`, value: [color.name, color.color].join('|||') }, +id!));
          });

          await Promise.all(promises);
        }

        const logosToDelete = companyMetadata?.logos?.filter((logo) => values.logos.every((item) => logo.id !== item.id));
        const logosToCreate = values.logos?.filter((logo) => !logo.id);
        const logosToUpdate = values.logos?.filter((logo) => {
          const fieldToCheck = companyMetadata?.logos?.find((item) => item.id === logo.id);
          if (fieldToCheck) {
            return fieldToCheck.url !== logo.url || fieldToCheck.url !== logo.name;
          }

          return false;
        });

        if (logosToDelete?.length) {
          const promises: Promise<ApiResponse<{code: number, message: string}>>[] = [];

          logosToDelete.forEach((item) => {
            promises.push(Api.deleteCompanyMetadata(item.id!));
          });

          await Promise.all(promises);
        }

        if (logosToUpdate.length) {
          const promises: Promise<ApiResponse<{code: number, message: string}>>[] = [];

          logosToUpdate.forEach((item) => {
            promises.push(Api.updateCompanyMetadata(item.id!, [item.name, item.url].join('|||')));
          });

          await Promise.all(promises);
        }

        if (logosToCreate.length) {
          const promises: Promise<ApiResponse<ApiMetadata>>[] = [];

          logosToCreate.forEach((color) => {
            promises.push(Api.createCompaniesMetadata({ key: `image_${Math.random()}`, value: [color.name, color.url].join('|||') }, +id!));
          });

          await Promise.all(promises);
        }

        const fontsToDelete = companyMetadata?.fonts?.filter((font) => values.fonts.every((item) => font.id !== item.id));
        const fontsToCreate = values.fonts?.filter((font) => !font.id);
        const fontsToUpdate = values.fonts?.filter((font) => {
          const fieldToCheck = companyMetadata?.fonts?.find((item) => item.id === font.id);
          if (fieldToCheck) {
            return fieldToCheck.url !== font.url || fieldToCheck.url !== font.name;
          }

          return false;
        });

        if (fontsToDelete?.length) {
          const promises: Promise<ApiResponse<{code: number, message: string}>>[] = [];

          fontsToDelete.forEach((item) => {
            promises.push(Api.deleteCompanyMetadata(item.id!));
          });

          await Promise.all(promises);
        }

        if (fontsToUpdate.length) {
          const promises: Promise<ApiResponse<{code: number, message: string}>>[] = [];

          fontsToUpdate.forEach((item) => {
            promises.push(Api.updateCompanyMetadata(item.id!, [item.name, item.url].join('|||')));
          });

          await Promise.all(promises);
        }

        if (fontsToCreate.length) {
          const promises: Promise<ApiResponse<ApiMetadata>>[] = [];

          fontsToCreate.forEach((font) => {
            promises.push(Api.createCompaniesMetadata({ key: `font_${Math.random()}`, value: [font.name, font.url].join('|||') }, +id!));
          });

          await Promise.all(promises);
        }
      } catch (e) {
        setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
      } finally {
        if (!submitError) {
          setConfirmSaveModalOpen(false);
          setAfterSaveModal(true);
        }
        setIsLoading(false);
      }
    }
  };

  async function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setConfirmSaveModalOpen(true);

    setSubmitting(false);
  }

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="colors"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_design_colors_text')}</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleColorsEdit(!accessibleColorsEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <TransparentButton
                        handleClick={() => setModalVisible(true)}
                        text={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_add_color_button')}
                      />
                      { modalVisible
                        && (
                        <Modal onClose={() => setModalVisible(false)}>
                          <ColorPicker
                            color={color}
                            handleSubmit={(color: {rgb: {r: number, g: number, b: number, a?: number}, hex: string}) => {
                              setColor(color);
                              insert(0, {
                                color: (color.rgb.a === undefined || color.rgb.a === 1) ? color.hex : `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                                name: (color.rgb.a === undefined || color.rgb.a === 1) ? color.hex : `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`,
                              });
                              setModalVisible(false);
                            }}
                          />
                        </Modal>
                        )}
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.colors.map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={accessibleColorsEdit ? 'colorsWrapper edit' : 'colorsWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleColorsEdit ? (
                          <InputField
                            name={`colors[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`colors[${index}].name`, setErrorMessage, errorMessage)}
                            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_add_color_placeholder')}
                            value={values.colors[index].name}
                              // @ts-ignore*
                            error={typeof errorMessage === 'object' ? getErrorMessage(`colors[${index}].name`, errorMessage) : undefined}
                            extraBlockStyles={{ width: '300px' }}
                          />
                        ) : field.name}
                      </div>
                      <div className={accessibleColorsEdit ? 'fileWrapper fileWrapperEditMargin' : 'fileWrapper'}>
                        <div className="colorSquare" style={{ background: typeof field.color === 'string' ? field.color : `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})` }} />
                        {accessibleColorsEdit
                          && (
                            <button
                              type="button"
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </button>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
      </div>

      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="logos"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_design_logos_text')}</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleLogosEdit(!accessibleLogosEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <UploadFileButton
                        onChange={(file) => {
                          logoExamination(file, insert);
                        }}
                        name={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_logos_add_button')}
                        formats="image/*"
                      />
                    </div>
                  </div>
                </div>
                <div className="errorWrapper">
                  <span className="uploadError">{maxSizeError}</span>
                </div>
                <div className="arrayWrapper">
                  {values.logos.length > 0 && values.logos.map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={accessibleLogosEdit ? 'logosWrapper edit' : 'logosWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleLogosEdit ? (
                          <InputField
                            name={`logos[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`logos[${index}].name`, setErrorMessage, errorMessage)}
                            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_logos_add_placeholder')}
                            value={values.logos[index].name}
                            // @ts-ignore*
                            error={typeof errorMessage === 'object' ? getErrorMessage(`logos[${index}].name`, errorMessage) : undefined}
                            extraBlockStyles={{ width: '294px' }}
                          />
                        ) : <span>{field.name}</span>}
                      </div>
                      <div className={accessibleLogosEdit ? 'fileWrapper fileWrapperEditMargin' : 'fileWrapper'}>
                        <img className="logoImage" src={field instanceof File ? URL.createObjectURL(field) : field.url} alt="logo" />
                        {accessibleLogosEdit
                          && (
                            <button
                              type="button"
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </button>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
      </div>

      <div className="designWrapper">
        <div className="optionsWrapper">
          <FieldArray
            name="fonts"
            render={({ insert, remove, push }) => (
              <>
                <div className="headWrapper">
                  <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_design_fonts_text')}</span>
                  <div className="buttonsWrapper">
                    <button type="button" onClick={() => setAccessibleFontsEdit(!accessibleFontsEdit)}>
                      <PencilIcon />
                    </button>
                    <div className="addButtonWrapper">
                      <UploadFileButton
                        onChange={(file) => {
                          logoExamination(file, insert);
                        }}
                        name={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_add_font_button')}
                        formats=".ttf"
                      />
                    </div>
                  </div>
                </div>
                <div className="arrayWrapper">
                  {values.fonts.map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className={accessibleFontsEdit ? 'colorsWrapper edit' : 'colorsWrapper'} key={index}>
                      <div className="inputWrapper">
                        {accessibleFontsEdit ? (
                          <InputField
                            name={`fonts[${index}].name`}
                            onChange={setFieldValue}
                            onKeyUp={() => handleKeyUp(`fonts[${index}].name`, setErrorMessage, errorMessage)}
                            placeholder={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_add_color_placeholder')}
                            value={values.fonts[index].name}
                              // @ts-ignore*
                            error={typeof errorMessage === 'object' ? getErrorMessage(`fonts[${index}].name`, errorMessage) : undefined}
                            extraBlockStyles={{ width: '300px' }}
                          />
                        ) : field.name}
                      </div>
                      <div className={accessibleFontsEdit ? 'fileWrapper fileWrapperEditMargin' : 'fileWrapper'}>
                        <div />
                        {accessibleFontsEdit
                          && (
                            <button
                              type="button"
                              onClick={() => remove(index)}
                            >
                              <DeleteIcon />
                            </button>
                          )}
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          />
        </div>
      </div>

      <SubmitButton extraBlockStyles={{ maxWidth: '544px', width: '100%' }}>{getTranslationByLangOrEng(interfaceLanguage, 'save_button')}</SubmitButton>
      <span className="uploadError">{submitError}</span>
    </Form>
  );

  return (
    <CompanyDesignFormStyles>
      {afterSaveModal && (
        <CompaniesInfoModal
          onConfirm={() => setAfterSaveModal(false)}
          onClose={() => {
            setAfterSaveModal(false);
          }}
          onDecline={() => navigate('/companies')}
        />
      )}
      {!initialValues ? <Loader /> : (
        <Formik
          innerRef={companyDesignRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema}
        >
          {renderForm}
        </Formik>
      )}
      {confirmSaveModalOpen && (
      <ModalConfirmDecline
        onClose={() => setConfirmSaveModalOpen(false)}
        onConfirm={() => onSaveModalConfirm(companyDesignRef.current!.values)}
        onDecline={() => setConfirmSaveModalOpen(false)}
        confirmText={getTranslationByLangOrEng(interfaceLanguage, 'save_changes_button')}
        declineText={getTranslationByLangOrEng(interfaceLanguage, 'cancel_button')}
        title={getTranslationByLangOrEng(interfaceLanguage, 'companies_design_confirmation_before_save_modal_title')}
        error={!!dataSaveError}
        errorText={dataSaveError}
        firstButtonStyles={{ background: '#ff0000' }}
        secondButtonStyles={{ background: '#0E9285' }}
      />
      )}
    </CompanyDesignFormStyles>
  );
}
