import styled from 'styled-components';

export const CompaniesStyles = styled.div`  
  .customButtonsWrapper {
    display: flex;
    
    button {
      :not(:last-child) {
        margin-right: 20px;
      }
    }
  }
  
  .customField {
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: #000000;
    text-decoration: none;
    
    div {
      display: flex;
      justify-content: center;
      width: 171px;
      height: 32px;
      background: #F0F0F0;
      border: 1px solid #0E9285;
      box-sizing: border-box;
      border-radius: 4px;
      font-weight: 400;
      font-size: 14px;
      color: #0E9285;
      transition: 0.3s all;
      
      :hover {
        background: #0E9285;
        color: #F0F0F0;
      }
    }
  }
  
  .customNameWrapper {
    display: flex;
    align-items: center;
  }
  
  .editButtonsContainer {
    height: 100%;
    display: flex;
    justify-content: center;
    
    .editButtons {
      width: 140px;
      display: grid;
      grid-template-columns: 1fr 1fr 20px 1fr;
      align-items: center;

      .openButton {
        svg {
          height: 20px;
        }
      }

      button, a {
        display: flex;
        justify-content: center;
      }

      .splitter {
        height: 24px;
        display: flex;
        justify-content: center;

        > div {
          width: 1px;
          height: 100%;
          background-color: #eee;
        }
      }
    }
    a {
      display: flex;
      align-items: center;
    }
  }
  
  .editButtons {
    width: 70px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .searchWrapper {
    display: flex;
    align-items: center;
    padding: 0 19px;
    border-bottom: 1px solid #F0F0F0;

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    input::placeholder {
      font-size: 15px;
      color: #979797;
    }
  }
  
  .modalBody {
    .modalTitle {
      padding: 10px 16px;
      background: #F0F5F5;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;  
      color: #000000;
      border: 1px solid #F0F5F5;
      border-radius: 4px 4px 0px 0px;
    }
    
    .modalButtonsWrap {
      padding: 16px;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      border-radius: 0px 0px 4px 4px;
      
      .modalButtonsCaption {
        margin-bottom: 16px;
        font-size: 14px;
        color: #000000;
      }
      
      div {
        display: flex;
        justify-content: space-between;
      }
      
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 244px;
        height: 40px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        margin-bottom: 8px;
        
        :nth-child(1) {
          background: #FFFFFF;
          border: 1px solid #C4C4C4;
          color: #000000;
          
          :hover {
            color: #FFF;
            background: #C4C4C4;
          }
        }
        
        :nth-child(2) {
          border: 1px solid #FF0101;
          background: #FF0101;
          color: #FFFFFF;
          
          :hover {
            background: #FFFFFF;
            color: #FF0101;
          }
        }
      }
    }
    
    .modalError {
      font-size: 11px;
      line-height: 16px;
      color: #ff3333;
      margin-bottom: 0;
    }
  }
`;
