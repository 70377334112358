import styled from 'styled-components';

export const IssuesStyles = styled.div`
  .searchWrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;

    input {
      width: 100%;
      height: 48px;
      margin-left: 11px;
      border: none;
      outline: none;
    }

    input::placeholder {
      font-size: 15px;
      color: #979797;
    }
  }

  .splitter {
    width: 1px;
    background: #F0F0F0;
    height: 100%;
  }

  .tableFiltersWrapper {
    padding: 0 32px 0 16px;
    height: 50px;
    display: grid;
    grid-template-columns: 1fr auto auto auto auto;
    grid-gap: 40px;
    align-items: center;
    border-bottom: 1px solid #F0F0F0;

    .calendarWrapper {
      display: flex;
      gap: 10px;
      height: 100%;
      align-items: center;
      position: relative;

      .calendarOpenerWrapper {
        display: grid;
        grid-template-columns: repeat(3, auto);
        place-items: center;
        grid-gap: 10px;
        cursor: pointer;

        .active {
          transform: rotate(180deg);
        }
      }
    }

    .filtersWrapper {
      position: relative;

      .filtersOpener {
        cursor: pointer;
        display: grid;
        grid-gap: 10px;
        grid-template-columns: repeat(3, auto);
        align-items: center;

        &-active {
          svg:last-child {
            transform: rotate(180deg);
          }
        }
      }
    }
  }
`;
