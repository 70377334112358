export enum INodeConnectionTypes {
  always = 'always',
  if = 'if'
}

export interface INodesConnection {
  type: INodeConnectionTypes,
  selectedCase?: number,
  selectedField?: number,
  isOpen: boolean,
}
