import IApi from './IApi';
import {
  fakeReviewTypesResponse,
} from '../fakeData';
import { ChangeUserData, RegisterData, LoginData } from '../state';
import {
  mapApiAllTypeFieldsToIAllTypeFields,
  mapApiCompanyDocumentsToICompanyDocuments,
  mapApiDocumentToIDocument,
  mapApiExtraFieldToIExtraField,
  mapApiFormsToIFormListItems,
  mapApiFormToIForm,
  mapApiFormToIFormListItem,
  mapApiIssueStatusesToIIssueStatuses,
  mapApiIssueStatusToIIssueStatus,
  mapApiIssuesToIIssues,
  mapApiIssueToIIssue,
  mapApiIssueTypesToIIssueTypes,
  mapApiIssueTypeToIIssueType,
  mapApiLanguagesToILanguages,
  mapApiMetadataToICompany,
  mapApiPermissionsToIPermissions,
  mapApiPermissionToIPermission,
  mapApiReviewTypesToIReviewTypes,
  mapApiReviewTypeToIReviewType,
  mapApiSendButtonToISendButton,
  mapApiThankYouPageToIThankYouPage,
  mapApiUserRolesToIUserRoles,
  mapApiUserRoleToIUserRole,
  mapApiUsersToIUsers,
  mapApiUserToIUser,
  mapFromApiCompaniesToICompanies,
  mapFromApiCompanyToICompany,
  mapFromApiDocumentToIDocument,
  mapFromApiNodesToINodes,
  mapFromApiNodeToINode,
  mapFromApiReviewFormsToIReviewForms,
  mapFromFileToFormData,
  mapIDocumentToApiDocument,
  mapIExtraFieldToApiExtraField,
  mapIFormToApiForm,
  mapIIssueToApiIssue,
  mapIPermissionToApiPermission,
  mapISendButtonToApiSendButton,
  mapIThankYouPageToApiThankYouPage,
  mapIUserRoleToApiUserRole,
  mapImportDataToFormData,
  mapChatImg,
  mapFromApiReviewFormToIOneReviewForm,
  mapFromApiReviewFormsToIOneReviewForms,
} from './Mappers';
import {
  CompaniesMainInfo, ICompany,
  IDocument, IIssueType,
  IIssueStatus, IMultiTenancy,
  ICompanyUser, IColorScaleSettings,
  INumberScaleSettings, IStarScaleSettings,
  INode, IForm, IFormListItem,
  ICompanyDocument, IOneReviewForm, IReviewForm,
  ILanguage, IIssue, IUser, IUserRole,
  IPermission, IReviewDiscussionMessage,
  IReviewType, ICreateUser, IEdiUser,
  IReviewMessagesHistoryData, ISource,
  IExtraField, IFormDocument, ISendButton, IThankYouPage,
  IContact, ICompanyChatBot,
} from '../entities';
import { FormMessageValues } from '../pages/reviews/review/ReviewUtils';
import { formatMessageAllHistoryData, formatMessageHistoryData } from '../utils';
import { omit } from 'lodash';
import {
  ApiContacts, ApiCompany,
  ApiAuthResponse, ApiGoogleAccounts,
  ApiGoogleVerify, ApiDocument,
  ApiResponse, ApiUser,
  ApiNode, ApiGoogleLocations,
  ApiChatTemplates, ApiImportStatusData,
  ApiFile, ApiMetadata, ApiNotifiableSource, ApiCreateNotifiableSource, ApiMultiTenancy, ApiAudit,
} from './entities';

export default class Api implements IApi {
  private baseUrl: string = process.env.REACT_APP_API_HOST!;

  private async fetchData(path: string, requestOptions: any, isBlob?: boolean): Promise<any> {
    try {
      const response = await fetch(`${this.baseUrl}${path}`, { ...requestOptions });
      const statusCode = response.status;
      const data = isBlob && statusCode === 200 ? await response.blob() : await response.json();

      return {
        data,
        statusCode,
      };
    } catch (e) {
      throw new Error(`API Fetch error: ${e}`);
    }
  }

  private async getData(path: string, isBlob?: boolean, tokenRequired = true): Promise<any> {
    const myHeaders: { [key: string]: string } = {};

    if (tokenRequired) {
      const accessToken = localStorage.getItem('access_token');
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;
      headers: { [key: string]: string };
    } = {
      method: 'GET',
      redirect: 'follow',
      headers: myHeaders,
    };
    return this.fetchData(path, requestOptions, isBlob);
  }

  private async postData(
    path: string,
    data?: any,
    formData?: any,
  ): Promise<any> {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string,
      cache: string,
      credentials: string,
      referrerPolicy: string,
    } = {
      method: 'POST',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  private async putData(
    path: string,
    data: any,
    formData?: boolean,
  ) {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};
    if (!formData) {
      myHeaders['Content-Type'] = 'application/json';
    }

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string,
      cache: string,
      credentials: string,
      referrerPolicy: string,
    } = {
      method: 'PUT',
      headers: myHeaders,
      body: formData ? data : JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  private async patchData(
    path: string,
    data: any,
  ) {
    const myHeaders: { [key: string]: string } = {};

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string,
      cache: string,
      credentials: string,
      referrerPolicy: string,
    } = {
      method: 'PATCH',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  private async deleteData(path: string, data?: any): Promise<any> {
    const accessToken = localStorage.getItem('access_token');
    const myHeaders: { [key: string]: string } = {};

    if (accessToken) {
      myHeaders.Authorization = `Bearer ${accessToken}`;
    }

    const requestOptions: {
      method: string;
      headers: { [key: string]: string };
      body: string;
      redirect: 'follow' | 'error' | 'manual' | undefined;

      mode: string,
      cache: string,
      credentials: string,
      referrerPolicy: string,
    } = {
      method: 'DELETE',
      headers: myHeaders,
      body: JSON.stringify(data),
      redirect: 'follow',

      mode: 'cors', // no-cors, *cors, same-origin
      cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      credentials: 'same-origin', // include, *same-origin, omit
      referrerPolicy: 'no-referrer', // no-referrer, *client
    };

    return this.fetchData(path, requestOptions);
  }

  public async login(data: LoginData): Promise<ApiResponse<ApiAuthResponse>> {
    return this.postData('/login', data);
  }

  public async getMyAccount(): Promise<ApiResponse<ApiUser>> {
    return this.getData('/user/me');
  }

  public async changeUserData(data: ChangeUserData): Promise<ApiResponse<ApiUser>> {
    return this.putData(`/user/${data.id}`, data);
  }

  public async editUser(id: number, data: IEdiUser): Promise<ApiResponse<null>> {
    const { statusCode } = await this.putData(`/users/${id}`, data);

    return {
      data: null,
      statusCode,
    };
  }

  public async createUser(data: ICreateUser): Promise<ApiResponse<IUser>> {
    const { data: response, statusCode } = await this.postData('/users', data);

    return {
      data: mapApiUserToIUser(response),
      statusCode,
    };
  }

  public async attachRolesToUser(userId: number, roleIds: number[]): Promise<ApiResponse<null>> {
    const { statusCode } = await this.postData(`/user/${userId}/attach`, { roleIds: roleIds.map((role) => role) });

    return {
      data: null,
      statusCode,
    };
  }

  public async detachRolesFromUser(userId: number, roleIds: number[]): Promise<ApiResponse<null>> {
    const { statusCode } = await this.postData(`/user/${userId}/detach`, { roleIds: roleIds.map((role) => role) });

    return {
      data: null,
      statusCode,
    };
  }

  public async register(data: RegisterData): Promise<ApiResponse<ApiAuthResponse>> {
    return this.postData('/register', data);
  }

  public async getCompanies(): Promise<ApiResponse<CompaniesMainInfo[]>> {
    const companies = await this.getData('/companies');

    return {
      data: mapFromApiCompaniesToICompanies(companies.data),
      statusCode: companies.statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getCompanyById(id: number): Promise<ApiResponse<CompaniesMainInfo>> {
    const company = await this.getData(`/companies/${id}`);

    return {
      data: mapFromApiCompanyToICompany(company.data),
      statusCode: company.statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async createCompaniesMetadata(data: { key: string, value: string }, parentId: number): Promise<ApiResponse<ApiMetadata>> {
    return this.postData('/metadata/companies', { ...data, parentID: parentId });

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getCompanyMetadataByMetadataId(id: number): Promise<ApiResponse<Partial<ICompany>>> {
    const metaData = await this.getData(`/metadata/companies/${id}`);

    return {
      statusCode: metaData.statusCode,
      data: mapApiMetadataToICompany(metaData.data),
    };
    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateCompanyMetadata(id: number, data: string): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.putData(`/metadata/companies/${id}`, { value: data });

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteCompanyMetadata(id: number): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.deleteData(`/metadata/companies/${id}`);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  public async getCompanyMetadata(id: number): Promise<ApiResponse<ApiMetadata[]>> {
    return this.getData(`/companies/${id}/metadata`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getCompanyMetadataByCompanyId(id: number): Promise<ApiResponse<Partial<ICompany>>> {
    const metaDataFromServer = await this.getData(`/companies/${id}/metadata`);

    return {
      data: mapApiMetadataToICompany(metaDataFromServer.data),
      statusCode: metaDataFromServer.statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async createDocument(data: Partial<ApiDocument>): Promise<ApiResponse<ApiDocument>> {
    return this.postData('/documents', data);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getDocumentById(id: number): Promise<ApiResponse<IDocument>> {
    const document = await this.getData(`/documents/${id}`);

    return {
      statusCode: document.statusCode,
      data: mapFromApiDocumentToIDocument(document.data),
    };

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateDocument(data: {name: string, url: string, description: string}, id: number): Promise<ApiResponse<{code: number, message: string}>> {
    return this.putData(`/documents/${id}`, data);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteDocument(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await this.deleteData(`/documents/${id}`);

    return Promise.resolve({
      statusCode,
      data: null,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getCompanyLanguages(id: number): Promise<ApiResponse<ILanguage[]>> {
    return this.getData(`/companies/${id}/languages`);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateCompanyLanguages(id: number, data: ILanguage[]): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.putData(`/companies/${id}/languages`, { languageIDs: data.map((item) => item.id) });

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async createCompanyLanguages(id: number, data: ILanguage[]): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.postData(`/companies/${id}/languages`, { languageIDs: data.map((item) => item.id) });

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteCompanyLanguages(id: number): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.deleteData(`/companies/${id}/languages`);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  public async getFormById(id: number): Promise<ApiResponse<IForm>> {
    const { data, statusCode } = await this.getData(`/forms/${id}`);

    return {
      data: mapApiFormToIForm(data),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getFormFields(id: number): Promise<ApiResponse<{ extraFields?: IExtraField[];
    sendButton?: ISendButton;
    documents?: IFormDocument[];
    thankYouPage?: IThankYouPage;
  }>> {
    const { data, statusCode } = await this.getData(`/forms/${id}/fields`);

    return {
      data: mapApiAllTypeFieldsToIAllTypeFields(data),
      statusCode,
    };
  }

  public async getFormsByNode(id: number): Promise<ApiResponse<IFormListItem[]>> {
    const { data, statusCode } = await this.getData(`/nodes/${id}/forms`);

    return {
      data: mapApiFormsToIFormListItems(data),
      statusCode,
    };
  }

  public async getFormsByCompany(id: number): Promise<ApiResponse<IFormListItem[]>> {
    const { data, statusCode } = await this.getData(`/companies/${id}/forms`);

    return {
      data: mapApiFormsToIFormListItems(data),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async createCompany(data: { name: string, alias: string }): Promise<ApiResponse<ApiCompany>> {
    return this.postData('/companies', data);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async editCompanyMainInfo(data: { name: string, alias: string, id: number }): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.putData(`/companies/${data.id}`, { name: data.name, alias: data.alias });

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async editCompanyForm(data: IForm): Promise<ApiResponse<IForm>> {
    const mappedData = mapIFormToApiForm(data);

    const { data: response, statusCode } = await this.putData(`/forms/${data.id}`, mappedData);

    return {
      data: response,
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async editCompanyFormColorScale(data: IColorScaleSettings): Promise<ApiResponse<null>> {
    // return this.putData(`/company/${data.id}`, data);

    return Promise.resolve({ statusCode: 200, data: null });
  }

  // eslint-disable-next-line class-methods-use-this
  public async editCompanyFormNumberScale(data: INumberScaleSettings): Promise<ApiResponse<null>> {
    // return this.putData(`/company/${data.id}`, data);

    return Promise.resolve({ statusCode: 200, data: null });
  }

  // eslint-disable-next-line class-methods-use-this
  public async editCompanyFormStarScale(data: IStarScaleSettings): Promise<ApiResponse<null>> {
    // return this.putData(`/company/${data.id}`, data);

    return Promise.resolve({ statusCode: 200, data: null });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteCompany(id: number): Promise<ApiResponse<ApiCompany>> {
    return this.deleteData(`/companies/${id}`);

    // return Promise.resolve(fakeGetCompanyApiResponse);
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteCompanyNode(companyId: number, nodeId: number): Promise<ApiResponse<undefined>> {
    // return this.deleteData(`/company/${id}`);

    return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async getFormField(fieldId: number): Promise<ApiResponse<IExtraField>> {
    const { data, statusCode } = await this.getData(`/form_fields/${fieldId}`);

    return {
      data,
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async createField(data: IExtraField): Promise<ApiResponse<IExtraField>> {
    const { data: response, statusCode } = await this.postData('/form_fields', mapIExtraFieldToApiExtraField(data));

    return Promise.resolve({
      data: mapApiExtraFieldToIExtraField(response),
      statusCode,
    });
  }

  public async updateField(data: IExtraField): Promise<ApiResponse<IExtraField>> {
    const { data: response, statusCode } = await this.putData(`/form_fields/${data.id}`, mapIExtraFieldToApiExtraField(data));

    return Promise.resolve({
      data: response,
      statusCode,
    });
  }

  public async deleteField(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await this.deleteData(`/form_fields/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async createFormDocument(data: IFormDocument): Promise<ApiResponse<IFormDocument>> {
    const { data: response, statusCode } = await this.postData('/form_fields', mapIDocumentToApiDocument(data));

    return Promise.resolve({
      data: mapApiDocumentToIDocument(response),
      statusCode,
    });
  }

  public async updateFormDocument(data: IFormDocument): Promise<ApiResponse<IFormDocument>> {
    const { data: response, statusCode } = await this.putData(`/form_fields/${data.id}`, mapIDocumentToApiDocument(data));

    return Promise.resolve({
      data: response,
      statusCode,
    });
  }

  public async createFormSendButton(data: ISendButton): Promise<ApiResponse<ISendButton>> {
    const { data: response, statusCode } = await this.postData('/form_fields', mapISendButtonToApiSendButton(data));

    return Promise.resolve({
      data: mapApiSendButtonToISendButton(response),
      statusCode,
    });
  }

  public async updateFormSendButton(data: ISendButton): Promise<ApiResponse<ISendButton>> {
    const { data: response, statusCode } = await this.putData(`/form_fields/${data.id}`, mapISendButtonToApiSendButton(data));

    return Promise.resolve({
      data: response,
      statusCode,
    });
  }

  public async createThankYouPage(data: IThankYouPage): Promise<ApiResponse<IThankYouPage>> {
    const { data: response, statusCode } = await this.postData('/form_fields', mapIThankYouPageToApiThankYouPage(data));

    return Promise.resolve({
      data: mapApiThankYouPageToIThankYouPage(response),
      statusCode,
    });
  }

  public async updateThankYouPage(data: IThankYouPage): Promise<ApiResponse<IThankYouPage>> {
    const { data: response, statusCode } = await this.putData(`/form_fields/${data.id}`, mapIThankYouPageToApiThankYouPage(data));

    return Promise.resolve({
      data: response,
      statusCode,
    });
  }

  public async getNodeFeedbackForms(nodeId: number): Promise<ApiResponse<IFormListItem[]>> {
    const { data, statusCode } = await this.getData(`/nodes/${nodeId}/forms`);

    return {
      data: mapApiFormsToIFormListItems(data),
      statusCode,
    };
  }

  public async createFeedbackForm(data: IForm): Promise<ApiResponse<IFormListItem>> {
    const mappedData = mapIFormToApiForm(data);

    const { statusCode, data: response } = await this.postData('/forms', mappedData);

    return Promise.resolve({
      data: mapApiFormToIFormListItem(response),
      statusCode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteFeedbackForm(formId: number): Promise<ApiResponse<null>> {
    const { statusCode } = await this.deleteData(`/forms/${formId}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async getNodeByIdPartially(nodeId: number): Promise<ApiResponse<Partial<INode>>> {
    const { statusCode, data } = await this.getData(`/nodes/${nodeId}`);

    return Promise.resolve({
      statusCode,
      data: mapFromApiNodeToINode(data),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getNodeById(id: number): Promise<ApiResponse<INode>> {
    const { statusCode, data } = await this.getData(`/nodes/${id}/full`);

    return Promise.resolve({
      statusCode,
      data: mapFromApiNodeToINode(data),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getCompanyNodes(companyId: number): Promise<ApiResponse<INode[]>> {
    const { statusCode, data } = await this.getData(`/companies/${companyId}/nodes`);

    // console.log(data);

    return Promise.resolve({
      statusCode,
      data: mapFromApiNodesToINodes(data),
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async createNode(data: { companyID: number, name: string, code: string | undefined, parentID?: number, sourceID: number }): Promise<ApiResponse<INode>> {
    const { statusCode, data: response } = await this.postData('/nodes', data);

    return {
      statusCode,
      data: mapFromApiNodeToINode(response),
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateNode(nodeId: number, companyId: number, data: Partial<ApiNode>): Promise<ApiResponse<INode>> {
    const { statusCode, data: response } = await this.putData(`/nodes/${nodeId}`, { companyID: companyId, ...data });

    return {
      statusCode,
      data: mapFromApiNodeToINode(response),
    };
  }

  public async deleteNode(nodeId: number): Promise<ApiResponse<undefined>> {
    const { statusCode } = await this.deleteData(`/nodes/${nodeId}`);

    return Promise.resolve({ data: undefined, statusCode });
  }

  public async createNodeMetadata(data: { key: string, value: string, parentID: number }): Promise<ApiResponse<any>> {
    const { statusCode, data: response } = await this.postData('/metadata/nodes', data);

    return {
      statusCode,
      data: response,
    };
  }

  public async getNodeMetadataByMetadataId(id: number): Promise<ApiResponse<ApiMetadata>> {
    return this.getData(`/metadata/nodes/${id}`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async updateNodeMetadata(data: string, id: number): Promise<ApiResponse<{ code: number, message: string }>> {
    const { statusCode, data: response } = await this.putData(`/metadata/nodes/${id}`, { value: data });

    return {
      statusCode,
      data: response,
    };
  }

  public async deleteNodeMetadata(id: number): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.deleteData(`/metadata/nodes/${id}`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async getAllNodeMetadataByNodeId(id: number): Promise<ApiResponse<ApiMetadata[]>> {
    return this.getData(`/nodes/${id}/metadata`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async getReview(id: number | string): Promise<ApiResponse<IOneReviewForm>> {
    const { statusCode, data } = await this.getData(`/reviews/${id}`);

    return Promise.resolve({ data: mapFromApiReviewFormToIOneReviewForm(data), statusCode });
  }

  public async updateReview(id: number, data: { status?: number, comment?: string}): Promise<ApiResponse<IReviewForm>> {
    return this.putData(`/reviews/${id}`, { ...data });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getReviewDiscussionHistory(id: number): Promise<ApiResponse<IReviewMessagesHistoryData>> {
    const { statusCode, data } = await this.getData(`/reviews/${id}/chat`);

    return {
      data: formatMessageHistoryData(data),
      statusCode,
    };
  }

  public async getReviewAllDiscussionHistory(id: number): Promise<ApiResponse<IReviewMessagesHistoryData>> {
    const { statusCode, data } = await this.getData(`/reviews/${id}/chat/history`);

    // console.log(data)

    return {
      data: formatMessageAllHistoryData(data, id),
      statusCode,
    };
  }

  public async createReviewTypeByCompany(id: number, name: string, color: string): Promise<ApiResponse<IReviewType>> {
    const { data, statusCode } = await this.postData(`/reviews/${id}`);

    return {
      data: mapApiReviewTypeToIReviewType(data),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getCompanyReviewType(id: number): Promise<ApiResponse<IReviewType[]>> {
    // return this.getData(`/reviews/${id}`);

    return {
      data: mapApiReviewTypesToIReviewTypes(fakeReviewTypesResponse.data),
      statusCode: fakeReviewTypesResponse.statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async setReviewType(id: number, typeId: number | undefined): Promise<ApiResponse<null>> {
    // return this.postData(`/reviews/${id}`, message);

    return {
      data: null,
      statusCode: 200,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async textToReview(id: number, message: FormMessageValues): Promise<ApiResponse<IReviewDiscussionMessage>> {
    return this.postData(`/reviews/${id}/chat`, message);
  }

  public async getReviews(
    data: {
      form_id?: number,
      node_id?: number[],
      companyID?: number,
      status?: number[],
      source?: number[],
      from?: number,
      to?: number
      contacts?: string[]
    },
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<IOneReviewForm[]>> {
    const partOfQueryData = omit(data, ['status', 'source', 'node_id', 'contacts']);
    const statusesParameters = data?.status ? data?.status.map((id) => `status=${id}`).join('&') : '';
    const sourcesParameters = data?.source ? data?.source.map((id) => `source=${id}`).join('&') : '';
    const nodesParameters = data?.node_id ? data?.node_id.map((id) => `node_id=${id}`).join('&') : '';
    const contactsParameters = data?.contacts ? data?.contacts.map((key) => `contacts=${key}`).join('&') : '';
    const query = Object.entries(partOfQueryData).map((item) => item.join('=')).join('&');
    const path = `/reviews?offset=${offset || 0}&limit=${limit || 10}${query ? `&${query}` : ''}`
    + `${statusesParameters
      ? `&${statusesParameters}`
      : ''}${sourcesParameters
      ? `&${sourcesParameters}`
      : ''}${nodesParameters
      ? `&${nodesParameters}`
      : ''}${contactsParameters
      ? `&${contactsParameters}`
      : ''}`;

    const res = await this.getData(path);

    return Promise.resolve({
      data: mapFromApiReviewFormsToIOneReviewForms(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async postDataToGetFilteredReviews(
    data: {
      companyID?: number,
      forms?: number[],
      nodes?: number[],
      statuses?: number[],
      sources?: number[],
      from?: number,
      to?: number,
      contacts?: string[],
      search?: {
        clientID: string,
        phone: string,
      }
    },
    offset?: number,
    limit?: number,
  ): Promise<ApiResponse<IReviewForm[]>> {
    const res = await this.postData(`/reviews?offset=${offset || 0}&limit=${limit || 10}`, data);

    return Promise.resolve({
      data: mapFromApiReviewFormsToIReviewForms(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getTicketsByCompany(companyID: number): Promise<ApiResponse<IIssue[]>> {
    const { statusCode, data: response } = await this.getData(`/companies/${companyID}/tickets`);

    return Promise.resolve({
      data: mapApiIssuesToIIssues(response.data),
      statusCode,
      count: response.count,
    });
  }

  public async getTicket(id: number): Promise<ApiResponse<IIssue>> {
    const { statusCode, data } = await this.getData(`/tickets/${id}`);
    return Promise.resolve({ data: mapApiIssueToIIssue(data), statusCode });
  }

  public async createTicket(companyId: number, data: IIssue): Promise<ApiResponse<IIssue>> {
    const { statusCode, data: response } = await this.postData(`/companies/${companyId}/tickets`, mapIIssueToApiIssue(data));

    return {
      data: mapApiIssueToIIssue(response),
      statusCode,
    };
  }

  public async updateTicket(id: number | string, data: IIssue): Promise<ApiResponse<IIssue>> {
    return this.putData(`/tickets/${id}`, mapIIssueToApiIssue(data));
  }

  public async createFile(data: { file: File, width?: number, height?: number }): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.postData('/file', formDataFromData, true);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async getFile(id: number): Promise<ApiResponse<ApiFile>> {
    return this.getData(`/file/${id}`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async updateFile(id: number, data: { file: File, width?: number, height?: number }): Promise<ApiResponse<ApiFile>> {
    const formDataFromData = mapFromFileToFormData(data.file);
    return this.putData(`/file/${id}`, formDataFromData, true);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async deleteFile(id: number): Promise<ApiResponse<ApiFile>> {
    return this.deleteData(`/file/${id}`);

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async getCompanyDocuments(id: number): Promise<ApiResponse<ICompanyDocument[]>> {
    const { data, statusCode } = await this.getData(`/companies/${id}/documents`);

    return {
      data: mapApiCompanyDocumentsToICompanyDocuments(data),
      statusCode,
    };

    // return Promise.resolve({ data: undefined, statusCode: 200 });
  }

  public async getLanguages(offset?: number, limit?: number): Promise<ApiResponse<ILanguage[]>> {
    const path = `/languages?offset=${offset || 0}&limit=${limit || 10}`;
    const { data, statusCode } = await this.getData(path);

    return {
      data: (statusCode >= 200 && statusCode < 300) ? mapApiLanguagesToILanguages(data) : [],
      statusCode,
      count: data.count,
    };
  }

  public async createLanguage(data: {name: string}): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.postData('/languages', data);
  }

  public async deleteLanguage(data: {name: string}): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.deleteData('/languages', data);
  }

  public async getUsers(data: { node_id?: number, companyID?: number }, offset?: number, limit?: number): Promise<ApiResponse<IUser[]>> {
    const query = Object.entries(data).map((item) => item.join('=')).join('&');
    const res = await this.getData(`/user/list?offset=${offset || 0}&limit=${limit || 10}${query ? `&${query}` : ''}`);

    return Promise.resolve({
      data: mapApiUsersToIUsers(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getFilteredUsers(data: { email?: string, name?: string, q?: string }, offset?: number, limit?: number): Promise<ApiResponse<IUser[]>> {
    const query = Object.entries(data).map((item) => item.join('=')).join('&');
    const res = await this.getData(`/users?offset=${offset || 0}&limit=${limit || 10}${query ? `&${query}` : ''}`);

    return Promise.resolve({
      data: mapApiUsersToIUsers(res.data.data),
      statusCode: res.statusCode,
      count: res.data.count,
    });
  }

  public async getUser(id: number): Promise<ApiResponse<IUser>> {
    const { data, statusCode } = await this.getData(`/user/${id}`);

    return Promise.resolve({
      data: mapApiUserToIUser(data),
      statusCode,
    });
  }

  public async deleteUser(id: number): Promise<ApiResponse<null>> {
    return this.deleteData(`/users/${id}`)
  }

  public async getRoles(offset?: number, limit?: number): Promise<ApiResponse<IUserRole[]>> {
    const path = `/role/list?offset=${offset || 0}&limit=${limit || 10}`;
    const { data, statusCode } = await this.getData(path);

    return Promise.resolve({
      data: mapApiUserRolesToIUserRoles(data.data),
      statusCode,
      count: data.count,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getRole(id: number): Promise<ApiResponse<IUserRole>> {
    const { data, statusCode } = await this.getData(`/role/${id}`);

    return Promise.resolve({
      data: mapApiUserRoleToIUserRole(data),
      statusCode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteRole(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await this.deleteData(`/role/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateRole(id: number, data: IUserRole): Promise<ApiResponse<null>> {
    const { statusCode } = await this.putData(`/role/${id}`, mapIUserRoleToApiUserRole(data));

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async createRole(data: IUserRole): Promise<ApiResponse<IUserRole>> {
    const { data: response, statusCode } = await this.postData('/role', mapIUserRoleToApiUserRole(data));

    return Promise.resolve({
      data: mapApiUserRoleToIUserRole(response),
      statusCode,
    });
  }

  public async attachPermissionsToRole(roleId: number, permissionIds: number[]): Promise<ApiResponse<null>> {
    const { statusCode } = await this.postData(`/role/${roleId}/attach`, { permissionIds });

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async detachPermissionsFromRole(roleId: number, permissionIds: number[]): Promise<ApiResponse<null>> {
    const { statusCode } = await this.postData(`/role/${roleId}/detach`, { permissionIds });

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async getPermissions(offset?: number, limit?: number): Promise<ApiResponse<IPermission[]>> {
    const path = `/permission/list?offset=${offset || 0}&limit=${limit || 10}`;
    const { data, statusCode } = await this.getData(path);

    return Promise.resolve({
      data: mapApiPermissionsToIPermissions(data.data),
      statusCode,
      count: data.count,
    });
  }

  public async getPermission(id: number): Promise<ApiResponse<IPermission>> {
    const { data, statusCode } = await this.getData(`/permission/${id}`);

    return Promise.resolve({
      data: mapApiPermissionToIPermission(data),
      statusCode,
    });
  }

  public async deletePermission(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await this.deleteData(`/permission/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  public async updatePermission(id: number, data: IPermission): Promise<ApiResponse<IPermission>> {
    const { data: response, statusCode } = await this.putData(`/permission/${id}`, mapIPermissionToApiPermission(data));

    return Promise.resolve({
      data: mapApiPermissionToIPermission(response),
      statusCode,
    });
  }

  public async createPermission(data: IPermission): Promise<ApiResponse<IPermission>> {
    const { data: response, statusCode } = await this.postData('/permission', mapIPermissionToApiPermission(data));

    return Promise.resolve({
      data: mapApiPermissionToIPermission(response),
      statusCode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async getTicketStatusesByCompany(companyId: number): Promise<ApiResponse<IIssueStatus[]>> {
    const { data, statusCode } = await this.getData(`/companies/${companyId}/ticket_statuses`);

    return {
      data: mapApiIssueStatusesToIIssueStatuses(data),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async createTicketStatusByCompany(companyId: number, data: IIssueStatus): Promise<ApiResponse<IIssueStatus>> {
    const response = await this.postData(`/companies/${companyId}/ticket_statuses`, data);

    return {
      data: mapApiIssueStatusToIIssueStatus(response.data),
      statusCode: response.statusCode,
    };
  }

  public async deleteTicketStatus(id: number): Promise<ApiResponse<null>> {
    const res = await this.deleteData(`/ticket_statuses/${id}`);

    return Promise.resolve({
      data: null,
      statusCode: res.statusCode,
    });
  }

  public async updateTicketStatus(id: number, data: IIssueStatus): Promise<ApiResponse<IIssueStatus>> {
    const { data: response, statusCode } = await this.putData(`/ticket_statuses/${id}`, data);

    return {
      data: mapApiIssueStatusToIIssueStatus(response),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getTicketTypesByCompany(companyId: number): Promise<ApiResponse<IIssueType[]>> {
    const { data, statusCode } = await this.getData(`/companies/${companyId}/ticket_types`);

    return {
      data: mapApiIssueTypesToIIssueTypes(data),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getTicketsByReviewId(id: number): Promise<ApiResponse<IIssue[]>> {
    const { data, statusCode } = await this.getData(`/reviews/${id}/tickets`);

    return {
      data: mapApiIssuesToIIssues(data),
      statusCode,
    };
  }

  public async getContactsByReviewId(id: number): Promise<ApiResponse<IContact[]>> {
    const { data, statusCode } = await this.getData(`/reviews/${id}/contacts`);

    return {
      data,
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async createTicketTypeByCompany(companyId: number, data: IIssueType): Promise<ApiResponse<IIssueType>> {
    const { data: response, statusCode } = await this.postData(`/companies/${companyId}/ticket_types`, data);

    return {
      data: mapApiIssueTypeToIIssueType(response),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async deleteTicketType(id: number): Promise<ApiResponse<null>> {
    const { statusCode } = await this.deleteData(`/ticket_types/${id}`);

    return Promise.resolve({
      data: null,
      statusCode,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  public async updateTicketType(id: number, data: IIssueType): Promise<ApiResponse<IIssueType>> {
    const { data: response, statusCode } = await this.putData(`/ticket_types/${id}`, data);

    return {
      data: mapApiIssueTypeToIIssueType(response),
      statusCode,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  public async getTicketStatus(id: number): Promise<ApiResponse<IIssueStatus>> {
    return this.getData(`/ticket_statuses/${id}`);
  }

  // eslint-disable-next-line class-methods-use-this
  public async getTicketType(id: number): Promise<ApiResponse<IIssueType>> {
    return this.getData(`/ticket_types/${id}`);
  }

  public async getSourcesByCompanyId(companyId: number): Promise<ApiResponse<ISource[]>> {
    const { data, statusCode } = await this.getData(`/companies/${companyId}/sources`);

    return {
      data,
      statusCode,
    };
  }

  public async getSourceById(id: number): Promise<ApiResponse<ISource>> {
    const { statusCode, data } = await this.getData(`/sources/${id}`);

    return Promise.resolve({
      statusCode,
      data,
    });
  }

  public async createSource(source: { companyID: number, name: string }): Promise<ApiResponse<ISource>> {
    return this.postData('/sources', source);
  }

  public async updateSource(id: number, source: { companyID: number, name: string }): Promise<ApiResponse<ISource>> {
    return this.putData(`/sources/${id}`, source);
  }

  public async deleteSource(id: number): Promise<ApiResponse<any>> {
    return this.deleteData(`/sources/${id}`);
  }

  public async getChatTemplates(companyID: number): Promise<ApiResponse<ApiChatTemplates[]>> {
    return this.getData(`/companies/${companyID}/chat-templates`)
  }

  public async deleteChatTemplate(companyID: number, templateID: number): Promise<ApiResponse<any>> {
    return this.deleteData(`/companies/${companyID}/chat-templates/${templateID}`);
  }

  public async updateChatTemplate(companyID: number, templateID: number, data: {key: string, content: string}): Promise<ApiResponse<ApiChatTemplates>> {
    return this.putData(`/companies/${companyID}/chat-templates/${templateID}`, data);
  }

  public async createNewChatTemplate(companyID: number, data: {key: string, content: string}): Promise<ApiResponse<ApiChatTemplates>> {
    return this.postData(`/companies/${companyID}/chat-templates`, data)
  }

  public async importNodes(data: { file: File, sheet: string, companyID: string, width?: number, height?: number }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(data.file, data.sheet, data.companyID);
    return this.postData('/imports/nodes', formDataFromImportData, true);
  }

  public async importUsers(data: { file: File, sheet: string, width?: number, height?: number }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(data.file, data.sheet, undefined);
    return this.postData('/imports/users', formDataFromImportData, true);
  }

  public async importReviews(data: { file: File, sheet: string, width?: number, height?: number }): Promise<ApiResponse<any>> {
    const formDataFromImportData = mapImportDataToFormData(data.file, data.sheet, undefined);
    return this.postData('/imports/reviews', formDataFromImportData, true);
  }

  public async getCurrentImportStatus(): Promise<ApiResponse<ApiImportStatusData>> {
    return this.getData('/imports')
  }

  public async stopCurrentImport(): Promise<ApiResponse<any>> {
    return this.deleteData('/imports');
  }

  public async getReviewCount(companies: number[], statuses: number[]): Promise<ApiResponse<number>> {
    const formattedCompanies = `companies=${companies.join(',')}`
    const formattedStatuses = `statuses=${statuses.join(',')}`
    return this.getData(`/counts/review?${formattedCompanies}&${formattedStatuses}`)
  }

  public async sendChatImg(file: File): Promise<ApiResponse<{attachmentUrl: string, DeleteUrl: string, attachmentType: number}>> {
    const res = await this.postData('/reviews/chat/attachments', mapChatImg(file), true);
    return res;
  }

  public async sendSms(data: { phone: string, data: string }): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.postData('/sms', data);
  }

  public async sendMassMailingsSms(data: { phones: string[], data: string }): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.postData('/sms/bulk', data);
  }

  public async sendViberMessage(data: { phone: string, data: string }): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.postData('/viber', data);
  }

  public async sendMassMailingsViberMessage(data: { phones: string[], data: string }): Promise<ApiResponse<{ code: number, message: string }>> {
    return this.postData('/viber/bulk', data);
  }

  public async getUsersByCompany(id: number): Promise<ApiResponse<ICompanyUser[]>> {
    return this.getData(`/companies/${id}/users`)
  }

  public async getMultiTenancy(id: number): Promise<ApiResponse<IMultiTenancy>> {
    return this.getData(`/users/${id}/view`)
  }

  public async putMultiTenancy(id: number, data: ApiMultiTenancy): Promise<ApiResponse<any>> {
    return this.putData(`/users/${id}/view`, data);
  }

  public async exportNodes(companyID: number): Promise<ApiResponse<any>> {
    const { data, statusCode } = await this.getData(`/exports/nodes/${companyID}`, true);

    return {
      data,
      statusCode,
    }
  }

  public async exportUsers(): Promise<ApiResponse<any>> {
    return this.getData('/exports/users', true);
  }

  public async getUserContacts(id: number): Promise<ApiResponse<ApiContacts[]>> {
    return this.getData(`/user_contacts?userID=${id}`);
  }

  public async putContactNotification(id: number, data: {active: boolean}): Promise<ApiResponse<any>> {
    return this.putData(`/user_contacts/${id}`, data);
  }

  public async getCompanyChatBots(companyID: number): Promise<ApiResponse<ICompanyChatBot[]>> {
    return this.getData(`/companies/${companyID}/chat-bots`);
  }

  public async forgotPassword(email: string, language: string): Promise<ApiResponse<any>> {
    return this.postData('/auth/password-forgot', { email, languageCode: language });
  }

  public async passwordReset(data: { password: string, passwordConfirmation: string }, code: string): Promise<ApiResponse<any>> {
    return this.putData(`/auth/password-reset?code=${code}`, data);
  }

  public async saveGoogleToken(data: { accessCode: string, companyId: string, clientId: string }, id: number): Promise<ApiResponse<ApiGoogleAccounts[]>> {
    return this.postData(`/google/${id}/token`, data);
  }

  public async postGoogleLocations(id: number, data: { accountId: string, companyId: string }): Promise<ApiResponse<ApiGoogleLocations[]>> {
    return this.postData(`/google/${id}/locations`, data);
  }

  public async verifyGoogleLocations(id: number): Promise<ApiResponse<ApiGoogleVerify>> {
    return this.getData(`/google/${id}/verify`);
  }

  public async getNotifiableSource(id: number): Promise<ApiResponse<ApiNotifiableSource[]>> {
    return this.getData(`/users/${id}/notifiable-sources`);
  }

  public async postNotifiableSource(id: number, sourceId: number, data: ApiCreateNotifiableSource): Promise<ApiResponse<ApiNotifiableSource[]>> {
    return this.postData(`/users/${id}/notifiable-sources/${sourceId}`, data);
  }

  public async deleteNotifiableSource(id: number, sourceId: number): Promise<ApiResponse<any>> {
    return this.deleteData(`/users/${id}/notifiable-sources/${sourceId}`);
  }

  public async logoutGoogle(id: string): Promise<ApiResponse<any>> {
    return this.getData(`/google/${id}/logout`);
  }

  public async getReviewHistory(id: string): Promise<ApiResponse<ApiAudit[]>> {
    return this.getData(`/audit/clients/${id}`);
  }
}
