import styled from 'styled-components';

export const AnalyticStyles = styled.div`
  height: 100%;
  
  img {
    width: 100%;
  }

  .report {
    & div {
      height: calc(100vh - 200px);
    }
  }

  iframe {
    width: 100%;
    height: calc(100vh - 200px);
  }

  .companyFilterContainer {
    display: grid;
    grid-template-columns: 80px 300px;
    column-gap: 14px;
    padding-top: 30px;

    .companyFilterTitle {
      margin-top: 10px;
      justify-self: center;
    }
  }
`;
