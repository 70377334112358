import React, {
  CSSProperties, useEffect, useRef, useState,
} from 'react';
import { TextAreaAutoResizeStyles } from './TextAreaAutoResizeStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import TextareaAutosize from 'react-textarea-autosize';
import { ArrowDownIcon } from '../../../assets';
import { FormErrorMessage } from '../../atoms';

interface TextAreaProps {
  name: string;
  value: string | number | undefined;
  min?: number;
  max?: number;
  placeholder?: string;
  // eslint-disable-next-line no-unused-vars
  onChange: (event: any) => void;
  extraBlockStyles?: CSSProperties;
  onKeyUp?: () => void;
  disabled?: boolean;
  label?: string;
  showError?: boolean;
  error?: string | undefined;
  onKeyPress?: any;
  maxRows?: number;
  minRows?: number;
  withAutoResizeButton?: boolean;
}

function TextAreaAutoResize({
  name,
  placeholder,
  max,
  min,
  value,
  disabled,
  extraBlockStyles,
  onChange,
  onKeyUp,
  label,
  showError = true,
  error,
  minRows,
  maxRows,
  onKeyPress,
  withAutoResizeButton,
}: TextAreaProps) {
  const { touched } = useFormikContext();

  const [autoResize, setAutoResize] = useState<boolean>(false);
  const [isScroll, setIsScroll] = useState<boolean>(false);

  const ref = useRef<any>();

  function onChangeWithAutoResizeButton(event: any) {
    if (ref.current.scrollHeight > 38) {
      setIsScroll(true);
    } else if (isScroll === true) {
      setIsScroll(false);
    }

    onChange(event);
  }

  useEffect(() => {
    if (ref.current.scrollHeight > 38) {
      setIsScroll(true);
    }
  }, []);

  return (
    <TextAreaAutoResizeStyles style={extraBlockStyles}>
      {label && <span>{label}</span>}
      <div className={withAutoResizeButton ? 'withArrow wrapper' : 'wrapper'}>
        <TextareaAutosize
          ref={ref}
          value={value}
          onChange={withAutoResizeButton ? (event) => onChangeWithAutoResizeButton(event) : onChange}
          onKeyUp={onKeyUp}
          placeholder={placeholder}
          disabled={disabled}
          className={disabled ? 'disabled textArea' : 'textArea'}
          minLength={min}
          maxLength={max}
          onKeyPress={onKeyPress}
          maxRows={autoResize ? undefined : maxRows}
          minRows={minRows}
          id={name}
        />

        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
        {withAutoResizeButton && isScroll && (
        <button
          type="button"
          className={autoResize ? 'active' : ''}
          onClick={() => {
            if (!disabled) {
              setAutoResize(!autoResize);
            }
          }}
        >
          <ArrowDownIcon />
        </button>
        )}
      </div>

      {showError && (
        <div className="formErrorContainer">
          {error && <span>{error}</span>}
          {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
        </div>
      )}
    </TextAreaAutoResizeStyles>
  );
}

export default TextAreaAutoResize;
