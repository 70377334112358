import React, {
  MouseEvent as ReactMouseEvent,
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import ReactFlow, {
  Background, Controls, Edge, MiniMap, Node, OnInit, ReactFlowInstance, ReactFlowProvider,
} from 'reactflow';
import { FieldsListIcon } from '../diagramIcons/FieldsListIcon';
import { SettingsIcon } from '../diagramIcons/SettingsIcon';
import FormConstructorItem from '../formConstructorItem/FormConstructorItem';
import NodeSettingsForm from '../nodeSettingsForm/NodeSettingsForm';
import CustomDiagramNode from '../customDiagramNode/CustomDiagramNode';
import {
  useAppDispatch, useAppSelector, updateNotSavedDocument, updateNotSavedExtraField,
} from '../../../../state';
import {
  IExtraField, IFormDocument,
  IOption, INodesConnection,
  INodeSettings,
} from '../../../../entities';
import { getNodeConnections } from '../utils';
import { FormikHelpers } from 'formik';
import CustomDiagramEdge from '../customDiagramEdge/CustomDiagramEdge';
import { getTranslationByLangOrEng } from '../../../../i18n';
import { Loader, RefContext } from '../../../../components';

interface DiagramFormProps {
  nodes: Node[],
  edges: Edge[],
  setNodes: React.Dispatch<React.SetStateAction<Node<any>[]>>,
  setEdges: React.Dispatch<React.SetStateAction<Edge<any>[]>>,
  onNodesChange: (changes: any[]) => void,
  onEdgesChange: (changes: any[]) => void,
  reactFlowInstance: ReactFlowInstance<any, any> | undefined,
  setReactFlowInstance: OnInit<any, any> | undefined,
  setShowAddFieldList: (value: boolean) => void,
  setSelectedFieldIndex: (value: number | undefined) => void,
  setSelectedSectionType: (value: string | undefined) => void,
  setFieldsListChanged: React.Dispatch<React.SetStateAction<boolean>>,
  showFormSection: string | undefined,
  setShowFormSection: (value: string | undefined) => void,
  // selectedFieldIndex: number | undefined,
  // selectedFieldType: string | undefined,
}

const nodeTypes = {
  custom: CustomDiagramNode,
};

const edgeTypes = {
  custom: CustomDiagramEdge,
};

const DiagramForm = React.forwardRef<any, DiagramFormProps>(({
  nodes,
  edges,
  setNodes,
  setEdges,
  onNodesChange,
  onEdgesChange,
  reactFlowInstance,
  setReactFlowInstance,
  setShowAddFieldList,
  setSelectedFieldIndex,
  setSelectedSectionType,
  setFieldsListChanged,
  showFormSection,
  setShowFormSection,
}, ref) => {
  const dispatch = useAppDispatch();
  const {
    notSavedForm, notSavedExtraFields, notSavedDocuments,
  } = useAppSelector((state) => state.notSavedForm);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  // @ts-ignore
  const { refs } = useContext(RefContext);

  // Save form from parent component
  useImperativeHandle(ref, () => ({
    getAlert() {
      return nodes;
    },
  }));

  const [diagramPlacedFieldIds, setDiagramPlacedFieldIds] = useState<number[]>([]);
  const [nodesListChanging, setNodesListChanging] = useState<boolean>(false);
  const [clickedElement, setClickedElement] = useState<Node[]>([]);
  const [showDiagramInternalTab, setShowDiagramInternalTab] = useState<'fields' | 'settings'>('fields');
  const [nodeSettingsInitialized, setNodeSettingsInitialized] = useState<boolean>(false);
  const [nodeSettingsChanged, setNodeSettingsChanged] = useState<boolean>(false);
  const [clickedOnBackground, setClickedOnBackground] = useState<boolean>(false);

  const nodeSettingsInitialValuesRef = useRef<INodeSettings>();
  const selectedDiagramNodeRef = useRef<IExtraField | IFormDocument>();

  useEffect(() => {
    setDiagramPlacedFieldIds(nodes.map((item) => +item.id));
  }, []);

  const onConnect = useCallback(
    () => null,
    [],
  );

  const onDragStart = (event: React.DragEvent<HTMLDivElement>, nodeType: any, question: string | undefined) => {
    event.dataTransfer.setData('application/diagram-node-type', nodeType);
    event.dataTransfer.setData('application/diagram-node-question', question || '');
    // @ts-ignore
    event.dataTransfer.setData('application/diagram-node-id', event.target.id);
    event.dataTransfer.effectAllowed = 'move';

    setNodesListChanging(true);
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event) => {
      event.preventDefault();

      if ((refs.reactFlowWrapper as MutableRefObject<HTMLDivElement | null>)?.current && reactFlowInstance) {
        // @ts-ignore
        const reactFlowBounds = refs.reactFlowWrapper.current.getBoundingClientRect();
        const question = event.dataTransfer.getData('application/diagram-node-question');
        const type = event.dataTransfer.getData('application/diagram-node-type');
        const id = event.dataTransfer.getData('application/diagram-node-id');

        // check if the dropped element is valid
        if (typeof type === 'undefined' || !type) {
          return;
        }

        const position = reactFlowInstance.project({
          x: event.clientX - reactFlowBounds.left,
          y: event.clientY - reactFlowBounds.top,
        });
        const newNode = {
          id,
          type: 'custom',
          position,
          data: { label: `${type} node`, type, question },
        };

        setNodes((nds) => [...nds, newNode]);
        setDiagramPlacedFieldIds((prev) => [...prev, +id]);
      }

      setNodesListChanging(false);
    },
    [reactFlowInstance],
  );

  const onClickElement = useCallback((event: ReactMouseEvent, element: Node) => {
    // Set the clicked element in local state
    setClickedElement([element]);
  }, []);

  const onSelect = useCallback(({ selectedNodes, selectedEdges }: { selectedNodes: Node[], selectedEdges: Edge[] }, fields: (IExtraField | IFormDocument)[]) => {
    if (selectedNodes.length === 1) {
      const targetField = fields.find((item) => item.id === +selectedNodes[0].id);

      if (targetField !== undefined && selectedNodes.length === 1 && selectedNodes[0].selected) {
        setShowDiagramInternalTab('settings');
        const connectionsList = getNodeConnections(targetField);

        selectedDiagramNodeRef.current = targetField;
        nodeSettingsInitialValuesRef.current = connectionsList;
        setNodeSettingsChanged(!nodeSettingsChanged);
        setNodeSettingsInitialized(!nodeSettingsInitialized);
      }
    }
  }, []);

  // function selectNode() {
  //   if (selectedFieldIndex !== undefined && selectedFieldType !== undefined) {
  //     const diagramNode = (selectedFieldType === 'content' ? notSavedExtraFields : notSavedDocuments)[selectedFieldIndex];
  //     const diagramNodeId = diagramNode.id;
  //     const targetNode = nodes.find((node) => +node.id === diagramNodeId);

  //     if (targetNode) {
  //       setClickedElement([targetNode]);
  //       onSelect({ selectedNodes: [{ ...targetNode, selected: true }], selectedEdges: [] }, [...notSavedExtraFields || [], ...notSavedDocuments || []]);
  //     }
  //   }
  // }

  // useEffect(() => {
  //   setTimeout(() => selectNode(), 0);
  // }, [selectedFieldType]);

  function saveExtraFieldChangesLocaly(fields: (IExtraField | IFormDocument)[], values: INodeSettings) {
    const fieldIndex = fields.findIndex((item) => item.id === selectedDiagramNodeRef.current!.id);
    const connectionsMap = values.connections!
      .filter((item) => (item.type !== 'always' && item.selectedCase))
      .reduce((acc: {[key: number]: INodesConnection}, item) => {
        acc[item.selectedCase!] = item;
        return acc;
      }, {});

    if (fieldIndex !== undefined) {
      setFieldsListChanged((prev) => !prev);
      const targetField = { ...fields[fieldIndex] };
      // @ts-ignore
      let answers: IOption[] | undefined;

      if (values.fieldType === 'document') {
        dispatch(updateNotSavedDocument({
          ...targetField as IFormDocument,
          fieldID: values.connections!.find((item) => item.type === 'always')?.selectedField,
          fieldInSeries: values.inSeries,
        }));
      } else {
        answers = (targetField as IExtraField).answers ? [...(targetField as IExtraField).answers!] : [];

        answers = answers.map((item) => {
          if (item.id && (item.id in connectionsMap)) {
            return { ...item, nextField: connectionsMap[item.id].selectedField };
          }

          return { ...item, nextField: undefined };
        });

        dispatch(updateNotSavedExtraField({
          ...targetField as IExtraField,
          fieldID: values.connections!.find((item) => item.type === 'always')?.selectedField,
          fieldInSeries: values.inSeries,
          answers,
        }));
      }

      setFieldsListChanged((prev) => !prev);
    }
  }

  function onSubmitConnectionForm(
    values: INodeSettings,
    { setSubmitting }: FormikHelpers<INodeSettings>,
  ) {
    setSubmitting(false);

    // Step 1: change edges
    const nodeConnections: Edge[] = values.connections!.map((item) => ({
      id: `edge-${selectedDiagramNodeRef.current!.id}-${item.selectedField!}`,
      source: `${selectedDiagramNodeRef.current!.id}`,
      target: `${item.selectedField!}`,
      sourceHandle: null,
      targetHandle: null,
    }));

    setEdges((prev) => [...prev.filter((item) => item.source !== `${selectedDiagramNodeRef.current!.id}`), ...nodeConnections]);

    // Step 2: change field
    if (values.fieldType !== 'document' && notSavedExtraFields) {
      saveExtraFieldChangesLocaly(notSavedExtraFields, values);
    }

    if (values.fieldType === 'document' && notSavedDocuments) {
      saveExtraFieldChangesLocaly(notSavedDocuments, values);
    }
  }

  useEffect(() => {
    if (showDiagramInternalTab === 'settings' && clickedOnBackground) {
      setTimeout(() => {
        setShowDiagramInternalTab('fields');
        setClickedOnBackground(false);
      }, 0);
    }
  }, [showDiagramInternalTab, clickedOnBackground]);

  return (
    <div className="diagramWrapper">
      <ReactFlowProvider>
        <div className="diagramContainer" ref={refs.reactFlowWrapper}>
          <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            onConnect={onConnect}
            snapToGrid
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            fitView
            onPaneClick={showDiagramInternalTab === 'settings' ? () => setClickedOnBackground(true) : () => null}
            attributionPosition="top-right"
            onInit={setReactFlowInstance}
            onDrop={onDrop}
            onDragOver={onDragOver}
            onNodeClick={onClickElement}
            onSelectionChange={({ nodes, edges }) => (notSavedExtraFields && notSavedDocuments
              ? onSelect({ selectedNodes: nodes, selectedEdges: edges }, [...notSavedExtraFields || [], ...notSavedDocuments || []])
              : null
            )}
          >
            <MiniMap />
            <Controls />
            <Background />
          </ReactFlow>
        </div>
      </ReactFlowProvider>

      <div className="content">
        <div className="tabs">
          <div className={showDiagramInternalTab === 'fields' ? 'tab active' : 'tab'}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button
              type="button"
              onClick={() => {
                const changedNodesList = nodes.map((item) => {
                  const { selected, ...defaultSettings } = item;
                  return defaultSettings;
                });
                setNodes(changedNodesList);
                selectedDiagramNodeRef.current = undefined;
                setTimeout(() => setShowDiagramInternalTab('fields'), 0);
              }}
            >
              <FieldsListIcon height={16} color={showDiagramInternalTab === 'fields' ? '#0E9285' : '#ccc'} />
            </button>
          </div>
          <div className={showDiagramInternalTab === 'settings' ? 'tab active' : 'tab'}>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <button type="button" onClick={() => setShowDiagramInternalTab('settings')}>
              <SettingsIcon height={16} color={showDiagramInternalTab === 'settings' ? '#0E9285' : '#ccc'} />
            </button>
          </div>
        </div>

        {showDiagramInternalTab === 'fields' && notSavedForm ? (
          <div>
            <div
              className="formSectionTitle"
              onClick={() => setShowFormSection(showFormSection !== 'content' ? 'content' : undefined)}
            >
              <h4>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_content')}</h4>
            </div>

            {showFormSection === 'content' && (
              notSavedExtraFields!.filter((item) => !diagramPlacedFieldIds.includes(item.id!)).length > 0 ? (
                <div className="formExtraFields">
                  <div className="extraFieldsList">
                    {notSavedExtraFields!.filter((item) => !diagramPlacedFieldIds.includes(item.id!)).map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <div
                        id={`${field.id}` || ''}
                        key={field.id}
                        className="extraFieldContainer"
                        onDragStart={(event) => onDragStart(event, field.type, field.question)}
                        draggable
                      >
                        <FormConstructorItem
                          type={field.type}
                          handleClick={() => {
                            setShowAddFieldList(false);
                            setSelectedFieldIndex(index);
                            setSelectedSectionType('content');
                          }}
                          previewText={field.question || ''}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_diagram_fields_list_is_empty')}</p>
              )
            )}

            <div
              className="formSectionTitle"
              onClick={() => setShowFormSection(showFormSection !== 'documents' ? 'documents' : undefined)}
            >
              <h4>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_fields_form_documents')}</h4>
            </div>

            {showFormSection === 'documents' && (
              notSavedDocuments!.filter((item) => !diagramPlacedFieldIds.includes(item.id!)).length > 0 ? (
                <div className="formExtraFields">
                  <div className="extraFieldsList">
                    {notSavedDocuments!.filter((item) => !diagramPlacedFieldIds.includes(item.id!)).map((field, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                      <div
                        id={`${field.id}` || ''}
                        key={field.id}
                        className="extraFieldContainer"
                        onDragStart={(event) => onDragStart(event, field.type, field.description)}
                        draggable
                      >
                        <FormConstructorItem
                          type={field.type}
                          handleClick={() => {
                            setShowAddFieldList(false);
                            setSelectedFieldIndex(index);
                            setSelectedSectionType('documents');
                          }}
                          previewText={field.description || ''}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <p>{getTranslationByLangOrEng(interfaceLanguage, 'form_constructor_diagram_fields_list_is_empty')}</p>
              )
            )}
          </div>
        ) : (
          <div className="settings">
            {(selectedDiagramNodeRef.current === undefined || nodeSettingsInitialValuesRef.current === undefined)
              ? 'No selected node'
              : notSavedExtraFields ? (
                <NodeSettingsForm
                  onSubmitConnectionForm={onSubmitConnectionForm}
                  selectedDiagramNodeId={selectedDiagramNodeRef.current.id!}
                  selectedDiagramNode={selectedDiagramNodeRef.current}
                  diagramPlacedFieldIds={diagramPlacedFieldIds}
                  initialValues={nodeSettingsInitialValuesRef.current}
                />
              ) : <Loader />}
          </div>
        )}
      </div>
    </div>
  );
});

export default DiagramForm;
