import React, { Dispatch, SetStateAction } from 'react';
import { Api, UpdateType } from '../../../api';
import { ArrowDownIcon } from '../../../assets';
import { DeepArrayItem } from '../../../entities/DeepArrayItem';
import { CheckboxSquare } from '../../form/squareCheckbox/checkboxSquare/CheckboxSquare';
import { MultiSelectNodeItemStyles } from './MultiSelectNodeItemStyles';

interface SelectItemProps {
  path: number[],
  option: DeepArrayItem,
  selectKey: string,
  inputName: string,
  setOpen: (open: boolean) => void,
  expandable?: boolean,
  handleExpandRow?: (id: number) => void,
  expandedRows?: {[key: number]: boolean},
  childrenKey?: string,
  selectable?: boolean,
  checkboxSelected?: boolean,
  checkboxHasSelectedChildren?: boolean,
  checkboxAllChildrenSelected?: boolean,
  reQuery: boolean;
  setReQuery: Dispatch<SetStateAction<boolean>>;
  id: number;
  tree: boolean;
}

export const MultiSelectNodeItem = ({
  path,
  option,
  selectKey,
  inputName,
  expandable,
  handleExpandRow,
  expandedRows,
  childrenKey,
  setOpen,
  selectable,
  checkboxSelected,
  checkboxHasSelectedChildren,
  checkboxAllChildrenSelected,
  reQuery,
  setReQuery,
  id,
  tree,
}: SelectItemProps) => {
  const handleAttach = (nodeID: number) => {
    const reqBody = {
      attachNodeIDs: [nodeID],
      updateType: tree ? UpdateType.Tree : UpdateType.Strict,
    }

    Api.putMultiTenancy(id, reqBody).then((res) => {
      setReQuery(!reQuery)
    });
  }

  const handleDetach = (nodeID: number) => {
    const reqBody = {
      detachNodeIDs: [nodeID],
      updateType: tree ? UpdateType.Tree : UpdateType.Strict,
    }

    Api.putMultiTenancy(id, reqBody).then((res) => {
      setReQuery(!reQuery)
    });
  }

  return (
    <MultiSelectNodeItemStyles
        // @ts-ignore
      key={`selectOption:${option.id}:${option[selectKey]}`}
      selectable={!!selectable}
    >
      <div className="rowItem">
        <input
          autoComplete="false"
          type="radio"
          name={inputName}
            // @ts-ignore
          value={option[selectKey]}
            // @ts-ignore
          id={`${option[selectKey]}:${option.id}`}
        />
        <div className="labelContent" style={{ paddingLeft: 10 + option.deep * 10, textAlign: option.name === '-' ? 'center' : 'left' }}>
          {selectable && (
            <button
              onClick={() => (checkboxSelected ? handleDetach(option.id) : handleAttach(option.id))}
              type="button"
              style={{ marginRight: 8 }}
            >
              <CheckboxSquare
                selected={!!checkboxSelected}
                hasSelectedChildren={checkboxHasSelectedChildren}
                allChildrenAreSelected={checkboxAllChildrenSelected}
                handleOnClick={() => null}
              />
            </button>
          )}
          {/* @ts-ignore */}
          <button style={{ textAlign: 'left' }} onClick={() => (handleExpandRow && handleExpandRow(option.id))} type="button">{option[selectKey]}</button>
        </div>
        {expandable && option.children.length > 0 && (
          // eslint-disable-next-line jsx-a11y/control-has-associated-label
          <button className="expandButton" type="button" onClick={() => (handleExpandRow && handleExpandRow(option.id))}><ArrowDownIcon /></button>
        )}
      </div>

      {expandable
          // @ts-ignore
          && expandedRows![option.id]
          && !!childrenKey
          // @ts-ignore
          && option[childrenKey]?.map((subItem: DeepArrayItem, index) => (
            <MultiSelectNodeItem
              tree={tree}
              id={id}
              setReQuery={setReQuery}
              reQuery={reQuery}
              key={`itemKey:${[index]}`}
              path={[...path, index]}
              option={subItem}
              selectKey={selectKey}
              childrenKey={childrenKey}
              inputName={`${inputName}:${subItem.id}`}
              expandedRows={expandedRows}
              expandable={expandable && subItem.children.length > 0}
              handleExpandRow={handleExpandRow}
              setOpen={setOpen}
              selectable={selectable}
              checkboxSelected={selectable ? subItem.selected : undefined}
              checkboxHasSelectedChildren={selectable ? subItem.hasSelectedChildren : undefined}
              checkboxAllChildrenSelected={selectable ? subItem.allChildrenAreSelected : undefined}
            />
          ))}
    </MultiSelectNodeItemStyles>
  )
}
