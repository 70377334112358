import * as Yup from 'yup';
import { every } from 'lodash';
import { getTranslationByLangOrEng, translations } from '../../i18n';

export const extraFieldValidationSchema = {
  title: Yup.object().shape({
    question: Yup.string().required('* Required'),
  }),
  name: Yup.object().shape({
    question: Yup.string().required('* Required'),
  }),
  email: Yup.object().shape({
    question: Yup.string().required('* Required'),
  }),
  phone_number: Yup.object().shape({
    question: Yup.string().required('* Required'),
  }),
  extra_question: Yup.object().shape({
    question: Yup.string().required('* Required'),
  }),
  checkbox: Yup.object().shape({
    question: Yup.string().required('* Required'),
    answers: Yup.array().min(2, '* Вопрос должен иметь минимум 2 варианта ответа'),
  }),
  radio: Yup.object().shape({
    question: Yup.string().required('* Required'),
    answers: Yup.array().min(2, '* Вопрос должен иметь минимум 2 варианта ответа'),
  }),
  scale: Yup.object().shape({
    question: Yup.string().required('* Required'),
    selectedOption: Yup.string().required('* Required'),
  }),
  upload_files: Yup.object().shape({
    options: Yup.array()
      .of(
        Yup.object().shape({
          selected: Yup.boolean(),
        }),
      )
      .test({
        name: 'one-true',
        message: '* Вы должны выбрать разрешённые для загрузки типы файлов или удалить это поле из формы',
        test: (val) => !every(val, ['selected', false]),
      }),
  }),
  networks: Yup.object().shape({
    options: Yup.array()
      .of(
        Yup.object().shape({
          selected: Yup.boolean(),
        }),
      )
      .test({
        name: 'one-true',
        message: '* Вы должны выбрать доступные вам мессенджеры для ответа на вопросы опрашиваемых',
        test: (val) => !every(val, ['selected', false]),
      }),
  }),
  quantity: Yup.object().shape({
    question: Yup.string().required('* Required'),
    minValue: Yup.string().required('* Required'),
    maxValue: Yup.string(),
    defaultValue: Yup.string(),
  }),
  documents: Yup.array().of(Yup.object().shape({
    selected: Yup.object().shape({
      id: Yup.number().required('* Required'),
    }),
    description: Yup.string().required('* Required'),
  })).min(1, '* Form should have min one document'),
};

export const sendButtonValidationSchema = (lang: string) => Yup.object().shape({
  text: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  textColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
  backgroundColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
});

export const thankYouPageValidationSchema = (lang: string) => Yup.object().shape({
  title: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  text: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  backgroundColor: Yup.object(),
  displayMainScaleBackgroundColor: Yup.boolean(),
  mascot: Yup.object(),
  displayMainScaleMascot: Yup.boolean(),
  buttonText: Yup.string(),
  buttonTextColor: Yup.object().when('buttonText', {
    is: (selectedType: string) => selectedType !== undefined,
    then: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.object(),
  }),
  buttonBackgroundColor: Yup.object().when('buttonText', {
    is: (selectedType: string) => selectedType !== undefined,
    then: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.object(),
  }),
  buttonLink: Yup.string().when('buttonText', {
    is: (selectedType: string) => selectedType !== undefined,
    then: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
    otherwise: Yup.string(),
  }),
});
