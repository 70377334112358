export const parseDatetime = (datetimeStr: string): string => {
  const date = new Date(datetimeStr);
  const hours = date.getUTCHours().toString().length < 2 ? `0${date.getUTCHours()}` : date.getUTCHours();
  const minutes = date.getUTCMinutes();
  return `${hours}:${minutes.toString().padStart(2, '0')}`;
}

export const formatDatetime = (timeStr: string): string => {
  const currentDate = new Date();
  const [hours, minutes] = timeStr.split(':');
  const formattedDate = currentDate.toISOString().split('T')[0];
  return `${formattedDate}T${hours}:${minutes}:00Z`;
}

export const validateTimeInput = (input: string): boolean => {
  const regex = /^\d{1,2}:\d{2}$/;
  return regex.test(input);
}
