import * as Yup from 'yup';
import { CompaniesMainInfo } from '../../../entities/ICompany';
import { getTranslationByLangOrEng } from '../../../i18n';

export interface FormValues {
  entity: { name: string } | undefined;
  company: CompaniesMainInfo | undefined;
}

export const validationSchema = (lang: string) => Yup.object({
  entity: Yup.object().required(
    getTranslationByLangOrEng(lang, 'validation_required'),
  ),
  company: Yup.object().when('entity', {
    is: (entity: { name: string }) => entity && entity.name === 'Nodes',
    then: Yup.object().required(
      getTranslationByLangOrEng(lang, 'validation_required'),
    ),
    otherwise: Yup.object(),
  }),
});
