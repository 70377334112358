import React, {
  CSSProperties, useEffect, useState,
} from 'react';
import { IExtraField } from '../../../entities/IExtraField';
import {
  ErrorMessage, FieldArray, Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { Api } from '../../../api';
import { getTranslationByLangOrEng } from '../../../i18n';
import {
  updateNotSavedExtraField, setSelectedScaleType, useAppDispatch, useAppSelector,
} from '../../../state';
import { mapIColorsToStringsArray, mapIColorToString } from '../../../mappers';
import RadioButton from '../../form/radioButton/RadioButton';
import ColorScale from '../../form/scales/colorScale/ColorScale';
import NumberScale from '../../form/scales/numberScale/NumberScale';
import StarScale from '../../form/scales/starScale/StarScale';
import Checkbox from '../../form/checkbox/Checkbox';
import SquareCheckbox from '../../form/squareCheckbox/SquareCheckbox';
import FieldSaveButton from '../fieldSaveButton/FieldSaveButton';
import { extraFieldValidationSchema } from '../validationSchemas';
import StepTextSettings from '../stepTextSettings/StepTextSettings';
import InputRaw from '../inputRaw/InputRaw';
import NpsScale from '../../form/scales/npsScale/NpsScale';
import { useForwardRef } from '../../../hooks';
import { getErrorMessage, handleKeyUp } from '../../../utils';
import { FormErrorMessage, Loader } from '../../atoms';

interface ScaleFieldFormProps {
  selectedTab: string;
  index: number;

  keyQuestionCheckboxOnChange?: () => void;
  keyQuestionCheckboxExtraBlockStyles?: CSSProperties;

  mainScaleCheckboxOnChange?: () => void;
  mainScaleCheckboxExtraBlockStyles?: CSSProperties;

  resetForm?: (values: IExtraField) => void;
  formReinitialized?: boolean;
}

const ScaleFieldForm = React.forwardRef<FormikProps<IExtraField>, ScaleFieldFormProps>(({
  selectedTab,
  index,
  keyQuestionCheckboxOnChange,
  keyQuestionCheckboxExtraBlockStyles,
  mainScaleCheckboxOnChange,
  mainScaleCheckboxExtraBlockStyles,
  resetForm,
  formReinitialized,
}, ref) => {
  const dispatch = useAppDispatch();

  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const { notSavedForm, notSavedExtraFields } = useAppSelector((state) => state.notSavedForm);

  const [initialValues, setInitialValues] = useState<IExtraField>();
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitSuccess, setSubmitSuccess] = useState<string>('');
  const [submitError, setSubmitError] = useState<string>('');
  const [isLoading, setIsLoading] = useState(false);

  const formRef = useForwardRef<FormikProps<IExtraField>>(ref);

  useEffect(() => () => {
    if (formRef.current) {
      dispatch(updateNotSavedExtraField(formRef.current.values));
    }
  }, [selectedTab]);

  useEffect(() => {
    if (notSavedExtraFields) {
      setInitialValues(notSavedExtraFields[index]);
      dispatch(setSelectedScaleType(notSavedExtraFields[index].selectedOption));
    }
  }, [index, formReinitialized]);

  async function onSubmit(
    values: IExtraField,
    { setSubmitting }: FormikHelpers<IExtraField>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    const res = await Api.updateField(values);

    if (res.statusCode >= 200 && res.statusCode < 300) {
      setSubmitSuccess(getTranslationByLangOrEng(interfaceLanguage, 'data_save_success'));
    } else {
      setSubmitError(getTranslationByLangOrEng(interfaceLanguage, 'data_save_error'));
    }

    dispatch(updateNotSavedExtraField(values));
    // @ts-ignore
    if (ref?.current) {
      // @ts-ignore
      resetForm({ ...ref.current?.values });
    }
    setIsLoading(false);
  }

  function renderForm({
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
  }: FormikProps<IExtraField>) {
    const titleError = typeof errorMessage === 'object' ? getErrorMessage('question', errorMessage) : undefined;
    const signatureMinValueNameError = typeof errorMessage === 'object' ? getErrorMessage('signatureMin', errorMessage) : undefined;
    const signatureMaxValueNameError = typeof errorMessage === 'object' ? getErrorMessage('signatureMax', errorMessage) : undefined;
    const optionsError = typeof errorMessage === 'object' ? getErrorMessage('options', errorMessage) : undefined;

    const colors = {
      colorScale: {
        colors: values.scaleGradientColors?.length ? mapIColorsToStringsArray(values.scaleGradientColors) : ['#EB5757', '#EF9644', '#F4E02D', '#18ECD6'],
        pointerColor: values.scalePointerColor ? mapIColorToString(values.scalePointerColor) : '#EB5757',
      },
      numberScale: {
        numbersColor: values.numbersColor ? mapIColorToString(values.numbersColor) : '#000',
        selectedButtonColor: values.selectedButtonColor ? mapIColorToString(values.selectedButtonColor) : '#eee',
      },
      starScale: {
        selectedStarColor: values.selectedStarColor ? mapIColorToString(values.selectedStarColor) : '#FF8C19',
        notSelectedStarBackground: values.notSelectedStarBackground ? mapIColorToString(values.notSelectedStarBackground) : '#fff',
        notSelectedStarBorder: values.notSelectedStarBorder ? mapIColorToString(values.notSelectedStarBorder) : '#FF8C19',
      },
      npcScale: {},
    };

    const maxValues = [
      values.maxValue ? +values.maxValue : 11,
      values.maxValue ? +values.maxValue : 5,
      values.maxValue ? +values.maxValue : 5,
      values.maxValue ? +values.maxValue : 11,
    ];
    const defaultValues = [
      (values.defaultValue ? +values.defaultValue : 1) || 1,
      values.defaultValue,
      values.defaultValue,
      values.defaultValue,
    ];

    return (
      <Form>
        <div className="body">
          <InputRaw
            name="question"
            value={values.question || ''}
            setFieldValue={setFieldValue}
            error={titleError}
            touched={touched}
            handleKeyUp={handleKeyUp}
            setErrorMessage={setErrorMessage}
            errorMessage={errorMessage}
            translationKeys={['companies_form_constructor_title_title', 'companies_form_constructor_title_placeholder', 'companies_form_constructor_info_question']}
          />

          <div className="signatureContainer">
            <h3>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_signature_label')}</h3>
            <div className="signatureValueContainer">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_signature_min_label')}</span>

              <InputRaw
                name="signatureMin"
                value={values.signatureMin || ''}
                setFieldValue={setFieldValue}
                error={signatureMinValueNameError}
                touched={touched}
                handleKeyUp={handleKeyUp}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
                translationKeys={[undefined, 'companies_form_constructor_min_placeholder', 'companies_form_constructor_info_scale_min']}
              />
            </div>
            <div className="signatureValueContainer">
              <span>{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_signature_max_label')}</span>

              <InputRaw
                name="signatureMax"
                value={values.signatureMax || ''}
                setFieldValue={setFieldValue}
                error={signatureMaxValueNameError}
                touched={touched}
                handleKeyUp={handleKeyUp}
                setErrorMessage={setErrorMessage}
                errorMessage={errorMessage}
                translationKeys={[undefined, 'companies_form_constructor_max_placeholder', 'companies_form_constructor_info_scale_max']}
              />
            </div>
          </div>

          <div className="addOption">
            <FieldArray
              name="options"
              render={() => (
                <div className="options">
                  {values.options?.map((field: any, subIndex: number) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div className="radioFieldContainer" key={subIndex}>
                      <RadioButton
                        name="selectedOption"
                        value={values.options![subIndex].value}
                        onChange={(event) => {
                          setFieldValue('selectedOption', event.target.value);
                          dispatch(setSelectedScaleType(event.target.value));
                          dispatch(updateNotSavedExtraField({ ...notSavedExtraFields[index], selectedOption: event.target.value }));
                        }}
                        radioButtonWrapperStyles={{ alignItems: 'center' }}
                        customizedRadioContainerStyles={{ marginBottom: 24 }}
                        checked={values.selectedOption === values.options![subIndex].value}
                      >
                        {field.value === 'color' && (
                          <ColorScale
                            colors={colors.colorScale.colors}
                            pointerColor={colors.colorScale.pointerColor}
                            maxValue={maxValues[0]}
                            defaultValue={defaultValues[0]!}
                            signatures={[values.signatureMin || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_min_placeholder'),
                              values.signatureMax || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_max_placeholder')]}
                            signatureColor={values.signatureColor?.color as string}
                          />
                        )}
                        {field.value === 'number' && (
                          <NumberScale
                            numbersColor={colors.numberScale.numbersColor}
                            selectedButtonColor={colors.numberScale.selectedButtonColor}
                            maxValue={maxValues[1]}
                            defaultValue={defaultValues[1]}
                            showDefaultValue={values.showDefaultValue!}
                            signatures={[values.signatureMin || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_min_placeholder'),
                              values.signatureMax || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_max_placeholder')]}
                            signatureColor={values.signatureColor?.color as string}
                          />
                        )}
                        {field.value === 'star' && (
                          <StarScale
                            selectedStarColor={colors.starScale.selectedStarColor}
                            notSelectedStarBorder={colors.starScale.notSelectedStarBorder}
                            notSelectedStarBackground={colors.starScale.notSelectedStarBackground}
                            maxValue={maxValues[2]}
                            defaultValue={defaultValues[2]}
                            showDefaultValue={values.showDefaultValue!}
                            signatures={[values.signatureMin || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_min_placeholder'),
                              values.signatureMax || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_max_placeholder')]}
                            signatureColor={values.signatureColor?.color as string}
                          />
                        )}
                        {field.value === 'nps' && (
                          <NpsScale
                            defaultValue={defaultValues[1]}
                            showDefaultValue={values.showDefaultValue!}
                            signatures={[values.signatureMin || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_min_placeholder'),
                              values.signatureMax || getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_max_placeholder')]}
                            signatureColor={values.signatureColor?.color as string}
                          />
                        )}
                      </RadioButton>
                    </div>
                  ))}
                  <div className="formErrorContainer">
                    {optionsError && <span>{optionsError}</span>}
                    {!optionsError && touched && <ErrorMessage name="options" component={FormErrorMessage} />}
                  </div>
                </div>
              )}
            />
          </div>

          {notSavedForm?.withSteps && values.type !== 'title' && (
            <StepTextSettings
              stepsText={values.stepsText || ''}
              handleKeyUp={handleKeyUp}
              setFieldValue={setFieldValue}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
            />
          )}

          <Checkbox
            name="required"
            value={values.required!}
            onChange={handleChange}
          >
            <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_required_question')}</span>
          </Checkbox>

          {keyQuestionCheckboxOnChange && (
            <SquareCheckbox
              name="keyQuestion"
              value={values.keyQuestion!}
              onChange={(event) => {
                keyQuestionCheckboxOnChange();
                handleChange(event);
              }}
              extraBlockStyles={keyQuestionCheckboxExtraBlockStyles}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_key_question')}</span>
            </SquareCheckbox>
          )}

          {mainScaleCheckboxOnChange && (
            <SquareCheckbox
              name="mainScale"
              value={values.mainScale!}
              onChange={(event) => {
                mainScaleCheckboxOnChange();
                setFieldValue('mainScale', !values.mainScale);
              }}
              extraBlockStyles={mainScaleCheckboxExtraBlockStyles}
            >
              <span className="checkboxValue">{getTranslationByLangOrEng(interfaceLanguage, 'companies_form_constructor_main_scale')}</span>
            </SquareCheckbox>
          )}
        </div>

        <FieldSaveButton isLoading={isLoading} submitSuccess={submitSuccess} errors={errors} submitError={submitError} touched={touched} />
      </Form>
    );
  }

  return initialValues ? (
    <Formik
      innerRef={ref}
      initialValues={initialValues}
      onSubmit={onSubmit}
      // @ts-ignore
      validationSchema={notSavedExtraFields?.[index].type ? extraFieldValidationSchema[notSavedExtraFields![index].type] : undefined}
      enableReinitialize
    >
      {renderForm}
    </Formik>
  ) : <Loader />;
})

export default ScaleFieldForm;
