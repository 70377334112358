import * as Yup from 'yup';
import { getTranslationByLangOrEng, translations } from '../../i18n';
import { IUserPermission } from '../../entities';

export interface FormValues {
  name: string,
  permissions: IUserPermission[]
}

export const formInitialValues: FormValues = {
  name: '',
  permissions: [],
};

// @ts-ignore
export const validationSchema = (lang: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  permissions: Yup.array(),
});
