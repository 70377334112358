export interface FormValues {
    name: string,
    email: string,
    roles: number[],
    passwords?: {
      password?: string,
      passwordConfirmation?: string
    }
}

export const formInitialValues: FormValues = {
  name: '',
  email: '',
  roles: [],
  passwords: {
    password: '',
    passwordConfirmation: '',
  },
};
