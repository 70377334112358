import { SocialNetworksModuleStyles } from './SocialNetworksModuleStyles';
import React, { CSSProperties } from 'react';
import {
  EmailIcon, MessageIcon,
  PhoneIcon, TelegramIcon,
  ViberIcon, WhatsAppIcon,
} from '../../../assets';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from '../../atoms';

interface SocialNetworksModuleProps {
  name: string;
  index: number;
  values: any;
  error: string | undefined;
  setFieldValue: (key: string, value: boolean) => void;
  required: boolean;
  handleClick: (type: number) => void;
  selectedNetwork: 'email' | 'sms' | 'call' | 'telegram' | 'whatsapp' | undefined;

  question?: string;
  extraBlockStyles?: CSSProperties;
}

export default function SocialNetworksModule({
  name,
  index,
  values,
  error,
  setFieldValue,
  required,
  handleClick,
  selectedNetwork,

  question,
  extraBlockStyles,
}: SocialNetworksModuleProps) {
  const { touched } = useFormikContext();

  function getSocialNetworkIcon(type: 'email' | 'sms' | 'call' | 'telegram' | 'whatsapp' | 'viber') {
    const types = {
      email:
  <div className="socialItem">
    <EmailIcon color="#636363" />
  </div>,
      sms:
  <div className="socialItem">
    <MessageIcon color="#636363" />
  </div>,
      call:
  <div className="socialItem">
    <PhoneIcon color="#636363" />
  </div>,
      telegram:
  <div className="socialItem">
    <TelegramIcon color="#32A9E1" />
  </div>,
      whatsapp:
  <div className="socialItem">
    <WhatsAppIcon />
  </div>,
      viber:
  <div className="socialItem">
    <ViberIcon color="#7B519C" />
  </div>
      ,
    };

    return types[type];
  }

  return (
    <SocialNetworksModuleStyles style={extraBlockStyles}>
      {question && (
        <div className="titleContainer">
          {required && <span>*</span>}
          <h3>{`${index > 8 ? index + 1 : `0${index + 1}`}. ${question}`}</h3>
        </div>
      )}

      <div className="optionsContainer">
        <div className="options">
          {values.options.filter((option: {value: string, selected: boolean}) => option.selected).map((field: any, subIndex: number) => (
            // eslint-disable-next-line react/no-array-index-key
            <div className="checkboxFieldContainer" key={subIndex}>
              <button
                type="button"
                onClick={() => handleClick(subIndex)}
                className={(values.answer?.[field.value] !== undefined ? 'selected' : '') + (field.value === selectedNetwork ? ' active' : '')}
              >
                <div className="iconContainer">
                  {getSocialNetworkIcon(field.value)}
                </div>
              </button>
            </div>
          ))}
        </div>
        <div className="formErrorContainer">
          {error && <span>{error}</span>}
          {!error && touched && <ErrorMessage name={name} component={FormErrorMessage} />}
        </div>
      </div>
    </SocialNetworksModuleStyles>
  );
}
