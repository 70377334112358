import React from 'react';

import { ArrowDownIcon } from '../../../../assets';
import { DeepArrayItem } from '../../../../entities/DeepArrayItem';
import { hasNonEmptyChildren } from '../../../../utils';
import { CheckboxSquare } from '../../squareCheckbox/checkboxSquare/CheckboxSquare';
import { MultiSelectItemStyles } from './MultiSelectItemStyles';

interface SelectItemProps {
  path: number[],
  option: DeepArrayItem,
  selectKey: string,
  inputName: string,
  handleSelect: (value: any, path?: number[]) => any,
  setOpen: (open: boolean) => void,
  imageProperty?: string,
  expandable?: boolean,
  handleExpandRow?: (id: number) => void,
  expandedRows?: {[key: number]: boolean},
  childrenKey?: string,
  selectable?: boolean,
  checkboxSelected?: boolean,
  checkboxHasSelectedChildren?: boolean,
  checkboxAllChildrenSelected?: boolean,
}

export default function MultiSelectItem({
  path,
  option,
  selectKey,
  inputName,
  handleSelect,
  imageProperty,
  expandable,
  handleExpandRow,
  expandedRows,
  childrenKey,
  setOpen,
  selectable,
  checkboxSelected,
  checkboxHasSelectedChildren,
  checkboxAllChildrenSelected,
}: SelectItemProps) {
  return (
    <MultiSelectItemStyles
        // @ts-ignore
      key={`selectOption:${option.id}:${option[selectKey]}`}
      selectable={!!selectable}
    >
      <div className="row">
        <input
          autoComplete="false"
          type="radio"
          name={inputName}
            // @ts-ignore
          value={option[selectKey]}
            // @ts-ignore
          id={`${option[selectKey]}:${option.id}`}
        />
        <div
          className={imageProperty ? 'withImage' : ''}
        >
          <div className="labelContent" style={{ paddingLeft: 16 + option.deep * 16, textAlign: option.name === '-' ? 'center' : 'left' }}>
            {selectable && (
              <button onClick={() => { handleSelect(option, path); }} type="button" style={{ marginRight: 8 }}>
                <CheckboxSquare
                  selected={!!checkboxSelected}
                  hasSelectedChildren={checkboxHasSelectedChildren}
                  allChildrenAreSelected={checkboxAllChildrenSelected}
                  handleOnClick={() => null}
                />
              </button>
            )}
            {imageProperty && (
            // @ts-ignore
            <button onClick={() => (handleExpandRow && handleExpandRow(option.id))} type="button" className="emoji">{option[imageProperty]}</button>
            )}
            {/* @ts-ignore */}
            <button style={{ textAlign: 'left' }} onClick={() => (handleExpandRow && handleExpandRow(option.id))} type="button">{option[selectKey]}</button>
          </div>
        </div>
        {/* eslint-disable-next-line jsx-a11y/control-has-associated-label, max-len */}
        {expandable && option.children.length > 0 && <button style={{ marginRight: '5px' }} className="expandButton" type="button" onClick={() => (handleExpandRow && handleExpandRow(option.id))}><ArrowDownIcon /></button>}
      </div>

      {expandable
          && expandedRows![option.id]
          && !!childrenKey
          && option.children.filter((node) => node.children.length).map((subItem: DeepArrayItem, index) => {
            const visibleArrow = hasNonEmptyChildren(subItem.children)
            return (
              <MultiSelectItem
                key={`itemKey:${[index]}`}
                path={[...path, index]}
                option={subItem}
                selectKey={selectKey}
                childrenKey={childrenKey}
                inputName={`${inputName}:${subItem.id}`}
                expandedRows={expandedRows}
                expandable={visibleArrow}
                handleExpandRow={handleExpandRow}
                imageProperty={imageProperty}
                handleSelect={handleSelect}
                setOpen={setOpen}
                selectable={selectable}
                checkboxSelected={selectable ? subItem.selected : undefined}
                checkboxHasSelectedChildren={selectable ? subItem.hasSelectedChildren : undefined}
                checkboxAllChildrenSelected={selectable ? subItem.allChildrenAreSelected : undefined}
              />
            )
          })}
    </MultiSelectItemStyles>
  )
}
