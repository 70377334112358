import { Link } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { SubHeaderStyles } from './SubHeaderStyles';
import { useWindowSize } from 'usehooks-ts';

interface SubHeaderProps {
  title: string;
  pathArray?: {name: string, route?: string, callback?: (path?: string) => void}[];
  hideBorderBottom?: boolean;
}

export const SubHeader: React.FC <SubHeaderProps> = ({
  title, pathArray, hideBorderBottom, children,
}) => {
  const [permissionsChanged, setPermissionsChanged] = useState<boolean>(false);

  const permissionsRef = useRef<{[key: string]: boolean}>();

  const { width } = useWindowSize();

  useEffect(() => {
    if (title && pathArray?.length) {
      permissionsRef.current = pathArray.reduce((acc: {[key: string]: boolean}, item) => {
        if (item.route && item.callback) {
          acc[item.route] = false;
        }

        return acc;
      }, {});

      setPermissionsChanged(!permissionsChanged);
    }
  }, [pathArray, title]);

  return (
    <SubHeaderStyles tableVersion={width < 768} className={hideBorderBottom ? 'titleWrapper withoutBorder' : 'titleWrapper'}>
      <div className="titleContainer">
        <h1>{title}</h1>
        {!!pathArray?.length && (
          <div className="path">
            {pathArray.map((item, index) => (item.route ? (
              <div key={item.name}>
                <Link
                  to={item.route}
                  onClick={(event) => {
                    if (item.callback) {
                      event.preventDefault();
                      item.callback?.(item.route);
                    }
                  }}
                >
                  {item.name}
                </Link>
                {pathArray[index + 1] && <span className="arrowRight">{' > '}</span>}
              </div>
            ) : (
              <div key={item.name}>
                <span>{item.name}</span>
                {pathArray[index + 1] && <span className="arrowRight">{' > '}</span>}
              </div>
            )))}
          </div>
        )}
      </div>
      {children}
    </SubHeaderStyles>
  );
};
