import React, { useState, useRef, useEffect } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { StatusFilterStyles } from './StatusFilterStyles';
import { translations } from '../../../../i18n';
import { useAppSelector } from '../../../../state';
import CustomSelectWithMultipleCheckboxes from '../../../form/customSelect/CustomSelectWithMultipleCheckboxes';
import { IReviewStatus } from '../../../../entities/IReviewStatus';
import SubmitButton from '../../../form/submitButton/SubmitButton';
import { TransparentButton } from '../../../atoms/transparentButton/TransparentButton';
import { Loader } from '../../..';
import { extraOneOptionStyles, resetFilterButtonStyles } from '../reviewsTableFiltersUtils';

interface StatusFilterValues {
    status: { id: number, name: string, icon: any, value: boolean }[] | undefined;
}

interface IStatusFilter {
  filterParams: {[key: string]: number | string | any },
  setReQuery: () => void,
  setApply: (params: {[key: string] : number | string }) => void,
  setFilterParams: (params: {[key: string] : number | string }) => void,
  onClose: () => void,
  status?: IReviewStatus[];
}

export const StatusFilter = ({
  filterParams, setReQuery, setApply, setFilterParams, onClose, status,
}: IStatusFilter) => {
  const { interfaceLanguage } = useAppSelector((state) => state.languages);
  const [initialValues, setInitialValues] = useState<StatusFilterValues>();
  const [errorMessage, setErrorMessage] = useState<any>({});

  const ref = useRef<any>();
  const formRef = useRef<FormikProps<StatusFilterValues>>(null);

  async function onSubmit(
    values: StatusFilterValues,
    { setSubmitting }: FormikHelpers<StatusFilterValues>,
  ) {
    const params: { [key: string]: number | string | any} = {};

    const checkedStatuses = values?.status?.filter((item) => item.value)?.map((item) => +item.id);
    if (checkedStatuses && checkedStatuses?.length > 0) {
      params.statuses = checkedStatuses;
    } else if (filterParams?.statuses && checkedStatuses && checkedStatuses?.length <= 0) {
      delete filterParams.statuses;
    }

    const resultParams: {[key: string]: any} = {};

    Object.entries({ ...filterParams, ...params }).forEach((item) => {
      if (!!item[0] && item[1] !== undefined && item[1] !== null && item[1] !== '') {
        resultParams[item[0]] = item[1];
      }
    });

    setFilterParams(resultParams);
    setApply(resultParams);
    setReQuery();
    setSubmitting(false);
  }

  const additionalResetHandlers = () => {
    if (filterParams.statuses) {
      delete filterParams.statuses;
    }
    setFilterParams({ ...filterParams });
    setApply({ ...filterParams });
    setReQuery();
    onClose()
  }

  useEffect(() => {
    const statusesFormattedArray = status?.map((item) => ({
      id: +item.value, name: item.text, icon: item.icon, value: false,
    }))
    const statusesArray = filterParams?.statuses && filterParams.statuses.length > 0
      ? statusesFormattedArray?.map((status) => (filterParams?.statuses?.includes(status.id) ? { ...status, value: true } : status)) : statusesFormattedArray;
    setInitialValues({ status: statusesArray });
  }, [])

  const renderForm = ({
    values,
    handleChange,
    resetForm,
  }: FormikProps<StatusFilterValues>) => (
    <Form>
      <div>
        <SubmitButton extraButtonStyles={{ width: '100%', maxWidth: '500px', height: 32 }}>{translations[interfaceLanguage].apply_filters_button}</SubmitButton>
        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
        <TransparentButton
          handleClick={() => {
            resetForm();
            additionalResetHandlers();
          }}
          text={translations[interfaceLanguage].reset_reviews_filter}
          extraStyles={resetFilterButtonStyles}
          extraButtonTextStyles={{ color: '#ffffff' }}
        />

        <CustomSelectWithMultipleCheckboxes
          name="status"
          handleChange={handleChange}
          options={values.status || []}
          extraOneOptionStyles={extraOneOptionStyles}
          optionsContainerStyles={{ width: '100%' }}
          placeholder={translations[interfaceLanguage].select_multiple_statuses}
          placeholderStyles={{ color: '#979797' }}
          oneOptionValueStyles={{ marginLeft: 4, fontSize: 12 }}
          alwaysOpened
        />
      </div>
    </Form>
  );

  return (
    <StatusFilterStyles ref={ref}>
      {initialValues ? (
        <Formik
          innerRef={formRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          {renderForm}
        </Formik>
      ) : <Loader margin={0} />}
    </StatusFilterStyles>
  )
}
