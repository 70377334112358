import React, { useEffect, useRef, useState } from 'react';
import {
  Form, Formik, FormikHelpers, FormikProps,
} from 'formik';
import { useNavigate } from 'react-router-dom';
import { translations } from '../../../../../../i18n';
import SubmitButton from '../../../../../../components/form/submitButton/SubmitButton';
import {
  useAppDispatch, useAppSelector, MassMailingsEmailRedux, setMassMailingsEmail,
} from '../../../../../../state';
import { MassMailingsCreatingEmailStep2Styles } from './MassMailingsCreatingEmailStep2Styles';
import InputField from '../../../../../../components/form/inputField/InputField';
import CustomSelect from '../../../../../../components/form/customSelect/CustomSelect';
import CodeEditor from '@uiw/react-textarea-code-editor';
import { UploadFileButton } from '../../../../../../components/form/uploadFileButton/UploadFileButton';
import { JustPlusIcon } from '../../../../../../assets';
import parse from 'html-react-parser';
import { FormValues, validationSchema } from './formValuesAndValidation';
import { Modal, TransparentButton } from '../../../../../../components';
import { getErrorMessage, handleKeyUp } from '../../../../../../utils';

const fakeEmails: {name: string, id: number}[] = [
  {
    name: 'lolly@gmail.com',
    id: 223,
  },
  {
    name: 'mashahasha@gmail.com',
    id: 456,
  },
  {
    name: 'dimaskvas@gmail.com',
    id: 258,
  },
];

export default function MassMailingCreatingEmailStep2() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { massMailingMainInfo, massMailingsEmail } = useAppSelector((state) => state.massMailing);
  const { interfaceLanguage } = useAppSelector((state) => state.languages);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState<any>({});
  const [initialValues, setInitialValues] = useState<FormValues>();
  const [showPreviewModal, setShowPreviewModal] = useState<boolean>(false);

  const formikRef = useRef<FormikProps<FormValues> | null>(null);

  async function getDataFromServer() {
    // TODO: add logic
  }

  function getInitialData() {
    if (massMailingsEmail) {
      const initialValuesFromRedux: FormValues = {
        senderName: massMailingsEmail.senderName || '',
        senderEmail: fakeEmails.find((email) => email.id === massMailingsEmail.senderEmail?.id),
        attachedFiles: massMailingsEmail.attachedFiles || [],
        emailBody: massMailingsEmail.emailBody || '',
        letterSubject: massMailingsEmail.letterSubject || '',
      };
      setInitialValues(initialValuesFromRedux);
    } else {
      setInitialValues({
        attachedFiles: [],
        emailBody: '',
        letterSubject: '',
        senderEmail: undefined,
        senderName: '',
      });
    }
  }

  useEffect(() => {
    // getDataFromServer();
    getInitialData();
  }, []);

  function saveEmailDataToRedux() {
    if (formikRef.current && formikRef.current.values) {
      const data: MassMailingsEmailRedux = {
        attachedFiles: formikRef.current.values.attachedFiles,
        emailBody: formikRef.current.values.emailBody,
        senderEmail: formikRef.current.values.senderEmail ? formikRef.current.values.senderEmail : undefined,
        letterSubject: formikRef.current.values.letterSubject,
        senderName: formikRef.current.values.senderName,
      };

      dispatch(setMassMailingsEmail(data));
    }
  }

  function onSubmit(
    values: FormValues,
    { setSubmitting }: FormikHelpers<FormValues>,
  ) {
    setSubmitting(false);
    setIsLoading(true);

    saveEmailDataToRedux();
    navigate('/massMailings/create/step-3');
  }

  const renderForm = ({
    values,
    errors,
    touched,
    setFieldValue,
  }: FormikProps<FormValues>) => (
    <Form>
      <div className="formSection">
        <div className="formFieldsContainer">
          <InputField
            label="Sender name"
            name="senderName"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('senderName', setErrorMessage, errorMessage)}
            placeholder="Enter sender name"
            value={values.senderName}
            error={typeof errorMessage === 'object' ? getErrorMessage('senderName', errorMessage) : undefined}
            extraBlockStyles={{ maxWidth: '428px', width: '100%' }}
          />

          <CustomSelect
            label="Sender's mail"
            name="senderEmail"
            selectKey="name"
            options={fakeEmails || []}
            placeholder="Choose a company"
            value={values.senderEmail}
            handleSelect={(formsArray) => setFieldValue('senderEmail', formsArray)}
            formGroupStyles={{ width: '428px' }}
          />

          <InputField
            label="Letter subject"
            name="letterSubject"
            onChange={setFieldValue}
            onKeyUp={() => handleKeyUp('letterSubject', setErrorMessage, errorMessage)}
            placeholder="Enter letter subject"
            value={values.letterSubject}
            error={typeof errorMessage === 'object' ? getErrorMessage('letterSubject', errorMessage) : undefined}
            extraBlockStyles={{ maxWidth: '428px', width: '100%' }}
          />

          <CodeEditor
            value={values.emailBody}
            language="html"
            placeholder="Please enter Email code."
            onChange={(evn) => setFieldValue('emailBody', evn.target.value)}
            padding={15}
            style={{
              maxWidth: 832,
              width: '100%',
              minHeight: '205px',
              fontSize: 12,
              backgroundColor: '#FFFFFF',
              fontFamily: 'ui-monospace,SFMono-Regular,SF Mono,Consolas,Liberation Mono,Menlo,monospace',
              border: '1px solid #E5E5E5',
              borderRadius: '0px 0px 4px 4px',
            }}
          />
        </div>
        <div className="uploadFilesContainer">
          <div className={values.attachedFiles.length > 6 ? 'fileLimitError uploadButtonContainer' : 'uploadButtonContainer'}>
            <UploadFileButton
              name="Attach files"
              formats="all"
              extraButtonStyles={{ width: 182 }}
              onChangeMultipleFiles={(e) => {
                if (e) {
                  setFieldValue('attachedFiles', [...values.attachedFiles, ...Object.values(e)]);
                }
              }}
              multiple
            />
            <span className="description">
              Maximum number of files 6
              <br />
              Maximum file size 25mb
            </span>

            {values.attachedFiles.length > 6 && (
            <span className="formErrorContainer">* The number of files attached is exceeded</span>
            )}
          </div>
          <div className="attachedFilesContainer">
            {values.attachedFiles.map((file, index) => {
              if (file instanceof File) {
                return (
                  <div className="fileItemWrapper">
                    <div className={file.size > 25000000 ? 'fileItem tooBigSizeError' : 'fileItem'}>
                      <p>{file.name}</p>
                      <button type="button" onClick={() => setFieldValue('attachedFiles', values.attachedFiles.filter((item, valuesFileIndex) => index !== valuesFileIndex))}>
                        <JustPlusIcon />
                      </button>
                    </div>
                    {file.size > 25000000 && (
                    <span className="formErrorContainer">* File size is too big</span>
                    )}
                  </div>
                );
              }
              return (
                123
              );
            })}
          </div>
        </div>

        <div className="buttonsContainer">
          <TransparentButton
            extraStyles={{ height: 40 }}
            text={translations[interfaceLanguage].preview_button}
            handleClick={() => {
              saveEmailDataToRedux();
              setShowPreviewModal(true);
              // navigate('/massMailings/preview');
            }}
          />
          <TransparentButton
            disabled={values.attachedFiles.length > 6 || values.attachedFiles.filter((file) => file instanceof File).some((file) => file instanceof File && file.size! > 25000000)}
            text="Save draft"
            handleClick={() => console.log('save draft')}
            extraStyles={{ height: 40 }}
          />
          <SubmitButton>{translations[interfaceLanguage].next_button}</SubmitButton>
        </div>

        {typeof errorMessage === 'string' && (<p className="extraErrorMessage">{errorMessage}</p>)}
      </div>
    </Form>
  );

  return (
    <MassMailingsCreatingEmailStep2Styles>
      <div className="titleWrapper">
        <h1>Creating - Step 2 of 3</h1>
        <div className="buttonsContainer">
          <TransparentButton
            handleClick={() => {
              saveEmailDataToRedux();
              navigate('/massMailings/create');
            }}
            text={translations[interfaceLanguage].go_back}
          />
        </div>
      </div>

      {massMailingMainInfo && (
        <div className="nameContainer">
          {`Name - ${massMailingMainInfo.name}`}
        </div>
      )}

      {initialValues && (
        <Formik
          innerRef={formikRef}
          initialValues={initialValues}
          onSubmit={onSubmit}
          validationSchema={validationSchema(interfaceLanguage)}
        >
          {renderForm}
        </Formik>
      )}

      {showPreviewModal && (
        <Modal onClose={() => setShowPreviewModal(false)} extraStyles={{ width: '100%', maxWidth: 1000 }}>
          <div className="previewContainer">
            <div className="modalHeader">
              <h3 className="modalTitle">Email preview</h3>
              <button type="button" onClick={() => setShowPreviewModal(false)}>
                <JustPlusIcon color="#999" width={20} height={20} />
              </button>
            </div>

            <div className="modalContent">
              <div className="desktopPreview">
                {parse(massMailingsEmail?.emailBody || '')}
              </div>
            </div>

            <div className="modalFooter">
              <TransparentButton handleClick={() => setShowPreviewModal(false)} text={translations[interfaceLanguage].close_button} />
            </div>
          </div>
        </Modal>
      )}
    </MassMailingsCreatingEmailStep2Styles>
  );
}
