import styled from 'styled-components';

export const LeavePageModalStyles = styled.div`
  background: #fff;
  color: #000000;
  border-radius: 4px;

  .modalHeader {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #e5e5e5;
    padding-left: 16px;
    padding-right: 12px;
    box-sizing: border-box;

    h5 {
      font-size: 14px;
      color: #000;
    }

    button {
      width: 32px;
      height: 32px;
      display: grid;
      place-items: center;

      svg {
        transform: rotate(45deg);
      }
    }
  }

  .body {
    padding: 16px;
    border-bottom: 1px solid #e5e5e5;

    h6 {
      font-size: 18px;
      margin-top: 0;
      margin-bottom: 16px;
      text-align: center;
    }

    p {
      font-size: 14px;
      margin: 0;
    }
  }

  .footer {
    height: 48px;
    padding: 0 16px;
    box-sizing: border-box;
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 20px;

    & > div, a {
      display: grid;
      grid-template-columns: 1fr;
      place-items: center;
    }

    & > div {
      width: 100%;
      margin: 0;

      a, button {
        width: 100%;
      }
    }
  }
`;
