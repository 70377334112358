import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ArrowLeft: FC<TIcon> = React.memo(({ color = 'black' }) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <path d="M0 6L7 0V5H13V7H7V12L0 6Z" fill={color} />
  </svg>
));
