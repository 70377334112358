import styled from 'styled-components';

export const CompaniesInfoModalStyles = styled.div`
  .modalContainer {
      padding: 10px 16px;
      background: #F0F5F5;
      font-weight: 500;
      font-size: 15px;
      line-height: 16px;
      color: #000000;
      border: 1px solid #F0F5F5;
      border-radius: 4px 4px 0px 0px;
    
    p {
      text-align: center;
      margin-bottom: 20px;
    }
    
    
    .buttonsContainer {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 20px;
      
      button {
        padding: 0 10px;
        font-weight: 500;
        font-size: 14px;
        border-radius: 4px;
        transition: 0.3s all;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      .confirmButton {
        background: #0E9285;
        border: 1px solid #0E9285;
        color: #FFFFFF;
        transition: all 0.3s;

        &:hover {
          background: #FFFFFF;
          color:  #0E9285;
        }
      }

      .declineButton {
        background: #FFFFFF;
        border: 1px solid #C4C4C4;
        color: #000000;

        :hover {
          color: #FFF;
          background: #C4C4C4;
        }
      }
    }
  }
`;
