import React, { CSSProperties } from 'react';
import { SubmitButtonStyles } from './SubmitButtonStyles';
import { ErrorIcon } from '../../../assets';
import { Loader } from '../../atoms';

interface SubmitButtonProps {
  extraBlockStyles?: CSSProperties;
  extraButtonStyles?: CSSProperties;
  onClick?: () => void;
  bgColor?: string;
  textColor?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  isLoading?: boolean;
  isError?: boolean;
  disabled?: boolean,
}

const SubmitButton:React.FC<SubmitButtonProps> = ({
  children,
  extraBlockStyles,
  extraButtonStyles,
  onClick,
  bgColor,
  textColor,
  type,
  isLoading,
  isError,
  disabled,
}) => (
  <SubmitButtonStyles style={extraBlockStyles} bgColor={isError ? '#FF0101' : bgColor || '#0E9285'} textColor={textColor || '#0E9285'}>
    {/* eslint-disable-next-line react/button-has-type */}
    <button type={type || 'submit'} style={extraButtonStyles} onClick={onClick} disabled={isLoading || disabled}>
      <div className="statusWrapper">
        {isLoading && <Loader height={20} color="#FFF" margin={0} />}
        {isError && <div className="errorWrapper"><ErrorIcon color="red" /></div>}
      </div>
      {children}
      <div className="statusWrapper" />
    </button>
  </SubmitButtonStyles>
);

export default SubmitButton;
