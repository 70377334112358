import * as Yup from 'yup';
import { DeepArrayItem, ISocialNetwork } from '../../../entities';
import { getTranslationByLangOrEng } from '../../../i18n';

export const validationSchema = (language: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(language, 'validation_required')),
  tiedNode: Yup.object().nullable().notRequired(),

  googlePlaceID: Yup.object().shape({
    value: Yup.string(),
  }).notRequired(),
  sms: Yup.object({
    active: Yup.boolean(),
    key: Yup.string().nullable().notRequired(),
    alphaName: Yup.string().nullable().notRequired(),
  }).test({
    name: 'allFieldsFilled',
    message: `${getTranslationByLangOrEng(language, 'validation_must_fill_all_fields_for')} SMS`,
    test: (val) => (val.active ? !!val.key && !!val.alphaName : true),
  }),
  viber: Yup.object({
    active: Yup.boolean(),
    key: Yup.string().nullable().notRequired(),
    alphaName: Yup.string().nullable().notRequired(),
  }).test({
    name: 'allFieldsFilled',
    message: `${getTranslationByLangOrEng(language, 'validation_must_fill_all_fields_for')} Viber`,
    test: (val) => (val.active ? !!val.key && !!val.alphaName : true),
  }),
  telegram: Yup.object({
    active: Yup.boolean(),
    key: Yup.string().nullable().notRequired(),
    alphaName: Yup.string().nullable().notRequired(),
  }).test({
    name: 'allFieldsFilled',
    message: `${getTranslationByLangOrEng(language, 'validation_must_fill_all_fields_for')} Telegram`,
    test: (val) => (val.active ? !!val.key && !!val.alphaName : true),
  }),
  email: Yup.object({
    active: Yup.boolean(),
    key: Yup.string().nullable().notRequired(),
    alphaName: Yup.string().nullable().notRequired(),
  }).test({
    name: 'allFieldsFilled',
    message: `${getTranslationByLangOrEng(language, 'validation_must_fill_all_fields_for')} Email`,
    test: (val) => (val.active ? !!val.key && !!val.alphaName : true),
  }),
});

export interface FormValues {
  name: string;
  slug: string | undefined;
  code: string | undefined;
  useDefaultForm: boolean;
  tiedNode?: number | undefined;
  secondTiedNode?: number | undefined;
  address: {
    value: string,
    id?: number,
  };
  nodes: DeepArrayItem[];

  googlePlaceID: {
    value: string,
    id?: number,
  };
  sms: ISocialNetwork;
  viber: ISocialNetwork;
  telegram: ISocialNetwork;
  whatsapp: ISocialNetwork;
  email: ISocialNetwork;
  extraFields: {id?: number, name: string, value: string, key?: string, position: string}[];
  sourceId: number | null;
  responsibleID: number | undefined;
  enableOverdue: boolean,
}

export const networks: {key: 'sms' | 'viber' | 'telegram' | 'whatsapp' | 'email', name: string}[] = [
  { key: 'sms', name: 'SMS' },
  { key: 'viber', name: 'Viber' },
  { key: 'telegram', name: 'Telegram' },
  { key: 'whatsapp', name: 'WhatsApp' },
  { key: 'email', name: 'E-mail' },
];
