import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const InProgressIcon: FC<TIcon> = React.memo(({ width = 22, height = 22 }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 700 700"
  >
    <path
      fill="#199085"
      d="M589,350c0-132-107-239-239-239S111,218,111,350s107,239,239,239S589,482,589,350z M328.1,461.4
  c-11.1-11.1-11.1-29.2,0-40.3l41.7-41.2l-110.4,0c-16,0-28.9-13-28.9-28.9v-1.9c0-16,12.9-28.9,28.9-28.9h110l-41.3-40.8
  c-11.1-11.1-11.1-29.2,0-40.3c11.3-11.1,29.5-11.1,40.7,0l91.9,91.1c11.1,11.1,11.1,29.2,0,40.3l-91.9,91
  C357.6,472.5,339.4,472.5,328.1,461.4L328.1,461.4z"
    />
    <path
      fill="#FFFFFF"
      d="M328.3,461.4c-11.1-11.1-11.1-29.2,0-40.3l41.7-41.2l-110.4,0c-16,0-28.9-13-28.9-28.9v-1.9
  c0-16,12.9-28.9,28.9-28.9h110l-41.3-40.8c-11.1-11.1-11.1-29.2,0-40.3c11.3-11.1,29.5-11.1,40.7,0l91.9,91.1
  c11.1,11.1,11.1,29.2,0,40.3l-91.9,91C357.8,472.5,339.6,472.5,328.3,461.4L328.3,461.4z"
    />
  </svg>
));
