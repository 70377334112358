import styled from 'styled-components';

export const HeaderStyles = styled.header<{tabletVersion: boolean, mobileVersion: boolean}>`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: ${({ tabletVersion }) => (tabletVersion ? '24px' : '32px')};
  padding-right: ${({ tabletVersion }) => (tabletVersion ? '30px' : '38px')};
  background-color: #FFFFFF;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04);
  box-sizing: border-box;

  .leftPart {
    .menuButton {
      margin-right: ${({ tabletVersion }) => (tabletVersion ? '16px' : '20px')};
    }

    &.compact {
      a svg {
        width: ${({ mobileVersion }) => (mobileVersion ? '80px' : '100px')};
      }
    }
  }

  .leftPart, .leftPart > a, .leftPart > button {
    display: flex;
    align-items: center;
  }

  .rightBlock {
    display: flex;
  }

  & > div > div > .select {
    min-width: 32px;
    padding: 0px 20px;
    height: 32px;
    margin-right: 11px;
    display: grid;
    place-items: center;
    background-color: #F0F5F5;
    border: 1px solid #1BCAB9;
    border-radius: 10px;
    box-sizing: border-box;

    font-weight: bold;
    font-size: 14px;
    color: #1BCAB9;
    text-decoration: none;
  }

  .selectMobile, .profileButtonMobile {
    height: 100%;
    display: flex;
    align-items: center;

    svg {
      filter: invert(92%) sepia(5%) saturate(0%) hue-rotate(150deg) brightness(87%) contrast(86%);
      transition: all 0.2s;
      height: ${({ mobileVersion }) => (mobileVersion ? '18px' : '22px')};

      &:hover {
        filter: invert(78%) sepia(29%) saturate(3090%) hue-rotate(118deg) brightness(90%) contrast(79%);
        transition: all 0.2s;
      }
    }

    &.active {
      svg {
        filter: invert(78%) sepia(29%) saturate(3090%) hue-rotate(118deg) brightness(90%) contrast(79%);
      }
    }
  }

  .feedbackModal {
    display: flex;
    align-items: center;
    margin: 0 10px;

    svg:hover {
      path {
        fill: #0E928585
      }
    }

    @media screen and (max-width: 768px) {
      margin: 0;

      svg {
        height: 18px;
        width: 18px;
      }
    }

    button {
      cursor: pointer;
    }
  }

  .profileButtonMobile {
    margin-right: 8px;

    svg {
      height: ${({ mobileVersion }) => (mobileVersion ? '16px' : '20px')};
    }
  }

  .rightPart {
    height: 100%;
    display: flex;
    align-items: center;

    & > div {
      margin-left: ${({ mobileVersion }) => (mobileVersion ? '20px' : '40px')};
    }
  }
  
  .languageSelect {
    position: relative;
    
    ul {
      display: grid;
      grid-gap: 8px;
      width: 40px;
      background-color: #fff;
      padding: 16px;
      border-radius: 4px;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
      position: absolute;
      top: 52px;
      left: ${({ tabletVersion }) => (tabletVersion ? 'none' : 'calc(50% - 6px)')};
      right: ${({ tabletVersion }) => (tabletVersion ? '0' : 'none')};
      transform: ${({ tabletVersion }) => (tabletVersion ? 'none' : 'translateX(-50%)')};
      z-index: 100;
      
      li {
        width: 100%;
        height: 18px;
        display: flex;
        align-items: center;
        list-style: none;
        
        .select {
          width: 100%;
          
          padding: 0 8px;
        }
      }
    }
    
    > .select {
      display: grid;
      grid-template-columns: 1fr min-content;
      gap: 8px;
      align-items: center;
      padding: 0 8px;
      
      &.active {
        svg {
          transform: rotate(180deg);
        }
      }
    }
  }
  
  .profileButtonContainer {
    display: flex;
    align-items: center;
  }

  .topMenu {
    background-color: #fff;
    padding: 16px;
    border-radius: 4px;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 64px;
    right: 32px;
    z-index: 100;

    svg {
      height: 18px;
    }
    
    a {
      margin-bottom: 8px;
    }

    a, button {
      text-decoration: none;
      display: flex;
      align-items: center;
      color: #000;
      font-size: 14px;
      
      .iconContainer {
        width: 24px;
        margin-right: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`;
