import React, { CSSProperties, useCallback, useRef } from 'react';
import { RadioButtonStyles } from './RadioButtonStyles';

interface RadioProps {
  name: string;
  value: string;
  checked: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  extraBlockStyles?: CSSProperties;
  radioButtonWrapperStyles?: CSSProperties;
  customizedRadioContainerStyles?: CSSProperties;
  disabled?: boolean;
  notFullClickable?: boolean;
  children?: any;
}

export default function RadioButton({
  name,
  value,
  checked,
  onChange,
  extraBlockStyles,
  radioButtonWrapperStyles,
  customizedRadioContainerStyles,
  disabled,
  notFullClickable,
  children,
}: RadioProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnClick = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  }, [inputRef]);

  const classNameValue = checked && !disabled
    ? 'customizedRadio customizedRadioChecked' : checked && disabled
      ? 'customizedRadio customizedRadioCheckedDisabled' : 'customizedRadio';

  return (
    <RadioButtonStyles style={extraBlockStyles}>
      <input
        className="radioDefault"
        type="radio"
        name={name}
        value={value}
        checked={checked}
        onChange={(event) => (onChange ? onChange(event) : null)}
        ref={inputRef}
        disabled={disabled}
      />

      <div className="radioWrapper" style={radioButtonWrapperStyles} onClick={() => (notFullClickable ? null : handleOnClick())}>
        <div style={customizedRadioContainerStyles}>
          <button
            type="button"
            className={classNameValue}
            onClick={handleOnClick}
          >
            {checked && <div className={disabled ? 'checked innerRoundDisabled' : 'checked'} />}
          </button>
        </div>
        <div className="radioText">
          {children}
        </div>
      </div>
    </RadioButtonStyles>
  );
}
