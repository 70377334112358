import styled from 'styled-components';

export const MultiTenancyStyles = styled.div`
  margin: 10px 0;
  width: 100%;
  max-width: 600px;
  display: flex;
  gap: 24px;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    gap: 10px;
    flex-direction: column;
  }

  .nodesSelects {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
  }
`;
