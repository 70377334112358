import * as Yup from 'yup';
import { getTranslationByLangOrEng } from '../../../i18n';

export const validationSchema = (lang: string) => Yup.object({
  name: Yup.string().required(getTranslationByLangOrEng(lang, 'validation_required')),
  language_id: Yup.number().required(getTranslationByLangOrEng(lang, 'validation_required')),
  backgroundColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
  elementsColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
  notCheckedElementsColor: Yup.object().required(getTranslationByLangOrEng(lang, 'validation_required')),
});
