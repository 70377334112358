import styled from 'styled-components';

export const FieldIconStyles = styled.div<{backgroundColor: string}>`
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
  border-radius: 4px;
  background-color: ${({ backgroundColor }) => backgroundColor};
`
