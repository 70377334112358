import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const ArrowDownIcon: FC<TIcon> = React.memo(({ width = 12, height = 8, color = '#C4C4C4' }) => (
  <svg
    width={width}
    height={height}
    fill="none"
    viewBox="0 0 12 8"
  >
    <path
      fill={color}
      d="M10.293.293L6 4.586 1.707.293.293 1.707 6 7.414l5.707-5.707L10.293.293z"
    />
  </svg>
));
