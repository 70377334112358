import { DeepArrayItem } from '../../../../entities/DeepArrayItem';
import { NodeFilterItem } from './additionalSettings';

function selectChildren(targetNode: DeepArrayItem) {
  targetNode.children.forEach((item) => {
    if (item.children.length) {
      item.hasSelectedChildren = targetNode.hasSelectedChildren;
      item.allChildrenAreSelected = targetNode.hasSelectedChildren;

      selectChildren(item);
    } else {
      item.selected = targetNode.hasSelectedChildren;
    }
  })
}

interface HandleSelectNodeResponse {
  hasSelectedChildren: boolean,
  allChildrenAreSelected: boolean,
}

export function handleSelectNode(obj: DeepArrayItem[] | DeepArrayItem, value: DeepArrayItem, propPath: number[], hasSelectedChildren?: HandleSelectNodeResponse): HandleSelectNodeResponse {
  const [head, ...rest] = propPath;
  let defaultSelectedChildrenStatuses = hasSelectedChildren;

  if (rest.length) {
    // Has more path parts logic ------------------------------------------------------------------------------------------------- Has more path parts logic
    const hasSelectedChildren = handleSelectNode(Array.isArray(obj) ? obj[head] : obj.children[head], value, rest, defaultSelectedChildrenStatuses);

    if (Array.isArray(obj)) {
      obj[head].hasSelectedChildren = obj[head].children.some((item) => (item.selected || item.hasSelectedChildren));
      obj[head].allChildrenAreSelected = obj[head].children.every((item) => item.selected)
        || obj[head].children.every((item) => item.allChildrenAreSelected);
    } else {
      obj.children[head].hasSelectedChildren = obj.children[head].children.some((item) => (item.selected || item.hasSelectedChildren));
      obj.children[head].allChildrenAreSelected = obj.children[head].children.every((item) => item.selected)
        || obj.children[head].children.every((item) => item.allChildrenAreSelected);
    }

    defaultSelectedChildrenStatuses = hasSelectedChildren;
  } else if (Array.isArray(obj)) {
    // Root level logic ------------------------------------------------------------------------------------------------------------------- Root level logic
    if (obj[head].children.length) {
      obj[head].hasSelectedChildren = !obj[head].hasSelectedChildren;
      selectChildren(obj[head]);
      obj[head].allChildrenAreSelected = obj[head].children.every((item) => item.selected)
      || obj[head].children.every((item) => item.allChildrenAreSelected);
    } else {
      obj[head].selected = !obj[head].selected;
    }

    return {
      hasSelectedChildren: obj.some((item) => item.selected || item.hasSelectedChildren),
      allChildrenAreSelected: obj.every((item) => item.selected || item.hasSelectedChildren),
    };
  } else if (obj.children[head].children.length) {
    // Target node with children ------------------------------------------------------------------------------------------------- Target node with children
    obj.children[head].hasSelectedChildren = !obj.children[head].hasSelectedChildren;

    selectChildren(obj.children[head]);

    obj.children[head].allChildrenAreSelected = obj.children[head].children.every((item) => item.selected)
     || obj.children[head].children.every((item) => item.allChildrenAreSelected);

    return {
      hasSelectedChildren: obj.children.some((item) => item.selected || item.hasSelectedChildren),
      allChildrenAreSelected: obj.children[head].children.every((item) => item.selected) || obj.children[head].children.every((item) => item.allChildrenAreSelected),
    };
  } else {
    // Target node without children ------------------------------------------------------------------------------------------- Target node without children
    if ((defaultSelectedChildrenStatuses?.hasSelectedChildren && !obj.children[head].selected) || !value.children.length) {
      obj.children[head].selected = !obj.children[head].selected;
    }

    if (obj.children[head].selected) {
      return {
        hasSelectedChildren: true,
        allChildrenAreSelected: obj.children.every((item) => item.selected),
      };
    }
    return {
      hasSelectedChildren: obj.children.some((item) => item.selected),
      allChildrenAreSelected: obj.children.every((item) => item.selected),
    };
  }

  return defaultSelectedChildrenStatuses;
}

export function markAllNodesWithAllSelectedChildren(obj: DeepArrayItem[] | DeepArrayItem): void {
  if (Array.isArray(obj)) {
    obj.forEach((child) => {
      if (child.hasSelectedChildren) {
        markAllNodesWithAllSelectedChildren(child);

        child.allChildrenAreSelected = child.children.every((subChild) => subChild.allChildrenAreSelected);
      }
    })
  } else if (obj.hasSelectedChildren) {
    obj.children.forEach((child) => {
      if (child.hasSelectedChildren) {
        const allChildrenAreSelected = markAllNodesWithAllSelectedChildren(child);

        child.allChildrenAreSelected = allChildrenAreSelected === undefined ? child.children.every((subChild) => (subChild.selected || subChild.allChildrenAreSelected)) : allChildrenAreSelected;
      }
    })

    obj.allChildrenAreSelected = obj.children.every((child) => (child.selected || child.allChildrenAreSelected));
  }
}

export function getAllSelectedNodeIds(arrayIds: number[], nodes: DeepArrayItem[] | DeepArrayItem) {
  if (Array.isArray(nodes)) {
    nodes.forEach((node) => {
      if (node.selected) {
        arrayIds.push(node.id);
      }

      if (node.hasSelectedChildren) {
        getAllSelectedNodeIds(arrayIds, node.children);
      }
    })
  }
}

export function getParentsPath(ids: number[], itemsList: NodeFilterItem[]) {
  const parentId = itemsList.find((item) => item.id === ids[0])?.parentId;

  if (parentId !== undefined) {
    ids.unshift(parentId);
    getParentsPath(ids, itemsList);
  }
}

export function getPathForInitialList(pathForInitialList: number[], targetNode: DeepArrayItem, itemsList: NodeFilterItem[], formikStateNodes: DeepArrayItem[]) {
  const targetNodeParentId = itemsList.find((item) => item.id === targetNode.id)?.parentId;
  const ids: number[] = [...(targetNodeParentId ? [targetNodeParentId] : []), targetNode.id];
  let lastParent: DeepArrayItem | undefined;

  getParentsPath(ids, itemsList);

  ids.forEach((id) => {
    const index = (lastParent?.children || formikStateNodes)?.findIndex((node) => node.id === id);

    if (index !== undefined) {
      pathForInitialList.push(index);
      lastParent = (lastParent?.children || formikStateNodes!)[index];
    }
  })
}

export function getParentId(array: { id: number, name: string, deep: number; parentId: number | undefined }[], deep: number, index: number) {
  for (let j = index - 1; j >= 0; j--) {
    if (array[j].deep < deep) {
      return +array[j].id;
    }
  }
}
