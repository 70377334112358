import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const StripArrowLeft: FC<TIcon> = React.memo(({ color = 'black' }) => (
  <svg
    width="16"
    height="12"
    viewBox="0 0 16 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M2 0H0V12H2V0ZM10 5H16V7H10V12L4 6L10 0V5Z" fill={color} />
  </svg>
));
